import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Actions from '../xmpp/Actions';
import { getUserDetailsForChat } from '../../store/actions/adminActions';
import { supportChatHistory } from '../../store/actions/globalActions';

class AdminMessages extends Actions {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            messages: [],
            users: [],
            currentChatUser: null,
            lastMessage: {}
        };
        this.uniqueKey = "";
    }

    componentDidMount() {
        if (localStorage.getItem('admin-identity')) {
            let uniqueKey = JSON.parse(localStorage.getItem('admin-identity')).uniquekey;
            this.uniqueKey = uniqueKey;
            this.connection(uniqueKey);
            this.props.dispatch(supportChatHistory('admin'));
        } else {
            this.props.history.push('/admin-login');
        }
    }

    manageKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit();
        } else {
            this.activeComposing(this.state.currentChatUser.uniquekey);
        }
    }

    manageKeyUp = () => {
        this.stopComposing(this.state.currentChatUser.uniquekey);
    }

    handleSubmit = () => {
        if (this.state.message) {
            this.setLastMessage(this.state.message, this.state.currentChatUser.uniquekey);
            this.sendMessage(this.state.currentChatUser.uniquekey, this.state.message);
            const sentMessage = [{ type: 'sent', message: this.state.message, user: this.state.currentChatUser.uniquekey }];
            this.setState({
                messages: [...this.state.messages, ...sentMessage]
            }, () => {
                this.manageScroll();
            });
            this.setState({ message: '' })
        }
    }

    onRecievrMessage = (message, from) => {
        const receivedMessage = [{ type: 'received', message: message, user: from }];
        const newUser = this.state.users.includes(from) ? this.state.users : [...this.state.users, from];
        this.setState({
            users: newUser,
            messages: [...this.state.messages, ...receivedMessage]
        }, () => {
            this.setLastMessage(message, from);
        });
    }

    setLastMessage = (message, from) => {
        let lastMessage = this.state.lastMessage;
        lastMessage[from] = message;
        this.setState({lastMessage: lastMessage});
    }

    manageScroll = () => {
        const list = document.getElementById("chat-scroll"),
            targetLis = document.querySelectorAll(".message-content"),
            targetLi = targetLis[targetLis.length - 1];
            list.scrollTop = targetLi.offsetTop;
    }

    openChatPanel = (user) => {
        console.warn("user ===============", user)
        this.setState({ currentChatUser: user }, () => {
            this.manageScroll();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState !== this.state) {
            if(prevState.users !== this.state.users && this.state.users.length > 0) {
                // this.EnablePresence(this.uniqueKey, this.state.users);
                const postData = {uniquekey: this.state.users}
                this.props.dispatch(getUserDetailsForChat(postData));
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.chat_history !== this.props.chat_history) {
            const newUsers = Object.keys(nextProps.chat_history);
            this.setState({users: [...newUsers]}, () => {
                this.state.users.forEach(user => {
                    const message = this.props.chat_history[user].pop().body;
                    this.setLastMessage(message, user.toUpperCase());
                })
            });
        }

    }

    render() {
        const { messages, currentChatUser } = this.state;
        const { chat_history } = this.props;
        return (
            <div>
                <div className="main-wrapper">
                    <div className="content message-window-content">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="chat-window">
                                    <div className="chat-cont-left">
                                        <div className="chat-header">
                                            <span><Link to="dashboard"><i className="fas fa-arrow-alt-circle-left"></i></Link> Chats</span>
                                            <Link to="#" className="chat-compose">
                                                <i className="material-icons">control_point</i>
                                            </Link>
                                        </div>
                                        <form className="chat-search">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <i className="fas fa-search"></i>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Search" />
                                            </div>
                                        </form>
                                        <div className="chat-users-list">
                                            <div className="chat-scroll">
                                                {this.props.userDetailListing.data && this.props.userDetailListing.data.map((user, key) => {
                                                    return (
                                                        <Link to="#" className="media" key={`user-${key}`} onMouseUp={() => this.openChatPanel(user)}>
                                                            <div className="media-img-wrap">
                                                                <div className={`avatar avatar-${this.state.status[user.uniquekey] === 'online' ? 'online' : 'offline'}`}>
                                                                    <img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
                                                                </div>
                                                            </div>
                                                            <div className="media-body">
                                                                <div>
                                                                    <div className="user-name">{user.username}</div>
                                                                    <div className="user-last-chat">{this.state.lastMessage[user.uniquekey]}</div>
                                                                </div>
                                                                {/* <div>
                                                                    <div className="last-chat-time block">2 min</div>
                                                                    <div className="badge badge-success badge-pill">15</div>
                                                                </div> */}
                                                            </div>
                                                        </Link>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {!currentChatUser &&
                                        <div className="chat-cont-right">
                                            <div className="chat-header"></div>
                                            <div className="chat-body">
                                                <div className="chat-scroll">
                                                    <h1>Welcome</h1>
                                                </div>
                                            </div>
                                            <div className="chat-footer"></div>
                                        </div>
                                    }
                                    {currentChatUser
                                        && <div className="chat-cont-right">
                                            <div className="chat-header">
                                                <Link id="back_user_list" to="#" className="back-user-list">
                                                    <i className="material-icons">chevron_left</i>
                                                </Link>
                                                <div className="media">
                                                    <div className="media-img-wrap">
                                                        <div className={`avatar avatar-${this.state.status[currentChatUser.uniquekey] === 'online' ? 'online' : 'offline'}`}>
                                                            <img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="user-name">{currentChatUser.username}</div>
                                                        <div className="user-status">{this.state.status[currentChatUser.uniquekey] === 'online' ? 'online' : 'offline'}</div>
                                                    </div>
                                                </div>
                                                <div className="chat-options">
                                                    <Link to="#" data-toggle="modal" data-target="#voice_call">
                                                        <i className="material-icons">local_phone</i>
                                                    </Link>
                                                    <Link to="#" data-toggle="modal" data-target="#video_call">
                                                        <i className="material-icons">videocam</i>
                                                    </Link>
                                                    <Link to="#">
                                                        <i className="material-icons">more_vert</i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="chat-body">
                                                <div className="chat-scroll" id="chat-scroll">
                                                    <ul className="list-unstyled" id="list-unstyled">
                                                        {chat_history[currentChatUser.uniquekey.toLowerCase()]
                                                            && chat_history[currentChatUser.uniquekey.toLowerCase()].map((message, key) => {
                                                                return (
                                                                    <li key={`message_${key}`} className={`media message-content ${message.fromAddress !== currentChatUser.uniquekey ? 'sent' : 'received'}`}>
                                                                        <div className="media-body">
                                                                            <div className="msg-box">
                                                                                <div>
                                                                                    <p>{message.body}</p>
                                                                                    <ul className="chat-msg-info">
                                                                                        <li>
                                                                                            <div className="chat-time">
                                                                                                {/* <span>8:30 AM</span> */}
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                        })}
                                                        {messages.map((message, key) => {
                                                            if (message.user === currentChatUser.uniquekey) {
                                                                return (
                                                                    <li key={`message_${key}`} className={`media message-content ${message.type === 'sent' ? 'sent' : 'received'}`}>
                                                                        <div className="media-body">
                                                                            <div className="msg-box">
                                                                                <div>
                                                                                    <p>{message.message}</p>
                                                                                    <ul className="chat-msg-info">
                                                                                        <li>
                                                                                            <div className="chat-time">
                                                                                                {/* <span>8:30 AM</span> */}
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                            return null;
                                                        })}
                                                        {
                                                            this.state.typingNotification
                                                            && <li className="media received">
                                                                <div className="avatar">
                                                                    <img src="./../assets/img/patients/patient.jpg" alt="User"
                                                                        className="avatar-img rounded-circle" />
                                                                </div>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div>
                                                                            <div className="msg-typing">
                                                                                <span></span>
                                                                                <span></span>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="chat-footer">
                                                <div className="input-group">
                                                    {/* <div className="input-group-prepend">
                                                    <div className="btn-file btn">
                                                        <i className="fa fa-paperclip"></i>
                                                        <input type="file" />
                                                    </div>
                                                </div> */}
                                                    <input
                                                        id="message"
                                                        type="text"
                                                        className="input-msg-send form-control"
                                                        placeholder="Write your message here..."
                                                        onKeyPress={this.manageKeyPress}
                                                        onKeyUp={this.manageKeyUp}
                                                        onChange={e => this.setState({ message: e.target.value })}
                                                        value={this.state.message}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn msg-send-btn"
                                                            id="btn-chat"
                                                            onMouseUp={this.handleSubmit}
                                                        ><i
                                                            className="fab fa-telegram-plane"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetailListing : state.admin.users,
    chat_history: state.global.chat_history
});

export default connect(mapStateToProps)(AdminMessages);
