import * as types from "../types/types";

const initalState = {
    nearby_locations:[],
    nearby_doctor_council:[],
    searched_locations:[],
    searched_doctor_council:[],
}

const locationReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.NEARBY_LOCATIONS:
            return {
                ...state,
                nearby_locations:action.payload
            }
        case types.NEARBY_DOCTOR_COUNCIL:
            return {
                ...state,
                nearby_doctor_council:action.payload
            }
        case types.SEARCHED_LOCATIONS:
            return {
                ...state,
                searched_locations:action.payload
            }
        case types.SEARCHED_DOCTOR_COUNCIL:
            return {
                ...state,
                searched_doctor_council:action.payload
            }
        default:
            return state
    }
}

export default  locationReducer;
