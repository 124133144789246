import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { SubmitReview } from "../store/actions/globalActions";

const initialState = {
    points: "",
    title: "",
    description: "",
    terms: "",
    loginkey: "",
    userID: "",
    update: false,
    reviewID: "",
};

class ReviewForm extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.validator = new SimpleReactValidator();
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const userID = localStorage.data ? JSON.parse(localStorage.data).loginID : "";
        this.setState({ loginkey: this.props.loginKey });
        this.setState({ userID });
        const { reviewData } = this.props;
        if (Object.keys(reviewData).length > 0) {
            this.setState({
                points: reviewData.points.toString(),
                title: reviewData.title,
                description: reviewData.description,
                terms: "accepted",
                reviewID: reviewData.unique_key,
                update: true
            },() => {
                this.formRef.current.scrollIntoView()
            });
        }
    }

    // UNSAFE_componentWillReceiveProps(newProps) {
    //     const { reviewData, reviewList } = newProps;
    //     const filteredReviews = reviewList.filter((review) => {
    //         return review.userID === this.state.userID;
    //     });
    //     if (filteredReviews.length > 0) {
    //         this.setState({ update: true });
    //     }

    //     if (Object.keys(reviewData).length > 0) {
    //         this.setState({
    //             points: reviewData.points.toString(),
    //             title: reviewData.title,
    //             description: reviewData.description,
    //             terms: "accepted",
    //             reviewID: reviewData.unique_key,
    //         },() => {
    //             this.formRef.current.scrollIntoView()
    //         });
    //     }
    // }

    goToForm = () => {
        alert("Sdfs");
        this.formRef.current.scrollIntoView();
    }

    onValueChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onReviewSubmit = async (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            let postData = {};
            const {
                points,
                title,
                description,
                loginkey,
                reviewID,
            } = this.state;
            if (!this.state.update) {
                postData = { points, title, description, loginkey };
            } else {
                postData = { points, title, description, loginkey, reviewID };
            }
            const res = await this.props.dispatch(SubmitReview(postData));
            if (res === 1) {
                this.setState(
                    {
                        points: "",
                        title: "",
                        description: "",
                        terms: "",
                    },
                    () => {
                        this.props.manageFormSubmit();
                        this.validator.hideMessages();
                    }
                );
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        this.validator.purgeFields();
        return (
            <form ref={this.formRef} onSubmit={this.onReviewSubmit}>
                <div className="form-group">
                    <label>Review</label>
                    <div className="star-rating">
                        <input
                            id="star-5"
                            type="radio"
                            name="points"
                            value="5"
                            checked={this.state.points === "5"}
                            onChange={this.onValueChange}
                        />
                        <label htmlFor="star-5" title="5 stars">
                            <i className="active fa fa-star"></i>
                        </label>
                        <input
                            id="star-4"
                            type="radio"
                            name="points"
                            value="4"
                            checked={this.state.points === "4"}
                            onChange={this.onValueChange}
                        />
                        <label htmlFor="star-4" title="4 stars">
                            <i className="active fa fa-star"></i>
                        </label>
                        <input
                            id="star-3"
                            type="radio"
                            name="points"
                            value="3"
                            checked={this.state.points === "3"}
                            onChange={this.onValueChange}
                        />
                        <label htmlFor="star-3" title="3 stars">
                            <i className="active fa fa-star"></i>
                        </label>
                        <input
                            id="star-2"
                            type="radio"
                            name="points"
                            value="2"
                            checked={this.state.points === "2"}
                            onChange={this.onValueChange}
                        />
                        <label htmlFor="star-2" title="2 stars">
                            <i className="active fa fa-star"></i>
                        </label>
                        <input
                            id="star-1"
                            type="radio"
                            name="points"
                            value="1"
                            checked={this.state.points === "1"}
                            onChange={this.onValueChange}
                        />
                        <label htmlFor="star-1" title="1 star">
                            <i className="active fa fa-star"></i>
                        </label>
                    </div>
                    {this.validator.message(
                        "Rating",
                        this.state.points,
                        "required"
                    )}
                </div>
                <div className="form-group">
                    <label>Title of your review</label>
                    <input
                        className="form-control"
                        type="text"
                        name="title"
                        placeholder="If you could say it in one sentence, what would you say?"
                        value={this.state.title}
                        onChange={this.onValueChange}
                    />
                    {this.validator.message(
                        "Title",
                        this.state.title,
                        "required"
                    )}
                </div>
                <div className="form-group">
                    <label>Your review</label>
                    <textarea
                        id="review_desc"
                        maxLength={150}
                        className="form-control"
                        name="description"
                        value={this.state.description}
                        onChange={this.onValueChange}
                    ></textarea>

                    <div className="d-flex justify-content-between mt-3">
                        <small className="text-muted">
                            <span id="chars">
                                {150 - this.state.description.length}
                            </span>{" "}
                            characters remaining
                        </small>
                    </div>
                    {this.validator.message(
                        "Description",
                        this.state.description,
                        "required"
                    )}
                </div>
                <hr />
                <div className="form-group">
                    <div className="terms-accept">
                        <div className="custom-checkbox">
                            <input
                                type="checkbox"
                                id="terms_accept"
                                name="terms"
                                value="accepted"
                                checked={this.state.terms === "accepted"}
                                onChange={this.onValueChange}
                            />
                            <label htmlFor="terms_accept">
                                I have read and accept{" "}
                                <Link to="#">Terms &amp; Conditions</Link>
                            </label>
                            {this.validator.message(
                                "Terms & Conditions",
                                this.state.terms,
                                "required"
                            )}
                        </div>
                    </div>
                </div>
                <div className="submit-section">
                    <button
                        type="submit"
                        className="btn btn-primary submit-btn"
                    >
                        {!this.state.update ? "Add Review" : "Update Review"}
                    </button>
                </div>
            </form>
        );
    }
}

export default connect()(ReviewForm);
