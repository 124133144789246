import React from 'react';
import {connect} from 'react-redux';
const Loaders = (props) => {
    const {loader} = props;
    return (
        <div id="preloader" className={`${loader?"show":"hide"}`}>
            <div id="loader">
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
	loader: state.static.loader
});
export default connect(mapStateToProps)(Loaders);