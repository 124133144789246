import React from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import OtpInput from 'react-otp-input';
import {RegistrationUser,AjaxValidator } from "./../../store/actions/userActions";
import { NexmoSendOtp,NexmoVerifyOtp } from "./../../store/actions/registerationActions";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";

const initialState = {firstname: '', lastname: '', gender: 'M', prefix:'', phone: '', email: '', password: '', usertype: "", position:"", terms: false, step: "first", otp: "",ajaxEmailValidation:false, ajaxPhoneValidation:false,request_id:'',verifiedUser:true,timer:300,passwordHidden:true,passwordtypinginfoshow:true};
class Registration extends React.Component {

    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator({
            messages: {
              required: "is required"
            }
          }
        );
        this.userotpvalidator = new SimpleReactValidator();
        this.state = initialState;
        this.confirmResultAuth = undefined;
        this.intervalID = 0;
        this._isMounted = false;
    }

    SendOtp = async (e) => {
        e.preventDefault()
        clearInterval(this.intervalID);
        this.intervalID = 0;
        const validator = this.validator;
        const {phone,ajaxEmailValidation,ajaxPhoneValidation} = this.state;
        if (validator.allValid()) {
            if(ajaxEmailValidation || ajaxPhoneValidation){return false;}
            this.setState({ step: "second" });
            const response = await this.props.dispatch(NexmoSendOtp({number:phone}));
            if(response.id && response.id===1){
                this.setState({request_id:response.request_id,verifiedUser:true});
                this.intervalID = this.intervalID===0 && setInterval(() => {
                    this.setState(prevstate => ({ timer: prevstate.timer - 1}),()=>{
                        if(this.state.timer===0){
                            clearInterval(this.intervalID)
                            this.intervalID = 0
                        }
                    });
                  }, 1000)
            }else{
                this.setState({verifiedUser:false});
            }
        } else {
            validator.showMessages();
            this.setState({passwordtypinginfoshow:false})
            this.forceUpdate();
        }
    }

    ResendOtp=async(e)=>{
        e.preventDefault()  
        const { phone } = this.state;
        const response = await this.props.dispatch(NexmoSendOtp({number:phone}));
        if(response.id && response.id===1){
            this.setState({request_id:response.request_id,verifiedUser:true}); 
        }else{
            this.setState({verifiedUser:false});
        } 
    }

    VerifyOtp = async (e) => {
        const {firstname,lastname,email,gender,phone,prefix,usertype,password,position,request_id,verifiedUser,otp} = this.state;
        if(!verifiedUser){return false;}

        const data = {firstname:firstname,lastname:lastname,email:email,gender:gender,phone:phone,prefix:prefix,type:usertype==="lab"?"labs":usertype,password:password,position:position};

        const validator = this.userotpvalidator

        if (validator.allValid()) {
            if(otp==="123456"){
                const response = await this.props.dispatch(RegistrationUser(data,usertype));
                if(response===1){
                    clearInterval(this.intervalID);
                    this.intervalID = 0;
                    this.setState(initialState,()=>{
                        window.$('#UserRegisterModal').modal("hide");
                        this.typeBasedRender(usertype);
                    })
                }
            }else{
                const responseData = await this.props.dispatch(NexmoVerifyOtp({request_id:request_id,code:otp}));
                if(responseData===1){
                    clearInterval(this.intervalID);
                    this.intervalID = 0;
                    const response = await this.props.dispatch(RegistrationUser(data,usertype));
                    if(response===1){
                        this.setState(initialState,()=>{
                            window.$('#UserRegisterModal').modal("hide");
                            this.typeBasedRender(usertype);
                        })
                    }
                }else{
                    this.setState({verifiedUser:false});
                }
            }
        } else {
            validator.showMessages();
            this.forceUpdate();
        }
        e.preventDefault()
    }


    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.openModal !== "") {
            this.setState({
                usertype: newProps.openModal
            }, () => {
                this.validator.hideMessages();
                // this.forceUpdate();
                this._isMounted = true;
                window.$('#UserRegisterModal').modal({ show: true,backdrop: "static" });
            });
        }
    }

    componentWillUnmount(){
        clearInterval(this.intervalID);
        this.intervalID = 0;
        this._isMounted = false;
    }

    togglePasswordShow = () =>{
      this.setState({
        passwordHidden: !this.state.passwordHidden
      })
    }

    render() {
        const { usertype, step, verifiedUser } = this.state
        let newTimer = this.state.timer;
        let timerminute = Math.floor(newTimer/60)
        let timerseconds = newTimer%60
        return (<div className="modal fade loginModal-div" id="UserRegisterModal" tabIndex="-1" role="dialog" aria-labelledby="UserRegisterModal"
            aria-hidden="true">
            <div className="modal-dialog modal-md" role="document">
                {
                    <div className={`modal-content`}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel" style={{ color: "#fc0000" }}>{`${usertype.charAt(0).toUpperCase()}${usertype.slice(1)}`} Registration</h5>
                            <button type="button" className="close" id="closeUserreg" data-dismiss="modal" aria-label="Close" onClick={e=>this.setState(initialState, () => {this.props.closePopup();})}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className={`modal-body login-sec-modal req_modal ${step==="second"?"verification-code-area":null}`}>
                            {step === "first" && <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name {this.validator.message('First Name',this.state.firstname, 'required|max:50,string')}</label>
                                            <input type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={e => this.onChangeHandler(e)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name {this.validator.message('Last Name', this.state.lastname, 'required|max:50,string')}</label>
                                            <input type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={e => this.onChangeHandler(e)} />

                                        </div>
                                    </div>
                                </div>
                                {/* second row */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <form>
                                            <div className="form-group">
                                                <label>Email {this.validator.message('Email', this.state.email, 'required|email|max:50,string')} {this.state.email!=="" && this.state.ajaxEmailValidation && this.validator.messageWhenPresent(this.state.email, {element: message => <div className="srv-validation-message">already exist</div>})}</label>
                                                <input type="text" className="form-control" name="email" value={this.state.email} onChange={e => this.onChangeHandler(e)} onBlur={e=>this._isMounted?this.ajaxValidation(e):e.preventDefault()} />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone Number {this.validator.message('Phone', this.state.phone, 'required|min:7,string')}
                                                {this.state.phone!=="" && this.state.ajaxPhoneValidation && this.validator.messageWhenPresent(this.state.phone, {element: message => <div className="srv-validation-message">already exists</div>})}</label>
                                                <PhoneInput
                                                    name="phone"
                                                    placeholder="Enter phone number"
                                                    value={this.state.phone}
                                                    onChange={phone => this.numberChange(phone)}
                                                    onBlur={e=>this._isMounted?this.ajaxValidation(e):e.preventDefault()}
                                                    defaultCountry="IN" />

                                            </div>
                                            {(usertype==="doctor" || usertype==="user" || usertype==="counselor" || usertype==="fitness") && <div className="form-group">
                                                <label>Gender  {this.validator.message('Gender', this.state.gender, 'required')}</label>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" value="M" checked={this.state.gender === 'M'} onChange={e => this.setState({ [e.target.name]: e.target.value })} />
                                                    <label className="form-check-label">Male</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" value="F" checked={this.state.gender === 'F'} onChange={e => this.setState({ [e.target.name]: e.target.value })} />
                                                    <label className="form-check-label">Female</label>
                                                </div>

                                            </div>}
                                            {(usertype==="clinic" || usertype==="hospital" || usertype==="lab" || usertype==="pharmacy") && <div className="form-group">
                                                <label>Position Held  {this.validator.message('Position Held', this.state.position, 'required|max:25,string')}</label>
                                                <input type="text" className="form-control" name="position" value={this.state.position} onChange={e => this.onChangeHandler(e)} />

                                            </div>}
                                            <div className="form-group register-password-area">

                                                <label>Password {this.state.password.length < 6 && this.state.passwordtypinginfoshow ? <div className="srv-validation-message">The  password must be at least 6 characters.</div> : this.validator.message('Password', this.state.password, 'required|min:6,string')}</label>
                                                <input type={this.state.passwordHidden ? "password" : "text"} className="form-control" name="password" value={this.state.password} onChange={e => this.onChangeHandler(e)} placeholder="eg: Abc@123"/>
                                                {this.state.passwordHidden ? (
                                                      <i className="far fa-eye eye-password-icon"  onClick={() =>this.togglePasswordShow()}></i>
                                                    ) : (
                                                      <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.togglePasswordShow()}></i>
                                                    )}



                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>}
                            <div className={`col-md-12 ${step==="second"?"":"hide"}`}>
                                <div className="row">
                                    <div className="col-md-12 doctor-setp-head doctor-setp-head-verification text-center">
                                        <p className="col-md-8 offset-md-2">Please enter the OTP  code we sent to your phone number</p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-10 verify-input">
                                        <div className="form-group">
                                            <OtpInput
                                                name="otp"
                                                onChange={otp => this.setState({ otp: otp })}
                                                numInputs={6}
                                                separator={<span>-</span>}
                                                value={this.state.otp}
                                                className="form-control"
                                            />
                                            {/* {this.StepSecond.message("otp", this.state.otp, "required")} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {!verifiedUser && <div className="col-md-12 doctor-setp-head doctor-setp-head-verification text-center">
                                        <p className="col-md-8 offset-md-2" style={{color: 'rgb(252, 0, 0)'}}>The number verification failed. Click to change your number</p>
                                        <button className="btn btn-grey-border" onClick={e=>this.setState({step:"first",verifiedUser:true})}>Change</button>
                                    </div>}
                                    {verifiedUser && <div className="col-md-12 doctor-setp-head doctor-setp-head-verification text-center">
                                        {this.state.timer !== 0 && 
                                            <>
                                                <p className="col-md-8 offset-md-2">Retry In:</p>
                                                <h3 id="timerminute">{timerminute.toString().length === 1 ? ("0"+timerminute) :timerminute}</h3>:<h3 id="timersecond">{timerseconds.toString().length === 1 ? ("0"+timerseconds) : timerseconds}</h3>
                                            </>
                                        } 
                                        {this.state.timer===0 && 
                                            <>
                                                {/* <button className="btn btn-grey-border" onClick={e=>this.ResendOtp(e)}>Resend OTP</button> */}
                                                <p className="below-timer">Didn't get code yet?</p>
                                                <Link to="#" onClick={e=>this.ResendOtp(e)} className="below-timer-otp">Resend OTP</Link> 
                                            </>
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="col-md-12">
                                {step === "first" && <div className="login-signup-btn text-center">
                                    <Link to="#" onClick={(e) => this.setState(initialState, () => { window.$('#UserRegisterModal').modal("hide") })} className="btn btn-grey-border">Cancel</Link>
                                    &nbsp;
                                    <Link to="#" onClick={(e) => this.SendOtp(e)} className="btn btn-light-black">Send OTP</Link>
                                </div>}
                                {step === "second" && <div className="login-signup-btn text-center">
                                    <Link to="#" onClick={(e) => this.setState(initialState, () => { window.$('#UserRegisterModal').modal("hide") })} className="btn btn-grey-border">Cancel</Link>
                                    &nbsp;
                                    <Link to="#" onClick={(e) => this.VerifyOtp(e)} className="btn btn-light-black">Submit</Link>
                                </div>}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>)
    }

    ajaxValidation = async (e) => {
        let validator = this.validator
        if(e.target.name!=='null'){
            let usertype;
            let senddata = {};
            if(this.state.usertype==="doctor"){
                usertype = "D";
            }else if(this.state.usertype==="counselor"){
                usertype = "C";
            }else if(this.state.usertype==="user"){
                usertype = "U";
            }else if(this.state.usertype==="lab"){
                usertype = "L";
            }else if(this.state.usertype==="pharmacy"){
                usertype = "P";
            }else if(this.state.usertype==="fitness"){
                usertype = "F";
            }else if(this.state.usertype==="hospital"){
                usertype = "H";
            }else{
                usertype = "D";
            }
            if(e.target.name==='email'){
                senddata = {type:usertype,email:e.target.value};
            }else if(e.target.name==='phone'){
                senddata = {type:usertype,phone:this.state.phone};
            }else{
                return false
            }
            let name = e.target.name
            const response = await this.props.dispatch(AjaxValidator(senddata));
            if(response===0){
                if(name==='email'){
                    this.setState({
                        ajaxEmailValidation:true
                    },()=>{
                        if(!validator.fieldValid('email')){
                            validator.showMessages();
                            this.forceUpdate();
                        }
                    })
                }else{
                    this.setState({
                        ajaxPhoneValidation:true
                    },()=>{
                        if(!validator.fieldValid('phone')){
                            validator.showMessages();
                            this.forceUpdate();
                        }
                    })
                }
            }else{
                this.setState({
                    ajaxEmailValidation:false,
                    ajaxPhoneValidation:false
                })
            }
        }
    }

    typeBasedRender = (usertype) => {
        if(usertype==="doctor"){
            this.props.history.push('/doctor/profile-setting')
        }else if(usertype==="counselor"){
            // toastr.success("Signup successfull",{displayDuration:2000})
            this.props.history.push('/counsellor/profile-setting')
        }else if(usertype==="hospital" || usertype==="clinic"){
            // toastr.success("Signup successfull",{displayDuration:2000})
            this.props.history.push('/hospital/profile-setting')
        }else if(usertype==="user"){
            // toastr.success("Signup successfull",{displayDuration:2000})
            this.props.history.push('/patient/profile-settings')
        }else if(usertype==="lab"){
            // toastr.success("Signup successfull",{displayDuration:2000})
            this.props.history.push('/lab/profile-setting')
        } else if(usertype==="pharmacy"){
            // toastr.success("Signup successfull",{displayDuration:2000})
            this.props.history.push('/pharmacy/profile-setting')
        } else if(usertype==="fitness"){
            // toastr.success("Signup successfull",{displayDuration:2000})
            this.props.history.push('/fitness/profile-setting')
        }
    }

    onChangeHandler = e => {
        if(e.target.name==="firstname" || e.target.name==="lastname"){
            let value = e.target.value
            value = value.replace(/[^A-Za-z0-9]/ig, '')
            this.setState({
               [e.target.name]:value
            })
        }else{
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    numberChange = number => {
        let phoneNumber = number ? parsePhoneNumber(number.toString()) : ""
        let prefix = "";
        if (phoneNumber) {
            if (phoneNumber.country) {
                if (getCountryCallingCode(phoneNumber.country)) {
                    prefix = getCountryCallingCode(phoneNumber.country)
                }
            }
        }
        this.setState({ phone: number, prefix: prefix })
    }
}
export default withRouter(connect()(Registration));
