import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';
import Category from './Category';
import SubCategory from './SubCategory';
import Products from './Products';

class AdminProduct extends Component {
    state = {tab:'category'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="category"?"active":""}`} to="#" onClick={e=>this.setState({tab:'category'})} >Category</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="sub-category"?"active":""}`} to="#" onClick={e=>this.setState({tab:'sub-category'})}>Sub Category</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="products"?"active":""}`} to="#" onClick={e=>this.setState({tab:'products'})}>Products</Link>
                                </li>
                            </ul>
                        </nav>
                        {tab==="category" && <Category />}
                        {tab==="sub-category" && <SubCategory />}
                        {tab==="products" && <Products />}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminProduct