import React, { Component } from 'react'; 

class EmptyLayout extends Component {
    render() {
        return (
            <div id="page_container" className="toggled">
            <div className="main_container"> 
                <div className="page_content">
                    {this.props.children}
                </div> 
            </div>
        </div>
        );
    }
}

export default EmptyLayout;