import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import {ApiRequest} from './../helpers/userServices';

export const NearbyLocations = (data) => {
    return async(dispatch) => {
        const response  = await ApiRequest("/api/nearby-locations","POST",true,data,false,false);
        if(response.data){
            dispatch({ type: actionTypes.NEARBY_LOCATIONS, payload: response.data });
            return null;
        }
    }
}

export const NearbyDoctorCouncellor = (data) => {
    return async(dispatch) => {
        const response  = await ApiRequest("/api/nearby-doctor-councellor","POST",true,data,false,false);
        if(response.data){
            dispatch({ type: actionTypes.NEARBY_DOCTOR_COUNCIL, payload: response.data });
            return null;
        }
    }
}

export const SearchedDoctorCouncellor = (data) => {
    return async(dispatch) => {
        const response  = await ApiRequest("/api/nearby-doctor-councellor","POST",true,data,false,false);
        if(response.data){
            dispatch({ type: actionTypes.SEARCHED_DOCTOR_COUNCIL, payload: response.data });
            return null;
        }
    }
}

export const FetchLocations = (data) => {
    return async(dispatch) => {
        const response  = await ApiRequest("/api/suggest-locations","POST",true,data,false,false);
        if(response.data){
            dispatch({ type: actionTypes.SEARCHED_LOCATIONS, payload: response.data });
            return null;
        }
    }
}

export const FetchLatLongs = (data) => {
    return async(dispatch) => {
        const response  = await ApiRequest("/api/fetch-lat-longs","POST",true,data,false,false);
        if(response.data){
            return response.data
        }else{
            return {latitude:'',longitude:''}
        }
    }
}
