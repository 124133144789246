import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';
import AdminSpecialist from './Specialisation';
import AdminSymptoms from './AdminSymptoms'; 

class AdminSpecialisation extends Component {
    state = {tab:'specialisation'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="specialisation"?"active":""}`} to="#" onClick={e=>this.setState({tab:'specialisation'})} >Specialisations</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="symptoms"?"active":""}`} to="#" onClick={e=>this.setState({tab:'symptoms'})}>Symptoms</Link>
                                </li> 
                            </ul>
                        </nav>
                        {tab==="specialisation" && <AdminSpecialist />}
                        {tab==="symptoms" && <AdminSymptoms />} 
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminSpecialisation