import { Component } from 'react'

export default class Actions extends Component {
    state = {
        typingNotification: false,
        message: '',
        status: {}
    }
    connection = null;
    currentUser = null;
    resource = null;
    pending_subscriber = null;
    mucSessionInfo = {};
    typingValidation = false;
    typingTimer = null;



    connection = (uniqueKey='Gagan') => {
        const checkStrophe = setInterval(() => {
            let StropheLib = window.Strophe;
            if (StropheLib) {
                this.EnablePresence(uniqueKey, this.state.users);
                this.manageConnection(StropheLib, uniqueKey);
                clearInterval(checkStrophe)
            }
        }, 100);
    }

    EnablePresence = (currentUser, users) => {

        users.forEach(async(connectWith) => {
            try {
                const postData = {
                    "localuser": currentUser,
                    "localserver": "cap-tek.com",
                    "user": connectWith,
                    "server": "cap-tek.com",
                    "nick": connectWith,
                    "group": "friends",
                    "subs": "both"
                };

                const postData1 = {
                    "localuser": connectWith,
                    "localserver": "cap-tek.com",
                    "user": currentUser,
                    "server": "cap-tek.com",
                    "nick": currentUser,
                    "group": "friends",
                    "subs": "both"
                };

                const request = new Request(`https://cap-tek.com:5280/api/add_rosteritem`, {
                    method: 'POST',
                    body: JSON.stringify(postData),
                    headers: new Headers({ 'Content-Type': 'application/json; charset=utf-' }),
                });

                const request1 = new Request(`https://cap-tek.com:5280/api/add_rosteritem`, {
                    method: 'POST',
                    body: JSON.stringify(postData1),
                    headers: new Headers({ 'Content-Type': 'application/json; charset=utf-' }),
                });

                var data = await Promise.all(
                    [
                        fetch(request).then((response) => response.json()),
                        fetch(request1).then((response) => response.json())
                    ]
                );
                console.log("EnablePresenceData ====>", data);
            } catch (error) {
                console.log("EnablePresenceEror ===>", error);
            }
        });


    }

    manageConnection = (StropheLib, uniqueKey) => {
        let { onRoster, onRosterChanged, onMessage, handlePong } = this;
        let that = this;
        let conn = new StropheLib.Connection('https://cap-tek.com:5280/bosh');
        conn.rawInput = this.rawInput;
        conn.rawOutput = this.rawOutput;
        conn.connect(uniqueKey+"@cap-tek.com", (uniqueKey === 'Gagan' ? 111111 : uniqueKey), function (status) {
            if (status === StropheLib.Status.CONNECTED) {
                console.log('$chat =>connected');
                that.currentUser = uniqueKey;
                let iq = window.$iq({ type: 'get' }).c('query', { xmlns: 'jabber:iq:roster' });
                console.log(`%c %s`, `color:aqua; font-size:50px`, `IQ`, iq);
                conn.sendIQ(iq, onRoster);
                conn.addHandler(onRosterChanged, "jabber:iq:roster", "iq", "set");
                conn.addHandler(onMessage, null, "message");
                conn.addHandler(handlePong, "urn:xmpp:ping", "iq", "get");
            } else if (status === StropheLib.Status.AUTHFAIL) {
                console.log('AUTHFAIL');
            } else if (status === StropheLib.Status.DISCONNECTED) {
                console.log('$chat =>disconnected');
                that.connection = null;
                that.pending_subscriber = null;
            }
        });
        this.connection = conn;
    }

    rawInput = data => {
        // console.log('RECV: ' + data);
    }

    rawOutput = data => {
        // console.log('SENT: ' + data);
    }

    jidToId = (jid) => {
        return window.Strophe.getBareJidFromJid(jid)
            .replace("@", "-")
            .replace(".", "-");
    }

    setStatus = async (status, type) => {

        console.log("set_status==>", status, type);

        const postData = {
            "user": this.currentUser,
            "host": "cap-tek.com",
            "resource": this.resource,
            "type": type,
            "show": status,
            "status": "BB",
            "priority": "7"
        };

        const request = new Request(`https://cap-tek.com:5280/api/set_presence`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({ 'Content-Type': 'application/json; charset=utf-' }),
        });
        try {
            const response = await fetch(request);
            const resData = await response.json();
            console.log("set_presence ===>", resData);
        } catch (error) {
            console.log("set_presence_error ====> ", error);
        }
    }

    onRoster = (iq) => {
        console.log(`%c %s`, `color:aqua; font-size:50px`, `ON_ROSTER_IQ`, iq);
        this.resource = iq.getAttribute('to').split('/')[1];
        console.log(`%c %s`, `color:aqua; font-size:50px`, `ON_ROSTER_IQ_resource`, this.resource);
        // iq.getElementsByTagName('item').forEach(element => {
        //     const jid = element.getAttribute('jid');
        //     const name = element.getAttribute('name') || jid;
        //     const jidId = this.jidToId(jid)
        // });

        this.connection.addHandler(this.onPresence, null, "presence");
        this.connection.send(window.$pres());

        this.setStatus("chat", "available");
    }

    onPresence = (presence) => {
        console.log("$chat =>On Presence=>", presence);
        const type = presence.getAttribute('type');
        const from = presence.getAttribute('from');
        // const jid_id = this.jidToId(from);
        const jid = from.split('@')[0];

        if (type === 'subscribe') {
            this.pending_subscriber = from;
        } else if (type === 'unavailable') {
            this.getUserStatus("offline",jid);
        } else if (type !== 'error') {
            // if () {
                const show = presence.querySelector("show") ? presence.querySelector("show").innerHTML : '';
                let status = "online"
                if (show === "" || show === "chat") {
                    status = "online";
                } else {
                    status = show;
                }
                console.log("STATUS JID ============== ", status,jid);
                this.getUserStatus(status,jid);
            // }
        }
        return true;
    }

    onRosterChanged = (iq) => {
        console.log(`%c %s`, `color:aqua; font-size:50px`, `onRosterChanged`, iq);
        if (iq.querySelectorAll('item')) {
            iq.querySelectorAll('item').forEach(element => {
                const sub = element.getAttribute('subscription');
                const jid = element.getAttribute('jid');
                // const name = element.getAttribute('name') || jid;
                const jid_id = this.jidToId(jid);
                if (sub === 'remove') {
                    // contact is being removed
                    document.querySelector('#' + jid_id).remove();
                } else {
                    // var contact_html = "<li id='" + jid_id + "'>" +
                    //     "<div class='" +
                    //     ($('#' + jid_id).attr('class') || "roster-contact offline") +
                    //     "'>" +
                    //     "<div class='roster-name'>" +
                    //     name +
                    //     "</div><div class='roster-jid'>" +
                    //     jid +
                    //     "</div></div></li>";

                    // if ($('#' + jid_id).length > 0) {
                    //     $('#' + jid_id).replaceWith(contact_html);
                    // } else {
                    //     Gab.insert_contact(contact_html);
                    // }
                }
            })
        }
        // $(iq).find('item').each(function () {

        // });
        return true;
    }

    onMessage = (message) => {
        console.log("message =======>", message);
        const body = message.querySelector('body') ? message.querySelector('body').innerHTML : null;
        const type = message.getAttribute('type');
        const from = message.getAttribute('from');
        let fromName = ""

        // console.log("from=>",from);
        console.log("body ================>",body);

        if (type === 'groupchat') {
            var res = from.split("/");
            if (res.length > 1) {
                fromName = from.substring(
                    from.lastIndexOf("/") + 1,
                    from.lastIndexOf("\\")
                );
            }
        }

        if (body) {
            if (fromName.trim() !== this.currentUser.trim()) {
                this.recieveMessage(message);
            }
        } else {
            if (fromName.trim() !== this.currentUser.trim()) {
                var composing = message.querySelector('composing');
                if (composing) {
                    this.setState({ typingNotification: true });
                }

                var stopComposing = message.querySelector('stopComposing');
                if (stopComposing ) {
                    this.setState({ typingNotification: false });
                }
            }
        }

        return true;
    }

    insertContact = (elem) => {

    }

    handlePong = (ping) => {
        console.log("$chat =>Received ping from server in " + ping.getAttribute('from'));
        let from = ping.getAttribute('from');
        let id = ping.getAttribute('id');
        let iq = window.$iq({ type: 'result', to: from, id: id });
        this.connection.sendIQ(iq);
        return true;
    }

    //Experimental Method
    mucSendPresence = roomName => {
        //http://xmpp.org/extensions/xep-0045.html#createroom
        const presence = window.$pres({ to: roomName }).c('x', { 'xmlns': 'http://jabber.org/protocol/muc' });
        this.log(presence.tree());
        this.connection.send(presence.tree());
    }

    log = () => {
        //If not connected
        if (!this.connection) {
            console.log("Error, not connected`, please enter credentials:\n " +
                "Chat.connect('jid','password')");
        }
        if (this.debuggingMode) {
            // for (var i = 0; i < arguments.length; i++) {
            //     console.log(arguments[i]);
            // }
        }
    }

    mucJoin = (roomName, nickname, password) => {
        var nicknamenew = (nickname) ? nickname : this.getSubJID(this.connection.jid);
        this.connection.muc.join(roomName, nickname, this.messageReceived,
            this.presenceReceived, this.rosterReceived, password);
        this.mucSessionInfo['roomName'] = roomName;
        this.mucSessionInfo['nickname'] = nicknamenew;
    }

    mucLeave = exitMessage => {
        this.connection.muc.leave(
            this.mucSessionInfo['roomName'],
            this.mucSessionInfo['nickname'],
            this.presenceReceived,
            exitMessage
        );
    }

    getSubJID = Jid => {
        var Jid = (Jid) ? Jid : this.connection.Jid;
        var subJID = '';
        for (let i = 0; i < Jid.length; i++) {
            if (Jid[i] === '/') {
                if (this.connected)
                    this.log(Jid + " => " + subJID);
                return subJID;
            }
            subJID += Jid[i];
        }
        return subJID;
    }

    recieveMessage = (message) => {

        var fromID    =   this.getFromId(message.getAttribute('from')).replace('-cap-tek-com', '');
        var body      =   message.querySelector('body').innerHTML;

        if(body){
            console.log(`%c %s`, `color:aqua;font-size:50px;`, `onRecievrMessage`, fromID);
            this.onRecievrMessage(body, fromID);
        //     $('.msg_container_base').append('<div class="row msg_container base_receive"><div class="col-md-10 col-xs-10"><div class="messages msg_receive"><p>'+body+'</p></div></div></div>');
        //     $('.msg_container_base').animate({scrollTop: $('.msg_container_base').prop("scrollHeight")}, 500);
        //     $(".typing").hide();
        }
    }

    stopComposing = (jid) => {
        clearTimeout(this.typingTimer);
        const that = this;
        this.typingTimer     =   setTimeout(function() {
            var notify  =   window.$msg({to: jid+'@cap-tek.com', "type": "chat"})
                    .c('stopComposing', {xmlns: "http://jabber.org/protocol/chatstates"});
                that.connection.send(notify);
                that.typingValidation = false;
        },3000);
    }

    activeComposing = (jid) => {
        clearTimeout(this.typingTimer);
        // var composing = $(this).parent().data('composing');
        if (!this.typingValidation) {
            var notify = window.$msg({ to: jid + '@cap-tek.com', "type": "chat" })
                .c('composing', { xmlns: "http://jabber.org/protocol/chatstates" });
            this.connection.send(notify);
            // $(this).parent().data('composing', true);
            this.typingValidation = true;
        }
    }

    sendMessage = (jid, msg) => {
        var body = msg;
        if (body) {
            console.log(jid);
            console.log("==>" + body);
            var message = window.$msg({ to: jid + '@cap-tek.com', "type": "chat" })
                .c('body').t(body).up()
                .c('active', { xmlns: "http://jabber.org/protocol/chatstates" });
            this.connection.send(message);

            this.typingValidation = false;
        }
    }

    getFromId = (full_jid) =>{
        const jid         =   window.Strophe.getBareJidFromJid(full_jid);
        // console.log("jidToId ---------------- ",this.jidToId(jid));
        return  this.jidToId(jid);
    }

    getUserStatus = (status,userID) => {
        console.log("Status===>",status,userID);
        let newStatus = "offline";
        if(userID !== this.currentUser){
            if(status === "online" || status === "available"){
                console.log("=====================> online");
                newStatus = "online";
            }else if(status === "away"){
                console.log("=====================> away");
                newStatus = "away";
            }else if(status === "dnd" || status === "offline"){
                console.log("offline");
                newStatus = "offline";
            }
            let currentStatus = this.state.status;
            if(userID) {
                currentStatus[userID] = newStatus;
                console.log(`%c %s`, `font-size:50px; color:olive;`, `newStatus====`, currentStatus
                )
                this.setState({status: currentStatus}, () => {
                    console.log(`%c %s`, `color:red, font-size:50px;`,`NEW STATUS`,this.state.status);
                })
            }
        }
    }
}
