import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { initOptions } from "../conferencePanel/Options";
import {
    jitsiConnection,
} from "../conferencePanel/Connection";
import {
    createLocalTrack,
    jitsiRoom,
    manageRoomEvents,
} from "../conferencePanel/Recording";
import swal from "sweetalert";
import {
    ShowAlert,
    ShowLoader,
} from "../../store/actions/staticListingsActions";
import FitnessVideoPlayer from "./FitnessVideoPlayer";
import { RecordingListing } from "./../../store/actions/doctorPanelActions";
import { Modal, Button } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';

const videoStyle = {
    width: "100%",
    height: "100vh",
};

class FitnessAddVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            connection: null,
            isJoined: false,
            room: null,
            localTracks: [],
            remoteTracks: {},
            remoteUsersDetails: {},
            isAudioMuted: false,
            isVideoMuted: false,
            cameraDeviceId: "",
            micDeviceId: "",
            roomName: "testing",
            displayName: "Guest",
            userDisplayName: "Guest",
            JitsiMeetJS: null,
            openModal: false,
            message: "",
            isAudioOnly: false,
            duration: "00:00:00",
            showSupportButtons: false,
            recordingSessionId: null,
            isConferenceJoined: false,
            jibriUser: false,
            video: null,
            recordingLength: 0,
            videoTitle: "",
            recordingType: "video"
        };
        this.validator = new SimpleReactValidator();
        this.isRecordingStarted = false;
        this.durationInterval = null;
    }

    UNSAFE_componentWillMount() {
        const uniqueKey = JSON.parse(localStorage.data).uniquekey;
        uniqueKey
            ? this.setState(
                  {
                      roomName: `tll-${uniqueKey.toLowerCase()}`,
                      showTitleModal: true
                  })
            : this.props.history.push("/");
        this.props.ShowLoader(true);
    }

    componentDidMount() {
        window.onpopstate = function () {
            if (this.state.isJoined && this.state.connection) {
                this.leaveRoom();
            }
        }.bind(this);
    }

    leaveRoom = () => {
        try {
            this.state.localTracks.forEach((track, index) => {
                track.dispose();
            });
            this.state.room.leave().then(() => {
                this.state.connection.disconnect();
                this.props.history.push("/fitness/article-videos");
            })
            .catch(error => {
                console.log(error);
                this.props.history.push("/fitness/article-videos");
            });
        } catch (error) {
            this.props.history.push("/fitness/article-videos");
        }
    };

    handleBackButton = () => {
        this.leaveRoom();
    };

    handleClick = () => {
        const { connection, roomName } = this.state;
        let room = jitsiRoom(connection, roomName);
        // event.target.innerHTML = 'Please wait...';
        this.setState({ room }, () => {
            manageRoomEvents(this);
            this.props.ShowLoader(false);
        });
    };

    toggleRecording = () => {
        swal({
            title: `Do you want to ${
                this.state.recordingSessionId ? "Stop" : "start"
            } recording?`,
            // icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const appData = JSON.stringify({
                    file_recording_metadata: {
                        share: true,
                    },
                });
                const recordingOption = {
                    appData,
                    mode: "file",
                };

                if (!this.state.recordingSessionId) {
                    this.state.room
                        .startRecording(recordingOption)
                        .then((response) => {
                            Object.keys(response).forEach((key) => {
                                if (key === "_sessionID") {
                                    this.setState(
                                        {
                                            recordingSessionId:
                                                response._sessionID,
                                        },
                                        () => {
                                            console.log(
                                                "TOGGLE RECORDING STATE ============ ",
                                                response
                                            );
                                        }
                                    );
                                }
                            });
                        })
                        .catch(error => {
                            console.log(error);
                            swal("Recording error!", "Please try again later!", "error");
                        });
                } else {
                    const stoptRecording = this.state.room.stopRecording(
                        this.state.recordingSessionId
                    );
                    stoptRecording
                        .then((response) => {
                            console.log(
                                "RECORDING Response =========> ",
                                response
                            );
                            this.setState({ recordingSessionId: null });
                        })
                        .catch((error) => {
                            console.log("RECORDING ERROR =========> ", error);
                        });
                }
            }
        });
    };

    openVideoModal = async () => {
        const result = await this.props.RecordingListing(this.state.roomName);
        if (result > this.state.recordingLength) {
            this.setState({
                video: this.props.recording_listing[0],
                recordingLength: result,
            });
        } else {
            this.openVideoModal();
        }
    };

    onCloseModal = () => {
        this.setState({ video: null });
    };

    displayPermissionError = () => {
        swal("Permission error!", "Video needs to use your microphone and camera. Select Allow when your browser asks for permissions.", "error").then(() => {
            this.props.history.push("/fitness/article-videos");
        });
    };

    msToTimeString(ms) {
        let seconds = (ms / 1000) % 60;
        let minutes = Math.floor(ms / 1000 / 60) % 60;
        let hours = Math.floor(ms / 1000 / 60 / 60);

        seconds = ("0" + seconds).slice(-2);
        minutes = ("0" + minutes).slice(-2);
        hours = ("0" + hours).slice(-2);

        return `${hours}:${minutes}:${seconds}`;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.recordingSessionId && !this.state.jibriUser && (
                    <div
                        style={{
                            position: "absolute",
                            color: "White",
                            top: "50%",
                            left: "42%",
                        }}
                    >
                        Please wait. Recording starts within few seconds...
                    </div>
                )}
                {this.state.recordingSessionId && this.state.jibriUser && (
                    <div
                        style={{
                            position: "absolute",
                            color: "White",
                            top: "50%",
                            left: "48%",
                        }}
                    >
                        {this.state.duration}
                    </div>
                )}
                <div className="back-button-container">
                    {!this.state.recordingSessionId && this.state.videoTitle && this.state.room && (
                        <Link to="#" onMouseUp={this.handleBackButton}>
                            <i className="fas fa-arrow-circle-left"></i> Back{" "}
                        </Link>
                    )}
                </div>
                <div className="video-container">
                    <audio id="on-recording">
                        <source
                            src="/assets/audio/recordingOn.mp3"
                            type="audio/mp3"
                        />
                    </audio>
                    <audio id="off-recording">
                        <source
                            src="/assets/audio/recordingOff.mp3"
                            type="audio/mp3"
                        />
                    </audio>
                    <video
                        style={videoStyle}
                        id="localVideo"
                        autoPlay={true}
                    ></video>
                    <audio id="localAudio" autoPlay={true} muted={true}></audio>
                </div>
                <div className="calling-ico button-container">
                    {this.state.isConferenceJoined && (
                        <Link to="#" onClick={this.toggleRecording}>
                            <i
                                className={`far fa-dot-circle bottom-call-icon ${
                                    this.state.recordingSessionId
                                        ? "text-red"
                                        : ""
                                }`}
                            ></i>
                        </Link>
                    )}
                </div>
                <FitnessVideoPlayer
                    video={this.state.video}
                    showDeleteBtn={true}
                    onCloseModal={this.onCloseModal}
                />
                {this.renderTitleModal()}
            </React.Fragment>
        );
    }

    renderTitleModal = () => {
        return(
            <Modal
            show={this.state.showTitleModal}
            onHide={() => this.setTitleModalVisibility()}
            dialogClassName="modal-90w"
        >
            <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
                Video Title
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={this.onTitleFormSubmit}>
                <label htmlFor="video-title"></label>
                <input
                    type="text"
                    placeholder="Enter Your Video Title"
                    id="video-title"
                    className="form-control"
                    maxLength={50}
                    value={this.state.videoTitle}
                    onChange={(e) => this.setState({videoTitle: e.target.value})}
                />
                {this.validator.message('Title', this.state.videoTitle, 'required|max:50,string')}
            </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onMouseUp={this.onTitleFormSubmit} >Submit</Button>
            </Modal.Footer>
        </Modal>
        )
    }

    onTitleFormSubmit = (event) => {
        event.preventDefault();
        const validator = this.validator;
        const {videoTitle } = this.state;
        if (validator.allValid()) {
            const titleStr = (videoTitle.replace(/ /g, '-')).toLowerCase();
            this.setState({roomName: `${this.state.roomName}-${titleStr}`}, async () => {
                let result = await this.props.RecordingListing(
                    this.state.roomName
                );
                this.setState({ recordingLength: result });
                this.setTitleModalVisibility();
                const checkJitsiLoadInterval = setInterval(() => {
                    if (window.JitsiMeetJS) {
                        window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.ERROR);
                        window.JitsiMeetJS.init(initOptions);
                        this.setState(
                            {
                                connection: jitsiConnection(window.JitsiMeetJS),
                                JitsiMeetJS: window.JitsiMeetJS,
                            },
                            () => {
                                createLocalTrack(this);
                                this.state.connection.addEventListener(
                                    this.state.JitsiMeetJS.events.connection
                                        .CONNECTION_ESTABLISHED,
                                    () => {
                                        this.handleClick();
                                    }
                                );
                            }
                        );
                        clearInterval(checkJitsiLoadInterval);
                    }
                }, 100);
            })
		} else {
            validator.showMessages();
            this.forceUpdate();
		}

    }

    setTitleModalVisibility = () => {
        this.setState({showTitleModal: false})
    }
}

const mapStateFromProps = (state) => ({
    recording_listing: state.doctorPanel.recording_listing,
});

export default connect(mapStateFromProps, { RecordingListing, ShowLoader, ShowAlert })(
    FitnessAddVideo
);
