export const FETCH_DATA_BEGIN                           =   'FETCH_DATA_BEGIN';
export const SHOW_LOADER                                =   'SHOW_LOADER';
export const SHOW_ALERT                                 =   'SHOW_ALERT';
export const COUNTRIES_LIST                             =   'COUNTRIES_LIST';
export const CITIES_LIST                                =   'CITIES_LIST';
export const STATES_LIST                                =   'STATES_LIST';
export const SPECIALIZATION_LIST                        =   'SPECIALIZATION_LIST';
export const LANGUAGES_LIST                             =   'LANGUAGES_LIST';
export const FETCH_COUNTRY_LISTING                      =   'FETCH_COUNTRY_LISTING';
export const FETCH_LOCATION                             =   'FETCH_LOCATION';
export const GOOGLE_MAP_ADDRESS                         =   'GOOGLE_MAP_ADDRESS';
export const ADMIN_DOCTOR_LISTING                       =   'ADMIN_DOCTOR_LISTING';
export const ADMIN_PATIENT_LISTING                      =   'ADMIN_PATIENT_LISTING';
export const ADMIN_LAB_LISTING                          =   'ADMIN_LAB_LISTING';
export const ADMIN_PHARMACY_LISTING                     =   'ADMIN_PHARMACY_LISTING';
export const ADMIN_HOSPITAL_LISTING                     =   'ADMIN_HOSPITAL_LISTING';
export const FETCH_MEDICAL_COUNCILS                     =   'FETCH_MEDICAL_COUNCILS';
export const GOOGLE_MAP_CLINIC                          =   'GOOGLE_MAP_CLINIC';
export const GOOGLE_MAP_CLINIC_ADDRESS                  =   'GOOGLE_MAP_CLINIC_ADDRESS';
export const ADMIN_SPEICALISATION_LISTING               =   'ADMIN_SPEICALISATION_LISTING';
export const SUGGESTED_CITIES                           =   'SUGGESTED_CITIES';
export const SUGGESTED_CLINIC_CITIES                    =   'SUGGESTED_CLINIC_CITIES';
export const SUGGESTED_STATES                           =   'SUGGESTED_STATES';
export const SUGGESTED_COUNTRIES                        =   'SUGGESTED_COUNTRIES';
export const FETCH_PROFILE_INFO                         =   'FETCH_PROFILE_INFO';
export const FETCH_ADMIN_PROFILE_INFO                   =   'FETCH_ADMIN_PROFILE_INFO';
export const FETCH_UNQIUE_ID                            =   'FETCH_UNQIUE_ID';
export const ADMIN_QUERY_LISTING                        =   'ADMIN_QUERY_LISTING'
export const USER_DETAIL_FOR_CHAT                       =   'USER_DETAIL_FOR_CHAT'
export const FETCH_SCHEDULED_TIMING                     =   'FETCH_SCHEDULED_TIMING'
export const FETCH_DOCTOR_LISTING                       =   'FETCH_DOCTOR_LISTING'
export const DOCTOR_PROFILE_INFO                        =   'DOCTOR_PROFILE_INFO'
export const AUTH_LOGIN                                 =   'AUTH_LOGIN'
export const SUPPORT_CHAT_HISTORY                       =   'SUPPORT_CHAT_HISTORY'
export const REVIEWS_LIST                               =   'REVIEWS_LIST'
export const CHECK_USER_LOGIN                           =   'CHECK_USER_LOGIN'
export const FETCH_APPOINTMENT_LISTING                  =   'FETCH_APPOINTMENT_LISTING'
export const FETCH_APPOINTMENT_CHECKOUT                 =   'FETCH_APPOINTMENT_CHECKOUT'
export const PATIENT_APPOINTMENT_LIST                   =   'PATIENT_APPOINTMENT_LIST'
export const DOCTOR_PATIENT_APPOINTMENT_LIST            =   'DOCTOR_PATIENT_APPOINTMENT_LIST'
export const PRESCRIPTIONS_LIST                         =   'PRESCRIPTIONS_LIST'
export const MEDICAL_RECORDS_LIST                       =   'MEDICAL_RECORDS_LIST'
export const WEEKLY_APPOINTMENT_LIST                    =   'WEEKLY_APPOINTMENT_LIST'
export const DOCTOR_SEARCH_INFO                         =   'DOCTOR_SEARCH_INFO'
export const TIMEZONE_LIST                              =   'TIMEZONE_LIST'
export const DOCTOR_PATIENTS                            =   'DOCTOR_PATIENTS'
export const PATIENT_OF_DOCTOR                          =   'PATIENT_OF_DOCTOR'
export const ARTICLE_LISTING                            =   'ARTICLE_LISTING'
export const RECORDING_LISTING                          =   'RECORDING_LISTING'
export const ADMIN_ARTICLE_LISTING                      =   'ADMIN_ARTICLE_LISTING'
export const ADMIN_REVIEWS                              =   'ADMIN_REVIEWS'
export const DOCTOR_REVIEWS                             =   'DOCTOR_REVIEWS'
export const ADMIN_SUBSCRIPTION_CHARGE_LIST             =   'ADMIN_SUBSCRIPTION_CHARGE_LIST'
export const LOCATION_LIST                              =   'LOCATION_LIST'
export const CHARGES_TO_DOCTOR                          =   'CHARGES_TO_DOCTOR'
export const FETCH_AREA_PRICE                           =   'FETCH_AREA_PRICE'
export const FETCH_LAB_LISTING                          =   'FETCH_LAB_LISTING'
export const FETCH_TODAY_APPOINTMENT_LISTING            =   'FETCH_TODAY_APPOINTMENT_LISTING'
export const LAB_PROFILE_INFO                           =   'LAB_PROFILE_INFO'
export const FETCH_BILL_LISTING                         =   'FETCH_BILL_LISTING'
export const WEEKLY_LAB_APPOINTMENT_LIST                =   'WEEKLY_LAB_APPOINTMENT_LIST'
export const PATIENT_LAB_APPOINTMENT_LIST               =   'PATIENT_LAB_APPOINTMENT_LIST'
export const FETCH_PATIENT_INVOICES                     =   'FETCH_PATIENT_INVOICES'
export const FETCH_LAB_APPOINTMENT_LISTING              =   'FETCH_LAB_APPOINTMENT_LISTING'
export const ADMIN_INVOICE_LISTING                      =   'ADMIN_INVOICE_LISTING'
export const QUERY                                      =   'QUERY'
export const FETCH_HEADER_FOOTER_IMAGE                  =   'FETCH_HEADER_FOOTER_IMAGE'
export const ADMIN_GLOBAL_SEARCH                        =   'ADMIN_GLOBAL_SEARCH';
export const DR_APPOINTMENT_DETAILS                     =   'DR_APPOINTMENT_DETAILS'
export const VIEW_SOCIAL_MEDIA                          =   'VIEW_SOCIAL_MEDIA'
export const FETCH_DOCTOR_ID                            =   'FETCH_DOCTOR_ID'
export const VIEW_HOME_ARTICLE                          =   'VIEW_HOME_ARTICLE'
export const FIREBASE_LOGIN_TOKEN                       =   'FIREBASE_LOGIN_TOKEN';
export const FETCH_AUTO_SUGGESTED_LOCATION              =    'FETCH_AUTO_SUGGESTED_LOCATION'
export const COUNSELLOR_TYPE_LIST                       =   'COUNSELLOR_TYPE_LIST';
export const ADMIN_COUNSELLOR_LISTING                   =   'ADMIN_COUNSELLOR_LISTING'
export const FETCH_COUNSELLOR_LISTING                   =   'FETCH_COUNSELLOR_LISTING'
export const FETCH_SUBTYPE_LISTING                      =   'FETCH_SUBTYPE_LISTING'
export const COUNSELLOR_PROFILE_INFO                    =   'COUNSELLOR_PROFILE_INFO'
export const STORE_LOCATION                             =   'STORE_LOCATION'
export const COUNSELOR_REVIEWS                          =   'COUNSELOR_REVIEWS'
export const COUNSELOR_PATIENTS                         =   'COUNSELOR_PATIENTS'
export const COUNSELLOR_REVIEWS_LIST                    =   'COUNSELLOR_REVIEWS_LIST'
export const COUNSELLOR_ARTICLE_LISTING                 =   'COUNSELLOR_ARTICLE_LISTING'
export const SELECTED_COUNSELLOR_PROFILE_INFO           =   'SELECTED_COUNSELLOR_PROFILE_INFO'
export const PATIENT_OF_COUNSELLOR                      =   'PATIENT_OF_COUNSELLOR'
export const COUNSELLOR_PATIENT_APPOINTMENT_LIST        =   'COUNSELLOR_PATIENT_APPOINTMENT_LIST'
export const COUNSELLOR_PRESCRIPTIONS_LIST              =   'COUNSELLOR_PRESCRIPTIONS_LIST'
export const COUNSELLOR_WEEKLY_APPOINTMENT_LIST         =   'COUNSELLOR_WEEKLY_APPOINTMENT_LIST'
export const COUNSELLOR_APPOINTMENT_DETAILS             =   'COUNSELLOR_APPOINTMENT_DETAILS'
export const FETCH_TODAY_COUNSELLOR_APPOINTMENT_LISTING =   'FETCH_TODAY_COUNSELLOR_APPOINTMENT_LISTING'
export const VIEW_SOCIAL_MEDIA_COUNSELLOR               =   'VIEW_SOCIAL_MEDIA_COUNSELLOR'
export const COUNSELLOR_MEDICAL_RECORDS_LIST            =   'COUNSELLOR_MEDICAL_RECORDS_LIST'
export const FETCH_FITNESSEXPERT_LISTING                =   'FETCH_FITNESSEXPERT_LISTING'
export const FITNESS_WEEKLY_APPOINTMENT_LIST            =   'FITNESS_WEEKLY_APPOINTMENT_LIST'
export const PATIENT_COUNSELLOR_APPOINTMENT_LIST        =   'PATIENT_COUNSELLOR_APPOINTMENT_LIST'
export const COUNSELLOR_APPOINTMENT_LISTING             =   'COUNSELLOR_APPOINTMENT_LISTING'
export const FETCH_DOCTOR_COUNSELLOR_LISTING            =   'FETCH_DOCTOR_COUNSELLOR_LISTING'
export const FITNESS_EXPERT_PROFILE_INFO                =   'FITNESS_EXPERT_PROFILE_INFO'
export const FITNESS_APPOINTMENT_LISTING                =   'FITNESS_APPOINTMENT_LISTING'
export const SELECTED_FITNESS_EXPERT_PROFILE_INFO       =   'SELECTED_FITNESS_EXPERT_PROFILE_INFO'
export const FETCH_CATEGORY_LISTING                     =   'FETCH_CATEGORY_LISTING'
export const FETCH_SUBCATEGORY_LISTING                  =   'FETCH_SUBCATEGORY_LISTING'
export const FITNESS_ARTICLE_LISTING                    =   'FITNESS_ARTICLE_LISTING'
export const FITNESS_RECORDING_LISTING                  =   'FITNESS_RECORDING_LISTING'
export const FETCH_PRODUCT_LISTING                      =   'FETCH_PRODUCT_LISTING'
export const VIEW_SOCIAL_MEDIA_FITNESS                  =   'VIEW_SOCIAL_MEDIA_FITNESS'
export const FITNESS_REVIEWS                            =   'FITNESS_REVIEWS'
export const FITNESS_PATIENT_APPOINTMENT_LIST           =   'FITNESS_PATIENT_APPOINTMENT_LIST'
export const PATIENT_OF_FITNESS_EXPERT                  =   'PATIENT_OF_FITNESS_EXPERT'
export const FETCH_PRODUCT_IMAGES_LISTING               =   'FETCH_PRODUCT_IMAGES_LISTING'
export const FITNESS_MEDICAL_RECORDS_LIST               =   'FITNESS_MEDICAL_RECORDS_LIST'
export const FETCH_GLOBAL_PRODUCT_LIST                  =   'FETCH_GLOBAL_PRODUCT_LIST'
export const FETCH_VIEW_PRODUCT                         =   'FETCH_VIEW_PRODUCT'
export const PRODUCT_REVIEWS_LIST                       =   'PRODUCT_REVIEWS_LIST'
export const FITNESS_APPOINTMENT_DETAILS                =   'FITNESS_APPOINTMENT_DETAILS'
export const FETCH_TODAY_FITNESS_APPOINTMENT_LISTING    =   'FETCH_TODAY_FITNESS_APPOINTMENT_LISTING'
export const FETCH_PRODUCT_CART_DETAILS                 =   'FETCH_PRODUCT_CART_DETAILS'
export const OPEN_PRODUCT_MODAL                         =   'OPEN_PRODUCT_MODAL'
export const PATIENT_FITNESS_APPOINTMENT_LIST           =   'PATIENT_FITNESS_APPOINTMENT_LIST'
export const PRODUCT_TOTAL_AMOUNT                       =   'PRODUCT_TOTAL_AMOUNT'
export const FETCH_CHECKOUT_DETAILS                     =   'FETCH_CHECKOUT_DETAILS'
export const FORCE_LOGIN_CLICK                          =   'FORCE_LOGIN_CLICK'
export const FITNESS_CLASS_LISTING                      =   'FITNESS_CLASS_LISTING'
export const VIEW_FITNESS_CLASS                         =   'VIEW_FITNESS_CLASS'
export const FETCH_GLOBAL_FITNESS_CLASS_LIST            =   'FETCH_GLOBAL_FITNESS_CLASS_LIST'
export const GLOBAL_VIEW_FITNESS_CLASS                  =   'GLOBAL_VIEW_FITNESS_CLASS'
export const FETCH_BOOKING_CLASS_CHECKOUT               =   'FETCH_BOOKING_CLASS_CHECKOUT'
export const FETCH_TOTAL_COUNT                          =   'FETCH_TOTAL_COUNT'
export const FETCH_ADMIN_FITNESS_CLASSES                =   'FETCH_ADMIN_FITNESS_CLASSES'
export const NEARBY_LOCATIONS                           =   'NEARBY_LOCATIONS'
export const NEARBY_DOCTOR_COUNCIL                      =   'NEARBY_DOCTOR_COUNCIL'
export const SEARCHED_LOCATIONS                         =   'SEARCHED_LOCATIONS'
export const SEARCHED_DOCTOR_COUNCIL                    =   'SEARCHED_DOCTOR_COUNCIL'
export const OPEN_REVIEW_TAB                            =   "OPEN_REVIEW_TAB"
