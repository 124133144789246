// @flow
import * as React from 'react';
import { Link } from 'react-router-dom'; 
import {
  ArticleListing,
  DeleteArticle,
} from './../../store/actions/counselorPanelActions';
import Pagination from './../../store/helpers/Pagination';
import { connect } from 'react-redux';
import swal from 'sweetalert';

class CounsellorArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1 };
  }

  UNSAFE_componentWillMount() {
    let data = localStorage.getItem('data');
    if (data) {
      let uniqueID = JSON.parse(data).uniquekey;
      this.props.dispatch(ArticleListing(uniqueID));
    }
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  deleteRecord = (articleID) => {
    let data = localStorage.getItem('data');
    let uniqueID = JSON.parse(data).uniquekey;
    swal({
      title: 'Are you sure want to delete this?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(
          DeleteArticle({ id: articleID, uniquekey: uniqueID })
        );
      } else {
        console.log('Your file is safe!');
      }
    });
  };

  render() {
    const { currentPage } = this.state;
    const { article_listing } = this.props;
    return (
      <>
        <div className='text-right'>
          <Link className='add-new-btn' to='/counsellor/add-article'>
            Add Article
          </Link>
        </div>
        <div className='table-responsive'>
          <table className='datatable table table-hover table-center mb-0'>
            <thead>
              <tr>
                <th>S No</th>
                <th>Title</th> 
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.pageOfItems.length > 0 ? (
                this.state.pageOfItems.map((val, i) => (
                  <tr key={i}>
                    <td>{i + 1 + currentPage * i}</td>
                    <td>{val.title}</td> 
                    <td>
                      <div className='table-action'>
                        <Link
                          className='btn btn-sm bg-success-light'
                          to={`/counsellor/view-article/${val.uniquekey}`}
                        >
                          <i className='far fa-eye'></i>&nbsp;View
                        </Link>
                        <Link
                          className='btn btn-sm bg-info-light'
                          to={`/counsellor/edit-article/${val.uniquekey}`}
                        >
                          <i className='far fa-edit'></i>&nbsp;Edit
                        </Link>
                        <Link
                          className='btn btn-sm bg-danger-light'
                          to='#'
                          onClick={(e) => this.deleteRecord(val.uniquekey)}
                        >
                          <i className='fas fa-remove'></i>&nbsp;Delete
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='4'>No records available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='container-fluid no-padding'>
          <div className='row pull-right'>
            <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
              &nbsp;
              {article_listing && (
                <Pagination
                  pageSize={20}
                  items={article_listing}
                  onChangePage={this.onChangePage}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateFromProps = (state) => ({
  article_listing: state.counselorPanel.counsellor_article_list,
});
export default connect(mapStateFromProps)(CounsellorArticle);
