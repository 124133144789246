import { confOptions } from './Options';
let recordingComponent = {};

/**
 * For local track
 * @param {object} component  //JITSI OBJECT
 */
export const createLocalTrack = (component) => {
    recordingComponent = component;
    let { JitsiMeetJS, isAudioOnly } = component.state;
    let devices = isAudioOnly ? ['audio'] : ['audio', 'video'];

    JitsiMeetJS.createLocalTracks({ devices })
        .then(onLocalTracks)
        .catch(error => {
            console.log("createLocalTracks ERROR------------", error);
            // throw error;
            Promise.all([
                createAudioTrack(JitsiMeetJS).then(onLocalTracks),
                createVideoTrack(JitsiMeetJS).then(onLocalTracks)
            ]).then(() => {
                // checkDeviceChangeAvailability();
            });
        });
}

function createAudioTrack(JitsiMeetJS) {
    return (
        JitsiMeetJS.createLocalTracks({ devices: ['audio'] })
            .catch(err => {
                console.log("Audio Track Error -- ", err);
                recordingComponent.displayPermissionError();
                return [];
            }));
}

function createVideoTrack(JitsiMeetJS) {
    return (
        JitsiMeetJS.createLocalTracks({ devices: ['video'] })
            .catch(err => {
                console.log("Video Track Error -- ", err);
                recordingComponent.displayPermissionError();
                return [];
            }));
}

/**
 * Handles local tracks.
 * @param tracks Array with JitsiTrack objects
 */
function onLocalTracks(tracks) {
    // checkDeviceChangeAvailability();
    let { localTracks } = recordingComponent.state;
    localTracks.length < 1
        ? recordingComponent.setState({ localTracks: tracks }, () => manageLocalTrack())
        : recordingComponent.setState({ localTracks: [...localTracks] }, () => manageLocalTrack());
}

function manageLocalTrack() {
    let { localTracks, JitsiMeetJS, isJoined, room } = recordingComponent.state;

    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, audioLevel => console.log(`Audio Level local: ${audioLevel}`));
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log('local track muted'));
        localTracks[i].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log('local track stoped'));
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, deviceId => console.log(`track audio output device was changed to ${deviceId}`));


        if (localTracks[i].getType() === 'video') {
            localTracks[i].attach(document.querySelector(`#localVideo`));
        } else {
            localTracks[i].attach(document.querySelector(`#localAudio`));
        }
        if (isJoined) {
            room.addTrack(localTracks[i]);
        }
        if (localTracks.length >= 1) {
            localTracks.forEach((track) => {
                if (track.type === "audio") {
                    recordingComponent.setState({ micDeviceId: track.getDeviceId() });
                } else {
                    recordingComponent.setState({ cameraDeviceId: track.getDeviceId() });
                }
            });
        }
    }
}

/**
 * For room
 * @param {object} JitsiMeetJS
 * @param {object} connection
 * @param {string} roomName
 */
export const jitsiRoom = (connection, roomName) => {
    let room = connection.initJitsiConference(roomName, confOptions);
    room.join();
    return room;
}

export const manageRoomEvents = (component) => {
    recordingComponent = component;
    let { room, JitsiMeetJS} = component.state;

    room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
    room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
        console.log(`track removed!!!${track}`);
    });
    room.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
    room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
        console.log(`TRACK_MUTE_CHANGED =========> ${track.getType()} - ${track.isMuted()}`);
        // alert(`${track.getType()} - ${track.isMuted()}`);
    });
    room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
    room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, (userID, displayName) => {
        console.log("DISPLAY_NAME_CHANGED", `${userID} - ${displayName}`);
    });
    room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`));
}

/**
 * That function is executed when the conference is joined
 */
function onConferenceJoined() {
    let { localTracks, room } = recordingComponent.state;
    recordingComponent.setState({ 'isJoined': true });
    for (let i = 0; i < localTracks.length; i++) {
        room.addTrack(localTracks[i]);
    }
    if(room.myUserId()) {
        recordingComponent.setState({ isConferenceJoined: true });
    }
    console.log('room.options.name ===================> ', room.options.name);
}

/**
 * Handle user on join room
 * @param {string} id
 * @param {object} user
 */
function onUserJoined(id, user) {
    console.log("USER JOINED ===================> ", id, user);
    let userType = null;
    let { remoteTracks, recordingType } = recordingComponent.state;
    Object.keys(user).forEach(key => {
        if( key === "_statsID" && user[key] === 'jibri') {
            userType = user[key];
        }
    });
    if(userType) {
        remoteTracks[id] = 'jibri';
        let timer = 0;
        recordingComponent.setState({ remoteTracks: remoteTracks, jibriUser: true }, () => {
            console.log("Recording is On =================");
            if(recordingType === "stream") {
                document.getElementById('on-streaming').play();
            } else{
                document.getElementById('on-recording').play();
            }
            recordingComponent.durationInterval = setInterval(() => {
                timer += 1000;
                recordingComponent.setState({ duration: recordingComponent.msToTimeString(timer) });
            }, 1000);
        });
    }
}

/**
 *
 * @param id
 */
function onUserLeft(id) {
    console.log('user left');
    let { remoteTracks, recordingType} = recordingComponent.state;

    if (!remoteTracks[id]) {
        return;
    } else if(remoteTracks[id] === 'jibri') {
        recordingComponent.setState({remoteTracks: {}, jibriUser: false}, () => {
            console.log("Recording is On =================");
            clearInterval(recordingComponent.durationInterval);
            recordingComponent.setState({ duration: "00:00:00" });
            if(recordingType === "stream") {
                document.getElementById('off-streaming').play();
            } else {
                document.getElementById('off-recording').play();
                recordingComponent.openVideoModal();
            }
        })
        return;
    }
}
