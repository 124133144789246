import { apiBaseUrl } from "./../helpers/common";
import * as actionTypes from "./../types/types";
import {ShowAlert } from "./staticListingsActions";
import { handleResponse } from "./../helpers/userServices";
// import toastr from "reactjs-toastr";
// import "reactjs-toastr/lib/toast.css";

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN,
    };
};

export function mapAddress(data) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GOOGLE_MAP_ADDRESS, payload: data });
    };
}

export function clinicNumber(data) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GOOGLE_MAP_CLINIC, payload: data });
    };
}

export function mapClinicAddress(data) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GOOGLE_MAP_CLINIC_ADDRESS,
            payload: data,
        });
    };
}

export function SuggestLocation(location) {
    return (dispatch) => {
        // dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/suggest-locations`, {
            method: "POST",
            body: JSON.stringify(location),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch({
                        type: actionTypes.LOCATION_LIST,
                        payload: data.data,
                    });
                    return { code: 1, response: data.data };
                } else {
                    dispatch(
                        ShowAlert({
                            Class: "alert-danger",
                            Message: data.ResponseText,
                            Timeout: 10000,
                        })
                    );
                    return 0;
                }
            });
    };
}
