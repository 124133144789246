import React from 'react'
import { connect } from 'react-redux'
import {Link } from 'react-router-dom'

class PatientDashboard extends React.Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }


    render () {
        return (
            <div>
                {/* <div className="breadcrumb-bar">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-12 col-12">
								<h2 className="breadcrumb-title">Dashboard</h2>
							</div>
						</div>
					</div>
				</div> */}
                <div className="card">
								<div className="card-body pt-0">
									<nav className="user-tabs mb-4">
										<ul className="nav nav-tabs nav-tabs-bottom nav-justified">
											<li className="nav-item">
												<Link className="nav-link active" to="#pat_appointments" data-toggle="tab">Appointments</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="#pat_prescriptions" data-toggle="tab">Prescriptions</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="#pat_medical_records" data-toggle="tab"><span className="med-records">Medical Records</span></Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="#pat_billing" data-toggle="tab">Billing</Link>
											</li>
										</ul>
									</nav>
									<div className="tab-content pt-0">
										<div id="pat_appointments" className="tab-pane fade show active">
											<div className="card card-table mb-0">
												<div className="card-body">
													<div className="table-responsive">
														<table className="table table-hover table-center mb-0">
															<thead>
																<tr>
																	<th>Doctor</th>
																	<th>Appt Date</th>
																	<th>Booking Date</th>
																	<th>Amount</th>
																	<th>Follow Up</th>
																	<th>Status</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>14 Nov 2019 <span className="d-block text-info">10.00 AM</span></td>
																	<td>12 Nov 2019</td>
																	<td>$160</td>
																	<td>16 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-02.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Darren Elder <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>12 Nov 2019 <span className="d-block text-info">8.00 PM</span></td>
																	<td>12 Nov 2019</td>
																	<td>$250</td>
																	<td>14 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-03.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Deborah Angel <span>Cardiology</span></Link>
																		</h2>
																	</td>
																	<td>11 Nov 2019 <span className="d-block text-info">11.00 AM</span></td>
																	<td>10 Nov 2019</td>
																	<td>$400</td>
																	<td>13 Nov 2019</td>
																	<td><span className="badge badge-pill bg-danger-light">Cancelled</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-04.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Sofia Brient <span>Urology</span></Link>
																		</h2>
																	</td>
																	<td>10 Nov 2019 <span className="d-block text-info">3.00 PM</span></td>
																	<td>10 Nov 2019</td>
																	<td>$350</td>
																	<td>12 Nov 2019</td>
																	<td><span className="badge badge-pill bg-warning-light">Pending</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-05.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Marvin Campbell <span>Ophthalmology</span></Link>
																		</h2>
																	</td>
																	<td>9 Nov 2019 <span className="d-block text-info">7.00 PM</span></td>
																	<td>8 Nov 2019</td>
																	<td>$75</td>
																	<td>11 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-06.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Katharine Berthold <span>Orthopaedics</span></Link>
																		</h2>
																	</td>
																	<td>8 Nov 2019 <span className="d-block text-info">9.00 AM</span></td>
																	<td>6 Nov 2019</td>
																	<td>$175</td>
																	<td>10 Nov 2019</td>
																	<td><span className="badge badge-pill bg-danger-light">Cancelled</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-07.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Linda Tobin <span>Neurology</span></Link>
																		</h2>
																	</td>
																	<td>8 Nov 2019 <span className="d-block text-info">6.00 PM</span></td>
																	<td>6 Nov 2019</td>
																	<td>$450</td>
																	<td>10 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-08.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Paul Richard <span>Dermatology</span></Link>
																		</h2>
																	</td>
																	<td>7 Nov 2019 <span className="d-block text-info">9.00 PM</span></td>
																	<td>7 Nov 2019</td>
																	<td>$275</td>
																	<td>9 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-09.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. John Gibbs <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>6 Nov 2019 <span className="d-block text-info">8.00 PM</span></td>
																	<td>4 Nov 2019</td>
																	<td>$600</td>
																	<td>8 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-10.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Olga Barlow  <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>5 Nov 2019 <span className="d-block text-info">5.00 PM</span></td>
																	<td>1 Nov 2019</td>
																	<td>$100</td>
																	<td>7 Nov 2019</td>
																	<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="pat_prescriptions">
											<div className="card card-table mb-0">
												<div className="card-body">
													<div className="table-responsive">
														<table className="table table-hover table-center mb-0">
															<thead>
																<tr>
																	<th>Date </th>
																	<th>Name</th>
																	<th>Created by </th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>14 Nov 2019</td>
																	<td>Prescription 1</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>13 Nov 2019</td>
																	<td>Prescription 2</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-02.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Darren Elder <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>12 Nov 2019</td>
																	<td>Prescription 3</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-03.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Deborah Angel <span>Cardiology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>11 Nov 2019</td>
																	<td>Prescription 4</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-04.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Sofia Brient <span>Urology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>10 Nov 2019</td>
																	<td>Prescription 5</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-05.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Marvin Campbell <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>9 Nov 2019</td>
																	<td>Prescription 6</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-06.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Katharine Berthold <span>Orthopaedics</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>8 Nov 2019</td>
																	<td>Prescription 7</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-07.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Linda Tobin <span>Neurology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>7 Nov 2019</td>
																	<td>Prescription 8</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-08.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Paul Richard <span>Dermatology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>6 Nov 2019</td>
																	<td>Prescription 9</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-09.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. John Gibbs <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>5 Nov 2019</td>
																	<td>Prescription 10</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-10.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Olga Barlow <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div id="pat_medical_records" className="tab-pane fade">
											<div className="card card-table mb-0">
												<div className="card-body">
													<div className="table-responsive">
														<table className="table table-hover table-center mb-0">
															<thead>
																<tr>
																	<th>ID</th>
																	<th>Date </th>
																	<th>Description</th>
																	<th>Attachment</th>
																	<th>Created</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td><Link to="#">#MR-0010</Link></td>
																	<td>14 Nov 2019</td>
																	<td>Dental Filling</td>
																	<td><Link to="#">dental-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0009</Link></td>
																	<td>13 Nov 2019</td>
																	<td>Teeth Cleaning</td>
																	<td><Link to="#">dental-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-02.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Darren Elder <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0008</Link></td>
																	<td>12 Nov 2019</td>
																	<td>General Checkup</td>
																	<td><Link to="#">cardio-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-03.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Deborah Angel <span>Cardiology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0007</Link></td>
																	<td>11 Nov 2019</td>
																	<td>General Test</td>
																	<td><Link to="#">general-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-04.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Sofia Brient <span>Urology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0006</Link></td>
																	<td>10 Nov 2019</td>
																	<td>Eye Test</td>
																	<td><Link to="#">eye-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-05.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Marvin Campbell <span>Ophthalmology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0005</Link></td>
																	<td>9 Nov 2019</td>
																	<td>Leg Pain</td>
																	<td><Link to="#">ortho-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-06.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Katharine Berthold <span>Orthopaedics</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0004</Link></td>
																	<td>8 Nov 2019</td>
																	<td>Head pain</td>
																	<td><Link to="#">neuro-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-07.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Linda Tobin <span>Neurology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0003</Link></td>
																	<td>7 Nov 2019</td>
																	<td>Skin Alergy</td>
																	<td><Link to="#">alergy-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-08.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Paul Richard <span>Dermatology</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0002</Link></td>
																	<td>6 Nov 2019</td>
																	<td>Dental Removing</td>
																	<td><Link to="#">dental-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-09.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. John Gibbs <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td><Link to="#">#MR-0001</Link></td>
																	<td>5 Nov 2019</td>
																	<td>Dental Filling</td>
																	<td><Link to="#">dental-test.pdf</Link></td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-10.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Olga Barlow <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="#" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div id="pat_billing" className="tab-pane fade">
											<div className="card card-table mb-0">
												<div className="card-body">
													<div className="table-responsive">
														<table className="table table-hover table-center mb-0">
															<thead>
																<tr>
																	<th>Invoice No</th>
																	<th>Doctor</th>
																	<th>Amount</th>
																	<th>Paid On</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0010</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Ruby Perrin <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>$450</td>
																	<td>14 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0009</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-02.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Darren Elder <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>$300</td>
																	<td>13 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0008</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-03.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Deborah Angel <span>Cardiology</span></Link>
																		</h2>
																	</td>
																	<td>$150</td>
																	<td>12 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0007</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-04.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Sofia Brient <span>Urology</span></Link>
																		</h2>
																	</td>
																	<td>$50</td>
																	<td>11 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0006</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-05.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Marvin Campbell <span>Ophthalmology</span></Link>
																		</h2>
																	</td>
																	<td>$600</td>
																	<td>10 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0005</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-06.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Katharine Berthold <span>Orthopaedics</span></Link>
																		</h2>
																	</td>
																	<td>$200</td>
																	<td>9 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0004</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-07.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Linda Tobin <span>Neurology</span></Link>
																		</h2>
																	</td>
																	<td>$100</td>
																	<td>8 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0003</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-08.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Paul Richard <span>Dermatology</span></Link>
																		</h2>
																	</td>
																	<td>$250</td>
																	<td>7 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0002</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-09.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. John Gibbs <span>Dental</span></Link>
																		</h2>
																	</td>
																	<td>$175</td>
																	<td>6 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<Link to="invoice-view.html">#INV-0001</Link>
																	</td>
																	<td>
																		<h2 className="table-avatar">
																			<Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
																				<img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-10.jpg" alt="User" />
																			</Link>
																			<Link to="doctor-profile.html">Dr. Olga Barlow <span>#0010</span></Link>
																		</h2>
																	</td>
																	<td>$550</td>
																	<td>5 Nov 2019</td>
																	<td className="text-right">
																		<div className="table-action">
																			<Link to="invoice-view.html" className="btn btn-sm bg-info-light">
																				<i className="far fa-eye"></i> View
																			</Link>
																			<Link to="#" className="btn btn-sm bg-primary-light">
																				<i className="fas fa-print"></i> Print
																			</Link>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>

									</div>

								</div>
							</div>




						</div>
                )
            }
}
const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(PatientDashboard);
