import * as types from "./../types/types";

const initalState = {
    patient_listing:[],
    appointment_listing:[],
    article_listing:[],
    recording_listing: [],
    doctor_reviews: [],
    invoice_listing: [],
    viewImage : [],
    doctor_id:''
}

const doctorPanelReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.DOCTOR_PATIENTS:
            return {
                ...state,
                patient_listing:action.payload
            }
        case types.PATIENT_OF_DOCTOR:
            return {
                ...state,
                patient_listing:action.payload
            }
        case types.DOCTOR_PATIENT_APPOINTMENT_LIST:
            return {
                ...state,
                appointment_listing:action.payload
            }
        case types.ARTICLE_LISTING:
            return {
                ...state,
                article_listing:action.payload
            }
        case types.RECORDING_LISTING:
            return {
                ...state,
                recording_listing:action.payload
            }
        case types.DOCTOR_REVIEWS:
            return {
                ...state,
                doctor_reviews:action.payload
            }
        case types.FETCH_PATIENT_INVOICES:
            return {
                ...state,
                invoice_listing:action.payload
            }
        case types.FETCH_HEADER_FOOTER_IMAGE:
            return {
                ...state,
                viewImage:action.payload
            }
        case types.FETCH_DOCTOR_ID:
            return {
                ...state,
                doctor_id:action.payload
            }
        default:
            return state
    }
}

export default doctorPanelReducer;