import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DoctorProfileDetails,openReviewTab } from './../store/actions/globalActions'
import { BookAppointment,WeeklyAppointments } from './../store/actions/registerationActions'
import { ArticleListing,GetArticle,RecordingListing,fetchDoctorId } from './../store/actions/doctorPanelActions'
import { bucketPath } from './../store/helpers/common';
import { Addtimes,currentDay,currentDate,checkTimeExistance } from './../store/helpers/timeIntervals';
import swal from "sweetalert";
import Reviews from './Reviews';
import Pagination from "./../store/helpers/Pagination";
import VideoPlayer from "./../components/doctorPanel/VideoPlayer";
import ListingSideBar from './ListingSideBar'

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const todayDayName = days[new Date().getDay()-1];
let tabs = ['Overview', 'Reviews', 'Consult Now','My Videos','My Articles'];
const appointmentTypes = ['Chat', 'Audio', 'Video'];
let dayTimes = {};
let daySlots = {};

days.map(value => {
	dayTimes[`${value}_time`] = undefined;
	daySlots[`${value}_slot`] = undefined;
	return null;
});


class DoctorDetails extends Component {
	constructor(props) {
		super(props);
		this.state = { tab: 'Overview', appointmentType: "Chat", dayChoosen: "", interval: [], doctorID: '', dateChoosen:'', pageOfItems: [], currentPage: 1, video: null,viewArticle:false};
		Object.assign(this.state, dayTimes, daySlots);
	}
	componentDidMount() {
		this.InitialHit();
	}

	componentDidUpdate(prevProps,prevState){ 
		if(prevState.doctorID!==this.props.match.params.id){
			this.InitialHit();
		}
		if(this.props?.review_tab?.tab){
			 this.setState({
				 tab:'Reviews'
			 })
			 this.props.dispatch(openReviewTab({})) 
		}
	}

	InitialHit = () => {
		let id = this.props.match.params.id;
		this.setState({
			doctorID: id
		})
		let uniqueID = id;
		this.props.dispatch(fetchDoctorId(this.state.doctorID))
		this.props.dispatch(DoctorProfileDetails(id));
		this.props.dispatch(WeeklyAppointments({ uniquekey: id }))
		this.props.dispatch(ArticleListing(id));
		this.props.dispatch(RecordingListing(`tll-${uniqueID}`));
	}

	getVideoTitle = (room) => {
		let id = this.props.match.params.id;
		let uniqueID = id.toLowerCase();
		let titleStr = room.split(`tll-${uniqueID}-`);
        if(titleStr.length < 2) {
            return "";
        }
		let title =  titleStr[1].split('-').join(' ')
		return title.charAt(0).toUpperCase() + title.slice(1);
    }



	GetAppointment = () => {
		window.$('#bookAppointment').modal({ show: true,backdrop: "static" });
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.profile_info.days && prevState.dayChoosen === "") {
			let insertInfo = nextProps.profile_info;
			let dayvals = Object.keys(insertInfo.days);
			let datesVal = insertInfo.dates;
			return {
				dayChoosen: dayvals[0] ? dayvals[0] : '',
				dateChoosen:datesVal[0]?datesVal[0].date:'',
				monday_time: insertInfo[`monday_time`] ? JSON.parse(insertInfo[`monday_time`]) : "",
				tuesday_time: insertInfo[`tuesday_time`] ? JSON.parse(insertInfo[`tuesday_time`]) : "",
				wednesday_time: insertInfo[`wednesday_time`] ? JSON.parse(insertInfo[`wednesday_time`]) : "",
				thursday_time: insertInfo[`thursday_time`] ? JSON.parse(insertInfo[`thursday_time`]) : "",
				friday_time: insertInfo[`friday_time`] ? JSON.parse(insertInfo[`friday_time`]) : "",
				saturday_time: insertInfo[`saturday_time`] ? JSON.parse(insertInfo[`saturday_time`]) : "",
				sunday_time: insertInfo[`sunday_time`] ? JSON.parse(insertInfo[`sunday_time`]) : "",
				monday_slot: insertInfo[`monday_slot`] ? JSON.parse(insertInfo[`monday_slot`]) : "",
				tuesday_slot: insertInfo[`tuesday_slot`] ? JSON.parse(insertInfo[`tuesday_slot`]) : "",
				wednesday_slot: insertInfo[`wednesday_slot`] ? JSON.parse(insertInfo[`wednesday_slot`]) : "",
				thursday_slot: insertInfo[`thursday_slot`] ? JSON.parse(insertInfo[`thursday_slot`]) : "",
				friday_slot: insertInfo[`friday_slot`] ? JSON.parse(insertInfo[`friday_slot`]) : "",
				saturday_slot: insertInfo[`saturday_slot`] ? JSON.parse(insertInfo[`saturday_slot`]) : "",
				sunday_slot: insertInfo[`sunday_slot`] ? JSON.parse(insertInfo[`sunday_slot`]) : "",
			};
		} else {
			return null;
		}
	}

	toHHMMSS = secs => {
		var sec_num = parseInt(secs, 10);
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;

		return [hours, minutes, seconds]
		  .map(v => (v < 10 ? "0" + v : v))
		  .filter((v, i) => v !== "00" || i > 0)
		  .join(":");
	  }

	BookAppointments = () => {
		const { interval, doctorID, dayChoosen, appointmentType, dateChoosen } = this.state
		let isLoggedIn = localStorage.getItem('token');
		if (interval === "") {
			swal({
				title: "Please choose time for the appointment"
			})
			return false
		}
		swal({
			title: "Are you sure want to fix this appointment?",
			buttons: ['No, thanks', 'Yes, I am sure!']
		}).then(async (Fix) => {
			if (Fix) {
				let dataToSend= {type:'D',doctorID: doctorID, appointment_day: dayChoosen, interval: interval, appointment_type: appointmentType, dateChoosen:dateChoosen};
				window.$('#bookAppointment').modal('hide');
				if (isLoggedIn) {
					const response = await this.props.dispatch(BookAppointment(dataToSend));
					if(response.response==="success"){
						this.props.history.push(`/patient/checkout/${response.code}`)
					}
				} else {
					sessionStorage.setItem('_booking_details',JSON.stringify(dataToSend));
                    sessionStorage.setItem('_booked_process',doctorID);
                    window.$('#loginModal1').modal({show:true,backdrop: "static"})
				}
			} else {
				console.log("you clicked on false");
			}
		});
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	}

	onViewVideo = (video) => {
        this.setState({ video }, () => {
          console.log("dfgjh");
        });
    };

    onCloseModal = () => {
      this.setState({ video: null })
	}

	ViewArticle = (id) => {
		let response = new Promise((resolved,rejected)=>{
			let dataresponse = this.props.dispatch(GetArticle({id:id}))
			if(dataresponse){
				resolved(dataresponse)
			}else{
				rejected('error')
			}
		});
		response.then(response => {
			if(response.response===1){
				this.setState({
					title:response.data.title,
					text:response.data.description,
					viewArticle:true
				  })
			}
		}).catch(error=>{
			console.log(error);
		})
	}

	handleChange = (e) => {
		this.setState(
			{
				viewArticle:false,
				tab:'My Articles'
			}
		)
	}

	render() {
		const { profile_info,weekly_appointments,article_listing,recording_listing } = this.props;
		const { dayChoosen, interval, appointmentType } = this.state;

		//let daysVal = profile_info.days ? Object.keys(profile_info.days) : '';

		let datesVal = profile_info.dates;

		const styles = {backgroundColor:'red'};

		const capitalizeFirstLetter = (string) => {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}

		let rating_data =profile_info.review ? profile_info.review[0].average : 0 ;



		const GetTimeInfo = (props) => {
			let mngSlot = '';
			let noonSlot = '';
			let evgSlot = '';
			if(profile_info[`${props.day}_slot`]){
				let parsed 	= profile_info[`${props.day}_slot`]? JSON.parse(profile_info[`${props.day}_slot`]) : '';
				mngSlot 	= parsed.morning_start && parsed.morning_start!==""?`${parsed.morning_start} - ${parsed.morning_end}`:<span className="badge bg-danger-light">Closed</span>
				noonSlot 	= parsed.noon_start && parsed.noon_start!==""?`${parsed.noon_start} - ${parsed.noon_end}`:<span className="badge bg-danger-light">Closed</span>
				evgSlot 	= parsed.evening_start && parsed.evening_start!==""?`${parsed.evening_start} - ${parsed.evening_end}`:<span className="badge bg-danger-light">Closed</span>
			}
			return (
				<div className="listing-day" key={`info_${props.day}`}>
					<div className="day">{capitalizeFirstLetter(props.day)}</div>
					<div className="time-items">
						<ul className="timing-inline-list">
							<li><span className="time">{profile_info[`${props.day}_slot`] ? mngSlot :<span className="badge bg-danger-light">Closed</span>}</span></li>
							<li><span className="time">{profile_info[`${props.day}_slot`] ? noonSlot :<span className="badge bg-danger-light">Closed</span>}</span></li>
							<li><span className="time">{profile_info[`${props.day}_slot`] ? evgSlot :<span className="badge bg-danger-light">Closed</span>}</span></li>
						</ul>
					</div>
				</div>
			)
		}



		// const todayTiming = () => {
		// 	let day 		= currentDay();
		// 	let mngSlot 	= '';
		// 	let noonSlot 	= '';
		// 	let evgSlot 	= '';
		// 	if(profile_info[`${day}_slot`]){
		// 		let parsed 	= JSON.parse(profile_info[`${day}_slot`]);
		// 		mngSlot 	= `${parsed.morning_start} - ${parsed.morning_end}`
		// 		noonSlot 	= `${parsed.noon_start} - ${parsed.noon_end}`
		// 		evgSlot 	= `${parsed.evening_start} - ${parsed.evening_end}`
		// 		return {morning:mngSlot,noon:noonSlot,evening:evgSlot};
		// 	}
		// }

		let checkExistance = () => {
			let defaultVal = "Closed";
			let day 		= currentDay();
			if(profile_info[`${day}_slot`]){

				let todayTime 	= JSON.parse(profile_info[`${day}_slot`]);

				if(todayTime.morning_start && todayTime.morning_start!==""){
					defaultVal = checkTimeExistance(todayTime.morning_start,todayTime.morning_end)?"Open":"Closed"
				}

				if(defaultVal==="Closed" && (todayTime.noon_start && todayTime.noon_start!=="")){
					defaultVal = checkTimeExistance(todayTime.noon_start,todayTime.noon_end)?"Open":"Closed"
				}

				if(defaultVal==="Closed" && (todayTime.evening_start && todayTime.evening_start!=="")){
					defaultVal = checkTimeExistance(todayTime.evening_start,todayTime.evening_end)?"Open":"Closed"
				}

			}
			return defaultVal
		}

		//let today_timing = todayTiming()

		let today_Class = checkExistance()



		return (
			<div>
				<div className="clearfix"></div>
				<div className="page-content background">
					<div className="clearfix"></div>
					<div className="container-fluid">
						<div className="row">
							<div className="doctor-list-section col-md-8">
								<div className="clearfix"></div>
								<div className="back-to-dr-list">
									<Link to="/doctor-listing" >Back to Doctors Listing</Link>
								</div>
								<div className="doctor-list-box">
									<div className="row list-repeat-row">
										<div className="col-lg-2 col-md-5   doctor-list-img text-center">
											<Link to="doctor-details.html"><img src={`${profile_info.profile_pic ? bucketPath + profile_info.profile_pic : "/assets/doctor/img/patients/patient.jpg"}`} alt="..." /></Link>
											<ul className="star-ul rating">
												<li>
												{[...Array(5).keys()].map((val, i) => {
													return (
														<i
															key={`star_${i}`}
															className={`fas fa-star ${
																val + 1 <= rating_data
																	? "filled"
																	: ""
															}`}
														></i>
													);
												})}
												</li>
												<li>
													<Link className="rating-count" to="">
													{profile_info.review && profile_info.review.map((value, index) => {
														return (
															<React.Fragment key={`review${index}`}>{`(${value.total} reviews)`} </React.Fragment>
														)
													})}

													</Link>
												</li>
											</ul>
										</div>
										<div className="col-lg-7 col-md-7 doctor-detail-box">
											<h2 className="dr-name"><Link to="#">{`${profile_info.title} ${profile_info.firstname} ${profile_info.lastname}`}</Link>
												<Link to="#"><i className="fas fa-heart"></i></Link>
												<span className="dr-specification float-right">{profile_info.specialization_name}</span></h2>

											<p className="dr-qualification">{profile_info.more && profile_info.more.map((value, index) => {
												let comma = profile_info.more[index + 1] ? "," : "";
												return value.degree.toUpperCase() + " (" + value.college.toUpperCase() + ")" + comma;
											})}</p>
											<p className="dr-experience"><i className="fas fa-user-md"></i>{`${profile_info.experience === 0 ? ">1" : profile_info.experience}${profile_info.experience > 0 ? "+" : ""}`} years experience overall</p>
											<p className="dr-location"><i className="fa fa-map-marker"></i>{`${profile_info.city},${profile_info.country}`}</p>

										</div>
										<div className="col-lg-3 col-md-12 dr-fees-box  text-center align-self-center">
											<ul className="contects-ico active-ico">
												<li className="active-ico"><Link to="#"><i className="fas fa-comment-alt"></i></Link></li>
												<li className="active-ico"><Link to="#"><i className="fas fa-phone"></i></Link>
												</li>
												<li className="active-ico"><Link to="#"><i className="fas fa-video"></i></Link></li>
											</ul>

											<Link to="#" className="btn btn-light-black margin-top-10 btn-block" onClick={() => this.GetAppointment()}>Consult Online</Link>
										</div>
									</div>
								</div>
								<div className="doctor-list-box">
									<div className="card">
										<div className="card-body pt-0">
											<nav className="user-tabs mb-4">
												<ul className="nav nav-tabs nav-tabs-bottom nav-justified">
													{tabs.map((value, index) => {
														return (
															<li className="nav-item" key={`tabs_${index}`}>
																<Link className={`nav-link ${this.state.tab === value ? "active" : ""}`} to="#" onClick={() => this.setState({ tab: value })}>{value}</Link>
															</li>
														)
													})}
												</ul>
											</nav>
											<div className="tab-content pt-0">

												{this.state.tab === "Overview" && <div id="doc_overview">
													<div className="row">
														<div className="col-md-12 col-lg-9">
															<div className="widget about-widget">
																<h4 className="widget-title">About Me</h4>
																<p>{profile_info.about_me}</p>
															</div>
															<div className="widget education-widget">
																<h4 className="widget-title">Education</h4>
																<div className="experience-box">
																	<ul className="experience-list">
																		{profile_info.more && profile_info.more.map((eduVal,eduIndex)=>{
																			return (
																				<li key={`eduVal_${eduIndex}`}>
																					<div className="experience-user">
																						<div className="before-circle"></div>
																					</div>
																					<div className="experience-content">
																						<div className="timeline-content">
																							<Link to="#/" className="name">{eduVal.college.toUpperCase()}</Link>
																							<div>{eduVal.degree.toUpperCase()}</div>
																							<span className="time">{eduVal.year}</span>
																						</div>
																					</div>
																				</li>
																			)
																		})}
																	</ul>
																</div>
															</div>
															<div className="widget experience-widget">
																<h4 className="widget-title">Work &amp; Experience</h4>
																<div className="experience-box">
																	<ul className="experience-list">
																		<li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<Link to="#/" className="name">{`(${profile_info.experience} years )`}</Link>
																					{/* <span className="time">2010 - Present (5 years)</span> */}
																				</div>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>

															<div className="service-list">
																<h4>Specializations</h4>
																<ul className="clearfix">
																	<li>{profile_info.specialization_name}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>}
												{this.state.tab === "Locations" && <div role="tabpanel">
													{profile_info.more && profile_info.more.map((value, index) => {
														if (value.hospital_name === "") { return null; }
														return (
															<div className="location-list">
																<div className="row">
																	<div className="col-md-12">
																		<div className="clinic-content">
																			<h4 className="clinic-name"><Link to="#">{value.hospital_name}</Link></h4>
																			<p className="doc-speciality">{profile_info.specialization_name}</p>
																			<div className="rating">
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star"></i>
																				<span className="d-inline-block average-rating">(4)</span>
																			</div>
																			<div className="clinic-details mb-0">
																				<h5 className="clinic-direction"> <i className="fas fa-map-marker-alt"></i>{` ${value.address}, ${value.city}`}<br /><Link to="#">Get Directions</Link></h5>
																				<ul>
																					<li>
																						<Link to="/assets/img/features/feature-01.jpg" data-fancybox="gallery2">
																							<img src="./..//assets/img/features/feature-01.jpg" alt="Feature " />
																						</Link>
																					</li>
																					<li>
																						<Link to="/assets/img/features/feature-02.jpg" data-fancybox="gallery2">
																							<img src="./..//assets/img/features/feature-02.jpg" alt="Feature " />
																						</Link>
																					</li>
																					<li>
																						<Link to="/assets/img/features/feature-03.jpg" data-fancybox="gallery2">
																							<img src="./..//assets/img/features/feature-03.jpg" alt="Feature " />
																						</Link>
																					</li>
																					<li>
																						<Link to="/assets/img/features/feature-04.jpg" data-fancybox="gallery2">
																							<img src="./..//assets/img/features/feature-04.jpg" alt="Feature " />
																						</Link>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														)
													})}
												</div>}
												{this.state.tab === "Reviews" && <div role="tabpanel" >
													<Reviews profileInfo={profile_info} doctorID={this.state.doctorID} userUniqueKey={this.state.doctorID} />
												</div>}
												{this.state.tab === "Consult Now" &&  <>
													<div className="row">
														<div className="login-sec-modal">
															<div className="bookAppointment-body-inner">
																<div className="row">
																	<div className="col-md-12">
																		<div className="bookappointment-tab-side">
																			<ul className="nav nav-tabs" id="myTab" role="tablist">
																				{appointmentTypes.map((value, index) => {
																					return (
																						<li className="nav-item" key={`appointmentType${index}`}>
																							<Link to="#" className={`nav-link ${appointmentType === value ? "active" : ""}`} onClick={() => { this.setState({ appointmentType: value, interval: [] }) }}><i className={`fas fa-${value === "Audio" ? "phone" : (value === "Video" ? "video" : (value === "Chat" ? "comment-alt" : "walking"))}`}></i> &nbsp;{value}</Link>
																						</li>
																					)
																				})}
																			</ul>
																			<h3 className="red_rate">Book {appointmentType} Consultancy for ₹ {appointmentType === "Audio" ? profile_info.phone_consultation_fee : (appointmentType === "Video" ? profile_info.video_consultation_fee : (appointmentType === "Chat" ? profile_info.chat_consultation_fee : profile_info.walkin_consultation_fee))}</h3>
																			<div className="tab-content" id="myTabContent">
																				<div className="active" >
																					<div className="upcoming-slots">
																						<ul className="upcoming-slots-ul">
																							{datesVal && datesVal.map((value, index) => {
																								return (
																									<li key={index} className={`${this.state.dateChoosen === value.date ? "active" : ""}`}>
																										<Link to="#" onClick={e => this.setState({ dayChoosen: value.dayName,interval:[],dateChoosen:value.date })}>{value.dateFormat}</Link>
																									</li>
																								)
																							})
																							}
																						</ul>
																					</div>
																					<div className="active-slots">
																						<div className="row">
																							<div className="col-md-12">
																								<h2><i className="slot-icos"><img src="/assets/images/slot-ico-1.png" alt="..." /></i>Morning</h2>
																								<ul className="ul-active-slots">
																									{
																										dayChoosen !== "" && this.state[`${dayChoosen}_time`]['morning'] && this.state[`${dayChoosen}_time`]['morning'].map((value, index) => {
																											let dataTest = weekly_appointments.filter((filteredvalue)=>filteredvalue.starttime===value && filteredvalue.appointment_date===this.state.dateChoosen);
																											return (
																												<li key={`mng_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
																													<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
																												</li>)
																										})
																									}
																									{
																										(dayChoosen === "" || !this.state[`${dayChoosen}_time`]['morning']) && <li key={`mng_slot_empty`}>
																											<span className="badge bg-danger-light">Closed</span>
																										</li>
																									}
																								</ul>
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-md-12">
																								<h2><i className="slot-icos"><img src="/assets/images/slot-ico-2.png" alt="..." /></i>Afternoon</h2>
																								<ul className="ul-active-slots">
																									{
																										dayChoosen !== "" && this.state[`${dayChoosen}_time`]['noon'] && this.state[`${dayChoosen}_time`]['noon'].map((value, index) => {
																											let dataTest = weekly_appointments.filter((filteredvalue)=>filteredvalue.starttime===value && filteredvalue.appointment_date===this.state.dateChoosen);
																											return (
																												<li key={`noon_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
																													<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
																												</li>)
																										})
																									}
																									{
																										(dayChoosen === "" || !this.state[`${dayChoosen}_time`]['noon']) && <li key={`noon_slot_empty`}>
																											<span className="badge bg-danger-light">Closed</span>
																										</li>
																									}
																								</ul>
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-md-12">
																								<h2><i className="slot-icos"><img src="/assets/images/slot-ico-3.png" alt="..." /></i>Evening</h2>
																								<ul className="ul-active-slots">
																								{
																										dayChoosen !== "" && this.state[`${dayChoosen}_time`]['evening'] && this.state[`${dayChoosen}_time`]['evening'].map((value, index) => {
																											let dataTest = weekly_appointments.filter((filteredvalue)=>filteredvalue.starttime===value && filteredvalue.appointment_date===this.state.dateChoosen);
																											return (
																												<li key={`evening_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
																													<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
																												</li>)
																										})
																									}
																									{
																										(dayChoosen === "" || !this.state[`${dayChoosen}_time`]['evening']) && <li key={`evening_slot_empty`}>
																											<span className="badge bg-danger-light">Closed</span>
																										</li>
																									}
																								</ul>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div> 
													<div className="modal-footer">
														<div className="col-md-12 text-center">
															<Link to="#" className="btn btn-light-black" onClick={e => { this.BookAppointments() }}>Book Appointment</Link>
														</div>
													</div>
													</>
												
												// <div role="tabpanel">
												// 	<div className="row">
												// 		<div className="col-md-12 ">
												// 			<div className="widget business-widget"> 
												// 				<div className="widget-content">
												// 					<div className="listing-hours">
												// 						<div className="listing-day current">
												// 							<div className="day">Today</div>
												// 							<div className="time-items w-300">
												// 								<span className="open-status text-center"><span className={`badge bg-${today_Class==="Open"?"success":"danger"}-light`}>{today_Class} Now</span></span>
												// 							</div>
												// 						</div>
												// 						<div className="listing-day">
												// 							<div className="day">{currentDate()}</div>
												// 							<div className="time-items">
												// 								<ul className="timing-inline-list">
												// 								{days.map((value, i)=>{
												// 									let parsed 	= profile_info[`${value}_slot`] ? JSON.parse(profile_info[`${value}_slot`]) : '';
												// 								return (
												// 									todayDayName === value && <div key={`today_${i}`}>
												// 									<li><span className="time">{parsed.morning_start && parsed.morning_start!=="" ?`${parsed.morning_start} - ${parsed.morning_end}`:''}</span></li>
												// 									<li><span className="time">{parsed.noon_start && parsed.noon_start!=="" ?`${parsed.noon_start} - ${parsed.noon_end}`:''}</span></li>
												// 									<li><span className="time">{parsed.evening_start  && parsed.evening_start!==""?`${parsed.evening_start} - ${parsed.evening_end}` : '' }</span></li>
												// 									</div>
												// 								)
												// 								})}
												// 								</ul>
												// 							</div>
												// 						</div>
												// 						<div className="listing-day border-bottom">
												// 							<div className="day">&nbsp;</div>
												// 							<div className="time-items">
												// 							<ul className="timing-inline-list timing-icons">
												// 									<li>
												// 										<span className="time">
												// 											<Link to="#">
												// 												<i className="slot-icos"><img src="/assets/images/slot-ico-1.png" alt="..." /></i>
												// 											</Link>
												// 										</span>
												// 									</li>
												// 									<li>
												// 										<span className="time">
												// 											<Link to="#">
												// 												<i className="slot-icos"><img src="/assets/images/slot-ico-2.png" alt="..." /></i>
												// 											</Link>
												// 										</span>
												// 									</li>
												// 									<li>
												// 										<span className="time">
												// 											<Link to="#">
												// 												<i className="slot-icos"><img src="/assets/images/slot-ico-3.png" alt="..." /></i>
												// 											</Link>
												// 										</span>
												// 									</li>
												// 								</ul>
												// 							</div>
												// 						</div>
												// 						{days.map((value, i)=>{
												// 							return (
												// 								<GetTimeInfo key={`info-${i}`} day={value}/>
												// 							)
												// 						})}
												// 					</div>
												// 				</div>
												// 			</div>
												// 		</div>
												// 	</div>
												// </div>
												}

												{this.state.tab === "My Videos" && <div role="tabpanel">
													<div className="row">
													{this.state.pageOfItems.length > 0 ? (this.state.pageOfItems.map((val, i) => (
														<div className="col-md-6 col-lg-4 my-video-box" key={`videos${i}`}>
															<div className="card border">
															<Link
																to="#"
																className=""
																onMouseUp={() =>
																	this.onViewVideo(val)
																}
															>
															<img className="card-img-top" src={ val.thumbnail} alt="Card cap" />
															<i className="fas fa-play-circle"></i>
															<p className="video-timer">{this.toHHMMSS(val.duration)}</p>
															</Link>
															<ul className="list-group list-group-flush text-center">
															<li className="list-group-item">{val.room ? this.getVideoTitle(val.room) : ''}</li>
															</ul>
															</div>
														</div>

													))) : (

														<div className="col-md-6 col-lg-4">
															<h5>No Videos Found</h5>
														</div>
													)}

													<div className="col-md-12 ">
													<div className="container-fluid no-padding">
														<div className="row pull-right">
															<div className="col-md-12 col-sm-12 col-xs-12 pagination ">
																&nbsp;
																{recording_listing && (
																	<Pagination
																		pageSize={12}
																		items={recording_listing}
																		onChangePage={
																			this.onChangePage
																		}
																	/>
																)}
															</div>
														</div>
													</div>
													</div>
													</div>
													<VideoPlayer video={this.state.video} showDeleteBtn={false} onCloseModal={this.onCloseModal} />
												</div>}


												{this.state.tab === "My Articles" && <div role="tabpanel">
													<div className="row">
														{this.state.viewArticle && <Link className="btn btn-light-black  float-right" to="#" onClick={e=>this.handleChange(e)}><i className="fa fa-arrow-left" aria-hidden="true"></i></Link>}
														{!this.state.viewArticle && <div className="col-md-12 ">
															<div className="table-responsive">
                  											<table className="datatable table table-hover table-center mb-0">
																<tbody>
																{this.state.pageOfItems.length > 0 ? (
																this.state.pageOfItems.map((val, i) => (
																	<tr key={i}>
																		<td style={{width:'20%'}}>
																			<Link
																				className=""
																				to="#"
																				onClick = {e=>this.ViewArticle(val.uniquekey)}
																			>
																				{val.creationdate}
																			</Link>
																		</td>
																		<td style={{width:'20%'}}>
																			<Link
																			className=""
																			to="#"
																			onClick = {e=>this.ViewArticle(val.uniquekey)}
																			>
																				{val.title}
																			</Link>
																		</td>
																	</tr>
																))
																) : (
																<tr>
																	<td colSpan="4">No articles found</td>
																</tr>
																)}
																</tbody>
															</table>
															</div>
															<div className="container-fluid no-padding">
																<div className="row pull-right">
																	<div className="col-md-12 col-sm-12 col-xs-12 pagination ">
																		&nbsp;
																		{article_listing && (
																		<Pagination
																			pageSize={10}
																			items={article_listing}
																			onChangePage={this.onChangePage}
																		/>
																		)}
																	</div>
																</div>
															</div>
														</div>}

														{this.state.viewArticle && <div className="col-md-12 ">
															<div className="card">
																<div className="card-body">
																	<h4 className="card-title">{this.state.title}</h4>
																	<div className="profile-box">
																		<div className="row">
																			<div className="col-md-12">
																				<div className="card schedule-widget view-artical-widget mb-0" dangerouslySetInnerHTML={{
																						__html: this.state.text
																					}}>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div> }
													</div>
												</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
							<ListingSideBar />
						</div>
					</div>
				</div>

				<div className="modal fade loginModal-div" id="bookAppointment" tabIndex="-1" role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Book Appointment</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body login-sec-modal">
								<div className="bookAppointment-body-inner">
									<div className="row">
										<div className="col-md-12">
											<div className="bookappointment-tab-side">
												<ul className="nav nav-tabs" id="myTab" role="tablist">
													{appointmentTypes.map((value, index) => {
														return (
															<li className="nav-item" key={`appointmentType${index}`}>
																<Link to="#" className={`nav-link ${appointmentType === value ? "active" : ""}`} onClick={() => { this.setState({ appointmentType: value, interval: [] }) }}><i className={`fas fa-${value === "Audio" ? "phone" : (value === "Video" ? "video" : (value === "Chat" ? "comment-alt" : "walking"))}`}></i> &nbsp;{value}</Link>
															</li>
														)
													})}
												</ul>
												<h3 className="red_rate">Book {appointmentType} Consultancy for ₹ {appointmentType === "Audio" ? profile_info.phone_consultation_fee : (appointmentType === "Video" ? profile_info.video_consultation_fee : (appointmentType === "Chat" ? profile_info.chat_consultation_fee : profile_info.walkin_consultation_fee))}</h3>
												<div className="tab-content" id="myTabContent">
													<div className="active" >
														<div className="upcoming-slots">
															<ul className="upcoming-slots-ul">
																{datesVal && datesVal.map((value, index) => {
																	return (
																		<li key={index} className={`${this.state.dateChoosen === value.date ? "active" : ""}`}>
																			<Link to="#" onClick={e => this.setState({ dayChoosen: value.dayName,interval:[],dateChoosen:value.date })}>{value.dateFormat}</Link>
																		</li>
																	)
																})
																}
															</ul>
														</div>
														<div className="active-slots">
															<div className="row">
																<div className="col-md-12">
																	<h2><i className="slot-icos"><img src="/assets/images/slot-ico-1.png" alt="..." /></i>Morning</h2>
																	<ul className="ul-active-slots">
																		{
																			dayChoosen !== "" && this.state[`${dayChoosen}_time`]['morning'] && this.state[`${dayChoosen}_time`]['morning'].map((value, index) => {
																				let dataTest = weekly_appointments.filter((filteredvalue)=>filteredvalue.starttime===value && filteredvalue.appointment_date===this.state.dateChoosen);
																				return (
																					<li key={`mng_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
																						<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
																					</li>)
																			})
																		}
																		{
																			(dayChoosen === "" || !this.state[`${dayChoosen}_time`]['morning']) && <li key={`mng_slot_empty`}>
																				<span className="badge bg-danger-light">Closed</span>
																			</li>
																		}
																	</ul>
																</div>
															</div>
															<div className="row">
																<div className="col-md-12">
																	<h2><i className="slot-icos"><img src="/assets/images/slot-ico-2.png" alt="..." /></i>Afternoon</h2>
																	<ul className="ul-active-slots">
																		{
																			dayChoosen !== "" && this.state[`${dayChoosen}_time`]['noon'] && this.state[`${dayChoosen}_time`]['noon'].map((value, index) => {
																				let dataTest = weekly_appointments.filter((filteredvalue)=>filteredvalue.starttime===value && filteredvalue.appointment_date===this.state.dateChoosen);
																				return (
																					<li key={`noon_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
																						<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
																					</li>)
																			})
																		}
																		{
																			(dayChoosen === "" || !this.state[`${dayChoosen}_time`]['noon']) && <li key={`noon_slot_empty`}>
																				<span className="badge bg-danger-light">Closed</span>
																			</li>
																		}
																	</ul>
																</div>
															</div>
															<div className="row">
																<div className="col-md-12">
																	<h2><i className="slot-icos"><img src="/assets/images/slot-ico-3.png" alt="..." /></i>Evening</h2>
																	<ul className="ul-active-slots">
																	{
																			dayChoosen !== "" && this.state[`${dayChoosen}_time`]['evening'] && this.state[`${dayChoosen}_time`]['evening'].map((value, index) => {
																				let dataTest = weekly_appointments.filter((filteredvalue)=>filteredvalue.starttime===value && filteredvalue.appointment_date===this.state.dateChoosen);
																				return (
																					<li key={`evening_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
																						<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
																					</li>)
																			})
																		}
																		{
																			(dayChoosen === "" || !this.state[`${dayChoosen}_time`]['evening']) && <li key={`evening_slot_empty`}>
																				<span className="badge bg-danger-light">Closed</span>
																			</li>
																		}
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="col-md-12 text-center">
									<Link to="#" className="btn btn-light-black" onClick={e => { this.BookAppointments() }}>Book Appointment</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

}
const mapStateToProps = state => ({
	profile_info: state.global.profile_info,
	weekly_appointments: state.registration.weekly_appointments,
	article_listing: state.doctorPanel.article_listing,
	recording_listing: state.doctorPanel.recording_listing,
	review_tab:state.global.review_tab

});

export default connect(mapStateToProps)(DoctorDetails);
