import * as actionTypes from './../types/types';

//Review Listing
export function AdminGlobalSearch(data) { 
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADMIN_GLOBAL_SEARCH,
      payload: data,
    });
  };
}
