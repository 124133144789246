import * as types from "../types/types";

const initalState = {
    loading: true, 
    lab_listing:[],
    lab_profile_info:[],
    weekly_lab_appointments:[],
    patient_lab_appoinments:[],
    lab_appointments:[]
}

const labPanelReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_LAB_LISTING:
            return {
                ...state,
                loading: false,
                lab_listing: action.payload
            } 
        case types.LAB_PROFILE_INFO:
            return {
                ...state,
                lab_profile_info:action.payload
            }
        case types.WEEKLY_LAB_APPOINTMENT_LIST:
            return {
                ...state,
                weekly_lab_appointments:action.payload
            }
        case types.PATIENT_LAB_APPOINTMENT_LIST:
            return {
                ...state,
                patient_lab_appoinments:action.payload
            }
        case types.FETCH_LAB_APPOINTMENT_LISTING:
            return {
                ...state,
                loading: false,
                lab_appointments: action.payload
            }
        default:
            return state
    }
}

export default  labPanelReducer;
