import React, { PureComponent } from "react";
class Insurance extends PureComponent {
    render() {
        return (
          <div className="page-content coming-soon background">
              <div className="banner-section-inner">
              <div className="container-fluid">
                  <div className="col-md-12">
                  <h2>Insurance</h2>
                  <p>Some informative text will come in this line to tell about this page</p>
                  </div>
              </div>
              </div>

              <div className="page-section nopadding cs-nomargin">
          <div className="container">
            <div className="row">
              <div className="page-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {
                  //<div className="cs-section-title"><h2>Diet Plan</h2></div>
                }
                <div className="page-not-found coming-soon-page">
                  <div className="cs-text">
                    <span className="red-color">Coming soon</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

          </div>
        );
    }
}


export default Insurance;
