import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import { DeleteRecording } from "../../store/actions/doctorPanelActions";
import swal from "sweetalert";

const initialState = {
    show: false,
    video: {},
    play: false,
    showDeleteBtn: false,
};;
class CounsellorVideoPlayer extends Component {
    state = initialState;

    handleClose = () => {
        this.setState({ ...initialState }, () => {
            this.props.onCloseModal();
        });
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.video) {
            this.setState({
                show: true,
                video: newProps.video,
                showDeleteBtn: newProps.showDeleteBtn,
            });
        }
    }

    onClickFine = () => {
        this.setState({ ...initialState }, () => {
            this.props.onCloseModal();
        });
    };

    onDeleteVideo = (recordingID) => {
        let data = localStorage.getItem("data");
        let uniqueID = JSON.parse(data).uniquekey.toLowerCase();
        swal({
            title: "Are you sure want to delete this?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const postData = {
                    recordingID: recordingID,
                    roomID: `tll-${uniqueID}`,
                };
                const res = await this.props.DeleteRecording(postData);
                if(res === 1) {
                    this.setState({ ...initialState }, () => {
                        this.props.onCloseModal();
                    });
                }
            } else {
                console.log("Your file is safe!");
            }
        });
    };

    render() {
        const { video, show } = this.state;
        return (
            <Modal show={show} onHide={this.handleClose} size="lg">
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <ReactPlayer
                        url={video.file}
                        width="100%"
                        controls={true}
                        playing={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {this.state.showDeleteBtn && (
                        <>
                            <Button
                                variant="danger"
                                onMouseUp={() => this.onDeleteVideo(video.recordingID)}
                            >
                                Delete
                            </Button>

                            <Button
                                variant="primary"
                                onMouseUp={this.onClickFine}
                            >
                                Fine
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, { DeleteRecording })(CounsellorVideoPlayer);
