import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

 const ToolTips = (props) => {
  return (
    <OverlayTrigger
      placement={props.Placement}
      overlay={<Tooltip id={`tooltip-${props.Placement}`}>{props.Text}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};
export default ToolTips;
