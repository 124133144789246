import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices'; 

export function getBillingList() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/payment-listing`, {
            method: 'POST',
            //body: JSON.stringify({search:search, location:location, symptoms:symptoms, specializations:specializations, gender:gender}),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_BILL_LISTING,payload: data.data});
            }
        });
    }
}

export function getProductBillingList() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/product-payment-listing`, {
            method: 'POST',
            //body: JSON.stringify({search:search, location:location, symptoms:symptoms, specializations:specializations, gender:gender}),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_BILL_LISTING,payload: data.data});
            }
        });
    }
}

export const forceloginClick = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FORCE_LOGIN_CLICK, payload: data })
    }
}
