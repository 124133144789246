import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GetArticle } from './../store/actions/doctorPanelActions'
import {ViewHomeArticle} from "./../store/actions/userActions";
import { bucketPath } from './../store/helpers/common';
import ListingSideBar from './ListingSideBar'

const initialState = {title:'',text:''}

class HealthFeed extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        this.InitialHit();
    }

    componentDidUpdate(prevProps){
        let id = this.props.match.params.id;
        let prevId = prevProps.match.params.id;
        if(prevId!==id && id!==""){
            this.InitialHit();
        }
    }

    InitialHit = () => {
        let id = this.props.match.params.id;
        this.props.dispatch(ViewHomeArticle())
        this.ViewArticle(id);
    }

    ViewArticle = (id) => {
        let response = new Promise((resolved,rejected)=>{
			let dataresponse = this.props.dispatch(GetArticle({id}))
			if(dataresponse){
				resolved(dataresponse)
			}else{
				rejected('error')
			}
		});
		response.then(response => {
			if(response.response===1){
				this.setState({
					title:response.data.title,
                    text:response.data.description,
                    image:response.data.image ,
                    showArticle:true
				  })
			}
		}).catch(error=>{
			console.log(error);
		})

    }

    render() {

      const {home_article} = this.props
      const {text} = this.state;

    return (
        <div className="page-content background">
            <div className="container-fluid">
                <div className="row">
                    <div className="doctor-list-section col-md-8 article-img">
                        <div className="grid-view-div">
                            <div className="doctor-list-box">
                                <div className="row list-repeat-row">
                                    <div className="health-feed-hed">
                                        <div className="col-md-12 ">
                                            <h2>{this.state.title}</h2>
                                        </div>
                                    </div>
                                   {/* { this.props.doctor_id && <><div className="col-md-2 align-self-center doctor-list-img doctor-health-topic-img text-center">
                                        <Link to="#">
                                            <img
                                                src={`${profile_info.profile_pic ? bucketPath + profile_info.profile_pic : "./../assets/doctor/img/patients/patient.jpg "}`}
                                                alt="..."
                                            />
                                        </Link>
                                    </div>
                                    <div className="col-md-10 doctor-detail-box">
                                        <h2 className="dr-name"><Link to="doctor-details.html">Dr. {profile_info.firstname} {profile_info.lastname} </Link></h2>
                                        <p className="dr-qualification">
                                            {profile_info.more && profile_info.more.map((value, index) => {
                                                let comma = profile_info.more[index + 1] ? ',' : '';
                                                return value.degree.toUpperCase() + '' + comma;
                                                })}{' '}
                                            - {profile_info.specialization_name
                                            }
                                        </p>
                                    </div> </>} */}
                                </div>
                            </div>
                            <div className="doctor-list-box ">
                                <div className="row list-repeat-row">
                                    {this.state.image &&  <div className="col-md-12 ">
                                        <Link to="#">
                                            <img
                                                src={`${this.state.image ? bucketPath + this.state.image : "./../assets/doctor/img/patients/patient.jpg"}`}
                                                className="img-fluid image"
                                                alt="..."
                                            />
                                        </Link>
                                    </div>}
                                    <div className="health-topic-detail-box">
                                        <div className="col-md-12">
                                            <div className="card schedule-widget view-artical-widget mb-0"
                                                dangerouslySetInnerHTML={{__html: text}}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ListingSideBar />
                    {/* <div className="col-md-4 doctor-list-right-side-sec">
                        <div className="dr-list-ask-question">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2><i className="far fa-envelope"></i>&nbsp; Ask a FREE Question</h2>
                                </div>
                            </div>
                            <div className="dr-list-ask-question-textbox">
                                <div className="row">
                                    <div className="col-md-12 align-self-center">
                                    <h4>Get FREE multiple opinions from Doctors</h4>
                                    <textarea className="form-control"
                                        placeholder="Enter question for you or someone else. E.g. my left eye is red and swollen..."
                                        id="exampleFormControlTextarea1" rows="5"></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 align-self-center">
                                        <p>Posted anonymously</p>
                                    </div>
                                    <div className="col-md-4 align-self-center">
                                        <Link to="#" className="btn btn-light-black  float-right">Submit</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dr-list-health-topic-box">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Top Health Tips</h2>
                                </div>
                            </div>
                            {home_article  && home_article.map((value,index)=> {
                                for(index===0;index<4;index++){
                                    return (
                                        <div className="dr-list-topic-list-box" key={`article_${index}`}>
                                            <div className="row">
                                                <div className="col-lg-7 col-md-12 align-self-center">
                                                    <h5><Link to="#" onClick = {e=>this.ViewArticle(value.uniquekey)}>{value.title}</Link></h5>
                                                </div>
                                                <div className="col-lg-5 col-md-12">
                                                    <img
                                                        src={`${value.image ? bucketPath + value.image : "./../assets/doctor/img/patients/patient.jpg"}`}
                                                        className="img-fluid"
                                                        alt="..." />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return true
                            })}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
  };
}

const mapStateToProps = (state) => ({
    home_article: state.user.home_article,
});

export default connect(mapStateToProps)(HealthFeed);
