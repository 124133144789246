// @flow
import * as React from "react";
import { Editor } from "@tinymce/tinymce-react";
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import { AddArticles, GetArticle } from '../../store/actions/counselorPanelActions'
import { Link } from "react-router-dom";
import { bucketPath } from "../../store/helpers/common";

class CounsellorAddArticle extends React.Component {
  state = { title: "", text: "", id: "", image: [], };
  validator = new SimpleReactValidator();
  stotageData = localStorage.getItem('data');

  componentDidMount = async () => {
    let token = this.props.match.params.token;
    if (token && token !== "") {
      let response = await this.props.dispatch(GetArticle({ id: token }))
      if (response.response && response.response === 1) {
        let image = [];
        image.push(response.data.image);
        this.setState({
          title: response.data.title,
          text: response.data.description,
          id: token,
          image: image,
          existing: true
        })
      }
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "image") {
      var file = window.$('#image')[0].files;
      let image = [];
      for (let i = 0; i < file.length; i++) {
        let imageFile = URL.createObjectURL(file[i])
        image.push(imageFile);
      }
      this.setState({
        image: image,
        existing: false
      })
    }
  };

  handleEditorChange = (content, editor) => {
    this.setState({ text: content })
  }

  onSubmitHandler = async () => {
    const { title, text, id } = this.state
    if (this.validator.allValid()) {
      let uniqueID = JSON.parse(this.stotageData).uniquekey;
      let response = await this.props.dispatch(AddArticles({ title: title, description: text, uniquekey: uniqueID, id: id }));
      if (response === 1) {
        this.props.history.push('/counsellor/article-videos')
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const { title, text } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={title}
                    onChange={(e) => this.onChangeHandler(e)}
                  />
                  {this.validator.message('Article Title', title, 'required')}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div id="Upload-documents" className="form-group row">
                  <div className="col-md-4">
                  <label
                    htmlFor="image"
                  >
                    Image
                  </label>
                    <small className="form-text text-muted">
                      Allowed JPG, GIF or PNG. Max size of 2MB
                    </small>
                  </div>
                  <div className="col-md-8">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="image"
                        name="image"
                        onChange={(e) =>
                          this.onChangeHandler(e)
                        }
                        multiple
                        aria-describedby="inputGroupFileAddon01"
                        accept="image/*"
                        ref={(input) => {
                          this.image = input;
                        }}
                      />
                      <label
                        className="custom-file-label custom-file-label-doc"
                        htmlFor="inputGroupFileAddon01"
                      >
                        {this.state.image
                          ? this.state.image
                          : "Add Registration Proof"}
                      </label>
                      <i
                        className="fas fa-camera camera-ico"
                        onClick={() => {
                          this.image.click();
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      {this.state.image.map(
                        (value) => {
                          return (
                            <div
                              className="change-avatar"
                              key={`avatar${value}`}
                            >
                              <div className="profile-img">
                                <Link to="#">
                                  <i className="fas fa-trash-alt del-profile-ico"></i>
                                </Link>
                                <img
                                  alt="User"
                                  src={this.state.existing ? `${bucketPath}${value}` : `${value}`}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                      {this.validator.message("image", this.state.image, "required")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Article Text</label>
                  <Editor
                    apiKey="xg6aofsienh3p2rx7w7qd63srneueilzfwumbic5cefxag6i"
                    init={{
                      height: 500,
                      plugins: [
                        "advlist autolink lists link image",
                        "charmap print preview anchor help",
                        "searchreplace visualblocks code",
                        "insertdatetime media table paste wordcount",
                      ],
                      menubar: 'file edit view insert format tools table help',
                      // toolbar: "insertfile undo redo | styleselect | fontselect fontsizeselect formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    }}
                    value={this.state.text}
                    onEditorChange={this.handleEditorChange}
                  />
                  {this.validator.message('Article Text', text, 'required')}
                </div>
              </div>
            </div>
            <div className="submit-section">
              <button
                type="button"
                className="btn btn-light-black submit-btn"
                onClick={(e) => this.onSubmitHandler(e)}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect()(CounsellorAddArticle);
