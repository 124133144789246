import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import {Link} from 'react-router-dom';
import { getDoctorList } from './../store/actions/userActions'
import Pagination from './../store/helpers/Pagination';
 


class DoctorGrid extends Component {
    constructor(props) {
        super(props);
       this.state = {
           tab:'List',
           currentPage:1,
		    totalItems:[],
			pageOfItems:[]
             
       }
    } 

    componentDidMount(){
        this.props.dispatch(getDoctorList());
    }

    componentDidUpdate = (prevProps, prevState) => {  
        let nextProps = this.props;
      
        if(nextProps.Doctors !== prevProps.Doctors){
          this.setState({ 
            totalItems:nextProps.Doctors, 
            pageOfItems:nextProps.Doctors.slice(0,10)
          })
          
        }
	}

	onChangePage = (pageOfItems,page) => {
        this.setState({ pageOfItems: pageOfItems,currentPage:page });
	};

    render() {
        return (
            <div> 
                <div className="clearfix"></div>
                    <div className="page-content background"> 
                        <div className="clearfix"></div> 
                        <div className="container-fluid"> 
                            <div className="row">
                                <div className="doctor-list-section col-md-8"> 
                                    <div className="clearfix"></div>
                                    <div className="filters-inner-pages">
                                        <div className="row">
                                            <div className="total-dr  col-md-2 align-self-center">
                                                <p>1054 Doctors</p>
                                            </div>
                                            <ul className="filters-bar-inner col-md-10">
                                                <li className="nav-item dropdown">
                                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Symtumus
                                                    </Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <div className="filter-drop-height">
                                                            <Link className="dropdown-item" to="#">Action</Link>
                                                            <Link className="dropdown-item" to="#">Another action</Link>
                                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Specialization
                                                    </Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <div className="filter-drop-height">
                                                            <Link className="dropdown-item" to="#">Action</Link>
                                                            <Link className="dropdown-item" to="#">Another action</Link>
                                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Gender
                                                    </Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <div className="filter-drop-height">
                                                            <Link className="dropdown-item" to="#">Action</Link>
                                                            <Link className="dropdown-item" to="#">Another action</Link>
                                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Video Visit
                                                    </Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <div className="filter-drop-height">
                                                            <Link className="dropdown-item" to="#">Action</Link>
                                                            <Link className="dropdown-item" to="#">Another action</Link>
                                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Reviews
                                                    </Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <div className="filter-drop-height">
                                                            <Link className="dropdown-item" to="#">Action</Link>
                                                            <Link className="dropdown-item" to="#">Another action</Link>
                                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item float-right select-view">
                                                    <Link className="nav-link" to="/doctor-grid">
                                                <i className="fas fa-th"></i>
                                                    </Link>
                                                </li>
                                                <li className="nav-item float-right ">
                                                    <Link className="nav-link" to="/doctor-listing">
                                                        <i className="fas fa-th-list"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> 
                                    
                                    <div className="grid-view-div"> 
                                        <div className="row list-repeat-row"> 
                                        {this.state.pageOfItems.length > 0 ? (this.state.pageOfItems.map((val, i) => (
                                            <div className="col-lg-6 col-md-12" key={i}>
                                                <div className="doctor-list-box">
                                                    <div className="row">
                                                        <div className="doctor-list-img text-center col-md-3 align-self-center">
                                                        <Link to="#"><img src="assets/img/img-01.jpg"
                                                                    alt="..." /></Link> 
                                                        </div>
                                                        <div className="col-md-9 doctor-detail-box">
                                                            <h2 className="dr-name"><Link to="#">Dr. {val.firstname} {val.lastname} </Link>&nbsp;
                                                                <Link to="#" className="float-right margin-top-10"><i className="fas fa-heart"></i></Link>
                                                            </h2>
                                                            <p className="dr-specification ">{val.specialization_name}</p>

                                                            <ul className="star-ul">
                                                                <li><Link to="#"><i className="fa fa-star"></i></Link></li>
                                                                <li>
                                                                    <Link to="#"><i className="fa fa-star"></i></Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><i className="fa fa-star"></i></Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><i className="fas fa-star-half-alt"></i></Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><i className="far fa-star"></i></Link>
                                                                </li>
                                                                <li>
                                                                    <Link className="rating-count" to="#">(105 reviews)</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> 
                                                    <div className="gird-detail-box">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p className="dr-qualification">
                                                                {val.degree.map((value,i) => {
                                                                return value.toUpperCase();
                                                                })} - 
                                                                {val.specialization_name}</p>
                                                                <p className="dr-experience"><i className="fas fa-user-md"></i>{val.experience ? val.experience : '0'} years experience overall</p>
                                                                <p className="dr-location"><i className="fa fa-map-marker"></i> {val.city},{val.state},{val.country}</p>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-md-12 dr-fees-box  align-self-center no-padding"> 
                                                        <Link className="btn btn-light-black margin-top-10 float-right"
                                                            data-toggle="modal" data-target="#bookAppointment"
                                                            to="#bookAppointment">Consult
                                                            Online</Link>
                                                        <ul className="contects-ico float-right">
                                                            <li className="active-ico"><Link to="#"><i className="fas fa-phone"></i></Link>
                                                            </li>
                                                            <li className="active-ico"><Link to="#"><i className="fas fa-video"></i></Link>
                                                            </li>
                                                            <li className="active-ico"><Link to="#"><i
                                                                        className="fas fa-comment-alt"></i></Link></li>
                                                        </ul>
                                                    </div> 
                                                </div>
                                            </div> 
                                            ))
                                            ) : ''} 
                                            <div className="container-fluid no-padding">
                                                <div className="row pull-right">
                                                    {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                                                    <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                                                        &nbsp;
                                                        {this.state.pageOfItems.length > 0 ? (
                                                            <Pagination
                                                            pageSize={10}
                                                            items={this.state.totalItems}
                                                            onChangePage={this.onChangePage}
                                                            />
                                                        ) : (
                                                                ""
                                                        )} 
                                                    </div>
                                                </div>
                                            </div> 
                                        </div> 
                                        


                                    </div>
                                </div>
                                <div className="col-md-4 doctor-list-right-side-sec"> 
                            <div className="dr-list-ask-question">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2><i className="far fa-envelope"></i>&nbsp; Ask a FREE Question</h2>
                                    </div>
                                </div> 
                                <div className="dr-list-ask-question-textbox">
                                    <div className="row">
                                        <div className="col-md-12 align-self-center">
                                        <h4>Get FREE multiple opinions from Doctors</h4>
                                        <textarea className="form-control"
                                            placeholder="Enter question for you or someone else. E.g. my left eye is red and swollen..."
                                            id="exampleFormControlTextarea1" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 align-self-center">
                                            <p>Posted anonymously</p>
                                        </div>
                                        <div className="col-md-4 align-self-center">
                                            <Link to="#" className="btn btn-light-black  float-right">Submit</Link>
                                        </div>
                                    </div>
                                </div> 
                                
                            </div> 
                            <div className="dr-list-health-topic-box">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Top Health Tips</h2>
                                    </div>
                                </div> 
                                <div className="dr-list-topic-list-box">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 align-self-center">
                                                <h5><Link to="/health-feed">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                                <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                                            </div>
                                        </div>
                                </div> 
                                <div className="dr-list-topic-list-box">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 align-self-center">
                                                <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                                <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                                            </div>
                                        </div>
                                </div> 
                                <div className="dr-list-topic-list-box">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 align-self-center">
                                                <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                                <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                                            </div>
                                        </div>
                                </div> 
                                    <div className="dr-list-topic-list-box">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 align-self-center">
                                                <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                                <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                                            </div>
                                        </div>
                                    </div> 
                            </div>
                        </div> 
                            </div>  
                        </div>
                        
                    </div>


         <div className="modal fade loginModal-div" id="bookAppointment" tabIndex="-1" role="dialog"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
       <div className="modal-dialog modal-lg" role="document">
           <div className="modal-content">
               <div className="modal-header">
                   <h5 className="modal-title" id="exampleModalLabel">Book Appointment</h5>
                   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                   </button>
               </div>
               <div className="modal-body login-sec-modal">
                   <div className="bookAppointment-body-inner">
                       <div className="row"> 
                            <div className="col-md-12">
                                <div className="bookappointment-tab-side">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <Link className="nav-link active" id="audio-tab" data-toggle="tab" to="#audio"
                                                role="tab" aria-controls="audio" aria-selected="true"><i className="fas fa-phone"></i> &nbsp;Audio</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" id="video-tab" data-toggle="tab" to="#video"
                                                role="tab" aria-controls="video" aria-selected="false"><i className="fas fa-video"></i>&nbsp;Video</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" id="Chat-tab" data-toggle="tab" to="#Chat"
                                                role="tab" aria-controls="Chat" aria-selected="false"><i className="fas fa-comment-alt"></i>&nbsp;Chat</Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="audio" role="tabpanel" aria-labelledby="audio-tab">
                                            <h3>Book Audio Consultancy for Rs. 300</h3>
                                            <div className="upcoming-slots">
                                                <ul className="upcoming-slots-ul">
                                                    <li>
                                                        <Link to="#">Today, 6 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Tomorrow, 7 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sat, 8 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sun, 9 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Mon, 10 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Wed, 12 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sat, 15 Feb</Link>
                                                    </li>
                                                </ul>
                                            </div> 
                                            <div className="active-slots">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-1.png" alt="..." /></i>Morning</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM  </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-2.png" alt="..." /></i>Afternoon</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-3.png" alt="..." /></i>Evening</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                            </div> 
                                        </div> 
                                        <div className="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
                                            <h3>Book Video Consultancy for Rs. 800</h3>
                                            <div className="upcoming-slots">
                                                <ul className="upcoming-slots-ul">
                                                    <li>
                                                        <Link to="#">Today, 6 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Tomorrow, 7 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sat, 8 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sun, 9 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Mon, 10 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Wed, 12 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sat, 15 Feb</Link>
                                                    </li>
                                                </ul>
                                            </div> 
                                            <div className="active-slots">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-1.png" alt="..." /></i>Morning</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-2.png" alt="..." /></i>Afternoon</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-3.png" alt="..." /></i>Evening</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div>   
                                            </div> 
                                        </div> 
                                        <div className="tab-pane fade" id="Chat" role="tabpanel" aria-labelledby="Chat-tab">
                                            <h3>Book Chat Consultancy for Rs. 100</h3>
                                            <div className="upcoming-slots">
                                                <ul className="upcoming-slots-ul">
                                                    <li>
                                                        <Link to="#">Today, 6 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Tomorrow, 7 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sat, 8 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sun, 9 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Mon, 10 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Wed, 12 Feb</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sat, 15 Feb</Link>
                                                    </li>
                                                </ul>
                                            </div> 
                                            <div className="active-slots">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-1.png" alt="..." /></i>Morning</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-2.png" alt="..." /></i>Afternoon</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2><i className="slot-icos"><img src="assets/images/slot-ico-3.png" alt="..." /></i>Evening</h2>
                                                        <ul className="ul-active-slots">
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                            <li><Link to="#"> 06:30 AM </Link></li>
                                                        </ul>
                                                    </div>
                                                </div> 
                                            </div>  
                                            </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
               <div className="modal-footer">
                   <div className="col-md-12 text-center"> 
                   <button type="button" className="btn btn-light-black">Book Appointment</button>
                </div>
               </div>
           </div>
       </div>
   </div>
                </div> 
        );
    }
}

const mapStateToProps = state => ({
	Doctors: state.user.doctorListing, 
});

export default connect(mapStateToProps)(DoctorGrid);