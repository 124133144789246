import React from 'react'
import { connect } from 'react-redux'
import SimpleReactValidator from "simple-react-validator";
import { Link} from 'react-router-dom';
import { MakePayment, PaymentDetails } from "./../../store/actions/userActions";
import MaskedInput from "react-maskedinput";
import { creditExpiry } from "./../../store/helpers/validator";

const initialState = {
	card_number: '',
	cvv_no: '',
	// expiry_month: '',
	// expiry_year: '',
	expiry_date: '',
	amount: '',
	errorMessages: []
}

class Payment extends React.Component {
	constructor(props) {
		super(props)
		this.state = initialState;
		this.formValidator = new SimpleReactValidator({
			validators: {
			  validDate: {  // name the rule
				message: 'Expiry date must be greater than current date',
				rule: (val, params, validator) => {
					return val?creditExpiry(val):true;
				},
				messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
				required: true  // optional
			  }
			}
		  });
		this.myRef = React.createRef()
	}

	componentDidMount() {
		this.props.dispatch(PaymentDetails());
		this.myRef.current.scrollTo(0, 0);
	}

	RegisterHandler = async (e) => {
		const { amount } = this.props;
		if (this.formValidator.allValid()) {
			const {
				card_number,
				cvv_no,
				expiry_date
			} = this.state;
			let date = expiry_date.split("/");
			let senddata = {
				card_number: card_number,
				cvv: cvv_no,
				exp_month: date[0],
				exp_year: date[1],
				amount: amount
			};
			const response = await this.props.dispatch(MakePayment(senddata));
			if (response === 1) {
				// return <Redirect to='/doctor/payment'  />       
				this.props.history.push('/doctor/dashboard');
			}
		}
		else {
			const filtered = Object.keys(this.formValidator.errorMessages).filter(key => this.formValidator.errorMessages[key] !== null);
			let newArray = [];
			filtered.map(value => {
				return newArray = [...newArray, value.replace(/ /g, '_').toLowerCase()];
			})
			this.setState({ errorMessages: newArray })
			this.formValidator.showMessages();
			this.forceUpdate();
		}
	}
	render() {
		const { card_number, cvv_no, expiry_date } = this.state;
		const { amount } = this.props;
		return (
			<div className="row" ref={this.myRef}>
				<div className="col-md-12 col-lg-12">
					<div className="card">
						<div className="card-body">
							{/* -- Checkout Form -- */}
							<form action="booking-success.html">

								<div className="payment-widget">
									<h4 className="card-title">Payment Method</h4>

									{/* <!-- Credit Card Payment --> */}
									<div className="payment-list">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group card-label">
													<label>Card Number</label>
													<MaskedInput
														mask="1111 1111 1111 1111"
														name="card_number"
														id="cr_no"
														size="20"
														value={card_number}
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })}
													/>
													{/* <input
														type="text"
														className="form-control"
														name="card_number"
														value={this.state.card_number}
														placeholder="1234  5678  9876  5432"
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
														}
													/> */}
													{this.formValidator.message(
														"card_number",
														this.state.card_number,
														"required|card_num"
													)}
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group card-label">
													<label>CVV</label>
													<MaskedInput
														mask="111"
														className="placeicon"
														name="cvv_no"
														value={cvv_no}
														// placeholder="&#9679;&#9679;&#9679;"
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
														}
													/>
													{/* <input
														type="text"
														className="form-control"
														name="cvv_no"
														value={this.state.cvv_no}
														placeholder="CVV"
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
														}
													/> */}
													{this.formValidator.message(
														"cvv_no",
														this.state.cvv_no,
														"required|integer"
													)}
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group card-label">
													<label>Expiry Month</label>
													<MaskedInput
														mask="11/1111"
														id="exp"
														name="expiry_date"
														placeholder="MM/YYYY"
														value={expiry_date}
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
														}
													/>
													{this.formValidator.message(
														"Card Expiry",
														expiry_date,
														"required|validDate"
													)}
													{/* <input
														type="text"
														className="form-control"
														name="expiry_month"
														value={this.state.expiry_month}
														placeholder="Expiry Month"
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
														}
													/>
													{this.formValidator.message(
														"expiry_month",
														this.state.expiry_month,
														"required|integer"
													)} */}
												</div>
											</div>
											{/* <div className="col-md-4">
												<div className="form-group card-label">
													<label>Expiry Year</label>
													<input
														type="text"
														className="form-control"
														name="expiry_year"
														value={this.state.expiry_year}
														placeholder="Expiry Year"
														onChange={(e) =>
															this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
														}
													/>
													{this.formValidator.message(
														"expiry_year",
														this.state.expiry_year,
														"required|integer"
													)}
												</div>
											</div> */}

											<div className="col-md-6">
												<div className="form-group card-label">
													<label>Amount</label>
													<input
														type="text"
														className="form-control"
														name="amount"
														value={`${amount}`}
														placeholder="Amount"
														disabled
													/>
													{/* {this.formValidator.message(
														"amount",
														this.state.amount,
														"required|integer"
													)} */}
												</div>
											</div>
										</div>
									</div>
									{/* <!-- /Credit Card Payment --> */}

									{/* <!-- Submit Section --> */}
									<div className="submit-section mt-4">
										<Link
											to="#"
											className="btn btn-light-black submit-btn"
											onClick={e => this.RegisterHandler(e)}
										>
											Confirm and Pay
										</Link>
									</div>
									{/* <!-- /Submit Section --> */}
								</div>
							</form>
							{/* <!-- /Checkout Form --> */}
						</div>
					</div>
				</div>

			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	amount: state.user.amount
});

export default connect(mapStateToProps)(Payment); 
