import React from "react";
import Loaders from './../../components/General/Loaders'
class Footer extends React.Component {
  

  render() {
    return (
      <>
      <Loaders/>
       {/* <footer id="footer">
       <div className="container">
           <div className="row">
                <div className="col-md-2">
                <h3>Useful Links</h3>
                  <ul className="footer-ul">
                      <li><a href="#">Terms</a></li>
                      <li><a href="#">Agreement</a></li>
                      <li><a href="#">Feedback</a></li>
                      <li><a href="#">Help Support</a></li>
                      <li><a href="#">Refund</a></li>
                      <li><a href="#">Referral</a></li>
                  </ul>
                </div>
                <div className="col-md-2"> 
                    <ul className="footer-ul margin-top-30">
                        <li><a href="#">Corporate</a></li>
                        <li><a href="#">Research</a></li>
                        <li><a href="#">Community</a></li>
                        <li><a href="#">Order Supplies</a></li>
                    </ul>
                </div>
                <div className="col-md-2">
                    <h3>Follow Us</h3>
                    <ul className="footer-ul">
                        <li><a href="#"><i className="fab fa-facebook social-ico"></i>Facebook</a></li>
                        <li><a href="#"><i className="fab fa-instagram social-ico"></i>Instagram</a></li>
                        <li><a href="#"><i className="fab fa-twitter social-ico"></i>Tiwtter</a></li>
                        <li><a href="#"><i className="fab fa-linkedin-in social-ico"></i>Linkdin</a></li>
                    </ul>
                </div>
                 <div className="col-md-2">
                     <h3>Download App</h3>
                     <ul className="footer-ul dowload-app-ul">
                         <li><a href="#"><img src="./../assets/images/app-store.png" alt="..." /></a></li>
                         <li><a href="#"><img src="./../assets/images/play-store.png" alt="..." /></a></li>
                     </ul>
                 </div>
                <div className="col-md-4">
                    <h3>Our Partners</h3>
                    <div className="owl-carousel owl-theme partners-carousel" id="owl-carousel-partners">
                        <div className="item">
                            <a href="#">
                                <img src="./../assets/images/trend-1.png" alt="..." />
                            </a>
                        </div>
                        <div className="item">
                            <img src="./../assets/images/trend-2.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="./../assets/images/trend-3.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="./../assets/images/trend-4.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="./../assets/images/trend-3.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="./../assets/images/trend-1.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="./../assets/images/trend-2.png" alt="..." />
                        </div>
                    </div>
                </div>
           </div>
           
       </div>
   </footer>
   <div className="text-center copyright">
       <p>Copyright © 2020 Thelifeline.TV</p>
   </div> */}
      </>
    );
  }
}
export default Footer;
