import firebase from "firebase";

/* const config = {
    apiKey: "AIzaSyDgPGqzsH_6GNBO3_w2_gwW9up39hqDQRs",
    authDomain: "thelifeline-275005.firebaseapp.com",
    databaseURL: "https://thelifeline-275005.firebaseio.com",
    projectId: "thelifeline-275005",
    storageBucket: "thelifeline-275005.appspot.com",
    messagingSenderId: "891363325983",
    appId: "1:891363325983:web:0e1b22667b75fb2849bd2a",
    measurementId: "G-97MQSK9S7R",
};
*/
const config = {
  apiKey: "AIzaSyB5csKCXD27DQL1CqW-HEtB8F7eG9RTSiI",
  authDomain: "adidoc-d1485.firebaseapp.com",
  databaseURL: "https://adidoc-d1485.firebaseio.com",
  projectId: "adidoc-d1485",
  storageBucket: "adidoc-d1485.appspot.com",
  messagingSenderId: "500025355664",
  appId: "1:500025355664:web:9e4e5718762c2532ded715",
  measurementId: "G-2WLXL46B73"
};

firebase.initializeApp(config);
export const firebaseApp = firebase;
export const firebaseAuth = firebase.auth();
export const firebaseDatabase = firebase.database();
export const isFCMSupported = firebaseApp.messaging.isSupported();
