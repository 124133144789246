import * as types from "./../types/types";

const initalState = {
    patient_listing:[], 
    article_listing:[],
    recording_listing: [],
    counselor_reviews: [],
    invoice_listing: [],
    viewImage : [],
    doctor_id:'',
    counsellor_review_list:[],
    counsellor_article_list:[],
    patient_of_counsellor:[],
    appointment_listing:[],
    patient_appointment:[],
    counsellor_prescription_list:[],
    scheduled_timing:[],
    counsellor_today_appointments:[],
    counsellor_appointment_details:[],
    view_social_media_counsellor:[],
    counsellor_medical_records:[], 
    counsellor_appointment_listing:[]

}

const counselorPanelReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.COUNSELOR_PATIENTS:
            return {
                ...state,
                patient_listing:action.payload
            }
        case types.PATIENT_OF_DOCTOR:
            return {
                ...state,
                patient_listing:action.payload
            }
        case types.DOCTOR_PATIENT_APPOINTMENT_LIST:
            return {
                ...state,
                appointment_listing:action.payload
            }
        case types.ARTICLE_LISTING:
            return {
                ...state,
                article_listing:action.payload
            }
        case types.RECORDING_LISTING:
            return {
                ...state,
                recording_listing:action.payload
            }
        case types.COUNSELOR_REVIEWS:
            return {
                ...state,
                counselor_reviews:action.payload
            }
        case types.FETCH_PATIENT_INVOICES:
            return {
                ...state,
                invoice_listing:action.payload
            }
        case types.FETCH_HEADER_FOOTER_IMAGE:
            return {
                ...state,
                viewImage:action.payload
            }
        case types.FETCH_DOCTOR_ID:
            return {
                ...state,
                doctor_id:action.payload
            }
        case types.COUNSELLOR_REVIEWS_LIST:
            return {
                ...state,
                counsellor_review_list:action.payload
            }
        case types.COUNSELLOR_ARTICLE_LISTING:
            return {
                ...state,
                counsellor_article_list:action.payload
            }
        case types.PATIENT_OF_COUNSELLOR:
            return {
                ...state,
                patient_of_counsellor:action.payload
            }
        case types.COUNSELLOR_PATIENT_APPOINTMENT_LIST:
            return {
                ...state,
                patient_appointment:action.payload
            }
        case types.COUNSELLOR_PRESCRIPTIONS_LIST:
            return {
                ...state,
                counsellor_prescription_list:action.payload 
        }
        case types.FETCH_SCHEDULED_TIMING:
            return {
                ...state,
                loading: false,
                scheduled_timing: action.payload
            }
        case types.COUNSELLOR_APPOINTMENT_DETAILS:
            return {
                ...state,
                loading: false,
                counsellor_appointment_details: action.payload
            }
        case types.FETCH_TODAY_COUNSELLOR_APPOINTMENT_LISTING:
            return {
                ...state,
                loading: false,
                counsellor_today_appointments: action.payload
            }
        case types.VIEW_SOCIAL_MEDIA_COUNSELLOR:
            return {
                ...state,
                loading: false,
                view_social_media_counsellor: action.payload
            }
        case types.COUNSELLOR_MEDICAL_RECORDS_LIST:
            return {
                ...state,
                loading: false,
                counsellor_medical_records: action.payload
            }
        case types.COUNSELLOR_APPOINTMENT_LISTING:
            return {
                ...state,
                loading: false,
                counsellor_appointment_listing: action.payload
            }
        default:
            return state
    }
}

export default counselorPanelReducer;