export const options = {
    hosts: {
        domain: 'rtc-adidoc.cap-tek.com', // XMPP domain
        muc: 'conference.rtc-adidoc.cap-tek.com' // FIXME: use XEP-0030 // XMPP MUC domain. FIXME: use XEP-0030 to discover it.
    },
    bosh: 'https://rtc-adidoc.cap-tek.com/http-bind', // FIXME: use xep-0156 for that // BOSH URL. FIXME: use XEP-0156 to discover it.
    // The name of client node advertised in XEP-0115 'c' stanza
    clientNode: 'http://jitsi.org/jitsimeet', // The name of client node advertised in XEP-0115 'c' stanza
};

export const confOptions = {
    openBridgeChannel: true
};

// JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
export const initOptions = {
    disableAudioLevels: true,

    // The ID of the jidesha extension for Chrome.
    desktopSharingChromeExtId: 'mbocklcggfhnbahlnepmldehdhpjfcjp',

    // Whether desktop sharing should be disabled on Chrome.
    desktopSharingChromeDisabled: false,

    // The media sources to use when using screen sharing with the Chrome
    // extension.
    desktopSharingChromeSources: ['screen', 'window'],

    // Required version of Chrome extension
    desktopSharingChromeMinExtVersion: '0.1',

    // Whether desktop sharing should be disabled on Firefox.
    desktopSharingFirefoxDisabled: true
};