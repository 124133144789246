
import React from 'react'
import { connect } from 'react-redux'  
import { SocialMediaUrl,ViewSocialMediaUrl} from '../../store/actions/fitnessActions'

const initialState = { facebook:'',twitter:'',instagram:'',linkedin:'',youtube:'',pinterest:'', uniquekey : ''}

class FitnessSocialMedia extends React.Component{
    constructor(props){
        super(props)
        this.state =  initialState
	}


	componentDidMount(){
		this.props.dispatch(ViewSocialMediaUrl());
	}

	componentDidUpdate(prevProps, prevStates) {
		if (prevProps.social_media !== this.props.social_media) {
			let info = this.props.social_media ? this.props.social_media : ''
		  this.setState({ 
			  uniquekey : info.uniquekey ? info.uniquekey : '',
			  twitter:info.twitter ? info.twitter : '',
			  facebook : info.facebook ? info.facebook : '',
			  youtube : info.youtube ? info.youtube : '',
			  instagram : info.instagram ? info.instagram : '',
			  linkedin : info.linkedin ? info.linkedin : '',
			  pinterest : info.pinterest ? info.pinterest : ''
			});
		}
	  }
	
	onChangeHandler = (e) => {
		this.setState({
			[e.target.name]:e.target.value
		})
	}

	SaveUrl = (e) => { 
		const {facebook ,twitter ,instagram ,youtube ,linkedin } = this.state
		let id = this.state.uniquekey ? this.state.uniquekey : ''
		let data = {facebook:facebook,twitter:twitter,instagram:instagram,youtube:youtube,linkedin:linkedin,id:id}
		this.props.dispatch(SocialMediaUrl(data));
		e.preventDefault();
	}

    
    render () {  
        return (
            <div>  
                <div className="card">
					<div className="card-body add-social-media-section"> 
						<form>                                                                                           
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="form-group soc-ico-div">
										<label className="not_required">Facebook URL</label>
										<input type="text" className="form-control" name="facebook" value={this.state.facebook} onChange={e=>this.onChangeHandler(e)}/>
										<i className="fab fa-facebook-f"></i>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group soc-ico-div">
										<label className="not_required">Twitter URL</label>
										<input type="text" className="form-control" name="twitter" value={this.state.twitter} onChange={e=>this.onChangeHandler(e)} />
										<i className="fab fa-twitter"></i>
									</div>
								</div>
							</div> 
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="form-group soc-ico-div">
										<label className="not_required">Instagram URL</label>
										<input type="text" className="form-control" name="instagram" value={this.state.instagram} onChange={e=>this.onChangeHandler(e)} />
										<i className="fab fa-instagram"></i>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group soc-ico-div">
										<label className="not_required">Pinterest URL</label>
										<input type="text" className="form-control" name="pinterest" value={this.state.pinterest} onChange={e=>this.onChangeHandler(e)} />
										<i className="fab fa-pinterest-p"></i>
									</div>
								</div>
							</div> 
							<div className="row">
								<div className="col-12 col-md-6 soc-ico-div">
									<div className="form-group">
										<label className="not_required">Linkedin URL</label>
										<input type="text" className="form-control" name="linkedin" value={this.state.linkedin} onChange={e=>this.onChangeHandler(e)} />
										<i className="fab fa-linkedin-in"></i>
									</div>
								</div>
								<div className="col-12 col-md-6 soc-ico-div">
									<div className="form-group">
										<label className="not_required">Youtube URL</label>
										<input type="text" className="form-control" name="youtube" value={this.state.youtube} onChange={e=>this.onChangeHandler(e)} />
										<i className="fab fa-youtube"></i>
									</div>
								</div>
							</div> 
							<div className="submit-section">
								<button type="submit" className="btn btn-light-black submit-btn" onClick={e=>this.SaveUrl(e)}>Save Changes</button>
							</div>
						</form>  
					</div>
				</div> 
		</div> 
                )
            }
}
const mapStateToProps = state => ({ 
    social_media : state.fitness.view_social_media_fitness
});

export default connect(mapStateToProps)(FitnessSocialMedia); 