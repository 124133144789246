import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse,ApiRequest } from './../helpers/userServices';
import toastr from "reactjs-toastr";
import {ShowLoader,ShowAlert} from './staticListingsActions'
import swal from "sweetalert";

const typeArray = {'D':'doctor','H':'hospital','P':'pharmacy','U':'patient','L':'lab','C':'clinic','CR':'counselor','F':'fitness'};

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

export function getUserRegistration(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/registration`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 2000 });
            } else {
                toastr.error(data.ResponseText, { displayDuration: 2000 });
            }
        })
    }
}

export function getHospitalRegistration(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/hospital/registration`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
            }
        })
    }
}

export function getLabRegistration(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/lab/registration`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
            }
        })
    }
}

export function getPharmacyRegistration(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/pharmacy/registration`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
            }
        })
    }
}

export const fetchcountrylisting = (data) => {
    return {
        type: actionTypes.FETCH_COUNTRY_LISTING,
        payload: data
    }
}

export function getCountryList() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/country/list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(fetchcountrylisting(data.data));
            }
        });
    }
}

export function SendOtpMail(data) {
    return dispatch => {
        return 1;
    }
}

export function VerifyOtpMail(data) {
    return dispatch => {
        return 0;
    }
}

export function MedicalCouncils() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/medical/council`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_MEDICAL_COUNCILS, payload: data.data })
            } else {
                return false
            }
        });
    }
}

export function DoctorCounsellorListing() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/doctor-counsellor-listing`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_DOCTOR_COUNSELLOR_LISTING, payload: data.data })
            } else {
                return false
            }
        });
    }
}

export function RegistrationUser(data, type) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/signUP`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                if (type === "doctor") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "doctor");
                } else if (type === "user") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "patient");
                } else if (type === "hospital") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "hospital");
                } else if (type === "lab") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "lab");
                } else if (type === "pharmacy") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "pharmacy");
                }else if (type === "counselor") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "counselor");
                }else if (type === "fitness") {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('data', JSON.stringify(data.data));
                    localStorage.setItem('type', "fitness");
                }
                return 1
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0
            }
        });
    }
}

export const login = (getData) => {
    return  dispatch => {
        const response = ApiRequest(`/api/login`,"POST",false,getData);
        return response.then(response=>{
            if(response.ResponseCode==="1"){
                localStorage.setItem('token', response.token);
                localStorage.setItem('data', JSON.stringify(response.data));
                localStorage.setItem('type', typeArray?.[response.type]?typeArray[response.type]:'doctor');
                if(getData.rememberMe){
                    sessionStorage.setItem('_user_details',JSON.stringify({username:getData.email,password:getData.password}));
                }else{
                    sessionStorage.removeItem('_user_details');
                }
                return 1;
            }else{
                swal({
                    title: response.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        }).catch(error=>{
            console.log('error',error)
        })
    }
}

export function profileInfo() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/profile-info`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_PROFILE_INFO, payload: data.data })
            }
        });
    }
}

export function MakePayment(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/stripe/make-payment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0;
            }
        });
    }
}

export function PaymentDetails() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/get-area-charge`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.FETCH_AREA_PRICE,payload: data.ResponseText})
                dispatch(ShowLoader(false));
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
            }
        });
    }
}

export function EditProfile(data) {

    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/editProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(profileInfo());
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
            }
        });
    }
}

export function submitQuery(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/create-contactUs`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
            }
        });
    }
}

export function AjaxValidator(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/authorization-at-signup`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                return 1;
            } else {
                return 0;
            }
        });
    }
}


export function UserProfilesEdit(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/edit-profile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(profileInfo());
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                return 1;
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:5000}));
                return 0;
            }
        });
    }
}

export function scheduleTimings(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/doctors-timing-schedule`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                window.$('#add_time_slot').modal('hide');
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(scheduleTimingsView());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        });
    }
}

export function scheduleTimingsView() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/doctors-timing-view`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_SCHEDULED_TIMING, payload: data.data})
                return 1;
            }
        });
    }
}

export function UpdateScheduleTimings(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/update-doctors-timing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(scheduleTimingsView())
                return 1;
            }
        });
    }
}

export function ForgotPasswordApi(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/forgot-password`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                // window.$('#loginModal1').modal("hide");
                return 1;
            }else{
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        });
    }
}


export function getDoctorList(search='', location='', symptoms='', specializations='', gender='') {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/get-all-doctors`, {
            method: 'POST',
            body: JSON.stringify({search:search, location:location, symptoms:symptoms, specializations:specializations, gender:gender}),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_DOCTOR_LISTING,payload: data.data});
            }
        });
    }
}

export function GetAppointmentList(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_APPOINTMENT_LISTING,payload: data.data});
            }
        });
    }
}

export function GetTodayAppointmentList(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/appointments/today`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch(ShowLoader(false));
                dispatch({type: actionTypes.FETCH_TODAY_APPOINTMENT_LISTING,payload: data.data});
            } else {
                dispatch(ShowLoader(false));
                return 0;
            }
        });
    }
}

export function GetDrAppointmentDetails() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor_appointments_record`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch(ShowLoader(false));
                dispatch({type: actionTypes.DR_APPOINTMENT_DETAILS,payload: data.data});
            } else {
                dispatch(ShowLoader(false));
                return 0;
            }
        });
    }
}

export function CancelAppointment(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/cancel-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:"Appointment cancelled successfully.",Timeout:1500}));
                return 1;
            } else {
                swal({
                    title: "Something went wrong. Please try again later.",
                    icon: "error",
                    dangerMode: true
                })
            }
        });
    }
}

export function sendForApproval(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/send-approval`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(profileInfo());
                return 1;
            } else {
                swal({
                    title: data.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        });
    }
}


export function sendForCounsellorApproval(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/send-approval`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(profileInfo());
                return 1;
            } else {
                swal({
                    title: data.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        });
    }
}

export function sendForFitnessExpertApproval(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/send-approval`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(profileInfo());
                return 1;
            } else {
                swal({
                    title: data.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        });
    }
}

export function SocialMediaUrl(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add/edit-doctor-social-media`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(ViewSocialMediaUrl());
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:1500}));
                return 0;
            }
        });
    }
}

export function ViewSocialMediaUrl(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/view-doctor-social-media`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.VIEW_SOCIAL_MEDIA,payload: data.data});
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:1500}));
                return 0;
            }
        });
    }
}


export function ViewHomeArticle() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/random-article-list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json'})
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.VIEW_HOME_ARTICLE,payload: data.data});
            } else {
                return 0;
            }
        });
    }
}

export function EditCounsellorProfile(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/councillor/editProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(profileInfo());
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
            }
        });
    }
}

export function EditFitnessExpertProfile(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/editProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(profileInfo());
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
            }
        });
    }
}

export function getCounsellorList(search='', location='', symptoms='', specializations='', gender='') {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/get-all-counselors`, {
            method: 'POST',
            body: JSON.stringify({search:search, location:location, symptoms:symptoms, specializations:specializations, gender:gender}),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_COUNSELLOR_LISTING,payload: data.data});
            }
        });
    }
}


export function getSubTypeListing() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/councillor-Symptoms-Subtypelist`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_SUBTYPE_LISTING,payload: data.data});
            }
        });
    }
}

