import React from "react";
import AppRouter from "./Routes/Router";
import { firebaseApp } from "./store/helpers/firebaseConfig";
import { connect } from "react-redux";
import { FirebaseStoreDeviceToken } from "./store/actions/globalActions";

const isFCMSupported = firebaseApp.messaging.isSupported();
const firebaseMessaging = isFCMSupported ? firebaseApp.messaging() : null;
class App extends React.Component {
    componentDidMount() {
        document.querySelector("body").addEventListener("scroll", () => {
            if (isFCMSupported) {
                firebaseMessaging
                    .requestPermission()
                    .then(() => {
                        return firebaseMessaging.getToken();
                    })
                    .then((token) => {
                        if (token) {
                            this.sendTokenToServer(token);
                        } else {
                            console.log("No Instance ID token available. Request permission to generate one.");
                            this.setTokenSentToServer(false);
                        }
                    })
                    .catch((err) => {
                        console.log("An error occurred while retrieving token. ", err);
                        this.setTokenSentToServer(false);
                    });

                firebaseMessaging.onTokenRefresh(() => {
                    firebaseMessaging
                        .getToken()
                        .then((refreshedToken) => {
                            console.log("Token refreshed.");
                            // Indicate that the new Instance ID token has not yet been sent to the
                            // app server.
                            this.setTokenSentToServer(false);
                            // Send Instance ID token to app server.
                            this.sendTokenToServer(refreshedToken);
                            // ...
                        })
                        .catch((err) => {
                            console.log("Unable to retrieve refreshed token ", err);
                        });
                });

                firebaseMessaging.onMessage((payload) => {
                    console.log("Message received. ", payload);
                    // ...
                });
            }
        });
    }

    sendTokenToServer = async (currentToken) => {
        if (!this.isTokenSentToServer()) {
            console.log("Sending token to server...");
            // TODO(developer): Send the current token to your server.
            if (localStorage.getItem("token")) {
                const response = await this.props.FirebaseStoreDeviceToken({ device_token: currentToken });
                if (response === 1) {
                    this.setTokenSentToServer(true);
                }
            }
        }
    };

    setTokenSentToServer = (sent) => {
        localStorage.setItem("sentToServer", sent ? "1" : "0");
    };

    isTokenSentToServer = () => {
        return localStorage.getItem("sentToServer") === "1";
    };

    render() {
        return (
            <>
                <AppRouter />
            </>
        );
    }
}

export default connect(null, { FirebaseStoreDeviceToken })(App);
