import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { initOptions } from './Options';
import { jitsiConnection } from './Connection';
import { jitsiRoom, manageRoomEvents, createLocalTrack, manageRoomLeave, changeDeviceTrack } from './Room';
//import Chat from './Chat';

class Conference extends Component {
    constructor(props) {
        super(props)
        this.state = {
            connection: null,
            isJoined: false,
            room: null,
            localTracks: [],
            remoteTracks: {},
            remoteUsersDetails: {},
            isAudioMuted: false,
            isVideoMuted: false,
            cameraDeviceId: '',
            micDeviceId: '',
            roomName: 'testing',
            displayName: 'Guest',
            JitsiMeetJS: null,
            openModal: false,
            message: '',
            duration: '00:00:00',
            showSupportButtons: false
        };
    }

    componentDidMount() {
        const checkJitsiLoadInterval = setInterval(() => {
            if (window.JitsiMeetJS) {
                window.JitsiMeetJS.init(initOptions);
                this.setState({ connection: jitsiConnection(window.JitsiMeetJS), JitsiMeetJS: window.JitsiMeetJS }, () => {
                    createLocalTrack(this);
                    this.state.connection.addEventListener(this.state.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,() => {
                        this.handleClick();
                    });
                });
                clearInterval(checkJitsiLoadInterval);
            }
        }, 100);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleClick = () => {
        const { connection, roomName } = this.state;
        let room = jitsiRoom(connection, roomName);
        // event.target.innerHTML = 'Please wait...';
        this.setState({ room }, () => {
            manageRoomEvents(this)
        });
    }


    AudioMuteUnmute = (event) => {
        let element = event.target;
        this.state.isAudioMuted
            ? this.setState({ isAudioMuted: false }, () => this.manageAudioMuted(element))
            : this.setState({ isAudioMuted: true }, () => this.manageAudioMuted(element))
    }

    manageAudioMuted = (element) => {
        let { localTracks, isAudioMuted } = this.state;
        localTracks.forEach((track, index) => {
            if (track.type === "audio" && !track.videoType) {
                isAudioMuted
                    ? localTracks[index].mute()
                        .then(d => {
                            element.classList.remove("fa-microphone");
                            element.classList.add("fa-microphone-slash");
                            // element.innerHTML = `<i class="fas fa-microphone-slash bottom-call-icon"></i>`;
                        })
                        .catch(err => console.log("Audio mute Error", err))
                    :
                    localTracks[index].unmute().then(d => {
                        element.classList.add("fa-microphone");
                        element.classList.remove("fa-microphone-slash");
                        // element.innerHTML = `<i class="fas fa-microphone bottom-call-icon">Unmute</i>`;
                    })
                        .catch(err => console.log("Audio mute Error", err))
            }
        })
    }

    VideoMuteUnmute = (event) => {
        let element = event.target;
        this.state.isVideoMuted
            ? this.setState({ isVideoMuted: false }, () => this.manageVideoMuted(element))
            : this.setState({ isVideoMuted: true }, () => this.manageVideoMuted(element))
    }

    manageVideoMuted = (element) => {
        let { localTracks, isVideoMuted } = this.state;
        localTracks.forEach((track, index) => {
            if (track.videoType === "camera") {
                isVideoMuted
                    ? localTracks[index].mute()
                        .then(d => {
                            element.classList.remove("fa-video");
                            element.classList.add("fa-video-slash");
                        })
                        .catch(err => console.log("Video mute Error", err))
                    :
                    localTracks[index].unmute().then(d => {
                        element.classList.add("fa-video");
                        element.classList.remove("fa-video-slash");
                    })
                        .catch(err => console.log("Video mute Error", err))
            }
        })
    }

    handleLeave = () => {
        manageRoomLeave(this);
    }

    openChat = () => {
        this.setState({ openModal: true })
    }

    closeChat = () => {
        this.setState({ openModal: false })
    }

    handleSubmit = () => {
        if(this.state.message) {
            this.sendMessage(this.state.message);
            this.setState({message: ''});
        }
    }

    sendMessage = (message) => {
        this.state.room.sendTextMessage(message);
    }

    changeVideoIntput = (event) => {
        this.setState({ cameraDeviceId: event.target.value }, () => {
            changeDeviceTrack(this);
        });
    }

    changeAudioInput = (event) => {
        console.log(event.target.value);
        this.setState({ micDeviceId: event.target.value }, () => {
            changeDeviceTrack(this);
        });
    }

    changeAudioOutput = (event) => {
        this.state.JitsiMeetJS.mediaDevices.setAudioOutputDevice(event.target.value);
    }

    render() {
        return (
            <React.Fragment>
                {/* <div className="devices-container mb-20">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="videoInputSelectWrapper" style={{ display: "none" }}>
                                <div className="col-md-6">
                                    Change video input device
                                </div>
                                <div className="col-md-6">
                                    <select id="videoInputSelect" className="form-control" value={this.state.cameraDeviceId} onChange={this.changeVideoIntput}></select>
                                </div>
                            </div>
                            <div id="audioInputSelectWrapper" style={{ display: "none" }}>
                                <div className="col-md-6">
                                    Change audio input device
                                </div>
                                <div className="col-md-6">
                                    <select id="audioInputSelect" className="form-control" value={this.state.micDeviceId} onChange={this.changeAudioInput} ></select>
                                </div>
                            </div>
                            <div id="audioOutputSelectWrapper" style={{ display: "none" }}>
                                <div className="col-md-6">
                                    Change audio output device
                                </div>
                                <div className="col-md-6">
                                    <select id="audioOutputSelect" className="form-control" onChange={this.changeAudioOutput}></select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="details-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" name="displayName" id="name" value={this.state.displayName} placeholder="name" onChange={this.handleChange} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="room">room</label>
                                    <input type="text" className="form-control" name="roomName" id="room" value={this.state.roomName} placeholder="room" onChange={this.handleChange} />
                                </div>
                                <div className="col-md-2" style={{ marginTop: '32px' }}>
                                    <button className="btn btn-lg btn-primary" id="join" onClick={this.handleClick} disabled={!this.state.connection}>Join</button>
                                </div>
                                <div className="col-md-4" style={{ marginTop: '32px' }}>
                                    <button className="btn btn-lg btn-danger" id="leave" style={{ "display": this.state.isJoined ? "block" : "none" }} onClick={this.handleLeave}>Leave</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="conference-container">
                    <div className="row">
                        <div className="col-md-5">
                            <h1>Local Video</h1>
                            <div className="local-container mt-10">
                                <video style={{ width: "100%" }} id="localVideo" autoPlay={true}></video>
                                <audio id="localAudio" autoPlay={true} muted={true}></audio>
                            </div>
                            <div className="button-container" style={{ "display": this.state.isJoined ? "block" : "none" }}>
                                <button onMouseUp={this.AudioMuteUnmute}>Mute</button>
                                <button onMouseUp={this.VideoMuteUnmute}>Video Mute</button>
                                <button onMouseUp={this.openChat}>Chat</button>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                            <h1>Remote Video</h1>
                            <div id="remote-container" className="remote-container mt-10">

                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <Chat sendMessage={(message) => this.sendMessage(message)} openModal={this.state.openModal} closeModal={this.closeChat} /> */}

                <div>
                    <div className="main-wrapper">
                        <div className="content message-window-content">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="chat-window">
                                        <div className=" chat-cont-right chat-cont-right-1">
                                            <div className="chat-header">
                                                <Link to="#" id="back_user_list" className="back-user-list">
                                                    <i className="material-icons">chevron_left</i>
                                                </Link>
                                                <div className="media">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-online">
                                                            <img src="assets/img/patients/patient.jpg" alt="User"
                                                                className="avatar-img rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="user-name">{this.state.displayName}</div>
                                                        {/* <div className="user-status">online</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chat-body">
                                                <div className="chat-scroll">
                                                    <ul className="list-unstyled" id="message-container">
                                                        {/* <li className="media sent">
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>Hello. What can I do for you?</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:30 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="media received">
                                                            <div className="avatar">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className="avatar-img rounded-circle" />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>I'm just looking around.</p>
                                                                        <p>Will you tell me something about yourself?</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:35 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>Are you there? That time!</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:40 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <div className="chat-msg-attachments">
                                                                            <div className="chat-attachment">
                                                                                <img src="assets/img/img-02.jpg"
                                                                                    alt="Attachment" />
                                                                                <div className="chat-attach-caption">placeholder.jpg
                                                                        </div>
                                                                                <a href="" className="chat-attach-download">
                                                                                    <i className="fas fa-download"></i>
                                                                                </a>
                                                                            </div>
                                                                            <div className="chat-attachment">
                                                                                <img src="assets/img/img-03.jpg"
                                                                                    alt="Attachment" />
                                                                                <div className="chat-attach-caption">placeholder.jpg
                                                                        </div>
                                                                                <a href="" className="chat-attach-download">
                                                                                    <i className="fas fa-download"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:41 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="media sent">
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>Where?</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:42 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>OK, my name is Limingqiang. I like singing, playing
                                                                    basketballand so on.</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:42 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <div className="chat-msg-attachments">
                                                                            <div className="chat-attachment">
                                                                                <img src="assets/img/img-04.jpg"
                                                                                    alt="Attachment" />
                                                                                <div className="chat-attach-caption">placeholder.jpg
                                                                        </div>
                                                                                <a href="" className="chat-attach-download">
                                                                                    <i className="fas fa-download"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:50 AM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="media received">
                                                            <div className="avatar">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className="avatar-img rounded-circle" />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>You wait for notice.</p>
                                                                        <p>Consectetuorem ipsum dolor sit?</p>
                                                                        <p>Ok?</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>8:55 PM</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="chat-date">Today</li>
                                                        <li className="media received">
                                                            <div className="avatar">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className="avatar-img rounded-circle" />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                    elit,</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>10:17 AM</span>
                                                                                </div>
                                                                            </li>
                                                                            <li><a href="#">Edit</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="media sent">
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>Lorem ipsum dollar sit</p>
                                                                        <div className="chat-msg-actions dropdown">
                                                                            <a href="#" data-toggle="dropdown"
                                                                                aria-haspopup="true" aria-expanded="false">
                                                                                <i className="fe fe-elipsis-v"></i>
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <a className="dropdown-item" href="#">Delete</a>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>10:19 AM</span>
                                                                                </div>
                                                                            </li>
                                                                            <li><a href="#">Edit</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="media received">
                                                            <div className="avatar">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className="avatar-img rounded-circle" />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <div className="msg-typing">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="chat-footer" style={{ "display": this.state.isJoined ? "block" : "none" }}>
                                                <div className="input-group">
                                                    {/* <div className="input-group-prepend">
                                                        <div className="btn-file btn">
                                                            <i className="fa fa-paperclip"></i>
                                                            <input type="file" />
                                                        </div>
                                                    </div> */}
                                                    <input
                                                        type="text"
                                                        className="input-msg-send form-control"
                                                        id="message"
                                                        name="message"
                                                        onKeyDown={(event) => { if(event.key === "Enter") { this.handleSubmit() }} }
                                                        placeholder="Type something"
                                                        value={this.state.message}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="input-group-append">
                                                        <button type="button" className="btn msg-send-btn" onClick={this.handleSubmit}><i className="fab fa-telegram-plane"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chat-cont-right chat-cont-right-2">
                                            <div className="chat-body">
                                                <div className="audio-call-screen">
                                                    <div className="col-md-12 align-self-center">
                                                        <div className="audio-call-inner">

                                                            <div className="top-bar col-md-12">
                                                                <div id="remote-container">
                                                                <div className="local-container" style={{position: "absolute"}}>
                                                                    <video style={{ width: "20%", float: "right", height: "auto !important" }} id="localVideo" autoPlay={true}></video>
                                                                    <audio id="localAudio" autoPlay={true} muted={true}></audio>
                                                                </div>
                                                                { this.state.isJoined && <p id="duration">{this.state.duration}</p>}
                                                                { this.state.showSupportButtons
                                                                && <>
                                                                    <button className="btn btn-sm bg-primary-light"><i className="fas fa-calendar-day"></i> Reschedule</button>
                                                                    <button className="btn btn-sm bg-danger-light"><i className="fas fa-calendar-times"></i> Cancel</button>
                                                                    <button className="btn btn-sm bg-info-light"><i className="fas fa-phone-volume"></i> Call Support</button>
                                                                </>}
                                                                <p id="wait-message">Please wait for another user to join call</p>
                                                                </div>
                                                                {/* <a href={void(0)} className="duration">
                                                                    00:00:00
                                                                </a>
                                                                <div className="second-party float-right">
                                                                </div> */}
                                                            </div>
                                                            {/* <div className="avatar-audio">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className=" rounded-circle" />
                                                            </div> */}
                                                            <div className="calling-ico button-container" style={{ "display": this.state.isJoined ? "block" : "none" }}>
                                                                <Link to="#" onMouseUp={this.AudioMuteUnmute}><i className="fas fa-microphone bottom-call-icon"></i></Link>
                                                                {/* <a href={void(0)} onMouseUp={this.VideoMuteUnmute}><i className="fas fa-video  bottom-call-icon"></i></a> */}
                                                                <Link to="#" onClick={this.handleLeave}><i className="call-end"><img
                                                                    src="assets/img/call.png" alt="user"/> </i> </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade call-modal" id="voice_call">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="call-box incoming-box">
                                        <div className="call-wrapper">
                                            <div className="call-inner">
                                                <div className="call-user">
                                                    <img alt="User" src="assets/img/patients/patient.jpg" className="call-avatar" />
                                                    <h4>Richard Wilson</h4>
                                                    <span>Connecting...</span>
                                                </div>
                                                <div className="call-items">
                                                    <Link to="#" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons">call_end</i></Link>
                                                    <Link to="#" className="btn call-item call-start"><i className="material-icons">call</i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade call-modal" id="video_call">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="call-box incoming-box">
                                        <div className="call-wrapper">
                                            <div className="call-inner">
                                                <div className="call-user">
                                                    <img className="call-avatar" src="assets/img/patients/patient.jpg" alt="User" />
                                                    <h4>Richard Wilson</h4>
                                                    <span>Calling ...</span>
                                                </div>
                                                <div className="call-items">
                                                    <Link to="#" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons">call_end</i></Link>
                                                    <Link to="video-call.html" className="btn call-item call-start"><i className="material-icons">videocam</i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
    };
  }


export default connect(mapStateToProps)(Conference);
