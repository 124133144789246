import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { PrescriptionsList } from '../../store/actions/registerationActions';
import { SubmitPrescriptions, DeletePrescriptions } from '../../store/actions/doctorPanelActions';
import Pagination from '../../store/helpers/Pagination';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Modals from './../General/Modals'
import SimpleReactvalidator from 'simple-react-validator';
import swal from 'sweetalert';
import { apiBaseUrl } from '../../store/helpers/common';

let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0');
const yyyy = today.getFullYear();

today = dd + '-' + mm + '-' + yyyy;
//const item = { name: '', quantity: 0, days: [], time: [] }
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const slots = ['Morning', 'Noon', 'Evening', 'Night'];
const InterVals = ['Daily', 'Weekly', 'Monthly']
const Dose = ['Before Meal', 'After Meal'];
const Type = ['Tablet', 'Syrup' ,'Capsule'];
let prescriptionName = {};
let prescriptionDose = {};
let prescriptionType = {};
let prescriptionQuantity = {};
let prescriptionDays = {};
let prescriptionTime = {};
let prescriptionInterval = {};
let prescriptionIntCount = {};
let prescriptionDescription = {};

[...Array(10).keys()].map(values => {
    prescriptionName[`name_${values}`] = '';
    prescriptionQuantity[`quantity_${values}`] = '';
    prescriptionDays[`day_slots_${values}`] = [];
    prescriptionTime[`time_slots_${values}`] = [];
    prescriptionInterval[`interval_${values}`] = '';
    prescriptionIntCount[`count_${values}`] = '';
    prescriptionDescription[`description_${values}`] = '';
    prescriptionDose[`dose_${values}`] = '';
    prescriptionType[`type_${values}`] = '';
    return null
})
class Prescription extends Component {
    constructor(props) {
        super(props)
        this.state = { pageOfItems: [], currentPage: 1, patientDetail: {}, open: false, isEdit: false, totalItems: [0], updateID: 0 };
        Object.assign(
            this.state,
            prescriptionName,
            prescriptionQuantity,
            prescriptionDays,
            prescriptionTime,
            prescriptionInterval,
            prescriptionIntCount,
            prescriptionDescription,
            prescriptionDose,
            prescriptionType
        );
        this.doctorDetails = JSON.parse(localStorage.data);
        this.sigPad = {}
        this.validator = new SimpleReactvalidator();
    }

    componentDidMount() {
        const postData = { uniquekey: JSON.parse(localStorage.data).uniquekey, user_key: this.props.user_key };
        this.props.dispatch(PrescriptionsList(postData));
    }

    addItem = (value) => {
        this.setState({ totalItems: [...this.state.totalItems, value] })
    }

    removeItem = id => {
        this.setState({ totalItems: this.state.totalItems.filter((value, key) => key !== id) })
    }

    onChangeHandler = e => {
        let value = e.target.value
        // value = value.replace(/[^0-9]/ig, '')
        this.setState({ [e.target.name]: value })
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        Object.assign(
            this.state,
            prescriptionName,
            prescriptionQuantity,
            prescriptionDays,
            prescriptionTime,
            prescriptionInterval,
            prescriptionIntCount,
            prescriptionDescription,
            prescriptionDose,
            prescriptionType
        );
        this.setState({
            open: false, isEdit: false, totalItems: [0]
        });
    };

    onClickSlot = (e, day) => {
        if (e.target.checked) {
            this.setState({ [e.target.name]: [...this.state[e.target.name], day] });
        } else {
            let array = [...this.state[e.target.name]]
            let index = array.indexOf(day);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ [e.target.name]: array });
            }
        }
    }



    onSubmitPrescription = async (e) => {
        let name = [];
        let quantity = [];
        let day_slots = [];
        let time_slots = [];
        let interval = [];
        let count = [];
        let dose = [];
        let type = [];
        const { totalItems, updateID } = this.state
        totalItems.map(values => {
            name = [...name, this.state[`name_${values}`]]
            quantity = [...quantity, this.state[`quantity_${values}`]]
            day_slots = [...day_slots, this.state[`day_slots_${values}`]]
            time_slots = [...time_slots, this.state[`time_slots_${values}`]]
            interval = [...interval, this.state[`interval_${values}`]]
            count = [...count, this.state[`count_${values}`]]
            dose = [...dose, this.state[`dose_${values}`]]
            type = [...type, this.state[`type_${values}`]]
            // description        = [...description,this.state[`description_${values}`]]
            return null;
        })
        if (this.validator.allValid()) {
            let response = await this.props.dispatch(
                SubmitPrescriptions(
                    {
                        user_key: this.props.user_key,
                        uniquekey: JSON.parse(localStorage.data).uniquekey,
                        updateID: updateID,
                        prescription: { name, quantity, day_slots, time_slots, interval, count, dose, type }
                    }
                )
            );
            if (response === 1) {
                this.setState({ open: false, isEdit: false, updateID: 0 }, () => {
                    Object.assign(
                        this.state,
                        prescriptionName,
                        prescriptionQuantity,
                        prescriptionDays,
                        prescriptionTime,
                        prescriptionInterval,
                        prescriptionIntCount,
                        prescriptionDescription,
                        prescriptionDose,
                        prescriptionType
                    );
                })
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    EditPrescription = value => {
        if (value.prescription) {
            let prescriptions = JSON.parse(value.prescription);
            if (prescriptions.name && (prescriptions.name instanceof Array)) {
                let array = [];
                let prescriptionName = {};
                let prescriptionQuantity = {};
                let prescriptionDays = {};
                let prescriptionTime = {};
                let prescriptionInterval = {};
                let prescriptionIntCount = {};
                (prescriptions.name).map((mapValue, mapIndex) => {
                    array = [...array, mapIndex];
                    prescriptionName[`name_${mapIndex}`] = mapValue;
                    prescriptionQuantity[`quantity_${mapIndex}`] = prescriptions.quantity[mapIndex];
                    prescriptionDays[`day_slots_${mapIndex}`] = prescriptions.day_slots[mapIndex];
                    prescriptionTime[`time_slots_${mapIndex}`] = prescriptions.time_slots[mapIndex];
                    prescriptionInterval[`interval_${mapIndex}`] = prescriptions.interval[mapIndex];
                    prescriptionIntCount[`count_${mapIndex}`] = prescriptions.count[mapIndex];
                    prescriptionDose[`dose_${mapIndex}`] = prescriptions.dose[mapIndex];
                    prescriptionType[`type_${mapIndex}`] = prescriptions.type[mapIndex];
                    return null;
                })
                Object.assign(
                    this.state,
                    prescriptionName,
                    prescriptionQuantity,
                    prescriptionDays,
                    prescriptionTime,
                    prescriptionInterval,
                    prescriptionIntCount,
                    prescriptionDose,
                    prescriptionType
                )
                this.setState({ open: true, totalItems: array, updateID: value.id });
            }
        }


    }

    deletePrescription = value => {
        swal({
            title: "Are you sure want to delete this prescription?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const data = { user_key: this.props.user_key, uniquekey: JSON.parse(localStorage.data).uniquekey, id: value };
                    this.props.dispatch(DeletePrescriptions(data));
                }
            });
    }

    onClearForm = () => {
        Object.assign(
            this.state,
            prescriptionName,
            prescriptionQuantity,
            prescriptionDays,
            prescriptionTime,
            prescriptionInterval,
            prescriptionIntCount,
            prescriptionDescription,
            prescriptionDose,
            prescriptionType
        );
        this.setState({
            open: true, isEdit: false, totalItems: [0]
        });
    }

    render() {
        const { prescriptions_list } = this.props
        const { pageOfItems, open, totalItems } = this.state
        const userType = localStorage.getItem('type');
        return (
            <>
                {userType === 'doctor' && <div className="text-right">
                    <Link to="#" className="add-new-btn" onClick={e => this.setState({ open: true })}>Add Prescription</Link>&nbsp;&nbsp;
                    <Link to="/counsellor/prescription-preview" className="add-new-btn" >Add Template</Link>
                </div>}
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>Date </th>
                                        <th>Name</th>
                                        <th>Created By</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageOfItems && pageOfItems.map((value, index) => {
                                        return (
                                            <tr key={`prescription_${index}`}>
                                                <td>{value.creationdate ? moment(value.creationdate).format('LL') : 'NA'}</td>
                                                <td>{value.uniquekey}</td>
                                                <td>{value.doctor_name}</td>
                                                <td className="text-right">
                                                    <div className="table-action">
                                                        <Link to="#" className="btn btn-sm bg-info-light" onClick={e => this.EditPrescription(value)}>
                                                            <i className="far fa-eye"></i> View
                                                        </Link>
                                                        <a href={apiBaseUrl + "/api/download-prescription_pdf?prescription_number=" + value.uniquekey} target="_blank" className="btn btn-sm bg-primary-light" rel="noopener noreferrer">
															<i className="fa fa-download"></i> Download
														</a>
                                                        <Link to="#" className="btn btn-sm bg-danger-light" onClick={e => this.deletePrescription(value.id)}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {prescriptions_list.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={prescriptions_list}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* modal for add prescription */}

                <Modals open={open} setOpen={this.setOpen} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.updateID > 0 ? "View" : "Add"} Prescription
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="biller-info">
                                        <h4 className="d-block">{`Dr ${this.doctorDetails.firstname} ${this.doctorDetails.lastname}`}</h4>
                                        <span className="d-block text-sm text-muted">{this.doctorDetails.specialization_name}</span>
                                        <span className="d-block text-sm text-muted">{this.doctorDetails.city}, {this.doctorDetails.country}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-sm-right">
                                    <div className="billing-info">
                                        <h4 className="d-block">{today}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="add-more-item text-right">
                                {totalItems.length < 10 && <Link onClick={e => this.addItem(totalItems.length)} to='#'><i className="fas fa-plus-circle"></i> Add Item</Link>}
                            </div>
                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover add-prescription-table table-center">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: "10%" }}>Type</th>
                                                    <th style={{ minWidth: "20%" }}>Name</th>
                                                    <th style={{ minWidth: "10%" }}>Dosage</th>
                                                    <th style={{ minWidth: "5%" }}>Quantity</th>
                                                    <th style={{ minWidth: "5%" }}>Interval</th>
                                                        {[...Array(10).keys()].map(values => {
                                                          return (
                                                            this.state[`interval_${values}`] === "Weekly"  &&
                                                            <th style={{ minWidth: "25%" }}>Days</th>
                                                            )
                                                        })}
                                                    <th style={{ minWidth: "15%" }}>Time</th>
                                                    <th style={{ minWidth: "5%" }}>Instructions</th>
                                                    <th style={{ minWidth: "20%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    totalItems.map((v, indexx) => {
                                                        return (<tr key={`prescribe_${indexx}`}>
                                                             <td>
                                                                <select style={{ minWidth: "80px" }} className="form-control" name={`type_${indexx}`} value={this.state[`type_${indexx}`]} onChange={e => this.setState({ [e.target.name]: e.target.value })}>
                                                                    <option>Select</option>
                                                                    {Type.map(valuess => {
                                                                        return (
                                                                            <option key={`type_${valuess}`} value={valuess}>{valuess}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input style={{ minWidth: "150px" }} className="form-control" type="text" name={`name_${indexx}`} value={this.state[`name_${indexx}`]} onChange={e => this.setState({ [e.target.name]: e.target.value })} />
                                                            </td>
                                                            <td>
                                                                <select style={{ minWidth: "80px" }} className="form-control" name={`dose_${indexx}`} value={this.state[`dose_${indexx}`]} onChange={e => this.setState({ [e.target.name]: e.target.value })}>
                                                                    <option>Select</option>
                                                                    {Dose.map(valuess => {
                                                                        return (
                                                                            <option key={`dose_${valuess}`} value={valuess}>{valuess}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className="inner-addon right-addon">
                                                                    <span className="ml-text">
                                                                    {this.state[`type_${indexx}`] === 'Tablet' ? 'mg' : this.state[`type_${indexx}`] === 'Syrup' ? 'ml' : this.state[`type_${indexx}`] === 'Capsule' ? 'mg' : ''}
                                                                    </span>
                                                                    <input style={{width: "85px" }} className="form-control" type="text" name={`quantity_${indexx}`} value={this.state[`quantity_${indexx}`]} onChange={e => this.setState({ [e.target.name]: e.target.value })} />
                                                                 </div>
                                                            </td>
                                                            <td>
                                                                <select style={{ minWidth: "80px" }} className="form-control" type="number" name={`interval_${indexx}`} value={this.state[`interval_${indexx}`]} onChange={e => this.setState({ [e.target.name]: e.target.value })}>
                                                                    <option>Select</option>
                                                                    {InterVals.map(valuess => {
                                                                        return (
                                                                            <option key={`intervals_${valuess}`} value={valuess}>{valuess}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </td>
                                                            {this.state[`interval_${indexx}`] === "Weekly"  &&
                                                                 <td className="check-box-des">
                                                                 {days.map((daysVal, daysKey) => {
                                                                     return (
                                                                         <div className="form-check form-check-inline" key={`day_slots_${daysKey}`}>
                                                                             <label className="form-check-label">
                                                                                 {daysVal.substr(0, 2)}<input className="form-check-input" name={`day_slots_${indexx}`} type="checkbox" onClick={e => this.onClickSlot(e, daysVal)} checked={this.state[`day_slots_${indexx}`].includes(daysVal)} />
                                                                             </label>
                                                                         </div>
                                                                     )
                                                                 })}
                                                             </td>
                                                            }
                                                            <td className="check-box-des">
                                                                {slots.map((slotsVal, slotKey) => {
                                                                    return (
                                                                        <div className="form-check form-check-inline" key={`slotVal_${slotKey}`}>
                                                                            <label className="form-check-label">
                                                                                {slotsVal === 'Morning' ? <i className="slot-icos"><img src="/assets/images/slot-ico-1.png" alt="..." /></i> : slotsVal === 'Noon' ? <i className="slot-icos"><img src="/assets/images/slot-ico-2.png" alt="..." /></i> : slotsVal === 'Evening' ? <i className="slot-icos"><img src="/assets/images/slot-ico-3.png" alt="..." /></i> : <i className="slot-icos"><img src="/assets/images/slot-icon-4.png" alt="..." /></i>}
                                                                                <input className="form-check-input" name={`time_slots_${indexx}`} type="checkbox" onClick={e => this.onClickSlot(e, slotsVal)} checked={this.state[`time_slots_${indexx}`].includes(slotsVal) ? true : false} />

                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </td>
                                                            <td>
                                                                <input className="form-control" type="text" name={`count_${indexx}`} value={this.state[`count_${indexx}`]} onChange={e => this.onChangeHandler(e)} />
                                                            </td>
                                                            <td>
                                                                {indexx > 0 && <Link to="#" className="btn bg-danger-light trash" onClick={e => this.removeItem(indexx)}><i className="far fa-trash-alt"></i></Link>}
                                                            </td>
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 text-right">
                                    <div className="signature-wrap">
                                        <div className="signature">
                                        <SignatureCanvas penColor='green' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}  ref={(ref) => { this.sigPad = ref }} onEnd={e=>this.trim()} />/>
                                        </div>
                                        <div className="sign-name">
                                            <p className="mb-0">( Dr. Darren Elder )</p>
                                            <span className="text-muted">Signature</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="submit-section">
                                        <Link to="#" className="btn btn-light-black submit-btn" onClick={e => this.onSubmitPrescription()}>Save</Link>
                                        <button type="reset" className="btn btn-grey-border submit-btn" onMouseUp={this.onClearForm}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modals>

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    prescriptions_list: state.registration.prescriptions_list
});

export default connect(mapStateToProps)(Prescription);
