// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import {
    RecordingListing,
    DeleteRecording,
} from "./../../store/actions/doctorPanelActions";
import Pagination from "./../../store/helpers/Pagination";
import { connect } from "react-redux";
import swal from "sweetalert";
import FitnessVideoPlayer from "./FitnessVideoPlayer";

class FitnessVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pageOfItems: [], currentPage: 1, video: null };
    }

    UNSAFE_componentWillMount() {
        let data = localStorage.getItem("data");
        if (data) {
            let uniqueID = JSON.parse(data).uniquekey.toLowerCase();
            this.props.RecordingListing(`tll-${uniqueID}`);
        }
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    deleteRecord = (recordingID) => {
        let data = localStorage.getItem("data");
        let uniqueID = JSON.parse(data).uniquekey.toLowerCase();
        swal({
            title: "Are you sure want to delete this?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.DeleteRecording({
                    recordingID: recordingID,
                    roomID: `tll-${uniqueID}`,
                });
            } else {
                console.log("Your file is safe!");
            }
        });
    };

    onViewVideo = (video) => {
        this.setState({ video }, () => {
          console.log("dfgjh");
        });
    };

    onCloseModal = () => {
      this.setState({ video: null })
    }

    getVideoTitle = (room) => {
        let uniqueID = JSON.parse(localStorage.data).uniquekey.toLowerCase();
        let titleStr = room.split(`tll-${uniqueID}-`);
        if(titleStr.length < 2) {
            return "";
        }
        let title =  titleStr[1].split('-').join(' ')
        return title.charAt(0).toUpperCase() + title.slice(1);
    }

    toHHMMSS = secs => {
		var sec_num = parseInt(secs, 10);
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;

		return [hours, minutes, seconds]
		  .map(v => (v < 10 ? "0" + v : v))
		  .filter((v, i) => v !== "00" || i > 0)
		  .join(":");
	  }

    render() {
        const { recording_listing } = this.props;
        return (
            <>
                {/* <div className="row"> */}
                    {/* <div className="col-sm-12"> */}
                        {/* <div className="card"> */}
                            {/* <div className="card-body"> */}
                                <div className="text-right">
                                    <Link
                                        className="add-new-btn mr-2"
                                        to="/fitness/live-stream"
                                    >
                                        Live Stream
                                    </Link>
                                    <Link
                                        className="add-new-btn"
                                        to="/fitness/add-video"
                                    >
                                        Add Video
                                    </Link>
                                </div>
                                <div className="table-responsive">
                                    <table className="datatable table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Date</th>
                                                <th>Duration</th>
                                                <th>Thumbnail</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.pageOfItems.length >
                                            0 ? (
                                                this.state.pageOfItems.map(
                                                    (val, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {this.getVideoTitle(val.room)}
                                                            </td>
                                                            <td>
                                                                {
                                                                    val.creationdate
                                                                }
                                                            </td>
                                                            <td>{this.toHHMMSS(val.duration)}</td>
                                                            <td>
                                                                {" "}
                                                                <img
                                                                    className="img-responsive"
                                                                    style={{
                                                                        width:
                                                                            "100px",
                                                                    }}
                                                                    src={
                                                                        val.thumbnail
                                                                    }
                                                                    alt="thumbnail"
                                                                />{" "}
                                                            </td>
                                                            <td>
                                                                <div className="table-action">
                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-sm bg-success-light"
                                                                        onMouseUp={() =>
                                                                            this.onViewVideo(
                                                                                val
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="far fa-eye"></i>
                                                                        &nbsp;View
                                                                    </Link>

                                                                    <Link
                                                                        className="btn btn-sm bg-danger-light"
                                                                        to="#"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.deleteRecord(
                                                                                val.recordingID
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fas fa-remove"></i>
                                                                        &nbsp;Delete
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            ) : (
                                                <tr>
                                                    <td colSpan="4">
                                                        No records available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid no-padding">
                                    <div className="row pull-right">
                                        <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                                            &nbsp;
                                            {recording_listing && (
                                                <Pagination
                                                    pageSize={20}
                                                    items={recording_listing}
                                                    onChangePage={
                                                        this.onChangePage
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}
                <FitnessVideoPlayer video={this.state.video} showDeleteBtn={false} onCloseModal={this.onCloseModal} />
            </>
        );
    }
}
const mapStateFromProps = (state) => ({
    recording_listing: state.fitness.fitness_recording_listing,
});
export default connect(mapStateFromProps, {
    RecordingListing,
    DeleteRecording,
})(FitnessVideo);
