
import React from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

class Messages extends React.Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }


    render () {
        return (
            <div>

								<div className="chat-cont-right message-full-div">
									<div className="chat-header">
										<Link id="back_user_list" to="#" className="back-user-list">
											<i className="material-icons">chevron_left</i>
										</Link>
										<div className="media">
											<div className="media-img-wrap">
												<div className="avatar avatar-online">
													<img src="./../assets/doctor/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
												</div>
											</div>
											<div className="media-body">
												<div className="user-name">Richard Wilson</div>
												<div className="user-status">online</div>
											</div>
										</div>
										<div className="chat-options">
											<Link to="#" data-toggle="modal" data-target="#voice_call">
												<i className="material-icons">local_phone</i>
											</Link>
											<Link to="#" data-toggle="modal" data-target="#video_call">
												<i className="material-icons">videocam</i>
											</Link>
											<Link to="#">
												<i className="material-icons">more_vert</i>
											</Link>
										</div>
									</div>
									<div className="chat-body">
										<div className="chat-scroll">
											<ul className="list-unstyled">
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Hello. What can I do for you?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:30 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/doctor/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>I'm just looking around.</p>
																<p>Will you tell me something about yourself?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:35 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>Are you there? That time!</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:40 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src="./../assets/doctor/img/img-02.jpg" alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<Link to="#" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</Link>
																	</div>
																	<div className="chat-attachment">
																		<img src="./../assets/doctor/img/img-03.jpg" alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<Link to="#" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</Link>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:41 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Where?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>OK, my name is Limingqiang. I like singing, playing basketballand so on.</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src="./../assets/doctor/img/img-04.jpg" alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<Link to="#" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</Link>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:50 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/doctor/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>You wait for notice.</p>
																<p>Consectetuorem ipsum dolor sit?</p>
																<p>Ok?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:55 PM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="chat-date">Today</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/doctor/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:17 AM</span>
																		</div>
																	</li>
																	<li><Link to="#">Edit</Link></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Lorem ipsum dollar sit</p>
																<div className="chat-msg-actions dropdown">
																	<Link to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																		<i className="fe fe-elipsis-v"></i>
																	</Link>
																	<div className="dropdown-menu dropdown-menu-right">
																		<Link className="dropdown-item" to="#">Delete</Link>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:19 AM</span>
																		</div>
																	</li>
																	<li><Link to="#">Edit</Link></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/doctor/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<div className="msg-typing">
																	<span></span>
																	<span></span>
																	<span></span>
																</div>
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
									<div className="chat-footer">
										<div className="input-group">
											<div className="input-group-prepend">
												<div className="btn-file btn">
													<i className="fa fa-paperclip"></i>
													<input type="file" />
												</div>
											</div>
											<input type="text" className="input-msg-send form-control" placeholder="Type something" />
											<div className="input-group-append">
												<button type="button" className="btn msg-send-btn"><i className="fab fa-telegram-plane"></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>

                )
            }
}
const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(Messages);
