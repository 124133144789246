import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    CounsellorTypeList,
    AddCounsellorTypes,
    DeleteCounsellorType,
} from "../../store/actions/adminActions";
import Pagination from "../../store/helpers/Pagination";
import { apiBaseUrl } from "../../store/helpers/common";
import SimpleReactValidator from "simple-react-validator";
import "reactjs-toastr/lib/toast.css";
import swal from "sweetalert";
import Modals from "../General/Modals";
import { Modal } from "react-bootstrap";

const addValidator = new SimpleReactValidator();

class AdminCounsellorTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            totalItems: "",
            pageOfItems: "",
            type: "",
            subTypeList: [{ subType: "" }],
            id: "",
            open: false,
            isEdit: false,
        };
    }

    componentDidMount() {
        this.props.CounsellorTypeList();
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {
            counsellor_type_list,
            filter: { admin_global_search },
        } = this.props;
        if (
            counsellor_type_list &&
            (counsellor_type_list !== prevProps.counsellor_type_list ||
                admin_global_search !== prevProps.filter.admin_global_search)
        ) {
            this.setState({ totalItems: this.filteredDataFxn() }, () => {
                this.onChangePage(this.state.totalItems, 1);
            });
        }
    };

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    addType = async (e) => {
        const { type, id, subTypeList } = this.state;
        if (addValidator.allValid()) {
            let data = { type, id: id };
            data.subtype = subTypeList.map((list) => list.subType).join(", ");
            let response = await this.props.AddCounsellorTypes(data);
            if (response === 1) {
                this.setState({
                    type: "",
                    id: "",
                    subTypeList: [{ subType: "" }],
                    open: false,
                    isEdit: false,
                });
            }
        } else {
            addValidator.showMessages();
            this.forceUpdate();
        }
    };

    EditCounsellorType = (e, id) => {
        const request = new Request(`${apiBaseUrl}/api/councillor-view-Symptoms`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("admin-token")}`,
            },
            body: JSON.stringify({ id }),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data;
                const subTypeList = data.subtype.split(", ").map(subType => {return {subType}});
                this.setState({
                    id: data.uniquekey,
                    type: data.type,
                    isEdit: true,
                    subTypeList,
                    open: true,
                });
            });
    };

    DeleteCounsellorType = (e, uniquekey) => {
        swal({
            title: "Are you sure you want to delete this Symptom?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.DeleteCounsellorType(uniquekey);
            } else {
                console.log("you clicked on false");
            }
        });
    };

    setOpen = (value) => {
        this.setState({
            open: value,
            isEdit: false,
            type: "",
            subTypeList: [{ subType: "" }],
            id: "",
        });
    };

    filteredDataFxn = () => {
        const {
            counsellor_type_list,
            filter: { admin_global_search },
        } = this.props;

        if (admin_global_search) { 
            return (
                counsellor_type_list &&
                counsellor_type_list.filter((val) => {
                    return (
                        val.name
                            .toString()
                            .toLowerCase()
                            .search(
                                admin_global_search.toString().toLowerCase()
                            ) !== -1
                    );
                })
            );
        } else {
            return counsellor_type_list;
        }
    };

    handleAddClick = () => {
        this.setState({
            subTypeList: [...this.state.subTypeList, { subType: "" }],
        });
    };

    handleRemoveClick = (index) => {
        const list = [...this.state.subTypeList];
        list.splice(index, 1);
        this.setState({ subTypeList: list });
    };

    handleSubTypeChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.subTypeList];
        list[index][name] = value;
        this.setState({ subTypeList: list });
    };

    render() {
        const { isEdit, totalItems } = this.state;
        return (
            <div>
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-7 col-auto"></div>
                        <div className="col-sm-5 col">
                            <Link
                                to="#"
                                className=" btn btn-light-black  float-right "
                                onClick={() => {
                                    this.setOpen(true);
                                }}
                            >
                                Add
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="datatable table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Type</th>
                                                <th>Subtypes</th>
                                                <th className="text-right">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.pageOfItems.length >
                                            0 ? (
                                                this.state.pageOfItems.map(
                                                    (val, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {(this.state
                                                                    .currentPage -
                                                                    1) *
                                                                    20 +
                                                                    (i + 1)}
                                                            </td>
                                                            <td>{val.type}</td>
                                                            <td>
                                                                {val.subtype}
                                                            </td>
                                                            <td className="text-right">
                                                                <div className="actions">
                                                                    <Link
                                                                        className="btn btn-sm bg-success-light"
                                                                        to="#"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.EditCounsellorType(
                                                                                e,
                                                                                val.uniquekey
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fe fe-pencil"></i>
                                                                        Edit
                                                                    </Link>
                                                                    <Link
                                                                        data-toggle="modal"
                                                                        to="#"
                                                                        className="btn btn-sm bg-danger-light"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.DeleteCounsellorType(
                                                                                e,
                                                                                val.uniquekey
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fe fe-trash"></i>
                                                                        Delete
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            ) : (
                                                <tr>
                                                    <td>
                                                        No records available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid no-padding">
                                    <div className="row pull-right">
                                        {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                                        {/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
                                        <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                                            &nbsp;
                                            {totalItems instanceof Array && (
                                                <Pagination
                                                    pageSize={20}
                                                    items={totalItems}
                                                    onChangePage={
                                                        this.onChangePage
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modals open={this.state.open} setOpen={this.setOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {isEdit ? "Edit" : "Add"} Types
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="type"
                                            value={this.state.type}
                                            onChange={(e) =>
                                                this.handleChange(e)
                                            }
                                        />
                                        {addValidator.message(
                                            "type",
                                            this.state.type,
                                            "required"
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Subtypes</label>
                                        {this.state.subTypeList.map((x, i) => {
                                            return (
                                                <React.Fragment
                                                    key={`input-${i}`}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <div className="col-md-10">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="subType"
                                                                value={
                                                                    x.subType
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleSubTypeChange(
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                            />
                                                            {/* {addValidator.message(
                                                                "type",
                                                                x.subType,
                                                                "required"
                                                            )} */}
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div
                                                                className="btn-box"
                                                                style={{
                                                                    marginTop:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {this.state
                                                                    .subTypeList
                                                                    .length !==
                                                                    1 && (
                                                                    <button
                                                                        className="btn bg-danger-light trash"
                                                                        style={{
                                                                            marginRight:
                                                                                "10px",
                                                                        }}
                                                                        type="button"
                                                                        onClick={() =>
                                                                            this.handleRemoveClick(
                                                                                i
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="far fa-trash-alt"></i>
                                                                    </button>
                                                                )}
                                                                {this.state
                                                                    .subTypeList
                                                                    .length -
                                                                    1 ===
                                                                    i && (
                                                                    <button
                                                                        className="btn bg-success-light trash"
                                                                        type="button"
                                                                        onClick={
                                                                            this
                                                                                .handleAddClick
                                                                        }
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <Link
                                to="#"
                                className="btn btn-light-black btn-block"
                                onClick={(e) => this.addType(e)}
                            >
                                Save Changes
                            </Link>
                        </form>
                    </Modal.Body>
                </Modals>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    counsellor_type_list: state.admin.counsellor_type_list?.data,
    filter: state.filter,
});

export default connect(mapStateToProps, {
    AddCounsellorTypes,
    CounsellorTypeList,
    DeleteCounsellorType,
})(AdminCounsellorTypes);
