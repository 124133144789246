import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {ShowAlert} from './../../store/actions/staticListingsActions'
// const messages = {success:"Data Inserted Successfully",updated:"Data Updated Successfully",image:"Image Uploaded Successfully",deleted:"Data deleted successfully"};

const Alerts = (props) => {

    const usePrevious = (value) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        });

        return ref.current;
    }

    const [show, setShow] = useState(false);

    const { Class, Message, Timeout } = props;

    const prevMessage = usePrevious(Message);


    useEffect(() => {
        if (Message!=="" && prevMessage !== Message) {
            setShow(true);
        }
    }, [Message])

    const onClickHide = () => {
        setShow(false)
        props.dispatch(ShowAlert({Class:"",Message:"",Timeout:""}));
    }

    setTimeout(() => {
        setShow(false);
        props.dispatch(ShowAlert({Class:"",Message:"",Timeout:""}))
    }, Timeout);
    return (
        <div className={`alert ${Class} alert-dismissible fade ${show ? "show" : "hide"}`}>
            {Message}
            <Link to="#" className="close" onClick={onClickHide.bind()}>
                <span aria-hidden="true">×</span>
            </Link>
        </div>
    )
}
const mapStateToProps = state => ({
	Class: state.static.Class,
	Message: state.static.Message,
	Timeout: state.static.Timeout,
});
export default connect(mapStateToProps)(Alerts);