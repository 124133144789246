import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices';
import { ShowLoader, ShowAlert } from './staticListingsActions';
import swal from 'sweetalert';
import { firebaseAuth } from '../helpers/firebaseConfig';

export function auth(isLoggedIn) {
  return (dispatch) => {
    dispatch({ type: actionTypes.AUTH_LOGIN, payload: isLoggedIn });
  };
}

export function storelocation(location) {
  return (dispatch) => {
    dispatch({ type: actionTypes.STORE_LOCATION, payload: location });
  };
}

export function openReviewTab(data) { 
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_REVIEW_TAB, payload: data });
  };
}

export function DoctorProfileDetails(id) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/doctor-profile-details/${id}`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((data) => {
        dispatch(ShowLoader(false));
        if (data.ResponseCode === '1') {
          dispatch({
            type: actionTypes.DOCTOR_PROFILE_INFO,
            payload: data.data,
          });
        } else {
          dispatch(
            ShowAlert({
              Class: 'alert-danger',
              Message: 'Error Occured',
              Timeout: 5000,
            })
          );
        }
      });
  };
}

export function CounselorProfileDetails(id) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/counsellor-profile-details/${id}`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((data) => {
        dispatch(ShowLoader(false));
        if (data.ResponseCode === '1') {
          dispatch({
            type: actionTypes.SELECTED_COUNSELLOR_PROFILE_INFO,
            payload: data.data,
          });
        } else {
          dispatch(
            ShowAlert({
              Class: 'alert-danger',
              Message: 'Error Occured',
              Timeout: 5000,
            })
          );
        }
      });
  };
}

export function DoctorSearchings(value) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOCTOR_SEARCH_INFO,
      payload: value.toLowerCase(),
    });
  };
}

export function supportChatHistory(type = null, postData = {}) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const token = type
      ? `${localStorage.getItem('admin-token')}`
      : `${localStorage.getItem('token')}`;
    const request = new Request(`${apiBaseUrl}/api/user/chat-history`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((data) => {
        dispatch(ShowLoader(false));
        if (data.ResponseCode === '1') {
          dispatch({
            type: actionTypes.SUPPORT_CHAT_HISTORY,
            payload: data.data,
          });
        } else {
          dispatch(
            ShowAlert({
              Class: 'alert-danger',
              Message: 'Error Occured',
              Timeout: 5000,
            })
          );
        }
      });
  };
}

// Add Review
export function SubmitReview(data) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/add-review`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('token')}`,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === '1') {
          swal({
            title: `Review ${data.reviewID ? `Updated` : `Added`} Successfully`,
            icon: 'success',
            dangerMode: true,
          });
          dispatch(ReviewsList({ uniquekey: data.loginkey }));
          return 1;
        } else {
          swal({
            title: responseData.ResponseText,
            icon: 'error',
            dangerMode: true,
          });
          return 0;
        }
      });
  };
}

//Review Listing
export function ReviewsList(data) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/review-listing`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('token')}`,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === '1') {
          dispatch({
            type: actionTypes.REVIEWS_LIST,
            payload: responseData.data,
          });
          return 1;
        } else {
          return 0;
        }
      });
  };
}

export function getFirebaseLoginToken() {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/firebase/refresh-token`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('token')}`,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === '1') {
          dispatch({
            type: actionTypes.FIREBASE_LOGIN_TOKEN,
            payload: responseData.data,
          });
          return 1;
        } else {
          return 0;
        }
      });
  };
}

export function FirebaseLogin(token) {
  return (dispatch) => {
    firebaseAuth.signInWithCustomToken(token).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage)
    })
  }
}

export function FirebaseStoreDeviceToken(postData) {
  return () => {
    const request = new Request(`${apiBaseUrl}/api/device-token`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('token')}`,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        if (responseData.ResponseCode === '1') {
          return 1;
        } else {
          return 0;
        }
      });
  }
}


export function CounsellorProfileDetails(id) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/counsellor-profile-details/${id}`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((data) => {
        dispatch(ShowLoader(false));
        if (data.ResponseCode === '1') {
          dispatch({
            type: actionTypes.COUNSELLOR_PROFILE_INFO,
            payload: data.data,
          });
        } else {
          dispatch(
            ShowAlert({
              Class: 'alert-danger',
              Message: 'Error Occured',
              Timeout: 5000,
            })
          );
        }
      });
  };
}

// Add Review
export function SubmitProductReview(data) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/add-product-review`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('token')}`,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === '1') {
          swal({
            title: `Review ${data.reviewID ? `Updated` : `Added`} Successfully`,
            icon: 'success',
            dangerMode: true,
          });
          dispatch(ProductReviewsList({ productID: data.loginkey }));
          return 1;
        } else {
          swal({
            title: responseData.ResponseText,
            icon: 'error',
            dangerMode: true,
          });
          return 0;
        }
      });
  };
}

//Review Listing
export function ProductReviewsList(data) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/product-review`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('token')}`,
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === '1') {
          dispatch({
            type: actionTypes.PRODUCT_REVIEWS_LIST,
            payload: responseData.data,
          });
          return 1;
        } else {
          return 0;
        }
      });
  };
}

export function GlobalProductList() {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/global-product-listing`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === "1") {
          dispatch({
            type: actionTypes.FETCH_GLOBAL_PRODUCT_LIST,
            payload: responseData.data,
          });
          return 1;
        } else {
          return 0;
        }
      });
  };
}

export function ViewProduct(data) {
  return (dispatch) => {
    dispatch(ShowLoader(true));
    const request = new Request(`${apiBaseUrl}/api/global-view-product`, {
      method: 'POST',
      body:JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    return fetch(request)
      .then(handleResponse)
      .then((responseData) => {
        dispatch(ShowLoader(false));
        if (responseData.ResponseCode === "1") {
          dispatch({
            type: actionTypes.FETCH_VIEW_PRODUCT,
            payload: responseData.data,
          });
          return 1;
        } else {
          return 0;
        }
      });
  };
}


export const productModal = (data) => {
  return dispatch => {
      dispatch({ type: actionTypes.OPEN_PRODUCT_MODAL, payload: data })
  }
}
