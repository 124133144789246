import { confOptions } from './Options';
let conferenceComponent = {};

/**
 * For local track
 * @param {object} component  //JITSI OBJECT
 */
export const createLocalTrack = (component) => {
    conferenceComponent = component;
    let { JitsiMeetJS, isAudioOnly } = component.state;
    // let devices = ['audio', 'video'];
    let devices = isAudioOnly ? ['audio'] : ['audio', 'video'];
    JitsiMeetJS.createLocalTracks({ devices })
        .then(onLocalTracks)
        .catch(error => {
            console.log("createLocalTracks ERROR------------", error);
            // throw error;
            Promise.all([
                createAudioTrack(JitsiMeetJS).then(onLocalTracks),
                createVideoTrack(JitsiMeetJS).then(onLocalTracks)
            ]).then(() => {
                // checkDeviceChangeAvailability();
            });
        });
}

function createAudioTrack(JitsiMeetJS) {
    return (
        JitsiMeetJS.createLocalTracks({ devices: ['audio'] })
            .catch(err => {
                console.log("Audio Track Error -- ", err);
                return [];
            }));
}

function createVideoTrack(JitsiMeetJS) {
    return (
        JitsiMeetJS.createLocalTracks({ devices: ['video'] })
            .catch(err => {
                console.log("Video Track Error -- ", err);
                return [];
            }));
}

/**
 * Handles local tracks.
 * @param tracks Array with JitsiTrack objects
 */
function onLocalTracks(tracks) {
    // checkDeviceChangeAvailability();
    let { localTracks } = conferenceComponent.state;
    localTracks.length < 1
        ? conferenceComponent.setState({ localTracks: tracks }, () => manageLocalTrack())
        : conferenceComponent.setState({ localTracks: [...localTracks] }, () => manageLocalTrack());
}

function manageLocalTrack() {
    let { localTracks, JitsiMeetJS, isJoined, room } = conferenceComponent.state;

    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, audioLevel => console.log(`Audio Level local: ${audioLevel}`));
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log('local track muted'));
        localTracks[i].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log('local track stoped'));
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, deviceId => console.log(`track audio output device was changed to ${deviceId}`));


        if (localTracks[i].getType() === 'video') {
            localTracks[i].attach(document.querySelector(`#localVideo`));
        } else {
            localTracks[i].attach(document.querySelector(`#localAudio`));
        }
        if (isJoined) {
            room.addTrack(localTracks[i]);
        }
        if (localTracks.length >= 1) {
            localTracks.forEach((track) => {
                if (track.type === "audio") {
                    conferenceComponent.setState({ micDeviceId: track.getDeviceId() });
                } else {
                    conferenceComponent.setState({ cameraDeviceId: track.getDeviceId() });
                }
            });
        }
    }
}


export const manageRoomLeave = (component) => {
    let { localTracks, room, connection } = component.state;
    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].dispose();
    }
    room.leave().then(() => {
        connection.disconnect();
        sessionStorage.removeItem('_caller_name');
        sessionStorage.removeItem('_call_type');
        // document.querySelector('#join').innerHTML = 'join';
        window.location.href = "/";
    });
}


/**
 * For room
 * @param {object} JitsiMeetJS
 * @param {object} connection
 * @param {string} roomName
 */
export const jitsiRoom = (connection, roomName) => {
    let room = connection.initJitsiConference(roomName, confOptions);
    room.join();
    return room;
}

export const manageRoomEvents = (component) => {
    conferenceComponent = component;
    let { room, JitsiMeetJS, remoteUsersDetails} = component.state;

    room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
    room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
    room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
        console.log(`track removed!!!${track}`);
    });
    room.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
    room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
    room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
        console.log(`TRACK_MUTE_CHANGED =========> ${track.getType()} - ${track.isMuted()}`);
        // alert(`${track.getType()} - ${track.isMuted()}`);
    });
    room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, (userID, displayName) => {
        console.log("DISPLAY_NAME_CHANGED", `${userID} - ${displayName}`);
        // alert(`${userID} - ${displayName}`)
        if (displayName && room.myUserId() !== userID) {
            document.getElementById(`${userID}-name`).innerHTML = displayName;
            remoteUsersDetails[userID] = {};
            remoteUsersDetails[userID]['name'] = displayName;
            conferenceComponent.setState({ remoteUsersDetails: remoteUsersDetails });
        }
    });
    room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`));

    room.on(
        JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
        () => console.log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`));

    room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (id, text) => {
        // let displayNameForMessage = room.myUserId() === id ? displayName : remoteUsersDetails[id]['name'];
        let message = '';
        if (room.myUserId() === id) {
            message = `<li class="media sent">
                            <div class="media-body">
                                <div class="msg-box">
                                    <div>
                                        <p>${text}</p>
                                        <ul class="chat-msg-info">
                                            <li>
                                                <div class="chat-time">
                                                    <span>8:30 AM</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>`;
        } else {
            message = `<li class="media received">
                            <div class="avatar">
                                <img src="assets/img/patients/patient.jpg" alt="${remoteUsersDetails[id]['name']}" class="avatar-img rounded-circle">
                            </div>
                            <div class="media-body">
                                <div class="msg-box">
                                    <div>
                                        <p>${text}</p>
                                        <ul class="chat-msg-info">
                                            <li>
                                                <div class="chat-time">
                                                    <span>8:55 PM</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>`;
        }
        // let message = `<div class="container">
        // <p>${text}</p>
        // <span class="${messageClass}">${displayNameForMessage}</span>`;
        document.getElementById('message-container').insertAdjacentHTML('beforeend', message);
    });
    room.on(JitsiMeetJS.errors.conference.CONFERENCE_ERROR, () => console.log("CONFERENCE ERROR"));
}

/**
 * That function is executed when the conference is joined
 */
function onConferenceJoined() {
    let { localTracks, room, displayName, remoteTracks } = conferenceComponent.state;
    conferenceComponent.setState({ 'isJoined': true });
    // document.querySelector("#join").innerHTML = 'Joined';
    for (let i = 0; i < localTracks.length; i++) {
        room.addTrack(localTracks[i]);
    }
    room.setDisplayName(displayName);
    let timer = 0;
    let durationInterval = setInterval(() => {
        timer += 1000;
        conferenceComponent.setState({ duration: msToTimeString(timer) });
    }, 1000);

    setTimeout(() => {
        if(Object.keys(remoteTracks).length < 1) {
            conferenceComponent.setState({showSupportButtons: true}, () => {
                room.leave();
            });
        }
    }, 5 * 60 *1000);
    // room.sendCommand("name", {value: displayName});
}

function msToTimeString(ms) {
    let seconds = (ms / 1000) % 60;
    let minutes = Math.floor(ms / 1000 / 60) % 60;
    let hours = Math.floor(ms / 1000 / 60 / 60);

    seconds = ('0' + seconds).slice(-2);
    minutes = ('0' + minutes).slice(-2);
    hours = ('0' + hours).slice(-2);

    return `${minutes}:${seconds}`;
}


/**
 * Handles remote tracks
 * @param track JitsiTrack object
 */
function onRemoteTrack(track) {
    if (track.isLocal()) {
        return;
    }
    console.log("REMOTE TRACK ================= ", track);
    let { remoteTracks, JitsiMeetJS } = conferenceComponent.state;
    const participant = track.getParticipantId();

    if (!remoteTracks[participant]) {
        remoteTracks[participant] = [];
    } else if(remoteTracks[participant] === 'jibri') {
        return;
    }
    if(document.getElementById('wait-message')) {
        document.getElementById('wait-message').remove();
    }
    // const idx = remoteTracks[participant].push(track);

    track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, audioLevel => console.log(`Audio Level remote: ${audioLevel}`));
    track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log('remote track muted'));
    track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log('remote track stoped'));
    track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, deviceId => console.log(`track audio output device was changed to ${deviceId}`));

    const id = participant + track.getType();
    if (track.getType() === 'video') {
        let video = document.createElement('video');
        video.setAttribute("id", `${participant}video`);
        video.setAttribute("class", `${participant}_track`);
        video.setAttribute("autoplay", `1`);
        video.setAttribute("style", `width:100%`);
        document.getElementById(`${participant}-container`).appendChild(video);
    } else {
        let audio = document.createElement('audio');
        audio.setAttribute("id", `${participant}audio`);
        audio.setAttribute("class", `${participant}_track`);
        audio.setAttribute("autoplay", `1`);
        document.getElementById(`${participant}-container`).appendChild(audio);
    }
    track.attach(document.getElementById(`${id}`));


}

/**
 * Handle user on join room
 * @param {string} id
 * @param {object} user
 */
function onUserJoined(id, user) {
    console.log("USER JOINED ===================> ", id, user);
    let userType = null;
    let { remoteTracks, remoteUsersDetails } = conferenceComponent.state;
    Object.keys(user).forEach(key => {
        if( key === "_statsID" && user[key] === 'jibri') {
            userType = user[key];
        }
    });
    if(!userType) {
        remoteTracks[id] = [];
        conferenceComponent.setState({ remoteTracks: remoteTracks });

        let div = document.createElement('div');
        div.setAttribute("id", `${id}-container`);
        div.setAttribute("class", `remote-video-container`);


        let h4 = document.createElement('h4');
        h4.setAttribute("id", `${id}-name`);

        let text = document.createTextNode("Guest");

        h4.appendChild(text);
        div.appendChild(h4);

        document.querySelector('#remote-container').appendChild(div);
        // let videoContainer = `<div id="${id}-container" class="remote-video-container"><h3 id="${id}-name">Guest</h3></div>`;
        // $('#remote-container').append(videoContainer);
        if (user.getDisplayName()) {
            document.getElementById(`${id}-name`).innerHTML = user.getDisplayName();
            remoteUsersDetails[id] = {};
            remoteUsersDetails[id]['name'] = user.getDisplayName();
            conferenceComponent.setState({ remoteUsersDetails: remoteUsersDetails });
        }
    } else {
        remoteTracks[id] = 'jibri';
        document.getElementById('start-recording').play();
    }
}

/**
 *
 * @param id
 */
function onUserLeft(id) {
    console.log('user left');
    let { remoteTracks, localTracks} = conferenceComponent.state;

    if (!remoteTracks[id]) {
        return;
    } else if(remoteTracks[id] === 'jibri') {
        document.getElementById('stop-recording').play();
        return;
    }
    const tracks = remoteTracks[id];

    for (let i = 0; i < tracks.length; i++) {
        tracks[i].detach(document.getElementById(`${id}${tracks[i].getType()}`));
        if (document.getElementById(`${id}-container`)) {
            document.getElementById(`${id}-container`).remove();
        }
    }

    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].dispose();
    }

    conferenceComponent.onUserLeave(id);

}

// function checkDeviceChangeAvailability() {
//     let { JitsiMeetJS, micDeviceId, cameraDeviceId } = conferenceComponent.state;
//     if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable()) {
//         JitsiMeetJS.mediaDevices.enumerateDevices(devices => {

//             const videoInputDevices = devices.filter(device => device.kind === 'videoinput' && device.deviceId);
//             const audioInputDevices = devices.filter(device => device.kind === 'audioinput' && device.deviceId);
//             const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput' && device.deviceId);

//             if (videoInputDevices.length >= 1) {
//                 document.getElementById('videoInputSelect').innerHTML =
//                     videoInputDevices.map(device => `<option ${cameraDeviceId === device.deviceId ? 'selected' : ''} value="${device.deviceId}">${device.label}</option>`).join('\n');
//                 document.getElementById('videoInputSelectWrapper').style.display = "block";
//             }

//             if (audioInputDevices.length >= 1) {
//                 document.getElementById('audioInputSelect').innerHTML =
//                     audioInputDevices.map(device => `<option ${micDeviceId === device.deviceId ? 'selected' : ''} value="${device.deviceId}">${device.label}</option>`).join('\n');
//                 document.getElementById('audioInputSelectWrapper').style.display = "block";
//             }

//             if (audioOutputDevices.length >= 1) {
//                 document.getElementById('audioOutputSelect').innerHTML =
//                     audioOutputDevices.map(device => `<option value="${device.deviceId}">${device.label}</option>`).join('\n');
//                 document.getElementById('audioOutputSelectWrapper').style.display = "block";
//             }
//         });

//     } else {
//         checkDeviceChangeAvailability();
//     }
// }

export const changeDeviceTrack = (component) => {
    conferenceComponent = component;
    let {JitsiMeetJS, micDeviceId, cameraDeviceId, localTracks} = component.state;
    let devices = micDeviceId && cameraDeviceId
        ? ['audio', 'video']
        : micDeviceId && !cameraDeviceId ? ['audio'] : ['video'];
    if (localTracks.length >= 1) {
        localTracks.forEach((track, index) => {
            track.dispose();
        });
        component.setState({localTracks: []});
        // localTracks = [];
    }

    JitsiMeetJS.createLocalTracks({
        devices,
        micDeviceId,
        cameraDeviceId
    })
        .then(onLocalTracks)
        .catch(error => console.log("Device change error", error));
}
