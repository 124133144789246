import React, { Component } from 'react';
import MapAutocomplete from './MapAutocomplete';
class Home extends Component {

	render() {
		return(
				<MapAutocomplete
					center={{lat: 18.5204, lng: 73.8567}}
					height = "400px"
					zoom = {16}
				/>
		);
	}
}

export default Home;
