import React from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

class PatientFavourites extends React.Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }


    render () {
        return (
            <div> 
              <div className="row row-grid">
								<div className="col-md-6 col-lg-4 col-xl-3">
									<div className="card widget-profile pat-widget-profile">
										<div className="card-body">
											<div className="pro-widget-content">
												<div className="profile-info-widget">
													<Link to="" className="booking-doc-img">
														<img src="./../assets/doctor/img/patients/patient.jpg" alt="User" />
													</Link>
													<div className="profile-det-info">
														<h3><Link to="">Richard Wilson</Link></h3>

														<div className="patient-details">
															<h5><b>Patient ID :</b> P0016</h5>
															<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Alabama, USA</h5>
														</div>
													</div>
												</div>
											</div>
											<div className="patient-info">
												<ul>
													<li>Age <span>38 Years, Male</span></li>
													<li>Blood Group <span>AB+</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-lg-4 col-xl-3">
									<div className="card widget-profile pat-widget-profile">
										<div className="card-body">
											<div className="pro-widget-content">
												<div className="profile-info-widget">
													<Link to="" className="booking-doc-img">
														<img src="./../assets/doctor/img/patients/patient1.jpg" alt="User" />
													</Link>
										   			<div className="profile-det-info">
														<h3><Link to="">Charlene Reed</Link></h3>

														<div className="patient-details">
															<h5><b>Patient ID :</b> P0001</h5>
															<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> North Carolina, USA</h5>
														</div>
													</div>
												</div>
											</div>
											<div className="patient-info">
												<ul>
													<li>Age <span>29 Years, Female</span></li>
													<li>Blood Group <span>O+</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-lg-4 col-xl-3">
									<div className="card widget-profile pat-widget-profile">
										<div className="card-body">
											<div className="pro-widget-content">
												<div className="profile-info-widget">
													<a href="/" className="booking-doc-img">
														<img src="./../assets/doctor/img/patients/patient2.jpg" alt="User" />
													</a>
													<div className="profile-det-info">
														<h3>Travis Trimble </h3>
														<div className="patient-details">
															<h5><b>Patient ID :</b> PT0002</h5>
															<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Maine, USA</h5>
														</div>
													</div>
												</div>
											</div>
											<div className="patient-info">
												<ul>
													<li>Age <span>23 Years, Male</span></li>
													<li>Blood Group <span>B+</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-lg-4 col-xl-3">
									<div className="card widget-profile pat-widget-profile">
										<div className="card-body">
											<div className="pro-widget-content">
												<div className="profile-info-widget">
													<a href="/" className="booking-doc-img">
														<img src="./../assets/doctor/img/patients/patient3.jpg" alt="User" />
													</a>
													<div className="profile-det-info">
														<h3>Carl Kelly</h3>
														<div className="patient-details">
															<h5><b>Patient ID :</b> PT0003</h5>
															<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Indiana, USA</h5>
														</div>
													</div>
												</div>
											</div>
											<div className="patient-info">
												<ul>
													<li>Age <span>32 Years, Male</span></li>
													<li>Blood Group <span>A+</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-lg-4 col-xl-3">
									<div className="card widget-profile pat-widget-profile">
										<div className="card-body">
											<div className="pro-widget-content">
												<div className="profile-info-widget">
													<a href="/" className="booking-doc-img">
														<img src="./../assets/doctor/img/patients/patient4.jpg" alt="User" />
													</a>
													<div className="profile-det-info">
														<h3>Michelle Fairfax</h3>
														<div className="patient-details">
															<h5><b>Patient ID :</b> PT0004</h5>
															<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Indiana, USA</h5>
														</div>
													</div>
												</div>
											</div>
											<div className="patient-info">
												<ul>
													<li>Age <span>25 Years, Female</span></li>
													<li>Blood Group <span>B+</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-lg-4 col-xl-3">
									<div className="card widget-profile pat-widget-profile">
										<div className="card-body">
											<div className="pro-widget-content">
												<div className="profile-info-widget">
													<a href="/" className="booking-doc-img">
														<img src="./../assets/doctor/img/patients/patient5.jpg" alt="User" />
													</a>
													<div className="profile-det-info">
														<h3>Gina Moore</h3>
														<div className="patient-details">
															<h5><b>Patient ID :</b> PT0005</h5>
															<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Florida, USA</h5>
														</div>
													</div>
												</div>
											</div>
											<div className="patient-info">
												<ul>
													<li>Age <span>25 Years, Female</span></li>
													<li>Blood Group <span>AB-</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							</div>





                )
            }
}
const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(PatientFavourites);
