import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getAdminSpecialisationListing,
  AddSpecialist,
  deleteSpecialisation,
} from '../../store/actions/adminActions';
import Pagination from '../../store/helpers/Pagination';
import { apiBaseUrl } from '../../store/helpers/common';
import SimpleReactValidator from 'simple-react-validator';
import 'reactjs-toastr/lib/toast.css';
import swal from 'sweetalert';
import Modals from './../General/Modals';
import { Modal } from 'react-bootstrap';

const addValidator = new SimpleReactValidator();
class AdminSpecialist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageOfItems: [],
      addSpecialities: '',
      id: '',
      open: false,
      isEdit: false,
      totalItems: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getAdminSpecialisationListing());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  addSpecialist = async (e) => {
    const { addSpecialities, id } = this.state;
    let data = { name: addSpecialities, id: id };
    if (addValidator.allValid()) {
      let response = await this.props.dispatch(AddSpecialist(data));
      if (response === 1) {
        this.setState({
          addSpecialities: '',
          id: '',
          open: false,
          isEdit: false,
        });
      }
    } else {
      addValidator.showMessages();
      this.forceUpdate();
    }
  };

  EditSpecialization = (e, id) => {
    const request = new Request(`${apiBaseUrl}/api/user/view/Specialization`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': `${localStorage.getItem('admin-token')}`,
      },
      body: JSON.stringify({ id: id }),
    });
    return fetch(request)
      .then((res) => res.json())
      .then((res) => {
        const data = res.data;
        this.setState({
          id: data.id,
          addSpecialities: data.name,
          isEdit: true,
          open: true,
        });
      });
  };

  DeleteSpecialisation = (e, id) => {
    let multiplecheck = [id];
    swal({
      title: 'Are you sure you want to delete this Specialisation?',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(deleteSpecialisation(multiplecheck));
      } else {
        console.log('you clicked on false');
      }
    });
  };

  setOpen = (value) => {
    this.setState({
      open: value,
      isEdit: false,
      addSpecialities: '',
      id: '',
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      SpecialisationListing,
      filter: { admin_global_search },
    } = this.props;
    if (
      SpecialisationListing &&
      (SpecialisationListing !== prevProps.SpecialisationListing ||
        admin_global_search !== prevProps.filter.admin_global_search)
    ) {
      this.setState({ totalItems: this.filteredDataFxn() },()=>{
        this.onChangePage(this.state.totalItems,1)
      });
    }
  };

  filteredDataFxn = () => {
    const {
      SpecialisationListing,
      filter: { admin_global_search },
    } = this.props;
    if (admin_global_search) {
      return (
        SpecialisationListing &&
        SpecialisationListing.filter((val) => {
          return (
            val.name
              .toString()
              .toLowerCase()
              .search(admin_global_search.toString().toLowerCase()) !== -1
          );
        })
      );
    } else {
      return SpecialisationListing;
    }
  };

  render() {
    const { isEdit, totalItems } = this.state; 

    return (
      <div>
        <div className='page-header'>
          <div className='row'>
            <div className='col-sm-7 col-auto'></div>
            <div className='col-sm-5 col'>
              <Link
                to='#'
                className=' btn btn-light-black  float-right '
                onClick={() => {
                  this.setOpen(true);
                }}
              >
                Add
              </Link>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='datatable table table-hover table-center mb-0'>
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>Specialities</th>
                        <th className='text-right'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pageOfItems.length > 0 ? (
                        this.state.pageOfItems.map((val, i) => (
                          <tr key={i}>
                            <td>
                              {(this.state.currentPage - 1) * 20 + (i + 1)}
                            </td>
                            <td>{val.name}</td>
                            <td className='text-right'>
                              <div className='actions'>
                                <Link
                                  className='btn btn-sm bg-success-light'
                                  to='#'
                                  onClick={(e) =>
                                    this.EditSpecialization(e, val.id)
                                  }
                                >
                                  <i className='fe fe-pencil'></i>Edit
                                </Link>
                                <Link
                                  data-toggle='modal'
                                  to='#'
                                  className='btn btn-sm bg-danger-light'
                                  onClick={(e) =>
                                    this.DeleteSpecialisation(e, val.id)
                                  }
                                >
                                  <i className='fe fe-trash'></i>Delete
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No records available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='container-fluid no-padding'>
                  <div className='row pull-right'>
                    {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                    {/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
                    <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                      &nbsp;
                      {totalItems instanceof Array && (
                        <Pagination
                          pageSize={20}
                          items={totalItems}
                          onChangePage={this.onChangePage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modals open={this.state.open} setOpen={this.setOpen}>
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              {isEdit ? 'Edit' : 'Add'} Specialities
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='row form-row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label>Specialities</label>
                    <input
                      type='text'
                      className='form-control'
                      name='addSpecialities'
                      value={this.state.addSpecialities}
                      onChange={(e) => this.handleChange(e)}
                    />
                    {addValidator.message(
                      'Add Specialities',
                      this.state.addSpecialities,
                      'required'
                    )}
                  </div>
                </div>
              </div>
              <Link
                to='#'
                className='btn btn-light-black btn-block'
                onClick={(e) => this.addSpecialist(e)}
              >
                Save Changes
              </Link>
            </form>
          </Modal.Body>
        </Modals>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  SpecialisationListing: state?.admin?.adminSpecListing?.data,
  filter: state.filter,
});

export default connect(mapStateToProps)(AdminSpecialist);
