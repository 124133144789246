import React from "react";
import { connect } from "react-redux"; 
import { GetFitnessExpertReviews } from "../../store/actions/fitnessActions";
import Pagination from "../../store/helpers/Pagination";

class CounsellorReviews extends React.Component {
    state = {
        pageOfItems: [],
        currentPage: 1,
    };

    componentDidMount() {
        this.props.GetFitnessExpertReviews();
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    render() {
        const { fitness_reviews } = this.props;
        const { pageOfItems } = this.state;
        return (
            <div>
                {/* <div className="row"> */}
                    {/* <div className="col-md-7 col-lg-8 col-xl-9"> */}
                        <div className="doc-review review-listing">
                            <ul className="comments-list">
                                {pageOfItems.length > 0 ? (
                                    pageOfItems.map((val, i) => (
                                        <li key={`reviews_${val}`}>
                                            <div className="comment">
                                                <img
                                                    className="avatar rounded-circle"
                                                    alt="User"
                                                    src="./../assets/doctor/img/patients/patient.jpg"
                                                />
                                                <div className="comment-body" style={{width:"100%"}}>
                                                    <div className="meta-data">
                                                        <span className="comment-author">
                                                            {val.user_name}
                                                        </span>
                                                        <span className="comment-date">
                                                            {val.creationdate}
                                                        </span>
                                                        <div className="review-count rating">
															{[...Array(5).keys()].map((point, index) => {
																if(point + 1 <= val.points) {
																	return <i key={`star_${index}`} className="fas fa-star filled"></i>;
																} else {
																	return <i key={`star_${index}`} className="fas fa-star"></i>
																}
															})}
                                                        </div>
                                                    </div>
                                                    {/* <p className="recommended">
                                                        <i className="far fa-thumbs-up"></i>{" "}
                                                        I recommend the doctor
                                                    </p> */}
                                                    <p className="comment-content">
                                                        {val.description}
                                                    </p>
                                                    <div className="comment-reply">
                                                        {/* <Link
                                                            className="comment-btn"
                                                            to="#"
                                                        >
                                                            <i className="fas fa-reply"></i>{" "}
                                                            Reply
                                                        </Link> */}
                                                        {/* <p className="recommend-btn">
                                                            <span>
                                                                Recommend?
                                                            </span>
                                                            <Link
                                                                to="#"
                                                                className="like-btn"
                                                            >
                                                                <i className="far fa-thumbs-up"></i>{" "}
                                                                Yes
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                className="dislike-btn"
                                                            >
                                                                <i className="far fa-thumbs-down"></i>{" "}
                                                                No
                                                            </Link>
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li>No reviews found</li>
                                )}
                            </ul>
                            <div className="all-feedback text-center">
                                {fitness_reviews && (
                                    <Pagination
                                        pageSize={20}
                                        items={fitness_reviews}
                                        onChangePage={this.onChangePage}
                                    />
                                )}
                            </div>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    fitness_reviews: state.fitness.fitness_reviews,
});

export default connect(mapStateToProps, { GetFitnessExpertReviews })(CounsellorReviews);
