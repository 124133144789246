import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Addtimes } from './../../store/helpers/timeIntervals';
import {
  BookAppointment,
  RescheduleAppointment,
  RemoveAppointmentData,
} from './../../store/actions/registerationActions';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const appointmentTypes = ['Chat', 'Audio', 'Video'];

let dayTimes = {};
let daySlots = {};

days.map((value) => {
  dayTimes[`${value}_time`] = undefined;
  daySlots[`${value}_slot`] = undefined;
  return null;
});
class CounsellorAppointment extends Component {
  constructor(props) {
    super(props);
    var CurrentTime = moment().format('HH:mm');
    this.state = {
      tab: 'Overview',
      appointmentType: '',
      dayChoosen: '',
      interval: [],
      doctorID: '',
      dateChoosen: '',
      currentDay: days[moment().isoWeekday() - 1],
      currentTime: CurrentTime,
    };
    Object.assign(this.state, dayTimes, daySlots);
  }

  GetAppointment = () => {
    let isLoggedIn = localStorage.getItem('token');
    if (isLoggedIn) {
      window
        .$('#bookAppointmentListing')
        .modal({ show: true, backdrop: 'static' });
    } else {
      this.props.history.push('/');
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.profile_info !== prevState.profile_info &&
      prevState.doctorID !== nextProps.profile_info.uniquekey
    ) {
      let insertInfo = nextProps.profile_info;
      let dayvals = insertInfo.days ? Object.keys(insertInfo.days) : [];
      let datesVal = insertInfo.dates;
      return {
        doctorID: insertInfo.uniquekey,
        dayChoosen: dayvals[0] ? dayvals[0] : '',
        dateChoosen: datesVal && datesVal[0] ? datesVal[0].date : '',
        monday_time: insertInfo[`monday_time`]
          ? JSON.parse(insertInfo[`monday_time`])
          : '',
        tuesday_time: insertInfo[`tuesday_time`]
          ? JSON.parse(insertInfo[`tuesday_time`])
          : '',
        wednesday_time: insertInfo[`wednesday_time`]
          ? JSON.parse(insertInfo[`wednesday_time`])
          : '',
        thursday_time: insertInfo[`thursday_time`]
          ? JSON.parse(insertInfo[`thursday_time`])
          : '',
        friday_time: insertInfo[`friday_time`]
          ? JSON.parse(insertInfo[`friday_time`])
          : '',
        saturday_time: insertInfo[`saturday_time`]
          ? JSON.parse(insertInfo[`saturday_time`])
          : '',
        sunday_time: insertInfo[`sunday_time`]
          ? JSON.parse(insertInfo[`sunday_time`])
          : '',
        monday_slot: insertInfo[`monday_slot`]
          ? JSON.parse(insertInfo[`monday_slot`])
          : '',
        tuesday_slot: insertInfo[`tuesday_slot`]
          ? JSON.parse(insertInfo[`tuesday_slot`])
          : '',
        wednesday_slot: insertInfo[`wednesday_slot`]
          ? JSON.parse(insertInfo[`wednesday_slot`])
          : '',
        thursday_slot: insertInfo[`thursday_slot`]
          ? JSON.parse(insertInfo[`thursday_slot`])
          : '',
        friday_slot: insertInfo[`friday_slot`]
          ? JSON.parse(insertInfo[`friday_slot`])
          : '',
        saturday_slot: insertInfo[`saturday_slot`]
          ? JSON.parse(insertInfo[`saturday_slot`])
          : '',
        sunday_slot: insertInfo[`sunday_slot`]
          ? JSON.parse(insertInfo[`sunday_slot`])
          : '',
      };
    } else {
      return null;
    }
  }

  BookAppointments = () => {
    const {
      interval,
      doctorID,
      dayChoosen,
      appointmentType,
      dateChoosen,

    } = this.state;
    let isLoggedIn = localStorage.getItem('token');
    if (appointmentType === '') {
      swal({
        title: 'Please choose appointment type',
      });
      return false;
    }
    if (interval.length === 0) {
      swal({
        title: 'Please choose time for the appointment',
      });
      return false;
    }
    swal({
      title: 'Are you sure want to fix this appointment?',
      buttons: ['No, thanks', 'Yes, I am sure!'],
    }).then(async (Fix) => {
      if (Fix) {
        let dataToSend = {
          doctorID: doctorID,
          appointment_day: dayChoosen,
          interval: interval,
          appointment_type: appointmentType,
          dateChoosen: dateChoosen,
          type:'CR'
        };
        window.$('#bookAppointmentListing').modal('hide');
        if (isLoggedIn) {
          const response = await this.props.dispatch(
            BookAppointment(dataToSend)
          );
          if (response.response === 'success') {
            this.props.history.push(`/counsellor-patient/checkout/${response.code}`);
          }
        } else {
          sessionStorage.setItem(
            '_booking_details',
            JSON.stringify(dataToSend)
          );
          sessionStorage.setItem('_booked_process', doctorID);
          window.$('#loginModal1').modal({ show: true, backdrop: 'static' });
        }
      } else {
        console.log('you clicked on false');
      }
    });
  };

  RescheduleAppointments = () => {
    const {
      interval,
      appointmentType,
      dateChoosen,
    } = this.state;
    let isLoggedIn = localStorage.getItem('token');
    if (appointmentType === '') {
      swal({
        title: 'Please choose appointment type',
      });
      return false;
    }
    if (interval === '') {
      swal({
        title: 'Please choose time for the appointment',
      });
      return false;
    }
    swal({
      title: 'Are you sure want to fix this appointment?',
      buttons: ['No, thanks', 'Yes, I am sure!'],
    }).then(async (Fix) => {
      if (Fix) {
        let dataToSend = {
          appointment_code: this.props.appointmentCode,
          interval: interval,
          appointment_type: appointmentType,
          dateChoosen: dateChoosen,
        };
        window.$('#bookAppointmentListing').modal('hide');
        if (isLoggedIn) {
          const response = await this.props.dispatch(
            RescheduleAppointment(dataToSend)
          );
          if (response.response === 'success') {
            this.props.updateAppointmentList();
          }
        }
        // else {
        // 	sessionStorage.setItem('_booking_details',JSON.stringify(dataToSend));
        //     sessionStorage.setItem('_booked_process',doctorID);
        //     window.$('#loginModal1').modal({show:true})
        // }
      } else {
        console.log('you clicked on false');
      }
    });
  };

  closeAppointment = () => {
    this.props.dispatch(RemoveAppointmentData());
    var CurrentTime = moment().format('HH:mm');
    this.setState({
      tab: 'Overview',
      appointmentType: '',
      dayChoosen: '',
      interval: [],
      doctorID: '',
      dateChoosen: '',
      currentDay: days[moment().isoWeekday() - 1],
      currentTime: CurrentTime,
      monday_time: {},
      tuesday_time: {},
      wednesday_time: {},
      thursday_time: {},
      friday_time: {},
      saturday_time: {},
      sunday_time: {},
      monday_slot: {},
      tuesday_slot: {},
      wednesday_slot: {},
      thursday_slot: {},
      friday_slot: {},
      saturday_slot: {},
      sunday_slot: {},
    });
    days.map((value) => {
      dayTimes[`${value}_time`] = undefined;
      daySlots[`${value}_slot`] = undefined;
      return null;
    });
    Object.assign(this.state, dayTimes, daySlots);
    this.props.onCloseHandler();
  };

  render() {
    const { profile_info, weekly_appointments } = this.props;
    const { dayChoosen, interval, appointmentType, currentTime } = this.state;
    //let daysVal = profile_info.days ? Object.keys(profile_info.days) : '';
    const styles = { backgroundColor: 'red' };
    let datesVal = profile_info.dates;
    return (
      <div
        className='modal fade loginModal-div'
        id='bookAppointmentListing'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                {this.props.appointmentCode ? `Reschedule` : `Book`} Appointment
              </h5>
              <button
                type='button'
                className='close'
                onClick={this.closeAppointment}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body login-sec-modal'>
              <div className='bookAppointment-body-inner'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='bookappointment-tab-side'>
                      <ul className='nav nav-tabs' id='myTab' role='tablist'>
                        {appointmentTypes.map((value, index) => {
                          return (
                            <li
                              className='nav-item'
                              key={`appointmentType${index}`}
                            >
                              <Link
                                to='#'
                                className={`nav-link ${
                                  appointmentType === value ? 'active' : ''
                                }`}
                                onClick={() => {
                                  this.setState({
                                    appointmentType: value,
                                    interval: [],
                                  });
                                }}
                              >
                                <i
                                  className={`fas fa-${
                                    value === 'Audio'
                                      ? 'phone'
                                      : value === 'Video'
                                      ? 'video'
                                      : value === 'Chat'
                                      ? 'comment-alt'
                                      : 'walking'
                                  }`}
                                ></i>{' '}
                                &nbsp;{value}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <h3 className='red_rate'>
                        Book {appointmentType} Consultancy for ₹ {' '}
                        {appointmentType === 'Audio'
                          ? profile_info.phone_consultation_fee
                          : appointmentType === 'Video'
                          ? profile_info.video_consultation_fee
                          : appointmentType === 'Chat'
                          ? profile_info.chat_consultation_fee
                          : profile_info.walkin_consultation_fee}
                      </h3>
                      <div className='tab-content' id='myTabContent'>
                        <div className='active'>
                          <div className='upcoming-slots'>
                            <ul className='upcoming-slots-ul'>
                              {datesVal &&
                                datesVal.map((value, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`${
                                        this.state.dateChoosen === value.date
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      <Link
                                        to='#'
                                        onClick={(e) =>
                                          this.setState({
                                            dayChoosen: value.dayName,
                                            interval: [],
                                            dateChoosen: value.date,
                                          })
                                        }
                                      >
                                        {value.dateFormat}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className='active-slots'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <h2>
                                  <i className='slot-icos'>
                                    <img
                                      src='/assets/images/slot-ico-1.png'
                                      alt='...'
                                    />
                                  </i>
                                  Morning
                                </h2>
                                <ul className='ul-active-slots'>
                                  {dayChoosen !== '' &&
                                    this.state[`${dayChoosen}_time`][
                                      'morning'
                                    ] &&
                                    this.state[`${dayChoosen}_time`][
                                      'morning'
                                    ].map((value, index) => {
                                      let dataTest = weekly_appointments.filter(
                                        (filteredvalue) =>
                                          filteredvalue.starttime === value &&
                                          filteredvalue.appointment_date ===
                                            this.state.dateChoosen
                                      );
                                      if (
                                        dayChoosen === this.state.currentDay &&
                                        value <= currentTime
                                      ) {
                                        return (
                                          <li
                                            key={`mng_slot_${index}`}
                                            style={
                                              dataTest.length > 0 ? styles : {}
                                            }
                                            className='time-slot disabled'
                                          >
                                            <Link
                                              title='Cannot select past time'
                                              to='#'
                                            >{`${value}-${Addtimes(
                                              value
                                            )}`}</Link>
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li
                                            key={`mng_slot_${index}`}
                                            style={
                                              dataTest.length > 0 ? styles : {}
                                            }
                                            className={
                                              interval.includes(value)
                                                ? 'active'
                                                : ''
                                            }
                                          >
                                            <Link
                                              to='#'
                                              onClick={(e) =>
                                                dataTest.length > 0
                                                  ? e.preventDefault()
                                                  : this.setState({
                                                      interval: [value],
                                                    })
                                              }
                                            >{`${value}-${Addtimes(
                                              value
                                            )}`}</Link>
                                          </li>
                                        );
                                      }
                                      // return (
                                      // 	<li key={`mng_slot_${index}`} style={dataTest.length>0?styles:{}} className={interval.includes(value) ? "active" : ""}>
                                      // 		<Link to="#" onClick={(e) => dataTest.length>0?e.preventDefault():this.setState({interval:[value]})}>{`${value}-${Addtimes(value)}`}</Link>
                                      // 	</li>)
                                    })}
                                  {(dayChoosen === '' ||
                                    !this.state[`${dayChoosen}_time`][
                                      'morning'
                                    ]) && (
                                    <li key={`mng_slot_empty`}>
                                      <span className='badge bg-danger-light'>
                                        Closed
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <h2>
                                  <i className='slot-icos'>
                                    <img
                                      src='/assets/images/slot-ico-2.png'
                                      alt='...'
                                    />
                                  </i>
                                  Afternoon
                                </h2>
                                <ul className='ul-active-slots'>
                                  {dayChoosen !== '' &&
                                    this.state[`${dayChoosen}_time`]['noon'] &&
                                    this.state[`${dayChoosen}_time`][
                                      'noon'
                                    ].map((value, index) => {
                                      let dataTest = weekly_appointments.filter(
                                        (filteredvalue) =>
                                          filteredvalue.starttime === value &&
                                          filteredvalue.appointment_date ===
                                            this.state.dateChoosen
                                      );
                                      if (
                                        dayChoosen === this.state.currentDay &&
                                        value <= currentTime
                                      ) {
                                        return (
                                          <li
                                            key={`noon_slot_${index}`}
                                            style={
                                              dataTest.length > 0 ? styles : {}
                                            }
                                            className='time-slot disabled'
                                          >
                                            <Link
                                              title='Cannot select past time'
                                              to='#'
                                            >{`${value}-${Addtimes(
                                              value
                                            )}`}</Link>
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li
                                            key={`noon_slot_${index}`}
                                            style={
                                              dataTest.length > 0 ? styles : {}
                                            }
                                            className={
                                              interval.includes(value)
                                                ? 'active'
                                                : ''
                                            }
                                          >
                                            <Link
                                              to='#'
                                              onClick={(e) =>
                                                dataTest.length > 0
                                                  ? e.preventDefault()
                                                  : this.setState({
                                                      interval: [value],
                                                    })
                                              }
                                            >{`${value}-${Addtimes(
                                              value
                                            )}`}</Link>
                                          </li>
                                        );
                                      }
                                    })}
                                  {(dayChoosen === '' ||
                                    !this.state[`${dayChoosen}_time`][
                                      'noon'
                                    ]) && (
                                    <li key={`noon_slot_empty`}>
                                      <span className='badge bg-danger-light'>
                                        Closed
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <h2>
                                  <i className='slot-icos'>
                                    <img
                                      src='/assets/images/slot-ico-3.png'
                                      alt='...'
                                    />
                                  </i>
                                  Evening
                                </h2>
                                <ul className='ul-active-slots'>
                                  {dayChoosen !== '' &&
                                    this.state[`${dayChoosen}_time`][
                                      'evening'
                                    ] &&
                                    this.state[`${dayChoosen}_time`][
                                      'evening'
                                    ].map((value, index) => {
                                      let dataTest = weekly_appointments.filter(
                                        (filteredvalue) =>
                                          filteredvalue.starttime === value &&
                                          filteredvalue.appointment_date ===
                                            this.state.dateChoosen
                                      );
                                      if (
                                        dayChoosen === this.state.currentDay &&
                                        value <= currentTime
                                      ) {
                                        return (
                                          <li
                                            key={`evening_slot_${index}`}
                                            style={
                                              dataTest.length > 0 ? styles : {}
                                            }
                                            className='time-slot disabled'
                                          >
                                            <Link
                                              title='Cannot select past time'
                                              to='#'
                                            >{`${value}-${Addtimes(
                                              value
                                            )}`}</Link>
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li
                                            key={`evening_slot_${index}`}
                                            style={
                                              dataTest.length > 0 ? styles : {}
                                            }
                                            className={
                                              interval.includes(value)
                                                ? 'active'
                                                : ''
                                            }
                                          >
                                            <Link
                                              to='#'
                                              onClick={(e) =>
                                                dataTest.length > 0
                                                  ? e.preventDefault()
                                                  : this.setState({
                                                      interval: [value],
                                                    })
                                              }
                                            >{`${value}-${Addtimes(
                                              value
                                            )}`}</Link>
                                          </li>
                                        );
                                      }
                                    })}
                                  {(dayChoosen === '' ||
                                    !this.state[`${dayChoosen}_time`][
                                      'evening'
                                    ]) && (
                                    <li key={`evening_slot_empty`}>
                                      <span className='badge bg-danger-light'>
                                        Closed
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='col-md-12 text-center'>
                {this.props.appointmentCode && (
                  <Link
                    to='#'
                    className='btn btn-light-black'
                    onClick={(e) => {
                      this.RescheduleAppointments();
                    }}
                  >
                    Reschedule Appointment
                  </Link>
                )}
                {!this.props.appointmentCode && (
                  <Link
                    to='#'
                    className='btn btn-light-black'
                    onClick={(e) => {
                      this.BookAppointments();
                    }}
                  >
                    Book Appointment
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile_info: state.global.selected_counsellor_profile_info,
  weekly_appointments: state.registration.counsellor_weekly_appointments,
});
export default withRouter(connect(mapStateToProps)(CounsellorAppointment));
