import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom' 
import { CounsellorMedicalRecordsList} from '../../store/actions/counselorPanelActions';
import {ShowLoader,ShowAlert} from '../../store/actions/staticListingsActions'
import {DeleteMedicalRecord} from '../../store/actions/doctorPanelActions'
import Pagination from '../../store/helpers/Pagination';
import { Modal } from 'react-bootstrap';
import Modals from './../General/Modals'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { apiBaseUrl,bucketPath} from './../../store/helpers/common';
import swal from 'sweetalert'

const axios = require("axios");
const initialState = { pageOfItems: [], currentPage: 1, patientDetail: {},open:false,date:'',description:'',upload:'',name:'', viewImage:false,imgPath:'' };
class CounsellorMedicalRecords extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        const postData = { uniquekey: this.props.user_key };
        this.props.dispatch(CounsellorMedicalRecordsList(postData));
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({open: false});
    };

    setDate = date => {
        this.setState({date:moment(date).local().format('YYYY-MM-DD')})
    }

    onClickHandler = async (e) => {
        if(this.validator.allValid()){
            this.UploadImage()
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    UploadImage = () => {
        const {date,description,name} = this.state
        let request;
        let formData = new FormData();
            formData.append(`name`, name);
            formData.append(`attachment`, window.$("#file-upload")[0].files[0]);
            formData.append("date", date);
            // formData.append("description", description);
            formData.append("uniquekey", JSON.parse(localStorage.data).uniquekey);
            formData.append("user_key", this.props.user_key);
        request = {
            method: "post",
            url: `${apiBaseUrl}/api/add-medical-record`,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            },
            data: formData,
        };
        this.props.dispatch(ShowLoader(true));
        axios(request).then((res) => {
            if (res.data.ResponseCode === "1") {
              this.setState(initialState,() => {
                  this.props.dispatch(CounsellorMedicalRecordsList({ uniquekey: this.props.user_key }));
                  this.props.dispatch(ShowLoader(false));
                  this.props.dispatch(
                    ShowAlert({
                      Class: "alert-success",
                      Message: res.data.ResponseText,
                      Timeout: 10000,
                    })
                  );
                }
              );
            }
          }).catch((error) => {
            this.props.dispatch(ShowLoader(false));
            this.props.dispatch(
              ShowAlert({
                Class: "alert-danger",
                Message: "Error Occured.",
                Timeout: 10000,
              })
            );
          });
    }

    DeleteRecord = value => {
        swal({
            title: "Are you sure want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
              const data = {user_key:this.props.user_key,uniquekey:JSON.parse(localStorage.data).uniquekey,id: value};
              this.props.dispatch(DeleteMedicalRecord(data));
            }
          });
    }

    render() {
        const { medical_records } = this.props;
        const { pageOfItems,open } = this.state
        let type = localStorage.getItem('type');
        return (
            <>
                {type==="patient" && <div className="text-right">
                    <Link to="#" className="add-new-btn" onClick={e=>this.setState({open:true})}>Add Medical Records</Link>
                </div>}
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date </th>
                                        <th>Name </th>
                                        <th>Description</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageOfItems.map((value, key) => {
                                        return (
                                            <tr key={`medical_records_${key}`}>
                                                <td><Link to="#">{value.uniquekey}</Link></td>
                                                <td>{value.creationdate}</td>
                                                <td>{value.name}</td>
                                                <td>{value.description}</td>
                                                <td className="text-right">
                                                    <div className="table-action">
                                                        <Link to="#" className="btn btn-sm bg-info-light" onClick={e=>localStorage.getItem('type')==="counselor"?this.setState({viewImage:true,imgPath:value.attachment,open:true}):false}>
                                                            <i className="far fa-eye"></i> View
                                                        </Link>
                                                        {/* <Link to="#" className="btn btn-sm bg-danger-light" onClick={e=>this.DeleteRecord(value.id)}>
                                                        <i className="far fa-trash-alt"></i> Delete
                                                        </Link> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {medical_records.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={medical_records}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>

                 {/* modal for add prescription */}

                 <Modals open={open} setOpen={this.setOpen} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                        Medical Records
                        </Modal.Title>
                    </Modal.Header>
                    {!this.state.viewImage && <Modal.Body>
                        <div className="form-group">
                            <label>Name</label>
                            <input className="form-control" name="name" value={this.state.name} onChange={e=>this.setState({[e.target.name]:e.target.value})}/>
                            {this.validator.message('Name',this.state.name,'required')}
                        </div>
                        <div className="form-group">
                            <label>Date</label>
                            <DatePicker className="form-control" name="date" dateFormat="dd/MM/yyyy" selected={this.state.date?new Date(this.state.date):''} onChange={val => this.setDate(val)} />
                            {this.validator.message('Date',this.state.date,'required')}
                        </div>
                        {/* <div className="form-group">
                            <label>Description ( Optional )</label>
                            <textarea className="form-control" name="description" value={this.state.description} onChange={e=>this.setState({[e.target.name]:e.target.value})}></textarea>
                            {this.validator.message('Description',this.state.description,'required')}
                        </div> */}
                        <div className="form-group">
                            <label>Upload File</label>
                            <input type="file" className="form-control" id="file-upload" name="upload" value={this.state.upload} onChange={e=>this.setState({[e.target.name]:e.target.value})}/>
                            {this.validator.message('Document',this.state.upload,'required')}
                        </div>
                        <div className="submit-section text-center">
                            <Link to="#" onClick={e=>this.onClickHandler()} className="btn btn-light-black submit-btn">Submit</Link>
                            <Link to="#" onClick={e=>this.setState({upload:'',date:'',description:''})} className="btn btn-grey-border submit-btn">Clear</Link>
                        </div>
                    </Modal.Body>}
                    {this.state.viewImage && <Modal.Body>
                        <div className="form-group">
                            <img src={`${this.state.imgPath?`${bucketPath}${this.state.imgPath}`:"/assets/img/patients/patient6.jpg"}`} alt="text"/>
                        </div>
                    </Modal.Body>}
                </Modals>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    medical_records: state.counselorPanel.counsellor_medical_records
});

export default connect(mapStateToProps)(CounsellorMedicalRecords)
