import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter} from 'react-router-dom';
import {ViewHomeArticle} from "./../store/actions/userActions";
import { bucketPath } from './../store/helpers/common';


class ListingSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  componentDidMount ( ){
    this.props.dispatch(ViewHomeArticle())
  }

  render() {
    return (
            <div className='col-md-4 doctor-list-right-side-sec'>
              <div className='dr-list-ask-question'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h2>
                      <i className='far fa-envelope'></i>&nbsp; Ask a FREE
                      Question
                    </h2>
                  </div>
                </div>
                <div className='dr-list-ask-question-textbox'>
                  <div className='row'>
                    <div className='col-md-12 align-self-center'>
                      <h4>Get FREE multiple opinions from Doctors</h4>
                      <textarea
                        className='form-control'
                        placeholder='Enter question for you or someone else. E.g. my left eye is red and swollen...'
                        id='exampleFormControlTextarea1'
                        rows='5'
                      ></textarea>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-8 align-self-center'>
                      {/* <p>Posted anonymously</p> */}
                    </div>
                    <div className='col-md-4 align-self-center'>
                      <Link to='#' className='btn btn-light-black  float-right'>
                        Submit
                      </Link>
                    </div>
                  </div>
                </div>
                </div>

                <div className='dr-list-health-topic-box'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 style={{color:'#ED1C24'}}>Top Health Tips</h2>
                        </div>
                    </div>
                    {this.props.home_article && this.props.home_article.map((value,index) => {
                        return (
                            <div className='dr-list-topic-list-box health-tip-sidebar' key={`article_${index}`}>
                                <div className='row' key = {`article_${index}`}>
                                    <div className='col-lg-7 col-md-7 col-8 align-self-center health-tips-content-side'>
                                        <h3>
                                        <Link to="#" onClick={e=>this.props.history.push(`/health-feed/${value.uniquekey}`)}>
                                            {value.title}
                                        </Link>
                                        </h3>
                                        {/* <h6 dangerouslySetInnerHTML={{__html:`${value?.description.substring(0,60)??'<p></p>'}...`}}>
                                            Lorem Ipsum is simply dummy text of the printing
                                        </h6> */}
                                        <h6 dangerouslySetInnerHTML={{__html:`${(value.description).substring(0,60)??'<p></p>'}`}}>
                                        </h6>
                                        <Link
                                          to={`/health-feed/${value.uniquekey}`}
                                          className="float-right read-m text-red"
                                          style={{color:'#ED1C24'}}
                                        >
                                          Read More
                                        </Link>

                                    </div>
                                    <div className='col-lg-5 col-md-5 col-4'>
                                        <img
                                        src={`${value.image ? bucketPath + value.image : "./../assets/doctor/img/patients/patient.jpg"}`}
                                        className='img-fluid'
                                        alt='...'
                                        />
                                    </div>
                                </div>
                            </div>
                        )})}
                    </div>
                </div>
            )
     }}

    const mapStateToProps = (state) => ({
        home_article: state.user.home_article,
    });

export default connect(mapStateToProps)(withRouter(ListingSideBar));
