import * as React from 'react';
import {GetArticle} from './../../store/actions/doctorPanelActions';
import {connect} from 'react-redux';

class ViewArticle extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {title:"",text:"",id:""};
    }

    componentDidMount = () => {
        let token = this.props.match.params.token;
        if(token && token!==""){
            let response = new Promise((resolved,rejected)=>{
                let dataresponse = this.props.dispatch(GetArticle({id:token}))
                if(dataresponse){
                    resolved(dataresponse)
                }else{
                    rejected('error')
                }
            });
            response.then(response => {
                if(response.response===1){
                    this.setState({
                        title:response.data.title,
                        text:response.data.description,
                        id:token
                      })
                }
            }).catch(error=>{
                console.log(error);
            })
        }
    }

  render() {
      const {title,text} = this.state;
    return (
        <>
            <div className="breadcrumb-bar view-artical-breadcrumb">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">View Article</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">{title}</h4>
                            <div className="profile-box">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card schedule-widget view-artical-widget mb-0" dangerouslySetInnerHTML={{
                                                __html: text
                                            }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

export default connect()(ViewArticle);
