import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Modals from "./General/Modals";
import ProductReviewForm from './productReviewForm';
import { GlobalProductList, ViewProduct, productModal, ProductReviewsList } from './../store/actions/globalActions'
import { bucketPath } from './../store/helpers/common';
import ListingSideBar from './ListingSideBar';
import { addProductCart, minusProductCart } from '../store/actions/productActions'
import { forceloginClick } from '../store/actions/patientActions'

import swal from 'sweetalert';

const initialState = {
    open: false,
    cartItems: [],
    productHideShow: false,
    modal_type:"",
    addedIntoCard:false,
    cartAddPop:false,
}
class Products extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = initialState;

    }

    componentDidMount() {
        this.props.dispatch(GlobalProductList())

    }

    setOpen = (value) => {
        this.setState({
          open: false,
          modal_type:""
        })
    };

    ViewProduct = (id, productID) => {
        this.props.dispatch(ViewProduct({ id: id }));
        this.props.dispatch(ProductReviewsList({ productID: productID }));
        this.setState({
            open: true,
            modal_type:"view_detail"
        })
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        let product_cart = newProps.product_cart ?? [];
        this.setState({ cartItems: product_cart });
        if (newProps?.open_product_modal) {
            if (newProps.open_product_modal.open && newProps.open_product_modal.open === true) {
                this.ViewProduct(newProps.open_product_modal.id);
                this.props.dispatch(productModal({}))
            }
        }
    }

    onAddProductCart = (value) => {
        this.props.dispatch(addProductCart(value, this.state));
        // this.productHideShowHandler()
        return null;
    };

    onMinusProductCart = (value) => {
        this.props.dispatch(minusProductCart(value, this.state));
        // this.productHideShowHandler()
        return null;
    };

    condimentPopShow = (value) => {
        this.setState({
            productHideShow: value
        })
    }

      productHideShowHandler = () =>{
        this.setState({
          productHideShow:false
        })
      }

      openReviewModal = () => {
        const getUserIdentity = localStorage.getItem('token');
        const identity = localStorage.getItem('type');
        if (!getUserIdentity && identity !== "patient" ) {
          this.setState({
              open: false,
              modal_type:""
          })
          swal({
            text: "You need to login to write Review!",
            icon: "info",
            buttons: true,
            dangerMode: true,
          })
            .then((yesDo) => {
              if (yesDo) {
                this.props.dispatch(forceloginClick(true))
              } else {
                console.log("You Clicked on false!");
              }
            });
          return false;
        }
        this.setState({
          open: true,
          modal_type:"add_review"
        })
      }

      callbackFunctionProduct = () =>{
        this.setState({
          open: false,
          modal_type:""
        })
      }

      addedItemPop = () =>{
      this.setState({
        addedIntoCard:true
      })
      setTimeout(() => {
        this.setState({
          addedIntoCard:false,
          cartAddPop:true
        },() =>{
          setTimeout(() =>{
            this.setState({
              cartAddPop:false
            })
          },1500);
        })
    }, 2000);
    }

    cartAddPopClose = () =>{
      this.setState({
        cartAddPop:false
      })
    }


    render() {
        const { open,modal_type } = this.state;
        const { product_list, view_product, product_cart, product_reviews_list } = this.props

        let name = view_product?.name ?? '';
        let price = view_product?.price ?? '';
        let description = view_product?.description ?? '';
        let product_highlight = view_product?.porduct_highlight ?? '';
        let images = view_product?.image ?? '';
        let viewProductArray =
            product_cart instanceof Array &&
            product_cart.findIndex(
                (obj) =>
                    obj.uniqueID === view_product.uniquekey
            );
        let productID = view_product?.productID ?? '';
        let productfilterdata = product_list && product_list.length > 0 && product_cart && product_cart.length > 0 ? product_list.filter(item =>item.uniquekey === product_cart[product_cart.length -1].uniqueID) :[]
        return (
            <div className="page-content background">
                <div className="banner-section-inner">
                    <div className="container-fluid">
                        <div className="col-md-12">
                        <h2>Products</h2>
                        <p>Some informative text will come in this line to tell about this page</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                {
                  <>
                  {
                    product_cart && product_cart.length > 0 && (
                    <div className={`row cart-items-main-div cart-items-main-div-2 col-md-4 ${this.state.addedIntoCard === false ? "small-pop-up" : ""}`}>
                        <div className="col-md-2">
                      <Link className="cart-item-img" to="#"><img src={productfilterdata && productfilterdata.length > 0 ? bucketPath + productfilterdata[0].image : ""} alt=""/></Link>
                        </div>
                        <div className="col-md-5 cart-item-name">
                          <h3><Link to="#">{product_cart[product_cart.length -1].name}</Link></h3>
                          <small>{productfilterdata && productfilterdata.length > 0 && productfilterdata[0].quantity}</small>
                        </div>
                        <div className="col-md-2">
                          <Link to="#" className="cart-item-count">{product_cart[product_cart.length -1].count}</Link>
                        </div>
                        <div className="col-md-3">
                          <p className="cart-item-price">RS {product_cart[product_cart.length -1].price}</p>
                        </div>
                    </div>
                  )}

                  <div className={`cart-add-popup col-md-4 ${this.state.cartAddPop === false ? "cart-add-pop-hide" : ""} `}>
                    <button type="button" className="close" onClick={this.cartAddPopClose}><span aria-hidden="true">×</span></button>
                            <span>Added to Cart</span>
                  </div>
                  </>
                }

                    <div className="row">
                        <div className="doctor-list-section col-md-8">
                            <div className="clearfix"></div>
                            <div className="filters-inner-pages">
                                <div className="row">
                                    <ul className="filters-bar-inner col-md-12">
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Family Care
                                        </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Senior Care</Link>
                                                    <Link className="dropdown-item" to="#">Feminine Hygiene</Link>
                                                    <Link className="dropdown-item" to="#">Mother care</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Fitness & Wellness
                                         </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Protein Supplements</Link>
                                                    <Link className="dropdown-item" to="#">Mass Gainers</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Skin care
                                         </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Acne care</Link>
                                                    <Link className="dropdown-item" to="#">Body and Bath</Link>
                                                    <Link className="dropdown-item" to="#">Face wash and cleansers</Link>
                                                    <Link className="dropdown-item" to="#">Skin care suppliments</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Hair care
                                         </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Antidandruff</Link>
                                                    <Link className="dropdown-item" to="#">Antihairloss</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Lip Care
                                         </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Lip Balms</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Baby care
                                         </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Baby Bath</Link>
                                                    <Link className="dropdown-item" to="#">Diapers and wipes</Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                More...
                                         </Link>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="filter-drop-height">
                                                    <Link className="dropdown-item" to="#">Sexual wellness</Link>
                                                    <Link className="dropdown-item" to="#">Antihairloss</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid-view-div">
                                <div className="row list-repeat-row">
                                    {product_list && product_list.map((value, index) => {
                                        let arrayFilter = product_cart instanceof Array &&
                                            product_cart.findIndex(
                                                (obj) =>
                                                    obj.uniqueID === value.uniquekey
                                            );
                                        return (
                                            <div className="col-md-6 col-lg-4" key={`product_cart_${index}`}>
                                                <div className="doctor-list-box product-list-box">
                                                    <div className="product-list-img  grid-product-img text-center col-md-12 align-self-center text-center">
                                                        <Link to="#" onClick={() => this.ViewProduct(value.uniquekey, value.productID)}>
                                                            <img
                                                                src={`${
                                                                    value.image
                                                                        ? bucketPath + value.image
                                                                        : './../assets/images/dummy.jpg'
                                                                    }`}
                                                                className="img-fluid"
                                                                alt="..."
                                                            />
                                                        </Link>
                                                        {product_cart instanceof Array && arrayFilter !== -1 ? (
                                                            <>
                                                                <Link
                                                                    to='#'
                                                                    className="add-ico-condiment"
                                                                    onClick={() => this.condimentPopShow(value.uniquekey)}
                                                                >
                                                                    <span className="">{product_cart[arrayFilter].count}</span>
                                                                </Link>
                                                                <div className={`condiment-increment-decrement-area row ${this.state.productHideShow === value.uniquekey ? "" : "hide"} `}>
                                                                    <div className="col-md-4 align-self-center">
                                                                        <Link
                                                                            to='#'
                                                                            onClick={(e) =>
                                                                                this.onMinusProductCart(value)
                                                                            }
                                                                        >
                                                                            {product_cart[arrayFilter].count > 1 ?
                                                                                <i className="fas fa-minus icon-cartbox"></i> :
                                                                                <i className="far fa-trash-alt"></i>
                                                                            }
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-md-4 no-padding align-self-center">
                                                                        <p>{product_cart[arrayFilter].count}</p>
                                                                    </div>
                                                                    <div className="col-md-4 align-self-center">
                                                                        <Link
                                                                            to="#"
                                                                            onClick={(e) => this.onAddProductCart(value)}
                                                                        >
                                                                            <i className="fas fa-plus icon-cartbox"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </>) : (
                                                                <Link
                                                                    className="add-ico-condiment"
                                                                    to="#"
                                                                    onClick={(e) =>{
                                                                      this.onAddProductCart(value);this.addedItemPop()
                                                                    }
                                                                  }
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Link>
                                                            )}
                                                    </div>
                                                    <div className="product-detail-box grid-product-height">
                                                        <div className="row">
                                                            <div className="col-md-12 ">
                                                                <p className="product-name">{value.name.toUpperCase()} {`(${value.quantity})`}</p>
                                                                <p className="product-rs">{` ₹ ${value.price}`}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 product-bx-btn align-self-center text-center">
                                                            <Link 
                                                                className="btn btn-sm btn-light-black margin-top-10" 
                                                                to="#" 
                                                                onClick={() => this.ViewProduct(value.uniquekey, value.productID)}
                                                            > 
                                                                View Detail
                                                            </Link>
                                                            {/* <Link className="btn btn-sm btn-light-black margin-top-10" to="#">Add to Cart</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <ListingSideBar />
                    </div>
                </div>
                <Modals open={open} setOpen={this.setOpen}>
                    <Modal.Header closeButton >
                        <Modal.Title id="example-custom-modal-styling-title">
                            {modal_type === "add_review" ? "Add Review" : "Product Details"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {modal_type === "view_detail" ?(
                      <div className="product-details-body">
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="prudct-slider-side main-post">
                                      <figure>
                                          <div
                                              id='carouselExampleIndicators'
                                              className='carousel slide'
                                              data-ride='carousel'
                                          >
                                              <ol className="carousel-indicators">
                                                  {images && images.map(
                                                      (imgVal, imgIndex) => {
                                                          return (
                                                              <li key={"dot_" + imgIndex} data-target="#carouselExampleIndicators" data-slide-to={`${imgIndex}`} className=""></li>
                                                          )
                                                      })
                                                  }
                                              </ol>
                                              <div
                                                  className='carousel-inner'
                                                  role='listbox'
                                              >
                                                  {images && images.map(
                                                      (imgVal, imgIndex) => {
                                                          return (
                                                              <div className={`carousel-item ${imgIndex === 0 ? "active" : ""}`} key={`item_${imgIndex}`}>
                                                                  <Link to='#'>
                                                                      <img
                                                                          src={`${bucketPath}${imgVal.image}`}
                                                                          alt='product'
                                                                      />
                                                                  </Link>
                                                              </div>
                                                          );
                                                      }
                                                  )}
                                              </div>
                                              <a
                                                  className='carousel-control-prev'
                                                  href='#carouselExampleIndicators'
                                                  role='button'
                                                  data-slide='prev'
                                              >
                                                  <span
                                                      className='fas fa-chevron-left'
                                                      aria-hidden='true'
                                                  ></span>
                                                  <span className='sr-only'>Previous</span>
                                              </a>
                                              <a
                                                  className='carousel-control-next'
                                                  href='#carouselExampleIndicators'
                                                  role='button'
                                                  data-slide='next'
                                              >
                                                  <span
                                                      className='fas fa-chevron-right'
                                                      aria-hidden='true'
                                                  ></span>
                                                  <span className='sr-only'>Next</span>
                                              </a>
                                          </div>
                                      </figure>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="prudct-content-side">
                                      <h2 className="product-dt-name">{name.toUpperCase()}</h2>
                                      <ul className="star-ul rating">
                                          <li>
                                              <i className="fas fa-star filled"></i>
                                              <i className="fas fa-star filled"></i>
                                              <i className="fas fa-star "></i>
                                              <i className="fas fa-star "></i>
                                              <i className="fas fa-star "></i>
                                          </li>
                                          <li>
                                              <a className="rating-count" href="#product-review-rating">{`(${product_reviews_list.length} reviews)`} </a>
                                          </li>
                                      </ul>
                                      <div className="row price-cart-row">
                                      <h3 className="text-red col-md-4 align-self-center">{`₹ ${price}`}</h3>
                                      <div className="col-md-8">
                                      {product_cart instanceof Array &&
                                          viewProductArray !== -1 ? (
                                              <div className={`row condiment-increment-decrement-area cart-box `}>
                                                  <div className="col-md-4">
                                                      <Link
                                                          to='#'
                                                          onClick={(e) => this.onMinusProductCart(view_product)}
                                                      >
                                                          {product_cart[viewProductArray].count > 1 ?
                                                              <i className="fas fa-minus-circle"></i> :
                                                              <i className="far fa-trash-alt"></i>
                                                          }
                                                      </Link>
                                                  </div>
                                                  <div className="col-md-4 no-padding"><p>{product_cart[viewProductArray].count}</p></div>
                                                  <div className="col-md-4">
                                                      <Link
                                                          to='#'
                                                          onClick={(e) => this.onAddProductCart(view_product)}
                                                      >
                                                          <i className="fas fa-plus-circle"></i>
                                                      </Link>
                                                  </div>
                                              </div>
                                          ) : (
                                              <Link
                                                  className="btn btn-sm btn-light-black "
                                                  to="#"
                                                  onClick={(e) =>{
                                                    this.onAddProductCart(view_product);this.addedItemPop()
                                                  }
                                                }
                                              >
                                                  Add to Cart
                                              </Link>
                                          )}
                                        </div>
                                        </div>
                                      <ul className="discription-body highlight">
                                          <h4>Product Highlights</h4>
                                          <p>{product_highlight}</p>
                                          {/* <li>Phasellus suscipit ante id congue maximus.</li>
                                          <li>Etiam ut urna accumsan, tempus metus a, sagittis nunc.</li>
                                          <li>Sed suscipit leo vel pharetra varius.</li>
                                          <li>Etiam ut urna accumsan, tempus metus a, sagittis nunc.</li>
                                          <li>Sed suscipit leo vel pharetra varius.</li> */}
                                      </ul>
                                  </div>
                              </div>
                          </div>
                          {/* comment area strt */}
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="discription-body">
                                      <h2>Product Description</h2>
                                      <p>{description}</p>
                                  </div>
                              </div>
                          </div>
                          {/* comment area strt */}
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="comment-body">
                                      <h2><i className="fas fa-comment-alt"></i>Comments / Reviews</h2>


                                      <ul className="product-review" id="product-review-rating">
                                          {
                                              product_reviews_list &&
                                              product_reviews_list.map((value, index) => {
                                                  return (
                                                      <li>
                                                          <div className="comment">
                                                              <img
                                                                  className="avatar avatar-sm rounded-circle"
                                                                  alt="User "
                                                                  src="./..//assets/img/patients/patient.jpg"
                                                              />
                                                              <div className="comment-body" style={{ width: "100%" }}>
                                                                  <div className="meta-data">
                                                                      {/* <span className="comment-author">{user_name}</span> */}
                                                                      <span className="comment-date">{value.creationdate}</span>
                                                                      <div className="review-count rating" style={{ display: "block" }}>
                                                                          {[...Array(5).keys()].map((val, i) => {
                                                                              return (
                                                                                  <i
                                                                                      key={`star_${i}`}
                                                                                      className={`fas fa-star ${
                                                                                          val + 1 <= value.points
                                                                                              ? "filled"
                                                                                              : ""
                                                                                          }`}
                                                                                  ></i>
                                                                              );
                                                                          })}
                                                                      </div>
                                                                  </div>
                                                                  <p className="comment-content">{value.description}</p>
                                                              </div>
                                                          </div>
                                                      </li>
                                                      // <li className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
                                                      //     <div className="list-holder ">
                                                      //         <div className="product-review-text">
                                                      //             <div className="product-title">
                                                      //                 <h6>{value.title}</h6>
                                                      //             </div>
                                                      //             <ul className="star-ul rating">
                                                      //                 <li>
                                                      //                     <i className="fas fa-star filled"></i>
                                                      //                     <i className="fas fa-star filled"></i>
                                                      //                     <i className="fas fa-star "></i>
                                                      //                     <i className="fas fa-star "></i>
                                                      //                     <i className="fas fa-star "></i>
                                                      //                 </li>
                                                      //             </ul>
                                                      //         </div>
                                                      //         <em className="prodcut-date">{value.creationdate.split(" ")}</em>
                                                      //         <p>{value.description}</p>
                                                      //     </div>
                                                      // </li>
                                                  )
                                              })
                                          }
                                      </ul>


                                  </div>
                              </div>
                              <div className="col-md-12 text-center review-btn-div">
                              <Link to="#" onClick={() =>
                                  this.openReviewModal()
                                } className="write-review-btn">Write Review</Link>
                            </div>
                          </div>
                          {/* comment area end */}
                      </div>
                    ):modal_type === "add_review" ?(
                          <ProductReviewForm loginKey={productID} parentCallbackProduct={this.callbackFunctionProduct}/>
                    ):null}

                    </Modal.Body>
                </Modals>

            </div>
        );
    };
}

const mapStateToProps = (state) => ({
    product_list: state.global.product_list,
    view_product: state.global.view_product,
    product_cart: state.product.product_cart,
    product_reviews_list: state.global.product_reviews_list,
    open_product_modal: state.global.open_product_modal
});

export default connect(mapStateToProps)(Products);
