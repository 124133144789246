import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PatientMedicalRecordsList } from '../../store/actions/registerationActions';
import { DoctorCounsellorListing } from '../../store/actions/userActions';
import {ShowLoader,ShowAlert} from '../../store/actions/staticListingsActions'
import {DeleteMedicalRecord} from '../../store/actions/doctorPanelActions'
import Pagination from '../../store/helpers/Pagination';
import { Modal } from 'react-bootstrap';
import Modals from './../General/Modals'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { apiBaseUrl,bucketPath} from './../../store/helpers/common';
import swal from 'sweetalert';
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";

const axios = require("axios");
const initialState = { pageOfItems: [], currentPage: 1, patientDetail: {},open:false,date:'',description:'',upload:'',name:'', viewImage:false,imgPath:'',shareWith:'',doctors:[],counsellors:[] };
class PatientMedicalRecords extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        const postData = { uniquekey: JSON.parse(localStorage.data).uniquekey }; //JSON.parse(localStorage.data).uniquekey
        this.props.dispatch(PatientMedicalRecordsList(postData));
        this.props.dispatch(DoctorCounsellorListing());
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({open: false, viewImage: false});
    };

    setDate = date => {
        this.setState({date:moment(date).local().format('YYYY-MM-DD')})
    }

    onClickHandler = async (e) => {
        if(this.validator.allValid()){
            this.UploadImage()
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    UploadImage = () => {
        const {date,description,name,doctors,counsellors} = this.state
        let request;
        let formData = new FormData();
            formData.append(`name`, name);
            formData.append(`attachment`, window.$("#file-upload")[0].files[0]);
            formData.append("date", date);
            // formData.append("description", description);
            formData.append('doctors',doctors);
            formData.append('counsellors',counsellors);
            formData.append("user_key", JSON.parse(localStorage.data).uniquekey);
            // formData.append("user_key", this.props.user_key);
        request = {
            method: "post",
            url: `${apiBaseUrl}/api/add-medical-record`,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            },
            data: formData,
        };
        this.props.dispatch(ShowLoader(true));
        axios(request).then((res) => {
            if (res.data.ResponseCode === "1") {
              this.setState(initialState,() => {
                  this.props.dispatch(PatientMedicalRecordsList({ uniquekey: JSON.parse(localStorage.data).uniquekey }));
                  this.props.dispatch(ShowLoader(false));
                  this.props.dispatch(
                    ShowAlert({
                      Class: "alert-success",
                      Message: res.data.ResponseText,
                      Timeout: 10000,
                    })
                  );
                }
              );
            }
          }).catch((error) => {
            this.props.dispatch(ShowLoader(false));
            this.props.dispatch(
              ShowAlert({
                Class: "alert-danger",
                Message: "Error Occured.",
                Timeout: 10000,
              })
            );
          });
    }

    DeleteRecord = value => {
        swal({
            title: "Are you sure want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
              const data = {user_key: JSON.parse(localStorage.data).uniquekey,id: value};
              this.props.dispatch(DeleteMedicalRecord(data));
            }
          });
    }

    groupValue = () => {
        if(this.state.shareWith === 'Doctors'){
            this.setState({ doctors: window.$("#doctorID").val() });
        } else if(this.state.shareWith === 'Counsellors'){
            this.setState({ counsellors: window.$("#counsellorID").val() });
        }else
            return 1

    };

    removeGroupValue = () => {
        if(this.state.shareWith === 'Doctors'){
            this.setState({ doctors: window.$("#doctorID").val() });
        } else if(this.state.shareWith === 'Counsellors'){
            this.setState({ counsellors: window.$("#counsellorID").val() });
        }else
            return 1
    };


    render() {
        const { medical_records,doctor_counsellor } = this.props;
        let doctors = doctor_counsellor?.doctor
        let counsellors = doctor_counsellor?.councillor
        const { pageOfItems, open } = this.state
        const type = localStorage.getItem('type');
        return (
            <div>
                {type === "patient" && <div className="text-right">
                    <Link to="#" className="add-new-btn" onClick={e => this.setState({ open: true })}>Add Medical Records</Link>
                </div>}
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date </th>
                                        <th>Description</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td><Link to="#">#MR-0010</Link></td>
                                        <td>14 Nov 2019</td>
                                        <td>Dental Filling</td>
                                        <td><Link to="#">dental-test.pdf</Link></td>
                                        <td>
                                            <h2 className="table-avatar">
                                                <Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
                                                    <img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
                                                </Link>
                                                <Link to="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></Link>
                                            </h2>
                                        </td>
                                        <td className="text-right">
                                            <div className="table-action">
                                                <Link to="#" className="btn btn-sm bg-info-light">
                                                    <i className="far fa-eye"></i> View
                                                </Link>
                                            </div>
                                        </td>
                                    </tr> */}
                                    {medical_records.length < 1
                                    && <tr>
                                        <td colSpan="4">
                                            No data found
                                        </td>
                                    </tr>}
                                    {pageOfItems.map((value, key) => {
                                        return (
                                            <tr key={`medical_records_${key}`}>
                                                <td><Link to="#">{value.uniquekey}</Link></td>
                                                <td>{value.creationdate}</td>
                                                <td>{value.name}</td>
                                                <td className="text-right">
                                                    <div className="table-action">
                                                        {value.attachment.split('.').pop() !== 'pdf'
                                                        && <Link to="#" className="btn btn-sm bg-info-light" onClick={e=>localStorage.getItem('type')!=="doctor"?this.setState({viewImage:true,imgPath:value.attachment,open:true}):false}>
                                                                <i className="far fa-eye"></i> View
                                                            </Link>
                                                        }
                                                        {value.attachment.split('.').pop() === 'pdf'
                                                        && <a href={`${bucketPath}${value.attachment}`} target="_blank" className="btn btn-sm bg-info-light" rel="noopener noreferrer">
                                                                <i className="far fa-eye"></i> View
                                                            </a>
                                                        }
                                                        <Link to="#" className="btn btn-sm bg-danger-light" onClick={e=>this.DeleteRecord(value.id)}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {medical_records.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={medical_records}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* modal for add medical record */}

                <Modals open={open} setOpen={this.setOpen} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Medical Records
                        </Modal.Title>
                    </Modal.Header>
                    {!this.state.viewImage && <Modal.Body>
                        <div className="form-group">
                            <label>Name</label>
                            <input className="form-control" name="name" value={this.state.name} onChange={e => this.setState({ [e.target.name]: e.target.value })} />
                            {this.validator.message('Name', this.state.name, 'required')}
                        </div>
                        <div className="form-group">
                            <label>Date</label>
                            <DatePicker className="form-control" name="date" dateFormat="dd/MM/yyyy" selected={this.state.date ? new Date(this.state.date) : ''} onChange={val => this.setDate(val)} />
                            {this.validator.message('Date', this.state.date, 'required')}
                        </div>
                        {/* <div className="form-group">
                            <label>Description ( Optional )</label>
                            <textarea className="form-control" name="description" value={this.state.description} onChange={e => this.setState({ [e.target.name]: e.target.value })}></textarea>
                            {this.validator.message('Description', this.state.description, 'required')}
                        </div> */}
                        <div className="form-group mb-0 row">
                            <label className="col-md-3">Share With</label>
                            <div className="col-md-9">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="price_free" name="rating_option" className="custom-control-input" checked={this.state.shareWith === "Doctors" ? "checked" : undefined } value="Doctors"  onChange = {(e) =>this.setState({shareWith:e.target.value})} />
                                    <label className="custom-control-label" htmlFor="price_free">Doctors</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="price_custom" name="rating_option" className="custom-control-input" checked={this.state.shareWith === "Counsellors" ? "checked" : undefined } value="Counsellors"  onChange = {(e) =>this.setState({shareWith:e.target.value})} />
                                    <label className="custom-control-label" htmlFor="price_custom">Counsellors</label>
                                </div>
                            </div>
                        </div>
                        {this.state.shareWith === 'Doctors' && <div className="row mt-0 mb-3">
                            <div className="col-md-12">
                                <Select2
                                    multiple
                                    className="form-control"
                                    name="doctors"
                                    value={this.state.doctors}
                                    data={
                                        doctors &&
                                        doctors.map((value, index) => ({
                                        text: value.doctor_name,
                                        id: value.doctorID,
                                        }))
                                    }
                                    options={{
                                        placeholder: "Choose Doctors",
                                    }}
                                    onSelect={this.groupValue}
                                    onUnselect={this.removeGroupValue}
                                    id="doctorID"
                                />
                            </div>
                        </div>  }
                        {this.state.shareWith === 'Counsellors' && <div className="row  mt-0 mb-3">
                            <div className="col-md-12">
                                <Select2
                                    multiple
                                    className="form-control"
                                    name="counsellors"
                                    value={this.state.counsellors}
                                    data={
                                        counsellors &&
                                        counsellors.map((value, index) => ({
                                        text: value.councillor_name,
                                        id: value.councillorID,
                                        }))
                                    }
                                    options={{
                                        placeholder: "Choose counsellors",
                                    }}
                                    onSelect={this.groupValue}
                                    onUnselect={this.removeGroupValue}
                                    id="counsellorID"
                                />
                            </div>
                        </div>  }
                        <div className="form-group">
                            <label>Upload File</label>
                            <input type="file" className="form-control" id="file-upload" name="upload" value={this.state.upload} onChange={e => this.setState({ [e.target.name]: e.target.value })} />
                            {this.validator.message('Document', this.state.upload, 'required')}
                        </div>
                        <div className="submit-section text-center">
                            <Link to="#" onClick={e => this.onClickHandler()} className="btn btn-light-black submit-btn">Submit</Link>
                            <Link to="#" onClick={e => this.setState({ name: '', upload: '', date: '', description: '',doctors:[],counsellors:[] })} className="btn btn-grey-border submit-btn">Clear</Link>
                        </div>
                    </Modal.Body>}
                    {this.state.viewImage && <Modal.Body>
                        <div className="form-group">
                            <img src={`${this.state.imgPath ? `${bucketPath}${this.state.imgPath}` : "/assets/img/patients/patient6.jpg"}`} alt="text" />
                        </div>
                    </Modal.Body>}
                </Modals>

            </div>
        )
    }
}
const mapStateToProps = state => ({
    medical_records: state.registration.medical_records,
    doctor_counsellor:state.user.doctor_counsellor_listing
});

export default connect(mapStateToProps)(PatientMedicalRecords);
