import React from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { GetTodayFitnessAppointmentList, GetFitnessAppointmentDetails } from '../../store/actions/fitnessActions';
import { bucketPath } from "../../store/helpers/common";

class FitnessDashboard extends React.Component{
    constructor(props){
        super(props)
        this.state = {
             tab : 'today'
		}
		this.getData = localStorage.getItem('data')
	}

	componentDidMount() {
		this.props.dispatch(GetFitnessAppointmentDetails())
		if (this.getData && JSON.parse(this.getData).uniquekey) {
			this.props.dispatch(GetTodayFitnessAppointmentList({ uniquekey: JSON.parse(this.getData).uniquekey,date_param:'today' }))
			sessionStorage.setItem("key",JSON.parse(this.getData).uniquekey)
		}
	}

	handleChange = (value) => {
		this.setState({
			tab:value
		})
		this.props.dispatch(GetTodayFitnessAppointmentList({ uniquekey: JSON.parse(this.getData).uniquekey , date_param:value}))
	}


    render () {
		const { today_appointments, fitness_appointment_details } = this.props
        return (
            <div>
				<div className="card dash-card">
					<div className="card-body">
						<div className="row">
							<div className="col-md-12 col-lg-4">
								<div className="dash-widget dct-border-rht">
									<div className="circle-bar circle-bar1">
										<div className="circle-graph1" data-percent="75">
											<img  src= "./../assets/doctor/img/total_patient.png"  className="img-fluid"  alt="..." />
										</div>
									</div>
									<div className="dash-widget-info">
										<h6>Total Patient</h6>
										<h3>{fitness_appointment_details.total_patients}</h3>
										{/* <p className="text-muted">Till Today</p> */}
									</div>
								</div>
							</div>
							<div className="col-md-12 col-lg-4">
								<div className="dash-widget dct-border-rht">
									<div className="circle-bar circle-bar2">
										<div className="circle-graph2" data-percent="65">
											<img src="./../assets/doctor/img/today_patient.png" className="img-fluid" alt="..." />
										</div>
									</div>
									<div className="dash-widget-info">
										<h6>Today Patient</h6>
										<h3>{fitness_appointment_details.today_appointments}</h3>
										{/* <p className="text-muted">06, Nov 2019</p> */}
									</div>
								</div>
							</div>
							<div className="col-md-12 col-lg-4">
								<div className="dash-widget">
									<div className="circle-bar circle-bar3">
										<div className="circle-graph3" data-percent="50">
											<img src="./../assets/doctor/img/appoinment.png" className="img-fluid" alt="..." />
										</div>
									</div>
									<div className="dash-widget-info">
										<h6>Total earned</h6>
										<h3>${fitness_appointment_details.total_earn}</h3>
										{/* <p className="text-muted">06, Apr 2019</p> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<h4 className="mb-4">Patient Appoinment</h4>
							<div className="appointment-tab">
								<ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
									<li className="nav-item">
										<Link className="nav-link active" to="#" data-toggle="tab" onClick={()=>this.handleChange('today')}>Today</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link " to="#" data-toggle="tab" onClick={()=>this.handleChange('upcoming')}>Upcoming</Link>
									</li>
								</ul>

								<div className="tab-content">
									{/* <!-- Today Appointment Tab --> */}

									<div className={`tab-pane ${this.state.tab === 'today' ? 'active' : ''}`} >
										<div className="card card-table mb-0">
											<div className="card-body">
												<div className="table-responsive">
													<table className="table table-hover table-center mb-0">
														<thead>
															<tr>
																<th>Patient Name</th>
																<th>Appt Date</th>
																<th>Consult Type</th>
																{/* <th>Patient Type</th> */}
																<th className="text-center">Paid Amount</th>
																{/* <th></th> */}
															</tr>
														</thead>
														<tbody>
															{today_appointments && today_appointments.map((value,index)=>{
																let date = value.appointment_date_display.split(',')
																return (
																	<tr key={`appt_${index}`}>
																		<td>
																			<h2 className="table-avatar">
																				<Link to={`/fitness/patient-profile/${value.uniquekey}`} className="avatar avatar-sm mr-2">
																					<img
																						className="avatar-img rounded-circle"
																						src={
																							value.patient_profile_pic
																							? `${bucketPath}${value.patient_profile_pic}`
																							: "./../assets/img/patients/patient6.jpg"
																						}
																						alt="User"
																					/>
																				</Link>
																				<Link to={`/fitness/patient-profile/${value.uniquekey}`}>{value.patient_name}<span>{value.uniquekey}</span></Link>
																			</h2>
																		</td>
																		<td>{date[0]}<span className="d-block text-info">{date[1]}</span></td>
																		<td>{value.appointment_type}</td>
																		{/* <td>Old Patient</td> */}
																		<td className="text-center">${value.paid_amount}</td>
																		{/* <td className="text-right">
																			<div className="table-action">
																				<Link to="#" className="btn btn-sm bg-success-light">
																					<i className="fas fa-check"></i> Accept
																									</Link>
																				<Link to="#" className="btn btn-sm bg-danger-light">
																					<i className="fas fa-times"></i> Cancel
																									</Link>
																			</div>
																		</td> */}
																	</tr>
																)
																})
															}
															{!today_appointments && <tr> <td>No Appointments today</td> </tr> }
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- /Today Appointment Tab --> */}

									{/* <!-- Upcoming Appointment Tab --> */}
										<div className={`tab-pane ${this.state.tab === 'upcoming' ? 'active' : ''}`}>
											<div className="card card-table mb-0">
												<div className="card-body">
													<div className="table-responsive">
														<table className="table table-hover table-center mb-0">
															<thead>
																<tr>
																	<th>Patient Name</th>
																	<th>Appt Date</th>
																	<th>Consult Type</th>
																	{/* <th>Patient Type</th> */}
																	<th className="text-center">Paid Amount</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
															{today_appointments && today_appointments.map((value,index)=>{
																let date = value.appointment_date_display.split(',')
																return (
																	<tr key={`appt_${index}`}>
																		<td>
																			<h2 className="table-avatar">
																				<Link to={`/fitness/patient-profile/${value.uniquekey}`} className="avatar avatar-sm mr-2">
																					<img
																						className="avatar-img rounded-circle"
																						src={
																							value.patient_profile_pic
																							? `${bucketPath}${value.patient_profile_pic}`
																							: "./../assets/img/patients/patient6.jpg"
																						}
																						alt="User"
																					/>
																				</Link>
																				<Link to={`/fitness/patient-profile/${value.uniquekey}`}>{value.patient_name}<span>{value.uniquekey}</span></Link>
																			</h2>
																		</td>
																		<td>{date[0]}<span className="d-block text-info">{date[1]}</span></td>
																		<td>{value.appointment_type}</td>
																		{/* <td>Old Patient</td> */}
																		<td className="text-center">${value.paid_amount}</td>
																		<td className="text-right">
																			<div className="table-action">
																				<Link to="#" className="btn btn-sm bg-success-light">
																					<i className="fas fa-check"></i> Accept
																									</Link>
																				<Link to="#" className="btn btn-sm bg-danger-light">
																					<i className="fas fa-times"></i> Cancel
																									</Link>
																			</div>
																		</td>
																	</tr>
																)
																})
															}
															{!today_appointments && <tr> <td>No Upcoming Appointments </td> </tr> }
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									{/* <!-- /Upcoming Appointment Tab --> */}
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}
const mapStateToProps = state => ({
	today_appointments: state.fitness.fitness_today_appointments,
	fitness_appointment_details : state.fitness.fitness_appointment_details
});

export default connect(mapStateToProps)(FitnessDashboard);
