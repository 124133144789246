import React from "react";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import { Link } from "react-router-dom";
import { getAdminInvoiceListing, DeleteInvoice } from "./../../store/actions/adminActions";
import { Modal} from "react-bootstrap";
import Modals from "./../General/Modals"; 
import swal from 'sweetalert';
import { apiBaseUrl } from './../../store/helpers/common'

class AdminInvoiceReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentPage: 1, totalItems: [], pageOfItems: [] };
    }

    componentDidMount() {
        this.props.dispatch(getAdminInvoiceListing());
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    handleClick = (e, id) => {
        this.props.history.push(`/admin/doctorprofile/${id}`);
    };

    setOpen = (value) => {
        this.setState({ status: "", doctorID: "", open: false, modal_type: '' })
    };

    deleteConfirmation = (invoice_number) => {
        swal({
			title: "Are you sure want to delete this record?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.props.dispatch(DeleteInvoice({ invoiceID: invoice_number }));
				} else {
					swal({ title: "Record is safe!", icon: "success", });
				}
			});
    }

    render() {
        const { admin_invoice_listing } = this.props
        const { open, description, modal_type, amount, currency, comment } = this.state;
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="datatable table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Invoice Number</th>
                                                <th>Patient Id</th>
                                                <th>Patient Name</th>
                                                {/* <th>Doctor Name</th> */}
                                                <th>Total Amount</th>
                                                <th>Paid Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.pageOfItems.length > 0 ? (
                                                this.state.pageOfItems.map((val, i) => (
                                                    <tr key={"invoice_" + i}>
                                                        <td>
                                                            <Link to={"https://tll.cap-tek.com:8081/api/download-invoice?invoice_number=" + val.invoice_number} target="_blank" rel="noopener noreferrer">{val.invoice_number}</Link>
                                                        </td>
                                                        <td>
                                                            <Link to="#">{val.patients_uniquekey}</Link>
                                                        </td>
                                                        <td>
                                                            <h2 className="table-avatar">
                                                                <Link to="patient-profile.html" className="avatar avatar-sm mr-2">
                                                                    <img className="avatar-img rounded-circle" src={val.patient_profile_pic ? "https://storage.googleapis.com/thelifeline-275005.appspot.com/thelifeline/" + val.patient_profile_pic : "./../assets/doctor/img/patients/patient.jpg"} alt="User" />
                                                                </Link>
                                                                <Link to="patient-profile.html">{val.patients_name} <span>{val.patients_uniquekey}</span></Link>
                                                            </h2>
                                                        </td>
                                                        {/* <td>
                                                            <h2 className="table-avatar">
                                                                <Link to="patient-profile.html" className="avatar avatar-sm mr-2">
                                                                    <img className="avatar-img rounded-circle" src={val.doctor_profile_pic ? "https://storage.googleapis.com/thelifeline-275005.appspot.com/thelifeline/" + val.doctor_profile_pic : "./../assets/doctor/img/patients/patient.jpg"} alt="User" />
                                                                </Link>
                                                                <Link to="patient-profile.html">{val.doctor_name} <span>{val.doctor_uniquekey}</span></Link>
                                                            </h2>
                                                        </td> */}
                                                        <td>{`₹ ${val.paid_amount}`}</td>
                                                        <td>{val.created.split(" ")[0]}</td>
                                                        <td className="text-right">
                                                            <div className="table-action">
                                                                <Link to={apiBaseUrl + "/api/download-invoice?invoice_number=" + val.invoice_number} target="_blank" rel="noopener noreferrer" className="btn btn-sm bg-primary-light">
                                                                    <i className="fa fa-download"></i> Download
																</Link>
                                                                <Link to="#" onClick={e => this.deleteConfirmation(val.id)} className="btn btn-sm bg-danger-light">
                                                                    <i className="fe fe-trash"></i>
                                                                    Delete
                                                                </Link>  
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                    <tr>
                                                        <td>No records available</td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid no-padding">
                                    <div className="row pull-right">
                                        <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                                            &nbsp;
                        {admin_invoice_listing.length > 0 ? (
                                                <Pagination
                                                    pageSize={20}
                                                    items={admin_invoice_listing}
                                                    onChangePage={this.onChangePage}
                                                />
                                            ) : (
                                                    ""
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modals open={open} setOpen={this.setOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {modal_type === "status_change" ? "Add description" : "Add Payment"}
                        </Modal.Title>
                    </Modal.Header>
                    {modal_type === "status_change" && <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            name="symptom"
                                            value={description}
                                            onChange={(e) => this.handleChange(e)}
                                            rows={3}
                                        />
                                        {this.validator.message(
                                            "Description",
                                            description,
                                            "required"
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Link
                                to="#"
                                className="btn btn-light-black btn-block"
                                onClick={(e) => this.submitHandler(e)}
                            >
                                Save
              </Link>
                        </form>
                    </Modal.Body>}
                    {modal_type === "payments" && <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-md-12">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Currency</label>
                      : {currency}
                                            {/* <select name="currency" value={currency} className="form-control" onChange={e=>this.onChangeHandler(e)}>
                        <option>Select Currency</option>
                        {Currencies.map(cvalue=>{
                          return (
                            <option value={cvalue} key={cvalue}>{cvalue}</option>
                          )
                        })}
                      </select> */}
                                            {/* {this.validator.message(
                        "Currency",
                        currency,
                        "required"
                      )} */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Amount</label>
                      : {amount}
                                            {/* <input type="number" name="amount" value={amount} className="form-control" onChange={e=>this.onChangeHandler(e)}/>
                      {this.validator.message(
                        "Amount",
                        amount,
                        "required"
                      )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Comments</label>
                                    <textarea
                                        className="form-control"
                                        name="comment"
                                        value={comment}
                                        onChange={e => this.onChangeHandler(e)}
                                        rows={3}
                                    />
                                </div>
                            </div>
                            <Link
                                to="#"
                                className="btn btn-light-black btn-block"
                                onClick={(e) => this.PaymentSubmitHandler(e)}
                            >
                                Save
              </Link>
                        </form>
                    </Modal.Body>}
                </Modals>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    admin_invoice_listing: state.admin.admin_invoice_listing
});

export default connect(mapStateToProps)(AdminInvoiceReport);
