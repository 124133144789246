import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PatientFitnessAppointmentList} from './../../store/actions/registerationActions';
import Pagination from './../../store/helpers/Pagination';
import { bucketPath } from '../../store/helpers/common';

const statusDetails = {
  C : {
    text: 'Confirm',
    className: 'bg-warning-light'
  },
  S: {
    text: 'Cancelled',
    className: 'bg-danger-light'
  },
  F: {
    text: 'Completed',
    className: 'bg-success-light'
  }
}
class PatientFitnessAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1};
  }

  componentDidMount() {
    this.props.dispatch(PatientFitnessAppointmentList());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  render() {
    const { patient_appoinments } = this.props;
    const { pageOfItems } = this.state;  ;
    return (
      <div>
        <div className='card card-table mb-0'>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-hover table-center mb-0'>
                <thead>
                  <tr>
                    <th>Expert's Name</th>
                    <th>Class Name</th>
                    <th>Staring Date</th>
                    <th>Duration</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItems.map((value, key) => {
                    return (
                      <tr key={`page_${key}`}>
                        <td>
                            <h2 className="table-avatar">
                              <Link to={`/view-expert/${value.fitness_uniquekey}`} className="avatar avatar-sm mr-2">
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={`${value.fitness_profile? bucketPath + value.fitness_profile : "./../assets/doctor/img/patients/patient.jpg"}`}
                                    alt="User"
                                  />
                              </Link>
                              <Link to={`/view-expert/${value.fitness_uniquekey}`}>{`${value.fitness_name}`} <span> {value.fitness_specialization}</span></Link>
                            </h2>
                        </td>
                        <td>
                          {value.class_title}
                        </td>
                        <td>
                          {value.date}
                        </td>
                        <td>
                          {value.class_duration}
                        </td>
                        <td>{`₹ ${value.class_price}`}</td>
                        <td>
                          <span className={`badge badge-pill ${statusDetails[value.status].className}`}>
                              {statusDetails[value.status].text}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {patient_appoinments.length > 0 && (
                <Pagination
                  pageSize={10}
                  items={patient_appoinments}
                  onChangePage={this.onChangePage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  patient_appoinments: state.registration.patfitness_appointments,
});

export default connect(mapStateToProps)(PatientFitnessAppointment);
