
import React from 'react';  
import { connect } from 'react-redux'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; 
import { Modal } from 'react-bootstrap';
import Modals from '../General/Modals';
import swal from "sweetalert";
import { UploadHeaderFooter,getHeaderFooterImage } from '../../store/actions/counselorPanelActions';
import { bucketPath } from "../../store/helpers/common";

const initialState = {headerImage :"",footerImage:'',croppedHeaderImage:'',croppedFooterImage:'',modal_type:'',open: false}  


class CounsellorPrescriptionPreview extends React.Component {
	constructor(props) {
		super(props)
		this.state= initialState
        this.cropper = React.createRef(null) 
	} 

	componentDidMount(){
		this.props.dispatch(getHeaderFooterImage());
	}

	 

	componentDidUpdate = (prevProps, prevState) => { 
		let nextProps = this.props; 
		if(nextProps.viewImage !== prevProps.viewImage){
		  this.setState({  
			croppedHeaderImage:nextProps.viewImage.prescription_header? nextProps.viewImage.prescription_header : '',
			croppedFooterImage:nextProps.viewImage.prescription_footer ? nextProps.viewImage.prescription_footer : '' 
		  }) 
		}
		 
	  } 

	updatePicture = (e,value) => { 
        let files
        if (e.dataTransfer) {
            files = e.dataTransfer.files
        } else if (e.target) {
            files = e.target.files
        }
        const reader = new FileReader()
        if(files[0].size > 1 * 1024 * 1024){
			swal({ title:'filesize should be < 1MB.', icon: "error", dangerMode: true }) 
            return
        }
        let val = files[0].name.toLowerCase(),
        regex = new RegExp("(.*?).(jpeg|jpg|png)$");

        if (!(regex.test(val))) { 
			swal({ title:'Please select correct file format(jpeg,jpg,png).', icon: "error", dangerMode: true }) 
            return
        }
        
		reader.readAsDataURL(files[0]) 
        reader.onload = () => {
            this.setState({
				headerImage:reader.result, 
				footerImage:reader.result,
				modal_type:value,
				open:true
            })  
		} 
        e.target.value = ""
	} 

	cropImage = () => { 
        if (typeof this.cropper.current.getCroppedCanvas() === 'undefined') {
          return
		}
		if(this.state.modal_type === 'header'){ 
			this.setState({
				croppedHeaderImage: this.cropper.current.getCroppedCanvas().toDataURL(),
				open:false
			},()=>{  
				this.props.dispatch(UploadHeaderFooter({prescription_header : this.state.croppedHeaderImage}));
			})
		}else{ 
			this.setState({
				croppedFooterImage: this.cropper.current.getCroppedCanvas().toDataURL(),
				open:false
			},()=>{
				this.props.dispatch(UploadHeaderFooter({prescription_footer : this.state.croppedFooterImage}));
			})
		}  
	}
	
	 
	 
	setOpen = () => { 
        this.setState({ open:false });
    };

	render() {  
		const {croppedFooterImage,croppedHeaderImage,headerImage,footerImage,modal_type,open} = this.state
		return (
			<div>
			<div className="card">
				<div className="card-body"> 
					<div className="header-div">
						<div className="row">
							<div className="col-md-12"> 
								<img   
									src={`${
										croppedHeaderImage
										  ? bucketPath + croppedHeaderImage
										  : "/assets/images/preview-header.png"
										}`}
									alt="upload" 
									className="thumb-xl rounded"
								/>
								<input 
									type="file" 
									id='header_image'  
									onChange={e=>this.updatePicture(e,'header')} 
									accept="image/jpeg, image/png"
								/>
							</div>
						</div>
					</div>
					<div className="content-discription-div">
						<div className="row">
							<div className="col-md-12">
								<p>Prescription will appear here</p>
							</div>
						</div>
					</div>
					<div className="footer-div">
						<div className="row">
							<div className="col-md-12"> 
								<img 
									src={`${
										croppedFooterImage
										  ? bucketPath + croppedFooterImage
										  : "/assets/images/preview-footer.png"
										}`}
									alt='upload' 
									className="thumb-xl rounded"
								/>
								<input 
									type="file" 
									id='logo_image' 
									onChange={e =>this.updatePicture(e,'footer')} 
									accept="image/jpeg, image/png"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>


			 {/* Modal for PrescriptionPreview */} 
				<Modals open={open} setOpen={this.setOpen} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="profileModalLongTitle">
							Upload {modal_type === 'header' ? 'Header' : 'Footer'} 
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-body"> 
							<div className="row">
								<div className="col-10">
									<Cropper
										ref={this.cropper}
										src={modal_type==='header' ? headerImage : footerImage}
										style={{height: 400, width: "100%"}}
										// aspectRatio={22 / 6}
										resizable={false}
										preview=".img-preview"
										guides={true}
									/>
								</div>
								<div className="col-2">
									<h5>Preview</h5> 
									<div className="img-preview thumb-xl rounded"></div>
								</div>
							</div> 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="submit-section">
										<button type="button" className="btn btn-grey-border" data-dismiss="modal" onClick={this.setOpen}>Close</button>&nbsp;&nbsp;
										<button type="button" className="btn btn-light-black" onClick={this.cropImage}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modals>

				</div>
		)
	}
}

const mapStateToProps = state => ({
	viewImage: state.doctorPanel.viewImage, 
});

export default connect(mapStateToProps)(CounsellorPrescriptionPreview); 