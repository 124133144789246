import * as types from './../types/types';

const initalState = {
  admin_global_search: '',
};

const filterReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.ADMIN_GLOBAL_SEARCH:
      return {
        ...state,
        admin_global_search: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;
