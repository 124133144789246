import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "./../../store/helpers/Pagination";
import {
    getAdminReviewList,
    DeleteReview,
} from "./../../store/actions/adminActions";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import Modals from "../General/Modals";

const descriptionStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

class AdminReviews extends Component {
    state = {
        pageOfItems: [],
        currentPage: 1,
        showModal: false,
        review: {},
        totalItems:[]
    };

    componentDidMount() {
        this.props.getAdminReviewList();
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    onReviewDelete = (uniqueKey) => {
        swal({
            title: "Are you sure want to delete this review?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.DeleteReview({
                    reviewID: uniqueKey,
                });
            }
        });
    };

    onReviewView = (review) => {
        this.setState({ showModal: true, review });
    };

    handleCloseModal = (val) => {
        this.setState({ showModal: val, review: {} });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {
            admin_reviews,
          filter: { admin_global_search },
        } = this.props;
        if (
          admin_reviews &&
          (admin_reviews !== prevProps.admin_reviews ||
            admin_global_search !== prevProps.filter.admin_global_search)
        ) {
          this.setState({ totalItems: this.filteredDataFxn() },()=>{
              this.onChangePage(this.state.totalItems,1)
          });
        }
      };

    filteredDataFxn = () => {
        const {
          admin_reviews,
          filter: { admin_global_search },
        } = this.props;
        if (admin_global_search) {
          return (
            admin_reviews &&
            admin_reviews.filter((val) => {
              return (
                val.description
                  .toString()
                  .toLowerCase()
                  .search(admin_global_search.toString().toLowerCase()) !== -1
              );
            })
          );
        } else {
          return admin_reviews;
        }
      };

    renderStars = (points) => {
        return ([...Array(5).keys(),].map((point, index) => {
                if (point +1 <= points ) {
                    return (
                        <i
                            key={`star_${index}`}
                            className="fas fa-star filled"
                        ></i>
                    );
                } else {
                    return (
                        <i
                            key={`star_${index}`}
                            className="fas fa-star"
                        ></i>
                    );
                }
            }
        ))
    }

    render() {
        const { pageOfItems, review,totalItems } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="datatable table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Patient Name</th>
                                                <th>Doctor Name</th>
                                                <th>Ratings</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageOfItems.length > 0 ? (
                                                pageOfItems.map((val, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {/* <img src={val.review_giver_profile} alt={val.title}/> */}
                                                            {
                                                                val.review_giver_name
                                                            }
                                                        </td>
                                                        <td>
                                                            {/* <img src={val.review_giver_profile} alt={val.title}/> */}
                                                            {
                                                                val.review_receiver_name
                                                            }
                                                        </td>
                                                        <td className="rating">
                                                            {this.renderStars(val.points)}
                                                        </td>
                                                        <td>
                                                            <div
                                                                style={{
                                                                    width:
                                                                        "200px",
                                                                }}
                                                            >
                                                                <p
                                                                    style={
                                                                        descriptionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        val.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {val.creationdate}
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="actions">
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-sm bg-success-light"
                                                                    onMouseUp={() =>
                                                                        this.onReviewView(
                                                                            val
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fe fe-pencil"></i>
                                                                    {" View"}
                                                                </Link>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-sm bg-danger-light"
                                                                    onMouseUp={() =>
                                                                        this.onReviewDelete(
                                                                            val.unique_key
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fe fe-trash"></i>
                                                                    {" Delete"}
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>
                                                        No records available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid no-padding">
                                    <div className="row pull-right">
                                        {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                                        {/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
                                        <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                                            &nbsp;
                                            {totalItems && (
                                                <Pagination
                                                    pageSize={20}
                                                    items={totalItems}
                                                    onChangePage={
                                                        this.onChangePage
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modals
                    open={this.state.showModal}
                    setOpen={this.handleCloseModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{review.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="review-modal-setion">
                            <div className="comment">
                                <div className="comment-body">
                                    <div className="meta-data">
                                        <span className="comment-author">
                                            {review.review_giver_name}
                                        </span>
                                        <span className="comment-date">
                                            {review.creationdate}
                                        </span>
                                        <div className="review-count rating">
                                            {this.renderStars(review.points)}
                                        </div>
                                    </div>
                                    <p className="comment-content">
                                        {review.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer></Modal.Footer>
                </Modals>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        admin_reviews: state.admin.admin_reviews,
        filter: state.filter,
    };
};

export default connect(mapStateToProps, { getAdminReviewList, DeleteReview })(
    AdminReviews
);
