import React from 'react'
import { connect } from 'react-redux'
import { Link} from 'react-router-dom'
import { getInvoiceListing } from '../../store/actions/fitnessActions'
import Pagination from '../../store/helpers/Pagination'
import Modals from "../General/Modals";
import { Modal } from "react-bootstrap";
import { bucketPath, apiBaseUrl } from '../../store/helpers/common';

class FitnessInvoices extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pageOfItems: [],
			currentPage: 1,
			open: false,
			invoice_info: {}
		}
	}

	componentDidMount() {
		this.props.dispatch(getInvoiceListing());
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	setOpen = (value) => {
		this.setState({ open: false, invoice_info: {} });
	};

	render() {
		const { invoice_listing } = this.props; 
		const { pageOfItems, invoice_info, open } = this.state
		return (
			<>
				<div>
					<div className="row">
						<div className="col-md-12">
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-hover table-center mb-0">
											<thead>
												<tr>
													<th>Invoice No</th>
													<th>Patient</th>
													<th>Amount</th>
													<th>Paid On</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{pageOfItems.map((value, index) => {
													let PaymentData = value.PaymentData; 
													return (
														<tr key={index}>
															<td>
																<Link to={apiBaseUrl + "/api/download-invoice?invoice_number=" + PaymentData.invoice_number} target="_blank" rel="noopener noreferrer">{PaymentData.invoice_number}</Link>
															</td>
															<td>
																<h2 className="table-avatar">
																	<Link to="patient-profile.html" className="avatar avatar-sm mr-2">
																		<img 
																			className="avatar-img rounded-circle"   
																			src={`${value.patientData.Image ? bucketPath + value.patientData.Image : "./../assets/doctor/img/patients/patient.jpg"}`}
																			alt="user" 
																		/>
																	</Link>
																	<Link to="patient-profile.html">{value.patientData.name} <span>{value.patientData.Uniquekey}</span></Link>
																</h2>
															</td>
															<td>${PaymentData.paid_amount}</td>
															<td>{PaymentData.Created.split(" ")[0]}</td>
															<td className="text-right">
																<div className="table-action">
																	<Link className="btn btn-sm bg-info-light" onClick={e => { this.setState({ open: true, invoice_info: value }) }}>
																		<i className="far fa-eye"></i> View
																	</Link>
																	<Link to={apiBaseUrl + "/api/download-invoice?invoice_number=" + PaymentData.invoice_number} target="_blank" rel="noopener noreferrer" className="btn btn-sm bg-primary-light">
																		<i className="fa fa-download"></i> Download
																	</Link>
																</div>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					{invoice_listing && (
						<Pagination
							pageSize={20}
							items={invoice_listing}
							onChangePage={this.onChangePage}
						/>
					)
					}
				</div>
				<Modals open={open} setOpen={this.setOpen}>
					<Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">
							Invoice Details
            			</Modal.Title>
					</Modal.Header>
					<Modal.Body className="invoice-form-modal">
						<div className="row">
							<div className="col-md-3">
								<h4>
									<div className="bold">Invoice From</div>
								</h4> 
								<div className="fromAddress">
									{invoice_info.DocotrData?.doctorName} <br />
									{invoice_info.DocotrData?.DoctorAddress}
								</div>
							</div>
							<div className="col-md-3 offset-md-6 pull-right text-right">
								<h4><div className="bold">Invoice To</div></h4>
								<div className="toAddress">
									{invoice_info.patientData?.name} <br />
									{invoice_info.patientData?.PatientAddress}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 mb-3 mt-3">
								<h4><div className="bold">Payment Method</div></h4>
								<div className="paymentType">
								{invoice_info.PaymentData?.PaymentMethod}
								</div>
							</div>  
						</div>
						<div className="row">
							<div className="col-md-12">
								<table className="table table-bordered">
									<tr>
										<th>Description</th>
										<th>Quantity</th>
										<th>VAT</th>
										<th>Total</th>
									</tr>
									<tr>
										<td>General Consultation</td>
										<td>1</td>
										<td>$0</td>
										<td>${invoice_info.DocotrData?.GeneralAmount}</td>
									</tr>
									<tr>
										<td>
											{
												invoice_info.AppointmentsData?.appointment_type + " Consultation"
											}
										</td>
										<td>1</td>
										<td>$0</td>
										<td>${invoice_info.DocotrData?.AppointmentAmount}</td>
									</tr>
									{/* <tr>
										<td></td>
										<td></td>
										<td>Subtotal:</td>
										<td>$350</td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td>Discount</td>
										<td>-10%</td>
									</tr> */}
									<tr>
										<td></td>
										<td></td>
										<td>Total Amount</td>
										<td>${invoice_info.PaymentData?.paid_amount}</td>
									</tr>
								</table>
							</div>
						</div>
					</Modal.Body>
				</Modals>

			</>
		)
	}
}
const mapStateToProps = state => ({
	invoice_listing: state.fitness.invoice_listing
});

export default connect(mapStateToProps)(FitnessInvoices); 
