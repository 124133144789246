import { apiBaseUrl} from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices'; 
import {ShowLoader,ShowAlert} from './staticListingsActions'  

 
 
export function LabListingDetails() {
    return dispatch => {
        dispatch(ShowLoader(true));  
        const request = new Request(`${apiBaseUrl}/api/labs/global/listing`, {
            method: 'POST', 
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({type:actionTypes.FETCH_LAB_LISTING,payload:responseData.data})
                return 1
            } else {
                return 0
            }
        })
    }
}


export function LabScheduleTimings(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/labs-timing-schedule`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                window.$('#add_time_slot').modal('hide'); 
                dispatch(LabScheduleTimingsView()) ;
                return 1;
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:5000})); 
                return 0;
            }
        });
    }
}


export function LabScheduleTimingsView() {
    return dispatch => { 
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/labs-timing-view`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(ShowLoader(false));
                dispatch({ type: actionTypes.FETCH_SCHEDULED_TIMING, payload: data.data})
                return 1;
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:5000})); 
                return 0;
            }
        });
    }
}


export function UpdateLabScheduleTimings(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/update-labs-timing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(LabScheduleTimingsView())
                return 1;
            }
        });
    }
}

export function LabProfileDetails(id) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/lab-profile-details/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type:actionTypes.LAB_PROFILE_INFO,payload:data.data})
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:"Error Occured",Timeout:5000}));
            }
        })
    }
}

export function BookLabAppointment(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/lab/book-appointment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => { 
            if (data.ResponseCode === "1") {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                return {response:'success',code:data.data};
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return {response:'error'};
            }
        })
    }
}


export function WeeklyLabAppointments(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/lab/weekly-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.WEEKLY_LAB_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                // dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}


export function PatientLabAppointmentList(){
    return dispatch => {
        dispatch(ShowLoader(true)); 
        const request = new Request(`${apiBaseUrl}/api/lab/patient/appointment-list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                dispatch(ShowLoader(false));
                dispatch({type: actionTypes.PATIENT_LAB_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}


export function RescheduleLabAppointment(data){
    return dispatch => {
        dispatch(ShowLoader(true)); 
        const request = new Request(`${apiBaseUrl}/api/reschedule-LabAppointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                return {response:'success',code:data.data};
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return {response:'error'};
            }
        })
    }
}


export function GetLabAppointmentList(data) {
    return dispatch => {
        dispatch(ShowLoader(true)); 
        const request = new Request(`${apiBaseUrl}/api/labs/appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch(ShowLoader(false)); 
                dispatch({type: actionTypes.FETCH_LAB_APPOINTMENT_LISTING,payload: data.data});
            }else {
                dispatch(ShowLoader(false)); 
                return {response:'error'};
            }
        });
    }
}
