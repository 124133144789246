
import React from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

class CounsellorMessages extends React.Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }


    render () {
        return (
            <div>
					<div className="main-wrapper">
					<div className="content message-window-content">
					<div className="row">
						<div className="col-xl-12">
							<div className="chat-window">
								<div className="chat-cont-left">
									<div className="chat-header">
										<span>Chats</span>
										<Link to="#" className="chat-compose">
											<i className="material-icons">control_point</i>
										</Link>
									</div>
									<form className="chat-search">
										<div className="input-group">
											<div className="input-group-prepend">
												<i className="fas fa-search"></i>
											</div>
											<input type="text" className="form-control" placeholder="Search" />
										</div>
									</form>
									<div className="chat-users-list">
										<div className="chat-scroll">
											<Link to="#" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
														<img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Richard Wilson</div>
														<div className="user-last-chat">Hey, How are you?</div>
													</div>
													<div>
														<div className="last-chat-time block">2 min</div>
														<div className="badge badge-success badge-pill">15</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat active">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
														<img src="./../assets/img/patients/patient1.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Charlene Reed</div>
														<div className="user-last-chat">I'll call you later </div>
													</div>
													<div>
														<div className="last-chat-time block">8:01 PM</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
														<img src="./../assets/img/patients/patient2.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Travis Trimble </div>
														<div className="user-last-chat">Give me a full explanation about our project</div>
													</div>
													<div>
														<div className="last-chat-time block">7:30 PM</div>
														<div className="badge badge-success badge-pill">3</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
														<img src="./../assets/img/patients/patient3.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Carl Kelly</div>
														<div className="user-last-chat">That's very good UI design</div>
													</div>
													<div>
														<div className="last-chat-time block">6:59 PM</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-offline">
														<img src="./../assets/img/patients/patient4.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Michelle Fairfax</div>
														<div className="user-last-chat">Yesterday i completed the task</div>
													</div>
													<div>
														<div className="last-chat-time block">11:21 AM</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
														<img src="./../assets/img/patients/patient5.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Gina Moore</div>
														<div className="user-last-chat">What is the major functionality?</div>
													</div>
													<div>
														<div className="last-chat-time block">10:05 AM</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
														<img src="./../assets/img/patients/patient6.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Elsie Gilley</div>
														<div className="user-last-chat">This has allowed me to showcase not only my technical skills</div>
													</div>
													<div>
														<div className="last-chat-time block">Yesterday</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-offline">
														<img src="./../assets/img/patients/patient7.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Joan Gardner</div>
														<div className="user-last-chat">Let's talk briefly in the evening. </div>
													</div>
													<div>
														<div className="last-chat-time block">Sunday</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
														<img src="./../assets/img/patients/patient8.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Daniel Griffing</div>
														<div className="user-last-chat">Do you have any collections? If so, what of?</div>
													</div>
													<div>
														<div className="last-chat-time block">Saturday</div>
													</div>
												</div>
											</Link>
											<Link to="#" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
														<img src="./../assets/img/patients/patient9.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Walter Roberson</div>
														<div className="user-last-chat">Connect the two modules with in 1 day.</div>
													</div>
													<div>
														<div className="last-chat-time block">Friday</div>
													</div>
												</div>
											</Link>
										</div>
									</div>
								</div>
								<div className="chat-cont-right">
									<div className="chat-header">
										<Link id="back_user_list" to="#" className="back-user-list">
											<i className="material-icons">chevron_left</i>
										</Link>
										<div className="media">
											<div className="media-img-wrap">
												<div className="avatar avatar-online">
													<img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
												</div>
											</div>
											<div className="media-body">
												<div className="user-name">Richard Wilson</div>
												<div className="user-status">online</div>
											</div>
										</div>
										<div className="chat-options">
											<Link to="#" data-toggle="modal" data-target="#voice_call">
												<i className="material-icons">local_phone</i>
											</Link>
											<Link to="#" data-toggle="modal" data-target="#video_call">
												<i className="material-icons">videocam</i>
											</Link>
											<Link to="#">
												<i className="material-icons">more_vert</i>
											</Link>
										</div>
									</div>
									<div className="chat-body">
										<div className="chat-scroll">
											<ul className="list-unstyled">
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Hello. What can I do for you?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:30 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>I'm just looking around.</p>
																<p>Will you tell me something about yourself?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:35 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>Are you there? That time!</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:40 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src="./../assets/img/img-02.jpg" alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<Link to="" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</Link>
																	</div>
																	<div className="chat-attachment">
																		<img src="./../assets/img/img-03.jpg" alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<Link to="" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</Link>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:41 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Where?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>OK, my name is Limingqiang. I like singing, playing basketballand so on.</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src="./../assets/img/img-04.jpg" alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<Link to="" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</Link>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:50 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>You wait for notice.</p>
																<p>Consectetuorem ipsum dolor sit?</p>
																<p>Ok?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:55 PM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="chat-date">Today</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:17 AM</span>
																		</div>
																	</li>
																	<li><Link to="#">Edit</Link></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Lorem ipsum dollar sit</p>
																<div className="chat-msg-actions dropdown">
																	<Link to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																		<i className="fe fe-elipsis-v"></i>
																	</Link>
																	<div className="dropdown-menu dropdown-menu-right">
																		<Link className="dropdown-item" to="#">Delete</Link>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:19 AM</span>
																		</div>
																	</li>
																	<li><Link to="#">Edit</Link></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
														<img src="./../assets/img/patients/patient.jpg" alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
										 						<div className="msg-typing">
																	<span></span>
																	<span></span>
																	<span></span>
																</div>
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
									<div className="chat-footer">
										<div className="input-group">
											<div className="input-group-prepend">
												<div className="btn-file btn">
													<i className="fa fa-paperclip"></i>
													<input type="file" />
												</div>
											</div>
											<input type="text" className="input-msg-send form-control" placeholder="Type something" />
											<div className="input-group-append">
												<button type="button" className="btn msg-send-btn"><i className="fab fa-telegram-plane"></i></button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>

				</div>
				</div>
				</div>

                )
            }
}
const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(CounsellorMessages);
