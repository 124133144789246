import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithLayout from './../layout/RouteWithLayout';
import EmptyLayout from './../layout/EmptyLayout';

import HomePageLayout from './../layout/HomePageLayout';
// import MainLayout from './../layout/MainLayout';
// *********************************ADMIN ROUTES ***************************************
import AdminLayout from '../layout/adminPanel/AdminLayout';
import AdminLogin from './../components/adminPanel/AdminLogin'
import AdminDashboard from './../components/adminPanel/Dashboard'
import AdminAppointmentList from './../components/adminPanel/AppointmentListing'
import AdminSpecialisation from './../components/adminPanel/AdminSpecialisation'
import AdminPatientList from './../components/adminPanel/PatientListing'
import AdminDoctorList from './../components/adminPanel/Doctor'
import AdminFitnessExpertsList from './../components/adminPanel/FitnessExperts'
import AdminCounsellor from './../components/adminPanel/AdminCounsellor';
import AdminDoctorProfile from './../components/adminPanel/DoctorProfile'
import AdminFitnessExpertprofile from './../components/adminPanel/FitnessExpertprofile'
import AdminCounsellorProfile from '../components/adminPanel/AdminCounsellorProfile';
import AdminHospitals from './../components/adminPanel/AdminHospitals'
import AdminLabs from './../components/adminPanel/Labs'
import AdminPharmacy from './../components/adminPanel/Pharmacy'
import AdminQuery from './../components/adminPanel/query'
import AdminMessages from '../components/adminPanel/AdminMessages';
import AdminSymptoms from '../components/adminPanel/AdminSymptoms';
import AdminArticles from '../components/adminPanel/AdminArticles';
import AdminReviews from '../components/adminPanel/AdminReviews';
import AdminInvoiceReport from '../components/adminPanel/AdminInvoiceReport';
import AdminSubscriptionCharge from '../components/adminPanel/AdminSubscriptionCharge';
import AdminCounsellorTypes from '../components/adminPanel/AdminCounsellorTypes';
import AdminProduct from '../components/adminPanel/Product/AdminProduct';
import AdminFitness from '../components/adminPanel/AdminFitness';
//  *********************************DOCTOR ROUTES *****************************
// import MainLayout from '../layout/doctorPanel/MainLayout'
import DoctorDashboard from './../components/doctorPanel/DoctorDashboard'
import DoctorAppointments from './../components/doctorPanel/DoctorAppointment'
import DoctorPatients from './../components/doctorPanel/DoctorPatients'
import DoctorInvoices from './../components/doctorPanel/Invoices'
import DoctorPayment from './../components/doctorPanel/Payment'
import DoctorScheduleTimings from './../components/doctorPanel/ScheduleTiming'
import DoctorReviews from './../components/doctorPanel/DoctorReviews'
import DoctorMessages from './../components/doctorPanel/DoctorMessages'
import DoctorProfileSetting from './../components/doctorPanel/DoctorProfileSetting'
import SocialMedia from './../components/doctorPanel/SocialMedia'
import PatientProfile from './../components/doctorPanel/PatientProfile'
import ArticleVideo from './../components/doctorPanel/ArticleVideo'
import Setting from './../components/doctorPanel/Setting'
// import DoctorArticle from './../components/doctorPanel/DoctorArticle'
// import DoctorVideo from './../components/doctorPanel/DoctorVideo';
import AddArticle from './../components/doctorPanel/AddArticle'
import AddVideo from './../components/doctorPanel/AddVideo'
import ViewArticle from './../components/doctorPanel/ViewArticle'
import PrescriptionPreview from './../components/doctorPanel/PrescriptionPreview'



// ***********************************PATIENT ROUTES *************************************
// import MainLayout from '../layout/patientPanel/MainLayout'
import PatientDashboard  from './../components/patientPanel/PatientDashboard'
import PatientFavourites from './../components/patientPanel/PatientFavourites'
// import PatientChangePassword from './../components/patientPanel/PatientChangePassword'
import Appointment from './../components/patientPanel/Appointment'
import PatientLabAppointment from './../components/patientPanel/PatientLabAppointment'
import PatientPrescription from './../components/patientPanel/PatientPrescription'
import PatientMedicalRecords from './../components/patientPanel/PatientMedicalRecords'
import PatientBilling from './../components/patientPanel/PatientBilling'
import PatientProfileSetting from './../components/patientPanel/PatientProfileSetting'
import CheckOut from './../components/patientPanel/CheckOut'
import FitnessClassCheckout from './../components/patientPanel/FitnessClassCheckout'
import CounsellorCheckOut from './../components/patientPanel/CounsellorCheckOut'
import PatientInsurance from './../components/patientPanel/PatientInsurance'


// *************************************LAB ROUTES ******************************************
import LabAppointments from './../components/labPanel/LabAppointments'
import LabScheduleTiming from './../components/labPanel/LabScheduleTiming'

// *************************************CLINIC ROUTES ******************************************
// import HospitalLayout from '../layout/hospitalPanel/hospitalLayout'

// *************************************PHARMACY ROUTES ******************************************
// import PharmacyLayout from '../layout/pharmacyPanel/pharmacyLayout'
// import PharmacyProfileSetting from '../components/pharmacyPanel/PharmacyProfileSetting';

import DoctorListing from './../components/doctorlisting';
import DoctorGrid from './../components/doctorgrid';
import DoctorDetails from './../components/doctordetails';
import Products from './../components/Products';
import ProductCheckOut from './../components/ProductCheckOut';
import HealthFeed from './../components/HealthFeed';
import HealthArticle from './../components/HealthArticle';
import LabListing from './../components/LabListing';
import HomePage from '../components/homepage';
import Home from './../components/gMaps/Home';
import FitnessClassList from './../components/FitnessClassList';
import Nutritions from './../components/Nutritions';
import MemberShip from './../components/MemberShip';
import Insurance from './../components/Insurance';



import ProfileSetting from './../components/ProfileSetting'
import ChatMessages from './../components/chatMessages'
import ChatAppointment from './../components/ChatAppointment'
import Messages from './../components/Messages'
import Conference from '../components/conferencePanel/Conference';

// ****************************************COMMON USE ***********************************************//
import ChangePassword from '../components/Common/ChangePassword'

// ****************************************OFFLINE/ONLINE ***********************************************//
import MainLayout from './../layout/MainLayout'
import Support from '../components/xmpp/Support';
import Chat from '../components/Chat';
import LiveStream from '../components/doctorPanel/LiveStream';

// ****************************************Counsellor ***********************************************//
import CounsellorListing from './../components/CounsellorListing';
import CounsellorProfileSetting from '../components/counsellorPanel/CounsellorProfileSetting';
import CounsellorDetails from './../components/CounsellorDetails';
import CounsellorChatAppointment from '../components/CounsellorChatAppointment';
import CounsellorChatMessages from '../components/CounsellorChatMessages';
import CounsellorReviews from './../components/counsellorPanel/CounsellorReviews'
import CounsellorDashboard from './../components/counsellorPanel/CounsellorDashboard'
import CounsellorAppointments from './../components/counsellorPanel/CounsellorAppointment'
import CounsellorPatients from './../components/counsellorPanel/CounsellorPatients'
import CounsellorInvoices from './../components/counsellorPanel/CounsellorInvoices'
import CounsellorPayment from './../components/counsellorPanel/CounsellorPayment'
import CounsellorScheduleTimings from './../components/counsellorPanel/CounsellorScheduleTiming'
import CounsellorMessages from './../components/counsellorPanel/CounsellorMessages'
import CounsellorSocialMedia from '../components/counsellorPanel/CounsellorSocialMedia'
import CounsellorPatientProfile from './../components/counsellorPanel/CounsellorPatientProfile'
import CounsellorArticleVideo from './../components/counsellorPanel/CounsellorArticleVideo'
import CounsellorSetting from './../components/counsellorPanel/CounsellorSetting'
import CounsellorAddArticle from './../components/counsellorPanel/CounsellorAddArticle'
import CounsellorAddVideo from './../components/counsellorPanel/CounsellorAddVideo'
import CounsellorViewArticle from './../components/counsellorPanel/CounsellorViewArticle'
import CounsellorPrescriptionPreview from './../components/counsellorPanel/CounsellorPrescriptionPreview'
import CounsellorChat from '../components/CounsellorChat';
import CounsellorLiveStream from '../components/counsellorPanel/CounsellorLiveStream';

// ****************************************Fitness Experts ***********************************************//
import FitnessDashboard from './../components/fitnessExpertPanel/FitnessDashboard'
import FitnessExpertListing from './../components/fitnessExpertListing';
import FitnessExpertDetails from './../components/fitnessExpertDetails';
import FitnessExpertProfileSetting from './../components/fitnessExpertPanel/FitnessExpertProfileSetting'
import FitnessScheduleTimings from './../components/fitnessExpertPanel/FitnessScheduleTiming'
import FitnessAppointments from './../components/fitnessExpertPanel/FitnessAppointment'
import FitnessPatients from './../components/fitnessExpertPanel/FitnessPatients'
import FitnessCheckOut from './../components/patientPanel/FitnessCheckOut'
import FitnessArticleVideo from './../components/fitnessExpertPanel/FitnessArticleVideo'
import FitnessAddArticle from './../components/fitnessExpertPanel/FitnessAddArticle'
import FitnessAddVideo from './../components/fitnessExpertPanel/FitnessAddVideo'
import FitnessViewArticle from './../components/fitnessExpertPanel/FitnessViewArticle'
// import CounsellorChat from '../components/CounsellorChat';
import FitnessLiveStream from '../components/fitnessExpertPanel/FitnessLiveStream';
import FitnessInvoices from './../components/fitnessExpertPanel/FitnessInvoices'
import FitnessSetting from './../components/fitnessExpertPanel/FitnessSetting'
import FitnessReviews from './../components/fitnessExpertPanel/FitnessReviews'
import FitnessPatientProfile from './../components/fitnessExpertPanel/FitnessPatientProfile'
import FitnessPayment from './../components/fitnessExpertPanel/Payment'
import FitnessClasses from './../components/fitnessExpertPanel/FitnessClasses'
import AddFitnessClasses from './../components/fitnessExpertPanel/AddFitnessClasses'
// ****************************************Fitness Experts ***********************************************//


function AppRouter() {
    return ( < Switch >
        {/* <RouteWithLayout layout = { MainLayout } path="/home" exact component={HomePage} /> */}
        <RouteWithLayout layout = { HomePageLayout } path="/" exact component={HomePage} />
        <RouteWithLayout layout = { MainLayout } path="/doctor-grid"  component={DoctorGrid} />
        <RouteWithLayout layout = { MainLayout } path="/doctor-details" component={DoctorDetails}/>
        <RouteWithLayout layout = { EmptyLayout } path="/google-maps" component={Home}/>
        {/* ******************************************ADMIN PANEL************************************************** */}
        <RouteWithLayout layout = { EmptyLayout } path="/admin-login"  component={AdminLogin} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/dashboard"  component={AdminDashboard} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/appointment-list"  component={AdminAppointmentList} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/specialities"  component={AdminSpecialisation} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/counsellor-list"  component={AdminCounsellor} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/doctor-list"  component={AdminDoctorList} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/fitness-experts-list"  component={AdminFitnessExpertsList} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/patient-list"  component={AdminPatientList} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/doctorprofile/:token"  component={AdminDoctorProfile} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/fitnessExpertprofile/:token"  component={AdminFitnessExpertprofile} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/counsellor-profile/:token"  component={AdminCounsellorProfile} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/hospital"  component={AdminHospitals} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/labs"  component={AdminLabs} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/pharmacy"  component={AdminPharmacy} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/query"  component={AdminQuery} />
        <RouteWithLayout layout = { EmptyLayout } path="/admin/messages"  component={AdminMessages} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/symptoms"  component={AdminSymptoms} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/counsellor-types"  component={AdminCounsellorTypes} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/article"  component={AdminArticles} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/reviews"  component={AdminReviews} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/subscription-charge"  component={AdminSubscriptionCharge} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/invoice-report"  component={AdminInvoiceReport} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/products"  component={AdminProduct} />
        <RouteWithLayout layout = { AdminLayout } path="/admin/fitness"  component={AdminFitness} />

        {/* ******************************************ADMIN PANEL************************************************** */}

        {/* ******************************************DOCTOR PANEL************************************************* */}
        <RouteWithLayout layout = { MainLayout } path="/doctor/dashboard"  component={DoctorDashboard} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/appointments"  component={DoctorAppointments} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/patients"  component={DoctorPatients} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/invoices"  component={DoctorInvoices} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/schedule-timings"  component={DoctorScheduleTimings} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/reviews"  component={DoctorReviews} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/profile-setting"  component={DoctorProfileSetting} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/social-media"  component={SocialMedia} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/change-password"  component={ChangePassword} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/patient-profile/:id"  component={PatientProfile} />
        {/* <RouteWithLayout layout = { MainLayout } path="/doctor/articles"  component={DoctorArticle} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/videos"  component={DoctorVideo} /> */}
        <RouteWithLayout layout = { MainLayout } path="/doctor/article-videos"  component={ArticleVideo} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/setting"  component={Setting} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/add-article"  component={AddArticle} />
        <RouteWithLayout layout = { EmptyLayout } path="/doctor/add-video"  component={AddVideo} />
        <RouteWithLayout layout = { EmptyLayout } path="/doctor/live-stream"  component={LiveStream} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/edit-article/:token"  component={AddArticle} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/view-article/:token"  component={ViewArticle} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/payment" component={DoctorPayment} />
        <RouteWithLayout layout = { MainLayout } path="/doctor/prescription-preview" component={PrescriptionPreview} />

        <RouteWithLayout layout = { EmptyLayout }  path="/doctor/messages" component={DoctorMessages} />
        <RouteWithLayout layout = { EmptyLayout }  path="/chat-messages" component={ChatMessages} />
        <RouteWithLayout layout = { EmptyLayout }  path="/chat/:id" component={Chat} />
        <RouteWithLayout layout = { EmptyLayout }  path="/chat-appointment/:id/:uid" component={ChatAppointment} />
        <RouteWithLayout layout = { EmptyLayout }  path="/messages" component={Messages} />
        <RouteWithLayout layout = { EmptyLayout }  path="/support" component={Support} />




        {/* ******************************************DOCTOR PANEL ************************************************ */}

        {/* ******************************************COUNSELlOR PANEL************************************************* */}
        <RouteWithLayout layout = { MainLayout } path="/counsellor/dashboard"  component={CounsellorDashboard} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/appointments"  component={CounsellorAppointments} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/patients"  component={CounsellorPatients} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/invoices"  component={CounsellorInvoices} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/schedule-timings"  component={CounsellorScheduleTimings} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/reviews"  component={CounsellorReviews} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/profile-setting"  component={CounsellorProfileSetting} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/social-media"  component={CounsellorSocialMedia} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/change-password"  component={ChangePassword} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/patient-profile/:id"  component={CounsellorPatientProfile} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/article-videos"  component={CounsellorArticleVideo} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/setting"  component={CounsellorSetting} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/add-article"  component={CounsellorAddArticle} />
        <RouteWithLayout layout = { EmptyLayout } path="/counsellor/add-video"  component={CounsellorAddVideo} />
        <RouteWithLayout layout = { EmptyLayout } path="/counsellor/live-stream"  component={CounsellorLiveStream} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/edit-article/:token"  component={CounsellorAddArticle} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/view-article/:token"  component={CounsellorViewArticle} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/payment" component={CounsellorPayment} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor/prescription-preview" component={CounsellorPrescriptionPreview} />
        <RouteWithLayout layout = { EmptyLayout }  path="/counsellor/messages" component={CounsellorMessages} />
        <RouteWithLayout layout = { EmptyLayout }  path="/chat-messages" component={CounsellorChatMessages} />
        <RouteWithLayout layout = { EmptyLayout }  path="/chat/:id" component={CounsellorChat} />
        <RouteWithLayout layout = { EmptyLayout }  path="/chat-appointment/:id/:uid" component={CounsellorChatAppointment} />
        <RouteWithLayout layout = { EmptyLayout }  path="/messages" component={CounsellorMessages} />
        <RouteWithLayout layout = { MainLayout } exact  path="/counsellor-view/:id" component={CounsellorDetails} />
        {/* ******************************************COUNSELlOR PANEL ************************************************ */}

        {/* ******************************************PATIENT PANEL ************************************************ */}
        <RouteWithLayout layout = { MainLayout } path="/patient/dashboard"  component={PatientDashboard} />
        <RouteWithLayout layout = { MainLayout } path="/patient/favourites"  component={PatientFavourites} />
        <RouteWithLayout layout = { MainLayout } path="/patient/profile-settings"  component={PatientProfileSetting} />
        <RouteWithLayout layout = { MainLayout } path="/patient/change-password"  component={ChangePassword} />
        <RouteWithLayout layout = { MainLayout } path="/patient/appointment"  component={Appointment} />
        <RouteWithLayout layout = { MainLayout } path="/patient/lab-appointment"  component={PatientLabAppointment} />
        <RouteWithLayout layout = { MainLayout } path="/patient/prescription"  component={PatientPrescription} />
        <RouteWithLayout layout = { MainLayout } path="/patient/medical_records"  component={PatientMedicalRecords} />
        <RouteWithLayout layout = { MainLayout } path="/patient/invoices"  component={PatientBilling} />
        <RouteWithLayout layout = { MainLayout } path="/patient/checkout/:id"  component={CheckOut} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor-patient/checkout/:id"  component={CounsellorCheckOut} />
        <RouteWithLayout layout = { MainLayout } path="/patient/insurance"  component={PatientInsurance} />


        {/* ******************************************PATIENT PANEL ************************************************ */}

        {/* ******************************************Fitness Expert PANEL ************************************************ */}
        <RouteWithLayout layout = { MainLayout } path="/fitness/dashboard"  component={FitnessDashboard} />
        <RouteWithLayout layout = { MainLayout } exact  path="/view-expert/:id" component={FitnessExpertDetails} />
        <RouteWithLayout layout = { MainLayout } path="/fitness-experts"  component={FitnessExpertListing} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/profile-setting"  component={FitnessExpertProfileSetting} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/schedule-timings"  component={FitnessScheduleTimings} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/appointments"  component={FitnessAppointments} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/patients"  component={FitnessPatients} />
        <RouteWithLayout layout = { MainLayout } path="/fitness-patient/checkout/:id"  component={FitnessCheckOut} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/article-videos"  component={FitnessArticleVideo} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/add-article"  component={FitnessAddArticle} />
        <RouteWithLayout layout = { EmptyLayout } path="/fitness/add-video"  component={FitnessAddVideo} />
        <RouteWithLayout layout = { EmptyLayout } path="/fitness/live-stream"  component={FitnessLiveStream} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/edit-article/:token"  component={FitnessAddArticle} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/view-article/:token"  component={FitnessViewArticle} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/invoices"  component={FitnessInvoices} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/setting"  component={FitnessSetting} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/reviews"  component={FitnessReviews} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/patient-profile/:id"  component={FitnessPatientProfile} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/payment" component={FitnessPayment} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/fitness-classes" component={FitnessClasses} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/add-fitness-classes" component={AddFitnessClasses} />
        <RouteWithLayout layout = { MainLayout } path="/fitness/edit-fitness-classes/:uniqueID" component={AddFitnessClasses} />
        {/* ******************************************Fitness Expert PANEL ************************************************ */}

         {/* ******************************************LAB PANEL ************************************************ */}
        <RouteWithLayout layout = { MainLayout } path="/lab/profile-setting"  component={ProfileSetting} />
        <RouteWithLayout layout = { MainLayout } path="/lab/appointments"  component={LabAppointments} />
        <RouteWithLayout layout = { MainLayout } path="/lab/schedule-timing"  component={LabScheduleTiming} />


        {/* ******************************************LAB PANEL ************************************************ */}

        {/* ******************************************HOSPITAL PANEL ************************************************ */}
        <RouteWithLayout layout = { MainLayout } path="/hospital/profile-setting"  component={ProfileSetting} />


        {/* ******************************************HOSPITAL PANEL ************************************************ */}
        {/* ******************************************HOSPITAL PANEL ************************************************ */}
        <RouteWithLayout layout = { MainLayout } path="/clinic/profile-setting"  component={ProfileSetting} />


        {/* ******************************************HOSPITAL PANEL ************************************************ */}

         {/* ******************************************PHARMACY PANEL ************************************************ */}
         <RouteWithLayout layout = { MainLayout } path="/pharmacy/profile-setting"  component={ProfileSetting} />


        {/* ******************************************PHARMACY PANEL ************************************************ */}

        {/* ******************************************CONFERENCE PANEL ************************************************ */}
        <RouteWithLayout layout = { EmptyLayout }  path="/conference" component={Conference} />
        {/* ******************************************CONFERENCE PANEL ************************************************ */}

        {/* // ****************************************OFFLINE/ONLINE ***********************************************/}
        <RouteWithLayout layout = { MainLayout } exact  path="/doctor-view/:id" component={DoctorDetails} />
        <RouteWithLayout layout = { MainLayout } path="/doctor-listing"  component={DoctorListing} />
        <RouteWithLayout layout = { MainLayout } path="/products"  component={Products} />
        <RouteWithLayout layout = { MainLayout } path="/productsCheckout"  component={ProductCheckOut} />
        <RouteWithLayout layout = { MainLayout } path="/health-feed/:id"  component={HealthFeed} />
        <RouteWithLayout layout = { MainLayout } path="/health-article"  component={HealthArticle} />
        <RouteWithLayout layout = { MainLayout } path="/lab-listing"  component={LabListing} />
        <RouteWithLayout layout = { MainLayout } path="/counsellor-listing"  component={CounsellorListing} />
        <RouteWithLayout layout = { MainLayout } path="/fitness-class-listing"  component={FitnessClassList} />
        <RouteWithLayout layout = { MainLayout } path="/nutritions"  component={Nutritions} />
        <RouteWithLayout layout = { MainLayout } path="/membership"  component={MemberShip} />
        <RouteWithLayout layout = { MainLayout } path="/insurance"  component={Insurance} />

        <RouteWithLayout layout = { MainLayout } path="/fitnessCheckout/:id"  component={FitnessClassCheckout} />

        </Switch>
    )
}

export default AppRouter;
