// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';  
import { connect } from 'react-redux';
import {FitnessClassListing,DeleteFitnessClass} from "../../store/actions/fitnessActions";
import Pagination from '../../store/helpers/Pagination';
import swal from 'sweetalert';

class FitnessClasses extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1 }

  }
  componentDidMount(){
    this.props.dispatch(FitnessClassListing());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  DeleteFitnessClass = (id) => { 
    swal({
        title: "Are you sure you want to delete this Fitness Class?",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            this.props.dispatch(DeleteFitnessClass({id:id})); 
        } else {
            swal({ title: "Record is safe!", icon: "success", });
        }
    });
  };
  

  render() {
    const {fitness_class_listing} = this.props  
    return (
      <> 
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className='text-right'>
                <Link className='add-new-btn' to='/fitness/add-fitness-classes'>
                  Add Fitness Class
                </Link>
              </div>
              <div className='table-responsive'>
                <table className='datatable table table-hover table-center mb-0'>
                  <thead>
                    <tr> 
                      <th>Title</th>
                      <th>Duration</th>
                      <th>Description</th> 
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.pageOfItems.length > 0 ? (
                    this.state.pageOfItems.map((fitness, index) => ( 
                        <tr key={`fitness_${index}`}> 
                          <td>{fitness.title.toUpperCase()}</td>
                          <td>{fitness.duration}</td>
                          <td className="fitness-discription">
                            <p 
                              dangerouslySetInnerHTML={{ __html: fitness?.description ?? '<p></p>',}}
                            />
                          </td>
                          <td>
                            <div className='table-action'> 
                              <Link
                                className='btn btn-sm bg-info-light'
                                to={`/fitness/edit-fitness-classes/${fitness.uniquekey}`}
                              >
                                <i className='far fa-edit'></i>&nbsp;Edit
                              </Link>
                              <Link
                                className='btn btn-sm bg-danger-light'
                                to='#'
                                onClick={() => this.DeleteFitnessClass(fitness.uniquekey)}
                              >
                                <i className='fas fa-remove'></i>&nbsp;Delete
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                      ) : (
                        <tr>
                          <td colSpan='4'>No records available</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <div className='container-fluid no-padding'>
                <div className='row pull-right'>
                  <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                    &nbsp;
                    {fitness_class_listing && (
                      <Pagination
                        pageSize={10}
                        items={fitness_class_listing}
                        onChangePage={this.onChangePage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
const mapStateFromProps = (state) => ({ 
  fitness_class_listing:state.fitness.fitness_class_listing
});
export default connect(mapStateFromProps)(FitnessClasses);
