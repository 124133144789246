import React, { Component } from 'react';
import DoctorArticle from './DoctorArticle';
import DoctorVideo from './DoctorVideo';
import {Link} from 'react-router-dom';

class ArticleVideo extends Component {
    state = {tab:'article'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="article"?"active":""}`} to="#" onClick={e=>this.setState({tab:'article'})} >Articles</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="video"?"active":""}`} to="#" onClick={e=>this.setState({tab:'video'})}>Videos</Link>
                                </li>
                            </ul>
                        </nav>
                        {tab==="article" && <DoctorArticle/>}
                        {tab==="video" && <DoctorVideo/>}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleVideo;