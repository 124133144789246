import React from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom' 

class AdminAppointmentList extends React.Component{
    constructor(props){
        super(props)
        this.state = {
             
        }
    }

    
    render () { 
        return (
            <div> 
               <div className="page-wrapper">
                <div className="content container-fluid"> 
					<div className="row">
						<div className="col-md-12"> 
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="datatable table table-hover table-center mb-0">
											<thead>
												<tr>
													<th>Doctor Name</th>
													<th>Speciality</th>
													<th>Patient Name</th>
													<th>Apointment Time</th>
													<th>Status</th>
													<th className="text-right">Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-01.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Ruby Perrin</Link>
														</h2>
													</td>
													<td>Dental</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient1.jpg" alt="User" /></Link>
															<Link to="profile.html">Charlene Reed </Link>
														</h2>
													</td>
													<td>9 Nov 2019 <span className="text-primary d-block">11.00 AM - 11.15 AM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_1" className="check" checked />
															<label for="status_1" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$200.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-02.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Darren Elder</Link>
														</h2>
													</td>
													<td>Dental</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient2.jpg" alt="User" /></Link>
															<Link to="profile.html">Travis Trimble </Link>
														</h2>
													</td>
													
													<td>5 Nov 2019 <span className="text-primary d-block">11.00 AM - 11.35 AM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_2" className="check" checked />
															<label for="status_2" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$300.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-03.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Deborah Angel</Link>
														</h2>
													</td>
													<td>Cardiology</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient3.jpg" alt="User" /></Link>
															<Link to="profile.html">Carl Kelly</Link>
														</h2>
													</td>
													<td>11 Nov 2019 <span className="text-primary d-block">12.00 PM - 12.15 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_3" className="check" checked />
															<label for="status_3" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$150.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-04.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Sofia Brient</Link>
														</h2>
													</td>
													<td>Urology</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient4.jpg" alt="User" /></Link>
															<Link to="profile.html"> Michelle Fairfax</Link>
														</h2>
													</td>
													<td>7 Nov 2019 <span className="text-primary d-block">1.00 PM - 1.20 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_4" className="check" checked />
															<label for="status_4" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$150.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-05.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Marvin Campbell</Link>
														</h2>
													</td>
													<td>Orthopaedics</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient5.jpg" alt="User" /></Link>
															<Link to="profile.html">Gina Moore</Link>
														</h2>
													</td>
													
													<td>15 Nov 2019 <span className="text-primary d-block">1.00 PM - 1.15 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_5" className="check" checked />
															<label for="status_5" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$200.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-06.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Katharine Berthold</Link>
														</h2>
													</td>
													<td>Orthopaedics</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient6.jpg" alt="User" /></Link>
															<Link to="profile.html">Elsie Gilley</Link>
														</h2>
													</td>
													
													<td>16 Nov 2019 <span className="text-primary d-block">1.00 PM - 1.15 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_5" className="check" checked />
															<label for="status_5" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$250.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-07.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Linda Tobin</Link>
														</h2>
													</td>
													<td>Neurology</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient7.jpg" alt="User" /></Link>
															<Link to="profile.html">Joan Gardner</Link>
														</h2>
													</td>
													
													<td>18 Nov 2019 <span className="text-primary d-block">1.10 PM - 1.25 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_5" className="check" checked />
															<label for="status_5" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$260.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-08.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Paul Richard</Link>
														</h2>
													</td>
													<td>Dermatology</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient8.jpg" alt="User" /></Link>
															<Link to="profile.html"> Daniel Griffing</Link>
														</h2>
													</td>
													
													<td>18 Nov 2019 <span className="text-primary d-block">11.10 AM - 11.25 AM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_5" className="check" checked />
															<label for="status_5" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$260.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-09.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. John Gibbs</Link>
														</h2>
													</td>
													<td>Dental</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient9.jpg" alt="User" /></Link>
															<Link to="profile.html">Walter Roberson</Link>
														</h2>
													</td>
													
													<td>21 Nov 2019 <span className="text-primary d-block">12.10 PM - 12.25 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_5" className="check" checked />
															<label for="status_5" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$300.00
													</td>
												</tr>
												<tr>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/doctors/doctor-thumb-10.jpg" alt="User" /></Link>
															<Link to="profile.html">Dr. Olga Barlow</Link>
														</h2>
													</td>
													<td>Dental</td>
													<td>
														<h2 className="table-avatar">
															<Link to="profile.html" className="avatar avatar-sm mr-2"><img className="avatar-img rounded-circle" src="./../assets/img/patients/patient10.jpg" alt="User" /></Link>
															<Link to="profile.html">Robert Rhodes</Link>
														</h2>
													</td>
													
													<td>23 Nov 2019 <span className="text-primary d-block">12.10 PM - 12.25 PM</span></td>
													<td>
														<div className="status-toggle">
															<input type="checkbox" id="status_5" className="check" checked />
															<label for="status_5" className="checktoggle">checkbox</label>
														</div>
													</td>
													<td className="text-right">
														$300.00
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div> 
							
						</div>
					</div>
				</div>			
			</div>
             </div> 
                )
            }
}
const mapStateToProps = state => ({ 
    
});

export default connect(mapStateToProps)(AdminAppointmentList); 