import * as React from "react";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import { Articlelisting,ArticleStatusChange } from "./../../store/actions/adminActions";
import ToolTips from "./../General/ToolTips";

class AdminArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], page: 1 };
  }

  componentDidMount() {
    this.props.dispatch(Articlelisting());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  statusChangeHandler(status,uniquekey){
    this.props.dispatch(ArticleStatusChange({uniquekey:uniquekey,status:status}))
  }

  render() {
      const {article_listing} = this.props
      const {pageOfItems,currentPage} = this.state
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="datatable table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Text</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems.length > 0 ? ( pageOfItems.map((val, i) => (
                        <tr key={i}>
                          <td>{(i+1)+((currentPage-1)*20)} </td>
                          <td>{val.title}</td>
                          <td dangerouslySetInnerHTML={{__html: val.description,}}></td>
                          <td>
                          {['A','P','D'].includes(val.status) && <div className="status-toggle">
                                  <input type="checkbox" id="status_1" checked={val.status==="A"?true:false} className="check" readOnly/>
                                  <ToolTips Placement="top" Text={val.status==="A"?"Active":"Deactive"}><label htmlFor="status_1" className="checktoggle" onClick={(e) => this.statusChangeHandler(val.status==="A"?"D":"A", val.uniquekey)
                                }>checkbox</label></ToolTips>
                                  </div> 
                                }
                                </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No records available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="container-fluid no-padding">
                <div className="row pull-right">
                  {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                  {/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
                  <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                    &nbsp;
                    {article_listing && (
                      <Pagination
                        pageSize={20}
                        items={article_listing}
                        onChangePage={this.onChangePage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  article_listing: state.admin.article_listing,
});
export default connect(mapStateToProps)(AdminArticle);
