import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Actions from './xmpp/Actions';
import { supportChatHistory, DoctorProfileDetails } from '../store/actions/globalActions';
import { getUserDetailsForChat } from '../store/actions/adminActions';
import Appoinment from './modals/Appoinment';
import { WeeklyAppointments } from '../store/actions/registerationActions';
import { CancelAppointment } from '../store/actions/userActions';
import swal from "sweetalert";
import axios from 'axios';
import { apiBaseUrl } from '../store/helpers/common';

class Chat extends Actions {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            messages: [],
            users: [],
            showSupportButtons: false
        }
        this.doctorUniqueKey = null;
        this.appointmentCode = null;
    }

    componentDidMount() {
        if(localStorage.data) {
            const uniqueKey = JSON.parse(localStorage.data).uniquekey;
            const userUniqueKey = this.props.match.params.id;
            let appointmentCode = sessionStorage.getItem('_appointment_code');
            let doctorUniqueKey = sessionStorage.getItem('_doctor_uniquekey');
            this.connection(uniqueKey);
            if(userUniqueKey && appointmentCode && doctorUniqueKey) {
                this.setState({users: [userUniqueKey]}, () => {
                    const postData = {uniquekey: userUniqueKey};
                    const postData1 = {uniquekey: [userUniqueKey]};
                    this.props.dispatch(supportChatHistory(null, postData));
                    this.props.dispatch(getUserDetailsForChat(postData1));
                    this.doctorUniqueKey = doctorUniqueKey;
                    this.appointmentCode = appointmentCode;
                    this.checkUserJoinStatus();
                })
            }
        } else {
            this.props.history.push('/');
        }
    }

    componentWillUnmount() {
        this.doctorUniqueKey = "";
        this.appointmentCode = "";
        sessionStorage.removeItem('_caller_name');
        sessionStorage.removeItem('_doctor_uniquekey');
        sessionStorage.removeItem('_appointment_code');
        sessionStorage.removeItem('_call_type');
    }

    checkUserJoinStatus = () => {
        setTimeout(() => {
            if(this.state.status[this.state.users[0]] !== 'online') {
                this.setState({showSupportButtons: true});
            }
        }, 5 * 60 * 1000);
    }

    manageKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit();
        } else {
            this.activeComposing(this.state.users[0]);
        }
    }

    manageKeyUp = () => {
        this.stopComposing(this.state.users[0]);
    }

    handleSubmit = () => {
        if (this.state.message) {
            this.sendMessage(this.state.users[0], this.state.message);
            const sentMessage = [{ type: 'sent', message: this.state.message }];
            this.setState({ messages: [...this.state.messages, ...sentMessage] }, () => {
                this.manageScroll();
            });
            this.setState({ message: '' })
        }
    }

    onRecievrMessage = (message) => {
        const receivedMessage = [{ type: 'received', message: message }];
        this.setState({ messages: [...this.state.messages, ...receivedMessage] }, () => {
            this.manageScroll();
        });
    }

    manageScroll = () => {
        if(!this.state.showSupportButtons) {
            const list = document.getElementById("chat-scroll"),
            targetLis = document.querySelectorAll(".message-content"),
            targetLi = targetLis[targetLis.length - 1];
            list.scrollTop = targetLi.offsetTop;
        }
        // window.$('.list-unstyled').animate({scrollTop: window.$('.list-unstyled').prop("scrollHeight")}, 500);
    }

    async componentDidUpdate(prevState, prevProp) {
        const userUniqueKey = this.state.users.length > 0 ? this.state.users[0] : null;
        if(!Array.isArray(this.props.chat_history)) {
            if(userUniqueKey && Object.keys(this.props.chat_history[userUniqueKey]).length > 0) {
                this.manageScroll();
            }
        }

        if(prevState !== this.state) {
            if(this.state.status[this.state.users[0]] === "offline") {
                try {
                    const postData = {
                        method: "post",
                        url: `${apiBaseUrl}/api/finish-appointments`,
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": `${localStorage.getItem("token")}`,
                        },
                        data: JSON.stringify({appointment_code: this.appointmentCode, duration: 0}),
                    };

                    const response = await axios(postData);
                    if(response.data.ResponseCode === "1") {
                        this.state.room.leave().then(() => {
                            this.state.connection.disconnect();
                            this.props.history.push('/');
                        })
                    }
                } catch (error) {
                    console.log("Appointment complete error ==> ", error);
                }
            }
        }
    }

    GetAppointment = (doctorUniquekey, appointmentCode) => {
        this.props.dispatch(DoctorProfileDetails(doctorUniquekey));
        this.props.dispatch(WeeklyAppointments({ uniquekey: doctorUniquekey }));
        this.setState({appointmentCode: appointmentCode}, () => {
          window.$('#bookAppointmentListing').modal({ show: true,backdrop: "static" });
        });
    }

    handleCancel = (appointmentCode) => {
        swal({
          title: "Are you sure want to cancel the appointment?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            const data = {appointment_code: appointmentCode};
            const res = await this.props.dispatch(CancelAppointment(data));
            if(res === 1){
                this.props.history.push('/');
            }
          }
        });
    }

    updateAppointmentList = () => {
        this.props.history.push('/');
    }

    render() {
        const { messages, users } = this.state;
        const{ chat_history } = this.props;
        const userUniqueKey = users.length > 0 ? users[0] : null;
        return (
            <>
                 { !this.state.showSupportButtons &&
                 <div className="main-wrapper">
                    <div className="content message-window-content">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="chat-window">
                                    <div className=" chat-cont-right chat-cont-right-1 message-full-div">
                                        <div className="chat-header">
                                            <Link to="#" id="back_user_list" className="back-user-list">
                                                <i className="material-icons">chevron_left</i>
                                            </Link>
                                            <div className="media">
                                                <div className="media-img-wrap">
                                                    <div className= {`avatar avatar-${this.state.status[this.state.users[0]] === 'online' ? 'online' : 'offline'}`}>
                                                        <img src="/assets/img/patients/patient.jpg" alt="User"
                                                            className="avatar-img rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    {this.props.userDetailListing.data && this.props.userDetailListing.data.map((user, key) => {
                                                        return (<div key={`user_${key}`} className="user-name">{user.username} </div>)
                                                        })
                                                    }
                                                    <div className="user-status">{this.state.status[this.state.users[0]] === 'online' ? 'online' : 'offline'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chat-body">
                                            <div className="chat-scroll" id="chat-scroll">
                                                <ul className="list-unstyled" id="list-unstyled">
                                                    {chat_history[userUniqueKey] && chat_history[userUniqueKey].map((message, key) => {
                                                        return (
                                                            <li key={`message_${key}`}className={`media message-content ${message.fromAddress !== userUniqueKey.toUpperCase() ? 'sent' : 'received'}`}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div>
                                                                            <p>{message.body}</p>
                                                                            <ul className="chat-msg-info">
                                                                                <li>
                                                                                    <div className="chat-time">
                                                                                        {/* {message.fromAddress === userUniqueKey.toUpperCase() && <span>Admin</span>} */}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                    {messages.map((message, key) => {
                                                        return (
                                                            <li key={`message_${key}`}className={`media message-content ${message.type === 'sent' ? 'sent' : 'received'}`}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div>
                                                                            <p>{message.message}</p>
                                                                            <ul className="chat-msg-info">
                                                                                <li>
                                                                                    <div className="chat-time">
                                                                                        {/* <span>8:30 AM</span> */}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                    {
                                                        this.state.typingNotification
                                                        && <li className="media received">
                                                            <div className="avatar">
                                                                <img src="/assets/img/patients/patient.jpg" alt="User"
                                                                    className="avatar-img rounded-circle" />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <div className="msg-typing">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="chat-footer">
                                            <div className="input-group">
                                                {/* <div className="input-group-prepend">
                                                    <div className="btn-file btn">
                                                        <i className="fa fa-paperclip"></i>
                                                        <input type="file" />
                                                    </div>
                                                </div> */}
                                                <input
                                                    id="message"
                                                    type="text"
                                                    className="input-msg-send form-control"
                                                    placeholder="Write your message here..."
                                                    onKeyPress={this.manageKeyPress}
                                                    onKeyUp={this.manageKeyUp}
                                                    onChange={e => this.setState({ message: e.target.value })}
                                                    value={this.state.message}
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn msg-send-btn"
                                                        id="btn-chat"
                                                        onMouseUp={this.handleSubmit}
                                                    ><i
                                                        className="fab fa-telegram-plane"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                { this.state.showSupportButtons
                && <div className="support-button" style={{position: "fixed",top: "40%",left: "38%", textAlign: "center"}}>
                    <h1>Chat couldn't be connected. Do you want to</h1>
                    <br/>
                    <button style={{marginRight: '10px'}}className="btn btn-lg bg-primary-light" onMouseUp={() => this.GetAppointment(this.doctorUniqueKey, this.appointmentCode)}><i className="fas fa-calendar-day"></i> Reschedule</button>
                    <button style={{marginRight: '10px'}}className="btn btn-lg bg-danger-light" onMouseUp={() => this.handleCancel(this.appointmentCode)}><i className="fas fa-calendar-times"></i> Cancel</button>
                    <Link to='/support' className="btn btn-lg bg-info-light"><i className="fas fa-comment-alt"></i> Chat Support</Link>
                </div>}
                <Appoinment appointmentCode={this.appointmentCode} updateAppointmentList={this.updateAppointmentList}/>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetailListing: state.admin.users,
    chat_history: state.global.chat_history
  });

export default connect(mapStateToProps)(Chat);
