import * as types from "../types/types";

const initalState = {
    product_cart:[],
    total_amount: 0
}

const productReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_PRODUCT_CART_DETAILS:
            return {
                ...state,
                product_cart: action.payload
            }
        case types.PRODUCT_TOTAL_AMOUNT:
            return {
                ...state,
                total_amount: action.payload
            }
        default:
            return state
    }
}

export default productReducer;
