import * as React from "react";
import { connect } from "react-redux";
import Pagination from "./../../store/helpers/Pagination";
import {AdminFitnessClasses } from "./../../store/actions/adminActions"; 

class AdminFitnessClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], page: 1 };
  }

  componentDidMount() { 
    this.props.dispatch(AdminFitnessClasses())
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

   

  render() {
      const {fitness} = this.props 
      const {pageOfItems,currentPage} = this.state
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="datatable table table-hover table-center mb-0">
                  <thead>
                    <tr>
                        <th>S No.</th>
                        <th>Expert's Name</th>
                        <th>Fitness Class</th>
                        <th>Start Date</th>
                        <th>Duration</th>
                        <th>Price</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {pageOfItems.length > 0 ? ( pageOfItems.map((value, index) => (
                        <tr key={`fitness_${index}`}>
                            <td>{((currentPage-1)*20) + (index+1)}</td>
                            <td>{value.fitnessexpert_name} </td>
                            <td>{value.title}</td>
                            <td>{value.date}</td>
                            <td>{value.duration}</td>
                            <td>{`₹${value.price}`}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No records available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="container-fluid no-padding">
                <div className="row pull-right"> 
                  <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                    &nbsp;
                    {fitness && (
                      <Pagination
                        pageSize={20}
                        items={fitness}
                        onChangePage={this.onChangePage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ 
  fitness:state.admin.admin_fitness_classes
});
export default connect(mapStateToProps)(AdminFitnessClass);
