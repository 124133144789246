import React, { Component } from 'react';
import Template from './Template';
import SocialMedia from './SocialMedia';
import ChangePassword from '../Common/ChangePassword'
import {Link} from 'react-router-dom';

class Setting extends Component {
    state = {tab:'change-password'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="change-password"?"active":""}`} to="#" onClick={e=>this.setState({tab:'change-password'})}>Change Password</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="social-media"?"active":""}`} to="#" onClick={e=>this.setState({tab:'social-media'})}>Social Media</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="template"?"active":""}`} to="#" onClick={e=>this.setState({tab:'template'})} >Template</Link>
                                </li>
                            </ul>
                        </nav>
                        {tab==="change-password" && <ChangePassword/>}
                        {tab==="social-media" && <SocialMedia/>}
                        {tab==="template" && <Template/>}

                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Setting;
