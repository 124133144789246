import * as types from './../types/types';

const initalState = {
  profile_info: [],
  selected_counsellor_profile_info: [],
  counsellor_profile_info: [],
  isLoggedIn: false,
  doctor_search_info: '',
  chat_history: [],
  reviews_list: [],
  product_reviews_list: [],
  login_token: null,
  location: null,
  product_list:[],
  view_product:[],
  open_product_modal:{},
  review_tab:{}
};

const globalReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case types.DOCTOR_PROFILE_INFO:
      return {
        ...state,
        profile_info: action.payload,
      };
    case types.SELECTED_COUNSELLOR_PROFILE_INFO:
      return {
        ...state,
        selected_counsellor_profile_info: action.payload,
      };
    case types.DOCTOR_SEARCH_INFO:
      return {
        ...state,
        doctor_search_info: action.payload,
      };
    case types.OPEN_REVIEW_TAB: 
      return {
        ...state,
        review_tab: action.payload,
      };
    case types.SUPPORT_CHAT_HISTORY:
      return {
        ...state,
        chat_history: action.payload,
      };
    case types.REVIEWS_LIST:
      return {
        ...state,
        reviews_list: action.payload,
      };
    case types.FIREBASE_LOGIN_TOKEN:
      return {
        ...state,
        login_token: action.payload,
      };
    case types.COUNSELLOR_PROFILE_INFO:
      return {
        ...state,
        counsellor_profile_info: action.payload,
      };
    case types.STORE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case types.FETCH_GLOBAL_PRODUCT_LIST:
      return {
        ...state,
        product_list: action.payload,
      };
    case types.FETCH_VIEW_PRODUCT:
      return {
        ...state,
        view_product: action.payload,
      };
    case types.PRODUCT_REVIEWS_LIST:
      return {
        ...state,
        product_reviews_list: action.payload,
      };

    case types.OPEN_PRODUCT_MODAL:
      return {
          ...state,
          open_product_modal: action.payload
      } 
    default:
      return state;
  }
};

export default globalReducer;
