import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { AddCategory,CategoryListing,DeleteCategory } from '../../../store/actions/adminActions'; 
import Pagination from '../../../store/helpers/Pagination'; 
import { Modal } from 'react-bootstrap';
import Modals from './../../General/Modals'
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert'; 
import { apiBaseUrl } from "../../../store/helpers/common";

const initialState = {open:false,pageOfItems:[],currentPage:1,totalItems:[]}

class Category extends Component {
    constructor(props) {
        super(props)
        this.formValidator = new SimpleReactValidator();
        this.state = initialState 
    }

    componentDidMount(){
        this.props.dispatch(CategoryListing({type:'list'}))
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => { 
        this.setState({
            open: false, isEdit: false, totalItems: [0]
        });
    };

    onSubmitCategory= async (e) => {
        const {name,description,id,isEdit} = this.state
        let type = isEdit ? 'edit' : 'insert'
        const data = {name,description,type,id}
        if (this.formValidator.allValid()) {
            let response = await this.props.dispatch(AddCategory(data))
            if(response === 1){
                this.setState({
                    type:'',
                    open:false,
                    isEdit:false
                }) 
            }
        } else { 
            this.formValidator.showMessages();
            this.forceUpdate();
          }
          e.preventDefault();
    } 

    DeleteCategory = (e, uniquekey) => {
        let data={type:'delete',id:uniquekey}
        swal({
            title: "Are you sure you want to delete this Category?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteCategory(data));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    EditCategory = (e, id) => {
        const request = new Request(`${apiBaseUrl}/api/category-api`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("admin-token")}`,
            },
            body: JSON.stringify({ id,type:'view' }),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data; 
                this.setState({
                    id: data.uniquekey, 
                    name:data.name,
                    description:data.description,
                    isEdit: true, 
                    open: true,
                });
            });
    };

    handleOpenModal =()=>{
        this.setState({
            open:true,
            name:'',
            description:'',
            isEdit:false
        })
    }

    render() {
        const {open,pageOfItems,isEdit} = this.state
        const {category_listing} = this.props
        return (
            <div>
                <div className="text-right">
                    <Link to="#" className="add-new-btn" onClick={()=>this.handleOpenModal()}>Add Category</Link>
                </div>
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>CID</th>
                                        <th>Name</th> 
                                        <th>Description</th>
                                        <th className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageOfItems && pageOfItems.map((value, index) => {
                                        return (
                                            <tr key={`category_${index}`}>
                                                <td>{value.uniquekey}</td>
                                                <td>{value.name}</td>
                                                <td>{value.description}</td>
                                                <td className="text-right">
                                                    <div className="actions">
                                                        <Link
                                                            className="btn btn-sm bg-success-light"
                                                            to="#"
                                                            onClick={(e) =>this.EditCategory(e,value.uniquekey)}
                                                        >
                                                            <i className="fe fe-pencil"></i>&nbsp;
                                                            Edit
                                                        </Link>&nbsp;&nbsp;
                                                        <Link
                                                            data-toggle="modal"
                                                            to="#"
                                                            className="btn btn-sm bg-danger-light"
                                                            onClick={( e ) => this.DeleteCategory(e,value.uniquekey)}
                                                        >
                                                            <i className="fe fe-trash"></i>&nbsp;
                                                            Delete
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {category_listing.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={category_listing}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* MODAL FOR ADD CATEGORY */}

                <Modals open={open} setOpen={this.setOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {isEdit ? 'Edit' : 'Add' } Category
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row form-row label-star">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={this.state.name}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        /> 
                                          {this.formValidator.message( "Name", this.state.name,"required")}
                                    </div> 
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea 
                                            className="form-control" 
                                            name="description" 
                                            value={this.state.description} 
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >   
                                        </textarea>
                                        {this.formValidator.message( "Description", this.state.description,"required")}
                                    </div> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="submit-section">
                                        <Link to="#" className="btn btn-light-black submit-btn" onClick={e => this.onSubmitCategory(e)}>Save</Link> 
                                    </div>
                                </div>
                            </div> 
                        </form> 
                    </Modal.Body>
                </Modals> 

            </div>
        )
    }
}

const mapStateToProps = (state) => ({ 
    category_listing:state.admin.category_listing
});

export default connect(mapStateToProps)(Category);