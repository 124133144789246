import React,{Component} from "react";
import { Link } from "react-router-dom";
// import { userType,apiBaseUrl } from "./../../store/helpers/common";
import { connect } from "react-redux";

class NavBar extends Component {
  constructor(props){
    super(props)
		this.storage = JSON.parse(localStorage.getItem('admin-identity'))
		this.state={
			location:''
		}
	}

	handleClick =(selected,e) =>{
		this.setState({
			location:selected
		})
	}

  render (){
    return (
       <div>
         <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
					<div id="sidebar-menu" className="sidebar-menu">
						<ul>
							<li className={`${this.state.location==='dashboard' ? 'active':''}`}>
								<Link to="/admin/dashboard" onClick={e=>this.handleClick('dashboard',e)}><i className="fe fe-home"></i> <span>Dashboard</span></Link>
							</li>
							{/* <li>
								<Link to="/admin/appointment-list"><i className="fe fe-layout"></i> <span>Appointments</span></Link>
							</li> */} 
							{/* <li className={`${this.state.location==='symptoms' ? 'active':''}`}>
								<Link to="/admin/symptoms" onClick={e=>this.handleClick('symptoms',e)}><i className="fas fa-diagnoses"></i> <span>Symptoms</span></Link>
							</li> */}
							{/* <li className={`${this.state.location==='counsellor-types' ? 'active':''}`}>
								<Link to="/admin/counsellor-types" onClick={e=>this.handleClick('counsellor-types',e)}><i className="fas fa-diagnoses"></i> <span>Counsellor Types</span></Link>
							</li> */} 
							<li className={`${this.state.location==='counsellor-list' ? 'active':''}`}>
								<Link to="/admin/counsellor-list" onClick={e=>this.handleClick('counsellor-list',e)}><i className="fe fe-user-plus"></i> <span>Counsellors</span></Link>
							</li>
							<li className={`${this.state.location==='doctor-list' ? 'active':''}`}>
								<Link to="/admin/doctor-list" onClick={e=>this.handleClick('doctor-list',e)}><i className="fe fe-user-plus"></i> <span>Doctors</span></Link>
							</li>
							<li className={`${this.state.location==='patient-list' ? 'active':''}`}>
								<Link to="/admin/patient-list" onClick={e=>this.handleClick('patient-list',e)}><i className="fe fe-user"></i> <span>Patients</span></Link>
							</li>
							<li className={`${this.state.location==='fitness' ? 'active':''}`}>
								<Link to="/admin/fitness" onClick={e=>this.handleClick('fitness',e)}><i className="fe fe-user-plus"></i> <span>Fitness Experts</span></Link>
							</li>
							<li className={`${this.state.location==='hospital' ? 'active':''}`}>
								<Link to="/admin/hospital" onClick={e => this.handleClick('hospital', e)}><i className="fas fa-hospital"></i><span>Hospitals</span></Link>
							</li>
							{/* <li className={`${this.state.location==='labs' ? 'active':''}`}>
								<Link to="/admin/labs" onClick={e => this.handleClick('labs', e)}><i className="fas fa-flask"></i><span>Labs</span></Link>
							</li> */}
							{/* <li className={`${this.state.location==='pharmacy' ? 'active':''}`}>
								<Link to="/admin/pharmacy" onClick={e => this.handleClick('pharmacy', e)}><i className="fas fa-pills"></i><span>Pharmacy</span></Link>
							</li> */}
							{/* <li className={`${this.state.location==='query' ? 'active':''}`}>
								<Link to="/admin/query" onClick={e => this.handleClick('query', e)}><i className="fas fa-file-signature"></i><span>Query</span></Link>
							</li> */}

							<li className={`${this.state.location==='reviews' ? 'active':''}`}>
								<Link to="/admin/reviews" onClick={e=>this.handleClick('reviews',e)}><i className="fas fa-star"></i> <span>Reviews</span></Link>
							</li> 
							<li className={`${this.state.location==='specialities' ? 'active':''}`}>
								<Link to="/admin/specialities" onClick={e=>this.handleClick('specialities',e)}><i className="fe fe-users"></i> <span>Specialities</span></Link>
							</li>
							<li className={`${this.state.location==='article' ? 'active':''}`}>
								<Link to="/admin/article" onClick={e => this.handleClick('article', e)}><i className="fa fa-file"></i><span>Article</span></Link>
							</li>
							{/* <li className={`${this.state.location==='query' ? 'active':''}`}>
								<Link to="/admin/messages" onClick={e => this.handleClick('query', e)}><i className="fas fa-file-signature"></i><span>Messages</span></Link>
							</li> */}
							<li className={`${this.state.location==='charge' ? 'active':''}`}>
								<Link to="/admin/subscription-charge" onClick={e => this.handleClick('charge', e)}><i className="fas fa-file-signature"></i><span>Subscription Charges</span></Link>
							</li>
							<li className={`${this.state.location==='invoice' ? 'active':''}`}>
								<Link to="/admin/invoice-report" onClick={e => this.handleClick('invoice', e)}><i className="fas fa-file-medical-alt"></i><span>Invoice Report</span></Link>
							</li>
							<li className={`${this.state.location==='product' ? 'active':''}`}>
								<Link to="/admin/products" onClick={e => this.handleClick('product', e)}><i className="fa fa-list-alt"></i><span>Products</span></Link>
							</li>
							<li className={`${this.state.location==='settings' ? 'active':''}`}>
								<Link to="/admin/settings" onClick={e => this.handleClick('settings', e)}><i className="fa fa-cogs"></i><span>settings</span></Link>
							</li>
						</ul>
					</div>
                </div>
            </div>

       </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(NavBar);
