import { apiBaseUrl } from "./../helpers/common";
import * as actionTypes from "./../types/types";
import { handleResponse } from "./../helpers/userServices";
import toastr from "reactjs-toastr";
import { ShowLoader, ShowAlert } from "./staticListingsActions";
import swal from "sweetalert";
import { firebaseAuth } from "../helpers/firebaseConfig";
const axios = require("axios");

export function auth(isLoggedIn) {
    return (dispatch) => {
        dispatch({ type: actionTypes.AUTH_LOGIN, payload: isLoggedIn });
    };
}

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN,
    };
};

export function storelocation(location) {
    return (dispatch) => {
        dispatch({ type: actionTypes.STORE_LOCATION, payload: location });
    };
}

export function scheduleTimings(data) {
    return (dispatch) => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/fitness-timing-schedule`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    window.$("#add_time_slot").modal("hide");
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                    dispatch(scheduleTimingsView());
                    return 1;
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                    return 0;
                }
            });
    };
}

export function scheduleTimingsView() {
    return (dispatch) => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/fitness-timing-view`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FETCH_SCHEDULED_TIMING, payload: data.data });
                    return 1;
                }
            });
    };
}

export function UpdateScheduleTimings(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/update-fitness-timing`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch(scheduleTimingsView());
                    return 1;
                }
            });
    };
}

export function GetFitnessAppointmentList(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/appointments`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json" }),
        });

        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FITNESS_APPOINTMENT_LISTING, payload: data.data });
                }
            });
    };
}

export function ArticleListing(uniquekey) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness-article-list`, {
            method: "POST",
            body: JSON.stringify({ uniquekey: uniquekey }),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FITNESS_ARTICLE_LISTING, payload: responseData.data });
                    return 1;
                } else {
                    return 0;
                }
            });
    };
}

// ArticleListing from doctor end
export function AddArticles(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));

        let formData = new FormData();
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("uniquekey", data.uniquekey);
        formData.append("id", data.id);

        let imageFile = window.$("#image")[0].files[0];
        if (imageFile) {
            formData.append("image", imageFile);
        }

        const request = {
            method: "post",
            url: `${apiBaseUrl}/api/add-fitness-article`,
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            },
            data: formData,
        };
        return axios(request)
            .then((data) => {
                dispatch(ShowLoader(false));
                data = data.data;
                if (data.ResponseCode === "1") {
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                    dispatch(ArticleListing({ uniquekey: data.uniquekey }));
                    return 1;
                } else {
                    swal({ title: data.ResponseText, icon: "error", dangerMode: true });
                    return 0;
                }
            })
            .catch((error) => {
                dispatch(ShowLoader(false));
                if (error && error.response && error.response.data) {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                    return 0;
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: "Error Occured", Timeout: 1500 }));
                    return 0;
                }
            });
    };
}
// ArticleListing from doctor end
export function DeleteArticle(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-fitness-article`, {
            method: "POST",
            body: JSON.stringify({ id: data.id }),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                    dispatch(ArticleListing({ uniquekey: data.uniquekey }));
                    return 1;
                } else {
                    swal({ title: responseData.ResponseText, icon: "error", dangerMode: true });
                    return 0;
                }
            });
    };
}

export function GetArticle(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/get-fitness-article`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    return { response: 1, data: responseData.data };
                } else {
                    swal({ title: responseData.ResponseText, icon: "error", dangerMode: true });
                    return { response: 0 };
                }
            });
    };
}

// RecordingListing from doctor end
export function RecordingListing(roomName) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/recording-listing`, {
            method: "POST",
            body: JSON.stringify({ roomID: roomName }),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FITNESS_RECORDING_LISTING, payload: responseData.data });
                    return responseData.data.length;
                } else {
                    return 0;
                }
            });
    };
}

// RecordingListing from doctor end
export function DeleteRecording(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/recording-delete`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                    dispatch(RecordingListing(data.roomID));
                    return 1;
                } else {
                    swal({ title: responseData.ResponseText, icon: "error", dangerMode: true });
                    return 0;
                }
            });
    };
}

export function Fitness_Patients() {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness-patients`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.COUNSELOR_PATIENTS, payload: data.data });
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            });
    };
}

export function getInvoiceListing() {
    return (dispatch) => {
        const request = new Request(`${apiBaseUrl}/api/fitness/payment/listing`, {
            method: "POST",
            // body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FETCH_PATIENT_INVOICES, payload: data.data });
                }
            });
    };
}

export function SocialMediaUrl(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add/edit-fitness-social-media`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 1500 }));
                    dispatch(ViewSocialMediaUrl());
                    return 1;
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 1500 }));
                    return 0;
                }
            });
    };
}

export function ViewSocialMediaUrl() {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/view-fitness-social-media`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.VIEW_SOCIAL_MEDIA_FITNESS, payload: data.data });
                    return 1;
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 1500 }));
                    return 0;
                }
            });
    };
}

export function FitnessMedicalRecordsList(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/Patientmedical-records-for-councellor`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FITNESS_MEDICAL_RECORDS_LIST, payload: data.data });
                    return 1;
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 10000 }));
                    return 0;
                }
            });
    };
}

export function GetFitnessExpertReviews(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/fitness-review`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FITNESS_REVIEWS, payload: responseData.data });
                    return 1;
                } else {
                    return 0;
                }
            });
    };
}

// FETCH INDIVIDUAL PATIENT INFO WHICH WERE BOOKED VIA APPOINTMENTS
export function PatientOfFitnessExpert(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient-of-counsellor`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.PATIENT_OF_FITNESS_EXPERT, payload: data.data });
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            });
    };
}

// FETCH INDIVIDUAL PATIENT APPOINTMENTS OF A PARTICULAR DOCTOR
export function PatientAppointments(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/patient-appointment`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FITNESS_PATIENT_APPOINTMENT_LIST, payload: data.data });
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
                }
            });
    };
}
export function ViewHomeArticle() {
    return (dispatch) => {
        const request = new Request(`${apiBaseUrl}/api/random-article-list`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
        });

        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.VIEW_HOME_ARTICLE, payload: data.data });
                } else {
                    return 0;
                }
            });
    };
}

export function getFitnessExpertList(search = "", location = "", symptoms = "", specializations = "", gender = "") {
    return (dispatch) => {
        const request = new Request(`${apiBaseUrl}/api/get-all-fitness`, {
            method: "POST",
            body: JSON.stringify({ search: search, location: location, symptoms: symptoms, specializations: specializations, gender: gender }),
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FETCH_FITNESSEXPERT_LISTING, payload: data.data });
                }
            });
    };
}

export function FitnessExpertProfileDetails(id) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/fitness-profile-details/${id}`, {
            method: "GET",
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({
                        type: actionTypes.FITNESS_EXPERT_PROFILE_INFO,
                        payload: data.data,
                    });
                } else {
                    dispatch(
                        ShowAlert({
                            Class: "alert-danger",
                            Message: "Error Occured",
                            Timeout: 5000,
                        })
                    );
                }
            });
    };
}

export function DoctorSearchings(value) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.DOCTOR_SEARCH_INFO,
            payload: value.toLowerCase(),
        });
    };
}

export function supportChatHistory(type = null, postData = {}) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const token = type ? `${localStorage.getItem("admin-token")}` : `${localStorage.getItem("token")}`;
        const request = new Request(`${apiBaseUrl}/api/user/chat-history`, {
            method: "POST",
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": token,
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({
                        type: actionTypes.SUPPORT_CHAT_HISTORY,
                        payload: data.data,
                    });
                } else {
                    dispatch(
                        ShowAlert({
                            Class: "alert-danger",
                            Message: "Error Occured",
                            Timeout: 5000,
                        })
                    );
                }
            });
    };
}

// Add Review
export function SubmitReview(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-fitness-review`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    swal({
                        title: `Review ${data.reviewID ? `Updated` : `Added`} Successfully`,
                        icon: "success",
                        dangerMode: true,
                    });
                    dispatch(FitnessReviewsList({ uniquekey: data.loginkey }));
                    return 1;
                } else {
                    swal({
                        title: responseData.ResponseText,
                        icon: "error",
                        dangerMode: true,
                    });
                    return 0;
                }
            });
    };
}

//Review Listing
export function FitnessReviewsList(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness-review`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch({
                        type: actionTypes.REVIEWS_LIST,
                        payload: responseData.data,
                    });
                    return 1;
                } else {
                    return 0;
                }
            });
    };
}

export function getFirebaseLoginToken() {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/firebase/refresh-token`, {
            method: "POST",
            body: JSON.stringify({}),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch({
                        type: actionTypes.FIREBASE_LOGIN_TOKEN,
                        payload: responseData.data,
                    });
                    return 1;
                } else {
                    return 0;
                }
            });
    };
}

export function FirebaseLogin(token) {
    return (dispatch) => {
        firebaseAuth.signInWithCustomToken(token).catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode, errorMessage);
        });
    };
}

export function FirebaseStoreDeviceToken(postData) {
    return () => {
        const request = new Request(`${apiBaseUrl}/api/device-token`, {
            method: "POST",
            body: JSON.stringify(postData),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                if (responseData.ResponseCode === "1") {
                    return 1;
                } else {
                    return 0;
                }
            });
    };
}
export function GetTodayFitnessAppointmentList(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/appointments/today`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json" }),
        });

        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch(ShowLoader(false));
                    dispatch({ type: actionTypes.FETCH_TODAY_FITNESS_APPOINTMENT_LISTING, payload: data.data });
                } else {
                    dispatch(ShowLoader(false));
                    return 0;
                }
            });
    };
}

export function GetFitnessAppointmentDetails() {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness_appointments_record`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });

        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch(ShowLoader(false));
                    dispatch({ type: actionTypes.FITNESS_APPOINTMENT_DETAILS, payload: data.data });
                } else {
                    dispatch(ShowLoader(false));
                    return 0;
                }
            });
    };
}

export function DeleteFitnessClass(data) {
    return (dispatch) => {
        const request = new Request(`${apiBaseUrl}/api/delete-fitness-class`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });

        return fetch(request)
            .then((res) => res.json())
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch(FitnessClassListing());
                    swal({ title: "Success!", text: data.data.ResponseText, icon: "success" });
                } else {
                    return 0;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function DeleteFitnessImage(data) {
    return (dispatch) => {
        const request = new Request(`${apiBaseUrl}/api/delete-fitness-images`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });

        return fetch(request)
            .then((res) => res.json())
            .then((data) => {
                if (data.ResponseCode === "1") {
                    swal({ title: "Success!", text: "Image deleted successfully.", icon: "success" });
                    window.location.reload();
                } else {
                    return 0;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function FitnessClass(data, this_ref) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        let request;
        let formData = new FormData();
        var files = window.$("#fitness_image")[0].files;
        for (let i = 0; i < files.length; i++) {
            formData.append(`file[${i}]`, files[i]);
        }
        if (data.classId !== "" && data.classId) {
            formData.append("id", data.classId);
        }
        formData.append("title", data.title);
        formData.append("date", data.date);
        formData.append("price", data.price);
        formData.append("description", data.description);
        formData.append("duration", data.duration);
        formData.append("fitness_timings", JSON.stringify(data.fitness_timings));
        request = {
            method: "POST",
            url: `${apiBaseUrl}/api/${data.classId ? "edit-fitness-class" : "add-fitness-class"}`,
            headers: { "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` },
            data: formData,
        };

        return axios(request)
            .then((data) => {
                if (data.data.ResponseCode === "1") {
                    dispatch(ShowLoader(false));
                    swal({ title: "Success!", text: data.data.ResponseText, icon: "success" });
                    return 1;
                } else {
                    dispatch(ShowLoader(false));
                    swal({ title: "Error!", text: data.data.ResponseText, icon: "error" });
                    return 0;
                }
            })
            .catch((error) => {
                dispatch(ShowLoader(false));
                if (error && error.response && error.response.data) {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                    return 0;
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: "Error Occured", Timeout: 1500 }));
                    return 0;
                }
            });
    };
}

export function ViewFitnessClass(uniqueID) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/view-fitness-class`, {
            method: "POST",
            body: JSON.stringify({ uniquekey: uniqueID }),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((response) => {
                dispatch(ShowLoader(false));
                if (response.ResponseCode === "1") {
                    dispatch({ type: actionTypes.VIEW_FITNESS_CLASS, payload: response.data });
                    return 1;
                } else {
                    return 0;
                }
            })
            .catch((error) => {
                dispatch(ShowLoader(false));
            });
    };
}

export function FitnessClassListing() {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/listing-fitness-class`, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });

        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                if (data.ResponseCode === "1") {
                    dispatch(ShowLoader(false));
                    dispatch({ type: actionTypes.FITNESS_CLASS_LISTING, payload: data.data });
                } else {
                    dispatch(ShowLoader(false));
                    return 0;
                }
            });
    };
}

export function GlobalFitnessClassList() {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness-class-list`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((responseData) => {
                dispatch(ShowLoader(false));
                if (responseData.ResponseCode === "1") {
                    dispatch({
                        type: actionTypes.FETCH_GLOBAL_FITNESS_CLASS_LIST,
                        payload: responseData.data,
                    });
                    return 1;
                } else {
                    return 0;
                }
            });
    };
}

export function GlobalViewFitnessClass(uniqueID) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/view-fitness-class`, {
            method: "POST",
            body: JSON.stringify({ uniquekey: uniqueID }),
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((response) => {
                dispatch(ShowLoader(false));
                if (response.ResponseCode === "1") {
                    dispatch({ type: actionTypes.GLOBAL_VIEW_FITNESS_CLASS, payload: response.data });
                    return 1;
                } else {
                    return 0;
                }
            })
            .catch((error) => {
                dispatch(ShowLoader(false));
            });
    };
}

export function BookFitnessExpertClass(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/book-class-appointment`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 10000 }));
                    return { response: "success", code: data.data };
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 10000 }));
                    return { response: "error" };
                }
            });
    };
}

export function BookingClassDetail(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/appointment-detail`, {
            method: "POST",
            body: JSON.stringify({ appointment_code: data }),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch({ type: actionTypes.FETCH_BOOKING_CLASS_CHECKOUT, payload: data.data });
                } else {
                    return { response: "error" };
                }
            });
    };
}

export function FitnessClassCheckOut(data) {
    return (dispatch) => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/stripe/checkout-for-Fitness`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json", "X-Auth-Token": `${localStorage.getItem("token")}` }),
        });
        return fetch(request)
            .then(handleResponse)
            .then((data) => {
                dispatch(ShowLoader(false));
                if (data.ResponseCode === "1") {
                    dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 10000 }));
                    return 1;
                } else {
                    dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 10000 }));
                    return 0;
                }
            });
    };
}
