import moment from 'moment';

export const SALT = "TwkRrROIrL";

export const getTimeStops = (start, end, slot, timeformat = 'HH:mm') => {
  //hh:mm A
  var startTime = moment(start, timeformat);
  var endTime = moment(end, timeformat);
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }
  var timeStops = [];
  while (startTime <= endTime) {
    timeStops.push(new moment(startTime).format(timeformat));
    startTime.add(slot, 'minutes');
  }
  return timeStops;
};

export const Addtimes = (currentTime, minutes = 15, format = 'HH:mm') => {
  return moment.utc(currentTime, format).add(minutes, 'minutes').format(format);
};

export const timezone_offset = () => {
  let date = new Date();
  let newOffset = date.getTimezoneOffset();
  return newOffset;
};

export const currentDay = () => {
  let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  let d = new Date();
  let dayName = days[d.getDay()];
  return dayName;
};

export const currentDate = (format = 'D MMMM YYYY') => {
  return moment().format(format);
};

export const checkTimeExistance = (startTime, endTime) => {
  let currentDate = new Date();

  let startDate = new Date(currentDate.getTime());
  startDate.setHours(startTime.split(':')[0]);
  startDate.setMinutes(startTime.split(':')[1]);
  // startDate.setSeconds(startTime.split(":")[2]);

  let endDate = new Date(currentDate.getTime());
  endDate.setHours(endTime.split(':')[0]);
  endDate.setMinutes(endTime.split(':')[1]);
  // endDate.setSeconds(endTime.split(":")[2]);

  return startDate < currentDate && endDate > currentDate;
};

export const SetLocalExpiry = (key, value, ttl=365*24*3600) => {
  let now = new Date();
  let item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const GetLocalWithExpiry = (key) => {
	let itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	let item = JSON.parse(itemStr)
	let now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

