import * as types from "./../types/types";

const initalState = {
    loading: true,
    data: [],
    adminDocListing:[],
    adminSpecListing:[],
    adminPatientListing:[],
    adminLabListing:[],
    adminPharmacyListing:[],
    adminHospitalListing:[],
    admin_profile_info:[],
    uniqueID:'',
    query:[],
    users: [],
    article_listing:[],
    admin_reviews: [],
    subscription_charge_list: [],
    doctor_charge:0,
    admin_invoice_listing: [],
    queryString: '',
    counsellor_type_list: [],
    category_listing:[],
    subcategory_listing:[],
    product_listing:[],
    product_image:[],
    total_count:[],
    admin_fitness_classes:[]
}

const adminReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.ADMIN_DOCTOR_LISTING:
        return {
            ...state,
            loading: false,
            adminDocListing: action.payload
        }
        case types.ADMIN_SPEICALISATION_LISTING:
        return {
            ...state,
            loading: false,
            adminSpecListing: action.payload
        }
        case types.ADMIN_PATIENT_LISTING:
        return {
            ...state,
            loading: false,
            adminPatientListing: action.payload
        }
        case types.ADMIN_LAB_LISTING:
        return {
            ...state,
            loading: false,
            adminLabListing: action.payload
        }
        case types.ADMIN_PHARMACY_LISTING:
        return {
            ...state,
            loading: false,
            adminPharmacyListing: action.payload
        }
        case types.ADMIN_HOSPITAL_LISTING:
        return {
            ...state,
            loading: false,
            adminHospitalListing: action.payload
        }
        case types.FETCH_ADMIN_PROFILE_INFO:
            return {
                ...state,
                loading: false,
                admin_profile_info: action.payload
            }
        case types.FETCH_UNQIUE_ID:
        return {
            ...state,
            uniqueID: action.payload
        }
        case types.ADMIN_QUERY_LISTING:
            return {
                ...state,
                loading: false,
                query: action.payload
            }
        case types.USER_DETAIL_FOR_CHAT:
            return {
                ...state,
                loading: false,
                users: action.payload
            }
        case types.ADMIN_ARTICLE_LISTING:
            return {
                ...state,
                loading: false,
                article_listing: action.payload
            }
        case types.ADMIN_REVIEWS:
            return {
                ...state,
                loading: false,
                admin_reviews: action.payload
            }
        case types.ADMIN_SUBSCRIPTION_CHARGE_LIST:
            return {
                ...state,
                loading: false,
                subscription_charge_list: action.payload
            }
        case types.CHARGES_TO_DOCTOR:
            return {
                ...state,
                doctor_charge: action.payload
            }
        case types.ADMIN_INVOICE_LISTING:
            return {
                ...state,
                admin_invoice_listing: action.payload
            }
        case types.QUERY:
            return {
                ...state,
                queryString: action.payload
            }
        case types.COUNSELLOR_TYPE_LIST:
            return {
                ...state,
                counsellor_type_list: action.payload
            }
        case types.ADMIN_COUNSELLOR_LISTING:
            return {
                ...state,
                admin_counsellor_list: action.payload
            }
        case types.FETCH_CATEGORY_LISTING:
            return {
                ...state,
                category_listing: action.payload
            }
        case types.FETCH_SUBCATEGORY_LISTING:
            return {
                ...state,
                subcategory_listing: action.payload
            }
        case types.FETCH_PRODUCT_LISTING:
        return {
            ...state,
            product_listing: action.payload
        }
        case types.FETCH_PRODUCT_IMAGES_LISTING:
            return {
                ...state,
                product_image: action.payload
            }
        case types.FETCH_TOTAL_COUNT:
            return {
                ...state,
                total_count: action.payload
            }
        case types.FETCH_ADMIN_FITNESS_CLASSES:
            return {
                ...state,
                admin_fitness_classes: action.payload
            }
        default:
            return state
    }
}

export default adminReducer;