import * as types from "./../types/types";

const initalState = {
    loading: true,
    checkoutInfo: [],
    checkout_details: [],
    patient_appoinments: [],
    doctor_patient_appoinments: [],
    weekly_appointments: [],
    prescriptions_list: [],
    medical_records: [],
    counsellor_weekly_appointments: [],
    fitness_expert_weekly_appointments: [],
    patcoun_appointments: [],
    patfitness_appointments: []
}

const registrationReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.LANGUAGES_LIST:
            return {
                ...state,
                loading: true,
                languages: action.payload
            }
        case types.FETCH_APPOINTMENT_CHECKOUT:
            return {
                ...state,
                checkoutInfo: action.payload
            }
        case types.FETCH_CHECKOUT_DETAILS:
            return {
                ...state,
                checkout_details: action.payload
            }
        case types.PATIENT_APPOINTMENT_LIST:
            return {
                ...state,
                patient_appoinments: action.payload
            }
        case types.DOCTOR_PATIENT_APPOINTMENT_LIST:
            return {
                ...state,
                doctor_patient_appoinments: action.payload
            }
        case types.PRESCRIPTIONS_LIST:
            return {
                ...state,
                prescriptions_list: action.payload
            }
        case types.MEDICAL_RECORDS_LIST:
            return {
                ...state,
                medical_records: action.payload
            }
        case types.WEEKLY_APPOINTMENT_LIST:
            return {
                ...state,
                weekly_appointments: action.payload
            }
        case types.COUNSELLOR_WEEKLY_APPOINTMENT_LIST:
            return {
                ...state,
                counsellor_weekly_appointments: action.payload
            }
        case types.FITNESS_WEEKLY_APPOINTMENT_LIST:
            return {
                ...state,
                fitness_expert_weekly_appointments: action.payload
            }
        case types.PATIENT_COUNSELLOR_APPOINTMENT_LIST:
            return {
                ...state,
                patcoun_appointments: action.payload
            }
        case types.PATIENT_FITNESS_APPOINTMENT_LIST:
            return {
                ...state,
                patfitness_appointments: action.payload
            }
        default:
            return state
    }
}

export default registrationReducer;