import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices'; 
import { ShowLoader, ShowAlert } from './staticListingsActions'
import { PrescriptionsList,PatientMedicalRecordsList } from './registerationActions'
import swal from "sweetalert";
const axios = require("axios");

// FETCH INDIVIDUAL DOCTOR PATIENTS WHICH WERE BOOKED VIA APPOINTMENTS
export function Doctor_Patients() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor-patients`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.DOCTOR_PATIENTS, payload: data.data })
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
            }
        })
    }
}


// FETCH INDIVIDUAL PATIENT INFO WHICH WERE BOOKED VIA APPOINTMENTS
export function PatientOfDoctor(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient-of-doctor`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.PATIENT_OF_DOCTOR, payload: data.data })
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
            }
        })
    }
}

// FETCH INDIVIDUAL PATIENT APPOINTMENTS OF A PARTICULAR DOCTOR
export function PatientAppointments(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/patient-appointment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.DOCTOR_PATIENT_APPOINTMENT_LIST, payload: data.data })
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
            }
        })
    }
}

// Add prescriptions from doctor end
export function SubmitPrescriptions(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-prescription`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(PrescriptionsList(data))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

// Selete prescriptions from doctor end
export function DeletePrescriptions(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-prescription`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(PrescriptionsList(data))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}
// Delete Medical record from doctor end
export function DeleteMedicalRecord(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-medical-record`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(PatientMedicalRecordsList({ uniquekey: data.user_key }))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

// ArticleListing from doctor end
export function ArticleListing(uniquekey) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/article-list`, {
            method: 'POST',
            body: JSON.stringify({ uniquekey: uniquekey }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({ type: actionTypes.ARTICLE_LISTING, payload: responseData.data })
                return 1
            } else {
                return 0
            }
        })
    }
}


// ArticleListing from doctor end
export function AddArticles(data) {
    return dispatch => {
        dispatch(ShowLoader(true));

        let formData = new FormData();
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("uniquekey", data.uniquekey);
        formData.append("id", data.id);

        let imageFile = window.$('#image')[0].files[0];
        if (imageFile) {
            formData.append('image', imageFile);
        }

        const request = {
            method: "post",
            url: `${apiBaseUrl}/api/add-article`,
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            },
            data: formData,
        };
        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            data = data.data;
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                dispatch(ArticleListing({ uniquekey: data.uniquekey }))
                return 1
            } else {
                swal({ title: data.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
        /*const request = new Request(`${apiBaseUrl}/api/add-article`, {
            method: 'POST',
            body: formData,
            headers: new Headers({ 'Content-Type': 'application/json','X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({Class:"alert-success",Message:responseData.ResponseText,Timeout:5000}));
                dispatch(ArticleListing({uniquekey:data.uniquekey}))
                return 1
            } else {
                swal({title:responseData.ResponseText,icon:"error",dangerMode:true})
                return 0
            }
        })*/
    }
}
// ArticleListing from doctor end
export function DeleteArticle(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-article`, {
            method: 'POST',
            body: JSON.stringify({ id: data.id }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(ArticleListing({ uniquekey: data.uniquekey }))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

export function GetArticle(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/get-article`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                return { response: 1, data: responseData.data }
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return { response: 0 }
            }
        })
    }
}

// RecordingListing from doctor end
export function RecordingListing(roomName) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/recording-listing`, {
            method: 'POST',
            body: JSON.stringify({ roomID: roomName }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({ type: actionTypes.RECORDING_LISTING, payload: responseData.data })
                return responseData.data.length;
            } else {
                return 0
            }
        })
    }
}

// RecordingListing from doctor end
export function DeleteRecording(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/recording-delete`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(RecordingListing(data.roomID))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

export function GetDoctorReviews(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor-review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({ type: actionTypes.DOCTOR_REVIEWS, payload: responseData.data })
                return 1;
            } else {
                return 0
            }
        })
    }
}

export function getInvoiceListing() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/doctor/user-payment-listing`, {
            method: 'POST',
            // body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_PATIENT_INVOICES, payload: data.data });
            }
        });
    }
}

export function UploadHeaderFooter(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-preview-prescription`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(getHeaderFooterImage())
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

export function getHeaderFooterImage() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/prescription-view`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_HEADER_FOOTER_IMAGE, payload: data.data });
            }
        });
    }
}

export function UpdateStreamingStatus(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/edit-doctor-streaming`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            if (responseData.ResponseCode === "1") {
                return 1
            } else {
                return 0
            }
        })
    }
}

export const fetchDoctorId = (data) => {  
    return {
        type: actionTypes.FETCH_DOCTOR_ID,
        payload: data
    }
}
