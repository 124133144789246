import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserDetailsForChat } from "../store/actions/adminActions";
import {
    getFirebaseLoginToken,
    FirebaseLogin,
} from "../store/actions/globalActions";
import {
    firebaseAuth,
    firebaseDatabase,
    firebaseApp,
} from "../store/helpers/firebaseConfig";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Appoinment from "./modals/Appoinment";
import { DoctorProfileDetails } from "../store/actions/globalActions";
import { WeeklyAppointments } from "../store/actions/registerationActions";
import { CancelAppointment } from "../store/actions/userActions";
import { apiBaseUrl } from "../store/helpers/common";
import Axios from "axios";

class CounsellorChatAppointment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userUid: null,
            messages: [],
            message: "",
            status: "offline",
            showSupportButtons: false,
        };
        this.chatContainerRef = React.createRef();
        this.chatMessageRef = React.createRef();
    }

    async componentDidMount() {
        const response = await this.props.getFirebaseLoginToken();
        let userStatusDatabaseRef = null;
        if (response === 1) {
            const FbLoginToken = this.props.login_token.data[0].chat_token;
            this.props.FirebaseLogin(FbLoginToken);
        }

        this.props.getUserDetailsForChat({
            uniquekey: [this.props.match.params.uid],
        });

        firebaseAuth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ userUid: user.uid }, () => {
                    userStatusDatabaseRef = firebaseDatabase.ref(
                        "/status/" + user.uid
                    );
                    firebaseDatabase
                        .ref(".info/connected")
                        .on("value", function (snapshot) {
                            if (snapshot.val() === false) {
                                return;
                            }

                            var isOfflineForDatabase = {
                                state: "offline",
                                last_changed:
                                    firebaseApp.database.ServerValue.TIMESTAMP,
                                user: JSON.parse(localStorage.data).uniquekey,
                            };

                            var isOnlineForDatabase = {
                                state: "online",
                                last_changed:
                                    firebaseApp.database.ServerValue.TIMESTAMP,
                                user: JSON.parse(localStorage.data).uniquekey,
                            };

                            userStatusDatabaseRef
                                .onDisconnect()
                                .set(isOfflineForDatabase)
                                .then(function () {
                                    userStatusDatabaseRef.set(
                                        isOnlineForDatabase
                                    );
                                });
                        });
                });
            } else {
                console.log("user logged out");
            }
        });

        const messagesRef = firebaseDatabase
            .ref(this.props.match.params.id)
            .orderByKey()
            .limitToLast(100);

        messagesRef.on("value", (snapshot) => {
            const messagesObj = snapshot.val() || {};
            let messages = [];
            Object.keys(messagesObj).forEach((key) =>
                messages.push(messagesObj[key])
            );
            this.setState(
                {
                    messages: messages,
                },
                () => {
                    this.manageScroll();
                }
            );
        });

        firebaseDatabase
            .ref()
            .child("status")
            .on("child_added", (snapshot) => {
                this.manageUserStatus(snapshot);
            });
        firebaseDatabase
            .ref()
            .child("status")
            .on("child_changed", (snapshot) => {
                this.manageUserStatus(snapshot);
            });

        this.checkUserJoinStatus();

        // window.addEventListener("beforeunload", this.onUnload);
    }

    // onUnload = (event) => {
    //     console.log(event);
    //     event.returnValue = true;
    //     return "Do you really want to close?";
    // };

    async componentDidUpdate(prevProp,prevState) {
        if (prevState !== this.state) {
            if (
                prevState.status !== this.state.status &&
                this.state.status === "offline"
            ) {
                try {
                    const postData = {
                        method: "POST",
                        url: `${apiBaseUrl}/api/finish-appointments`,
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": `${localStorage.getItem("token")}`,
                        },
                        data: JSON.stringify({
                            appointment_code: this.props.match.params.id,
                            duration: 0,
                        }),
                    };

                    const response = await Axios(postData);
                    if (response.data.ResponseCode === "1") {
                        this.props.history.push("/");
                    }
                } catch (error) {
                    console.log("Appointment complete error ==> ", error);
                }
            }
        }
    }

    checkUserJoinStatus = () => {
        setTimeout(() => {
            if (this.state.status !== "online") {
                this.setState({ showSupportButtons: true });
            }
        }, 5 * 60 * 1000);
    };

    manageUserStatus = (snapshot) => {
        const { state: status, user } = snapshot.val();
        if (user === this.props.match.params.uid && status) {
            this.setState((prevState) => {
                return prevState.status === status
                    ? { ...prevState }
                    : { ...prevState, status };
            });
        }
    };

    onAddMessage = () => {
        const data = JSON.parse(localStorage.data);
        const postMessageData = {
            text: this.state.message,
            user: {
                _id: this.state.userUid,
                avatar: "",
                email: data.email,
                id: this.state.userUid,
                name: `${data.firstname} ${data.lastname}`,
            },
        };
        firebaseDatabase.ref(this.props.match.params.id).push(postMessageData);
        this.manageScroll();
        this.setState({ message: "" }, async () => {
            try {
                const deviceToken = this.props.user_detail.data[0].devicetoken;
                const response = await fetch(
                    "https://fcm.googleapis.com/fcm/send",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization:
                                "KEY=AAAAz4lk-B8:APA91bGhZ619sxSA9epqIU1Cfq5WbA0kfoTDPrz8NajuNp50owhNkXSiCcZ2Xidb758AltyVKO9NySeBOw8mdaDMyi0w9QZNDuF5URMDihirUi-q6Ebq0mxQQ8mIElix0qI0xCUDgzF3",
                        },
                        body: JSON.stringify({
                            to: deviceToken,
                            notification: {
                                title: `Message from ${
                                    JSON.parse(localStorage.data).firstname
                                } ${JSON.parse(localStorage.data).lastname}`,
                                body: this.state.message,
                            },
                        }),
                    }
                );
                const data = await response.json();
                console.log("RESPONSE ================== ", data);
            } catch (error) {
                console.log("ERROR ================= ", error);
            }
        });
    };

    manageScroll = () => {
        if (!this.state.showSupportButtons) {
            const chatContainer = this.chatContainerRef.current,
                targetLi = this.chatMessageRef.current;
            if (targetLi) {
                chatContainer.scrollTop = targetLi.offsetTop;
            }
        }
    };

    manageKeyUp = (event) => {
        if (event.key === "Enter") {
            this.onAddMessage();
        }
    };

    GetAppointment = async (doctorUniquekey) => {
        await this.props.DoctorProfileDetails(doctorUniquekey);
        await this.props.WeeklyAppointments({ uniquekey: doctorUniquekey });
        window
            .$("#bookAppointmentListing")
            .modal({ show: true, backdrop: "static" });
    };

    handleCancel = (appointmentCode) => {
        swal({
            title: "Are you sure want to cancel the appointment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const data = { appointment_code: appointmentCode };
                const res = await this.props.CancelAppointment(data);
                if (res === 1) {
                    this.props.history.push("/");
                }
            }
        });
    };

    updateAppointmentList = () => {
        this.props.history.push("/");
    };

    renderMessage = () => {
        const { messages, userUid } = this.state;
        return (
            <>
                {messages.map((message, key) => {
                    return (
                        <li
                            key={`message_${key}`}
                            ref={this.chatMessageRef}
                            className={`media message-content ${
                                message.user._id === userUid
                                    ? "sent"
                                    : "received"
                            }`}
                        >
                            {message.user._id !== userUid && (
                                <div className="avatar">
                                    <img
                                        src={
                                            message.user.avatar
                                                ? message.user.avatar
                                                : `/assets/img/patients/patient.jpg`
                                        }
                                        alt="user"
                                        className="avatar-img rounded-circle"
                                    />
                                </div>
                            )}
                            <div className="media-body">
                                <div className="msg-box">
                                    <div>
                                        <p>{message.text}</p>
                                        <ul className="chat-msg-info">
                                            <li>
                                                <div className="chat-time">
                                                    {/* <span>8:30 AM</span> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </>
        );
    };

    render() {
        const { id: appointmentCode } = this.props.match.params;
        const { uniquekey: doctorUniqueKey } = JSON.parse(localStorage.data);
        return (
            <React.Fragment>
                {!this.state.showSupportButtons && (
                    <div className="main-wrapper">
                        <div className="content message-window-content">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="chat-window">
                                        <div className=" chat-cont-right chat-cont-right-1 message-full-div">
                                            <div className="chat-header">
                                                <Link
                                                    to="#"
                                                    id="back_user_list"
                                                    className="back-user-list"
                                                >
                                                    <i className="material-icons">
                                                        chevron_left
                                                    </i>
                                                </Link>
                                                <div className="media">
                                                    <div className="media-img-wrap">
                                                        <div
                                                            className={`avatar avatar-${
                                                                this.state
                                                                    .status ===
                                                                "online"
                                                                    ? "online"
                                                                    : "offline"
                                                            }`}
                                                        >
                                                            <img
                                                                src="/assets/img/patients/patient.jpg"
                                                                alt="User"
                                                                className="avatar-img rounded-circle"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        {this.props.user_detail
                                                            .data &&
                                                            this.props.user_detail.data.map(
                                                                (user, key) => {
                                                                    return (
                                                                        <div
                                                                            key={`user_${key}`}
                                                                            className="user-name"
                                                                        >
                                                                            {
                                                                                user.username
                                                                            }{" "}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        <div className="user-status">
                                                            {this.state.status}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chat-body">
                                                <div
                                                    className="chat-scroll"
                                                    id="chat-scroll"
                                                    ref={this.chatContainerRef}
                                                >
                                                    <ul
                                                        className="list-unstyled"
                                                        id="list-unstyled"
                                                    >
                                                        {this.renderMessage()}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="chat-footer">
                                                <div className="input-group">
                                                    <input
                                                        id="message"
                                                        type="text"
                                                        className="input-msg-send form-control"
                                                        placeholder="Write your message here..."
                                                        onChange={(e) =>
                                                            this.setState({
                                                                message:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                        onKeyUp={
                                                            this.manageKeyUp
                                                        }
                                                        value={
                                                            this.state.message
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn msg-send-btn"
                                                            id="btn-chat"
                                                            onMouseUp={
                                                                this
                                                                    .onAddMessage
                                                            }
                                                        >
                                                            <i className="fab fa-telegram-plane"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.showSupportButtons && (
                    <div
                        className="support-button"
                        style={{
                            position: "fixed",
                            top: "40%",
                            left: "32%",
                            textAlign: "center",
                        }}
                    >
                        <h1>Chat couldn't be connected. Do you want to</h1>
                        <br />
                        <button
                            style={{ marginRight: "10px" }}
                            className="btn btn-lg bg-primary-light"
                            onMouseUp={() =>
                                this.GetAppointment(doctorUniqueKey)
                            }
                        >
                            <i className="fas fa-calendar-day"></i> Reschedule
                        </button>
                        <button
                            style={{ marginRight: "10px" }}
                            className="btn btn-lg bg-danger-light"
                            onMouseUp={() => this.handleCancel(appointmentCode)}
                        >
                            <i className="fas fa-calendar-times"></i> Cancel
                        </button>
                        <Link
                            to="/support"
                            className="btn btn-lg bg-info-light"
                        >
                            <i className="fas fa-comment-alt"></i> Chat Support
                        </Link>
                    </div>
                )}
                <Appoinment
                    appointmentCode={appointmentCode}
                    updateAppointmentList={this.updateAppointmentList}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    login_token: state.global.login_token,
    user_detail: state.admin.users,
});

export default connect(mapStateToProps, {
    DoctorProfileDetails,
    WeeklyAppointments,
    CancelAppointment,
    getFirebaseLoginToken,
    FirebaseLogin,
    getUserDetailsForChat,
})(CounsellorChatAppointment);
