import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CheckoutDetail } from "../store/actions/registerationActions";
import { productCart, CheckOutProducts } from "../store/actions/productActions";
import { bucketPath } from "../store/helpers/common";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import MaskedInput from "react-maskedinput";
import swal from "sweetalert";

const initialState = {
  appointmentID: "",
  firstname: "",
  lastname: "",
  email: "",
  // phone: "",
  card_name: "",
  card_number: "",
  card_expiry: "",
  cvv: "",
  zip: "",
  terms_and_conditions: false,
  amount:''
};
class ProductCheckOut extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
      this.props.dispatch(CheckoutDetail());
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickHandler = async(e) => {
    if (!this.validator.allValid()){
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    const {firstname,lastname,email,card_name,card_number,card_expiry,cvv} = this.state;
    const { product_cart } = this.props;
    let date = card_expiry.split("/");
    let data = {
      // appointment_code: appointmentID,
      firstname,
      lastname,
      email,
      cardName: card_name,
      card_number,
      cvv,
      exp_month: date[0],
      exp_year: date[1],
      products: product_cart
    }
    const response = await this.props.dispatch(CheckOutProducts(data)); 
    // const wrapper = document.createElement('div');
// wrapper.innerHTML = `<div>Appointment booked with <b>Dr. ` + checkout_details.user_name + `</b> on <b>` + checkout_details.appointment_date + ` ` + checkout_details.appointment_slots[0].starttime + ` to ` + checkout_details.appointment_slots[0].endtime +`</b><div>`;
    if(response===1){       
      this.props.dispatch(productCart([]));
      swal({
        title: "order placed successfully",
        icon: "success",
        // content: wrapper
      })
      .then(async (action) => { 
        if(action) {
          this.props.history.push('/patient/invoices');
        }
      });
    }
    else {
      swal({
        title: "Error",
        icon: "Danger",
        // content: wrapper
      })
      .then(async (action) => { 
        if(action) {
          this.props.history.push('/patient/appointment');
        }
      });
    }
  }

  render() {
    const { checkout_details, total_amount } = this.props;
    const user_data = JSON.parse(localStorage.getItem("data"));
    // let review_average = checkout_details && checkout_details.review_average
    return (
      <div className="row">
        <div className="col-md-7 col-lg-8">
          <div className="card">
            <div className="card-body">
              {/* -- Checkout Form -- */}
              <form action="booking-success.html">
                {/* <!-- Personal Information --> */}
                <div className="info-widget">
                  <h4 className="card-title">Personal Information</h4>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>First Name</label>
                        <input
                          className="form-control"
                          name="firstname"
                          value={this.state.firstname}
                          type="text"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('First Name',this.state.firstname,'required')}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>Last Name</label>
                        <input
                          className="form-control"
                          name="lastname"
                          value={this.state.lastname}
                          type="text"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Last Name',this.state.lastname,'required')}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>Email</label>
                        <input
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          type="email"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Email',this.state.email,'required|email')}
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>Phone</label>
                        <input
                          className="form-control"
                          name="phone"
                          value={this.state.phone}
                          type="text"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Phone',this.state.phone,'required')}
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="exist-customer">Existing Customer? <Link to="#">Click here to login</Link></div> */}
                </div>
                {/* <!-- /Personal Information --> */}

                <div className="payment-widget">
                  <h4 className="card-title">Payment Method</h4>

                  {/* <!-- Credit Card Payment --> */}
                  <div className="payment-list">
                    <label className="payment-radio credit-card-option">
                      <input type="radio" name="radio" checked={true} />
                      <span className="checkmark"></span>
                      Credit card
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="card_name">Name on Card</label>
                          <input
                            className="form-control"
                            name="card_name"
                            value={this.state.card_name}
                            id="card_name"
                            type="text"
                            onChange={(e) => this.onChangeHandler(e)}
                          />
                          {this.validator.message('Name',this.state.card_name,'required')}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="card_number">Card Number</label>
                          <MaskedInput
														mask="1111 1111 1111 1111"
														name="card_number"
														id="cr_no"
														size="20"
														value={this.state.card_number}
														onChange={(e) => this.onChangeHandler(e)}
													/>
                          {/* <input
                            className="form-control"
                            id="card_number"
                            name="card_number"
                            value={this.state.card_number}
                            placeholder="1234  5678  9876  5432"
                            type="text"
                            onChange={(e) => this.onChangeHandler(e)}
                          /> */}
                          {this.validator.message('Card Number',this.state.card_number,'required|card_num')}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="expiry_month">Expiry Month</label>
                          <MaskedInput
														mask="11/1111"
														id="card_expiry"
														name="card_expiry"
														placeholder="MM/YYYY"
														value={this.state.card_expiry}
														onChange={(e) => this.onChangeHandler(e)}
													/>
                          {/* <input
                            className="form-control"
                            name="card_expiry"
                            value={this.state.card_expiry}
                            id="card_expiry"
                            placeholder="MM/YY"
                            type="text"
                            onChange={(e) => this.onChangeHandler(e)}
                          /> */}
                          {this.validator.message('Card Expiry',this.state.card_expiry,'required|card_exp')}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="cvv">CVV</label>
                          <MaskedInput
														mask="111"
														className="placeicon"
                            name="cvv"
                            id="cvv"
														value={this.state.cvv}
														// placeholder="&#9679;&#9679;&#9679;"
														onChange={(e) => this.onChangeHandler(e)}
													/>
                          {/* <input
                            className="form-control"
                            name="cvv"
                            value={this.state.cvv}
                            id="cvv"
                            type="text"
                            onChange={(e) => this.onChangeHandler(e)}
                          /> */}
                          {this.validator.message('Card Cvv',this.state.cvv,'required')}
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group card-label">
                          <label htmlFor="zip">Pin Code</label>
                          <input
                            className="form-control"
                            name="zip"
                            value={this.state.zip}
                            id="zip"
                            type="text"
                            onChange={(e) => this.onChangeHandler(e)}
                          />
                          {this.validator.message('Pin Code',this.state.zip,'required|numeric')}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <!-- /Credit Card Payment --> */}

                  {/* <!-- Paypal Payment --> */}
                  <div className="payment-list">
                    <label className="payment-radio paypal-option">
                      <input type="radio" name="radio" />
                      <span className="checkmark"></span>
                      Paypal
                    </label>
                  </div>
                  {/* <!-- /Paypal Payment --> */}

                  {/* <!-- Terms Accept --> */}
                  <div className="terms-accept">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="terms_accept"
                        name="terms_and_conditions"
                        checked={this.state.terms_and_conditions}
                        onClick={(e) =>
                          this.setState((prevState) => ({
                            terms_and_conditions: !prevState.terms_and_conditions,
                          }))
                        }
                      />&nbsp;
                      <label htmlFor="terms_accept">
                        I have read and accept{" "}
                        <Link to="#">Terms &amp; Conditions</Link>
                      </label>
                    </div>
                  </div>
                  {/* <!-- /Terms Accept --> */}

                  {/* <!-- Submit Section --> */}
                  <div className="submit-section mt-4">
                    <Link
                      to="#"
                      className="btn btn-light-black submit-btn"
                      onClick={e=>this.onClickHandler(e)}
                    >
                      Confirm and Pay
                    </Link>
                  </div>
                  {/* <!-- /Submit Section --> */}
                </div>
              </form>
              {/* <!-- /Checkout Form --> */}
            </div>
          </div>
        </div>

        
        <div className="col-md-5 col-lg-4 theiaStickySidebar">
          <div className="card booking-card">
            <div className="card-header">
              <h4 className="card-title">Checkout Summary</h4>
            </div>
            <div className="card-body">
              <div className="booking-doc-info">
                <Link to="doctor-profile.html" className="booking-doc-img">
                  <img
                    src={`${
                      user_data && user_data.user_profile_pic
                        ? bucketPath + user_data.user_profile_pic
                        : "/assets/img/doctors/doctor-thumb-02.jpg"
                    }`}
                    alt="User"
                  />
                </Link>
                <div className="booking-info">
                  <h4>
                    <Link to="doctor-profile.html">{`${
                      user_data && user_data.firstname
                    }${
                      user_data && user_data.lastname
                    }`}</Link>
                  </h4>
                  <div className="clinic-details">
                    <p className="doc-location">
                      <i className="fas fa-map-marker-alt"></i> {`${user_data && user_data.city},${user_data && user_data.country}`}
                    </p>
                  </div>
                </div>
              </div><div className="booking-summary">
                <div className="booking-item-wrap">
                  <ul className="booking-fee">
                    <li>
                      Delivery Charges  <span>₹{checkout_details && checkout_details.deliver_charge}</span>
                    </li>
                    <li>
                      Sub Total <span>₹{total_amount.totalAmount}</span>
                    </li>
                    <li>
                      TAX <span>₹{checkout_details && total_amount.totalTax}</span>
                    </li>
                  </ul>
                  <div className="booking-total">
                    <ul className="booking-total-list">
                      <li>
                        <span>Total</span>
                        <span className="total-cost">₹{parseFloat(total_amount.totalAmount) + parseFloat(total_amount.totalTax) + parseFloat(checkout_details.deliver_charge)}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  checkout_details: state.registration.checkout_details,
  total_amount: state.product.total_amount,
  product_cart:state.product.product_cart,
});
export default connect(mapStateToProps)(ProductCheckOut);
