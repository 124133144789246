import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { WeeklyAppointments } from './../store/actions/registerationActions';
import { getDoctorList,ViewHomeArticle } from './../store/actions/userActions';
import { DoctorProfileDetails,openReviewTab } from './../store/actions/globalActions';
import { specializations } from './../store/actions/staticListingsActions';
import Pagination from './../store/helpers/Pagination';
import { bucketPath } from './../store/helpers/common';
import Appoinment from './modals/Appoinment';
import LiveStreamPlayer from './doctorPanel/LiveStreamPlayer';
import ListingSideBar from './ListingSideBar'

class DoctorListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'List',
      currentPage: 1,
      pageOfItems: [],
      listType: 'row',
      symptoms: [],
      specializations: [],
      gender: '',
      totalItems: [],
      liveStream: ""
    };
  }

  symptoms = [
    { text: 'anorexia', id: 'an' },
    { text: 'chills and shivering', id: 'chi' },
    { text: 'convulsions', id: 'con' },
    { text: 'deformity', id: 'def' },
    { text: 'dizziness', id: 'diz' },
    { text: 'fatigue', id: 'fat' },
    { text: 'malaise', id: 'mal' },
    { text: 'asthenia', id: 'asth' },
    { text: 'hypothermia', id: 'hyp' },
  ];

  componentDidMount() {
    this.props.dispatch(specializations());
    this.props.dispatch(getDoctorList());
    this.props.dispatch(ViewHomeArticle())
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  onCloseHandler = () => {
    window.$('#bookAppointmentListing').modal('hide');
    this.setState({
      displayPopup: false,
    });
  };

  onClickHandler = (id) => {
    this.props.dispatch(DoctorProfileDetails(id));
    this.props.dispatch(WeeklyAppointments({ uniquekey: id }));
    this.setState(
      {
        displayPopup: true,
      },
      () => {
        window
          .$('#bookAppointmentListing')
          .modal({ show: true, backdrop: 'static' });
      }
    );
  };

  manageFilter = (type) => {
    const { symptoms, specializations, gender } = this.state;
    const { Doctors } = this.props;
    let updatedList = Doctors;
    updatedList =
      symptoms.length > 0
        ? updatedList.filter((val) => {
            let getSymptom = val.symptom ? val.symptom.split(',') : [];
            return getSymptom.some((r) => symptoms.indexOf(r) >= 0);
          })
        : updatedList;
    updatedList =
      specializations.length > 0
        ? updatedList.filter((val) => {
            return specializations.includes(val.specialization_name);
          })
        : updatedList;

    updatedList =
      gender !== ''
        ? updatedList.filter((val) => {
            return val.gender.search(gender) !== -1;
          })
        : updatedList;
    this.setState({ totalItems: updatedList }, () => {
      this.onChangePage(updatedList, this.state.page);
    });
  };

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.Doctors !== this.props.Doctors) {
      this.setState({ totalItems: this.props.Doctors });
    }
  }

  openStream = (uniqueKey) => {
    const roomName = `tll-${uniqueKey.toLowerCase()}`;
    const liveStream = `https://rtc-adidoc.cap-tek.com:8443/app/${roomName}.m3u8`
    this.setState({liveStream});
  }

  onCloseStreamModal = () => {
    this.setState({liveStream: ""});
  }

  ReviewHandler=(url)=>{ 
    this.props.history.push(`/doctor-view/${url}`);
    this.props.dispatch(openReviewTab({tab:"Reviews"}));  
  }

  render() {
    const { listType, totalItems } = this.state;

    return (
      <div className='page-content background'>
        <div className="banner-section-inner">
          <div className="container-fluid">
            <div className="col-md-12">
              <h2>Doctors</h2>
              <p>Book Doctor appointment online, Get checked live</p>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='doctor-list-section col-md-8'>
              <div className='clearfix'></div>
              <div className='filters-inner-pages'>
                <div className='row'>
                  <div className='total-dr  col-md-2 align-self-center'>
                    <p>{totalItems.length} Doctors</p>
                  </div>
                  <ul className='filters-bar-inner col-md-10'>
                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle'
                        to='#'
                        id='navbarDropdown'
                        role='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        Symptoms
                      </Link>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <div className='filter-drop-height'>
                          {this.symptoms.map((symptom, index) => {
                            return (
                              <Link
                                className={`dropdown-item ${
                                  this.state.symptoms.includes(symptom.id)
                                    ? 'active'
                                    : ''
                                }`}
                                to='#'
                                id={symptom.id}
                                key={`symptoms_${index}`}
                                onMouseUp={(e) => {
                                  this.setState(
                                    (prevState) => ({
                                      symptoms: prevState.symptoms.includes(
                                        symptom.id
                                      )
                                        ? prevState.symptoms.filter(
                                            (value) => value !== symptom.id
                                          )
                                        : [...prevState.symptoms, symptom.id],
                                    }),
                                    () => {
                                      this.manageFilter('symptoms');
                                    }
                                  );
                                }}
                              >
                                {symptom.text}
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle'
                        to='#'
                        id='navbarDropdown'
                        role='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        Specialization
                      </Link>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <div className='filter-drop-height'>
                          {this.props.specializations &&
                            this.props.specializations.map(
                              (specialization, index) => {
                                return (
                                  <Link
                                    className={`dropdown-item ${
                                      this.state.specializations.includes(
                                        specialization.name
                                      )
                                        ? 'active'
                                        : ''
                                    }`}
                                    to='#'
                                    id={specialization.id}
                                    key={`specializations_${index}`}
                                    onMouseUp={(e) => {
                                      this.setState(
                                        (prevState) => ({
                                          specializations: prevState.specializations.includes(
                                            specialization.name
                                          )
                                            ? prevState.specializations.filter(
                                                (value) =>
                                                  value !== specialization.name
                                              )
                                            : [
                                                ...prevState.specializations,
                                                specialization.name,
                                              ],
                                        }),
                                        () => {
                                          this.manageFilter('specializations');
                                        }
                                      );
                                    }}
                                  >
                                    {specialization.name}
                                  </Link>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </li>
                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle'
                        to='#'
                        id='navbarDropdown'
                        role='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        Gender
                      </Link>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <div className='filter-drop-height'>
                          <Link
                            className={`dropdown-item ${
                              this.state.gender === 'M' ? 'active' : ''
                            }`}
                            to='#'
                            onMouseUp={(e) =>
                              this.setState({ gender: 'M' }, () => {
                                this.manageFilter('gender');
                              })
                            }
                          >
                            Male
                          </Link>
                          <Link
                            className={`dropdown-item ${
                              this.state.gender === 'F' ? 'active' : ''
                            }`}
                            to='#'
                            onMouseUp={(e) =>
                              this.setState({ gender: 'F' }, () => {
                                this.manageFilter('gender');
                              })
                            }
                          >
                            Female
                          </Link>
                        </div>
                      </div>
                    </li>
                    {/* <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle'
                        to='#'
                        id='navbarDropdown'
                        role='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        Video Visit
                      </Link>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <div className='filter-drop-height'>
                          <Link className='dropdown-item' to='#'>
                            Action
                          </Link>
                          <Link className='dropdown-item' to='#'>
                            Another action
                          </Link>
                          <Link className='dropdown-item' to='#'>
                            Something else here
                          </Link>
                        </div>
                      </div>
                    </li> */}
                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle'
                        to='#'
                        id='navbarDropdown'
                        role='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        Reviews
                      </Link>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='navbarDropdown'
                      >
                        <div className='filter-drop-height'>
                          <Link className='dropdown-item' to='#'>
                            Action
                          </Link>
                          <Link className='dropdown-item' to='#'>
                            Another action
                          </Link>
                          <Link className='dropdown-item' to='#'>
                            Something else here
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li
                      className={`nav-item float-right ${
                        listType === 'grid' ? 'select-view' : ''
                      }`}
                    >
                      <Link
                        className='nav-link'
                        to='#'
                        onClick={(e) => this.setState({ listType: 'grid' })}
                      >
                        <i className='fas fa-th'></i>
                      </Link>
                    </li>
                    <li
                      className={`nav-item float-right ${
                        listType === 'row' ? 'select-view' : ''
                      }`}
                    >
                      <Link
                        className='nav-link'
                        to='#'
                        onClick={(e) => this.setState({ listType: 'row' })}
                      >
                        <i className='fas fa-th-list'></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {listType === 'row' && this.ListViewListing()}
              {listType === 'grid' && this.GridListing()}
            </div>
            <ListingSideBar />
          </div>
        </div>
        {this.state.displayPopup ? (
          <Appoinment onCloseHandler={this.onCloseHandler} />
        ) : (
          ''
        )}
      <LiveStreamPlayer video={this.state.liveStream} onCloseStreamModal={this.onCloseStreamModal}/>
      </div>
    );
  }
  ListViewListing = () => {
    let { totalItems } = this.state;
    return (
      <>
        {this.state.pageOfItems &&
          this.state.pageOfItems.map((val, i) => {
            let review_data = val.review ? val.review[0].average : 0;
            return (
              <div className='doctor-list-box' key={`hii_${i}`}>
                <div className='row list-repeat-row'>
                  <div className='col-lg-2 col-md-5  doctor-list-img text-center'>
                    <Link to='#'>
                      <img
                        src={`${
                          val.profile_pic
                            ? bucketPath + val.profile_pic
                            : 'assets/img/img-01.jpg'
                        }`}
                        alt='Img'
                      />
                    </Link>
                    <ul className='star-ul rating'>
                      <li>
                        {[...Array(5).keys()].map((value, i) => {
                          return (
                            <i
                              key={`star_${i}`}
                              className={`fas fa-star ${
                                value + 1 <= Math.round(review_data)
                                  ? 'filled'
                                  : ''
                              }`}
                            ></i>
                          );
                        })}
                      </li>
                      <li>
                        <Link to="#" className='rating-count' onClick={()=>this.ReviewHandler(val.uniquekey)}>
                          {val.review &&
                            val.review.map((value, index) => {
                              return <React.Fragment key={`frags_${index}`}>{`(${value.total} reviews)`} </React.Fragment>;
                            })}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className='col-lg-7 col-md-7 doctor-detail-box'>
                    <h2 className='dr-name'>
                      <Link to={`/doctor-view/${val.uniquekey}`}>
                        Dr. {val.firstname} {val.lastname}
                      </Link>
                      &nbsp;
                      <Link to='#'>
                        <i className='fas fa-heart'></i>
                      </Link>
                    </h2>
                    <p className='dr-qualification'>
                      {val.degree &&
                        val.degree.map((value, index) => {
                          let comma = val.degree[index + 1] ? ',' : '';
                          return value.toUpperCase() + '' + comma;
                        })}{' '}
                      - {val.specialization_name}
                    </p>
                    <p className='dr-experience'>
                      <i className='fas fa-user-md'></i>
                      {val.experience === 0 ? '>1' : val.experience} years
                      experience (
                      {val.gender === 'M'
                        ? 'Male'
                        : val.gender === 'F'
                        ? 'Female'
                        : 'Trans'}
                      )
                    </p>
                    <p className='dr-location'>
                      <i className='fa fa-map-marker'></i>
                      {val.city},{val.state},{val.country}
                    </p>
                  </div>
                  <div className='col-lg-3 col-md-12 dr-fees-box  text-center align-self-center'>
                    <ul className={`contects-ico active-ico`}>
                      {
                        val.streaming && val.streaming === 'YES' &&
                        <li className='active-ico' style={{paddingLeft: "8px"}}>
                          <Link to='#' onClick={() => this.openStream(val.uniquekey)}>
                            Live
                          </Link>
                        </li>
                      }
                      <li className='active-ico'>
                        <Link to='#'>
                          <i className='fas fa-comment-alt'></i>
                        </Link>
                      </li>
                      <li className='active-ico'>
                        <Link to='#'>
                          <i className='fas fa-phone'></i>
                        </Link>
                      </li>
                      <li className='active-ico'>
                        <Link to='#'>
                          <i className='fas fa-video'></i>
                        </Link>
                      </li>
                    </ul>
                    <Link
                      to='#'
                      className='btn btn-light-black margin-top-10 btn-block'
                      onClick={(e) => this.onClickHandler(val.uniquekey)}
                    >
                      Consult Online
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}

        <div className='container-fluid no-padding'>
          <div className='row pull-right'>
            {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
            <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
              &nbsp;
              {totalItems.length >= 0 ? (
                <Pagination
                  pageSize={10}
                  items={totalItems}
                  onChangePage={this.onChangePage}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  GridListing = () => {
    const { totalItems } = this.state;
    return (
      <div className='grid-view-div'>
        <div className='row list-repeat-row'>
          {this.state.pageOfItems.length > 0
            ? this.state.pageOfItems.map((val, i) => {
                let review_data = val.review ? val.review[0].average : 0;
                return (
                  <div className='col-lg-6 col-md-12' key={i}>
                    <div className='doctor-list-box'>
                      <div className='row'>
                        <div className='doctor-list-img text-center col-md-3 align-self-center'>
                          <Link to='#'>
                            <img
                              src={`${
                                val.profile_pic
                                  ? bucketPath + val.profile_pic
                                  : 'assets/img/img-01.jpg'
                              }`}
                              alt='Img'
                            />
                          </Link>
                        </div>
                        <div className='col-md-9 doctor-detail-box'>
                          <h2 className='dr-name'>
                            <Link to={`/doctor-view/${val.uniquekey}`}>
                              Dr. {val.firstname} {val.lastname}
                            </Link>
                            &nbsp;
                            <Link to='#' className='float-right margin-top-10'>
                              <i className='fas fa-heart'></i>
                            </Link>
                          </h2>
                          <p className='dr-specification '>
                            {val.specialization_name}
                          </p>

                          <ul className='star-ul rating'>
                            <li>
                              {[...Array(5).keys()].map((value, i) => {
                                return (
                                  <i
                                    key={`star_${i}`}
                                    className={`fas fa-star ${
                                      value + 1 <= Math.round(review_data)
                                        ? 'filled'
                                        : ''
                                    }`}
                                  ></i>
                                );
                              })}
                            </li>
                            <li>
                              <Link className='rating-count' to=''>
                                {val.review &&
                                  val.review.map((value, index) => {
                                    return <>{`(${value.total} reviews)`} </>;
                                  })}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='gird-detail-box'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <p className='dr-qualification'>
                              {val.degree &&
                                val.degree.map((value, index) => {
                                  let comma = val.degree[index + 1] ? ',' : '';
                                  return value.toUpperCase() + '' + comma;
                                })}{' '}
                              - {val.specialization_name}
                            </p>
                            <p className='dr-experience'>
                              <i className='fas fa-user-md'></i>
                              {val.experience === 0
                                ? '>1'
                                : val.experience}{' '}
                              years experience (
                              {val.gender === 'M'
                                ? 'Male'
                                : val.gender === 'F'
                                ? 'Female'
                                : 'Trans'}
                              )
                            </p>
                            <p className='dr-location'>
                              <i className='fa fa-map-marker'></i> {val.city},
                              {val.state},{val.country}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12 dr-fees-box  align-self-center no-padding'>
                        <Link
                          className='btn btn-light-black margin-top-10 float-right'
                          onClick={(e) => this.onClickHandler(val.uniquekey)}
                        >
                          Consult Online
                        </Link>
                        <ul className='contects-ico float-right'>
                          <li className='active-ico'>
                            <Link to='#'>
                              <i className='fas fa-comment-alt'></i>
                            </Link>
                          </li>
                          <li className='active-ico'>
                            <Link to='#'>
                              <i className='fas fa-phone'></i>
                            </Link>
                          </li>
                          <li className='active-ico'>
                            <Link to='#'>
                              <i className='fas fa-video'></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
          <div className='container-fluid no-padding'>
            <div className='row pull-right'>
              {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
              <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                &nbsp;
                {totalItems.length >= 0 && (
                  <Pagination
                    pageSize={10}
                    items={totalItems}
                    onChangePage={this.onChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  Doctors: state.user.doctorListing,
  specializations: state.static.specializations,
  home_article: state.user.home_article,
});

export default connect(mapStateToProps)(DoctorListing);
