import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetCounselorAppointmentList } from './../../store/actions/counselorPanelActions'
import { CounselorProfileDetails } from './../../store/actions/globalActions' 
import { counselorWeeklyAppointments } from './../../store/actions/registerationActions'
import Pagination from './../../store/helpers/Pagination'
import swal from "sweetalert";
import { CancelAppointment } from '../../store/actions/userActions';
import CounsellorAppointment from '../modals/CounsellorAppointment' 
import { ShowLoader } from '../../store/actions/staticListingsActions';
import { bucketPath } from '../../store/helpers/common';

const initialState = { currentPage: 1, pageOfItems: [], countDownTime: {}, appointmentCode: '', doctorUniquekey: '' };
const AppointmentIcon = { Chat: 'fas fa-comment-alt', Audio: 'fas fa-phone', Video: 'fas fa-video' }; //Walkin: 'fas fa-walking'
class CounsellorAppointments extends React.Component {

	constructor(props) {
		super(props)
		this.state = initialState;
		this.intervalID = 0;
		this.getData = localStorage.getItem('data')
	}

	componentDidMount() {
		if (this.getData && JSON.parse(this.getData).uniquekey) {
			this.props.dispatch(GetCounselorAppointmentList({ uniquekey: JSON.parse(this.getData).uniquekey }))
			sessionStorage.setItem("key",JSON.parse(this.getData).uniquekey)
		}
		setTimeout(() => {
			this.props.dispatch(ShowLoader(false));
		}, 1000)
	}

	onChangePage = (pageOfItems, page) => {
		clearInterval(this.intervalID)
		this.intervalID = 0
		this.setState({ pageOfItems: pageOfItems, currentPage: page },()=>{
			this.intervalID = setInterval(()=>{
				this.setState({ pageOfItems: pageOfItems, currentPage: page });
			},1000)
		})

	};
	// componentDidUpdate(prevProps, prevState) {
	// 	if (prevState.pageOfItems !== this.state.pageOfItems) {
	// 		clearInterval(this.intervalID);
	// 		this.intervalID = 0
	// 		this.countDownTimer(this.state.pageOfItems)
	// 	}
	// }

	// countDownTimer = (appointments) => {
	// 	this.intervalID = this.intervalID === 0 && setInterval(() => {
	// 		const now = new Date().getTime();
	// 		let timerStatus = [];
	// 		appointments.map(appointment => {
	// 			let countDownDate = new Date(appointment.appointment_date_display).getTime();
	// 			const distance = countDownDate - now;
	// 			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
	// 			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	// 			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	// 			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

	// 			if (distance <= 0 && distance > -900000) {
	// 				timerStatus[appointment.appointmentID] = 'START';
	// 			} else if (distance <= -900000) {
	// 				timerStatus[appointment.appointmentID] = 'EXPIRED';
	// 			} else if (distance > 0 && distance < 3600000) {
	// 				if (!days && hours && minutes && seconds) {
	// 					timerStatus[appointment.appointmentID] = hours + "hours " + minutes + "mins " + seconds + "secs";
	// 				} else if (!days && !hours && minutes && seconds) {
	// 					let text = minutes > 1 ? " mins " : " min "
	// 					timerStatus[appointment.appointmentID] = minutes + text + seconds + "secs";
	// 				}
	// 			} else {
	// 				timerStatus[appointment.appointmentID] = appointment.appointment_date_display;
	// 			}
	// 			return true
	// 		})
	// 		this.setState({ countDownTime: { ...this.state.countDownTime, ...timerStatus } })
	// 	}, 1000);

	// 	setTimeout(() => {
	// 		this.props.dispatch(ShowLoader(false));
	// 	}, 1000)
	// }

	countDownTimer = (appointment_date_display,starttime) => {
		let timerStatus = "";
		const now = new Date().getTime();
		let countDownDate = new Date(appointment_date_display).getTime();
		const distance = countDownDate - now;
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if (distance <= 0 && distance > -900000) {
			timerStatus = 'START';
		} else if (distance <= -900000) {
			timerStatus = 'EXPIRED';
		} else if (distance > 0 && distance < 3600000) {
			if (!days && hours && minutes && seconds) {
				timerStatus = hours + "hours " + minutes + "mins " + seconds + "secs";
			} else if (!days && !hours && minutes && seconds) {
				let text = minutes > 1 ? " mins " : " min "
				timerStatus = minutes + text + seconds + "secs";
			} else if (!days && !hours && !minutes && seconds) {
				let text = seconds > 1 ? " secs " : " sec "
				timerStatus = seconds + text; 
			}
		} else { 
			timerStatus = appointment_date_display;
		}
		return timerStatus;
	}

	SetName = (name, type = "Audio", counsellor_uniquekey, appointment_code) => {
		sessionStorage.setItem('_caller_name', name);
		sessionStorage.setItem('_call_type', type);
		sessionStorage.setItem('_counsellor_uniquekey', counsellor_uniquekey);
		sessionStorage.setItem('_appointment_code', appointment_code);
	}

	handleCancel = (appointmentCode) => {
		swal({
			title: "Are you sure want to cancel the appointment?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then(async (willDelete) => {
				if (willDelete) {
					const data = { appointment_code: appointmentCode };
					const res = await this.props.dispatch(CancelAppointment(data));
					if (res === 1) {
						let getId = sessionStorage.getItem("key")
						this.props.dispatch(GetCounselorAppointmentList({ uniquekey: getId }));
					}
				}
			});
	}

	GetAppointment = (counselorUniquekey, appointmentCode) => {
		this.props.dispatch(CounselorProfileDetails(counselorUniquekey));
		this.props.dispatch(counselorWeeklyAppointments({ uniquekey: counselorUniquekey }));
		this.setState({ appointmentCode: appointmentCode }, () => {
			window.$('#bookAppointmentListing').modal({ show: true, backdrop: "static" });
		});
	}

	updateAppointmentList = () => {
		let getId = sessionStorage.getItem("key")
		if (getId) {
			this.props.dispatch(GetCounselorAppointmentList({ uniquekey: getId }))
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
		this.intervalID = 0
	}

	render() {
		const { appointments } = this.props;
		const { pageOfItems } = this.state; 

		return (
			<div>
				{pageOfItems.map((value, index) => {
					let duration = value.duration === '0' || value.duration===0 ? '00:00' : value.duration;
					duration = duration.split(":")
					duration = duration[2] && duration[1]?`${duration[1]}:${duration[2]}`:"00:00";
					const currentStatus = this.countDownTimer(value.appointment_date_display,value.starttime);
					return (
						<div className="appointments" key={`index_${index}`}>
							<div className="appointment-list">
								<div className="profile-info-widget">
									<Link to={`/counsellor/patient-profile/${value.uniquekey}`} className="booking-doc-img">
										<img
											src={`${value.patient_profile_pic? bucketPath + value.patient_profile_pic : "./../assets/doctor/img/patients/patient.jpg"}`}
											alt="User"
										/>
									</Link>
									<div className="profile-det-info">
										<h3><Link to={`/counsellor/patient-profile/${value.uniquekey}`}>{`${value.patient_name}`}</Link></h3>
										<div className="patient-details">
											<h5><i className="fas fa-calendar-day"></i> Appointment date : {value.appointment_date}</h5>
											<h5><i className="far fa-clock"></i> Appointment time : {value.starttime} - {value.endtime}</h5>
											<h5><i className="far fa-clock"></i> Duration : {value.status === 'F'?duration:"NA"}</h5> 
										</div>
									</div>
								</div>
								{value.status === 'S' && <div className='appointment-action'>
									<h5>{`Appointment cancelled by ${value.updatedBy === sessionStorage.getItem("key") ? 'you.' : 'patient.'}`}</h5>
								</div>
								}
								{value.status === 'F' && <div className='appointment-action'>
									<h5>{`Appointment Completed`}</h5>
								</div>
								}
								{value.status === 'C' && <div className="appointment-action">
									<h5>{' '}
										{
											currentStatus !== 'START'
											&& currentStatus !== 'EXPIRED'
											&& currentStatus === value.appointment_date_display
											&& 'You had an appointment on'
										}&nbsp;
										{
											currentStatus !== 'START'
											&& currentStatus !== 'EXPIRED'
											&& currentStatus !== value.appointment_date_display
											&& 'You\'re appointment will start in '
										}&nbsp;
										{/* <br /> */}
										{
											currentStatus !== 'START'
											&& currentStatus
										}
									</h5>
									{currentStatus === 'START'
										&& <Link
											to={value.appointment_type === `Chat` ? `/chat-messages` : `/chat-appointment/${value.appointment_code}/${value.uniquekey}`}
											target="_blank"
											className="btn btn-sm bg-success-light"
											onClick={e => this.SetName(
												value.patient_name,
												value.appointment_type,
												sessionStorage.getItem("key"),
												value.appointment_code
											)}
										>
											<i className={AppointmentIcon[value.appointment_type] ? AppointmentIcon[value.appointment_type] : ''}></i>
											{value.appointment_type !== 'Chat' ? ` Make ${value.appointment_type} Call` : ` Start Chat`}
									</Link>}
									{currentStatus !== 'START'
										&& currentStatus !== 'EXPIRED'
										&& <Link
										to={value.appointment_type === `Chat` ? `/chat-messages` : `/chat-appointment/${value.appointment_code}/${value.uniquekey}`}
										target="_blank"
											className="btn btn-sm bg-warning-light"
										>
											<i className={AppointmentIcon[value.appointment_type] ? AppointmentIcon[value.appointment_type] : ''}></i>
											{value.appointment_type !== 'Chat' ? ` Make ${value.appointment_type} Call` : ` Start Chat`}
									</Link>}
									{
										currentStatus !== 'EXPIRED'
										&& (
											<>
												<button className="btn btn-sm bg-primary-light" onMouseUp={() => this.GetAppointment(sessionStorage.getItem("key"), value.appointment_code)}><i className="fas fa-calendar-day"></i> Reschedule</button>
												<button className="btn btn-sm bg-danger-light" onMouseUp={() => this.handleCancel(value.appointment_code)}><i className="fas fa-calendar-times"></i> Cancel</button>
												<Link to='/support' className="btn btn-sm bg-info-light"><i className="fas fa-comment-alt"></i> Chat Support</Link>
											</>
										)
									}
								</div>
								}
							</div>
						</div>
					)
				})}
				<div className="container-fluid no-padding">
					<div className="row pull-right">
						<div className="col-md-12 col-sm-12 col-xs-12 pagination ">
							&nbsp;
                                    {appointments && appointments.length > 0 ? (
								<Pagination
									pageSize={10}
									items={appointments}
									onChangePage={this.onChangePage}
								/>
							) : (
									""
								)}
						</div>
					</div>
				</div>

				<div className="modal fade loginModal-div" id="unavailable" tabIndex="-1" role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" id="closeUserreg" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body login-sec-modal">
								<div className="unavailable-inner-div text-center">
									<div className="col-md-12">
										<h5>Sorry for inconvenience</h5>
										<h6>Seems Charlene Reed is not available to receive call.</h6>
									</div>
									<div className="col-md-12 unavailable-div">
										<Link to="#" className="btn btn-grey-border">Cancel Appointment</Link>
                                    &nbsp;
                                    <Link to="#" className="btn btn-light-black">Contact Support</Link>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>

				<CounsellorAppointment appointmentCode={this.state.appointmentCode} updateAppointmentList={this.updateAppointmentList} />
			</div>
		)
	}
}
const mapStateToProps = state => ({
	profile_info: state.user.profile_info,
	appointments: state.counselorPanel.counsellor_appointment_listing?.filter(value => value.status === "C" || value.status === "F" || value.status === "S"),
});

export default connect(mapStateToProps)(CounsellorAppointments);