import React from 'react';
import { connect } from 'react-redux';
import Pagination from './../../store/helpers/Pagination';
import { getAdminLabListing } from './../../store/actions/adminActions';

class AdminLabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalItems: [],
      pageOfItems: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getAdminLabListing());
  }

  componentDidUpdate = (prevProps, prevState) => {
    let nextProps = this.props;

    let {
      filter: { admin_global_search },
    } = nextProps;

    if (nextProps.LabListing !== prevProps.LabListing || admin_global_search !== prevProps.filter.admin_global_search) {
      this.setState({
        totalItems: this.filteredDataFxn()
      },()=>{
        this.onChangePage(this.state.totalItems,1)
      });
    }
  };

  filteredDataFxn = () => {
    const {
      LabListing,
      filter: { admin_global_search },
    } = this.props;

    if (admin_global_search) {
      return (
        LabListing &&
        LabListing.filter((val) => {
          return (
            val.firstname
              .toString()
              .toLowerCase()
              .search(admin_global_search.toString().toLowerCase()) !== -1 ||
            val.lastname
              .toString()
              .toLowerCase()
              .search(admin_global_search.toString().toLowerCase()) !== -1
          );
        })
      );
    } else {
      return LabListing;
    }
  };

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='datatable table table-hover table-center mb-0'>
                    <thead>
                      <tr>
                        <th>Lab ID</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pageOfItems.length > 0 ? (
                        this.state.pageOfItems.map((val, i) => (
                          <tr key={i}>
                            <td>{val.uniquekey}</td>
                            <td>
                              {val.firstname} {val.lastname}
                            </td>
                            <td>{val.position_held}</td>
                            <td>{val.email}</td>
                            <td>{val.contact_number}</td>
                            <td>
                              {val.gender === 'M'
                                ? 'Male'
                                : val.gender === 'F'
                                ? 'Female'
                                : val.gender === 'T'
                                ? 'Transgender'
                                : ''}
                            </td>
                            <td>{val.city}</td>
                            <td>{val.state}</td>
                            <td>
                              {val.status === 'A' ? (
                                <div className='status-toggle'>
                                  <input
                                    type='checkbox'
                                    id='status_1'
                                    className='check'
                                    checked
                                    readOnly
                                  />
                                  <label
                                    htmlFor='status_1'
                                    className='checktoggle'
                                  >
                                    checkbox
                                  </label>
                                </div>
                              ) : val.status === 'D' ? (
                                <div className='status-toggle'>
                                  <input
                                    type='checkbox'
                                    id='status_1'
                                    className='check'
                                    readOnly
                                  />
                                  <label
                                    htmlFor='status_1'
                                    className='checktoggle'
                                  >
                                    checkbox
                                  </label>
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No records available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='container-fluid no-padding'>
                  <div className='row pull-right'>
                    {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                    {/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
                    <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                      &nbsp;
                      {this.state.pageOfItems.length > 0 ? (
                        <Pagination
                          pageSize={20}
                          items={this.state.totalItems}
                          onChangePage={this.onChangePage}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  LabListing: state.admin.adminLabListing.data,
  filter: state.filter,
});

export default connect(mapStateToProps)(AdminLabs);
