import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { initOptions } from './conferencePanel/Options';
import { jitsiConnection } from './conferencePanel/Connection';
import { jitsiRoom, manageRoomEvents, createLocalTrack, manageRoomLeave, changeDeviceTrack } from './conferencePanel/Room';
import swal from "sweetalert";
import { CancelAppointment } from '../store/actions/userActions';
import { DoctorProfileDetails } from '../store/actions/globalActions';
import { WeeklyAppointments } from '../store/actions/registerationActions';
import Appoinment from './modals/Appoinment';
import axios from 'axios';
import { apiBaseUrl } from '../store/helpers/common';

class CounsellorChatMessages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            connection: null,
            isJoined: false,
            room: null,
            localTracks: [],
            remoteTracks: {},
            remoteUsersDetails: {},
            isAudioMuted: false,
            isVideoMuted: false,
            cameraDeviceId: '',
            micDeviceId: '',
            roomName: 'testing',
            displayName: 'Guest',
            userDisplayName: 'Guest',
            JitsiMeetJS: null,
            openModal: false,
            message: '',
            isAudioOnly: true,
            duration: '00:00:00',
            showSupportButtons: false,
            recordingSessionId: null
        };
        this.isRecordingStarted = false;
    }

    UNSAFE_componentWillMount() {
        let callerType = sessionStorage.getItem('_call_type');
        let callerName = sessionStorage.getItem('_caller_name');
        let doctorUniqueKey = sessionStorage.getItem('_doctor_uniquekey');
        let appointmentCode = sessionStorage.getItem('_appointment_code');


        if (doctorUniqueKey && appointmentCode) {
            if (callerName) {
                this.CallerName = callerName;
                this.setState({ displayName: callerName });
            } else {
                this.CallerName = 'Guest';
            }
            if (callerType) {
                this.setState({ isAudioOnly: callerType === "Video" ? false : true })
            }
            this.doctorUniqueKey = doctorUniqueKey;
            this.appointmentCode = appointmentCode;
            this.setState({ roomName: `tll_${appointmentCode.toLowerCase()}` })

        } else {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        const checkJitsiLoadInterval = setInterval(() => {
            if (window.JitsiMeetJS) {
                window.JitsiMeetJS.init(initOptions);
                this.setState({ connection: jitsiConnection(window.JitsiMeetJS), JitsiMeetJS: window.JitsiMeetJS }, () => {
                    createLocalTrack(this);
                    this.state.connection.addEventListener(this.state.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, () => {
                        this.handleClick();
                    });
                });
                clearInterval(checkJitsiLoadInterval);
            }
        }, 100);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleClick = () => {
        const { connection, roomName } = this.state;
        let room = jitsiRoom(connection, roomName);
        // event.target.innerHTML = 'Please wait...';
        this.setState({ room }, () => {
            manageRoomEvents(this)
        });
    }


    AudioMuteUnmute = (event) => {
        let element = event.target;
        this.state.isAudioMuted
            ? this.setState({ isAudioMuted: false }, () => this.manageAudioMuted(element))
            : this.setState({ isAudioMuted: true }, () => this.manageAudioMuted(element))
    }

    manageAudioMuted = (element) => {
        let { localTracks, isAudioMuted } = this.state;
        localTracks.forEach((track, index) => {
            if (track.type === "audio" && !track.videoType) {
                isAudioMuted
                    ? localTracks[index].mute()
                        .then(d => {
                            element.classList.remove("fa-microphone");
                            element.classList.add("fa-microphone-slash");
                            // element.innerHTML = `<i className="fas fa-microphone-slash bottom-call-icon"></i>`;
                        })
                        .catch(err => console.log("Audio mute Error", err))
                    :
                    localTracks[index].unmute().then(d => {
                        element.classList.add("fa-microphone");
                        element.classList.remove("fa-microphone-slash");
                        // element.innerHTML = `<i className="fas fa-microphone bottom-call-icon">Unmute</i>`;
                    })
                        .catch(err => console.log("Audio mute Error", err))
            }
        })
    }

    VideoMuteUnmute = (event) => {
        let element = event.target;
        this.state.isVideoMuted
            ? this.setState({ isVideoMuted: false }, () => this.manageVideoMuted(element))
            : this.setState({ isVideoMuted: true }, () => this.manageVideoMuted(element))
    }

    manageVideoMuted = (element) => {
        let { localTracks, isVideoMuted } = this.state;
        localTracks.forEach((track, index) => {
            if (track.videoType === "camera") {
                isVideoMuted
                    ? localTracks[index].mute()
                        .then(d => {
                            element.classList.remove("fa-video");
                            element.classList.add("fa-video-slash");
                        })
                        .catch(err => console.log("Video mute Error", err))
                    :
                    localTracks[index].unmute().then(d => {
                        element.classList.add("fa-video");
                        element.classList.remove("fa-video-slash");
                    })
                        .catch(err => console.log("Video mute Error", err))
            }
        })
    }

    handleLeave = () => {
        manageRoomLeave(this);
    }

    openChat = () => {
        this.setState({ openModal: true })
    }

    closeChat = () => {
        this.setState({ openModal: false })
    }

    handleSubmit = () => {
        if (this.state.message) {
            this.sendMessage(this.state.message);
            this.setState({ message: '' });
        }
    }

    sendMessage = (message) => {
        this.state.room.sendTextMessage(message);
    }

    changeVideoIntput = (event) => {
        this.setState({ cameraDeviceId: event.target.value }, () => {
            changeDeviceTrack(this);
        });
    }

    changeAudioInput = (event) => {
        console.log(event.target.value);
        this.setState({ micDeviceId: event.target.value }, () => {
            changeDeviceTrack(this);
        });
    }

    changeAudioOutput = (event) => {
        this.state.JitsiMeetJS.mediaDevices.setAudioOutputDevice(event.target.value);
    }

    componentWillUnmount() {
        this.CallerName = "";
        this.doctorUniqueKey = "";
        this.appointmentCode = "";
        sessionStorage.removeItem('_caller_name');
        sessionStorage.removeItem('_doctor_uniquekey');
        sessionStorage.removeItem('_appointment_code');
    }

    onUserLeave = async (id) => {
        // alert(`${id} === ${this.state.room.myUserId()}`);
        let localId = this.state.room.myUserId();
        if (id && localId) {
            try {
                const postData = {
                    method: "post",
                    url: `${apiBaseUrl}/api/finish-appointments`,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": `${localStorage.getItem("token")}`,
                    },
                    data: JSON.stringify({ appointment_code: this.appointmentCode, duration: this.state.duration }),
                };

                const response = await axios(postData);
                if (response.data.ResponseCode === "1") {
                    this.state.room.leave().then(() => {
                        this.state.connection.disconnect();
                        window.location.href = "/";
                    })
                }
            } catch (error) {
                console.log("Appointment complete error ==> ", error);
            }
        } else {
            this.state.room.leave().then(() => {
                this.state.connection.disconnect();
                window.location.href = "/";
            })
        }
    }

    handleCancel = (appointmentCode) => {
        swal({
            title: "Are you sure want to cancel the appointment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const data = { appointment_code: appointmentCode };
                    const res = await this.props.dispatch(CancelAppointment(data));
                    if (res === 1) {
                        this.props.history.push('/');
                    }
                }
            });
    }

    GetAppointment = (doctorUniquekey, appointmentCode) => {
        this.props.dispatch(DoctorProfileDetails(doctorUniquekey));
        this.props.dispatch(WeeklyAppointments({ uniquekey: doctorUniquekey }));
        this.setState({ appointmentCode: appointmentCode }, () => {
            window.$('#bookAppointmentListing').modal({ show: true, backdrop: "static" });
        });
    }

    updateAppointmentList = () => {
        this.props.history.push('/');
    }

    toggleRecording = () => {
        swal({
            title: `Do you want to ${this.state.recordingSessionId ? 'Stop' : 'start'} recording?`,
            // icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const appData = JSON.stringify({
                        'file_recording_metadata': {
                            'share': true
                        }
                    });
                    const recordingOption = {
                        appData,
                        mode: "file"
                    };

                    if (!this.state.recordingSessionId) {
                        this.state.room.startRecording(recordingOption).then(response => {
                            Object.keys(response).forEach(key => {
                                if (key === '_sessionID') {
                                    this.setState({ recordingSessionId: response._sessionID }, () => {
                                        console.log("TOGGLE RECORDING STATE ============ ", this.state.recordingSessionId);
                                    });
                                }
                            });
                        });
                    } else {
                        const stoptRecording = this.state.room.stopRecording(this.state.recordingSessionId);
                        stoptRecording.then(response => {
                            console.log("RECORDING Response =========> ", response);
                            this.setState({ recordingSessionId: null });
                        })
                            .catch(error => {
                                console.log("RECORDING ERROR =========> ", error);
                            })
                    }
                }
            });

    }

    render() {
        const { isAudioOnly } = this.state;
        // alert(this.props.caller_name_info)
        return (
            <div>
                <audio id="start-recording">
                    <source src="assets/audio/recordingOn.mp3" type="audio/mp3" />
                </audio>
                <audio id="stop-recording">
                    <source src="assets/audio/recordingOff.mp3" type="audio/mp3" />
                </audio>
                {!this.state.showSupportButtons && <> <div className="main-wrapper">
                    <div className="content message-window-content">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="chat-window">
                                    <div className=" chat-cont-right chat-cont-right-1">
                                        <div className="chat-header">
                                            <Link to="#" id="back_user_list" className="back-user-list">
                                                <i className="material-icons">chevron_left</i>
                                            </Link>
                                            <div className="media">
                                                <div className="media-img-wrap">
                                                    <div className="avatar avatar-online">
                                                        <img src="assets/img/patients/patient.jpg" alt="User"
                                                            className="avatar-img rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div className="user-name">{this.CallerName ? this.CallerName : this.state.userDisplayName}</div>
                                                    {/* <div className="user-status">online</div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chat-body">
                                            <div className="chat-scroll">
                                                <ul className="list-unstyled" id="message-container">

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="chat-footer" style={{ "display": this.state.isJoined ? "block" : "none" }}>
                                            <div className="input-group">
                                                {/* <div className="input-group-prepend">
                                                        <div className="btn-file btn">
                                                            <i className="fa fa-paperclip"></i>
                                                            <input type="file" />
                                                        </div>
                                                    </div> */}
                                                <input
                                                    type="text"
                                                    className="input-msg-send form-control"
                                                    id="message"
                                                    name="message"
                                                    onKeyDown={(event) => { if (event.key === "Enter") { this.handleSubmit() } }}
                                                    placeholder="Type something"
                                                    value={this.state.message}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="input-group-append">
                                                    <button type="button" className="btn msg-send-btn" onClick={this.handleSubmit}><i className="fab fa-telegram-plane"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-cont-right chat-cont-right-2">
                                        <div className="chat-body">
                                            <div className="audio-call-screen">
                                                <div className="col-md-12 align-self-center">
                                                    <div className="audio-call-inner">

                                                        <div className={`top-bar col-md-12 ${isAudioOnly ? `audio-call-section` : `video-call-section`}`}>
                                                            <div id="remote-container" className="text-center">
                                                                <div className="local-container" style={{ position: "absolute" }}>
                                                                    <video style={{ width: "20%", float: "right", height:"auto" }} id="localVideo" autoPlay={true}></video>
                                                                    <audio id="localAudio" autoPlay={true} muted={true}></audio>
                                                                </div>
                                                                {this.state.isJoined && <p id="duration">{this.state.duration}</p>}
                                                                <p id="wait-message">Please wait for another user to join call</p>
                                                            </div>
                                                            {/* <a href={void(0)} className="duration">
                                                                    00:00:00
                                                                </a>
                                                                <div className="second-party float-right">
                                                                </div> */}
                                                        </div>
                                                        {/* <div className="avatar-audio">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className=" rounded-circle" />
                                                            </div> */}
                                                        <div className="calling-ico button-container" style={{ "display": this.state.isJoined ? "block" : "none" }}>
                                                            <Link to={void (0)} onMouseUp={this.AudioMuteUnmute}><i className="fas fa-microphone bottom-call-icon"></i></Link>
                                                            {!isAudioOnly && <Link to={void (0)} onMouseUp={this.VideoMuteUnmute}><i className="fas fa-video  bottom-call-icon"></i></Link>}
                                                            <Link to={void (0)} onClick={this.handleLeave}><i className="call-end"><img src="assets/img/call.png" alt="..."/> </i> </Link>
                                                            {/* <a href={void (0)} onClick={this.toggleRecording}><i className={`far fa-dot-circle bottom-call-icon ${this.state.recordingSessionId ? 'text-red' : ''}`}></i></a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="modal fade call-modal" id="voice_call">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="call-box incoming-box">
                                        <div className="call-wrapper">
                                            <div className="call-inner">
                                                <div className="call-user">
                                                    <img alt="User" src="assets/img/patients/patient.jpg" className="call-avatar" />
                                                    <h4>Richard Wilson</h4>
                                                    <span>Connecting...</span>
                                                </div>
                                                <div className="call-items">
                                                    <Link to={void (0)} className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons">call_end</i></Link>
                                                    <Link to="voice-call.html" className="btn call-item call-start"><i className="material-icons">call</i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade call-modal" id="video_call">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="call-box incoming-box">
                                        <div className="call-wrapper">
                                            <div className="call-inner">
                                                <div className="call-user">
                                                    <img className="call-avatar" src="assets/img/patients/patient.jpg" alt="User" />
                                                    <h4>Richard Wilson</h4>
                                                    <span>Calling ...</span>
                                                </div>
                                                <div className="call-items">
                                                    {!isAudioOnly && <Link to="#" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons">call_end</i></Link>}
                                                    <a href="video-call.html" className="btn call-item call-start"><i className="material-icons">videocam</i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
                {this.state.showSupportButtons
                    && <div className="support-button" style={{ position: "fixed", top: "40%", left: "38%", textAlign: "center" }}>
                        <h1>Call couldn't be connected. Do you want to</h1>
                        <br />
                        <button style={{ marginRight: '10px' }} className="btn btn-lg bg-primary-light" onMouseUp={() => this.GetAppointment(this.doctorUniqueKey, this.appointmentCode)}><i className="fas fa-calendar-day"></i> Reschedule</button>
                        <button style={{ marginRight: '10px' }} className="btn btn-lg bg-danger-light" onMouseUp={() => this.handleCancel(this.appointmentCode)}><i className="fas fa-calendar-times"></i> Cancel</button>
                        <Link to='/support' className="btn btn-lg bg-info-light"><i className="fas fa-comment-alt"></i> Chat Support</Link>
                    </div>}
                <Appoinment appointmentCode={this.appointmentCode} updateAppointmentList={this.updateAppointmentList} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    caller_name_info: state.global.caller_name_info
});

export default connect(mapStateToProps)(CounsellorChatMessages);
