import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Registration from './modals/Registration';
import { withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { login, submitQuery, ForgotPasswordApi, profileInfo, getDoctorList, getCounsellorList } from '../store/actions/userActions';
import { BookAppointment } from '../store/actions/registerationActions';
import { BookFitnessExpertClass } from '../store/actions/fitnessActions';
import { specializations } from '../store/actions/staticListingsActions';
import { NearbyLocations,NearbyDoctorCouncellor,FetchLocations,FetchLatLongs,SearchedDoctorCouncellor } from '../store/actions/locationAction';
import { globalUrl } from '../store/helpers/globalUrls';
import Geocode from 'react-geocode';
import $ from 'jquery';
import { auth, storelocation, productModal, GlobalProductList } from '../store/actions/globalActions';
import {SetLocalExpiry,GetLocalWithExpiry} from '../store/helpers/timeIntervals';
import { removeProductCart } from '../store/actions/productActions';
import { bucketPath } from '../store/helpers/common';
import swal from 'sweetalert';
import { forceloginClick } from '../store/actions/patientActions';

Geocode.setApiKey('AIzaSyApUSUxWd-PVCrqVfP7QPClKRHAwbL-nVY');
const globalUrls = globalUrl();
const initialState = {
  tabname: '',
  isLogin: false,
  email: '',
  password: '',
  username: '',
  icon: false,
  title: 'Mr',
  firstname: '',
  lastname: '',
  phone: '+91',
  message: '',
  query_mail: '',
  ForgotPassword: false,
  DoctorSearching: '',
  LocationSearching: '',
  rememberMe: false,
  userPasswordHidden: true,
  doctorPasswordHidden: true,
  providerLoginValue: 'CounsellorLogin',
  counsellorPasswordHidden: true,
  hospitalPasswordHidden: true,
  labPasswordHidden: true,
  pharmacyPasswordHidden: true,
  fitnessPasswordHidden: true,
  passwordtypinginfoshow: true,
  open: false,
  userModalValue: '',
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.total = 0;
    this.storageToken = localStorage.getItem('token');
    this.storageData = localStorage.getItem('data');
    this.storageType = localStorage.getItem('type');
    this.validator = new SimpleReactValidator();
    this.queryValidator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.props.dispatch(specializations());
    this.props.dispatch(getDoctorList());
    this.props.dispatch(getCounsellorList());
    this.props.dispatch(GlobalProductList());
    let currentPath = this.props.match.path;
    if (this.storageToken && this.storageData && this.storageToken !== '' && this.storageToken !== '') {
      this.props.dispatch(profileInfo());
      this.setState(
        {
          isLogin: true,
          tabname: '',
        },
        () => {
          this.props.dispatch(auth(this.state.isLogin));
        }
      );
    } else {
      this.setState(
        {
          isLogin: false,
        },
        () => {
          let splitter = currentPath.split('/');
          if (!globalUrls.includes(splitter[1])) {
            this.props.history.push('/');
          }
          localStorage.removeItem('token');
          localStorage.removeItem('data');
          localStorage.removeItem('type');
          if (sessionStorage.getItem('_user_details')) {
            let userDetails = JSON.parse(sessionStorage.getItem('_user_details'));
            if (userDetails.username && userDetails.password) {
              this.setState({
                email: userDetails.username,
                password: userDetails.password,
                rememberMe: true,
              });
            }
          }
        }
      );
    }
    //   TO FETCH THE LOCATION
    let location = GetLocalWithExpiry('location');
    if(location && location.city){
      this.setState({LocationSearching:location.city},()=>{
        {location.latitude && location.longitude && this.props.dispatch(NearbyLocations({latitude:location.latitude,longitude:location.longitude}))}
        {location.latitude && location.longitude && this.props.dispatch(NearbyDoctorCouncellor({latitude:location.latitude,longitude:location.longitude}))}
      })
    }else{
      this.props.dispatch(NearbyLocations({latitude:'',longitude:''}))
      this.props.dispatch(NearbyDoctorCouncellor({latitude:'',longitude:''}))
    }
  }

  DetectPermission = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state == 'granted') {
          this.getCurrentLocation();
        } else if (result.state == 'prompt') {
          this.getCurrentLocation();
        } else if (result.state == 'denied') {
          alert('User denied location access. Please enable location access to detect current location');
        }
      });
    }
  };

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.getPosition(position),
        (error) => this.showError(error)
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '' && !prevState.isLogin) {
      this.setState(
        {
          isLogin: true,
          tabname: '',
        },
        () => {
          this.props.dispatch(auth(this.state.isLogin));
        }
      );
    }
  }

  getPosition(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
      (response) => {
        const addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray);
        let location = {
          city,
          area,
        };
        SetLocalExpiry('location',{...location,latitude,longitude});
        this.props.dispatch(storelocation(location));
        this.props.dispatch(NearbyLocations({latitude,longitude}))
        this.props.dispatch(NearbyDoctorCouncellor({latitude,longitude}))
        this.setState({ LocationSearching: city });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getArea(addressArray) {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  }

  getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
      default:
        break;
    }
  }

  RedirectOnClick = (e) => {
    this.props.history.push('/');
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps?.force_login_click) {
      window.$('#loginModal1').modal({ show: true, backdrop: 'static' });
      this.props.dispatch(forceloginClick(false));
      this.forceUpdate();
    }
  }

  loginUser = async () => {
    const validator = this.validator;
    const doctorID = sessionStorage.getItem('_booked_process');
    const fitnessID = sessionStorage.getItem('_booked_class');
    const _booking_class_details = sessionStorage.getItem('_booking_class_details');
    const _booking_details = sessionStorage.getItem('_booking_details');
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'U' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal1').modal('hide');
        let type = localStorage.getItem('type');
        if (type === 'user' || type === 'patient') {
          if (doctorID && _booking_details) {
            let response = await this.props.dispatch(BookAppointment(JSON.parse(_booking_details)));
            if (response && response.response === 'success') {
              sessionStorage.removeItem('_booked_process');
              sessionStorage.removeItem('_booking_details');
              this.props.history.push(`/patient/checkout/${response.code}`);
            } else {
              this.props.history.push('/patient/profile-settings');
            }
          } else {
            this.props.history.push('/patient/profile-settings');
          }
          if (fitnessID && _booking_class_details) {
            let response = await this.props.dispatch(BookFitnessExpertClass(JSON.parse(_booking_class_details)));
            if (response && response.response === 'success') {
              sessionStorage.removeItem('_booked_class');
              sessionStorage.removeItem('_booking_class_details');
              this.props.history.push(`/fitnessCheckout/${response.code}`);
            } else {
              this.props.history.push('/patient/profile-settings');
            }
          } else {
            this.props.history.push('/patient/profile-settings');
          }
        }
      }
    } else {
      this.setState({ isLogin: false }, () => {
        this.props.dispatch(auth(this.state.isLogin));
      });
      validator.showMessages();
      this.setState({ passwordtypinginfoshow: false });
      this.forceUpdate();
    }
  };

  loginDoctor = async () => {
    const validator = this.validator;
    //  const doctorID = sessionStorage.getItem("_booked_process");
    //const _booking_details = sessionStorage.getItem("_booking_details");
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'D' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal2').modal('hide');
        let type = localStorage.getItem('type');
        let payment_status = localStorage.getItem('payment_status');
        if (type === 'doctor') {
          let redirectURL = payment_status === 'Y' ? '/doctor/dashboard' : '/doctor/profile-setting';
          this.props.history.push(redirectURL);
        }
      }
    } else {
      this.setState({ isLogin: false }, () => {
        this.props.dispatch(auth(this.state.isLogin));
      });
      validator.showMessages();
      this.setState({ passwordtypinginfoshow: false });
      this.forceUpdate();
    }
  };

  loginProviderCounseller = async () => {
    const validator = this.validator;
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'CR' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal3').modal('hide');
        let type = localStorage.getItem('type');
        //let payment_status = localStorage.getItem("payment_status");
        if (type === 'counselor') {
          let redirectURL = '/counsellor/profile-setting'; //payment_status === "Y" ? "/doctor/dashboard" : "/counsellor/profile-setting";
          this.props.history.push(redirectURL);
        }
      } else {
        this.setState({ isLogin: false }, () => {
          this.props.dispatch(auth(this.state.isLogin));
        });
        validator.showMessages();
        this.setState({ passwordtypinginfoshow: false });
        this.forceUpdate();
      }
    }
  };

  loginProviderHospital = async () => {
    const validator = this.validator;
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'H' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal3').modal('hide');
        let type = localStorage.getItem('type');
        if (type === 'hospital') {
          this.props.history.push('/hospital/profile-setting');
        }
      } else {
        this.setState({ isLogin: false }, () => {
          this.props.dispatch(auth(this.state.isLogin));
        });
        validator.showMessages();
        this.setState({ passwordtypinginfoshow: false });
        this.forceUpdate();
      }
    }
  };

  loginProviderLab = async () => {
    const validator = this.validator;
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'L' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal3').modal('hide');
        let type = localStorage.getItem('type');
        if (type === 'lab') {
          this.props.history.push('/lab/profile-setting');
        }
      } else {
        this.setState({ isLogin: false }, () => {
          this.props.dispatch(auth(this.state.isLogin));
        });
        validator.showMessages();
        this.setState({ passwordtypinginfoshow: false });
        this.forceUpdate();
      }
    }
  };

  loginProviderPharmacy = async () => {
    const validator = this.validator;
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'P' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal3').modal('hide');
        let type = localStorage.getItem('type');
        if (type === 'lab') {
          this.props.history.push('/pharmacy/profile-setting');
        }
      } else {
        this.setState({ isLogin: false }, () => {
          this.props.dispatch(auth(this.state.isLogin));
        });
        validator.showMessages();
        this.setState({ passwordtypinginfoshow: false });
        this.forceUpdate();
      }
    }
  };

  loginProviderFitness = async () => {
    const validator = this.validator;
    const { email, password, rememberMe } = this.state;
    if (validator.allValid()) {
      const loginFxn = await this.props.dispatch(login({ email: email, password: password, rememberMe: rememberMe, type: 'F' }));
      if (loginFxn === 1) {
        this.setState({ isLogin: true, tabname: '' });
        window.$('#loginModal3').modal('hide');
        let type = localStorage.getItem('type');
        if (type === 'fitness') {
          this.props.history.push('/fitness/profile-setting');
        }
      } else {
        this.setState({ isLogin: false }, () => {
          this.props.dispatch(auth(this.state.isLogin));
        });
        validator.showMessages();
        this.setState({ passwordtypinginfoshow: false });
        this.forceUpdate();
      }
    }
  };

  logOutUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('data');
    localStorage.removeItem('type');
    this.setState(
      {
        isLogin: false,
      },
      () => {
        this.props.history.push('/');
        this.props.dispatch(auth(this.state.isLogin));
      }
    );
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitQuery = (e) => {
    const validator = this.queryValidator;
    const { title, firstname, lastname, message, phone, query_mail } = this.state;
    let data = {
      title: title,
      firstname: firstname,
      lastname: lastname,
      message: message,
      email: query_mail,
      phone: phone,
    };
    if (validator.allValid()) {
      this.props.dispatch(submitQuery(data));
      $('#closequery').click();
    } else {
      validator.showMessages();
      this.forceUpdate();
    }
  };

  SendOTP = async (e) => {
    const response = await this.props.dispatch(ForgotPasswordApi({ email: this.state.email }));
    if (response === 1) {
      this.setState({ ForgotPassword: false });
    }
  };

  CheckProfile = () => {
    let type = localStorage.getItem('type');
    if (type === 'doctor') {
      this.props.history.push('/doctor/profile-setting');
    } else if (type === 'hospital') {
      this.props.history.push('/hospital/profile-setting');
    } else if (type === 'user' || type === 'patient') {
      this.props.history.push('/patient/profile-settings');
    } else if (type === 'lab') {
      this.props.history.push('/lab/profile-setting');
    } else if (type === 'pharmacy') {
      this.props.history.push('/pharmacy/profile-setting');
    } else if (type === 'fitness') {
      this.props.history.push('/fitness/profile-setting');
    } else if (type === 'clinic') {
      this.props.history.push('/clinic/profile-setting');
    } else if (type === 'counselor') {
      this.props.history.push('/counsellor/profile-setting');
    }
  };

  toggleUserPasswordShow = () => {
    this.setState({
      userPasswordHidden: !this.state.userPasswordHidden,
    });
  };
  toggleDoctorPasswordShow = () => {
    this.setState({
      doctorPasswordHidden: !this.state.doctorPasswordHidden,
    });
  };

  toggleCounsellorPasswordShow = () => {
    this.setState({
      counsellorPasswordHidden: !this.state.counsellorPasswordHidden,
    });
  };

  toggleHospitalPasswordShow = () => {
    this.setState({
      hospitalPasswordHidden: !this.state.hospitalPasswordHidden,
    });
  };

  toggleLabPasswordShow = () => {
    this.setState({
      labPasswordHidden: !this.state.labPasswordHidden,
    });
  };

  togglePharmacyPasswordShow = () => {
    this.setState({
      pharmacyPasswordHidden: !this.state.pharmacyPasswordHidden,
    });
  };

  toggleFitnessPasswordShow = () => {
    this.setState({
      fitnessPasswordHidden: !this.state.fitnessPasswordHidden,
    });
  };

  onRemoveCart = (value) => {
    this.props.dispatch(removeProductCart(value, this.props.product_cart));
  };

  ViewProduct = (id) => {
    const data = {
      id: id,
      open: true,
    };
    this.props.dispatch(productModal(data));
  };

  checkoutModal = () => {
    let isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      swal({
        text: 'You need to login for checkout!',
        icon: 'info',
        buttons: true,
        dangerMode: true,
      }).then((successResponse) => {
        if (successResponse) {
          window.$('#loginModal1').modal({ show: true, backdrop: 'static' });
        } else {
          console.log('You Clicked on false!');
        }
      });
      return false;
    } else {
      this.props.history.push(`/productsCheckout`);
    }
  };

  FetchAndSearch = async(place_id) => {
    const {DoctorSearching,LocationSearching} = this.state;
    this.props.dispatch(getDoctorList(DoctorSearching, LocationSearching));
    this.props.history.push(`/doctor-listing`);
    const GetResponse = await this.props.dispatch(FetchLatLongs({place_id}));
    SetLocalExpiry('location',{city:LocationSearching,latitude:GetResponse.latitude??'',longitude:GetResponse.longitude??''});
  }

  render() {
    if (this.state.tabname === 'user') {
      window.$('#loginModal1').modal('hide');
    }
    if (this.state.tabname === 'doctor') {
      window.$('#loginModal2').modal('hide');
    }
    if (this.state.tabname === 'counselor' || this.state.tabname === 'hospital' || this.state.tabname === 'lab' || this.state.tabname === 'pharmacy' || this.state.tabname === 'fitness') {
      window.$('#loginModal3').modal('hide');
    }
    const { ForgotPassword } = this.state;
    const { product_cart, product_list,fetch_location:{nearby_locations,nearby_doctor_council,searched_locations} } = this.props;
    let username = localStorage.getItem('data') ? JSON.parse(localStorage.getItem('data')).firstname : 'User';

    return (
      <div>
        <header id='header-outer-id'>
          <nav className={`navbar navbar-expand-lg navbar-light home-new-header header-inner ${this.state.isLogin ? 'header-login-area' : ''}`} id='header'>
            <div className='container'>
              <Link className='navbar-brand' to='/'>
                <img src='assets/images/logo-text.png' alt='...' />
              </Link>
              <div className='menus'>
                <Link className='nav-link dropdown-toggle menu-ico' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' to='/'>
                  <i className='fas fa-bars'></i>
                </Link>
                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <div className='row menu-drop-row'>
                    <div className='col-md-12'>
                      <Link className='dropdown-item' to='/doctor-listing'>
                        <i className='fas fa-circle'></i>Doctors
                      </Link>
                      <Link className='dropdown-item' to='/counsellor-listing'>
                        <i className='fas fa-circle'></i>Counsellors
                      </Link>
                      <Link className='dropdown-item' to='/fitness-experts'>
                        <i className='fas fa-circle'></i>Fitness Training
                      </Link>
                      <Link className='dropdown-item' to='/products'>
                        <i className='fas fa-circle'></i>Products
                      </Link>
                      <Link className='dropdown-item' to='/'>
                        <i className='fas fa-circle'></i>Laboratory
                      </Link>
                      <Link className='dropdown-item' to='/'>
                        <i className='fas fa-circle'></i>Pharmacy
                      </Link>
                      <Link className='dropdown-item' to='/insurance'>
                        <i className='fas fa-circle'></i>Insurance
                      </Link>
                      <Link className='dropdown-item' to='/nutritions'>
                        <i className='fas fa-circle'></i>Nutritions
                      </Link>
                      <Link className='dropdown-item' to='/membership'>
                        <i className='fas fa-circle'></i>Membership
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <ul className='ios-android-ul'>
                <li className='nav-item'>
                  <Link className='btn btn-red-border' to='/doctor-listing'>
                    Consult Now
                  </Link>
                </li>
                <li className="nav-item app-dwn-ico">
                  <Link className="" href="/"><img src="assets/images/ios-1.png" alt="..." /></Link>
                </li>
                <li className="nav-item app-dwn-ico">
                  <Link className="" href="/"><img src="assets/images/android-1.png" alt="..." /></Link>
                </li>
              </ul>
              <button className='navbar-toggler navbar-menu-btn' type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                Menus
              </button>
              <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                <ul className='d-none d-md-none d-sm-none d-lg-block d-xl-block navbar-nav mx-auto col-lg-7 header-heading text-center col-md-8'>
                  <h1>You Will Be Fine</h1>
                  <h5>A Complete Health Management System</h5>
                </ul>
                <ul className='navbar-nav ml-auto nav-right-side '>
                  {!this.state.isLogin && (
                    <>
                      <li className='nav-item'>
                        <Link
                          to='#'
                          className={`btn btn-red-border`}
                          data-toggle='modal'
                          data-target='#loginModal1'
                          onClick={(e) =>
                            this.setState({
                              tabname: '',
                              ForgotPassword: false,
                              userModalValue: '#loginModal1',
                            })
                          }
                        >
                          For Users
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          to='#'
                          className={`btn btn-red-border`}
                          data-toggle='modal'
                          data-target='#loginModal2'
                          onClick={(e) =>
                            this.setState({
                              tabname: '',
                              ForgotPassword: false,
                            })
                          }
                        >
                          For Doctors
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          to='#'
                          className={`btn btn-pink`}
                          data-toggle='modal'
                          data-target='#loginModal3'
                          onClick={(e) =>
                            this.setState({
                              tabname: '',
                              ForgotPassword: false,
                            })
                          }
                        >
                          For Providers
                        </Link>
                      </li>
                    </>
                  )}

                  {this.state.isLogin && (
                    <li className='nav-item dropdown'>
                      <Link className={`dropdown-toggle`} to='#' id='navbarDropdownProfile' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                        Hi {username}
                      </Link>
                      <div className='dropdown-menu register-dropdown-menu' aria-labelledby='navbarDropdownProfile'>
                        <Link to='#' className='dropdown-item' onClick={(e) => this.CheckProfile()}>
                          Profile
                        </Link>
                        <Link to='/' className='dropdown-item' onClick={(e) => this.logOutUser()}>
                          Logout
                        </Link>
                      </div>
                    </li>
                  )}
                  <li className='nav-item cart-icon-main'>
                    <div className='dropdown add-cart-drop'>
                      <Link to='#' className='dropdown-toggle add-cart-ico cart-icons' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                        <i className='fas fa-shopping-cart'></i>
                        {product_cart && product_cart.length > 0 && <label className='badge add-cart-badge'>{product_cart.length}</label>}
                      </Link>
                      <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                        {product_cart &&
                          product_cart.map((value, index) => {
                            let filterdata = product_list && product_list.filter((data) => data.uniquekey === value.uniqueID);
                            this.total = index === 0 ? 0 : this.total;
                            this.total += parseFloat(value.price);
                            return (
                              <li key={`cart_${index}`}>
                                <div className='row cart-items-main-div'>
                                  <div className='col-md-2'>
                                    <Link className='cart-item-img' to='#'>
                                      <img src={filterdata && filterdata.length > 0 ? bucketPath + filterdata[0].image : null} alt='...' />
                                    </Link>
                                  </div>
                                  <div className='col-md-6 cart-item-name'>
                                    <h3>
                                      <Link to='#'>{value.name}</Link>
                                    </h3>
                                    <small>{filterdata && filterdata.length > 0 && filterdata[0].quantity}</small>
                                    <div className='edit-remove'>
                                      <Link to='#' onClick={() => this.ViewProduct(value.uniqueID)}>
                                        <i className='far fa-edit'></i>&nbsp;Preferences
                                      </Link>
                                      &nbsp;&nbsp;
                                      <Link to='#' onClick={(e) => this.onRemoveCart(value.uniqueID)}>
                                        <i className='far fa-trash-alt'></i>&nbsp;Remove
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='col-md-2'>
                                    <Link to='#' className='cart-item-count'>
                                      {value.count}
                                    </Link>
                                  </div>
                                  <div className='col-md-2 no-padding'>
                                    <p className='cart-item-price'>{`₹ ${value.price.toFixed(2)}`}</p>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        {product_cart && product_cart.length > 0 && (
                          <div className='check-out-btn-div'>
                            <Link to='#' className='btn btn-light-black btn-chek-out-drop btn-block' onClick={() => this.checkoutModal()}>
                              Go to Checkout
                              <span className='total-price'>{`₹ ${this.total.toFixed(2)}`}</span>
                            </Link>
                          </div>
                        )}

                        {product_cart && product_cart.length === 0 && (
                          <div className='check-out-btn-div'>
                            <Link to='#' className='btn btn-light-black btn-chek-out-drop btn-block'>
                              No items added.
                            </Link>
                          </div>
                        )}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {!this.state.isLogin && (
          <Link className={`btn btn-pink`} id='query-btn' data-toggle='modal' data-target='#askquery' to='#askquery'>
            Contact Us
          </Link>
        )}
        <ul className='social-fixed-ul'>
          <li>
            <Link to='#'>
              <i className='fab fa-facebook social-ico'></i>
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fab fa-instagram social-ico'></i>
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fab fa-twitter social-ico'></i>
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fab fa-linkedin-in social-ico'></i>
            </Link>
          </li>
        </ul>
        <div className='clearfix'></div>
        <div className='home-sticky-banner'>
          <div id='carouselExampleCaptions' className='carousel slide carousel-fade' data-ride='carousel'>
            {/* <ol className="carousel-indicators">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                        </ol> */}
            <div className='carousel-inner'>
              <div className='carousel-caption  d-md-block'>
                <ul className='d-block d-md-block d-sm-block d-lg-none d-xl-none navbar-nav mx-auto col-lg-7 header-heading text-center col-md-8'>
                  <h1>You Will Be Fine</h1>
                  <h5>A Complete Health Management System</h5>
                </ul>
                {/* <div className="row">
                                    <div className="col-md-12 text-center banner-heading-div">
                                        <h2><span>You Will Be Fine</span></h2>
                                    </div>
                                </div> */}

                <ul className='banner-search mx-auto search-head-ul row '>
                  <li className='nav-item col-lg-4 col-md-5 search-banner-li-1 no-padding'>
                    <div className='input-group location-input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='fa fa-map-marker'></i>
                        </span>
                      </div>
                      <input
                        className='no-padding-left form-control Search-control-outer dropdown-toggle'
                        id='myInput'
                        type='text'
                        placeholder='Search..'
                        name='LocationSearching'
                        value={this.state.LocationSearching}
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        autoComplete='off'
                        onChange={(e) =>
                          this.setState({ [e.target.name]: e.target.value }, () => {
                            this.props.dispatch(FetchLocations({location:this.state.LocationSearching}))
                            this.props.dispatch(getDoctorList(this.state.DoctorSearching, this.state.LocationSearching));
                          })
                        }
                      />
                      <div className={`input-group-prepend ${!this.state.LocationSearching ? 'hide' : ''}`}>
                        <span className='input-group-text clear-location' id='basic-addon1'>
                          <i
                            className='fas fa-times'
                            onMouseUp={() => {
                              this.setState({ LocationSearching: '' });
                            }}
                          ></i>
                        </span>
                      </div>
                        <div className='dropdown-menu Search-control-drop dropdown-content dropdown-menu-right'>
                            <Link
                                className='dropdown-item alert alert-danger'
                                to='#'
                                key={`doctors`}
                                onClick={(e) =>this.DetectPermission()}
                              >
                                <i className="fa fa-map-marker"></i>&nbsp;&nbsp;Detect Location
                              </Link>
                          {nearby_locations instanceof Array && nearby_locations.map((value, index) => {
                            return (
                              <Link
                                className='dropdown-item'
                                to='#'
                                key={`doctors_${index}`}
                                onClick={(e) =>
                                  this.setState({ LocationSearching: `${value.city}` }, () => {
                                    this.props.dispatch(getDoctorList(this.state.DoctorSearching, this.state.LocationSearching));
                                    this.props.history.push(`/doctor-listing`);
                                    SetLocalExpiry('location',{city:this.state.LocationSearching,latitude:value.latitude,longitude:value.longitude});
                                  })
                                }
                              >
                                {`${value.city}`}
                              </Link>
                            );
                          })}
                          {searched_locations instanceof Array && searched_locations.map((value, index) => {
                            return (
                              <Link
                                className='dropdown-item'
                                to='#'
                                key={`doctorscustom_${index}`}
                                onClick={(e) =>
                                  this.setState({ LocationSearching: `${value.city}` }, () => {
                                    this.FetchAndSearch(value.place_id)
                                  })
                                }
                              >
                                {`${value.description}`}
                              </Link>
                            );
                          })}
                        </div>

                    </div>
                  </li>
                  <li className='nav-item col-lg-8 col-md-7 search-banner-li-2 no-padding serach-col-md'>
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='fa fa-search'></i>
                        </span>
                      </div>
                      <input
                        className='form-control no-padding-left Search-control-outer dropdown-toggle'
                        id='DoctorSearching'
                        name='DoctorSearching'
                        value={this.state.DoctorSearching}
                        type='text'
                        placeholder='Search Doctors,Counsellors etc...'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        autoComplete='off'
                        onChange={(e) =>
                          this.setState({ [e.target.name]: e.target.value }, () => {
                            this.props.dispatch(NearbyDoctorCouncellor({search:this.state.DoctorSearching}))
                          })
                        }
                      />
                      <div className='dropdown-menu Search-control-drop dropdown-content dropdown-menu-right'>
                        {nearby_doctor_council instanceof Array && nearby_doctor_council.map((value, index) => {
                            return (
                              <Link
                                className='dropdown-item'
                                to='#'
                                key={`doctors_${index}`}
                                onClick={(e) =>
                                  this.setState(
                                    {
                                      DoctorSearching: `${value.firstname} ${value.lastname}`,
                                    },
                                    () => {
                                      this.props.dispatch(getDoctorList(this.state.DoctorSearching, this.state.LocationSearching));
                                      this.props.dispatch(getCounsellorList(this.state.DoctorSearching, this.state.LocationSearching));
                                      this.props.history.push(`${value.type==="doctor"?"/doctor-view":"/counsellor-view"}/${value.uniquekey}`);
                                    }
                                  )
                                }
                              >
                                {`${value.firstname} ${value.lastname} (${value.specialization_name})`}
                              </Link>
                            );
                          })}
                      </div>
                    </div>
                  </li>
                </ul>
                <div className='row banner-video-menu'>
                  <div className='col-md-5 banner-right-side-img'>
                    <img src='assets/images/adjust-l-1.png' className='img-fluid' alt='...' />
                    <a href='#videoModal' className='banner-play-icon' data-toggle='modal' data-target='#videoModal'>
                      <i className='fas fa-play'></i>
                    </a>
                  </div>
                  <div className='col-md-7  banner-left-side-text  align-self-center'>
                    <div className='row'>
                      <div className='col-md-4 col-6'>
                        <Link to='/doctor-listing'>Doctors</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='#'>Laboratory</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/fitness-experts'>Fitness Training</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/counsellor-listing'>Counselling</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/products'>Pharmacy</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/insurance'>Insurance</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/nutritions'>Nutritions</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/membership'>Membership</Link>
                      </div>
                      <div className='col-md-4 col-6'>
                        <Link to='/products'>Products</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="carousel-item active">
                                <img src="assets/images/b-1.jpg" className="d-block w-100" alt="..." />
                            </div> */}
              <div className='carousel-item active'>
                <img src='assets/images/b-2.jpg' className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src='assets/images/b-3.jpg' className='d-block w-100' alt='...' />
              </div>
            </div>
          </div>
        </div>

        {/* modal for All */}
        <Registration
          openModal={this.state.tabname}
          closePopup={() => {
            this.setState({ tabname: '' });
          }}
        />

        <div className='modal fade loginModal-div loginModal-div-small' id='loginModal1' tabIndex='-1' role='dialog' aria-labelledby='loginModal1' aria-hidden='true'>
          <div className='modal-dialog modal-sm' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='loginhead'>
                  {ForgotPassword ? 'Forgot My Password' : 'User Login'}
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ userModalValue: '' })}>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body login-sec-modal login-form'>
                <div className=' col-md-12'>
                  <div className='row'>
                    <div className='col-md-12 login-right-side'>
                      {!ForgotPassword && (
                        <>
                          <form>
                            <div className='form-group'>
                              <label htmlFor='exampleInputEmail1'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail1' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-password'>
                              <label htmlFor='exampleInputPassword31'>Password</label>
                              <input type={this.state.userPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword31' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} autoComplete='off' placeholder='eg: Abc@123' />
                              {this.state.userPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.toggleUserPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.toggleUserPasswordShow()}></i>}
                            </div>
                            {this.validator.message('Password', this.state.password, 'required|min:6')}
                            {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                            <div className='form-group form-check'>
                              <input type='checkbox' className='form-check-input' id='exampleCheck1' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                              <label className='form-check-label' htmlFor='exampleCheck1'>
                                Remember Me
                              </label>
                              <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                Forgot Password?
                              </Link>
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.submit = input;
                                }}
                                onClick={(e) => this.loginUser()}
                              >
                                Login
                              </Link>
                            </div>
                          </form>
                          <div className='signin-tab-link forget-password'>
                            Don't have an account?&nbsp;
                            <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'user' })}>
                              SignUp
                            </Link>
                          </div>
                        </>
                      )}
                      {ForgotPassword && (
                        <form>
                          <div className='form-group'>
                            <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                            <label htmlFor='exampleInputEmail2'>Email address</label>
                            <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail2' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                            {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                          </div>
                          <div className='form-group login-signup-btn text-center'>
                            <Link
                              to='#'
                              className='btn btn-light-black btn-block'
                              ref={(input) => {
                                this.sendOtp = input;
                              }}
                              onClick={(e) => this.SendOTP()}
                            >
                              Send Password
                            </Link>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal fade loginModal-div loginModal-div-small' id='loginModal2' tabIndex='-1' role='dialog' aria-labelledby='loginModal2' aria-hidden='true'>
          <div className='modal-dialog modal-sm' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='loginhead'>
                  {ForgotPassword ? 'Forgot My Password' : 'Doctor Login'}
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body login-sec-modal login-form'>
                <div className=' col-md-12'>
                  <div className='row'>
                    <div className='col-md-12 login-right-side'>
                      {!ForgotPassword && (
                        <>
                          <form>
                            <div className='form-group'>
                              <label htmlFor='exampleInputEmail3'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail3' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-password'>
                              <label htmlFor='exampleInputPassword21'>Password</label>
                              <input type={this.state.doctorPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword21' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} placeholder='eg: Abc@123' autoComplete='off' />
                              {this.state.doctorPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.toggleDoctorPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.toggleDoctorPasswordShow()}></i>}
                            </div>
                            {this.validator.message('Password', this.state.password, 'required|min:6')}
                            {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                            <div className='form-group form-check'>
                              <input type='checkbox' className='form-check-input' id='exampleCheck2' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                              <label className='form-check-label' htmlFor='exampleCheck2'>
                                Remember Me
                              </label>
                              <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                Forgot Password?
                              </Link>
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.submit = input;
                                }}
                                onClick={(e) => this.loginDoctor()}
                              >
                                Login
                              </Link>
                            </div>
                          </form>
                          <div className='signin-tab-link forget-password'>
                            Don't have an account?&nbsp;
                            <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'doctor' })}>
                              SignUp
                            </Link>
                          </div>
                        </>
                      )}
                      {ForgotPassword && (
                        <form>
                          <div className='form-group'>
                            <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                            <label htmlFor='exampleInputEmail4'>Email address</label>
                            <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail4' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                            {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                          </div>
                          <div className='form-group login-signup-btn text-center'>
                            <Link
                              to='#'
                              className='btn btn-light-black btn-block'
                              ref={(input) => {
                                this.sendOtp = input;
                              }}
                              onClick={(e) => this.SendOTP()}
                            >
                              Send Password
                            </Link>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal fade loginModal-div loginModal-div-small loginModal-div-provider' id='loginModal3' tabIndex='-1' role='dialog' aria-labelledby='loginModal3' aria-hidden='true'>
          <div className='modal-dialog modal-sm' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='loginhead'>
                  {ForgotPassword ? 'Forgot My Password' : 'Provider'}
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body login-sec-modal login-form provider-login-form'>
                <div className='col-md-12 no-padding'>
                  <form>
                    <div className='form-check-inline'>
                      <label className='form-check-label' htmlFor='radio1'>
                        <input type='radio' className='form-check-input' id='radio1' name='optradio' defaultChecked={this.state.providerLoginValue === 'CounsellorLogin' ? 'checked' : undefined} value='CounsellorLogin' onClick={(e) => this.setState({ providerLoginValue: e.target.value })} />
                        Counsellor
                      </label>
                    </div>
                    <div className='form-check-inline'>
                      <label className='form-check-label' htmlFor='radio2'>
                        <input type='radio' className='form-check-input' id='radio2' name='optradio' defaultChecked={this.state.providerLoginValue === 'HospitalLogin' ? 'checked' : undefined} value='HospitalLogin' onClick={(e) => this.setState({ providerLoginValue: e.target.value })} />
                        Hospital
                      </label>
                    </div>

                    <div className='form-check-inline'>
                      <label className='form-check-label' htmlFor='radio3'>
                        <input type='radio' className='form-check-input' id='radio3' name='optradio' defaultChecked={this.state.providerLoginValue === 'LabLogin' ? 'checked' : undefined} value='LabLogin' onClick={(e) => this.setState({ providerLoginValue: e.target.value })} />
                        Lab
                      </label>
                    </div>

                    <div className='form-check-inline'>
                      <label className='form-check-label' htmlFor='radio4'>
                        <input type='radio' className='form-check-input' id='radio4' name='optradio' defaultChecked={this.state.providerLoginValue === 'PharmacyLogin' ? 'checked' : undefined} value='PharmacyLogin' onClick={(e) => this.setState({ providerLoginValue: e.target.value })} />
                        Pharmacy
                      </label>
                    </div>

                    <div className='form-check-inline'>
                      <label className='form-check-label' htmlFor='radio5'>
                        <input type='radio' className='form-check-input' id='radio5' name='optradio' defaultChecked={this.state.providerLoginValue === 'FitnessLogin' ? 'checked' : undefined} value='FitnessLogin' onClick={(e) => this.setState({ providerLoginValue: e.target.value })} />
                        Fitness Training
                      </label>
                    </div>
                  </form>
                </div>
                {this.state.providerLoginValue === 'CounsellorLogin' ? (
                  <div className=' col-md-12 provider-login-form-content'>
                    <div className='row'>
                      <div className='col-md-12 login-right-side'>
                        {!ForgotPassword && (
                          <>
                            <form>
                              <div className='form-group'>
                                <label htmlFor='exampleInputEmail5'>Email address</label>
                                <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail5' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                                {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                              </div>
                              <div className='form-group login-password'>
                                <label htmlFor='exampleInputPassword1'>Password</label>
                                <input type={this.state.counsellorPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword1' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} autoComplete='off' placeholder='eg: Abc@123' />
                                {this.state.counsellorPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.toggleCounsellorPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.toggleCounsellorPasswordShow()}></i>}
                              </div>
                              {this.validator.message('Password', this.state.password, 'required|min:6')}
                              {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                              <div className='form-group form-check'>
                                <input type='checkbox' className='form-check-input' id='exampleCheck3' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                                <label className='form-check-label' htmlFor='exampleCheck3'>
                                  Remember Me
                                </label>
                                <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className='form-group login-signup-btn text-center'>
                                <Link
                                  to='#'
                                  className='btn btn-light-black btn-block'
                                  ref={(input) => {
                                    this.submit = input;
                                  }}
                                  onClick={(e) => this.loginProviderCounseller()}
                                >
                                  Login
                                </Link>
                              </div>
                            </form>
                            <div className='signin-tab-link forget-password'>
                              Don't have an account?&nbsp;
                              <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'counselor' })}>
                                SignUp
                              </Link>
                            </div>
                          </>
                        )}
                        {ForgotPassword && (
                          <form>
                            <div className='form-group'>
                              <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                              <label htmlFor='exampleInputEmail6'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail6' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.sendOtp = input;
                                }}
                                onClick={(e) => this.SendOTP()}
                              >
                                Send Password
                              </Link>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                ) : this.state.providerLoginValue === 'HospitalLogin' ? (
                  <div className=' col-md-12 provider-login-form-content'>
                    <div className='row'>
                      <div className='col-md-12 login-right-side'>
                        {!ForgotPassword && (
                          <>
                            <form>
                              <div className='form-group'>
                                <label htmlFor='exampleInputEmail7'>Email address</label>
                                <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail7' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                                {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                              </div>
                              <div className='form-group login-password'>
                                <label htmlFor='exampleInputPassword41'>Password</label>
                                <input type={this.state.hospitalPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword41' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} autoComplete='off' placeholder='eg: Abc@123' />
                                {this.state.hospitalPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.toggleHospitalPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.toggleHospitalPasswordShow()}></i>}
                              </div>
                              {this.validator.message('Password', this.state.password, 'required|min:6')}
                              {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                              <div className='form-group form-check'>
                                <input type='checkbox' className='form-check-input' id='exampleCheck4' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                                <label className='form-check-label' htmlFor='exampleCheck5'>
                                  Remember Me
                                </label>
                                <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className='form-group login-signup-btn text-center'>
                                <Link
                                  to='#'
                                  className='btn btn-light-black btn-block'
                                  ref={(input) => {
                                    this.submit = input;
                                  }}
                                  onClick={(e) => this.loginProviderHospital()}
                                >
                                  Login
                                </Link>
                              </div>
                            </form>
                            <div className='signin-tab-link forget-password'>
                              Don't have an account?&nbsp;
                              <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'hospital' })}>
                                SignUp
                              </Link>
                            </div>
                          </>
                        )}
                        {ForgotPassword && (
                          <form>
                            <div className='form-group'>
                              <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                              <label htmlFor='exampleInputEmail8'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail8' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.sendOtp = input;
                                }}
                                onClick={(e) => this.SendOTP()}
                              >
                                Send Password
                              </Link>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                ) : this.state.providerLoginValue === 'LabLogin' ? (
                  <div className=' col-md-12 provider-login-form-content'>
                    <div className='row'>
                      <div className='col-md-12 login-right-side'>
                        {!ForgotPassword && (
                          <>
                            <form>
                              <div className='form-group'>
                                <label htmlFor='exampleInputEmail9'>Email address</label>
                                <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail9' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                                {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                              </div>
                              <div className='form-group login-password'>
                                <label htmlFor='exampleInputPassword51'>Password</label>
                                <input type={this.state.labPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword51' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} autoComplete='off' placeholder='eg: Abc@123' />
                                {this.state.labPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.toggleLabPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.toggleLabPasswordShow()}></i>}
                              </div>
                              {this.validator.message('Password', this.state.password, 'required|min:6')}
                              {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                              <div className='form-group form-check'>
                                <input type='checkbox' className='form-check-input' id='exampleCheck6' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                                <label className='form-check-label' htmlFor='exampleCheck6'>
                                  Remember Me
                                </label>
                                <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className='form-group login-signup-btn text-center'>
                                <Link
                                  to='#'
                                  className='btn btn-light-black btn-block'
                                  ref={(input) => {
                                    this.submit = input;
                                  }}
                                  onClick={(e) => this.loginProviderLab()}
                                >
                                  Login
                                </Link>
                              </div>
                            </form>
                            <div className='signin-tab-link forget-password'>
                              Don't have an account?&nbsp;
                              <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'lab' })}>
                                SignUp
                              </Link>
                            </div>
                          </>
                        )}
                        {ForgotPassword && (
                          <form>
                            <div className='form-group'>
                              <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                              <label htmlFor='exampleInputEmail'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.sendOtp = input;
                                }}
                                onClick={(e) => this.SendOTP()}
                              >
                                Send Password
                              </Link>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                ) : this.state.providerLoginValue === 'PharmacyLogin' ? (
                  <div className=' col-md-12 provider-login-form-content'>
                    <div className='row'>
                      <div className='col-md-12 login-right-side'>
                        {!ForgotPassword && (
                          <>
                            <form>
                              <div className='form-group'>
                                <label htmlFor='exampleInputEmail21'>Email address</label>
                                <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail21' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                                {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                              </div>
                              <div className='form-group login-password'>
                                <label htmlFor='exampleInputPassword61'>Password</label>
                                <input type={this.state.pharmacyPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword61' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} autoComplete='off' placeholder='eg: Abc@123' />
                                {this.state.pharmacyPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.togglePharmacyPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.togglePharmacyPasswordShow()}></i>}
                              </div>
                              {this.validator.message('Password', this.state.password, 'required|min:6')}
                              {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                              <div className='form-group form-check'>
                                <input type='checkbox' className='form-check-input' id='exampleCheck7' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                                <label className='form-check-label' htmlFor='exampleCheck7'>
                                  Remember Me
                                </label>
                                <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className='form-group login-signup-btn text-center'>
                                <Link
                                  to='#'
                                  className='btn btn-light-black btn-block'
                                  ref={(input) => {
                                    this.submit = input;
                                  }}
                                  onClick={(e) => this.loginProviderPharmacy()}
                                >
                                  Login
                                </Link>
                              </div>
                            </form>
                            <div className='signin-tab-link forget-password'>
                              Don't have an account?&nbsp;
                              <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'pharmacy' })}>
                                SignUp
                              </Link>
                            </div>
                          </>
                        )}
                        {ForgotPassword && (
                          <form>
                            <div className='form-group'>
                              <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                              <label htmlFor='exampleInputEmail22'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail22' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.sendOtp = input;
                                }}
                                onClick={(e) => this.SendOTP()}
                              >
                                Send Password
                              </Link>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                ) : this.state.providerLoginValue === 'FitnessLogin' ? (
                  <div className=' col-md-12 provider-login-form-content'>
                    <div className='row'>
                      <div className='col-md-12 login-right-side'>
                        {!ForgotPassword && (
                          <>
                            <form>
                              <div className='form-group'>
                                <label htmlFor='exampleInputEmail23'>Email address</label>
                                <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail23' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} autoComplete='off' />
                                {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                              </div>
                              <div className='form-group login-password'>
                                <label htmlFor='exampleInputPassword71'>Password</label>
                                <input type={this.state.fitnessPasswordHidden ? 'password' : 'text'} name='password' value={this.state.password} className='form-control' id='exampleInputPassword71' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.submit.click() : null)} autoComplete='off' placeholder='eg: Abc@123' />
                                {this.state.fitnessPasswordHidden ? <i className='far fa-eye eye-password-icon' onClick={() => this.toggleFitnessPasswordShow()}></i> : <i className='fa fa-eye-slash eye-password-icon' aria-hidden='true' onClick={() => this.toggleFitnessPasswordShow()}></i>}
                              </div>
                              {this.validator.message('Password', this.state.password, 'required|min:6')}
                              {/* {this.state.password.length < 6 && this.state.passwordtypinginfoshow ?
                    <div className="srv-validation-message">The  password must be at least 6 characters.</div>
                    :this.validator.message(
                    "Password",
                    this.state.password,
                    "required|min:6,string|max:30,string"
                  )} */}
                              <div className='form-group form-check'>
                                <input type='checkbox' className='form-check-input' id='exampleCheck8' name='rememberMe' checked={this.state.rememberMe ? 'checked' : ''} onChange={(e) => this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))} />
                                <label className='form-check-label' htmlFor='exampleCheck8'>
                                  Remember Me
                                </label>
                                <Link className='float-right' to='#' onClick={(e) => this.setState({ ForgotPassword: true })}>
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className='form-group login-signup-btn text-center'>
                                <Link
                                  to='#'
                                  className='btn btn-light-black btn-block'
                                  ref={(input) => {
                                    this.submit = input;
                                  }}
                                  onClick={(e) => this.loginProviderFitness()}
                                >
                                  Login
                                </Link>
                              </div>
                            </form>
                            <div className='signin-tab-link forget-password'>
                              Don't have an account?&nbsp;
                              <Link to='#' className='foodbakery-dev-login-box-btn forgot-switch signup-text-color' onClick={(e) => this.setState({ tabname: 'fitness' })}>
                                SignUp
                              </Link>
                            </div>
                          </>
                        )}
                        {ForgotPassword && (
                          <form>
                            <div className='form-group'>
                              <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with AdiDoc.</p>
                              <label htmlFor='exampleInputEmail24'>Email address</label>
                              <input type='email' name='email' className='form-control' value={this.state.email} id='exampleInputEmail24' aria-describedby='emailHelp' onChange={(e) => this.onChangeHandler(e)} onKeyUp={(e) => (e.keyCode === 13 ? this.sendOtp.click() : null)} />
                              {this.validator.message('Email', this.state.email, 'required|email|min:10,string|max:30,string')}
                            </div>
                            <div className='form-group login-signup-btn text-center'>
                              <Link
                                to='#'
                                className='btn btn-light-black btn-block'
                                ref={(input) => {
                                  this.sendOtp = input;
                                }}
                                onClick={(e) => this.SendOTP()}
                              >
                                Send Password
                              </Link>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* modal for query */}
        <div className='modal fade loginModal-div' id='askquery' tabIndex='-1' role='dialog' aria-labelledby='exampleModalLabel' aria-hidden='true'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Query Form
                </h5>
                <button type='button' className='close' id='closequery' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body login-sec-modal'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-2 doctor-setp-mid no-padding-right'>
                      <div className='form-group'>
                        <label>Title</label>
                        <select className='form-control title-control' name='title' value={this.state.title} onChange={(e) => this.onChangeHandler(e)}>
                          <option value='Mr'>Mr</option>
                          <option value='Mrs'>Mrs</option>
                          <option value='Miss'>Miss</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-5 doctor-setp-mid '>
                      <div className='form-group'>
                        <label>First Name</label>
                        <input type='text' className='form-control' name='firstname' value={this.state.firstname} onChange={(e) => this.onChangeHandler(e)} />
                        {this.queryValidator.message('FirstName', this.state.firstname, 'required')}
                      </div>
                    </div>
                    <div className='col-md-5 doctor-setp-mid'>
                      <div className='form-group'>
                        <label>Last Name</label>
                        <input type='text' className='form-control' name='lastname' value={this.state.lastname} onChange={(e) => this.onChangeHandler(e)} />
                        {this.queryValidator.message('LastName', this.state.lastname, 'required')}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 doctor-setp-mid'>
                      <div className='form-group'>
                        <label>Contact Number</label>
                        <input type='text' className='form-control' name='phone' value={this.state.phone} onChange={(e) => this.onChangeHandler(e)} />
                        {this.queryValidator.message('Number', this.state.phone, 'required')}
                      </div>
                    </div>
                    <div className='col-md-6 doctor-setp-mid'>
                      <div className='form-group'>
                        <label>Your Email Address</label>
                        <input type='email' className='form-control' name='query_mail' value={this.state.query_mail} onChange={(e) => this.onChangeHandler(e)} />
                        {this.queryValidator.message('Email', this.state.query_mail, 'required|email|min:10,string|max:30,string')}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 doctor-setp-mid'>
                      <div className='form-group'>
                        <label>Message</label>
                        <textarea className='form-control' id='exampleFormControlTextarea1' rows='6' name='message' value={this.state.message} onChange={(e) => this.onChangeHandler(e)}></textarea>
                        {this.queryValidator.message('Message', this.state.message, 'required')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div className='row'>
                  <div className='col-md-12 doctor-setp-footer text-center'>
                    <Link to='#' className='btn btn-light-black' onClick={(e) => this.submitQuery(e)}>
                      Submit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='modal fade video-modal' id='videoModal' tabIndex='-1' role='dialog' aria-labelledby='exampleModalLabel' aria-hidden='true'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'>
              <div className='modal-body video-modal-body'>
                <button type='button' className='close video-close-ico' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
                <div className='video-div'>
                  <iframe width='100%' height='450' title='video' src='https://www.youtube.com/embed/sR8vmBnp6qw'></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Doctors: state.user.doctorListing,
  Counsellors: state.user.counsellorListing,
  product_cart: state.product.product_cart,
  product_list: state.global.product_list,
  force_login_click: state.patient.force_login_click,
  fetch_location:state.location
});

export default withRouter(connect(mapStateToProps)(HomePage));
