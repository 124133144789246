import * as types from "../types/types";

const initalState = {
    loading: true,
    countryList: [],
    medical_council: [],
    profile_info: [],
    scheduled_timing:[],
    doctorListing:[],
    appointments:[],
    today_appointments:[],
    amount: 0,
    dr_appointment_details: {},
    social_media:[],
    home_article : [],
    counsellorListing : [],
    subTypeListing:[],
    doctor_counsellor_listing:[]

}

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_COUNTRY_LISTING:
            return {
                ...state,
                loading: false,
                countryList: action.payload
            }
        case types.FETCH_MEDICAL_COUNCILS:
            return {
                ...state,
                loading: false,
                medical_council: action.payload
            }
        case types.FETCH_PROFILE_INFO:
            return {
                ...state,
                loading: false,
                profile_info: action.payload
            }
        case types.FETCH_SCHEDULED_TIMING:
            return {
                ...state,
                loading: false,
                scheduled_timing: action.payload
            }
        case types.FETCH_DOCTOR_LISTING:
            return {
                ...state,
                loading: false,
                doctorListing: action.payload
            }
        case types.FETCH_APPOINTMENT_LISTING:
            return {
                ...state,
                loading: false,
                appointments: action.payload
            }
        case types.FETCH_TODAY_APPOINTMENT_LISTING:
            return {
                ...state,
                loading: false,
                today_appointments: action.payload
            }
        case types.FETCH_AREA_PRICE:
            return {
                ...state,
                loading: false,
                amount: action.payload
            }
        case types.DR_APPOINTMENT_DETAILS:
            return {
                ...state,
                loading: false,
                dr_appointment_details: action.payload
            }
        case types.VIEW_SOCIAL_MEDIA:
            return {
                ...state,
                loading: false,
                social_media: action.payload
            }
        case types.VIEW_HOME_ARTICLE:
            return {
                ...state,
                loading: false,
                home_article: action.payload
            }
        case types.FETCH_COUNSELLOR_LISTING:
            return {
                ...state,
                loading: false,
                counsellorListing: action.payload
            }
        case types.FETCH_SUBTYPE_LISTING:
            return {
                ...state,
                loading: false,
                subTypeListing: action.payload
            }
        case types.FETCH_DOCTOR_COUNSELLOR_LISTING:
            return {
                ...state,
                loading: false,
                doctor_counsellor_listing: action.payload
            }
        
        default:
            return state
    }
}

export default userReducer;
