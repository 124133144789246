import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';
import AdminCounsellorList from './AdminCounsellorList';
import AdminCounsellorTypes from './AdminCounsellorTypes'; 

class AdminCounsellor extends Component {
    state = {tab:'counsellor'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="counsellor"?"active":""}`} to="#" onClick={e=>this.setState({tab:'counsellor'})} >Counsellors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="counsellor_type"?"active":""}`} to="#" onClick={e=>this.setState({tab:'counsellor_type'})}>Counsellor Types</Link>
                                </li> 
                            </ul>
                        </nav>
                        {tab==="counsellor" && <AdminCounsellorList />}
                        {tab==="counsellor_type" && <AdminCounsellorTypes />} 
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminCounsellor