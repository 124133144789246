import * as actionTypes from '../types/types';
import { apiBaseUrl} from './../helpers/common';
import { handleResponse } from './../helpers/userServices';
import {ShowLoader,ShowAlert} from './staticListingsActions'

export const productCart = (data) => {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_PRODUCT_CART_DETAILS, payload: data })
    }
}


export function  addProductCart (value,state){ 
  return dispatch => {
  let uniqueID = value.uniquekey;
  let name = value.name;
  let tax = value.tax;
  let price = parseFloat(value.price); 
  let { cartItems } = state;
  let arrayFilter =
    cartItems instanceof Array &&
    cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
  if (arrayFilter !== -1) {
    cartItems[arrayFilter] = {
      name: name,
      uniqueID: uniqueID, 
      price: cartItems[arrayFilter].price + price,
      count: cartItems[arrayFilter].count + 1,
      tax: tax
    };
  } else {
    cartItems = [
      ...cartItems,
      { name: name, uniqueID: uniqueID, price: price, count: 1, tax: tax},
    ];
  }
  dispatch(productCart(cartItems));
  let totalAmount = 0;
  let totalTax = 0;

  for(let i =0; i<cartItems.length; i++) {
    let value = cartItems[i];

    let _unit = value.count;
    let _price = value.price;
    let _tax = value.tax;
    let _taxAmount = _tax ? _price * (_tax / 100) : 0;
    totalTax += _taxAmount;
    totalAmount += (_unit * _price);
  }
  let amount_to_pay = {
    totalAmount,
    totalTax
  }
  dispatch({type: actionTypes.PRODUCT_TOTAL_AMOUNT, payload: amount_to_pay}); 
  return null;
}
};

export function minusProductCart(value,state) {
  return dispatch => {
  let uniqueID = value.uniquekey;
  let name = value.name;
  let price = parseFloat(value.price); 
  let { cartItems } = state;
  let arrayFilter =
    cartItems instanceof Array &&
    cartItems.findIndex((obj) => obj.uniqueID === uniqueID);
  if (arrayFilter !== -1) {
    if (cartItems[arrayFilter].count > 1) {
      cartItems[arrayFilter] = {
        name: name,
        uniqueID: uniqueID,
        price: cartItems[arrayFilter].price - price,
        count: cartItems[arrayFilter].count - 1, 
      };
    } else {
      cartItems.splice(arrayFilter, 1);
    }
  }
  dispatch(productCart(cartItems));
  return null;
}
};

export function removeProductCart(uniqueID,state){
  return dispatch => {
  let  cartItems  = state;
  let arrayFilter =
    cartItems instanceof Array &&
    cartItems.filter((obj) => obj.uniqueID !== uniqueID);
  dispatch(productCart(arrayFilter));
  return null;
}
};

export function CheckOutProducts(data){
  return dispatch => {
      dispatch(ShowLoader(true));
      const request = new Request(`${apiBaseUrl}/stripe/checkout/products`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
      });
      return fetch(request).then(handleResponse).then(data => {
              dispatch(ShowLoader(false));
          if (data.ResponseCode === "1") {
              localStorage.removeItem("appt_with");
              dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
              return 1;
          } else {
              dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
              return 0
          }
      })
  }
}