import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Modals from "./General/Modals";
import {GlobalFitnessClassList,GlobalViewFitnessClass,BookFitnessExpertClass} from '../store/actions/fitnessActions'
import { bucketPath } from '../store/helpers/common';
import Pagination from '../store/helpers/Pagination';
import swal from 'sweetalert';

const initialState = {
    open: false,
    currentPage: 1,
    pageOfItems: [],
}
class FitnessClassList extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

    }

    componentDidMount() {
        this.props.dispatch(GlobalFitnessClassList())

    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
      };

    setOpen = (value) => {
        this.setState({
          open: false,
          modal_type:""
        })
    };

    ViewFitnessClass = (id) => {
        this.props.dispatch(GlobalViewFitnessClass(id));
        this.setState({
            open: true,
        })
    };

    formattedTime = (value) => {
        let Time;
        Time = value.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [Time];
        let startTime = ''
        if (Time.length > 1) {
             Time = Time.slice (1);
             Time[5] = +Time[0] < 12 ? 'AM' : 'PM';
             Time[0] = +Time[0] % 12 || 12;
             startTime =  Time.join ('');
        }
        var formattedStartTime = startTime.slice(0, 4) + startTime.slice(7);
        return formattedStartTime
    }


    BookFitnessClass = async (value) => {
        const getUserIdentity = localStorage.getItem('token');
        const identity = localStorage.getItem('type');
        const data = {
            fitnessID : value.fitnessID,
            classID : value.uniquekey,
            type:'F'
        }
        if (!getUserIdentity && identity !== "patient" ) {
          swal({
            text: "You need to login to book fitness class !",
            icon: "info",
            buttons: true,
            dangerMode: true,
          })
            .then((yesDo) => {
              if (yesDo) {
                sessionStorage.setItem('_booked_class', value.uniquekey);
                sessionStorage.setItem( '_booking_class_details', JSON.stringify(data));
                window.$('#loginModal1').modal({ show: true, backdrop: 'static' });
              } else {
                console.log("You Clicked on false!");
              }
            });
          return false;
        } else {
            const response = await this.props.dispatch(
                BookFitnessExpertClass(data)
              );
              if (response.response === 'success') {
                 this.props.history.push(`/fitnessCheckout/${response.code}`);
              }

        }
      }

    render() {
        const { open,pageOfItems } = this.state;
        const { global_fitness_classes,view_fitness} = this.props
        let images = view_fitness?.fitness_image
        let price = view_fitness?.price
        let title = view_fitness?.title
        let description = view_fitness?.description
        let date = view_fitness?.date
        let duration = view_fitness?.duration
        let time = view_fitness?.time
        return (
            <div className="page-content background">
                <div className="banner-section-inner">
                <div className="container-fluid">
                    <div className="col-md-12">
                    <h2>Fitness Classes</h2>
                    <p>Some informative text will come in this line to tell about this page</p>
                    </div>
                </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="doctor-list-section col-md-12">
                            <div className="clearfix"></div>
                            <div className="grid-view-div">
                                <div className="row list-repeat-row">
                                    {pageOfItems && pageOfItems.map((value, index) => {
                                        return (
                                            <div className="col-md-4 col-lg-3" key={`product_cart_${index}`}>
                                                <div className="doctor-list-box product-list-box">
                                                    <div className="product-list-img  grid-product-img text-center col-md-12 align-self-center text-center">
                                                        <Link to="#" onClick = {() => this.ViewFitnessClass(value.uniquekey)}>
                                                            <img
                                                                src={`${
                                                                    value.fitness_image
                                                                        ? bucketPath + value.fitness_image[0].image
                                                                        : './../assets/images/dummy.jpg'
                                                                    }`}
                                                                className="img-fluid"
                                                                alt="..."
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="product-detail-box grid-product-height">
                                                        <div className="row">
                                                            <div className="col-md-12 ">
                                                                <p className="product-name product-title"><b>{value.title.toUpperCase()}</b></p>
                                                                {/* <p className="product-name prodcut-summary" dangerouslySetInnerHTML={{ __html: value.description}}></p> */}
                                                                <p className="product-name"><b>Price : </b>{` ₹ ${value.price}`}</p>
                                                                <p className="product-name"><b>Starting Date : </b>{value.date}</p>
                                                                <p className="product-name"><b>Duration : </b>{value.duration}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 product-bx-btn align-self-center text-center">
                                                            <Link className="btn btn-sm btn-light-black margin-top-10" to="#" onClick={()=>this.BookFitnessClass(value)}>Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='container-fluid no-padding'>
                                    <div className='row pull-right'>
                                    <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                                        &nbsp;
                                        {global_fitness_classes && (
                                            <Pagination
                                                pageSize={8}
                                                items={global_fitness_classes}
                                                onChangePage={this.onChangePage}
                                            />
                                        )}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modals open={open} setOpen={this.setOpen}>
                    <Modal.Header closeButton >
                        <Modal.Title id="example-custom-modal-styling-title">
                            FITNESS CLASS DETAILS
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="product-details-body">
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="prudct-slider-side main-post">
                                      <figure>
                                          <div
                                              id='carouselExampleIndicators'
                                              className='carousel slide'
                                              data-ride='carousel'
                                          >
                                              <ol className="carousel-indicators">
                                                  {images && images.map(
                                                      (imgVal, imgIndex) => {
                                                          return (
                                                              <li key={"dot_" + imgIndex} data-target="#carouselExampleIndicators" data-slide-to={`${imgIndex}`} className=""></li>
                                                          )
                                                      })
                                                  }
                                              </ol>
                                              <div
                                                  className='carousel-inner'
                                                  role='listbox'
                                              >
                                                  {images && images.map(
                                                      (imgVal, imgIndex) => {
                                                          return (
                                                              <div className={`carousel-item ${imgIndex === 0 ? "active" : ""}`} key={`item_${imgIndex}`}>
                                                                  <Link to='#' >
                                                                      <img
                                                                          src={`${bucketPath}${imgVal.image}`}
                                                                          alt=''
                                                                      />
                                                                  </Link>
                                                              </div>
                                                          );
                                                      }
                                                  )}
                                              </div>
                                              <a
                                                  className='carousel-control-prev'
                                                  href='#carouselExampleIndicators'
                                                  role='button'
                                                  data-slide='prev'
                                              >
                                                  <span
                                                      className='fas fa-chevron-left'
                                                      aria-hidden='true'
                                                  ></span>
                                                  <span className='sr-only'>Previous</span>
                                              </a>
                                              <a
                                                  className='carousel-control-next'
                                                  href='#carouselExampleIndicators'
                                                  role='button'
                                                  data-slide='next'
                                              >
                                                  <span
                                                      className='fas fa-chevron-right'
                                                      aria-hidden='true'
                                                  ></span>
                                                  <span className='sr-only'>Next</span>
                                              </a>
                                          </div>
                                      </figure>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="prudct-content-side">
                                      <h2 className="product-dt-name text-red">{title}</h2>
                                        <h5 className="start-date-duration"><b>Price :</b> {`₹ ${price}`}</h5>
                                        <h5 className="start-date-duration"><b>Starting Date :</b> {date}</h5>
                                        <h5 className="start-date-duration"><b>Duration :</b> {duration}</h5>
                                        <div className="fitness-time-modal-area">
                                          <div className="widget widget-timing ">
                                              <h5>Fitness Class Timings :</h5>
                                              {time && time.map((value,index)=>{
                                                  return (
                                                    <ul key={`time_${index}`}>
                                                        <li >
                                                            <span>{value.starttime !== "" && value.endtime !== "" ? value.day_name : ''}</span>
                                                            {value.starttime !=="" && value.endtime !=="" ? this.formattedTime(value.starttime) + ' - '+ this.formattedTime(value.endtime) : ""}
                                                        </li>
                                                    </ul>
                                                  )
                                              })}
                                            </div>
                                      </div>

                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="discription-body">
                                      <h2>Fitness Class Description</h2>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: description}}
                                        />
                                  </div>
                              </div>
                          </div>
                      </div>
                    </Modal.Body>
                </Modals>

            </div>
        );
    };
}

const mapStateToProps = (state) => ({
    global_fitness_classes:state.fitness.global_fitness_classes,
    view_fitness:state.fitness.global_view_fitness_class
});

export default connect(mapStateToProps)(FitnessClassList);
