import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices';
import toastr from "reactjs-toastr";
import { ShowLoader, ShowAlert } from './staticListingsActions'
import { PrescriptionsList,PatientMedicalRecordsList } from './registerationActions'
import swal from "sweetalert";
const axios = require("axios");

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

// FETCH INDIVIDUAL DOCTOR PATIENTS WHICH WERE BOOKED VIA APPOINTMENTS
export function Counselor_Patients() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor-patients`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.COUNSELOR_PATIENTS, payload: data.data })
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
            }
        })
    }
}

export function GetCounselorAppointmentList(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.COUNSELLOR_APPOINTMENT_LISTING,payload: data.data});
            }
        });
    }
}


// FETCH INDIVIDUAL PATIENT INFO WHICH WERE BOOKED VIA APPOINTMENTS
export function PatientOfCounsellor(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient-of-counsellor`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.PATIENT_OF_COUNSELLOR, payload: data.data })
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
            }
        })
    }
}



// FETCH INDIVIDUAL PATIENT APPOINTMENTS OF A PARTICULAR DOCTOR
export function PatientAppointments(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/patient-appointment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.COUNSELLOR_PATIENT_APPOINTMENT_LIST, payload: data.data })
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: data.ResponseText, Timeout: 5000 }));
            }
        })
    }
}

export function CounsellorPrescriptionsList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor-prescriptions`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.COUNSELLOR_PRESCRIPTIONS_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

// Add prescriptions from doctor end
export function SubmitPrescriptions(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-counsellor-prescription`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(CounsellorPrescriptionsList(data))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

// Selete prescriptions from doctor end
export function DeletePrescriptions(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-counsellor-prescription`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(PrescriptionsList(data))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}
// Delete Medical record from doctor end
export function DeleteMedicalRecord(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-medical-record`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(PatientMedicalRecordsList({ uniquekey: data.user_key }))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}


export function ArticleListing(uniquekey) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor-article-list`, {
            method: 'POST',
            body: JSON.stringify({ uniquekey: uniquekey }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({ type: actionTypes.COUNSELLOR_ARTICLE_LISTING, payload: responseData.data })
                return 1
            } else {
                return 0
            }
        })
    }
}


// ArticleListing from doctor end
export function AddArticles(data) {
    return dispatch => {
        dispatch(ShowLoader(true));

        let formData = new FormData();
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("uniquekey", data.uniquekey);
        formData.append("id", data.id);

        let imageFile = window.$('#image')[0].files[0];
        if (imageFile) {
            formData.append('image', imageFile);
        }

        const request = {
            method: "post",
            url: `${apiBaseUrl}/api/add-counsellor-article`,
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`,
            },
            data: formData,
        };
        return axios(request).then((data) => {
            dispatch(ShowLoader(false));
            data = data.data;
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: data.ResponseText, Timeout: 5000 }));
                dispatch(ArticleListing({ uniquekey: data.uniquekey }))
                return 1
            } else {
                swal({ title: data.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        }).catch((error) => {
            dispatch(ShowLoader(false));
            if (error && error.response && error.response.data) {
                dispatch(ShowAlert({ Class: "alert-danger", Message: error.response.data.ResponseText, Timeout: 1500 }));
                return 0;
            } else {
                dispatch(ShowAlert({ Class: "alert-danger", Message: 'Error Occured', Timeout: 1500 }));
                return 0;
            }
        });
    }
}
// ArticleListing from doctor end
export function DeleteArticle(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-counsellor-article`, {
            method: 'POST',
            body: JSON.stringify({ id: data.id }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                dispatch(ArticleListing({ uniquekey: data.uniquekey }))
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

export function GetArticle(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/get-counseller-article`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                return { response: 1, data: responseData.data }
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return { response: 0 }
            }
        })
    }
}



export function GetCounselorReviews(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor-review`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({ type: actionTypes.COUNSELOR_REVIEWS, payload: responseData.data })
                return 1;
            } else {
                return 0
            }
        })
    }
}

export function getInvoiceListing() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/counsellor/user-payment-listing`, {
            method: 'POST',
            // body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_PATIENT_INVOICES, payload: data.data });
            }
        });
    }
}

export function UploadHeaderFooter(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-counsellor-preview-prescription`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(getHeaderFooterImage())
                dispatch(ShowAlert({ Class: "alert-success", Message: responseData.ResponseText, Timeout: 5000 }));
                return 1
            } else {
                swal({ title: responseData.ResponseText, icon: "error", dangerMode: true })
                return 0
            }
        })
    }
}

export function getHeaderFooterImage() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/counsellor_prescription-view`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_HEADER_FOOTER_IMAGE, payload: data.data });
            }
        });
    }
}

export function UpdateStreamingStatus(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/edit-counsellor-streaming`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then(responseData => {
            if (responseData.ResponseCode === "1") {
                return 1
            } else {
                return 0
            }
        })
    }
}

export const fetchCounselorId = (data) => {
    return {
        type: actionTypes.FETCH_DOCTOR_ID,
        payload: data
    }
}

export function SubmitReview(data) {
    return (dispatch) => {
      dispatch(ShowLoader(true));
      const request = new Request(`${apiBaseUrl}/api/add-Counsellor-review`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Auth-Token': `${localStorage.getItem('token')}`,
        }),
      });
      return fetch(request)
        .then(handleResponse)
        .then((responseData) => {
          dispatch(ShowLoader(false));
          if (responseData.ResponseCode === '1') {
            swal({
              title: `Review ${data.reviewID ? `Updated` : `Added`} Successfully`,
              icon: 'success',
              dangerMode: true,
            });
            dispatch(CounsellorReviewsList({ uniquekey: data.loginkey }));
            return 1;
          } else {
            swal({
              title: responseData.ResponseText,
              icon: 'error',
              dangerMode: true,
            });
            return 0;
          }
        });
    };
  }


  export function CounsellorReviewsList(data) {
    return (dispatch) => {
      dispatch(ShowLoader(true));
      const request = new Request(`${apiBaseUrl}/api/counsellor/review-listing`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Auth-Token': `${localStorage.getItem('token')}`,
        }),
      });
      return fetch(request)
        .then(handleResponse)
        .then((responseData) => {
          dispatch(ShowLoader(false));
          if (responseData.ResponseCode === '1') {
            dispatch({
              type: actionTypes.COUNSELLOR_REVIEWS_LIST,
              payload: responseData.data,
            });
            return 1;
          } else {
            return 0;
          }
        });
    };
  }

  export function scheduleTimings(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/counsellor-timing-schedule`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                window.$('#add_time_slot').modal('hide');
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(scheduleTimingsView());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        });
    }
}

export function scheduleTimingsView() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/counsellor-timing-view`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_SCHEDULED_TIMING, payload: data.data})
                return 1;
            }
        });
    }
}

export function UpdateScheduleTimings(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/update-counsellor-timing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(scheduleTimingsView())
                return 1;
            }
        });
    }
}

export function GetTodayCounsellorAppointmentList(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/appointments/today`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch(ShowLoader(false));
                dispatch({type: actionTypes.FETCH_TODAY_COUNSELLOR_APPOINTMENT_LISTING,payload: data.data});
            } else {
                dispatch(ShowLoader(false));
                return 0;
            }
        });
    }
}

export function GetCounsellorAppointmentDetails() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor_appointments_record`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch(ShowLoader(false));
                dispatch({type: actionTypes.COUNSELLOR_APPOINTMENT_DETAILS,payload: data.data});
            } else {
                dispatch(ShowLoader(false));
                return 0;
            }
        });
    }
}

export function SocialMediaUrl(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add/edit-counselor-social-media`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(ViewSocialMediaUrl());
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:1500}));
                return 0;
            }
        });
    }
}

export function ViewSocialMediaUrl(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/view-counsellor-social-media`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.VIEW_SOCIAL_MEDIA_COUNSELLOR,payload: data.data});
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:1500}));
                return 0;
            }
        });
    }
}


export function CounsellorMedicalRecordsList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/Patientmedical-records-for-councellor`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.COUNSELLOR_MEDICAL_RECORDS_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

