import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bucketPath } from "./../store/helpers/common"; 
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { auth } from "../store/actions/globalActions";
import { withRouter } from "react-router-dom";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      isLogin:true,
    };
  }

  handleClick = (selected, e) => {
    this.setState({
      location: selected,
    });
  };
  logOutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    localStorage.removeItem("type");
    this.setState(
      {
        isLogin: false,
      },
      () => {
        this.props.dispatch(auth(this.state.isLogin))
        window.location = "/" 
      }
    );
  };

  render() {
    const { profile_info } = this.props; 
    const identity = localStorage.getItem('type');
    const DoctorPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info && profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/img/doctors/doctor-thumb-02.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>
                  Dr.{" "}
                  {`${
                    profile_info
                      ? `${profile_info.firstname} ${profile_info.lastname}`
                      : ""
                  }`}{" "}
                </h3>
                <div className="patient-details">
                  <h5 className="mb-0">
                    {`${
                      profile_info &&
                      profile_info.qualifications &&
                      profile_info.qualifications[0]
                        ? `${profile_info.qualifications[0].degree}`
                        : ""
                    }`}{" "}
                    -{" "}
                    {`${profile_info && profile_info.specialization_name ? `${profile_info.specialization_name}` : ""}`}
                  </h5>
                </div>
              </div>
              <div className="dr-fees-box ">
                <ul className="contects-ico">
                  <Link to="#">Status:</Link>
                  <li
                    className={this.state.chat ? "active-ico" : ""}
                    onClick={() => this.handleChange("chat")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-comment-alt"
                        id="chat"
                        data-tip
                        data-for="chat"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.chat ? "success" : "error"}
                        id="chat"
                      >
                        <span>
                          {this.state.chat ? "Available" : "Unavailable"} on
                          Chat
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                  <li
                    className={this.state.phone ? "active-ico" : ""}
                    onClick={() => this.handleChange("phone")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-phone"
                        id="phone"
                        data-tip
                        data-for="phone"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.phone ? "success" : "error"}
                        id="phone"
                      >
                        <span>
                          {this.state.phone ? "Available" : "Unavailable"} on
                          Audio
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                  <li
                    className={this.state.video ? "active-ico" : ""}
                    onClick={() => this.handleChange("video")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-video"
                        id="video"
                        data-tip
                        data-for="video"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.video ? "success" : "error"}
                        id="video"
                      >
                        <span>
                          {this.state.video ? "Available" : "Unavailable"} on
                          video
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li
                  className={`${
                    this.state.location === "dashboard" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/dashboard"
                    id="dashboard"
                    onClick={(e) => this.handleClick("dashboard", e)}
                  >
                    <i className="fas fa-columns"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "appointment" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/appointments"
                    id="Appointments"
                    onClick={(e) => this.handleClick("appointment", e)}
                  >
                    <i className="fas fa-calendar-check"></i>
                    <span>Appointments</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "patients" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/patients"
                    onClick={(e) => this.handleClick("patients", e)}
                  >
                    <i className="fas fa-user-injured"></i>
                    <span>My Patients</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "articles" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/article-videos"
                    onClick={(e) => this.handleClick("articles", e)}
                  >
                    <i className="fas  fa-file"></i>
                    <span>Articles/videos</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "reviews" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/reviews"
                    onClick={(e) => this.handleClick("reviews", e)}
                  >
                    <i className="fas fa-star"></i>
                    <span>Reviews</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "timing" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/schedule-timings"
                    onClick={(e) => this.handleClick("timing", e)}
                  >
                    <i className="fas fa-hourglass-start"></i>
                    <span>Set Roster</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "invoices" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/invoices"
                    onClick={(e) => this.handleClick("invoices", e)}
                  >
                    <i className="fas fa-file-medical-alt"></i>
                    <span>Invoices</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "profile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/profile-setting"
                    onClick={(e) => this.handleClick("profile", e)}
                  >
                    <i className="fas fa-user-cog"></i>
                    <span>Profile</span>
                  </Link>
                </li>
                {/* <li
                  className={`${
                    this.state.location === "messages" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/messages"
                    target="_blank"
                    onClick={(e) => this.handleClick("messages", e)}
                  >
                    <i className="fas fa-comments"></i>
                    <span>Messages</span>
                  </Link>
                </li> */}
                <li
                  className={`${
                    this.state.location === "setting" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/doctor/setting"
                    onClick={(e) => this.handleClick("setting", e)}
                  >
                    <i className="fas fa-share-alt"></i>
                    <span>Setting</span>
                  </Link>
                </li>
                <li onClick={(e) => this.logOutUser()} className="logout">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    const CounselorPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info && profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/img/doctors/doctor-thumb-02.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>
                  Dr.{" "}
                  {`${
                    profile_info
                      ? `${profile_info.firstname} ${profile_info.lastname}`
                      : ""
                  }`}{" "}
                </h3>
                <div className="patient-details">
                  <h5 className="mb-0">
                    {`${
                      profile_info &&
                      profile_info.qualifications &&
                      profile_info.qualifications[0]
                        ? `${profile_info.qualifications[0].degree}`
                        : "Unknown"
                    }`}{" "}
                    -{" "}
                    {`${profile_info ? `${profile_info.specialization_name}` : ""}`}
                  </h5>
                </div>
              </div>
              <div className="dr-fees-box ">
                <ul className="contects-ico">
                  <Link to="#">Status:</Link>
                  <li
                    className={this.state.chat ? "active-ico" : ""}
                    onClick={() => this.handleChange("chat")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-comment-alt"
                        id="chat"
                        data-tip
                        data-for="chat"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.chat ? "success" : "error"}
                        id="chat"
                      >
                        <span>
                          {this.state.chat ? "Available" : "Unavailable"} on
                          Chat
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                  <li
                    className={this.state.phone ? "active-ico" : ""}
                    onClick={() => this.handleChange("phone")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-phone"
                        id="phone"
                        data-tip
                        data-for="phone"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.phone ? "success" : "error"}
                        id="phone"
                      >
                        <span>
                          {this.state.phone ? "Available" : "Unavailable"} on
                          Audio
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                  <li
                    className={this.state.video ? "active-ico" : ""}
                    onClick={() => this.handleChange("video")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-video"
                        id="video"
                        data-tip
                        data-for="video"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.video ? "success" : "error"}
                        id="video"
                      >
                        <span>
                          {this.state.video ? "Available" : "Unavailable"} on
                          video
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li
                  className={`${
                    this.state.location === "dashboard" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/dashboard"
                    id="dashboard"
                    onClick={(e) => this.handleClick("dashboard", e)}
                  >
                    <i className="fas fa-columns"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "appointment" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/appointments"
                    id="Appointments"
                    onClick={(e) => this.handleClick("appointment", e)}
                  >
                    <i className="fas fa-calendar-check"></i>
                    <span>Appointments</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "patients" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/patients"
                    onClick={(e) => this.handleClick("patients", e)}
                  >
                    <i className="fas fa-user-injured"></i>
                    <span>My Patients</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "articles" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/article-videos"
                    onClick={(e) => this.handleClick("articles", e)}
                  >
                    <i className="fas  fa-file"></i>
                    <span>Articles/videos</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "reviews" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/reviews"
                    onClick={(e) => this.handleClick("reviews", e)}
                  >
                    <i className="fas fa-star"></i>
                    <span>Reviews</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "timing" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/schedule-timings"
                    onClick={(e) => this.handleClick("timing", e)}
                  >
                    <i className="fas fa-hourglass-start"></i>
                    <span>Set Roster</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "invoices" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/invoices"
                    onClick={(e) => this.handleClick("invoices", e)}
                  >
                    <i className="fas fa-file-medical-alt"></i>
                    <span>Invoices</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "profile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/profile-setting"
                    onClick={(e) => this.handleClick("profile", e)}
                  >
                    <i className="fas fa-user-cog"></i>
                    <span>Profile</span>
                  </Link>
                </li>
                {/* <li
                  className={`${
                    this.state.location === "messages" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/messages"
                    target="_blank"
                    onClick={(e) => this.handleClick("messages", e)}
                  >
                    <i className="fas fa-comments"></i>
                    <span>Messages</span>
                  </Link>
                </li> */}
                <li
                  className={`${
                    this.state.location === "setting" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/counsellor/setting"
                    onClick={(e) => this.handleClick("setting", e)}
                  >
                    <i className="fas fa-share-alt"></i>
                    <span>Setting</span>
                  </Link>
                </li>
                <li onClick={(e) => this.logOutUser()} className="logout">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    const FitnessPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info && profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/img/doctors/doctor-thumb-02.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>
                  {" "}
                  {`${
                    profile_info
                      ? `${profile_info.title}. ${profile_info.firstname} ${profile_info.lastname}`
                      : ""
                  }`}{" "}
                </h3>
                 <div className="patient-details">
                  <h5 className="mb-0">
                    {" "}
                    {`${
                      profile_info
                        ? `${profile_info.specialization} (${profile_info.experience}+year)`
                        : ""
                    }`}{" "} 
                  </h5>
                </div>
                {/* <div className="patient-details">
                  <h5 className="mb-0">
                    {`${
                      profile_info &&
                      profile_info.qualifications &&
                      profile_info.qualifications[0]
                        ? `${profile_info.qualifications[0].degree}`
                        : "Unknown"
                    }`}{" "}
                    -{" "}
                    {`${profile_info ? `${profile_info.specialization_name}` : ""}`}
                  </h5>
                </div> */}
              </div>
              <div className="dr-fees-box ">
                <ul className="contects-ico">
                  <Link to="#">Status:</Link>
                  {/* <li
                    className={this.state.chat ? "active-ico" : ""}
                    onClick={(e) => this.handleChange(e, "chat")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-comment-alt"
                        id="chat"
                        data-tip
                        data-for="chat"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.chat ? "success" : "error"}
                        id="chat"
                      >
                        <span>
                          {this.state.chat ? "Available" : "Unavailable"} on
                          Chat
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                  <li
                    className={this.state.phone ? "active-ico" : ""}
                    onClick={(e) => this.handleChange(e, "phone")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-phone"
                        id="phone"
                        data-tip
                        data-for="phone"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.phone ? "success" : "error"}
                        id="phone"
                      >
                        <span>
                          {this.state.phone ? "Available" : "Unavailable"} on
                          Audio
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li> */}
                  <li
                    className={this.state.video ? "active-ico" : ""}
                    onClick={() => this.handleChange("video")}
                  >
                    <Link to="#">
                      <i
                        className="fas fa-video"
                        id="video"
                        data-tip
                        data-for="video"
                      ></i>
                      <ReactTooltip
                        place="top"
                        type={this.state.video ? "success" : "error"}
                        id="video"
                      >
                        <span>
                          {this.state.video ? "Available" : "Unavailable"} on
                          video
                        </span>
                      </ReactTooltip>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li
                  className={`${
                    this.state.location === "dashboard" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/dashboard"
                    id="dashboard"
                    onClick={(e) => this.handleClick("dashboard", e)}
                  >
                    <i className="fas fa-columns"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "appointment" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/appointments"
                    id="Appointments"
                    onClick={(e) => this.handleClick("appointment", e)}
                  >
                    <i className="fas fa-calendar-check"></i>
                    <span>Appointments</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "patients" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/patients"
                    onClick={(e) => this.handleClick("patients", e)}
                  >
                    <i className="fas fa-user-injured"></i>
                    <span>My Customers</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "fitness-class" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/fitness-classes"
                    onClick={(e) => this.handleClick("fitness-class", e)}
                  >
                    <i className="fas fa-user-injured"></i>
                    <span>Fitness Classes</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "articles" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/article-videos"
                    onClick={(e) => this.handleClick("articles", e)}
                  >
                    <i className="fas  fa-file"></i>
                    <span>Articles/videos</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "reviews" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/reviews"
                    onClick={(e) => this.handleClick("reviews", e)}
                  >
                    <i className="fas fa-star"></i>
                    <span>Reviews</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "timing" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/schedule-timings"
                    onClick={(e) => this.handleClick("timing", e)}
                  >
                    <i className="fas fa-hourglass-start"></i>
                    <span>Set Classes Time</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "invoices" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/invoices"
                    onClick={(e) => this.handleClick("invoices", e)}
                  >
                    <i className="fas fa-file-medical-alt"></i>
                    <span>Invoices</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "profile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/profile-setting"
                    onClick={(e) => this.handleClick("profile", e)}
                  >
                    <i className="fas fa-user-cog"></i>
                    <span>Profile</span>
                  </Link>
                </li>
                {/* <li
                  className={`${
                    this.state.location === "messages" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/messages"
                    target="_blank"
                    onClick={(e) => this.handleClick("messages", e)}
                  >
                    <i className="fas fa-comments"></i>
                    <span>Messages</span>
                  </Link>
                </li> */}
                <li
                  className={`${
                    this.state.location === "setting" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/fitness/setting"
                    onClick={(e) => this.handleClick("setting", e)}
                  >
                    <i className="fas fa-share-alt"></i>
                    <span>Setting</span>
                  </Link>
                </li>
                <li onClick={(e) => this.logOutUser()} className="logout">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    const PatientPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info &&
                    profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/img/doctors/doctor-thumb-02.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>{profile_info?`${profile_info.firstname} ${profile_info.lastname}`: ""}</h3>
                <div className="patient-details">
                  {/* <h5><i className="fas fa-birthday-cake"></i> 24 Jul 1983, 38 years</h5> */}
                  {/* <h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {city},{state}</h5> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                {/* <li className={`${this.state.location==='dashboard' ? 'active':''}`}>
												<Link to="#" onClick={e=>this.handleClick('dashboard',e)}>
													<i className="fas fa-columns"></i>
													<span>Dashboard</span>
												</Link>
											</li> */}
                <li
                  className={`${
                    this.state.location === "appointment" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/appointment"
                    onClick={(e) => this.handleClick("appointment", e)}
                  >
                    <i className="fas fa-calendar-check"></i>
                    <span>Appointments</span>
                  </Link>
                </li>
                {/* <li
                  className={`${
                    this.state.location === "lab-appointment" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/lab-appointment"
                    onClick={(e) => this.handleClick("lab-appointment", e)}
                  >
                    <i className="fas fa-calendar-check"></i>
                    <span>Lab Appointments</span>
                  </Link>
                </li> */}
                <li
                  className={`${
                    this.state.location === "prescription" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/prescription"
                    onClick={(e) => this.handleClick("prescription", e)}
                  >
                    <i className="fas fa-laptop-medical"></i>
                    <span>Prescriptions</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "medical_records" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/medical_records"
                    onClick={(e) => this.handleClick("medical_records", e)}
                  >
                    <i className="fas fa-file-medical"></i>
                    <span>Medical Records</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "insurance" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/insurance"
                    onClick={(e) => this.handleClick("insurance", e)}
                  >
                    <i className="fas fa-clinic-medical"></i>
                    <span>Insurance</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "billing" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/invoices"
                    onClick={(e) => this.handleClick("billing", e)}
                  >
                    <i className="fas fa-file-medical-alt"></i>
                    <span>Invoices</span>
                  </Link>
                </li>
                {/* <li
                  className={`${
                    this.state.location === "favourites" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/favourites"
                    onClick={(e) => this.handleClick("favourites", e)}
                  >
                    <i className="fas fa-bookmark"></i>
                    <span>Favourites</span>
                  </Link>
                </li> */}
                <li
                  className={`${
                    this.state.location === "profile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/profile-settings"
                    onClick={(e) => this.handleClick("profile", e)}
                  >
                    <i className="fas fa-user-cog"></i>
                    <span>Profile Settings</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "password" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/patient/change-password"
                    onClick={(e) => this.handleClick("password", e)}
                  >
                    <i className="fas fa-lock"></i>
                    <span>Change Password</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    const LabPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info &&
                    profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/img/doctors/doctor-thumb-02.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>{`${
                  profile_info
                    ? `${profile_info.firstname} ${profile_info.lastname}`
                    : ""
                }`}</h3>
                <div className="patient-details">
                  {/* <h5><i className="fas fa-birthday-cake"></i> 24 Jul 1983, 38 years</h5> */}
                  {/* <h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {city}, {state}</h5> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li  className={`${
                    this.state.location === "lab_appointment" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/lab/appointments"
                    onClick={(e) => this.handleClick("lab_appointment", e)}
                  >
                    <i className="fas fa-calendar-check"></i>
                    <span>Appointments</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.state.location === "labtiming" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/lab/schedule-timing"
                    onClick={(e) => this.handleClick("labtiming", e)}
                  >
                    <i className="fas fa-hourglass-start"></i>
                    <span>Schedule Timings</span>
                  </Link>
                </li>
                <li className={`${
                    this.state.location === "lab_profile" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/lab/profile-setting"
                    onClick={(e) => this.handleClick("lab_profile", e)}
                  >
                    <i className="fas fa-columns"></i>
                    <span>Profile Settings</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    const ClinicPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/doctor/img/patients/patient.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>{`${
                  profile_info
                    ? `${profile_info.firstname} ${profile_info.lastname}`
                    : ""
                }`}</h3>
                <div className="patient-details">
                  {/* <h5><i className="fas fa-birthday-cake"></i> 24 Jul 1983, 38 years</h5> */}
                  {/* <h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {city},{state}</h5> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li>
                  <Link to="/clinic/profile-setting">
                    <i className="fas fa-columns"></i>
                    <span>Profile Settings</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    const PharmaPanel = () => {
      return (
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#" className="booking-doc-img">
                <img
                  src={
                    profile_info.profile_pic
                      ? `${bucketPath}${profile_info.profile_pic}`
                      : "./../assets/doctor/img/patients/patient.jpg"
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>{`${
                  profile_info
                    ? `${profile_info.firstname} ${profile_info.lastname}`
                    : ""
                }`}</h3>
                <div className="patient-details">
                  {/* <h5><i className="fas fa-birthday-cake"></i> 24 Jul 1983, 38 years</h5> */}
                  {/* <h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {city},{state}</h5> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li>
                  <Link to="/hospital/profile-setting">
                    <i className="fas fa-columns"></i>
                    <span>Profile Settings</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      );
    };
    return (
      <>
        {identity === "doctor" && <DoctorPanel />}
        {identity === "counselor" && <CounselorPanel />}
        {identity === "fitness" && <FitnessPanel />}
        {identity === "patient" && <PatientPanel />}
        {identity === "lab" && <LabPanel />}
        {(identity === "clinic" || identity === "hospital") && <ClinicPanel />}
		    {identity === "pharmacy" && <PharmaPanel />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile_info: state.user.profile_info,
});

export default withRouter(connect(mapStateToProps)(NavBar));
