import React, { Component } from "react";
import Modals from "../General/Modals";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { AdminSubscriptionChargeCreate } from "../../store/actions/adminActions";
import {SuggestLocation} from "../../store/actions/googleMapActions";
import { Link } from "react-router-dom";

const initialState = {
    area: "",
    selectedState: "",
    country: "",
    charge: "",
    uniquekey: "",
    areaArr: [],
    stateArr: [],
    countryArr:[]
};

class SubscriptionChargeForm extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.validator = new SimpleReactValidator();
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    setOpen = () => {
        this.setState({initialState}, () => {
            this.props.onFormModalClose();
            this.validator.hideMessages();
        })
    };

    onFormSubmit = async (event) => {
        event.preventDefault();
        const validator = this.validator;
        if (validator.allValid()) {
            const { selectedState, area, country, charge, uniquekey } = this.state;
            const { tab } = this.props;
            const postData = { state: selectedState, area, country, charge, uniquekey, type: tab === "fitness" ? "F" : tab === "doctor" ? "D" : "C" };
            const response = await this.props.AdminSubscriptionChargeCreate(
                postData
            );
            if (response === 1) {
                this.setOpen();
            } else{
                this.setState({
                    areaArr: [],
                    stateArr: [],
                    countryArr:[]
                });
            }
        } else {
            validator.showMessages();
            this.forceUpdate();
        }
    };


    UNSAFE_componentWillReceiveProps(props) {
        const { subscription } = props;

        if (Object.keys(subscription).length > 0) {
            this.setState({
                area: subscription.area,
                selectedState: subscription.state,
                country: subscription.country,
                charge: subscription.charge,
                uniquekey: subscription.unique_key,
            });
        } else {
            this.setState({
                area: "",
                selectedState: "",
                country: "",
                charge: "",
                uniquekey: "",
            });
        }
    }

    searchLocation = async (type,location) => {
        const apiResponse = await this.props.SuggestLocation({location});
        const {code, response } = apiResponse;
        if(code === 1) {
            const unqArr = [...new Set(response)];
            if(type === 'area') {
                this.setState({areaArr : unqArr})
            }
            if(type === 'state') {
                this.setState({stateArr : unqArr})
            }
            if(type === 'country') {
                this.setState({countryArr : unqArr})
            }
        }
    }

    render() {
        const { subscription } = this.props;
        const {areaArr, stateArr, countryArr} = this.state;
        return (
            <>
                <Modals open={this.props.openFormModal} setOpen={this.setOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {subscription ? "Update" : "Add"} Subscription
                            Charge
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.onFormSubmit}>
                            <div className="row form-row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Area</label>
                                        <input
                                            type="text"
                                            className="form-control dropdown-toggle"
                                            name="area"
                                            value={this.state.area}
                                            onChange={this.onInputChange}
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            onKeyUp={(e) => this.searchLocation('area', e.target.value)}
                                        />
                                            <div className="dropdown-menu dropdown-content dropdown-menu-left" style={{width:"99%"}}>
                                            {areaArr.map((area,index) => {
                                                return (<Link
                                                    className="dropdown-item"
                                                    to="#"
                                                    key={`area-${index}`}
                                                    onClick={(e) => {
                                                        let areaDescription = area.description.split(",");
                                                        this.setState(
                                                            { 
                                                                area: areaDescription[0],
                                                                selectedState: areaDescription[1],
                                                                country: areaDescription[2]
                                                            })
                                                    }}
                                                    >
                                                    {area.description}
                                                </Link>)
                                            })}
                                            </div>
                                    </div>
                                    <div className="form-group">
                                        <label>State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="selectedState"
                                            value={this.state.selectedState}
                                            onChange={this.onInputChange}
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            onKeyUp={(e) => this.searchLocation('state', e.target.value)}
                                        />
                                        <div className="dropdown-menu dropdown-content dropdown-menu-left" style={{width:"99%"}}>
                                        {stateArr.map((_state,index) => {
                                                return (<Link
                                                    className="dropdown-item"
                                                    to="#"
                                                    key={`state-${index}`}
                                                    onClick={(e) => {
                                                        let stateDescription = _state.description.split(",");
                                                        this.setState(
                                                            {
                                                                selectedState: stateDescription[0],
                                                                country: stateDescription[1]
                                                            }
                                                        )
                                                    }}
                                                    >
                                                    {_state.description}
                                                </Link>)
                                            })}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Country</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="country"
                                            value={this.state.country}
                                            onChange={this.onInputChange}
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            onKeyUp={(e) => this.searchLocation('country', e.target.value)}
                                        />
                                        {this.validator.message(
                                            "Country",
                                            this.state.country,
                                            "required"
                                        )}
                                        <div className="dropdown-menu dropdown-content dropdown-menu-left" style={{width:"99%"}}>
                                        {countryArr.map((country,index) => {
                                                return (<Link
                                                    className="dropdown-item"
                                                    to="#"
                                                    key={`country-${index}`}
                                                    onClick={(e) => {
                                                        this.setState(
                                                            { country : country.country }
                                                        )
                                                    }}
                                                    >
                                                    {country.country}
                                                </Link>)
                                            })}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Charge</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="charge"
                                            value={this.state.charge}
                                            onChange={this.onInputChange}
                                        />
                                        {this.validator.message(
                                            "Charge",
                                            this.state.charge,
                                            "required|numeric|min:0,num|max:999,num"
                                        )}
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-light-black btn-block">
                                Save Changes
                            </button>
                        </form>
                    </Modal.Body>
                </Modals>
            </>
        );
    }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { AdminSubscriptionChargeCreate, SuggestLocation })(
    SubscriptionChargeForm
);
