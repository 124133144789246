import { options } from "./Options";

let connection = null;
let JitsiMeetJS = null;

export const jitsiConnection = (JitsiMeetJS) => {
    JitsiMeetJS = JitsiMeetJS;
    connection = new JitsiMeetJS.JitsiConnection(null, null, options);
    connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        onConnectionSuccess
    );
    connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        onConnectionFailed
    );
    connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        disconnect
    );

    connection.connect();
    return connection;
};

function onConnectionSuccess() {
    console.log("Connection Success");
}

function onConnectionFailed() {
    console.log("Connection Failed!");
}

function disconnect() {
    console.log("disconnect!");
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        onConnectionSuccess
    );
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        onConnectionFailed
    );
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        disconnect
    );
}

export const makeRandomString = (length) => {
    let result = "";
    const characters =
        "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};
