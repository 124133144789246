import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';
import AdminDoctorList from './FitnessExperts';
import AdminFitnessClass from './AdminFitnessClass'; 

class AdminFitness extends Component {
    state = {tab:'fitness-expert'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="fitness-expert"?"active":""}`} to="#" onClick={e=>this.setState({tab:'fitness-expert'})} >Fitness Experts</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="fitness-class"?"active":""}`} to="#" onClick={e=>this.setState({tab:'fitness-class'})}>Fitness Classes</Link>
                                </li> 
                            </ul>
                        </nav>
                        {tab==="fitness-expert" && <AdminDoctorList />}
                        {tab==="fitness-class" && <AdminFitnessClass />} 
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminFitness