import { createBrowserHistory } from 'history';

export const apiBaseUrl         = 'https://api.adidoc.com';
export const adminbaseUrl       = 'https://adidoc.com:8080'
export const history            = createBrowserHistory()
export const geolocUrl          = "http://ip-api.com/json";
export const googlemapKey       = "AIzaSyAc3os-e7DCcBZuE_p9J_a9UTeq0CQ6xsU";
// export const sendgridKey        = "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";
 export const sendgridKey        = "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";

 export const bucketPath         = "https://storage.googleapis.com/adidoc-d1485.appspot.com/";

 export const FirstLetterCapital = (string) => {
     return string.charAt(0).toUpperCase() + string.slice(1);
       }
 export const weburl            = "https://adidoc.com/"
