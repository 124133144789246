import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Alerts from "./../components/General/Alerts";
import { globalUrl } from "./../store/helpers/globalUrls";
const globalUrls = globalUrl();
class MainLayout extends Component {
  render() {
    let getIdentity = localStorage.getItem("token");
    let currentPath = this.props.match.path;
    let splitter    = currentPath.split("/");

    // console.log("==============================>",splitter[1] )
    // console.log("==============================>",globalUrls.includes(splitter[1]))
    return (
      <div className="main-wrapper">
        <Header />
        {(getIdentity && (splitter[1] && !globalUrls.includes(splitter[1]))) && (
          <div id="page_container" className="toggled">
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                    <NavBar />
                  </div>
                  <div className="col-md-7 col-lg-8 col-xl-9">
                    <Alerts />
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(getIdentity && (currentPath==="/")) && <div className="page_content">{this.props.children}</div>}
        {(!getIdentity || (splitter[1] && globalUrls.includes(splitter[1]))) && <div className="page_content"><Alerts />{this.props.children}</div>}
        <Footer />
      </div>
    );
  }
}

export default MainLayout;
