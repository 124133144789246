import React, { Component } from 'react';
import PatientAppointment from './PatientAppointment';
import PatientCounsellorAppointment from './PatientCounsellorAppointment';
import PatientFitnessAppointment from './PatientFitnessAppointment';
import {Link} from 'react-router-dom';

class Appointment extends Component {
    state = {tab:'doctor'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="doctor"?"active":""}`} to="#" onClick={e=>this.setState({tab:'doctor'})} >Doctor Appointment</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="counsellor"?"active":""}`} to="#" onClick={e=>this.setState({tab:'counsellor'})}>Counsellor Appointment</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="fitness"?"active":""}`} to="#" onClick={e=>this.setState({tab:'fitness'})}>Fitness Appointment</Link>
                                </li>
                            </ul>
                        </nav>
                        {tab==="doctor" && <PatientAppointment/>}
                        {tab==="counsellor" && <PatientCounsellorAppointment/>}
                        {tab==="fitness" && <PatientFitnessAppointment/>}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Appointment