import React from "react";
import { Modal } from "react-bootstrap";

const Modals = (props) => {
  return (
    <Modal
      show={props.open}
      onHide={() => props.setOpen(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
      size={props.size?props.size:'lg'}
    >
      {props.children}
    </Modal>
  );
};
export default Modals;
