import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';  

class HealthArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
        
    }
  }

   
   
  render() { 
    return (
        <div className="page-content background">  
            <div className="container-fluid"> 
                <div className="row">
                    <div className="doctor-list-section col-md-8">  
                        <div className="grid-view-div"> 
                            {/* first topic start here */}
                            <div className="doctor-list-box"> 
                                <div className="row list-repeat-row">
                                    <div className="health-feed-hed">
                                        <div className="col-md-12 ">
                                            <h2>How To Get Rid Of Acne Scars?</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-2 align-self-center doctor-list-img doctor-health-topic-img text-center">
                                        <Link to="doctor-details.html"><img src="./../assets/images/dr-1.png" alt="..." /></Link>
                                    </div>
                                    <div className="col-md-10 doctor-detail-box">
                                        <h2 className="dr-name"><Link to="doctor-details.html">Dr. Ankur Vats</Link>
                                            <Link to="#"><i className="fas fa-heart"></i></Link>
                                            <span className="dr-specification float-right">Dermatologist</span></h2> 
                                        <p className="dr-qualification">MBBS, MD - Dermatology , Venereology & Leprosy
                                        <Link to="#" className="consult-online-text float-right">Consult Online</Link>
                                        </p> 
                                    </div>
                                </div>
                            </div> 
                             <div className="doctor-list-box"> 
                                 <div className="row list-repeat-row">
                                     <div className="col-md-12">
                                          <Link to="#"><img src="./../assets/images/acne-image.jpg" className="img-fluid"
                                                  alt="..." /></Link>
                                     </div>
                                     <div className="health-topic-detail-box">
                                         <div className="col-md-12">
                                             <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                            dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                            book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                                            unchanged. It was popularised in the 1960s with the release of Letraset sheets <Link to="#" className="read-more-text">read more...</Link></p>
                                            
                                         </div>
                                     </div>
                                 </div>
                             </div>
                            {/* first topic end here */} 
                            {/* second topic start here */}
                            <div className="doctor-list-box">
                                <div className="row list-repeat-row">
                                    <div className="health-feed-hed">
                                        <div className="col-md-12 ">
                                            <h2>Suppression Of Immune System - How It Affects The Chance Of Conceiving With IVF?</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-2 align-self-center doctor-list-img doctor-health-topic-img text-center">
                                        <Link to="doctor-details.html"><img src="./../assets/images/dummy-img.png" alt="..." /></Link>
                                    </div>
                                    <div className="col-md-10 doctor-detail-box">
                                        <h2 className="dr-name"><Link to="doctor-details.html">Dr. Johan</Link>
                                            <Link to="#"><i className="fas fa-heart"></i></Link>
                                            <span className="dr-specification float-right">IVF Specialist</span></h2>
                                        <p className="dr-qualification">MBBS
                                        <Link to="#" className="consult-online-text float-right">Consult Online</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="doctor-list-box">
                                <div className="row list-repeat-row">
                                    <div className="health-topic-detail-box">
                                        <div className="col-md-12">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                                book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                                            unchanged. It was popularised in the 1960s with the release of Letraset sheets <Link to="#" className="read-more-text">read more...</Link></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* second topic end here */} 
                            {/* first topic start here */}
                            <div className="doctor-list-box">
                                <div className="row list-repeat-row">
                                    <div className="health-feed-hed">
                                        <div className="col-md-12 ">
                                            <h2>How Natural IVF Differs From Mini IVF?</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-2 align-self-center doctor-list-img doctor-health-topic-img text-center">
                                        <Link to="doctor-details.html"><img src="./../assets/images/dummy-img.png" alt="..." /></Link>
                                    </div>
                                    <div className="col-md-10 doctor-detail-box">
                                        <h2 className="dr-name"><Link to="doctor-details.html">Dr. Dev</Link>
                                            <Link to="#"><i className="fas fa-heart"></i></Link>
                                            <span className="dr-specification float-right">IVF Specialist</span></h2>
                                        <p className="dr-qualification">MBBS
                                        <Link to="#" className="consult-online-text float-right">Consult Online</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="doctor-list-box">
                                <div className="row list-repeat-row">
                                    <div className="col-md-12">
                                        <Link to="#"><img src="./../assets/images/dummy-flat.png" className="img-fluid"
                                            alt="..." /></Link>
                                    </div>
                                    <div className="health-topic-detail-box">
                                        <div className="col-md-12">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                                book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                                            unchanged. It was popularised in the 1960s with the release of Letraset sheets <Link to="#" className="read-more-text">read more...</Link></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* first topic end here */} 
                        </div> 
                </div>
                <div className="col-md-4 doctor-list-right-side-sec"> 
                     <div className="dr-list-ask-question">
                         <div className="row">
                             <div className="col-md-12">
                                 <h2><i className="far fa-envelope"></i>&nbsp; Ask a FREE Question</h2>
                             </div>
                         </div> 
                         <div className="dr-list-ask-question-textbox">
                             <div className="row">
                                 <div className="col-md-12 align-self-center">
                                   <h4>Get FREE multiple opinions from Doctors</h4>
                                   <textarea className="form-control"
                                       placeholder="Enter question for you or someone else. E.g. my left eye is red and swollen..."
                                       id="exampleFormControlTextarea1" rows="5"></textarea>
                                 </div>
                             </div>
                             <div className="row">
                                 <div className="col-md-8 align-self-center">
                                      <p>Posted anonymously</p>
                                 </div>
                                 <div className="col-md-4 align-self-center">
                                    <Link to="#" className="btn btn-light-black  float-right">Submit</Link>
                                 </div>
                             </div>
                         </div> 
                     </div> 
                    <div className="dr-list-health-topic-box">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Top Health Tips</h2>
                            </div>
                        </div> 
                         <div className="dr-list-topic-list-box">
                            <div className="row">
                                <div className="col-lg-7 col-md-12 align-self-center">
                                        <h5><Link to="/health-feed">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                   <img src="./../assets/images/tp-1.png" alt="..." /> 
                                </div>
                            </div>
                        </div> 
                          <div className="dr-list-topic-list-box">
                          <div className="row">
                                <div className="col-lg-7 col-md-12 align-self-center">
                                    <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                   <img src="./../assets/images/tp-1.png" alt="..." /> 
                                </div>
                                </div>
                          </div> 
                           <div className="dr-list-topic-list-box">
                            <div className="row">
                                    <div className="col-lg-7 col-md-12 align-self-center">
                                        <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                    <img src="./../assets/images/tp-1.png" alt="..." /> 
                                    </div>
                                </div>
                           </div> 
                            <div className="dr-list-topic-list-box">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 align-self-center">
                                        <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                    <img src="./../assets/images/tp-1.png" alt="..." /> 
                                    </div>
                                </div>
                            </div> 
                    </div>
                </div>
            </div>    
        </div>
    </div>
    );
  };
}

const mapStateToProps = (state) => ({ 
});

export default connect(mapStateToProps)(HealthArticle);
