import React, { Component } from 'react';


class CounsellorTemplate extends Component {
    render() {
        return (
            <></>
        );
    }
}

export default CounsellorTemplate;
