import React, { Component } from "react";
import { bucketPath } from './../store/helpers/common';

class CounsellorReview extends Component {
    render() {
        const {
            userID,
            creationdate,
            description,
            points,
            user_name,
            profile_pic
        } = this.props.reviewData;
        return (
            <li>
                <div className="comment">
                    <img
                        className="avatar avatar-sm rounded-circle"
                        alt="User "
                        src={`${profile_pic ? bucketPath + profile_pic : "./..//assets/img/patients/patient.jpg"}`}
                    />
                    <div className="comment-body" style={{ width: "100%" }}>
                        <div className="meta-data">
                            <span className="comment-author">{user_name}</span>
                            <span className="comment-date">{creationdate}</span>
                            <div className="review-count rating" style={{display: "block"}}>
                                {[...Array(5).keys()].map((val, i) => {
                                    return (
                                        <i
                                            key={`star_${i}`}
                                            className={`fas fa-star ${
                                                val + 1 <= points
                                                    ? "filled"
                                                    : ""
                                            }`}
                                        ></i>
                                    );
                                })}
                                {this.props.uderID === userID && (
                                    <>
                                        <br />
                                        <button
                                            className="btn btn-sm btn-secondary"
                                            onMouseUp={() =>
                                                this.props.editReview(
                                                    this.props.reviewData
                                                )
                                            }
                                        >
                                            Edit
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <p className="recommended">
                            <i className="far fa-thumbs-up"></i> I recommend the
                            doctor
                        </p> */}
                        <p className="comment-content">{description}</p>
                        {/* <div className="comment-reply">
                            <Link className="comment-btn" to="#">
                                <i className="fas fa-reply"></i> Reply
                            </Link>
                            <p className="recommend-btn">
                                <span>Recommend?</span>
                                <Link to="#" className="like-btn">
                                    <i className="far fa-thumbs-up"></i> Yes
                                </Link>
                                <Link to="#" className="dislike-btn">
                                    <i className="far fa-thumbs-down"></i> No
                                </Link>
                            </p>
                        </div> */}
                    </div>
                </div>
                {/* <ul className="comments-reply">
                    <li>
                        <div className="comment">
                            <img
                                className="avatar avatar-sm rounded-circle"
                                alt="User "
                                src="./..//assets/img/patients/patient1.jpg"
                            />
                            <div className="comment-body">
                                <div className="meta-data">
                                    <span className="comment-author">
                                        Charlene Reed
                                    </span>
                                    <span className="comment-date">
                                        Reviewed 3 Days ago
                                    </span>
                                    <div className="review-count rating">
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star filled"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                </div>
                                <p className="comment-content">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam. Curabitur non nulla
                                    sit amet nisl tempus
                                </p>
                                <div className="comment-reply">
                                    <Link className="comment-btn" to="#">
                                        <i className="fas fa-reply"></i> Reply
                                    </Link>
                                    <p className="recommend-btn">
                                        <span>Recommend?</span>
                                        <Link to="#" className="like-btn">
                                            <i className="far fa-thumbs-up"></i>{" "}
                                            Yes
                                        </Link>
                                        <Link to="#" className="dislike-btn">
                                            <i className="far fa-thumbs-down"></i>{" "}
                                            No
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul> */}
            </li>
        );
    }
}

export default CounsellorReview;
