import React from 'react';
import { connect } from 'react-redux';  
import {adminLogin} from './../../store/actions/adminActions'; 
import SimpleReactValidator from 'simple-react-validator'; 


class AdminLogin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {email:"",password:"",rememberMe:true}
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = props => {
        this.token      = localStorage.getItem('admin-token')
        this.storage    = JSON.parse(localStorage.getItem('admin-identity'))
        if(this.token!=="" && this.storage){
            this.props.history.push('/admin/dashboard')
        }
    }
    
    loginForm = async  () => {
        
        const {email,password} = this.state
        let data ={email:email,password:password}
        if (this.validator.allValid()) {
            const res = await this.props.dispatch(adminLogin(data))         
                if(res === 1){
                    this.props.history.push('/admin/dashboard')
                }
          } else {
            this.validator.showMessages();
            this.forceUpdate();
          }        
    }

    render() {
        return (
            <div>
                {/* <div className="clearfix"></div>
                <div className="page-content background">
                    <div className="container">
                        <div className="login-sec col-md-10 offset-md-1">
                            <div className="row col-md-12">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <h2>Login</h2>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email address</label>
                                        <input type="text" className="form-control" name="email" id="email" onChange={e=>this.setState({[e.target.name]:e.target.value})} aria-describedby="emailHelp" />
                                        {this.validator.message('Email', this.state.email, 'required|email')}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" name="password" id="password" onChange={e=>this.setState({[e.target.name]:e.target.value})}  />
                                        {this.validator.message('Password', this.state.password, 'required')}
                                    </div>
                                    <div className="form-group form-check row">
                                        <div className="col-md-12">
                                            <div className="text-right">
                                                <a href="#"> Forgot Password?</a>
                                            </div>   
                                        </div>                                
                                    </div>
                                    <div className="form-group login-signup-btn text-center">
                                        <button type="button" className="btn btn-pink btn-block" onClick={()=>this.loginForm()}>Login</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-3"></div>
                            </div>
                        </div>
                    </div>
                </div> */}

         <div className="main-wrapper login-body">
            <div className="login-wrapper">
            	<div className="container">
                	<div className="loginbox">
                    	<div className="login-left">
							<img className="img-fluid" src="./../assets/images/login-img.png" alt="Logo" />
                        </div>
                        <div className="login-right">
							<div className="login-right-wrap">
								<h1>Login</h1>
								<p className="account-subtitle">Access to our dashboard</p>
								 
								<form action="index.html">
									<div className="form-group">
                                    <input type="text" className="form-control" name="email" id="email" onChange={e=>this.setState({[e.target.name]:e.target.value})} aria-describedby="emailHelp" />
                                        {this.validator.message('Email', this.state.email, 'required|email')}
									</div>
									<div className="form-group">
                                    <input type="password" className="form-control" name="password" id="password" onChange={e=>this.setState({[e.target.name]:e.target.value})}  />
                                        {this.validator.message('Password', this.state.password, 'required')}
									</div>
									<div className="form-group">
                                        <button type="button" className="btn btn-pink btn-block" onClick={()=>this.loginForm()}>Login</button>
									</div>
								</form> 
{/* 								
								<div className="text-center forgotpass"><a href="forgot-password.html">Forgot Password?</a></div>
								<div className="login-or">
									<span className="or-line"></span>
									<span className="span-or">or</span>
								</div> 
								<div className="social-login">
									<span>Login with</span>
									<a href="#" className="facebook"><i className="fa fa-facebook"></i></a><a href="#" className="google"><i className="fa fa-google"></i></a>
								</div> 
								
								<div className="text-center dont-have">Don’t have an account? <a href="register.html">Register</a></div> */}
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            </div>
        )
    }
}
export default connect()(AdminLogin)