
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PatientOfFitnessExpert,PatientAppointments } from '../../store/actions/fitnessActions';
import Pagination from '../../store/helpers/Pagination';
// import CounsellorPrescription from '../patientPanel/CounsellorPrescription';
// import CounsellorMedicalRecords from '../patientPanel/CounsellorMedicalRecords';
// import FitnessBilling from '../patientPanel/CounsellorMedicalRecords';
import {bucketPath} from '../../store/helpers/common'
import moment from 'moment';

class FitnessPatientProfile extends React.Component {
	constructor(props) {
		super(props)
		this.state = { pageOfItems: [], currentPage: 1, patientDetail: {} };
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		const data = { patientID: id };
		this.props.dispatch(PatientOfFitnessExpert(data))
		this.props.dispatch(PatientAppointments(data));
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	calculateAge(birthday) {
		var ageDifMs = Date.now() - new Date(birthday).getTime();
		var ageDate = new Date(ageDifMs);
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.doctor_patient_appoinments) {
			this.setState({ patientDetail: props.doctor_patient_appoinments[0] })
		}
	}

	render() {
		const {patient_listing,appointment_listing } = this.props;
		const { pageOfItems } = this.state;
		return (
			<div className="container-fluid">
				<div className="card widget-profile pat-widget-profile">
					<div className="card-body patient-profile-top-bar">
						<div className="row">
							<div className="pro-widget-content col-md-10">
								<div className="profile-info-widget">
									<div className="row">
										<div className="col-lg-2 col-md-4 text-center align-self-center">
											<Link to="#" className="booking-doc-img ">
												<img src={patient_listing.profile_pic?`${bucketPath}${patient_listing.profile_pic}`:`/assets/doctor/img/patients/patient.jpg`} alt="Patient" />
											</Link>
										</div>
										<div className="profile-det-info col-lg-10 col-md-8 p-0 align-self-center">
											<h3>{`${patient_listing.firstname} ${patient_listing.lastname}`}</h3>
											<div className="patient-details">
												<h5><b>Patient ID :</b> {this.props.match.params.id}</h5>
												<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {`${patient_listing.address} ${patient_listing.state} ${patient_listing.country}`}</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="patient-info col-lg-2 col-md-12">
								<ul>
									<li>Age: <span>{patient_listing.dob?`${moment().diff(patient_listing.dob, 'years')} Years`:""}, {patient_listing.gender === 'M' ? 'Male':patient_listing.gender==="F"?'Female':"Trans"}</span></li>
									<li>Blood Group: <span>{patient_listing.blood_group} </span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-body pt-0">
						<div className="user-tabs">
							<ul className="nav nav-tabs nav-tabs-bottom nav-justified flex-wrap">
								<li className="nav-item">
									<a className="nav-link active" href="#pat_appointments" data-toggle="tab">Appointments</a>
								</li>
								{/* <li className="nav-item">
									<a className="nav-link" href="#pres" data-toggle="tab"><span>Prescription</span></a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#medical" data-toggle="tab"><span className="med-records">Medical Records</span></a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#billing" data-toggle="tab"><span>Billing</span></a>
								</li> */}
							</ul>
						</div>
						<div className="tab-content">
							<div id="pat_appointments" className="tab-pane fade show active">
								<div className="card card-table mb-0">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-hover table-center mb-0">
												<thead>
													<tr>

														<th>Appointment Date</th>
														<th>Booking Date</th>
														<th>Amount</th>
														<th>Status</th>
														<th>Consult Type</th>
													</tr>
												</thead>
												<tbody>
													{pageOfItems.map((value, key) => {
														return (<tr key={`page_${key}`}>
															<td>{value.appointment_date} <span className="d-block text-info">{value.starttime}</span></td>
															<td>{value.creationdate}</td>
															<td>{0}</td>
															<td><span className="badge badge-pill bg-success-light">
																{
																	value.status === 'C'
																		? 'Confirm'
																		: value.status === 'P'
																			? 'Pending'
																			: 'New'
																}
															</span></td>
															<td className="">
																<div className="table-action">
																	<Link to="#" target="_blank" className="btn btn-sm bg-warning-light">
																		<i
																			className={`fas ${
																				value.appointment_type === 'Chat'
																					? 'fa-comment-alt'
																					: value.appointment_type === 'Video'
																						? 'fas fa-video'
																						: 'fas fa-phone'
																				}`}
																		></i>
																		{' '}
																		{value.appointment_type === 'Audio'
																			? 'Audio'
																			: value.appointment_type === 'Video'
																				? 'Video'
																				: 'Audio'}{' '}
																			Call
																		</Link>
																</div>
															</td>
														</tr>)
													})
													}
												</tbody>
											</table>
											{appointment_listing.length > 0 && (
												<Pagination
													pageSize={20}
													items={appointment_listing}
													onChangePage={this.onChangePage}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* <div className="tab-pane fade" id="pres">
								<CounsellorPrescription user_key={this.props.match.params.id} />
							</div>
							<div className="tab-pane fade" id="medical">
								<CounsellorMedicalRecords user_key={this.props.match.params.id} />
							</div> */}
							{/* <div className="tab-pane" id="billing">
								<FitnessBilling user_key={this.props.match.params.id} />
							</div> */}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	patient_listing: state.fitness.patient_of_fitness_expert,
	appointment_listing: state.fitness.patient_appointment,
});

export default connect(mapStateToProps)(FitnessPatientProfile);
