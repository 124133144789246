
import React from 'react'
import { Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import {ChangePasswordApi} from './../../store/actions/registerationActions';
import {connect} from 'react-redux';

const initialState = {password: '',new_password: '',confirm_password: "",oldPasswordHidden:true,newPasswordHidden:true,confirmPasswordHidden:true,passwordtypinginfoshow:true,new_passwordtypinginfoshow:true,confirm_passwordtypinginfoshow:true};
class ChangePassword extends React.Component {
	constructor(props) {
		super(props)
		this.state = initialState;
		this.validator = new SimpleReactValidator({
			messages: {
				in: 'New Password and Confirm Password need to be same!'
			  }
		});
	}

	UNSAFE_componentWillMount () {
		this._isMounted = true;
	}

	onChangeHandler = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onKeyUpHandler = (e) => {
		if (e.keyCode === 13) {
			this.submit.focus();
			this.submit.click();
		}
	}

	onClickHandler = async () => {
		let validator = this.validator;
		const {password,new_password,confirm_password} = this.state;
		if(validator.allValid()){
			let data = {oldpassword:password,newpassword:new_password,confirmpassword:confirm_password}
			let response = await this.props.dispatch(ChangePasswordApi(data));
			if(response===1){
				this.setState(initialState);
			}
		}else{
			validator.showMessages();
			this.setState({
				passwordtypinginfoshow:false,
				new_passwordtypinginfoshow:false,
				confirm_passwordtypinginfoshow:false
			})
			this.forceUpdate()
		}
	}

	componentWillUnmount(){
		this._isMounted = false;
	}

	toggleNewPasswordShow = () =>{
    this.setState({
      newPasswordHidden: !this.state.newPasswordHidden
    })
  }
  toggleConfirmPasswordShow = () =>{
    this.setState({
      confirmPasswordHidden: !this.state.confirmPasswordHidden
    })
  }

	toggleOldPasswordShow = () =>{
    this.setState({
      oldPasswordHidden: !this.state.oldPasswordHidden
    })
  }

	render() {
		const { password, new_password, confirm_password } = this.state;
		return (
			<div className="card">
				<div className="card-body">
					{this._isMounted && <div className="row">
						<div className="col-md-12 col-lg-6">
							<form className="change-password-area">
								<div className="form-group">
									<label>Old Password</label>
									<input
										type={this.state.oldPasswordHidden ? "password" : "text"}
										placeholder="eg: Abc@123"
										name="password"
										onChange={this.onChangeHandler.bind(this)}
										value={password}
										className="form-control"
										autoComplete = "off"
										ref={(input) => { this.password = input }}
									/>
									{this.state.oldPasswordHidden
										? ( <i className="far fa-eye eye-password-icon"  onClick={() =>this.toggleOldPasswordShow()}></i> )
										: (<i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.toggleOldPasswordShow()}></i>)
									}
									{this.state.password.length < 6 && this.state.passwordtypinginfoshow ? <div className="srv-validation-message">The  password must be at least 6 characters.</div> :this.validator.message('Password',this.state.password,'required|min:6,string')}
								</div>
								<div className="form-group">
									<label>New Password</label>
									<input
										type={this.state.newPasswordHidden ? "password" : "text"}
										placeholder="eg: Abc@1234"
										name="new_password"
										onChange={this.onChangeHandler.bind(this)} value={new_password}
										className="form-control"
										autoComplete="off"
										ref={(input) => { this.new_password = input }}
									/>
									{this.state.newPasswordHidden
										? ( <i className="far fa-eye eye-password-icon"  onClick={() =>this.toggleNewPasswordShow()}></i>)
										: ( <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.toggleNewPasswordShow()}></i>)
									}
									{this.state.new_password.length < 6 && this.state.new_passwordtypinginfoshow ? <div className="srv-validation-message">The New password must be at least 6 characters.</div> :this.validator.message('New Password',this.state.new_password,'required|min:6,string')}
								</div>
								<div className="form-group">
									<label>Confirm Password</label>
									<input
										type={this.state.confirmPasswordHidden ? "password" : "text"}
										placeholder="eg: Abc@1234"
										name="confirm_password"
										onChange={this.onChangeHandler.bind(this)}
										value={confirm_password}
										className="form-control"
										autoComplete="off"
										ref={(input) => { this.confirm_password = input }}
										onKeyUp={this.onKeyUpHandler.bind(this)}
									/>
									{this.state.confirmPasswordHidden
										? ( <i className="far fa-eye eye-password-icon"  onClick={() =>this.toggleConfirmPasswordShow()}></i> )
										: ( <i className="fa fa-eye-slash eye-password-icon" aria-hidden="true" onClick={() =>this.toggleConfirmPasswordShow()}></i>)
									}
									{this.state.confirm_password.length < 6 && this.state.confirm_passwordtypinginfoshow ? <div className="srv-validation-message">The  Confirm Password must be at least 6 characters.</div> :this.validator.message('Confirm Password',this.state.confirm_password,`required|in:${this.state.new_password}|min:6,string`)}
								</div>
								<div className="submit-section">
									<Link to="#" ref={(input) => { this.submit = input }} className="btn btn-light-black submit-btn" onClick={this.onClickHandler.bind()}>Save Changes</Link>
								</div>
							</form>
						</div>
					</div>}
				</div>
			</div>
		)
	}
}

export default connect()(ChangePassword);
