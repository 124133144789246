import * as types from './../types/types';

const initalState = {
  profile_info: [],
  selected_counsellor_profile_info: [],
  counsellor_profile_info: [],
  isLoggedIn: false,
  doctor_search_info: '',
  chat_history: [],
  reviews_list: [],
  login_token: null,
  location: null,
  fitnessExpertListing: [],
  home_article: [],
  scheduled_timing: [],
  fitness_appointment_listing: [],
  fitness_article_list: [],
  fitness_recording_listing: [],
  view_social_media_fitness: [],
  invoice_listing: [],
  fitness_reviews: [],
  patient_of_fitness_expert: [],
  patient_appointment: [],
  fitness_appointment_details: [],
  fitness_medical_records: [],
  fitness_today_appointments:[],
  fitness_class_listing:[],
  view_fitness_class:[],
  global_fitness_classes:[],
  global_view_fitness_class:[],
  fitness_checkout_details:[]
}

const fitnessReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case types.FETCH_FITNESSEXPERT_LISTING:
      return {
        ...state,
        loading: false,
        fitnessExpertListing: action.payload
      }
    case types.VIEW_HOME_ARTICLE:
      return {
        ...state,
        loading: false,
        home_article: action.payload
      }
    case types.FITNESS_EXPERT_PROFILE_INFO:
      return {
        ...state,
        profile_info: action.payload,
      };
    case types.FETCH_PATIENT_INVOICES:
      return {
        ...state,
        invoice_listing: action.payload
      }
    case types.SELECTED_COUNSELLOR_PROFILE_INFO:
      return {
        ...state,
        selected_counsellor_profile_info: action.payload,
      };
    case types.DOCTOR_SEARCH_INFO:
      return {
        ...state,
        doctor_search_info: action.payload,
      };
    case types.SUPPORT_CHAT_HISTORY:
      return {
        ...state,
        chat_history: action.payload,
      };
    case types.REVIEWS_LIST:
      return {
        ...state,
        reviews_list: action.payload,
      };
    case types.FITNESS_APPOINTMENT_DETAILS:
      return {
        ...state,
        loading: false,
        fitness_appointment_details: action.payload
      }
    case types.FETCH_TODAY_FITNESS_APPOINTMENT_LISTING:
      return {
        ...state,
        loading: false,
        fitness_today_appointments: action.payload
      }
    case types.FIREBASE_LOGIN_TOKEN:
      return {
        ...state,
        login_token: action.payload,
      };
    case types.COUNSELLOR_PROFILE_INFO:
      return {
        ...state,
        counsellor_profile_info: action.payload,
      };
    case types.STORE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case types.FETCH_SCHEDULED_TIMING:
      return {
        ...state,
        loading: false,
        scheduled_timing: action.payload
      }
    case types.FITNESS_APPOINTMENT_LISTING:
      return {
        ...state,
        loading: false,
        fitness_appointment_listing: action.payload
      }
    case types.FITNESS_ARTICLE_LISTING:
      return {
        ...state,
        fitness_article_list: action.payload
      }
    case types.FITNESS_RECORDING_LISTING:
      return {
        ...state,
        fitness_recording_listing: action.payload
      }
    case types.VIEW_SOCIAL_MEDIA_FITNESS:
      return {
        ...state,
        loading: false,
        view_social_media_fitness: action.payload
      }
    case types.FITNESS_REVIEWS:
      return {
        ...state,
        fitness_reviews: action.payload
      }
    case types.FITNESS_PATIENT_APPOINTMENT_LIST:
      return {
        ...state,
        patient_appointment: action.payload
      }
    case types.PATIENT_OF_FITNESS_EXPERT:
      return {
        ...state,
        patient_of_fitness_expert: action.payload
      }
    case types.FITNESS_MEDICAL_RECORDS_LIST:
      return {
        ...state,
        loading: false,
        fitness_medical_records: action.payload
      }
    case types.FITNESS_CLASS_LISTING:
      return {
        ...state,
        fitness_class_listing: action.payload
      }
    case types.VIEW_FITNESS_CLASS:
      return {
        ...state,
        view_fitness_class: action.payload
      }
    case types.FETCH_GLOBAL_FITNESS_CLASS_LIST:
      return {
        ...state,
        global_fitness_classes: action.payload
      }
    case types.GLOBAL_VIEW_FITNESS_CLASS:
    return {
      ...state,
      global_view_fitness_class: action.payload
    }
    case types.FETCH_BOOKING_CLASS_CHECKOUT:
    return {
      ...state,
      fitness_checkout_details: action.payload
    }


    default:
      return state;
  }
};

export default fitnessReducer;
