import React, { Component } from 'react';


class Template extends Component {
    render() {
        return (
            <></>
        );
    }
}

export default Template;
