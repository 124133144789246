import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Pagination from '../../store/helpers/Pagination';
import { bucketPath} from './../../store/helpers/common';
import SimpleReactValidator from 'simple-react-validator';

class PatientInsurance extends Component {
    constructor(props){
        super(props);
        this.state = {pageOfItems:[],currentPage:1}
        this.validator = new SimpleReactValidator();
    }
    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({open: false, viewImage: false});
    };
    render() {
        const medical_records = []
        const { pageOfItems } = this.state
        return (
            <div>
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date </th>
                                        <th>Description</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td><Link to="#">#MR-0010</Link></td>
                                        <td>14 Nov 2019</td>
                                        <td>Dental Filling</td>
                                        <td><Link to="#">dental-test.pdf</Link></td>
                                        <td>
                                            <h2 className="table-avatar">
                                                <Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
                                                    <img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
                                                </Link>
                                                <Link to="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></Link>
                                            </h2>
                                        </td>
                                        <td className="text-right">
                                            <div className="table-action">
                                                <Link to="#" className="btn btn-sm bg-info-light">
                                                    <i className="far fa-eye"></i> View
                                                </Link>
                                            </div>
                                        </td>
                                    </tr> */}
                                    {medical_records.length < 1
                                    && <tr>
                                        <td colSpan="4">
                                            No data found
                                        </td>
                                    </tr>}
                                    {pageOfItems.map((value, key) => {
                                        return (
                                            <tr key={`medical_records_${key}`}>
                                                <td><Link to="#">{value.uniquekey}</Link></td>
                                                <td>{value.creationdate}</td>
                                                <td>{value.name}</td>
                                                <td className="text-right">
                                                    <div className="table-action">
                                                        {value.attachment.split('.').pop() !== 'pdf'
                                                        && <Link to="#" className="btn btn-sm bg-info-light" onClick={e=>localStorage.getItem('type')!=="doctor"?this.setState({viewImage:true,imgPath:value.attachment,open:true}):false}>
                                                                <i className="far fa-eye"></i> View
                                                            </Link>
                                                        }
                                                        {value.attachment.split('.').pop() === 'pdf'
                                                        && <Link to={`${bucketPath}${value.attachment}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm bg-info-light">
                                                                <i className="far fa-eye"></i> View
                                                            </Link>
                                                        }
                                                        <Link to="#" className="btn btn-sm bg-danger-light" onClick={e=>this.DeleteRecord(value.id)}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {medical_records.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={medical_records}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>

             </div>
        )
    }
}

export default PatientInsurance;
