import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTimeStops,Addtimes } from './../../store/helpers/timeIntervals';
import SimpleReactValidator from 'simple-react-validator';
import { LabScheduleTimings,LabScheduleTimingsView,UpdateLabScheduleTimings } from './../../store/actions/labPanelActions'
import moment from 'moment';
const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const slots = ['morning', 'noon', 'evening'];

let morning_start_timing = {};
let noon_start_timing = {};
let evening_start_timing = {};
let morning_end_timing = {};
let noon_end_timing = {};
let evening_end_timing = {};
let dayTimes = {};
let daySlots = {};

days.map(value => {
	morning_start_timing[`morning_start_${value}`] = "";
	noon_start_timing[`noon_start_${value}`] = "";
	evening_start_timing[`evening_start_${value}`] = "";
	morning_end_timing[`morning_end_${value}`] = "";
	noon_end_timing[`noon_end_${value}`] = "";
	evening_end_timing[`evening_end_${value}`] = "";
	dayTimes[`${value}_time`] = undefined;
	daySlots[`${value}_slot`] = undefined;
	return null;
});

const initialState = { time_slot: 15, selected_day: 'monday', day_slots: {}, time_slots: {}, morning_slot: undefined, evening_slot: undefined, noon_slot: undefined, isEdit: false };
class LabScheduleTiming extends React.Component {
	constructor(props) {
		super(props)
		this.state = initialState;
		this.validator = new SimpleReactValidator();
		Object.assign(this.state, morning_start_timing, noon_start_timing, evening_start_timing, morning_end_timing, noon_end_timing, evening_end_timing, dayTimes, daySlots);
	}

	componentDidMount() {
		this.props.dispatch(LabScheduleTimingsView());
	}

	openModal = e => {
		const { time_slot } = this.state
		window.$('#add_time_slot').modal({ show: true,backdrop: "static" })
		this.morningSlot = getTimeStops('07:00', '12:00', parseInt(time_slot));
		this.noonSlot = getTimeStops('12:00', '16:00', parseInt(time_slot));
		this.eveningSlot = getTimeStops('16:00', '22:00', parseInt(time_slot));
	}

	capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	submitSlotHandler = async (e) => {
		const validator = this.validator;
		const { time_slot, selected_day, morning_slot, noon_slot, evening_slot } = this.state;
		if (validator.allValid()) {
			let morning_start = this.state[`morning_start_${selected_day}`];
			let noon_start = this.state[`noon_start_${selected_day}`];
			let evening_start = this.state[`evening_start_${selected_day}`];
			let morning_end = this.state[`morning_end_${selected_day}`];
			let noon_end = this.state[`noon_end_${selected_day}`];
			let evening_end = this.state[`evening_end_${selected_day}`];
			let sendArr = { time_slot: time_slot, day: selected_day, morning_start: morning_start, noon_start: noon_start, evening_start: evening_start, morning_end: morning_end, noon_end, evening_end: evening_end, morning_slot: morning_slot, noon_slot: noon_slot, evening_slot: evening_slot };
			let response = this.props.dispatch(LabScheduleTimings(sendArr));
			if (response === 1) {
				window.$('#add_time_slot').modal('hide');
			}

		} else {
			validator.showMessages();
			this.forceUpdate();
		}
	}

	BeforeTimeChange = (start, end, interval) => {
		if (start === "" || end === "") {
			return null;
		} else {
			let time_slot = this.state.time_slot;
			if (interval === 'morning') {
				this.setState({ morning_slot: getTimeStops(start, end, parseInt(time_slot)) })
			} else if (interval === "noon") {
				this.setState({ noon_slot: getTimeStops(start, end, parseInt(time_slot)) })
			} else if (interval === "evening") {
				this.setState({ evening_slot: getTimeStops(start, end, parseInt(time_slot)) })
			}
		}
	}

	componentWillUpdate(newProps) {
		if (newProps.scheduled_timing !== this.props.scheduled_timing) {
			let scheduled_timing = newProps.scheduled_timing;
			if (scheduled_timing) {
				this.setState({
					time_slot: scheduled_timing.time_slot_duration,
					monday_time: scheduled_timing[`monday_time`] ? JSON.parse(scheduled_timing[`monday_time`]) : "",
					tuesday_time: scheduled_timing[`tuesday_time`] ? JSON.parse(scheduled_timing[`tuesday_time`]) : "",
					wednesday_time: scheduled_timing[`wednesday_time`] ? JSON.parse(scheduled_timing[`wednesday_time`]) : "",
					thursday_time: scheduled_timing[`thursday_time`] ? JSON.parse(scheduled_timing[`thursday_time`]) : "",
					friday_time: scheduled_timing[`friday_time`] ? JSON.parse(scheduled_timing[`friday_time`]) : "",
					saturday_time: scheduled_timing[`saturday_time`] ? JSON.parse(scheduled_timing[`saturday_time`]) : "",
					sunday_time: scheduled_timing[`sunday_time`] ? JSON.parse(scheduled_timing[`sunday_time`]) : "",
					monday_slot: scheduled_timing[`monday_slot`] ? JSON.parse(scheduled_timing[`monday_slot`]) : "",
					tuesday_slot: scheduled_timing[`tuesday_slot`] ? JSON.parse(scheduled_timing[`tuesday_slot`]) : "",
					wednesday_slot: scheduled_timing[`wednesday_slot`] ? JSON.parse(scheduled_timing[`wednesday_slot`]) : "",
					thursday_slot: scheduled_timing[`thursday_slot`] ? JSON.parse(scheduled_timing[`thursday_slot`]) : "",
					friday_slot: scheduled_timing[`friday_slot`] ? JSON.parse(scheduled_timing[`friday_slot`]) : "",
					saturday_slot: scheduled_timing[`saturday_slot`] ? JSON.parse(scheduled_timing[`saturday_slot`]) : "",
					sunday_slot: scheduled_timing[`sunday_slot`] ? JSON.parse(scheduled_timing[`sunday_slot`]) : "",
					isEdit: true
				},()=>{
					let selctedDay = this.state.selected_day;
					if(this.state[`${selctedDay}_slot`]){
						this.setState({
							[`morning_start_${selctedDay}`]:this.state[`${selctedDay}_slot`]['morning_start']?this.state[`${selctedDay}_slot`]['morning_start']:'',
							[`morning_end_${selctedDay}`]:this.state[`${selctedDay}_slot`]['morning_end']?this.state[`${selctedDay}_slot`]['morning_end']:'',
							[`noon_start_${selctedDay}`]:this.state[`${selctedDay}_slot`]['noon_start']?this.state[`${selctedDay}_slot`]['noon_start']:'',
							[`noon_end_${selctedDay}`]:this.state[`${selctedDay}_slot`]['noon_end']?this.state[`${selctedDay}_slot`]['noon_end']:'',
							[`evening_start_${selctedDay}`]:this.state[`${selctedDay}_slot`]['evening_start']?this.state[`${selctedDay}_slot`]['evening_start']:'',
							[`evening_end_${selctedDay}`]:this.state[`${selctedDay}_slot`]['evening_end']?this.state[`${selctedDay}_slot`]['evening_end']:''
						})
					}
				});
			}
		}
	}

	AddMinutes = (time) => {
		const { time_slot } = this.state;
		let settime = moment(time, 'HH:mm');
		settime.add(parseInt(time_slot), 'm');
		return moment(settime).format('HH:mm');
	}

	filterOnCross = (value, slot) => {
		const {selected_day } = this.state;
		let sendval = this.state[`${selected_day}_time`][slot].filter(valueslot => valueslot !== value);

		this.state[`${selected_day}_time`][slot] = sendval;
		// let data = { time_slot_duration: this.state.time_slot, time_slot: time_slots };
		let data = {day:selected_day,value:this.state[`${selected_day}_time`]}
		this.props.dispatch(UpdateLabScheduleTimings(data))
	}

	HHtoMM = (value) => {
		let splitter = value.split(":");
		if (splitter[0] && splitter[1]) {
			let HtoMins = parseInt(splitter[0]) * 60;
			let mins = parseInt(splitter[1]);
			return (HtoMins + mins);
		} else {
			return 0;
		}
	}

	render() {
		const { selected_day,isEdit } = this.state;
		return (
			<div>
				<div className="breadcrumb-bar">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-12 col-12">
								<h2 className="breadcrumb-title">Schedule Timings</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<h4 className="card-title">Schedule Timings</h4>
								<div className="profile-box">
									<div className="row">
										<div className="col-md-12">
											<div className="card schedule-widget mb-0 login-sec-modal">
												<div className="schedule-header">
													<div className="schedule-nav">
														<ul className="nav nav-tabs nav-justified">
															{days.map(value => {
																return (
																	<li className="nav-item" key={`day_${value}`}>
																		<Link className={`nav-link ${selected_day === value ? "active" : ""}`} to="#" onClick={e => this.setState({ selected_day: value })}>{value}</Link>
																	</li>
																)
															})}
														</ul>
													</div>
												</div>
												<div className="tab-content schedule-cont">
													<div id="slot_sunday" className="tab-pane fade">
														<h4 className="card-title d-flex justify-content-between">
															<span>Time Slots</span>
															<Link className="edit-link" data-toggle="modal" to="#add_time_slot"><i className="fa fa-plus-circle"></i> Add Slot</Link>
														</h4>
														<p className="text-muted mb-0">Not Available</p>
													</div>
													<div id="slot_monday" className="tab-pane fade show active">
														<h4 className="card-title d-flex justify-content-between">
															<span>Time Slots</span>
															<Link to="#" className="edit-link" onClick={e => this.openModal(e)}><i className="fa fa-edit mr-1"></i>{isEdit ? "Edit" : "Add"}</Link>
														</h4>
                                                        <div className="active-slots">
															<div className="row">
																<div className="col-md-12">
																	<h2><i className="slot-icos"><img src="/assets/images/slot-ico-1.png" alt="..." /></i>Morning</h2>
																	<ul className="ul-active-slots">
                                                                    {this.state[`${selected_day}_time`] && this.state[`${selected_day}_time`]['morning'] && this.state[`${selected_day}_time`]['morning'].map((valueslot, keyslot) => {
                                                                        return (
                                                                            <li className="" key={`morning_slot${keyslot}`}>
                                                                                {`${valueslot}-${Addtimes(valueslot)}`}
                                                                                <Link to="#" className="delete_schedule" onClick={e => this.filterOnCross(valueslot, 'morning')}>
                                                                                    <i className="fa fa-times"></i>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    }
																	</ul>
																</div>
															</div>
                                                        <div className="row">
																<div className="col-md-12">
																	<h2><i className="slot-icos"><img src="/assets/images/slot-ico-2.png" alt="..." /></i>Afternoon</h2>
																	<ul className="ul-active-slots">
                                                                    {this.state[`${selected_day}_time`] && this.state[`${selected_day}_time`]['noon'] && this.state[`${selected_day}_time`]['noon'].map((valueslot, keyslot) => {
																	return (
																		<li className="" key={`noon_slot${keyslot}`}>
																			{`${valueslot}-${Addtimes(valueslot)}`}
																			<Link to="#" className="delete_schedule" onClick={e => this.filterOnCross(valueslot, 'noon')}>
																				<i className="fa fa-times"></i>
																			</Link>
																		</li>
																	)
																    })
															        }
																	</ul>
																</div>
															</div>
															<div className="row">
																<div className="col-md-12">
																	<h2><i className="slot-icos"><img src="/assets/images/slot-ico-3.png" alt="..." /></i>Evening</h2>
																	<ul className="ul-active-slots">
                                                                    {this.state[`${selected_day}_time`] && this.state[`${selected_day}_time`]['evening'] && this.state[`${selected_day}_time`]['evening'].map((valueslot, keyslot) => {
																	return (
																		<li className="" key={`evening_slot${keyslot}`}>
																			{`${valueslot}-${Addtimes(valueslot)}`}
																			<Link to="#" className="delete_schedule" onClick={e => this.filterOnCross(valueslot, 'evening')}>
																				<i className="fa fa-times"></i>
																			</Link>
																		</li>
																	)
																})
															}
																	</ul>
																</div>
															</div>
                                                        </div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade custom-modal" id="add_time_slot">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{isEdit ? "Edit" : "Add"} Time Slots</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="hours-info">
										{slots && slots.map(value => {
											return (
												<div className="row form-row hours-cont" key={`slot_${value}`}>
													<div className="col-12 col-md-10">
														<div className="row form-row">
															<div className="col-12 col-md-6">
																<div className="form-group">
																	<label>{this.capitalizeFirstLetter(value)} Start Time</label>
																	<select className="form-control" name={`${value}_start_${selected_day}`} value={this.state[`${value}_start_${selected_day}`]} onChange={e => this.setState({ [`${value}_start_${selected_day}`]: e.target.value }, () => { this.BeforeTimeChange(this.state[`${value}_start_${selected_day}`], this.state[`${value}_end_${selected_day}`], value) })}>
																		<option>Select Slot</option>
																		{value === 'morning' && this.morningSlot && this.morningSlot.map((valueslot, index) => {
																			if (!this.morningSlot[index + 1]) { return null; }
																			return (
																				<option key={`${valueslot}_${index}`} value={valueslot}>{valueslot}</option>
																			);
																		})}
																		{value === 'noon' && this.noonSlot && this.noonSlot.map((valueslot, index) => {
																			if (!this.morningSlot[index + 1]) { return null; }
																			return (
																				<option key={`${valueslot}_${index}`} value={valueslot}>{valueslot}</option>
																			);
																		})}
																		{value === 'evening' && this.eveningSlot && this.eveningSlot.map((valueslot, index) => {
																			if (!this.morningSlot[index + 1]) { return null; }
																			return (
																				<option key={`${valueslot}_${index}`} value={valueslot}>{valueslot}</option>
																			);
																		})}
																	</select>
																	{this.state[`${value}_end_${selected_day}`] !== "" && this.validator.message(`${this.capitalizeFirstLetter(value)} Interval`, this.state[`${value}_start_${selected_day}`], 'required')}
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div className="form-group">
																	<label>{this.capitalizeFirstLetter(value)} End Time</label>
																	<select className="form-control" name={`${value}_end_${selected_day}`} value={this.state[`${value}_end_${selected_day}`]} onChange={e => this.setState({ [`${value}_end_${selected_day}`]: e.target.value }, () => { this.BeforeTimeChange(this.state[`${value}_start_${selected_day}`], this.state[`${value}_end_${selected_day}`], value) })}>
																		<option>Select Slot</option>
																		{value === 'morning' && this.morningSlot && this.morningSlot.map((valueslot, index) => {
																			let startVal = this.HHtoMM(this.state[`${value}_start_${selected_day}`]);
																			let endVal = this.HHtoMM(valueslot);
																			if (startVal >= endVal) { return null; }
																			return (
																				<option key={`${valueslot}__${index}`} value={valueslot}>{valueslot}</option>
																			);
																		})}
																		{value === 'noon' && this.noonSlot && this.noonSlot.map((valueslot, index) => {
																			let startVal = this.HHtoMM(this.state[`${value}_start_${selected_day}`]);
																			let endVal = this.HHtoMM(valueslot);
																			if (startVal >= endVal) { return null; }
																			return (
																				<option key={`${valueslot}__${index}`} value={valueslot}>{valueslot}</option>
																			);
																		})}
																		{value === 'evening' && this.eveningSlot && this.eveningSlot.map((valueslot, index) => {
																			let startVal = this.HHtoMM(this.state[`${value}_start_${selected_day}`]);
																			let endVal = this.HHtoMM(valueslot);
																			if (startVal >= endVal) { return null; }
																			return (
																				<option key={`${valueslot}__${index}`} value={valueslot}>{valueslot}</option>
																			);
																		})}
																	</select>
																	{this.state[`${value}_start_${selected_day}`] !== "" && this.validator.message(`${this.capitalizeFirstLetter(value)} Interval`, this.state[`${value}_end_${selected_day}`], `required`)}
																</div>
															</div>
														</div>
													</div>
												</div>
											)
										})
										}
									</div>
									<div className="submit-section text-center">
										<Link to="#" className="btn btn-light-black submit-btn" onClick={e => this.submitSlotHandler(e)}>Save Changes</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	scheduled_timing: state.user.scheduled_timing
});

export default connect(mapStateToProps)(LabScheduleTiming);
