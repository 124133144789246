// @flow
import * as React from "react";
import { Editor } from "@tinymce/tinymce-react";
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux'; 
import { Link } from "react-router-dom";
import { bucketPath} from "../../store/helpers/common"; 
import {DeleteFitnessImage,ViewFitnessClass,FitnessClass} from "../../store/actions/fitnessActions";
import swal from 'sweetalert';  

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
let start_time={}
let end_time={}
let closed = {}
let hideHour = {}
days.map(val => {
	start_time[`${val}_start`] = "";
  end_time[`${val}_end`] = "";
  closed[`${val}_closed`] = false
  hideHour[`${val}_hideHour`] = false
  return true
});

class AddFitnessClasses extends React.Component {
  state = { title: "", description: "", classId: "", fitness_image: [],price:'',date:'',duration:'',isEdit:false,pageOfItems: [], currentPage: 1};
  validator = new SimpleReactValidator(); 

  componentDidMount(){ 
    let uniqueID = this.props.match.params.uniqueID;  
		this.props.dispatch(ViewFitnessClass(uniqueID)) 
  }  

  handleEditorChange = (content, editor) => {
    this.setState({ description: content })
  }


  onChangeHandler = (e) => {
    this.setState({[e.target.name]:e.target.value}) 
    if (e.target.name === "fitness_image") {
			var file = window.$('#fitness_image')[0].files;
			let fitness_image = [];
			for (let i = 0; i < file.length; i++) {
        let imageFile = URL.createObjectURL(file[i])  
				fitness_image.push({image:imageFile});
			}
			this.setState({
				fitness_image: fitness_image
			})
		}
  }

  ClosedHours=(e,id,val)=>{
      window.$('#'+id).addClass('hide');
      this.setState({[`${val}_start`]:'',[`${val}_end`]:'',[`${val}_closed`]:true});
  }

  OpenHours=(e,id,val)=>{
      window.$('#'+id).removeClass('hide');
      this.setState({[`${val}_hideHour`]:false,[`${val}_closed`]:false})
  }

  componentDidUpdate = (prevProps, prevState) => {
    let nextProps = this.props; 
    if(nextProps.view_fitness_class !== prevProps.view_fitness_class){
        let rest_data = nextProps.view_fitness_class?.time 
        rest_data.map((val, i) => {
            this.setState({[`${val.day_name}_start`]:val.starttime,[`${val.day_name}_end`]:val.endtime});
            if(val.starttime === '' && val.endtime === ''){
                this.setState({[`${val.day_name}_closed`]:true,[`${val.day_name}_hideHour`]:true})
            }
            return true
        })

        this.setState({
          title:nextProps.view_fitness_class?.title,
          date:nextProps.view_fitness_class?.date,
          duration:nextProps.view_fitness_class?.duration,
          price:nextProps.view_fitness_class?.price, 
          fitness_image:nextProps.view_fitness_class?.fitness_image,
          description:nextProps.view_fitness_class?.description,
          classId:nextProps.view_fitness_class?.uniquekey
        })
    }
}


   

  DeleteImage = (value) => { 
    swal({
        title: "Are you sure you want to delete this Image?",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            this.props.dispatch(DeleteFitnessImage({id:value.uniquekey}));  
           
        } else {
            swal({ title: "Record is safe!", icon: "success", });
        }
    });
  };


  

  onSubmitHandler = async (e) => {
    const { title,date,price, duration, description,classId} = this.state;
    let response; 
    if (this.validator.allValid()) {
      let fitness_timings = [];
          days.map((val) => {
            return fitness_timings.push({ day_name: val, "starttime": this.state[`${val}_start`], "endtime": this.state[`${val}_end`] });
          });  
      if(classId === ''){
        let AddData = {title,date,price,duration,fitness_timings,description}
        response = await this.props.dispatch(FitnessClass(AddData));
      }else {
        let EditData = {classId,title,date,price,duration,fitness_timings,description}
        response = await this.props.dispatch(FitnessClass(EditData));
      }  
      if (response === 1) {
        this.props.history.push('/fitness/fitness-classes');
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate()
    }
  } 
   
  render() {
    const { title, date ,price,duration,description,fitness_image,classId} = this.state;  
    return (
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={title}
                    onChange={(e) => this.onChangeHandler(e)}
                  />
                  {this.validator.message('Title', title, 'required')}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={date}
                    onChange={(e) => this.onChangeHandler(e)}
                  />
                  {this.validator.message('Date', date, 'required')}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group fitness-text">
                  <label>Price</label>
                  <span className="price-input">₹</span>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    value={price}
                    onChange={(e) => this.onChangeHandler(e)}
                  />
                  {this.validator.message('Price',price, 'required')}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Duration</label>
                  <input
                    type="text"
                    className="form-control"
                    name="duration"
                    value={duration}
                    onChange={(e) => this.onChangeHandler(e)}
                  />
                  {this.validator.message('Duration',duration, 'required')}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Fitness Class Timings</label>  
                    {
                    days.map((val, i) => {
                        return (
                            <div className="open-close-repeat" key={i}>
                                <div className="open-close-time opening-time">
                                    <div className="row">
                                <div className="day-sec col-md-4 align-self-center">
                                        <span>{val}</span>
                                    </div>
                                <div className={`time-sec col-md-8 ${this.state[`${val}_hideHour`] ? 'hide' : ''}`} id={i} >
                                        <div className="row">
                                        <select className="col-md-4 form-control" name={`${val}_start`} value={this.state[`${val}_start`]} onChange={(e) => this.onChangeHandler(e)}>
                                            <option>Select</option>
                                            <option value="00:00:00">12:00 AM</option>
                                            <option value="01:00:00">01:00 AM</option>
                                            <option value="02:00:00">02:00 AM</option>
                                            <option value="03:00:00">03:00 AM</option>
                                            <option value="04:00:00">04:00 AM</option>
                                            <option value="05:00:00">05:00 AM</option>
                                            <option value="06:00:00">06:00 AM</option>
                                            <option value="07:00:00">07:00 AM</option>
                                            <option value="08:00:00">08:00 AM</option>
                                            <option value="09:00:00">09:00 AM</option>
                                            <option value="10:00:00">10:00 AM</option>
                                            <option value="11:00:00">11:00 AM</option>
                                            <option value="12:00:00">12:00 PM</option>
                                            <option value="13:00:00">01:00 PM</option>
                                            <option value="14:00:00">02:00 PM</option>
                                            <option value="15:00:00">03:00 PM</option>
                                            <option value="16:00:00">04:00 PM</option>
                                            <option value="17:00:00">05:00 PM</option>
                                            <option value="18:00:00">06:00 PM</option>
                                            <option value="19:00:00">07:00 PM</option>
                                            <option value="20:00:00">08:00 PM</option>
                                            <option value="21:00:00">09:00 PM</option>
                                            <option value="22:00:00">10:00 PM</option>
                                            <option value="23:00:00">11:00 PM</option>
                                        </select>
                                    <span className="option-label col-md-2 align-self-center text-center">to</span>
                                        <select className="col-md-4 form-control" name={`${val}_end`} value={this.state[`${val}_end`]} onChange={(e) => this.onChangeHandler(e, `${val}_end`)} >
                                            <option>Select</option>
                                            <option value="00:00:00">12:00 AM</option>
                                            <option value="01:00:00">01:00 AM</option>
                                            <option value="02:00:00">02:00 AM</option>
                                            <option value="03:00:00">03:00 AM</option>
                                            <option value="04:00:00">04:00 AM</option>
                                            <option value="05:00:00">05:00 AM</option>
                                            <option value="06:00:00">06:00 AM</option>
                                            <option value="07:00:00">07:00 AM</option>
                                            <option value="08:00:00">08:00 AM</option>
                                            <option value="09:00:00">09:00 AM</option>
                                            <option value="10:00:00">10:00 AM</option>
                                            <option value="11:00:00">11:00 AM</option>
                                            <option value="12:00:00">12:00 PM</option>
                                            <option value="13:00:00">01:00 PM</option>
                                            <option value="14:00:00">02:00 PM</option>
                                            <option value="15:00:00">03:00 PM</option>
                                            <option value="16:00:00">04:00 PM </option>
                                            <option value="17:00:00">05:00 PM</option>
                                            <option value="18:00:00">06:00 PM</option>
                                            <option value="19:00:00">07:00 PM</option>
                                            <option value="20:00:00">08:00 PM</option>
                                            <option value="21:00:00">09:00 PM</option>
                                            <option value="22:00:00">10:00 PM</option>
                                            <option value="23:00:00">11:00 PM</option>
                                        </select>
                                    <Link className="col-md-2 align-self-center text-center" to="#" value={`${i}`}  onClick={(e)=>this.ClosedHours(e,i,val)} > 
                                            <i class="fa fa-times" aria-hidden="true" value={`${i}`}></i>
                                        </Link>
                                        </div>    
                                    </div>
                                    <div className={`time-sec col-md-8 ${this.state[`${val}_closed`] ? '' : 'hide'}`} >
                                        <div className="row">       
                                        <h4 className="col-md-6">Closed</h4>
                                        <Link className="col-md-6 text-right" to="#" value={`${i}`}  onClick={(e)=>this.OpenHours(e,i,val)} >
                                            <i className="fa fa-plus-circle" aria-hidden="true" value={`${i}`}></i>
                                        </Link>
                                        </div> 
                                    </div>
                                </div>
                                </div>
                            </div>
                        )
                    })
                    }  
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div id="Upload-documents" className="form-group row">
                  <div className="col-md-4">
                  <label htmlFor="image"> Image </label>
                    <small className="form-text text-muted">
                      Allowed JPG, GIF or PNG. Max size of 2MB
                    </small>
                    {this.validator.message("fitness_image", this.state.fitness_image, "required")}
                  </div>
                  <div className="col-md-8">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="fitness_image"
                        name="fitness_image"
                        onChange={this.onChangeHandler} 
                        aria-describedby="inputGroupFileAddon01"
                        accept="image/*"
                        multiple
                        ref={(input) => {
                          this.image = input;
                        }}
                      />
                      
                      <label
                        className="custom-file-label custom-file-label-doc"
                        htmlFor="inputGroupFileAddon01"
                      > 
                      </label>
                      <i
                        className="fas fa-camera camera-ico"
                        onClick={() => {
                          this.image.click();
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">  
                    {fitness_image.length > 0 && fitness_image.map((value, index) => {
                            let imgSrc = ""
                            if (value.image.includes("http://") === true || value.image.includes("https://") === true) {
                              imgSrc = value.image;
                            } else {
                              imgSrc = `${bucketPath}${value.image}` 

                            }
                            return (
                              <div
                                className="change-avatar"
                                key={`avatar${index}`}
                              >
                                <div className="profile-img">  
                                     {classId &&  <Link to="#">
                                        <i 
                                          className="fas fa-trash-alt del-profile-ico"
                                          onClick={() => this.DeleteImage(value)}
                                        > 
                                        </i>
                                      </Link> }
                                  <img
                                    alt="User"
                                    src={`${imgSrc}`}
                                  />
                                </div>
                              </div>
                            )
                         })}   
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description</label>
                  <Editor
                    apiKey="xg6aofsienh3p2rx7w7qd63srneueilzfwumbic5cefxag6i"
                    init={{
                      height: 300,
                      plugins: [
                        "advlist autolink lists link image",
                        "charmap print preview anchor help",
                        "searchreplace visualblocks code",
                        "insertdatetime media table paste wordcount",
                      ],
                      menubar: 'file edit view insert format tools table help', 
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    }}
                    value={this.state.description}
                    onEditorChange={this.handleEditorChange}
                  />
                  {this.validator.message('Description', description, 'required')}
                </div>
              </div>
            </div>
            <div className="submit-section">
              <button
                type="button"
                className="btn btn-light-black submit-btn"
                onClick={(e) => this.onSubmitHandler(e)}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({ 
  view_fitness_class:state.fitness.view_fitness_class
});

export default connect(mapStateFromProps)(AddFitnessClasses);
