import * as types from "./../types/types";

const initalState = {
    loading: true,
    languages: [],
    specializations:[],
    statesList:[],
    citiesList:[],
    geoLocations:undefined,
    suggested_cities:[],
    suggested_clinic_cities:[],
    suggested_states:[],
    suggested_countries:[],
    loader:false,
    Class:"",
    Bold:"",
    Message:"",
    Timeout:2500,
    timezones:[],
    suggested_locations : [],
    counsellor_types: []
}

const staticListingsReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.LANGUAGES_LIST:
            return {
                ...state,
                loading: true,
                languages:action.payload
            }
        case types.SPECIALIZATION_LIST:
            return {
                ...state,
                loading: true,
                specializations:action.payload
            }
        case types.STATES_LIST:
            return {
                ...state,
                loading: true,
                statesList:action.payload
            }
        case types.CITIES_LIST:
            return {
                ...state,
                loading: true,
                citiesList:action.payload
            }
        case types.FETCH_LOCATION:
            return {
                ...state,
                loading: true,
                geoLocations:action.payload
            }
        case types.SUGGESTED_CITIES:
            return {
                ...state,
                loading: true,
                suggested_cities:action.payload
            }
        case types.SUGGESTED_CLINIC_CITIES:
            return {
                ...state,
                loading: true,
                suggested_clinic_cities:action.payload
            }
        case types.SUGGESTED_STATES:
            return {
                ...state,
                loading: true,
                suggested_states:action.payload
            }
        case types.SUGGESTED_COUNTRIES:
            return {
                ...state,
                loading: true,
                suggested_countries:action.payload
            }
        case types.SHOW_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case types.SHOW_ALERT:
            return {
                ...state,
                Class:action.payload.Class,
                Message:action.payload.Message,
                Timeout:action.payload.Timeout
            }
        case types.TIMEZONE_LIST:
            return {
                ...state,
                timezones:action.payload
            }
        case types.COUNSELLOR_TYPE_LIST:
            return {
                ...state,
                counsellor_types:action.payload
            }
        case types.FETCH_AUTO_SUGGESTED_LOCATION:
            return {
                ...state,
                loading: true,
                suggested_locations:action.payload
            }
        default:
            return state
    }
}

export default staticListingsReducer;