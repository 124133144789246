import React, { Component } from "react";
import { connect } from "react-redux"; 
import { FitnessReviewsList } from "./../store/actions/fitnessActions";
import Pagination from "../store/helpers/Pagination";
import CounsellorReview from "./CounsellorReview";
import FitnessReviewForm from "./FitnessReviewForm";

class FitnessReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageOfItems: [],
            currentPage: 1,
            editableData: {},
            manageClick: null,
        };
    }

    componentDidMount() {
        this.props.dispatch(
            FitnessReviewsList({ uniquekey: this.props.userUniqueKey })
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ pageOfItems: nextProps.reviews_list });
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    manageEditReview = (reviewData) => {
        this.setState({ editableData: reviewData });
    };

    manageFormSubmit = () => {
        this.setState({ editableData: {} });
    };

    render() {
        const { reviews_list } = this.props;
        const { pageOfItems, editableData } = this.state;
        const localId = localStorage.data
            ? JSON.parse(localStorage.data).loginID
            : "";
        const drFullNmae = (
            <h4>
                Write a review for{" "}
                <strong>{`${this.props.profileInfo.title}. ${this.props.profileInfo.firstname} ${this.props.profileInfo.lastname}`}</strong>
            </h4>
        );
        const identity = localStorage.getItem('type');
        return (
            <React.Fragment>
                <div className="widget review-listing">
                    <ul className="comments-list">
                        {this.state.pageOfItems.length > 0 ? (
                            this.state.pageOfItems.map((val, i) => (
                                <CounsellorReview
                                    key={`review_${i}`}
                                    reviewData={val}
                                    editReview={this.manageEditReview}
                                    uderID={localId}
                                />
                            ))
                        ) : (
                            <li>No reviews found</li>
                        )}
                    </ul>

                    <div className="all-feedback text-center">
                        {reviews_list && (
                            <Pagination
                                pageSize={20}
                                items={reviews_list}
                                onChangePage={this.onChangePage}
                            />
                        )}
                    </div>
                </div>
                {this.props.isLoggedIn && identity === "patient" && (
                    <div className="write-review">
                        {pageOfItems.filter((v) => v.userID === localId)
                            .length === 0 ? (
                            <>
                                {drFullNmae}
                                <FitnessReviewForm
                                    childRef={(ref) => (this.child = ref)}
                                    reviewList={reviews_list}
                                    loginKey={this.props.doctorID}
                                    reviewData={editableData}
                                    manageFormSubmit={this.manageFormSubmit}
                                />
                            </>
                        ) : Object.keys(editableData).length > 0 ? (
                            <>
                                {drFullNmae}
                                <FitnessReviewForm
                                    childRef={(ref) => (this.child = ref)}
                                    reviewList={reviews_list}
                                    loginKey={this.props.doctorID}
                                    reviewData={editableData}
                                    manageFormSubmit={this.manageFormSubmit}
                                />
                            </>
                        ) : null}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    reviews_list: state.fitness.reviews_list,
    isLoggedIn: state.global.isLoggedIn,
});

export default connect(mapStateToProps)(FitnessReviews);
