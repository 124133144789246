import React from 'react';
import Actions from './Actions';
import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { supportChatHistory } from '../../store/actions/globalActions';

class Support extends Actions {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            messages: [],
            users: ['A-624162']
        }
    }

    componentDidMount() {
        if(localStorage.data) {
            let uniqueKey = JSON.parse(localStorage.data).uniquekey;
            this.connection(uniqueKey);
            this.props.dispatch(supportChatHistory())
        } else {
            this.props.history.push('/');
        }
    }

    manageKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit();
        } else {
            this.activeComposing(this.state.users[0]);
        }
    }

    manageKeyUp = () => {
        this.stopComposing(this.state.users[0]);
    }

    handleSubmit = () => {
        if (this.state.message) {
            this.sendMessage("A-624162", this.state.message);
            const sentMessage = [{ type: 'sent', message: this.state.message }];
            this.setState({ messages: [...this.state.messages, ...sentMessage] }, () => {
                this.manageScroll();
            });
            this.setState({ message: '' })
        }
    }

    onRecievrMessage = (message) => {
        const receivedMessage = [{ type: 'received', message: message }];
        this.setState({ messages: [...this.state.messages, ...receivedMessage] }, () => {
            this.manageScroll();
        });
    }

    manageScroll = () => {
        const list = document.getElementById("chat-scroll"),
        targetLis = document.querySelectorAll(".message-content"),
        targetLi = targetLis[targetLis.length - 1];
        list.scrollTop = targetLi.offsetTop;
        // window.$('.list-unstyled').animate({scrollTop: window.$('.list-unstyled').prop("scrollHeight")}, 500);
    }

    componentDidUpdate() {
        const userUniqueKey = this.state.users[0].toLowerCase();
        console.log("this.props.chat_history======", this.props.chat_history)
        if(this.props.chat_history.length > 0 && this.props.chat_history[userUniqueKey].length > 0) {
            this.manageScroll();
        }
    }

    render() {
        const { messages, users } = this.state;
        const{ chat_history } = this.props;
        const userUniqueKey = users[0].toLowerCase();
        return (
            <>
                <div className="main-wrapper">
                    <div className="content message-window-content">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="chat-window">
                                    <div className=" chat-cont-right chat-cont-right-1 message-full-div">
                                        <div className="chat-header">
                                            <Link to="#" id="back_user_list"  className="back-user-list">
                                                <i className="material-icons">chevron_left</i>
                                            </Link>
                                            <div className="media">
                                                <div className="media-img-wrap">
                                                    <div className= {`avatar avatar-${this.state.status[this.state.users[0]] === 'online' ? 'online' : 'offline'}`}>
                                                        <img src="assets/img/patients/patient.jpg" alt="User"
                                                            className="avatar-img rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div className="user-name">Admin</div>
                                                    <div className="user-status">{this.state.status[this.state.users[0]] === 'online' ? 'online' : 'offline'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chat-body">
                                            <div className="chat-scroll" id="chat-scroll">
                                                <ul className="list-unstyled" id="list-unstyled">
                                                    {chat_history[userUniqueKey] && chat_history[userUniqueKey].map((message, key) => {
                                                        return (
                                                            <li key={`message_${key}`}className={`media message-content ${message.fromAddress !== userUniqueKey.toUpperCase() ? 'sent' : 'received'}`}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div>
                                                                            <p>{message.body}</p>
                                                                            <ul className="chat-msg-info">
                                                                                <li>
                                                                                    <div className="chat-time">
                                                                                        {/* {message.fromAddress === userUniqueKey.toUpperCase() && <span>Admin</span>} */}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                    {messages.map((message, key) => {
                                                        return (
                                                            <li key={`message_${key}`}className={`media message-content ${message.type === 'sent' ? 'sent' : 'received'}`}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div>
                                                                            <p>{message.message}</p>
                                                                            <ul className="chat-msg-info">
                                                                                <li>
                                                                                    <div className="chat-time">
                                                                                        {/* <span>8:30 AM</span> */}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                    {
                                                        this.state.typingNotification
                                                        && <li className="media received">
                                                            <div className="avatar">
                                                                <img src="assets/img/patients/patient.jpg" alt="User"
                                                                    className="avatar-img rounded-circle" />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <div className="msg-typing">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="chat-footer">
                                            <div className="input-group">
                                                {/* <div className="input-group-prepend">
                                                    <div className="btn-file btn">
                                                        <i className="fa fa-paperclip"></i>
                                                        <input type="file" />
                                                    </div>
                                                </div> */}
                                                <input
                                                    id="message"
                                                    type="text"
                                                    className="input-msg-send form-control"
                                                    placeholder="Write your message here..."
                                                    onKeyPress={this.manageKeyPress}
                                                    onKeyUp={this.manageKeyUp}
                                                    onChange={e => this.setState({ message: e.target.value })}
                                                    value={this.state.message}
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn msg-send-btn"
                                                        id="btn-chat"
                                                        onMouseUp={this.handleSubmit}
                                                    ><i
                                                        className="fab fa-telegram-plane"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    chat_history: state.global.chat_history
  });

export default connect(mapStateToProps)(Support);
