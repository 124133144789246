import React from "react"; 
import Loaders from './../components/General/Loaders'
import { Link } from 'react-router-dom';
class Footer extends React.Component {
  

  render() {
    return (
      <>
        {/* <footer id="footer">
            <div className="container">
                <div className="col-md-12"> 
                    <div className="owl-carousel owl-theme partners-carousel" id="owl-carousel-partners">
                        <div className="item">
                            <img src="/assets/images/h-1.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-2.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-3.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-4.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-5.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-6.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-7.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-8.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-4.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-5.png" alt="..." />
                        </div>
                        <div className="item">
                            <img src="/assets/images/h-6.png" alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center"> 
                        <ul className="footer-ul">
                            <li><Link to="#">Terms</Link></li>
                            <li><Link to="#">Agreement</Link></li>
                            <li><Link to="#">Refund</Link></li>
                            <li><Link to="#">Help Support</Link></li>
                        </ul>
                    </div>
                </div> 
            </div>
        </footer> */}
        <div className="row copyright">
                <p className="col-md-4 align-self-center">© 2020 AdiDoc.com All Rights Reserved.</p>
                <div className="footer-social-div col-md-4  align-self-center text-center">
                    <div className="download-ico">
                        <a href="/"><img src="./../assets/images/ios-white.png" alt="..."/></a>
                        <a href="/"><img src="./../assets/images/android-white.png" alt="..."/></a>
                    </div>
                </div>
                <div className="col-md-4  align-self-center text-right">
                    <ul className="footer-ul">
                        <li><Link to="#">Terms</Link></li>
                        <li><Link to="#">Agreement</Link></li>
                        <li><Link to="#">Refund</Link></li>
                        <li><Link to="#">Help Support</Link></li>
                    </ul>
                </div>
            
        </div>
        <Loaders/>
      </>
    );
  }
}
export default Footer;
