import React from 'react'
import { connect } from 'react-redux'
import { Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator'
import { apiBaseUrl, bucketPath } from './../../store/helpers/common';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import Select2 from 'react-select2-wrapper';
import "react-select2-wrapper/css/select2.css";
import { languages, specializations, AutosuggestCities, AutosuggestClinicCities, AutosuggestStates, AutosuggestCountries, ShowLoader, ShowAlert, Timezones } from "./../../store/actions/staticListingsActions";
import { profileInfo} from "./../../store/actions/userActions";
import { AdminProfileInfo, VerifyFitnessExpertApproval, SymptomsListing } from "./../../store/actions/adminActions";
import { MedicalCouncils } from "./../../store/actions/userActions";
import { Button, Modal } from 'react-bootstrap'
import MapModal from "./../modals/MapModal";
import MapAutocomplete from "./../gMaps/MapAutocomplete";
import Modals from './../General/Modals'

// const Statuses = { A: "success", R: "danger", PA: "warning", D: "danger", P: "warning", };
// const Labels = { A: "Active", R: "Rejected", PA: "Pending for Approval", D: "Deactivated", P: "New User" };

const axios = require('axios');
const initialState = {
	title: "Mr",
	firstname: "",
	lastname: "",
	gender: "M",
	language: [],
	phone: "+91",
	email: "",
	country: "",
	state: "",
	city: "",
	address: "",
	qualifications: [1],
	medical_reg_number: "",
	medical_council: "",
	medical_reg_year: "",
	second_medical_reg_number: "",
	second_medical_council: "",
	second_medical_reg_year: "",
	third_medical_reg_number: "",
	third_medical_council: "",
	third_medical_reg_year: "",
	clinics: [1],
	medical_registration_proof: [],
	qualification_proof: [],
	id_proof: [],
	password: "",
	prefix: "",
	zip: "",
	showMap: true,
	mail_consultation_fee: "",
	phone_consultation_fee: "",
	phone_consultation_mins: "",
	video_consultation_fee: "",
	video_consultation_mins: "",
	experience: "",
	specialization: "",
	latitude: undefined,
	longitude: undefined,
	chat_consultation_fee: "",
	chat_consultation_mins: "",
	walkin_consultation_fee: "",
	walkin_consultation_mins: "",
	confirm_password: "",
	search_city: "",
	currentClinic: 0,
	search_state: "",
	bank_name: "",
	bank_address: "",
	bank_code: "",
	micr_code: '',
	account_number: "",
	health_problem: [],
	profile_pic: "",
	expendedTab: "",
	openMap: false,
	hospital_name: "",
	hospital_address: "",
	hospital_position_held: "",
	location: "",
	lat_lng: {},
	website: "",
	reference: "",
	timezone: "",
	about_me: "",
	errorMessages: [],
	description: '',
	open: false
};


let degree = {};
let college = {};
let year = {};
let clinic = {};
let clinic_address = {};
let clinic_city = {};
let clinic_city_search = {};
[...Array(5).keys()].map((value, key) => {
	key = key + 1;
	degree["degree_" + key] = "";
	college["college_" + key] = "";
	year["year_" + key] = "";
	clinic["clinic_" + key] = "";
	clinic_address["clinic_address_" + key] = "";
	clinic_city["clinic_city_" + key] = "";
	clinic_city_search["clinic_city_search_" + key] = "";
	return null;
});

class AdminFitnessExpertProfile extends React.Component {
	constructor(props) {
		super(props);
		this.formValidator 	= new SimpleReactValidator();
		this.validator 		= new SimpleReactValidator();
		this.state = initialState;
		Object.assign(
			this.state,
			degree,
			college,
			year,
			clinic,
			clinic_address,
			clinic_city,
			clinic_city_search
		);
	}

	componentDidMount() {

		this.props.dispatch(AdminProfileInfo(this.props.match.params.token));
		this.props.dispatch(languages());
		this.props.dispatch(specializations());
		this.props.dispatch(MedicalCouncils());
		this.props.dispatch(Timezones());
		this.props.dispatch(SymptomsListing());
		this.setState({
			expendedTab: "basicinfo",
		});
	}

	groupValue = () => {
		this.setState({ language: window.$("#languageID").val() });
	};

	removeGroupValue = () => {
		this.setState({ language: window.$("#languageID").val() });
	};

	numberChange = (number) => {
		let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
		let prefix = "";
		if (phoneNumber) {
			if (phoneNumber.country) {
				if (getCountryCallingCode(phoneNumber.country)) {
					prefix = getCountryCallingCode(phoneNumber.country);
				}
			}
		}
		this.setState({ phone: number, prefix: prefix });
	};

	clinicsArray = () => {
		const { clinics } = this.state;
		let array = [];
		clinics.map((value) => {
			let jsonobj = {
				clinic: this.state[`clinic_${value}`],
				address: this.state[`clinic_address_${value}`],
				city: this.state[`clinic_city_${value}`],
			};
			array.push(jsonobj);
			return null;
		});
		return array;
	};

	SearchCitiesClinic = (e, key) => {
		this.setState(
			{
				[`clinic_city_search_${key}`]: e.target.value,
				currentClinic: key,
			},
			() => {
				this.props.dispatch(
					AutosuggestClinicCities({
						city: this.state[`clinic_city_search_${key}`],
					})
				);
			}
		);
	};

	AutoFillClinicCity = (value, key) => {
		const city = value.name ? value.name : "";
		this.setState(
			{ [`clinic_city_${key}`]: city, [`clinic_city_search_${key}`]: "" },
			() => {
				this.props.dispatch(AutosuggestClinicCities({ city: "" }));
			}
		);
	};

	SearchCities = (e) => {
		this.setState(
			{
				[e.target.name]: e.target.value,
			},
			() => {
				this.props.dispatch(
					AutosuggestCities({ city: this.state.search_city })
				);
			}
		);
	};

	SearchStates = (e) => {
		this.setState(
			{
				[e.target.name]: e.target.value, errorMessages: [],
			},
			() => {
				this.props.dispatch(
					AutosuggestStates({ state: this.state.search_state })
				);
			}
		);
	};

	SearchCountries = (e) => {
		this.setState(
			{
				[e.target.name]: e.target.value, errorMessages: [],
			},
			() => {
				this.props.dispatch(
					AutosuggestCountries({ country: this.state.search_country })
				);
			}
		);
	};

	AutoFillCity = (value) => {
		const city = value.name ? value.name : "",
			state = value.region_name ? value.region_name : "",
			country = value.country_name ? value.country_name : "";
		this.setState(
			{ city: city, state: state, country: country, search_city: "" },
			() => {
				this.props.dispatch(AutosuggestCities({ city: "" }));
			}
		);
	};

	AutoFillState = (value) => {
		const state = value.region_name ? value.region_name : "",
			country = value.country_name ? value.country_name : "";
		this.setState({ state: state, country: country, search_state: "" }, () => {
			this.props.dispatch(AutosuggestStates({ state: "" }));
		});
	};

	AutoFillCountry = (value) => {
		const country = value.country_name ? value.country_name : "";
		this.setState({ country: country, search_country: "" }, () => {
			this.props.dispatch(AutosuggestCountries({ country: "" }));
		});
	};

	qualificationsArray = () => {
		const { qualifications } = this.state;
		let array = [];
		qualifications.map((value) => {
			let jsonobj = {
				degree: this.state[`degree_${value}`],
				college: this.state[`college_${value}`],
				year: this.state[`year_${value}`],
			};
			array.push(jsonobj);
			return null;
		});
		return array;
	};


	timezone_offset = () => {
		let date = new Date();
		let newOffset = date.getTimezoneOffset();
		return -(newOffset * 60);
	}

	componentDidUpdate(nextProps, prevState) {
		let profile_info = this.props.profile_info;
		if (!profile_info || profile_info === nextProps.profile_info) {
			return false;
		}
		sessionStorage.setItem("key", profile_info.uniquekey);
		let clinics = [];
		let qualifications = [];
		if (profile_info.clinics) {
			let clinic = {};
			let clinic_address = {};
			let clinic_city = {};
			profile_info.clinics.map((value, key) => {
				key = key + 1;
				clinics = [...clinics, key];
				clinic[`clinic_${key}`] = value.clinic;
				clinic_address[`clinic_address_${key}`] = value.address;
				clinic_city[`clinic_city_${key}`] = value.city;
				return null;
			});
			Object.assign(this.state, clinic, clinic_address, clinic_city);
		}
		if (profile_info.qualifications) {
			let degree = {};
			let college = {};
			let year = {};
			profile_info.qualifications.map((value, key) => {
				key = key + 1;
				qualifications = [...qualifications, key];
				degree[`degree_${key}`] = value.degree;
				college[`college_${key}`] = value.college;
				year[`year_${key}`] = parseInt(value.year);
				return null
			});
			Object.assign(this.state, degree, college, year);
		}
		this.setState({
			title: profile_info.title,
			firstname: profile_info.firstname,
			lastname: profile_info.lastname,
			gender: profile_info.gender,
			language: profile_info.language ? profile_info.language : [],
			phone: profile_info.phone,
			email: profile_info.email,
			country: profile_info.country,
			state: profile_info.state,
			city: profile_info.city,
			address: profile_info.address,
			prefix: profile_info.prefix,
			zip: profile_info.zip,
			timezone: profile_info.timezone ? profile_info.timezone : this.timezone_offset(),
			mail_consultation_fee: profile_info.fees,
			phone_consultation_fee: profile_info.phone_consultation_fee,
			phone_consultation_mins: profile_info.phone_consultation_time,
			video_consultation_fee: profile_info.video_consultation_fee,
			video_consultation_mins: profile_info.video_consultation_time,
			experience: profile_info.experience,
			specialization: profile_info.specialization,
			latitude: profile_info.latitude,
			longitude: profile_info.longitude,
			chat_consultation_fee: profile_info.chat_consultation_fee,
			chat_consultation_mins: profile_info.chat_consultation_time,
			walkin_consultation_fee: profile_info.walkin_consultation_fee,
			walkin_consultation_mins: profile_info.walkin_consultation_time,
			medical_reg_number: profile_info.medical_reg_number,
			medical_council: profile_info.medical_council,
			medical_reg_year: profile_info.education_registration_year,
			second_medical_reg_number: profile_info.second_medical_reg_number,
			second_medical_council: profile_info.second_medical_council,
			second_medical_reg_year: parseInt(profile_info.second_medical_reg_year),
			third_medical_reg_number: profile_info.third_medical_reg_number,
			third_medical_council: profile_info.third_medical_council,
			third_medical_reg_year: parseInt(profile_info.third_medical_reg_year),
			website: profile_info.website,
			about_me: profile_info.about_me,
			reference: profile_info.reference,
			hospital_name: profile_info.hospital_name,
			hospital_address: profile_info.hospital_address,
			hospital_position_held: profile_info.hospital_position_held,
			micr_code: profile_info.micr_code,

			clinics: clinics.length > 0 ? clinics : [1],
			qualifications: qualifications.length > 0 ? qualifications : [1],
			bank_name:
				profile_info.bank_details && profile_info.bank_details.bank_name
					? profile_info.bank_details.bank_name
					: "",
			bank_address:
				profile_info.bank_details && profile_info.bank_details.bank_address
					? profile_info.bank_details.bank_address
					: "",
			bank_code:
				profile_info.bank_details && profile_info.bank_details.bank_IFSC
					? profile_info.bank_details.bank_IFSC
					: "",
			account_number:
				profile_info.bank_details && profile_info.bank_details.bank_accountno
					? profile_info.bank_details.bank_accountno
					: "",
			health_problem: profile_info.health_topics
				? profile_info.health_topics
				: [],
			profile_pic: profile_info.profile_pic,
			medical_registration_proof: profile_info.medical_registration_proof
				? profile_info.medical_registration_proof.split(",")
				: [],
			qualification_proof: profile_info.qualification_proof
				? profile_info.qualification_proof.split(",")
				: [],
			id_proof: profile_info.id_proof ? profile_info.id_proof.split(",") : [],
		});
	}

	healthgroupValue = () => {
		this.setState({ health_problem: window.$("#healthID").val() }, () => { });
	};

	removehealthGroupValue = () => {
		this.setState({ health_problem: window.$("#healthID").val() }, () => { });
	};

	uploadImage = (e, field) => {
		this.props.dispatch(ShowLoader(true));
		let request;
		let formData = new FormData();
		if (field === "profile_pic") {
			formData.append("file", e.target.files[0]);
		} else {
			let files = e.target.files;
			for (let i = 0; i < files.length; i++) {
				formData.append(`file[${i}]`, files[i]);
			}
		}
		formData.append("name", field);
		request = {
			method: "post",
			url: `${apiBaseUrl}/api/upload-image`,
			headers: {
				"Content-Type": "multipart/form-data",
				"X-Auth-Token": `${localStorage.getItem("token")}`,
			},
			data: formData,
		};
		axios(request)
			.then((res) => {
				if (res.data.ResponseCode === "1" && field === "profile_pic") {
					this.setState(
						{
							profile_pic: res.data.imagename,
						},
						() => {
							this.props.dispatch(profileInfo());
							this.props.dispatch(ShowLoader(false));
							this.props.dispatch(
								ShowAlert({
									Class: "alert-success",
									Message: res.data.ResponseText,
									Timeout: 10000,
								})
							);
						}
					);
				} else if (res.data.ResponseCode === "1") {
					let response = res.data.imagename;
					this.setState(
						{
							[field]: response.split(","),
						},
						() => {
							this.props.dispatch(profileInfo());
							this.props.dispatch(ShowLoader(false));
							this.props.dispatch(
								ShowAlert({
									Class: "alert-success",
									Message: res.data.ResponseText,
									Timeout: 10000,
								})
							);
						}
					);
				}
			})
			.catch((error) => {
				this.props.dispatch(ShowLoader(false));
				this.props.dispatch(
					ShowAlert({
						Class: "alert-danger",
						Message: "Error Occured.",
						Timeout: 10000,
					})
				);
				console.log(error);
			});
	};



	openModal = () => {
		this.setState({ openMap: true });
	};

	closeModal = () => {
		this.setState({ openMap: false });
	};

	ActiveClass = array => {
		const { errorMessages } = this.state
		return errorMessages.some(r => array.includes(r)) ? "activeDiv" : ""
	}

	forApprovalHandler = async (status) => {
		let response = await this.props.dispatch(VerifyFitnessExpertApproval({ fitnessID: this.props.match.params.token, status: status,reason:this.state.description }))
		if (response === 1) {
			this.props.dispatch(AdminProfileInfo(this.props.match.params.token));
			this.setState({open:false,description:''})
		}
	}

	submitHandler = e => {
		if(this.validator.allValid()){
			this.forApprovalHandler('R')
		}else{
			this.validator.showMessages();
			this.forceUpdate();
		}

		e.preventDefault();
	}

	setOpen = (value) => {
		this.setState({ description: "", open: false })
	}

	render() {
		this.formValidator.purgeFields()
		const {  clinics, expendedTab, open, description } = this.state;
		const { timezones,profile_info } = this.props;
		return (
			<div>
				{/* collapse start here */}
						<div
							className="accordion profile-setting-collapse"
							id="accordionExample"
						>
							<div className="card-1">
								<div className={`card-header`} id="headingOne">
									<h2 className="mb-0">
										<Link
											to="#"
											onClick={(e) =>
												this.setState((prevState) => ({
													expendedTab:
														prevState.expendedTab === "basicinfo"
															? ""
															: "basicinfo",
												}))
											}
											className="btn btn-link"
											type="Link"
											data-toggle="collapse"
											data-target="#basicinfo"
											aria-expanded="true"
											aria-controls="basicinfo"
										>
											Basic Information
					 					 <span className="float-right collapse-arrow">
												<i
													className={`fas fa-angle-${
														expendedTab === "basicinfo" ? "up" : "down"
														}`}
												></i>
											</span>
										</Link>
									</h2>
								</div>

								<div
									id="basicinfo"
									className="collapse show"
									aria-labelledby="headingOne"
									data-parent="#accordionExample"
								>
									<div className="card">
										<div className="card-body">
											<div className="row form-row">
												<div className="col-md-12">
													<div className="form-group">
														<div className="change-avatar">
															<div className="profile-img">
																<img
																	src={`${
																		this.state.profile_pic
																			? bucketPath + this.state.profile_pic
																			: "/assets/doctor/img/doctors/doctor-thumb-02.jpg"
																		}`}
																	alt="User"
																/>
															</div>
															<div className="upload-img">
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-2 doctor-setp-mid">
													<div className="form-group">
														<label>Title</label>
														<select
															className="form-control"
															name="title"
															value={this.state.title}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														>
															<option>Dr</option>
															<option>Mr</option>
															<option>Mrs</option>
															<option>Miss</option>
														</select>
													</div>
												</div>
												<div className="col-md-5 doctor-setp-mid">
													<div className="form-group">
														<label>First Name</label>
														<input
															type="text"
															className="form-control"
															name="firstname"
															value={this.state.firstname}
															placeholder="First Name"
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														/>
														{this.formValidator.message(
															"firstname",
															this.state.firstname,
															"required|max:50,string"
														)}
													</div>
												</div>
												<div className="col-md-5 doctor-setp-mid">
													<div className="form-group">
														<label>Last Name</label>
														<input
															type="text"
															className="form-control"
															name="lastname"
															placeholder="Last Name"
															value={this.state.lastname}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														/>
														{this.formValidator.message(
															"lastname",
															this.state.lastname,
															"required|max:50,string"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Gender</label>
														<select
															className="form-control select"
															name="gender"
															value={this.state.gender}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														>
															<option value="">Select Gender</option>
															<option value="M">Male</option>
															<option value="F">Female</option>
														</select>
														{this.formValidator.message(
															"gender",
															this.state.gender,
															"required"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Language</label>
														<Select2
															multiple
															className="form-control"
															name="language"
															value={this.state.language}
															data={
																this.props.Language &&
																this.props.Language.map(({ id, name }) => ({
																	text: name,
																	id: id,
																}))
															}
															options={{
																placeholder: "Choose Languages",
															}}
															onSelect={this.groupValue}
															onUnselect={this.removeGroupValue}
															id="languageID"
														/>
														{this.formValidator.message(
															"language",
															this.state.language,
															"required"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group mb-0">
														<label>Phone</label>
														<PhoneInput
															name="phone"
															placeholder="Enter phone number"
															value={this.state.phone}
															onChange={(number) => this.numberChange(number)}
															defaultCountry="IN"
															disabled={true}
														/>

														{this.formValidator.message(
															"phone",
															this.state.phone,
															"required|min:7,string"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Email</label>
														<input
															type="email"
															className="form-control"
															placeholder="Enter Email"
															disabled={true}
															name="email"
															value={this.state.email}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														/>
														{this.formValidator.message(
															"email",
															this.state.email,
															"required|email|max:50,string"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label className="not_required">Websites If any:</label>
														<input
															type="text"
															className="form-control"
															placeholder="Enter Website"
															name="website"
															value={this.state.website}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														/>
														{this.formValidator.message(
															"website",
															this.state.website,
															"url"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Reference ( How you got to know about us)</label>
														<input
															type="text"
															className="form-control"
															placeholder="Enter Reference"
															name="reference"
															value={this.state.reference}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														/>
														{this.formValidator.message(
															"reference",
															this.state.reference,
															"required|max:100,string"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Timezone</label>
														<select
															className="form-control"
															name="timezone"
															value={this.state.timezone}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														>
															<option>Select Timezone</option>
															{timezones &&
																timezones.map((value, keys) => {
																	return (
																		<option
																			value={value.gmtoffset}
																			key={`timezones_${keys}`}
																		>
																			{value.gmtzone}
																		</option>
																	);
																})}
														</select>
														{this.formValidator.message(
															"Timezone",
															this.state.timezone,
															"required"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label className="not_required">About Me</label>
														<textarea
															id="about_me"
															className="form-control"
															name="about_me"
															value={this.state.about_me}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
															rows="1"
															cols="50"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* care 2 */}
							<div className="card-2">
								<div className={`card-header`} id="headingTwo">
									<h2 className="mb-0">
										<Link
											to="#"
											onClick={(e) =>
												this.setState((prevState) => ({
													expendedTab:
														prevState.expendedTab === "clinic-info"
															? ""
															: "clinic-info",
												}))
											}
											className="btn btn-link collapsed"
											type="Link"
											data-toggle="collapse"
											data-target="#clinic-info"
											aria-expanded="false"
											aria-controls="clinic-info"
										>
											Clinic/Hospital Info
					  <span className="float-right collapse-arrow">
												<i
													className={`fas fa-angle-${
														expendedTab === "clinic-info" ? "up" : "down"
														}`}
												></i>
											</span>
										</Link>
									</h2>
								</div>
								<div
									id="clinic-info"
									className="collapse"
									aria-labelledby="headingTwo"
									data-parent="#accordionExample"
								>
									<div className="card">
										<div className="card-body">
											<div className="row form-row" key={`availability`}></div>
											{clinics.map((clinicVal, vals) => {
												return (
													<div className="row form-row" key={`clinic_${vals}`}>
														<div className="col-md-4 doctor-setp-mid">
															<div className="form-group">
																<label className="not_required">Clinic Name</label>
																<input
																	type="text"
																	name={`clinic_${clinicVal}`}
																	value={this.state[`clinic_${clinicVal}`]}
																	className="form-control"
																	onChange={(e) =>
																		this.setState(
																			{ [e.target.name]: e.target.value, errorMessages: [] })
																	}
																	aria-describedby="emailHelp"
																	placeholder="Clinic Name"
																/>
																{clinics.includes(clinicVal) &&
																	(this.state[`clinic_address_${clinicVal}`] !== "" ||
																		this.state[`clinic_city_${clinicVal}`] !== "")
																	? this.formValidator.message(
																		"Clinic",
																		this.state[`clinic_${clinicVal}`],
																		"required"
																	)
																	: null}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid">
															<div className="form-group">
																<label className="not_required">
																	Clinic Address
								</label>
																<input
																	type="text"
																	className="form-control"
																	name={`clinic_address_${clinicVal}`}
																	value={this.state[`clinic_address_${clinicVal}`]}
																	aria-describedby="emailHelp"
																	onChange={(e) =>
																		this.setState(
																			{ [e.target.name]: e.target.value, errorMessages: [] })
																	}
																	placeholder="Clinic Address (Plot/House No, Lane)"
																/>
																{clinics.includes(clinicVal) &&
																	(this.state[`clinic_${clinicVal}`] !== "" ||
																		this.state[`clinic_city_${clinicVal}`] !== "")
																	? this.formValidator.message(
																		"Clinic Address",
																		this.state[`clinic_address_${clinicVal}`],
																		"required"
																	)
																	: null}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid">
															{/* <div className="form-group">
								<label className="not_required">Clinic City</label>
								<div className="input-group">
								  <div className="input-group-prepend">
									<span className="input-group-text">
									  <i className="fa fa-search"></i>
									</span>
								  </div>
								  <button
									type="button"
									className="form-control no-padding-left Search-control-outer dropdown-toggle"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								  >
									{`${
									  this.state[`clinic_city_${clinicVal}`]
										? this.state[`clinic_city_${clinicVal}`]
										: "Search Cities"
									}`}
								  </button>
								  <div className="dropdown-menu Search-control-drop dropdown-content dropdown-menu-right">
									<input
									  className="form-control Search-control"
									  name={`clinic_city_search_${clinicVal}`}
									  value={
										this.state[
										  `clinic_city_search_${clinicVal}`
										]
									  }
									  type="text"
									  placeholder="Search.."
									  onChange={(e) =>
										this.SearchCitiesClinic(e, clinicVal)
									  }
									/>
									{suggested_clinic_cities &&
									  suggested_clinic_cities.map((value, key) => {
										return (
										  <Link
											key={`clinic_cities_${key}${clinicVal}`}
											to="#"
											className="dropdown-item"
											onClick={(e) =>
											  this.AutoFillClinicCity(
												value,
												clinicVal
											  )
											}
										  >
											{value.name}
										  </Link>
										);
									  })}
								  </div>
								</div>
								{clinics.includes(clinicVal) &&
								(this.state[`clinic_${clinicVal}`] !== "" ||
								  this.state[`clinic_address_${clinicVal}`] !== "")
								  ? this.formValidator.message(
									  "Clinic City",
									  this.state[`clinic_city_${clinicVal}`],
									  "required"
									)
								  : null}
							  </div> */}
														</div>
														{/* <div className="col-md-1 add-remove text-center align-self-center">
							  {clinicVal > 1 && (
								<Link to="#">
								  <i
									className="fas fa-minus-square"
									onClick={(e) =>
									  this.setState(
										{
										  clinics: clinics.filter(
											(val) => val !== clinicVal
										  ),
										},
										() => {
										  this.props.dispatch(
											clinicNumber(clinicVal - 1)
										  );
										}
									  )
									}
								  ></i>
								</Link>
							  )}
							  {clinicVal < 5 && (
								<Link to="#">
								  <i
									className="fas fa-plus-square"
									onClick={(e) =>
									  this.setState(
										{ clinics: [...clinics, clinicVal + 1] },
										() => {
										  this.props.dispatch(
											clinicNumber(clinicVal + 1)
										  );
										}
									  )
									}
								  ></i>
								</Link>
							  )}
							</div> */}
													</div>
												);
											})}
											{/* /////////////////////////////////////////////////////////HOSPITAL INFO/////////////////////////////////////////////////////////////// */}
											<div className="row form-row" key={`hospital_first`}>
												<div className="col-md-4 doctor-setp-mid">
													<div className="form-group">
														<label className="not_required">Hospital Name</label>
														<input
															type="text"
															name="hospital_name"
															value={this.state.hospital_name}
															className="form-control"
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
															aria-describedby="emailHelp"
															placeholder="Hospital Name"
														/>
														{(this.state.hospital_position_held !== "" ||
															this.state.hospital_address !== "") ?
															this.formValidator.message(
																"Hospital Name",
																this.state.hospital_name,
																"required"
															) : null}
													</div>
												</div>
												<div className="col-md-4 doctor-setp-mid">
													<div className="form-group">
														<label className="not_required">Hospital Address</label>
														<input
															type="text"
															className="form-control"
															name="hospital_address"
															value={this.state.hospital_address}
															aria-describedby="emailHelp"
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
															placeholder="Hospital Address (Plot/House No, Lane)"
														/>
														{(this.state.hospital_name !== "" ||
															this.state.hospital_position_held !== "") ?
															this.formValidator.message(
																"Hospital Address",
																this.state.hospital_address,
																"required"
															) : null}
													</div>
												</div>
												<div className="col-md-4 doctor-setp-mid">
													<div className="form-group">
														<label className="not_required">Position Held</label>
														<input
															type="text"
															className="form-control"
															name="hospital_position_held"
															value={this.state.hospital_position_held}
															aria-describedby="emailHelp"
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
															placeholder="Position Held"
														/>
														{(this.state.hospital_name !== "" ||
															this.state.hospital_address !== "") ?
															this.formValidator.message(
																"Position Held",
																this.state.hospital_position_held,
																"required"
															) : null}
													</div>
												</div>
											</div>
											{/* ///////////////////////////////////////////////// HOSPITAL INFO END////////////////////////////////////////////////// */}
										</div>
									</div>
								</div>
							</div>
							{/* card-3 */}
							<div className="card-3">
								<div className={`card-header`} id="headingThree">
									<h2 className="mb-0">
										<Link
											to="#"
											onClick={(e) =>
												this.setState((prevState) => ({
													expendedTab:
														prevState.expendedTab === "contact-detail"
															? ""
															: "contact-detail",
												}))
											}
											className="btn btn-link collapsed"
											type="Link"
											data-toggle="collapse"
											data-target="#contact-detail"
											aria-expanded="false"
											aria-controls="contact-detail"
										>
											Contact Details
					  <span className="float-right collapse-arrow">
												<i
													className={`fas fa-angle-${
														expendedTab === "contact-detail" ? "up" : "down"
														}`}
												></i>
											</span>
										</Link>
									</h2>
								</div>
								<div
									id="contact-detail"
									className="collapse"
									aria-labelledby="headingThree"
									data-parent="#accordionExample"
								>
									<div className="card contact-card">
										<div className="card-body">
											{/* <button onMouseUp={this.openModal}>open modal</button> */}
											<MapModal
												openMap={this.state.openMap}
												closeModal={this.closeModal}
											>
												<MapAutocomplete
													center={{ lat: 18.5204, lng: 73.8567 }}
													height="400px"
													zoom={16}
												/>
											</MapModal>
											<div className="row form-row">
												<div className="col-md-4 doctor-setp-mid">
													<div className="form-group">
														<label>Street/Lane Address</label>
														<div className="input-group">
															<input
																className="form-control Search-control"
																id="address"
																name="address"
																value={this.state.address}
																type="text"
																placeholder="Street/Lane Address"
																onChange={(e) =>
																	this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
																}
															/>
														</div>
														{this.formValidator.message(
															"address",
															this.state.address,
															"required"
														)}
													</div>
												</div>
												<div className="col-md-4 doctor-setp-mid">
													<div className="form-group">
														<label>
															Location
							  <span>
																<i
																	className="fa fa-map-marker"
																	style={{ color: "red" }}
																	aria-hidden="true"
																	onMouseUp={this.openModal}
																></i>
															</span>
														</label>
														<div className="input-group">
															<input
																className="form-control Search-control"
																id="location_data"
																name="location"
																value={this.state.location}
																type="text"
																onChange={(e) => e.preventDefault()}
															/>
														</div>
														{this.formValidator.message(
															"location",
															this.state.location,
															"required|max:50,string"
														)}
													</div>
												</div>
												{/* <div className="col-md-4 doctor-setp-mid">
						  <div className="form-group">
							<label>State</label>
							<div className="input-group">
							  <input
								className="form-control Search-control"
								readOnly
								id="Search_State"
								name="search_state"
								value={this.state.state}
								type="text"
							  />
							</div>
							{this.formValidator.message(
							  "State",
							  this.state.state,
							  "required|max:50,string"
							)}
						  </div>
						</div> */}
												<div className="col-md-4 doctor-setp-mid">
													<div className="form-group">
														<label>Country</label>
														<div className="input-group">
															<input
																className="form-control Search-control"
																id="country"
																name="country"
																value={this.state.country}
																onChange={(e) => e.preventDefault()}
																type="text"
															/>
														</div>
														{this.formValidator.message(
															"country",
															this.state.country,
															"required|max:50,string"
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* card-4 */}
							<div className="card-4">
								<div className={`card-header`} id="headingFour">
									<h2 className="mb-0">
										<Link
											to="#"
											onClick={(e) =>
												this.setState((prevState) => ({
													expendedTab:
														prevState.expendedTab === "Specialization"
															? ""
															: "Specialization",
												}))
											}
											className="btn btn-link collapsed"
											type="Link"
											data-toggle="collapse"
											data-target="#Specialization"
											aria-expanded="false"
											aria-controls="Specialization"
										>
											Specialization & Experience
					  <span className="float-right collapse-arrow">
												<i
													className={`fas fa-angle-${
														expendedTab === "Specialization" ? "up" : "down"
														}`}
												></i>
											</span>
										</Link>
									</h2>
								</div>
								<div
									id="Specialization"
									className="collapse"
									aria-labelledby="headingFour"
									data-parent="#accordionExample"
								>
									<div className="card services-card">
										<div className="card-body">
											<div className="row form-row">
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Specialization</label>
                        {/* <select
															className="form-control"
															name="specialization"
															value={this.state.specialization}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														>
															<option>Select Specialization</option>
															{specializations &&
																specializations.map((value, keys) => {
																	return (
																		<option
																			value={value.id}
																			key={`specialization_${keys}`}
																		>
																			{value.name}
																		</option>
																	);
																})}
                        </select> */}
                        <input
                            className="form-control Search-control"
                            id="specialization"
                            name="specialization"
                            value={this.state.specialization ?? ''}
                            onChange={(e) =>
                              this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
                            }
                            type="text"
                          />
														{this.formValidator.message(
															"specialization",
															this.state.specialization,
															"required"
														)}
													</div>
												</div>
												<div className="col-md-6 doctor-setp-mid">
													<div className="form-group">
														<label>Experience</label>
														<select
															className="form-control"
															name="experience"
															value={this.state.experience}
															onChange={(e) =>
																this.setState({ [e.target.name]: e.target.value, errorMessages: [] })
															}
														>
															<option>Select Experience</option>
															{[...Array(51).keys()].map((value) => {
																return (
																	<option value={value} key={`experience_${value}`}>
																		{value === 0 ? `<1 year` : `${value}+ year`}
																	</option>
																);
															})}
														</select>
														{this.formValidator.message(
															"experience",
															this.state.experience,
															"required"
														)}
													</div>
												</div>
                    {/* <div className="col-md-12 doctor-setp-mid">
													<div className="form-group">
														<label>Symptoms / Health Problem</label>
														<Select2
															multiple
															className="form-control"
															name="health_problem"
															value={this.state.health_problem}
															data={symptomListing && symptomListing.map(({ name }) => ({ text: name, id: name }))}
															options={{
																placeholder: "Choose Health Problem",
															}}
															onSelect={this.healthgroupValue}
															onUnselect={this.removehealthGroupValue}
															id="healthID"
														/>
														{this.formValidator.message(
															"Health Problem",
															this.state.health_problem,
															"required"
														)}
													</div>
                    </div> */}
											</div>
										</div>
									</div>
								</div>
								{/* card-
								<div className="card-5">
									<div className={`card-header`} id="headingFive">
										<h2 className="mb-0">
											<Link
												to="#"
												onClick={(e) =>
													this.setState((prevState) => ({
														expendedTab:
															prevState.expendedTab === "Education"
																? ""
																: "Education",
													}))
												}
												className="btn btn-link collapsed"
												type="Link"
												data-toggle="collapse"
												data-target="#Education"
												aria-expanded="false"
												aria-controls="Education"
											>
												Education{" "}
												<i
													className={`fas fa-angle-${
														expendedTab === "Education" ? "up" : "down"
														} float-right collapse-arrow`}
												></i>
											</Link>
										</h2>
									</div>
									<div
										id="Education"
										className="collapse"
										aria-labelledby="headingFive"
										data-parent="#accordionExample"
									>
										<div className="card">
											<div className="card-body">
												<div className="education-info">
													<div className="row form-row education-cont">
														<div className="col-12 col-md-10 col-lg-11">
															{qualifications.map((value, key) => {
																return (
																	<div
																		className="row repeat-row"
																		key={`Qual_${value}`}
																	>
																		<div className="col-md-4 doctor-setp-mid">
																			<div className="form-group">
																				<label htmlFor="exampleInputEmail1">
																					Degree
										</label>
																				<input
																					type="text"
																					name={`degree_${value}`}
																					value={this.state[`degree_${value}`]}
																					className="form-control"
																					id="exampleInputEmail1"
																					aria-describedby="emailHelp"
																					onChange={(e) =>
																						this.setState(
																							{ [e.target.name]: e.target.value, errorMessages: [] }
																						)
																					}
																					placeholder="Degree"
																				/>
																				{qualifications.includes(value)
																					? this.formValidator.message(
																						"degree",
																						this.state[`degree_${value}`],
																						"required"
																					)
																					: null}
																			</div>
																		</div>
																		<div className="col-md-4 doctor-setp-mid">
																			<div className="form-group">
																				<label htmlFor="exampleInputEmail1">
																					{" "}
										  College/Institute
										</label>
																				<input
																					type="text"
																					name={`college_${value}`}
																					value={this.state[`college_${value}`]}
																					className="form-control"
																					id="exampleInputEmail1"
																					aria-describedby="emailHelp"
																					onChange={(e) =>
																						this.setState(
																							{ [e.target.name]: e.target.value, errorMessages: [] })
																					}
																					placeholder="College/Institute"
																				/>
																				{qualifications.includes(value)
																					? this.formValidator.message(
																						"college",
																						this.state[`college_${value}`],
																						"required"
																					)
																					: null}
																			</div>
																		</div>
																		<div className="col-md-3 doctor-setp-mid">
																			<div className="form-group">
																				<label htmlFor="exampleInputEmail1">
																					Year of Completion
										</label>
																				<input
																					type="number"
																					name={`year_${value}`}
																					className="form-control"
																					value={this.state[`year_${value}`]}
																					id="exampleInputEmail1"
																					aria-describedby="emailHelp"
																					onChange={(e) =>
																						this.setState(
																							{ [e.target.name]: e.target.value, errorMessages: [] })
																					}
																					placeholder="Completion Year"
																				/>
																				{qualifications.includes(value)
																					? this.formValidator.message(
																						"year",
																						this.state[`year_${value}`],
																						"required|between:1960,2020,num"
																					)
																					: null}
																			</div>
																		</div>
																		<div className="col-md-1 add-remove text-center align-self-center">
																			{value > 1 && (
																				<Link to="#">
																					<i
																						className="fas fa-minus-square"
																						onClick={(e) =>
																							this.setState(
																								{
																									qualifications: qualifications.filter(
																										(val) => val !== value
																									),
																								})
																						}
																					></i>
																				</Link>
																			)}
																			{value < 5 && (
																				<Link to="#">
																					<i
																						className="fas fa-plus-square"
																						onClick={(e) =>
																							this.setState(
																								{
																									qualifications: [
																										...qualifications,
																										value + 1,
																									],
																								})
																						}
																					></i>
																				</Link>
																			)}
																		</div>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* card-6
								<div className="card-6">
									<div className={`card-header`} id="headingSix">
										<h2 className="mb-0">
											<Link
												to="#"
												onClick={(e) =>
													this.setState((prevState) => ({
														expendedTab:
															prevState.expendedTab === "Registrations"
																? ""
																: "Registrations",
													}))
												}
												className="btn btn-link collapsed"
												type="Link"
												data-toggle="collapse"
												data-target="#Registrations"
												aria-expanded="false"
												aria-controls="Registrations"
											>
												Registrations{" "}
												<i
													className={`fas fa-angle-${
														expendedTab === "Registrations" ? "up" : "down"
														} float-right collapse-arrow`}
												></i>
											</Link>
										</h2>
									</div>
									<div
										id="Registrations"
										className="collapse"
										aria-labelledby="headingSix"
										data-parent="#accordionExample"
									>
										<div className="card">
											<div className="card-body">
												<div className="registrations-info">
													<div className="row form-row reg-cont">
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="">
																	Main Registration Number
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="medical_reg_number"
																	value={this.state.medical_reg_number}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Registration Number"
																/>
																{(this.state.medical_council !== "" || this.state.medical_reg_year !== "") && this.formValidator.message(
																	"Registration Number",
																	this.state.medical_reg_number,
																	"required"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label>Main Registration Council</label>
																<select
																	className="form-control"
																	name="medical_council"
																	value={this.state.medical_council}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option>Select Council</option>
																	{medical_council &&
																		medical_council.map((value, keys) => {
																			return (
																				<option
																					value={value.name}
																					key={`medical_council_${keys}`}
																				>
																					{value.name}
																				</option>
																			);
																		})}
																</select>
																{(this.state.medical_reg_number !== "" || this.state.medical_reg_year !== "") && this.formValidator.message(
																	"Medical Council",
																	this.state.medical_council,
																	"required"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Main Registration Year
								</label>
																<input
																	type="number"
																	className="form-control"
																	name="medical_reg_year"
																	value={this.state.medical_reg_year}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Registration Year"
																/>
																{(this.state.medical_reg_number !== "" || this.state.medical_council !== "") && this.formValidator.message(
																	"Medical Registration",
																	this.state.medical_reg_year,
																	"required|between:1960,2020,num"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Second Registration Number
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="second_medical_reg_number"
																	value={this.state.second_medical_reg_number}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Registration Number"
																/>
																{(this.state.second_medical_council !== "" || this.state.second_medical_reg_year !== "") && this.formValidator.message(
																	"Registration Number",
																	this.state.second_medical_reg_number,
																	"required"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label>Second Registration Council</label>
																<select
																	className="form-control"
																	name="second_medical_council"
																	value={this.state.second_medical_council}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option>Select Council</option>
																	{medical_council &&
																		medical_council.map((value, keys) => {
																			return (
																				<option
																					value={value.name}
																					key={`second_medical_council_${keys}`}
																				>
																					{value.name}
																				</option>
																			);
																		})}
																</select>
																{(this.state.second_medical_reg_number !== "" || this.state.second_medical_reg_year !== "") && this.formValidator.message(
																	"Medical Council",
																	this.state.second_medical_council,
																	"required"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Second Registration Year
								</label>
																<input
																	type="number"
																	className="form-control"
																	name="second_medical_reg_year"
																	value={this.state.second_medical_reg_year}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Registration Year"
																/>
																{(this.state.second_medical_reg_number !== "" || this.state.second_medical_council !== "") && this.formValidator.message(
																	"Medical Registration",
																	this.state.second_medical_reg_year,
																	"required|between:1960,2020,num"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Third Registration Number
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="third_medical_reg_number"
																	value={this.state.third_medical_reg_number}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Third Registration Number"
																/>
																{(this.state.third_medical_council !== "" || this.state.third_medical_reg_year !== "") && this.formValidator.message(
																	"Registration Number",
																	this.state.third_medical_reg_number,
																	"required"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label>Third Registration Council</label>
																<select
																	className="form-control"
																	name="third_medical_council"
																	value={this.state.third_medical_council}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option>Select Council</option>
																	{medical_council &&
																		medical_council.map((value, keys) => {
																			return (
																				<option
																					value={value.name}
																					key={`third_medical_council_${keys}`}
																				>
																					{value.name}
																				</option>
																			);
																		})}
																</select>
																{(this.state.third_medical_reg_number !== "" || this.state.third_medical_reg_year !== "") && this.formValidator.message(
																	"Medical Council",
																	this.state.third_medical_council,
																	"required"
																)}
															</div>
														</div>
														<div className="col-md-4 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Third Registration Year
								</label>
																<input
																	type="number"
																	className="form-control"
																	name="third_medical_reg_year"
																	value={this.state.third_medical_reg_year}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Third Registration Year"
																/>
																{(this.state.third_medical_reg_number !== "" || this.state.third_medical_council !== "") && this.formValidator.message(
																	"Medical Registration",
																	this.state.third_medical_reg_year,
																	"required|between:1960,2020,num"
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* card-7 */}
								<div className="card-7">
									<div className={`card-header`} id="headingSeven">
										<h2 className="mb-0">
											<Link
												to="#"
												onClick={(e) =>
													this.setState((prevState) => ({
														expendedTab:
															prevState.expendedTab === "Fees-consultation"
																? ""
																: "Fees-consultation",
													}))
												}
												className="btn btn-link collapsed"
												type="Link"
												data-toggle="collapse"
												data-target="#Fees-consultation"
												aria-expanded="false"
												aria-controls="Fees-consultation"
											>
												Fees For Consultation{" "}
												<i
													className={`fas fa-angle-${
														expendedTab === "Fees-consultation" ? "up" : "down"
														} float-right collapse-arrow`}
												></i>
											</Link>
										</h2>
									</div>
									<div
										id="Fees-consultation"
										className="collapse"
										aria-labelledby="headingSeven"
										data-parent="#accordionExample"
									>
										<div className="card">
											<div className="card-body">
												<div className="row">
													<div className="col-md-12 doctor-setp-mid">
														<div className="form-group row">
															<label
																htmlFor="staticEmail"
																className="col-sm-5 col-form-label"
															>
																Basic consultation fees
							  </label>
															<div className="col-sm-2">
																<input
																	type="text"
																	className="form-control"
																	name="mail_consultation_fee"
																	value={this.state.mail_consultation_fee}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																	placeholder="Amount"
																/>
																{this.formValidator.message(
																	"mail consultation fee",
																	this.state.mail_consultation_fee,
																	"required"
																)}
															</div>
														</div>
													</div>
												</div>
												{/* <div className="row">
													<div className="col-md-12 doctor-setp-mid">
														<div className="form-group row">
															<label
																htmlFor="staticEmail"
																className="col-sm-5 col-form-label"
															>
																Chat Consultation along with time
							  </label>
															<div className="col-sm-2">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Amount"
																	aria-describedby="emailHelp"
																	name="chat_consultation_fee"
																	value={this.state.chat_consultation_fee}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
															</div>
															<div className="col-sm-1 for-class text-center align-self-center">
																<p className="text-red">For</p>
															</div>
															<div className="col-sm-2">
																<select
																	className="form-control"
																	id="chat_consultation_fee_time"
																	name="chat_consultation_mins"
																	value={this.state.chat_consultation_mins}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option>Select</option>
																	<option value="15">15 Minutes</option>
																	<option value="30">30 Minutes</option>
																	<option value="45">45 Minutes</option>
																	<option value="60">60 Minutes</option>
																</select>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 doctor-setp-mid">
														<div className="form-group row">
															<label
																htmlFor="staticEmail"
																className="col-sm-5 col-form-label"
															>
																Fees for Audio Consultation along with time
							  </label>
															<div className="col-sm-2">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Amount"
																	aria-describedby="emailHelp"
																	name="phone_consultation_fee"
																	value={this.state.phone_consultation_fee}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
															</div>
															<div className="col-sm-1 for-class text-center align-self-center">
																<p className="text-red">For</p>
															</div>
															<div className="col-sm-2">
																<select
																	className="form-control"
																	id="exampleFormControlSelect1"
																	name="phone_consultation_mins"
																	value={this.state.phone_consultation_mins}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option value="">Select</option>
																	<option value="15">15 Minutes</option>
																	<option value="30">30 Minutes</option>
																	<option value="45">45 Minutes</option>
																	<option value="60">60 Minutes</option>
																</select>
															</div>
														</div>
													</div>
												</div> */}
												<div className="row">
													<div className="col-md-12 doctor-setp-mid">
														<div className="form-group row">
															<label
																htmlFor="staticEmail"
																className="col-sm-5 col-form-label"
															>
																Fees for Video Consultation along with time
							  </label>
															<div className="col-sm-2">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Amount"
																	aria-describedby="emailHelp"
																	name="video_consultation_fee"
																	value={this.state.video_consultation_fee}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
															</div>
															<div className="col-sm-1 for-class text-center align-self-center">
																<p className="text-red">For</p>
															</div>
															<div className="col-sm-2">
																<select
																	className="form-control"
																	id="exampleFormControlSelect1"
																	name="video_consultation_mins"
																	value={this.state.video_consultation_mins}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option>Select</option>
																	<option value="15">15 Minutes</option>
																	<option value="30">30 Minutes</option>
																	<option value="45">45 Minutes</option>
																	<option value="60">60 Minutes</option>
																</select>
															</div>
														</div>
													</div>
												</div>

												{/* <div className="row">
													<div className="col-md-12 doctor-setp-mid">
														<div className="form-group row">
															<label
																htmlFor="staticEmail"
																className="col-sm-5 col-form-label"
															>
																Walkin Consultation along with time
							  </label>
															<div className="col-sm-2">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Amount"
																	aria-describedby="emailHelp"
																	name="walkin_consultation_fee"
																	value={this.state.walkin_consultation_fee}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
																{this.formValidator.message(
																	"Walkin consultation fee",
																	this.state.walkin_consultation_fee,
																	"required|min:1,num"
																)}
															</div>
															<div className="col-sm-1 for-class text-center align-self-center">
																<p className="text-red">For</p>
															</div>
															<div className="col-sm-2">
																<select
																	className="form-control"
																	id="walkin_consultation_mins"
																	name="walkin_consultation_mins"
																	value={this.state.walkin_consultation_mins}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																>
																	<option>Select</option>
																	<option value="15">15 Minutes</option>
																	<option value="30">30 Minutes</option>
																	<option value="45">45 Minutes</option>
																	<option value="60">60 Minutes</option>
																</select>
																{this.formValidator.message(
																	"Walkin consultation mins",
																	this.state.walkin_consultation_mins,
																	"required"
																)}
															</div>
														</div>
													</div>
												</div> */}
											</div>
										</div>
									</div>
								</div>
								{/* card-8 */}
								<div className="card-7">
									<div className="card-header" id="headingEight">
										<h2 className="mb-0">
											<Link
												to="#"
												onClick={(e) =>
													this.setState((prevState) => ({
														expendedTab:
															prevState.expendedTab === "Upload-documents"
																? ""
																: "Upload-documents",
													}))
												}
												className="btn btn-link collapsed"
												type="Link"
												data-toggle="collapse"
												data-target="#Upload-documents"
												aria-expanded="false"
												aria-controls="Upload-documents"
											>
												Upload Documents for Verification{" "}
												<i
													className={`fas fa-angle-${
														expendedTab === "Upload-documents" ? "up" : "down"
														} float-right collapse-arrow`}
												></i>
											</Link>
										</h2>
									</div>
									<div
										id="Upload-documents"
										className="collapse"
										aria-labelledby="headingEight"
										data-parent="#accordionExample"
									>
										<div className="card">
											<div className="card-body">
												<div className="registrations-info">
													<div className="row form-row reg-cont">
														<div className="col-md-12">
															<div className="form-group row">
																<label
																	className="col-md-4"
																	htmlFor="inputMedicalRegistration"
																>
																	Medical Registration Proof
								</label>
																<div className="col-md-8">
																	{/* <div className="custom-file">
																		<input
																			type="file"
																			className="custom-file-input"
																			id="inputMedicalRegistration"
																			name="medical_registration_proof"
																			onChange={(e) =>
																				this.uploadImage(
																					e,
																					"medical_registration_proof"
																				)
																			}
																			multiple
																			aria-describedby="inputGroupFileAddon01"
																			accept="image/*"
																			ref={(input) => {
																				this.medical_registration_proof = input;
																			}}
																		/>
																		<label
																			className="custom-file-label custom-file-label-doc"
																			htmlFor="inputGroupFileAddon01"
																		>
																			{this.state.medical_registration_proof
																				? this.state.medical_registration_proof
																				: "Add Registration Proof"}
																		</label>
																	</div> */}
																</div>
																<div className="col-md-8">
																	<div className="form-group">
																		{this.state.medical_registration_proof.map(
																			(value) => {
																				return (
																					<div
																						className="change-avatar"
																						key={`avatar${value}`}
																					>
																						<div className="profile-img">
																							<Link to="#">
																							</Link>
																							<img
																								alt="User"
																								src={`${bucketPath}${value}`}
																							/>
																						</div>
																					</div>
																				);
																			}
																		)}
																	</div>
																</div>
															</div>
														</div>
														{/* <div className="col-md-12">
															<div className="form-group row">
																<label
																	className="col-md-4"
																	htmlFor="inputDegreeRegistrationn"
																>
																	Degree Proof
								</label>
																<div className="col-md-8">
																	<div className="custom-file">
																		<input
																			type="file"
																			className="custom-file-input"
																			id="inputDegreeRegistrationn"
																			name="qualification_proof"
																			ref={(input) => {
																				this.qualification_proof = input;
																			}}
																			onChange={(e) =>
																				this.uploadImage(e, "qualification_proof")
																			}
																			aria-describedby="inputDegreeRegistration123"
																			multiple
																			accept="image/*"
																		/>
																		<label
																			className="custom-file-label custom-file-label-doc"
																			htmlFor="inputDegreeRegistration123"
																		>
																			{this.state.qualification_proof.length > 0
																				? this.state.qualification_proof
																				: "Add Qualification Proof"}
																		</label>
																	</div>
																</div>
																<div className="col-md-8">
																	<div className="form-group">
																		{this.state.qualification_proof.map((value) => {
																			return (
																				<div
																					className="change-avatar"
																					key={`avatar1${value}`}
																				>
																					<div className="profile-img">
																						<Link to="#">
																						</Link>
																						<img
																							alt="User"
																							src={`${bucketPath}${value}`}
																						/>
																					</div>
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>
														</div>
														<div className="col-md-12 ">
															<div className="form-group row">
																<label
																	className="col-md-4"
																	htmlFor="inputPhotoRegistration"
																>
																	Goverment-Issued Photo Address/ID Proof
								</label>
																<div className="col-md-8">
																	{/* <div className="custom-file">
																		<input
																			type="file"
																			className="custom-file-input"
																			id="inputPhotoRegistration"
																			name="id_proof"
																			multiple
																			onChange={(e) =>
																				this.uploadImage(e, "id_proof")
																			}
																			aria-describedby="inputPhotoRegistration1"
																			accept="image/*"
																			ref={(input) => {
																				this.id_proof = input;
																			}}
																		/>
																		<label
																			className="custom-file-label custom-file-label-doc"
																			htmlFor="inputPhotoRegistration1"
																		>
																			{this.state.id_proof
																				? this.state.id_proof
																				: "Add Photo ID Proof"}
																		</label>
																	</div>
																</div>
																<div className="col-md-8">
																	<div className="form-group">
																		{this.state.id_proof.map((value) => {
																			return (
																				<div
																					className="change-avatar"
																					key={`avatar2${value}`}
																				>
																					<div className="profile-img">
																						<Link to="#">
																						</Link>
																						<img
																							alt="User"
																							src={`${bucketPath}${value}`}
																						/>
																					</div>
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>
                        </div> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="card-7">
									<div className="card-header" id="headingEight">
										<h2 className="mb-0">
											<Link
												to="#"
												onClick={(e) =>
													this.setState((prevState) => ({
														expendedTab:
															prevState.expendedTab === "bank-documents"
																? ""
																: "bank-documents",
													}))
												}
												className="btn btn-link collapsed"
												type="Link"
												data-toggle="collapse"
												data-target="#bank-documents"
												aria-expanded="false"
												aria-controls="Bank-documents"
											>
												Bank Details
						<i
													className={`fas fa-angle-${
														expendedTab === "bank-documents" ? "up" : "down"
														} float-right collapse-arrow`}
												></i>
											</Link>
										</h2>
									</div>
									<div
										id="bank-documents"
										className="collapse"
										aria-labelledby="headingEight"
										data-parent="#accordionExample"
									>
										<div className="card">
											<div className="card-body">
												<div className="registrations-info">
													<div className="row form-row reg-cont">
														<div className="col-md-6 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Bank's Name
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="bank_name"
																	value={this.state.bank_name}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
																{/* {this.formValidator.message(
								  "Bank Name",
								  this.state.bank_name,
								  "required"
								)} */}
															</div>
														</div>
														<div className="col-md-6 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Bank's Address
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="bank_address"
																	value={this.state.bank_address}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
																{/* {this.formValidator.message(
								  "Bank Address",
								  this.state.bank_address,
								  "required"
								)} */}
															</div>
														</div>
														<div className="col-md-6 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Bank's Account Number
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="account_number"
																	value={this.state.account_number}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
																{/* {this.formValidator.message(
								  "Bank Account Number",
								  this.state.account_number,
								  "required"
								)} */}
															</div>
														</div>
														<div className="col-md-6 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Bank's IFSC Code
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="bank_code"
																	value={this.state.bank_code}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
																{/* {this.formValidator.message(
								  "Bank Code",
								  this.state.bank_code,
								  "required"
								)} */}
															</div>
														</div>
														<div className="col-md-6 doctor-setp-mid ">
															<div className="form-group">
																<label htmlFor="exampleInputEmail1">
																	Bank's MICR Code
								</label>
																<input
																	type="text"
																	className="form-control"
																	name="micr_code"
																	value={this.state.micr_code}
																	onChange={(e) =>
																		this.setState({
																			[e.target.name]: e.target.value, errorMessages: [],
																		})
																	}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* main div close */}
							</div>

							<div className="submit-section submit-btn-bottom">
								{['PA'].includes(profile_info.status) && <Button variant="warning" className="btn submit-btn" onClick={e => this.forApprovalHandler("A")}>Approve</Button>}
								{['PA'].includes(profile_info.status) && <Button variant="danger" className="btn submit-btn" onClick={e => this.setState({ open: true })}>Reject</Button>}
							</div>
						</div>

				<Modals open={open} setOpen={this.setOpen}>
					<Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">
							Add description
            			</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-md-12">
									<div className="form-group">
										<label>Description</label>
										<textarea
											className="form-control"
											name="description"
											value={description}
											onChange={(e) => this.setState({description:e.target.value})}
											rows={3}
										/>
										{this.validator.message(
											"Description",
											description,
											"required"
										)}
									</div>
								</div>
							</div>
							<Link
								to="#"
								className="btn btn-light-black btn-block"
								onClick={(e) => this.submitHandler(e)}
							>
								Save
              </Link>
						</form>
					</Modal.Body>
				</Modals>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	Language: state.static.languages,
	specializations: state.static.specializations,
	suggested_cities: state.static.suggested_cities,
	suggested_clinic_cities: state.static.suggested_clinic_cities,
	suggested_states: state.static.suggested_states,
	suggested_countries: state.static.suggested_countries,
	medical_council: state.user.medical_council,
	profile_info: state.admin.admin_profile_info,
	DoctorID: state.admin.uniqueID,
	data: state.maps.data,
	timezones: state.static.timezones,
	symptomListing: state.admin.adminSpecListing.data,

});

export default connect(mapStateToProps)(AdminFitnessExpertProfile);
