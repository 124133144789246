import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SubscriptionChargeForm from "./SubscriptionChargeForm";
import { AdminSubscriptionChargeList, DeleteSubscriptionCharge } from "../../store/actions/adminActions";
import Pagination from "./../../store/helpers/Pagination";
import swal from "sweetalert";

class AdminSubscriptionCharge extends Component {
    state = {
        openFormModal: false,
        pageOfItems: [],
        currentPage: 1,
        subscription: {},
        tab: "doctor"
    };

    componentDidMount() {
        this.getSubscriptionList();
    }

    onFormModalClose = () => {
        this.getSubscriptionList();
        this.setState({ openFormModal: false, subscription: {} });
    };

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    onEditCharge = (subscription) => {
        this.setState({ openFormModal: true, subscription });
    }

    getSubscriptionList = () => {
        const { tab } = this.state;
        this.props.AdminSubscriptionChargeList({ type: tab === "fitness" ? "F" : tab === "doctor" ? "D" : "C" });
    }

    onDeleteCharge = (uniquekey) => {
        swal({
            title: "Are you sure want to delete this Subscription?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.DeleteSubscriptionCharge({
                    uniquekey
                });
            }
        });
    }

    render() {
        const { subscription_charge_list } = this.props;
        const { pageOfItems, openFormModal, subscription, tab } = this.state;
        return (
            <div>
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-7 col-auto"></div>
                        <div className="col-sm-5 col">
                            <Link
                                to="#"
                                className=" btn btn-light-black  float-right "
                                onClick={() => {
                                    this.setState({ openFormModal: true, subscription: {} });
                                }}
                            >
                                Add
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <nav className="user-tabs mb-4">
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li className="nav-item">
                                            <Link className={`nav-link ${tab === "doctor" ? "active" : ""}`} to="#" onClick={e => this.setState({ tab: 'doctor' }, () => { this.getSubscriptionList() })} >Doctors</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${tab === "counselor" ? "active" : ""}`} to="#" onClick={e => this.setState({ tab: 'counselor' }, () => { this.getSubscriptionList() })}>Counsellors</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${tab === "fitness" ? "active" : ""}`} to="#" onClick={e => this.setState({ tab: 'fitness' }, () => { this.getSubscriptionList() })}>Fitness Expert</Link>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="table-responsive">
                                    <table className="datatable table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Date</th>
                                                <th>Area</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>Charge</th>
                                                <th className="text-right">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pageOfItems.length === 0 ?
                                                    (
                                                        <tr>
                                                            <td>
                                                                No records available
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    ""
                                            }
                                            {
                                                pageOfItems && pageOfItems.map((val, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {(this.state
                                                                .currentPage -
                                                                1) *
                                                                20 +
                                                                (i + 1)}
                                                        </td>
                                                        <td>{val.creationdate}</td>
                                                        <td>{val.area}</td>
                                                        <td>{val.state}</td>
                                                        <td>{val.country}</td>
                                                        <td>{val.charge}</td>
                                                        <td className="text-right">
                                                            <div className="actions">
                                                                <Link
                                                                    className="btn btn-sm bg-success-light"
                                                                    to="#"
                                                                    onClick={() =>
                                                                        this.onEditCharge(val)
                                                                    }
                                                                >
                                                                    <i className="fe fe-pencil"></i>
                                                                    Edit
                                                                </Link>
                                                                <Link
                                                                    data-toggle="modal"
                                                                    to="#"
                                                                    className="btn btn-sm bg-danger-light"
                                                                    onClick={() => this.onDeleteCharge(val.unique_key)}
                                                                >
                                                                    <i className="fe fe-trash"></i>
                                                                    Delete
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="container-fluid no-padding">
                                    <div className="row pull-right">
                                        {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
                                        {/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
                                        <div className="col-md-12 col-sm-12 col-xs-12 pagination ">
                                            &nbsp;
                                            {subscription_charge_list.length >
                                                0 ? (
                                                    <Pagination
                                                        pageSize={20}
                                                        items={
                                                            subscription_charge_list
                                                        }
                                                        onChangePage={
                                                            this.onChangePage
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SubscriptionChargeForm
                    openFormModal={openFormModal}
                    onFormModalClose={this.onFormModalClose}
                    subscription={subscription}
                    tab={tab}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { subscription_charge_list: state.admin.subscription_charge_list };
};

export default connect(mapStateToProps, { AdminSubscriptionChargeList, DeleteSubscriptionCharge })(
    AdminSubscriptionCharge
);
