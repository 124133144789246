import { apiBaseUrl} from './../helpers/common';
import * as actionTypes from './../types/types';
import {handleResponse} from './../helpers/userServices';
// import toastr from "reactjs-toastr";
// import "reactjs-toastr/lib/toast.css";

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

export function languages() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/language/list`, {
            method: 'POST',
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.LANGUAGES_LIST,payload: data.data})
            }
        });
    }
}

export function statesList() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/state/list`, {
            method: 'POST',
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.STATES_LIST,payload: data.data})
            }
        });
    }
}

export function citiesList() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/city/list`, {
            method: 'POST',
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.CITIES_LIST,payload: data.data})
            }
        });
    }
}

export function AutosuggestCities(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/city/Autosuggest`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.SUGGESTED_CITIES,payload: data.data})
            }
        });
    }
}

export function AutosuggestStates(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/state/Autosuggest`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.SUGGESTED_STATES,payload: data.data})
            }
        });
    }
}

export function AutosuggestLocation(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/auto-suggest-location`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.FETCH_AUTO_SUGGESTED_LOCATION,payload: data.data})
            }
        });
    }
}

export function AutosuggestCountries(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/country/Autosuggest`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.SUGGESTED_COUNTRIES,payload: data.data})
            }
        });
    }
}

export function AutosuggestClinicCities(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/clinic/cities`, {
            method: 'POST',
            body:JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.SUGGESTED_CLINIC_CITIES,payload: data.data})
            }
        });
    }
}

export function specializations() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/specialization/list`, {
            method: 'POST',
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.SPECIALIZATION_LIST,payload: data.data})
            }
        });
    }
}

export function ShowLoader(value){
    return dispatch => {
         dispatch({type: actionTypes.SHOW_LOADER,payload: value})
    }
}

export function ShowAlert(value){
    return dispatch => {
         dispatch({type: actionTypes.SHOW_ALERT,payload: value})
    }
}

export function Timezones() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/timezone-list`, {
            method: 'GET',
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.TIMEZONE_LIST,payload: data.data})
            }
        });
    }
}

export function CounsellorTypes() {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/councillor-Symptoms-list`, {
            method: 'POST',
            headers:new Headers({'Content-Type':'application/json'})
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.ResponseCode==="1"){
                dispatch({type: actionTypes.COUNSELLOR_TYPE_LIST,payload: data.data})
            }
        });
    }
}
