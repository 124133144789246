import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    CategoryListing, 
    SubCategoryListing,
    AddProduct,
    ProductListing,
    DeleteProduct,
    EditProduct,
    ProductImagesListing,
    DeleteProductImage
} from '../../../store/actions/adminActions';
import {ShowLoader} from "../../../store/actions/staticListingsActions";
import Pagination from '../../../store/helpers/Pagination'; 
import { Modal } from 'react-bootstrap';
import Modals from './../../General/Modals';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert'; 
import { apiBaseUrl,bucketPath } from "../../../store/helpers/common";
import toastr from "reactjs-toastr";

const axios = require("axios");

const initialState = { open : false,modalType:'productDetails'}

class Product extends Component {
    constructor(props) {
        super(props)
        this.formValidator = new SimpleReactValidator();
        this.state = initialState
    }

    componentDidMount(){
        this.props.dispatch(CategoryListing({type:'list'}))
        this.props.dispatch(SubCategoryListing())
        this.props.dispatch(ProductListing())
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => { 
        this.setState({
            open: false, isEdit: false
        });
    }

    onSubmitProduct = async (e) => { 
        const {name,category,subcategory,price, tax,quantity,status,description,product_highlight,id,isEdit} = this.state  
        if (this.formValidator.allValid()) {
            if(isEdit){
                const data = {id,name,categoryID:category,sub_categoryID:subcategory,price,quantity,status,description,product_highlight,tax}
                let response = await this.props.dispatch(EditProduct(data))
                if(response === 1){
                    this.props.dispatch(ProductImagesListing({id:id})); 
                    this.setState({ 
                        modalType:'productImages'
                    }) 
                }  
            }else{
                const data = {name,categoryID:category,sub_categoryID:subcategory,price,quantity,status,description,product_highlight,tax}
                let response = await this.props.dispatch(AddProduct(data))
                if(response === 1){
                    this.setState({ 
                        isEdit:false,
                        modalType:'productImages'
                    }) 
                }
            }
            
        } else { 
            this.formValidator.showMessages();
            this.forceUpdate();
          }
          e.preventDefault();
    } 

    EditProduct = (e, uniquekey) => {
        const request = new Request(`${apiBaseUrl}/api/view-products`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("admin-token")}`,
            },
            body: JSON.stringify({uniquekey}),
        });
        return fetch(request)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data; 
                this.setState({
                    id: data.uniquekey, 
                    name:data.name,
                    category:data.category,
                    subcategory:data.sub_category,
                    quantity:data.quantity,
                    price:data.price,
                    status:data.status,
                    description:data.description,
                    product_highlight:data.porduct_highlight,
                    isEdit: true, 
                    open: true,
                    tax: data.tax,
                    modalType:'productDetails'
                });
            });
    };


    DeleteProduct = (e, uniquekey) => { 
        swal({
            title: "Are you sure you want to delete this Product?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteProduct({uniquekey:uniquekey}));
            } else {
                console.log("you clicked on false");
            }
        });
    };


    uploadImage = (e) => {
        let localId =  localStorage.getItem('product');
        let ID = this.state.isEdit ? this.state.id : localId
        this.props.dispatch(ShowLoader(true));
        let request;
        let formData = new FormData(); 
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
        formData.append(`file[${i}]`, files[i]); 
        }  
        formData.append('id',ID);
        request = {
          method: "post", 
          url: `${apiBaseUrl}/api/upload-product-images`,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": `${localStorage.getItem("admin-token")}`,
          },
          data: formData,
        };
        axios(request)
          .then((res) => {
            if (res.data.ResponseCode === "1") { 
                this.props.dispatch(ProductImagesListing({id:ID})); 
                this.props.dispatch(ShowLoader(false));
                toastr.success(res.data.ResponseText, { displayDuration: 1500 }); 
            }
          })
          .catch((error) => {
            this.props.dispatch(ShowLoader(false)); 
            console.log(error);
          });
      }; 

      DeleteImageProduct = (e,value) => { 
        swal({
            title: "Are you sure you want to delete this Product Image?",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(DeleteProductImage({image:value}));
            } else {
                console.log("you clicked on false");
            }
        });
    };

    handleOpenModal = ()=>{
        this.setState({
            open:true,
            isEdit:false,
            modalType:'productDetails',
            name:'',
            category:'',
            subcategory:'',
            tax:'',
            quantity:'',
            price:'',
            product_highlight:'',
            description:'',
            status:''
        })
    }
   
    render() {
        const {open,modalType,pageOfItems,isEdit} = this.state
        const {category_listing,subcategory_listing,product_listing,product_image} = this.props 

        return (
            <div>
                <div className="text-right">
                    <Link to="#" className="add-new-btn" onClick={()=>this.handleOpenModal()}>Add Product</Link>
                </div>
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr> 
                                        <th>Product ID</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageOfItems && pageOfItems.map((value, index) => {
                                        return (
                                            <tr key={`product_${index}`}> 
                                                <td>{value.uniquekey}</td>
                                                <td>{value.name}</td>
                                                <td>{value.category_name}</td>
                                                <td>{value.sub_category_name}</td>
                                                <td>{`₹ ${value.price}`}</td>
                                                <td>{value.quantity}</td>
                                                <td className="text-right">
                                                    <div className="actions">
                                                        <Link
                                                            className="btn btn-sm bg-success-light"
                                                            to="#"
                                                            onClick={(e) =>this.EditProduct(e,value.uniquekey)}
                                                        >
                                                            <i className="fe fe-pencil"></i>&nbsp;
                                                            Edit
                                                        </Link>&nbsp;&nbsp;
                                                        <Link
                                                            data-toggle="modal"
                                                            to="#"
                                                            className="btn btn-sm bg-danger-light"
                                                            onClick={( e ) => this.DeleteProduct(e,value.uniquekey)}
                                                        >
                                                            <i className="fe fe-trash"></i>&nbsp;
                                                            Delete
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {product_listing.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={product_listing}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>


                {/* MODAL FOR SUB CATEGORY */}

                <Modals open={open} setOpen={this.setOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                           {isEdit  ? 'Edit' : 'Add'} Product
                        </Modal.Title> 
                    </Modal.Header>
                    <Modal.Body>
                        {modalType === 'productDetails' && <form>
                            <div className="row form-row label-star">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type='text' 
                                            className="form-control" 
                                            name="name" 
                                            value={this.state.name} 
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        /> 
                                        {this.formValidator.message( "Name", this.state.name,"required")} 
                                    </div> 
                                    <div className="form-group">
                                        <label>Category</label> 
                                        <select 
                                            type="text"
                                            className="form-control"
                                            name="category"
                                            value={this.state.category}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >
                                            <option value="">Select Category</option>
                                            {category_listing && category_listing.map((value,index)=>{
                                                return (
                                                    <option 
                                                        key={`category_${index}`} 
                                                        value={value.uniquekey}
                                                    >
                                                        {value.name}
                                                    </option>
                                                )
                                            })}
                                        </select> 
                                        {this.formValidator.message( "Category", this.state.category,"required")}
                                    </div> 
                                    <div className="form-group">
                                        <label>Sub Category</label> 
                                        <select 
                                            type="text"
                                            className="form-control"
                                            name="subcategory"
                                            value={this.state.subcategory}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >
                                            <option value="">Select Sub Category</option>
                                            {subcategory_listing && subcategory_listing.map((value,index)=>{
                                                return (
                                                    <option 
                                                        key={`subcategory_${index}`} 
                                                        value={value.uniquekey}
                                                    >
                                                        {value.name}
                                                    </option>
                                                )
                                            })}
                                        </select>  
                                        {this.formValidator.message( "Sub Category", this.state.subcategory,"required")}
                                    </div> 
                                    <div className="form-group">
                                        <label>Price</label>
                                        <div className="input-group mb-3 admin-price-input"> 
                                            <span className="price-input-text">₹</span>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                name="price" 
                                                value={this.state.price} 
                                                onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                            /> 
                                        </div> 
                                        {this.formValidator.message( "Price", this.state.price,"required")} 
                                    </div> 
                                    <div className="form-group">
                                        <label>Tax</label>
                                        <div className="input-group mb-3 admin-price-input"> 
                                            <span className="price-input-text">%</span>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                name="tax" 
                                                value={this.state.tax} 
                                                onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                            /> 
                                        </div> 
                                        {this.formValidator.message( "Tax", this.state.tax,"required")} 
                                    </div>
                                    <div className="form-group">
                                        <label> Quantity</label>
                                        <input 
                                            type="text"
                                            className="form-control" 
                                            name="quantity" 
                                            value={this.state.quantity} 
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        />  
                                        {this.formValidator.message( "Quantity", this.state.quantity,"required")}
                                    </div>  
                                    <div className="form-group">
                                        <label>Status</label> 
                                        <select 
                                            type="text"
                                            className="form-control"
                                            name="status"
                                            value={this.state.status}
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="A">Active</option>
                                            <option value="D">Inactive</option>
                                        </select> 
                                    </div>
                                    <div className="form-group">
                                        <label>Product Highlight</label>
                                        <textarea 
                                            className="form-control" 
                                            name="product_highlight" 
                                            value={this.state.product_highlight} 
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >   
                                        </textarea>
                                        {this.formValidator.message( "Product Highlight", this.state.product_highlight,"required")}
                                    </div> 
                                    <div className="form-group">
                                        <label>Product Description</label>
                                        <textarea 
                                            className="form-control" 
                                            name="description" 
                                            value={this.state.description} 
                                            onChange={e=>this.setState({[e.target.name]:e.target.value})}
                                        >   
                                        </textarea>
                                        {this.formValidator.message( "Description", this.state.description,"required")}
                                    </div> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="submit-section">
                                        <Link to="#" className="btn btn-light-black submit-btn" onClick={e => this.onSubmitProduct(e)}>Save</Link> 
                                    </div>
                                </div>
                            </div> 
                        </form> }

                        {
                            modalType === 'productImages' && 
                            <div className="row form-row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Image</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="product_image"
                                            name="product_image"
                                            onChange={(e) =>
                                                this.uploadImage(e)
                                            }
                                            multiple
                                            aria-describedby="inputGroupFileAddon01"
                                            accept="image/*" 
                                            /> 
                                    </div> 
                                    <div className="form-group product-image-thumb" id="Upload-documents">
                                    {product_image && product_image.map((value,index) => {
                                        return (
                                            <div className="change-avatar"  key={`avatar${index}`} >
                                                <div className="profile-img">
                                                <Link to="#"  onClick={( e ) => this.DeleteImageProduct(e,value.image)}>
                                                    <i className="fas fa-trash-alt del-profile-ico"></i>
                                                </Link>
                                                <img
                                                    alt="User"
                                                    src={`${bucketPath}${value.image}`}
                                                />
                                                </div>
                                            </div> 
                                        );
                                        }
                                    )} 
                                    </div>
                                    </div> 
                                </div> 
                        }
                    </Modal.Body>
                </Modals> 
                 

            </div>
        )
    }
}

const mapStateToProps = (state) => ({ 
    category_listing:state.admin.category_listing,
    subcategory_listing:state.admin.subcategory_listing,
    product_listing:state.admin.product_listing,
    product_image:state.admin.product_image
});

export default connect(mapStateToProps)(Product);