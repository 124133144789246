import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input';
import SimpleReactValidator from 'simple-react-validator'
import { UserProfilesEdit, profileInfo } from './../store/actions/userActions'
import { AutosuggestCities, AutosuggestStates, AutosuggestCountries,ShowAlert } from "./../store/actions/staticListingsActions";
import {apiBaseUrl,bucketPath} from './../store/helpers/common';
const axios = require('axios');

const initialState = { title: "", firstname: "", middlename: "", lastname: "", position_held: "", phone: "", landline_number: "", fax_number: "", email: "", website: "", address: "", city: "", state: "", country: "", remarks: "", test_done: 0,profile_pic:'' };

class ProfileSetting extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.typeStorage = localStorage.getItem('type');
		this.validator = new SimpleReactValidator();
	}

	componentDidMount() {
		this.props.dispatch(profileInfo());
		if (this.typeStorage && this.typeStorage !== "") {
			this.setState({
				type: this.typeStorage
			})
		}
	}

	onChangeHandler = e => {
		e.preventDefault();
		if(e.target.name==="firstname" || e.target.name==="lastname" || e.target.name==="middlename" || e.target.name==="position_held"|| e.target.name==="title"){
            let value = e.target.value
            this.setState({
               [e.target.name]:value.replace(/[^A-Za-z]/ig, '')
            })
        }else{
			this.setState({
				[e.target.name]: e.target.value
			})
		}
		
	}

	componentWillUpdate(nextProps) { 
		if (nextProps.profile_info !== this.props.profile_info && nextProps.profile_info.email) {
			let profile_info = nextProps.profile_info;
			this.setState({ title: profile_info.title, firstname: profile_info.firstname, middlename: profile_info.middlename, lastname: profile_info.lastname, position_held: profile_info.position_held, phone: profile_info.phone, landline_number: profile_info.landline_number, fax_number: profile_info.fax_number, email: profile_info.email, website: profile_info.website, address: profile_info.address, city: profile_info.city, state: profile_info.state, country: profile_info.country, remarks: profile_info.remarks, test_done: profile_info.test_done,profile_pic:profile_info.profile_pic });
		}
	}

	onSubmitHandler = e => {
		let validator = this.validator;
		if (validator.allValid()) {
			this.props.dispatch(UserProfilesEdit(this.state));
		} else {
			validator.showMessages();
			this.forceUpdate();
		}
	}

	SearchCities = e => {
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			this.props.dispatch(AutosuggestCities({ city: this.state.search_city }))
		})
	}

	SearchStates = e => {
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			this.props.dispatch(AutosuggestStates({ state: this.state.search_state }))
		})
	}

	SearchCountries = e => {
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			this.props.dispatch(AutosuggestCountries({ country: this.state.search_country }))
		})
	}

	AutoFillCity = (value) => {
		const city = value.name ? value.name : "",
			state = value.region_name ? value.region_name : "",
			country = value.country_name ? value.country_name : ""
		this.setState({ city: city, state: state, country: country, search_city: "" }, () => {
			this.props.dispatch(AutosuggestCities({ city: "" }))
		})
	}

	AutoFillState = (value) => {
		const state = value.region_name ? value.region_name : "",
			country = value.country_name ? value.country_name : ""
		this.setState({ state: state, country: country, search_state: "" }, () => {
			this.props.dispatch(AutosuggestStates({ state: "" }))
		})
	}

	AutoFillCountry = (value) => {
		const country = value.country_name ? value.country_name : ""
		this.setState({ country: country, search_country: "" }, () => {
			this.props.dispatch(AutosuggestCountries({ country: "" }))
		})
	}

	uploadImage = (e,field) => { 
		let request;
		let formData = new FormData();
		formData.append('file', e.target.files[0]);
		formData.append('name', field);
		request = {
					method: 'post',
					url: `${apiBaseUrl}/api/upload-image`,
					headers: { 'Content-Type': 'multipart/form-data', 'X-Auth-Token': `${localStorage.getItem('token')}` },
					data: formData
				};
		axios(request).then((res) => {
			if(res.data.ResponseCode==="1" && field==="profile_pic"){
				this.setState({
					profile_pic:res.data.imagename
				},()=>{
					this.props.dispatch(ShowAlert({Class:"alert-success",Message:res.data.ResponseText,Timeout:5000}));
				})
				this.props.dispatch(profileInfo());
			}
		}).catch((error) => {
			console.log(error)
			});
	}



	render() {
		const { title, firstname,lastname, position_held, phone, landline_number, fax_number, email, website, address, city, state, country, remarks, test_done, type } = this.state;
		const { suggested_cities, suggested_states, suggested_countries} = this.props
		return (
			<div className="card">
				<div className="card-body">
					<form>
						<div className="row form-row">
							<div className="col-12 col-md-12">
								<div className="form-group">
									<div className="change-avatar">
										<div className="profile-img">
											<img src={`${this.state.profile_pic ? bucketPath + this.state.profile_pic : "./../assets/doctor/img/doctors/doctor-thumb-02.jpg"}`} alt="User" />
										</div>
										<div className="upload-img">
											<div className="change-photo-btn">
												<span><i className="fa fa-upload"></i> Upload Photo</span>
												<input type="file" className="upload" onChange={e=>this.uploadImage(e,'profile_pic')}/>
											</div>
											<small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group">
									<label>Title</label>
									<select
										className="form-control"
										name="title"
										value={this.state.title}
										onChange={e => this.onChangeHandler(e)}
									>   
										<option>Mr</option>
										<option>Mrs</option>
										<option>Miss</option>
									</select> 
									{this.validator.message('Title', title, 'required|max:25,string')}
								</div>
							</div>
							<div className="col-12 col-md-5">
								<div className="form-group">
									<label>First Name</label>
									<input type="text" name="firstname" className="form-control" value={firstname} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('First Name', firstname, 'required|max:50,string')}
								</div>
							</div>
							<div className="col-12 col-md-5">
								<div className="form-group">
									<label>Last Name</label>
									<input type="text" name="lastname" className="form-control" value={lastname} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Last Name', lastname, 'required|max:50,string')}
								</div>
							</div>
							{/* <div className="col-12 col-md-6">
								<div className="form-group">
									<label className="not_required">Middle Name</label>
									<input type="text" className="form-control" name="middlename" value={middlename} onChange={e => this.onChangeHandler(e)} />
								</div>
							</div> */} 
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Position Held</label>
									<input type="text" className="form-control" name="position_held" value={position_held} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Position Held', position_held, 'required|max:50,string')}
								</div>
							</div>
							<div className="col-12 col-md-6 doctor-setp-mid">
								<div className="form-group">
									<label>Phone</label>
									<PhoneInput
										name="phone"
										placeholder="Enter phone number"
										value={phone}
										onChange={phone => this.setState({ phone: phone })}
										// onBlur={e=>this.ajaxValidation(e)}
										defaultCountry="IN" />
									{this.validator.message('Phone', phone, 'required|phone|min:7,string')}
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Landline Number</label>
									<input type="text" className="form-control" name="landline_number" value={landline_number} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Landline Number', landline_number, 'required|phone|min:7,string')}
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Fax Number</label>
									<input type="text" className="form-control" name="fax_number" value={fax_number} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Fax Number', fax_number, 'required|phone|min:7,string')}
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Email</label>
									<input type="text" className="form-control" name="email" value={email} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Email', email, 'required|email|max:50,string')}
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Website</label>
									<input type="text" className="form-control" name="website" value={website} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Website', website, 'required|url|max:100,string')}
								</div>
							</div>

							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Address</label>
									<input type="text" className="form-control" name="address" value={address} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Address', address, 'required|max:100,string')}
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>City</label>
									<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text" id="basic-addon1">
												<i className="fa fa-search"></i>
											</span>
										</div>
										<button type="button" className="form-control no-padding-left Search-control-outer dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
											aria-expanded="false">
											{`${city ? city : "Search Cities"}`}
										</button>
										<div className="dropdown-menu Search-control-drop dropdown-content dropdown-menu-right">
											<input className="form-control Search-control" id="Search_City" name="search_city" value={this.state.search_city} type="text"
												placeholder="Search.." onChange={e => this.SearchCities(e)} />
											{
												suggested_cities && suggested_cities.map((value, key) => {
													return (
														<Link to="#" key={`cities_${key}`} className="dropdown-item" onClick={e => this.AutoFillCity(value)}>{value.name}</Link>
													)
												})
											}
										</div>
									</div>
									{this.validator.message('City', this.state.city, 'required|max:50,string')}
								</div>
								{/* <div className="form-group">
									<label>City</label>
									<input type="text" className="form-control" name="city" value={city}  onChange={e => this.onChangeHandler(e)}/>
									{this.validator.message('City', city, 'required')}
								</div> */}
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>State</label>
									<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text" id="basic-addon2">
												<i className="fa fa-search"></i>
											</span>
										</div>
										<button type="button" className="form-control no-padding-left Search-control-outer dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
											aria-expanded="false">
											{`${state ? state : "Search States"}`}
										</button>
										<div className="dropdown-menu Search-control-drop dropdown-content dropdown-menu-right">
											<input className="form-control Search-control" id="Search_State" name="search_state" value={this.state.search_state} type="text"
												placeholder="Search.." onChange={e => this.SearchStates(e)} />
											{
												suggested_states && suggested_states.map((value, key) => {
													return (
														<Link to="#" key={`states_${key}`} className="dropdown-item" onClick={e => this.AutoFillState(value)}>{value.region_name}</Link>
													)
												})
											}
										</div>
									</div>
									{this.validator.message('State', this.state.state, 'required|max:50,string')}
								</div>
								{/* <div className="form-group">
									<label>State</label>
									<input type="text" className="form-control" name="state" value={state} onChange={e => this.onChangeHandler(e)}/>
									{this.validator.message('State', state, 'required')}
								</div> */}
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Country</label>
									<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text" id="basic-addon3">
												<i className="fa fa-search"></i>
											</span>
										</div>
										<button type="button" className="form-control no-padding-left Search-control-outer dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
											aria-expanded="false">
											{`${country ? country : "Search Country"}`}
										</button>
										<div className="dropdown-menu Search-control-drop dropdown-content dropdown-menu-right">
											<input className="form-control Search-control" id="Search_Country" name="search_country" value={this.state.search_country} type="text"
												placeholder="Search.." onChange={e => this.SearchCountries(e)} />
											{
												suggested_countries && suggested_countries.map((value, key) => {
													return (
														<Link to="#" key={`country_${key}`} className="dropdown-item" onClick={e => this.AutoFillCountry(value)}>{value.country_name}</Link>
													)
												})
											}
										</div>
									</div>
									{this.validator.message('country', this.state.country, 'required|max:100,string')}
								</div>
								{/* <div className="form-group">
									<label>Country</label>
									<input type="text" className="form-control" name="country" value={country} onChange={e => this.onChangeHandler(e)}/>
									{this.validator.message('Country', country, 'required')}
								</div> */}
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>Remarks</label>
									<input type="text" className="form-control" name="remarks" value={remarks} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Remarks', remarks, 'required')}
								</div>
							</div>
							{type === "lab" && <div className="col-12 col-md-6">
								<div className="form-group">
									<label>Test Done</label>
									<input type="text" className="form-control" name="test_done" value={test_done} onChange={e => this.onChangeHandler(e)} />
									{this.validator.message('Test Done', test_done, 'required')}
								</div>
							</div>}
						</div>
						<div className="submit-section">
							<button type="button" className="btn btn-light-black submit-btn" onClick={e => this.onSubmitHandler(e)}>Save Changes</button>
						</div>
					</form>

				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	profile_info: state.user.profile_info,
	suggested_cities: state.static.suggested_cities,
    suggested_states: state.static.suggested_states,
	suggested_countries: state.static.suggested_countries,
});

export default connect(mapStateToProps)(ProfileSetting); 