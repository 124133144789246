import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import adminReducer from './adminReducer';
import staticListingsReducer from './staticListingsReducer';
import userReducer from './userReducer';
import googleMapReducer from './googleMapReducer';
import registrationReducer from './registrationReducer';
import globalReducer from './globalReducer';
import doctorPanelReducer from './doctorPanelReducer';
import counselorPanelReducer from './counselorPanelReducer';
import labPanelReducer from './labPanelReducer';
import patientReducer from './patientReducer';
import filterReducer from './filterReducer';
import fitnessReducer from './fitnessReducer';
import productReducer from './productReducer';
import locationReducer from './locationReducer';
import storage from 'redux-persist/lib/storage';

const persistConfig = { key: 'root', storage, whitelist: ['filter','product'] };

const rootReducer = combineReducers({
  admin: adminReducer,
  static: staticListingsReducer,
  user: userReducer,
  maps: googleMapReducer,
  registration: registrationReducer,
  global: globalReducer,
  doctorPanel: doctorPanelReducer,
  counselorPanel: counselorPanelReducer,
  fitness: fitnessReducer,
  labPanel: labPanelReducer,
  patient: patientReducer,
  filter: filterReducer,
  product:productReducer,
  location:locationReducer,
});
export default persistReducer(persistConfig, rootReducer);
