import React from 'react'
import { connect } from 'react-redux'
import Pagination from './../../store/helpers/Pagination';
import {getQueryListing} from './../../store/actions/adminActions';

class AdminQuery extends React.Component{
    constructor(props){
        super(props)
        this.state = {
			currentPage:1,
			totalItems:[],
			pageOfItems:[]
        }
	}

	componentDidMount(){
        this.props.dispatch(getQueryListing());
	}

	componentDidUpdate = (prevProps, prevState) => {
		let nextProps = this.props;

		let {
			filter: { admin_global_search },
		  } = nextProps;

        if(nextProps.queryListing !== prevProps.queryListing || admin_global_search !== prevProps.filter.admin_global_search){
          this.setState({
            totalItems:this.filteredDataFxn()
          },()=>{
			this.onChangePage(this.state.totalItems,1)
		  })

        }
	}

	filteredDataFxn = () => {
		const {
		  queryListing,
		  filter: { admin_global_search },
		} = this.props;

		if (admin_global_search) {
		  return (
			queryListing &&
			queryListing.filter((val) => {
			  return (
				val.firstname
				  .toString()
				  .toLowerCase()
				  .search(admin_global_search.toString().toLowerCase()) !== -1 ||
				val.lastname
				  .toString()
				  .toLowerCase()
				  .search(admin_global_search.toString().toLowerCase()) !== -1
			  );
			})
		  );
		} else {
		  return queryListing;
		}
	  };

	onChangePage = (pageOfItems,page) => {
        this.setState({ pageOfItems: pageOfItems,currentPage:page });
    };

    render () {
        return (
            <div>
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="datatable table table-hover table-center mb-0">
											<thead>
												<tr>
													<th style={{width:'15%'}}>Name</th>
													<th style={{width:'20%'}}>Email</th>
													<th style={{width:'15%'}}>Phone</th>
													<th style={{width:'50%'}}>Message</th>
												</tr>
											</thead>
											<tbody>
											{this.state.pageOfItems.length > 0 ? (this.state.pageOfItems.map((val, i) => (
												<tr key={i}>
												<td>{val.firstname} {val.lastname}</td>
												<td>{val.email}</td>
												<td>{val.phone}</td>
												<td>{val.message}</td>
											</tr>
											 ))
											) : (
											<tr><td>No records available</td></tr>
											)}
											</tbody>
										</table>
									</div>
									<div className="container-fluid no-padding">
										<div className="row pull-right">
											{/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
											{/* <div className="col-md-4 col-sm-4 col-xs-12 pagination "> */}
										<div className="col-md-12 col-sm-12 col-xs-12 pagination ">
												&nbsp;
												{this.state.pageOfItems.length > 0 ? (
													<Pagination
													pageSize={20}
													items={this.state.totalItems}
													onChangePage={this.onChangePage}
													/>
												) : (
														""
												)}
											</div>
										</div>
                  					</div>
								</div>
							</div>
						</div>
					</div>

				</div>
                )
            }
}
const mapStateToProps = state => ({
	 queryListing:state.admin.query.data,
	 filter: state.filter,
});

export default connect(mapStateToProps)(AdminQuery);