import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; 
import ToolTips from './../../components/General/ToolTips';
import { AdminGlobalSearch } from './../../store/actions/filterActions';

const initialState = { globalSearch: '' };
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const {
      filter: { admin_global_search },
    } = this.props;
    if (admin_global_search) {
      this.setState({ globalSearch: admin_global_search });
    }
  }

  logout = (e) => {
    localStorage.removeItem('admin-token');
    localStorage.removeItem('admin-identity');
    window.location.replace('/admin-login');
    e.preventDefault();
  };

  onClickHandler = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('admin-token');
    if (token && token !== '') {
      this.props.history.push('/admin/dashboard');
    } else {
      localStorage.removeItem('admin-token');
      localStorage.removeItem('admin-identity');
      this.props.history.push('/admin-login');
    }
  };

  GlobalSearch = (e) => {
    this.props.dispatch(AdminGlobalSearch(this.state.globalSearch));
  };

  keyDownhandler = (e) => {
    if (e.key === 'Enter') {
      this.buttonRef.click();
    }
  };

  render() {
    const { globalSearch } = this.state;
    return (
      <div>
        <div className='header'>
          <div className='header-left'> 
            <Link
              className='navbar-brand'
              to='#'
              onClick={(e) => this.onClickHandler(e)}
            >
              <img src='/assets/images/logo-text.png' alt='...' />
            </Link> 
          </div> 
          <Link to="#" id="toggle_btn">
            <i className="fe fe-text-align-left"></i>
          </Link>

          <div className='top-nav-search top-nav-search-admin'>
            {/* <form> */}
            <input
              type='text' 
              name='globalSearch'
              value={globalSearch}
              onChange={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
              onKeyDown={(e) => this.keyDownhandler(e)}
              className='form-control'
              placeholder='Search here'
            />
            <Link
              className='btn'
              to='#'
              onClick={this.GlobalSearch.bind(this)}
              ref={(input) => {
                this.buttonRef = input;
              }}
            >
              <i className='fa fa-search'></i>
            </Link>
            {/* </form> */}
          </div>
          <Link to="#" className="mobile_btn" id="mobile_btn">
              <i className="fa fa-bars"></i>
          </Link>
          <ul className='nav user-menu'>
            <li className='nav-item logout-ico'>
              <ToolTips Placement='left' Text='Logout'>
                <Link to='#' onClick={(e) => this.logout(e)}>
                  <i className='fas fa-sign-out-alt'></i>
                </Link>
              </ToolTips>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter,
  };
}
export default connect(mapStateToProps)(Header);
