import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PatientPrescriptionsList } from '../../store/actions/registerationActions';
import Pagination from '../../store/helpers/Pagination';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Modals from './../General/Modals'  
import { apiBaseUrl,bucketPath } from '../../store/helpers/common';

let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0');
const yyyy = today.getFullYear();

today = dd + '-' + mm + '-' + yyyy; 
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const slots = ['Morning', 'Noon', 'Evening', 'Night'];
// const InterVals = ['Day', 'Week', 'Month'];
// const Dose = ['Before', 'After'];
// const Type = ['Tablet', 'Syrup'];
let prescriptionName = {};
let prescriptionDose = {};
let prescriptionType = {};
let prescriptionQuantity = {};
let prescriptionDays = {};
let prescriptionTime = {};
let prescriptionInterval = {};
let prescriptionIntCount = {};
let prescriptionDescription = {};

[...Array(10).keys()].map(values => {
    prescriptionName[`name_${values}`] = '';
    prescriptionQuantity[`quantity_${values}`] = '';
    prescriptionDays[`day_slots_${values}`] = [];
    prescriptionTime[`time_slots_${values}`] = [];
    prescriptionInterval[`interval_${values}`] = '';
    prescriptionIntCount[`count_${values}`] = '';
    prescriptionDescription[`description_${values}`] = '';
    prescriptionDose[`dose_${values}`] = '';
    prescriptionType[`type_${values}`] = '';
    return null
})
class PatientPrescription extends React.Component {
    constructor(props) {
        super(props)
        this.state = { currentPage: 1, pageOfItems: [], open: false, totalItems: [0] }
        Object.assign(
            this.state,
            prescriptionName,
            prescriptionQuantity,
            prescriptionDays,
            prescriptionTime,
            prescriptionInterval,
            prescriptionIntCount,
            prescriptionDescription,
            prescriptionDose,
            prescriptionType
        );
    }

    componentDidMount() {
        const postData = { uniquekey: JSON.parse(localStorage.data).uniquekey };
        this.props.dispatch(PatientPrescriptionsList(postData));
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    EditPrescription = value => {
        if (value.prescription) {
            let prescriptions = JSON.parse(value.prescription);
            if (prescriptions.name && (prescriptions.name instanceof Array)) {
                let array = [];
                let prescriptionName = {};
                let prescriptionQuantity = {};
                let prescriptionDays = {};
                let prescriptionTime = {};
                let prescriptionInterval = {};
                let prescriptionIntCount = {};
                (prescriptions.name).map((mapValue, mapIndex) => {
                    array = [...array, mapIndex];
                    prescriptionName[`name_${mapIndex}`] = mapValue;
                    prescriptionQuantity[`quantity_${mapIndex}`] = prescriptions.quantity[mapIndex];
                    prescriptionDays[`day_slots_${mapIndex}`] = prescriptions.day_slots[mapIndex];
                    prescriptionTime[`time_slots_${mapIndex}`] = prescriptions.time_slots[mapIndex];
                    prescriptionInterval[`interval_${mapIndex}`] = prescriptions.interval[mapIndex];
                    prescriptionIntCount[`count_${mapIndex}`] = prescriptions.count[mapIndex];
                    prescriptionDose[`dose_${mapIndex}`] = prescriptions.dose[mapIndex];
                    prescriptionType[`type_${mapIndex}`] = prescriptions.type[mapIndex];
                    return true
                })
                Object.assign(
                    this.state,
                    prescriptionName,
                    prescriptionQuantity,
                    prescriptionDays,
                    prescriptionTime,
                    prescriptionInterval,
                    prescriptionIntCount,
                    prescriptionDose,
                    prescriptionType
                )
                this.setState({ open: true, totalItems: array, updateID: value.id });
            }
        }
    }

    setOpen = (value) => {
        Object.assign(
            this.state,
            prescriptionName,
            prescriptionQuantity,
            prescriptionDays,
            prescriptionTime,
            prescriptionInterval,
            prescriptionIntCount,
            prescriptionDescription,
            prescriptionDose,
            prescriptionType
        );
        this.setState({
            open: false, isEdit: false, totalItems: [0]
        });
    };

    render() {
        const { prescriptions_list } = this.props
        const { pageOfItems, totalItems, open } = this.state
        return (
            <div>
                <div className="card card-table mb-0">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th>Date </th>
                                        <th>Name</th>
                                        <th>Created by </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td>14 Nov 2019</td>
                                        <td>Prescription 1</td>
                                        <td>
                                            <h2 className="table-avatar">
                                                <Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
                                                    <img className="avatar-img rounded-circle" src="./../assets/doctor/img/doctors/doctor-thumb-01.jpg" alt="User" />
                                                </Link>
                                                <Link to="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></Link>
                                            </h2>
                                        </td>
                                        <td className="text-right">
                                            <div className="table-action">
                                                <Link to="#" className="btn btn-sm bg-info-light">
                                                    <i className="far fa-eye"></i> View
                                                </Link>
                                            </div>
                                        </td>
                                    </tr> */}
                                    {pageOfItems.length < 1
                                    && <tr>
                                        <td colSpan="3">
                                            No data found
                                        </td>
                                    </tr>}
                                    {pageOfItems && pageOfItems.map((value, index) => {
                                        return (
                                            <tr key={`prescription_${index}`}>
                                                <td>{value.creationdate ? moment(value.creationdate).format('LL') : 'NA'}</td>
                                                <td>{value.uniquekey}</td>
                                                <td>
                                                    <h2 className="table-avatar">
                                                        <Link to="doctor-profile.html" className="avatar avatar-sm mr-2">
                                                            <img
                                                                className="avatar-img rounded-circle"
                                                                src={`${value.doctor_profile_pic ? bucketPath + value.doctor_profile_pic : "./../assets/doctor/img/doctors/doctor-thumb-01.jpg"}`}
                                                                alt="User"
                                                            />
                                                        </Link>
                                                        <Link to="doctor-profile.html">Dr. {value.doctor_name} <span>{value.specialization_name}</span></Link>
                                                    </h2></td>
                                                <td className="text-right">
                                                    <div className="table-action">
                                                        <Link to="#" className="btn btn-sm bg-info-light" onClick={e => this.EditPrescription(value)}>
                                                            <i className="far fa-eye"></i> View
                                                        </Link>
                                                        <a href={apiBaseUrl + "/api/download-prescription_pdf?prescription_number=" + value.uniquekey} target="_blank" rel="noopener noreferrer" className="btn btn-sm bg-primary-light">
															<i className="fa fa-download"></i> Download
														</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {prescriptions_list.length > 0 && (
                                <Pagination
                                    pageSize={20}
                                    items={prescriptions_list}
                                    onChangePage={this.onChangePage}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <Modals open={open} setOpen={this.setOpen} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.updateID > 0 ? "View" : "Add"} Prescription
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    {/* <div className="biller-info">
                                        <h4 className="d-block">{`Dr ${this.doctorDetails.firstname} ${this.doctorDetails.lastname}`}</h4>
                                        <span className="d-block text-sm text-muted">{this.doctorDetails.specialization_name}</span>
                                        <span className="d-block text-sm text-muted">{this.doctorDetails.city}, {this.doctorDetails.country}</span>
                                    </div> */}
                                </div>
                                <div className="col-sm-6 text-sm-right">
                                    <div className="billing-info">
                                        <h4 className="d-block">{today}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card card-table">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover add-prescription-table table-center">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: "20%" }}>Name</th>
                                                    <th style={{ minWidth: "5%" }}>Dose</th>
                                                    <th style={{ minWidth: "5%" }}>Type</th>
                                                    <th style={{ minWidth: "5%" }}>Quantity</th>
                                                    <th style={{ minWidth: "10%" }}>Interval</th>
                                                    <th style={{ minWidth: "5%" }}>Interval Value</th>
                                                    <th style={{ minWidth: "25%" }}>Days</th>
                                                    <th style={{ minWidth: "15%" }}>Time</th>
                                                    <th style={{ minWidth: "20%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    totalItems.map((v, indexx) => {
                                                        return (<tr key={`prescribe_${indexx}`}>
                                                            <td>
                                                                <input style={{ minWidth: "150px" }} readOnly className="form-control" type="text" name={`name_${indexx}`} value={this.state[`name_${indexx}`]} />
                                                            </td>
                                                            <td>
                                                                <input style={{ minWidth: "90px" }} readOnly className="form-control" type="text" name={`dose_${indexx}`} value={this.state[`dose_${indexx}`]} />
                                                            </td>
                                                            <td>
                                                                <input style={{ minWidth: "50px" }} readOnly className="form-control" type="text" name={`type_${indexx}`} value={this.state[`type_${indexx}`]} />
                                                            </td>
                                                            <td>
                                                                <input style={{ minWidth: "80px" }} readOnly className="form-control" type="number" step="0.1" name={`quantity_${indexx}`} value={this.state[`quantity_${indexx}`]} />
                                                            </td>
                                                            <td>
                                                                <input style={{ minWidth: "90px" }} readOnly className="form-control" type="text" step="0.1" name={`interval_${indexx}`} value={this.state[`interval_${indexx}`]} />
                                                            </td>
                                                            <td>
                                                                <input className="form-control" readOnly type="text" name={`count_${indexx}`} value={this.state[`count_${indexx}`]} />
                                                            </td>
                                                            <td className="check-box-des">
                                                                {days.map((daysVal, daysKey) => {
                                                                    return (
                                                                        <div className="form-check form-check-inline" key={`day_slots_${daysKey}`}>
                                                                            <label className="form-check-label">
                                                                                {daysVal.substr(0, 2)}<input className="form-check-input" readOnly name={`day_slots_${indexx}`} type="checkbox" checked={this.state[`day_slots_${indexx}`].includes(daysVal) ? true : false} />
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </td>
                                                            <td className="check-box-des">
                                                                {slots.map((slotsVal, slotKey) => {
                                                                    return (
                                                                        <div className="form-check form-check-inline" key={`slotVal_${slotKey}`}>
                                                                            <label className="form-check-label">
                                                                                {slotsVal === 'Morning' ? <i className="slot-icos"><img src="/assets/images/slot-ico-1.png" alt="..." /></i> : slotsVal === 'Noon' ? <i className="slot-icos"><img src="/assets/images/slot-ico-2.png" alt="..." /></i> : slotsVal === 'Evening' ? <i className="slot-icos"><img src="/assets/images/slot-ico-3.png" alt="..." /></i> : <i className="slot-icos"><img src="/assets/images/slot-icon-4.png" alt="..." /></i>}
                                                                                <input className="form-check-input" readOnly name={`time_slots_${indexx}`} type="checkbox" checked={this.state[`time_slots_${indexx}`].includes(slotsVal) ? true : false} />
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </td>
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 text-right">
                                    <div className="signature-wrap">
                                        <div className="signature">
                                        <SignatureCanvas penColor='green' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}  ref={(ref) => { this.sigPad = ref }} onEnd={e=>this.trim()} />/>
                                        </div>
                                        <div className="sign-name">
                                            <p className="mb-0">( Dr. Darren Elder )</p>
                                            <span className="text-muted">Signature</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="submit-section">
                                        <Link to="#" className="btn btn-light-black submit-btn" onClick={e=>this.onSubmitPrescription()}>Save</Link>
                                        <button type="reset" className="btn btn-grey-border submit-btn">Clear</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </Modal.Body>
                </Modals>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    prescriptions_list: state.registration.prescriptions_list
});

export default connect(mapStateToProps)(PatientPrescription);
