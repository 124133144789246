import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { initOptions } from "../conferencePanel/Options";
import { jitsiConnection } from "../conferencePanel/Connection";
import {
    createLocalTrack,
    jitsiRoom,
    manageRoomEvents,
} from "../conferencePanel/Recording";
import swal from "sweetalert";
import { UpdateStreamingStatus } from "../../store/actions/counselorPanelActions";

const videoStyle = {
    width: "100%",
    height: "100vh",
};

const streamingBtnStyle = {
    background: "#fff",
    padding: "16px",
    borderRadius: "50px",
};

class FitnessLiveStream extends Component {
    constructor(props) {
        super(props);

        this.state = {
            connection: null,
            isJoined: false,
            room: null,
            localTracks: [],
            remoteTracks: {},
            remoteUsersDetails: {},
            isAudioMuted: false,
            isVideoMuted: false,
            cameraDeviceId: "",
            micDeviceId: "",
            roomName: "testing",
            displayName: "Guest",
            userDisplayName: "Guest",
            JitsiMeetJS: null,
            openModal: false,
            message: "",
            isAudioOnly: false,
            duration: "00:00:00",
            showSupportButtons: false,
            recordingSessionId: null,
            isConferenceJoined: false,
            jibriUser: false,
            video: null,
            videoTitle: "",
            recordingType: "stream",
        };
        this.isRecordingStarted = false;
        this.durationInterval = null;
    }

    UNSAFE_componentWillMount() {
        const uniqueKey = JSON.parse(localStorage.data).uniquekey;
        uniqueKey
            ? this.setState({
                  roomName: `tll-${uniqueKey.toLowerCase()}`,
              })
            : this.props.history.push("/");
    }

    componentDidMount() {
        window.onpopstate = function () {
            if (this.state.isJoined && this.state.connection) {
                this.leaveRoom();
            }
        }.bind(this);

        window.addEventListener("beforeunload", this.onUnload);

        const checkJitsiLoadInterval = setInterval(() => {
            if (window.JitsiMeetJS) {
                window.JitsiMeetJS.setLogLevel(
                    window.JitsiMeetJS.logLevels.ERROR
                );
                window.JitsiMeetJS.init(initOptions);
                this.setState(
                    {
                        connection: jitsiConnection(window.JitsiMeetJS),
                        JitsiMeetJS: window.JitsiMeetJS,
                    },
                    () => {
                        createLocalTrack(this);
                        this.state.connection.addEventListener(
                            this.state.JitsiMeetJS.events.connection
                                .CONNECTION_ESTABLISHED,
                            () => {
                                this.handleClick();
                            }
                        );
                    }
                );
                clearInterval(checkJitsiLoadInterval);
            }
        }, 100);
    }

    onUnload = () => {
        if (this.state.recordingSessionId) {
            this.toggleStreaming();
        }
    };

    handleClick = () => {
        const { connection, roomName } = this.state;
        let room = jitsiRoom(connection, roomName);
        this.setState({ room }, () => {
            manageRoomEvents(this);
        });
    };

    handleBackButton = () => {
        this.leaveRoom();
    };

    displayPermissionError = () => {
        swal(
            "Permission error!",
            "Video needs to use your microphone and camera. Select Allow when your browser asks for permissions.",
            "error"
        ).then(() => {
            this.props.history.push("/fitness/article-videos");
        });
    };

    msToTimeString(ms) {
        let seconds = (ms / 1000) % 60;
        let minutes = Math.floor(ms / 1000 / 60) % 60;
        let hours = Math.floor(ms / 1000 / 60 / 60);

        seconds = ("0" + seconds).slice(-2);
        minutes = ("0" + minutes).slice(-2);
        hours = ("0" + hours).slice(-2);

        return `${hours}:${minutes}:${seconds}`;
    }

    leaveRoom = () => {
        try {
            this.state.localTracks.forEach((track, index) => {
                track.dispose();
            });
            this.state.room.leave().then(() => {
                this.state.connection.disconnect();
                this.props.history.push("/fitness/article-videos");
            });
        } catch (error) {
            this.props.history.push("/fitness/article-videos");
        }
    };

    toggleStreaming = () => {
        const { room, recordingSessionId } = this.state;
        swal({
            title: `Do you want to ${
                recordingSessionId ? "Stop" : "start"
            } streaming?`,
            // icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const appData = JSON.stringify({
                    file_recording_metadata: {
                        share: true,
                    },
                });
                const recordingOption = {
                    appData,
                    mode: "stream",
                    streamId: room.options.name,
                };

                if (!recordingSessionId) {
                    room.startRecording(recordingOption)
                        .then((response) => {
                            Object.keys(response).forEach((key) => {
                                if (key === "_sessionID") {
                                    this.setState(
                                        {
                                            recordingSessionId:
                                                response._sessionID,
                                        },
                                        () => {
                                            this.updateStreamingStatus(true);
                                            console.log(
                                                "TOGGLE RECORDING STATE ============ ",
                                                response
                                            );
                                        }
                                    );
                                }
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.updateStreamingStatus(false);
                            swal(
                                "Streaming error!",
                                "Please try again later!",
                                "error"
                            );
                        });
                } else {
                    const stoptRecording = room.stopRecording(
                        recordingSessionId
                    );
                    stoptRecording
                        .then((response) => {
                            console.log(
                                "RECORDING Response =========> ",
                                response
                            );
                            this.setState({ recordingSessionId: null }, () => {
                                this.updateStreamingStatus(false);
                            });
                        })
                        .catch((error) => {
                            console.log("RECORDING ERROR =========> ", error);
                        });
                }
            }
        });
    };

    updateStreamingStatus = (streamStatus) => {
        const streaming = streamStatus ? "YES" : "NO";
        this.props.UpdateStreamingStatus({ streaming });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.recordingSessionId && !this.state.jibriUser && (
                    <div
                        style={{
                            position: "absolute",
                            color: "White",
                            top: "50%",
                            left: "42%",
                        }}
                    >
                        Please wait. Streaming starts within few seconds...
                    </div>
                )}
                {this.state.recordingSessionId && this.state.jibriUser && (
                    <div
                        style={{
                            position: "absolute",
                            color: "White",
                            top: "50%",
                            left: "48%",
                        }}
                    >
                        {this.state.duration}
                    </div>
                )}
                <div className="back-button-container">
                    {!this.state.recordingSessionId && (
                        <Link to="#" onMouseUp={this.handleBackButton}>
                            <i className="fas fa-arrow-circle-left"></i> Back{" "}
                        </Link>
                    )}
                </div>
                <div className="video-container">
                    <audio id="on-streaming">
                        <source
                            src="/assets/audio/liveStreamingOn.mp3"
                            type="audio/mp3"
                        />
                    </audio>
                    <audio id="off-streaming">
                        <source
                            src="/assets/audio/liveStreamingOff.mp3"
                            type="audio/mp3"
                        />
                    </audio>
                    <video
                        style={videoStyle}
                        id="localVideo"
                        autoPlay={true}
                    ></video>
                    <audio id="localAudio" autoPlay={true} muted={true}></audio>
                </div>
                <div className="calling-ico button-container">
                    {this.state.isConferenceJoined && (
                        <Link
                            to="#"
                            style={streamingBtnStyle}
                            onClick={this.toggleStreaming}
                        >
                            <i
                                className={`far fa-dot-circle bottom-call-icon ${
                                    this.state.recordingSessionId
                                        ? "text-red"
                                        : ""
                                }`}
                            ></i>
                            {this.state.recordingSessionId ? `Stop` : `Start`}{" "}
                            Streaming
                        </Link>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(null, { UpdateStreamingStatus })(FitnessLiveStream);
