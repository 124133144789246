import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Pagination from './../../store/helpers/Pagination';
import { Link } from 'react-router-dom';
import {
  getAdminFitnessExpertListing,
  VerifyFitnessExpertApproval,
  AdminPayments,
  GetDoctorRegistrationPrice,
} from './../../store/actions/adminActions';
import { Modal, Button } from 'react-bootstrap';
import Modals from './../General/Modals';
import ToolTips from './../General/ToolTips';
import SimpleReactValidator from 'simple-react-validator';
const Statuses = {
  A: 'success',
  R: 'danger',
  PA: 'warning',
  D: 'danger',
  P: 'warning',
};
const Labels = {
  A: 'Active',
  R: 'Rejected',
  PA: 'Pending for Approval',
  D: 'Deactivated',
  P: 'New User',
};
//const Currencies = ['USD', 'INR', 'GBP'];
class AdminDoctorList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalItems: [],
      pageOfItems: [],
      open: false,
      description: '',
      status: '',
      fitnessID: '',
      modal_type: '',
      amount: '',
      currency: '',
      comment: '',
      userID: '',
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.props.dispatch(getAdminFitnessExpertListing());
    this.setState({ currency: 'INR' });
  }

  componentDidUpdate = (prevProps, prevState) => {
    let nextProps = this.props;
    let {
      filter: { admin_global_search },
    } = nextProps;
    if (nextProps.DoctorListing !== prevProps.DoctorListing ||
      admin_global_search !== prevProps.filter.admin_global_search) {
      this.setState({
        totalItems: this.filteredDataFxn()
      },()=>{
        this.onChangePage(this.state.totalItems,1)
      });
    }

    if (nextProps.doctor_charge !== prevProps.doctor_charge) {
      this.setState({ amount: nextProps.doctor_charge });
    }
  };

  filteredDataFxn = () => {
    const {
      DoctorListing,
      filter: { admin_global_search },
    } = this.props;

    if (admin_global_search) {
      return (
        DoctorListing &&
        DoctorListing.filter((val) => {
          return (
            val.firstname
              .toString()
              .toLowerCase()
              .search(admin_global_search.toString().toLowerCase()) !== -1 ||
            val.lastname
              .toString()
              .toLowerCase()
              .search(admin_global_search.toString().toLowerCase()) !== -1
          );
        })
      );
    } else {
      return DoctorListing;
    }
  };

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  handleClick = (e, id) => {
    this.props.history.push(`/admin/fitnessExpertprofile/${id}`);
  };

  setOpen = (value) => {
    this.setState({ status: '', fitnessID: '', open: false, modal_type: '' });
  };

  statusChangeHandler = (status, id) => {
    if (status === 'R') {
      this.setState({
        status: status,
        fitnessID: id,
        open: true,
        modal_type: 'status_change',
      });
    } else {
      this.props.dispatch(VerifyFitnessExpertApproval({ fitnessID: id, status: status }));
      this.setState({ status: '', fitnessID: '', open: false });
    }
  };

  submitHandler = async (e) => {
    const { fitnessID, status, description } = this.state;
    if (this.validator.allValid()) {
      const response = await this.props.dispatch(
        VerifyFitnessExpertApproval({
          fitnessID: fitnessID,
          status: status,
          reason: description,
        })
      );
      if (response === 1) {
        this.setState({ status: '', fitnessID: '', open: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  PaymentHandler = (uniqueID) => {
    this.props.dispatch(GetDoctorRegistrationPrice({ uniquekey: uniqueID }));
    this.setState({ userID: uniqueID, open: true, modal_type: 'payments' });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  PaymentSubmitHandler = async (e) => {
    const { userID, amount, currency, comment } = this.state;
    if (this.validator.allValid()) {
      let response = await this.props.dispatch(
        AdminPayments({ userID, amount, currency, comment })
      );
      if (response === 1) {
        this.setState({ open: false, comment: '', userID: '', modal_type: '' });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // filterData = () => {
  //   let queryString = this.props.queryString;
  //   let filteredList = this.state.totalItems.filter(data => data.firstname === queryString);

  //   return filteredList
  // }

  render() {
    const {
      open,
      description,
      modal_type,
      amount,
      currency,
      comment,
      totalItems,
    } = this.state;
    //let { queryString, DoctorListing } = this.props;

    // let filteredList = this.state.totalItems;
    // if(queryString) {
    //   DoctorListing = DoctorListing.filter(data => data.firstname === queryString);
    // }
    return (
      <>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='datatable table table-hover table-center mb-0'>
                    <thead>
                      <tr>
                        <th>Fitness ID</th>
                        <th>Expert's Name</th> 
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pageOfItems.length > 0 ? (
                        this.state.pageOfItems.map((val, i) => (
                          <tr key={i}>
                            <td>#{val.uniquekey}</td>
                            <td>
                              <Link
                                to='#'
                                onClick={(e) =>
                                  this.handleClick(e, val.uniquekey)
                                }
                              >
                                {val.firstname} {val.lastname}
                              </Link>
                            </td> 
                            <td>{val.email}</td>
                            <td>{val.phone}</td>
                            <td>
                              {val.gender === 'M'
                                ? 'Male'
                                : val.gender === 'F'
                                ? 'Female'
                                : val.gender === 'T'
                                ? 'Transgender'
                                : ''}
                            </td>
                            <td>
                              {val.status === 'P' &&
                                val.payment_status === 'N' && (
                                  <Button
                                    variant={Statuses[val.status]}
                                    onClick={(e) =>
                                      this.PaymentHandler(val.uniquekey)
                                    }
                                  >
                                    {'Add Payment'}
                                  </Button>
                                )}
                              {val.status === 'P' &&
                                val.payment_status === 'Y' && (
                                  <Button
                                    variant={Statuses[val.status]}
                                    onClick={(e) =>
                                      this.handleClick(e, val.uniquekey)
                                    }
                                  >
                                    {Labels[val.status]}
                                  </Button>
                                )}
                              {val.status !== 'A' &&
                                val.status !== 'D' &&
                                val.status !== 'P' && (
                                  <Button
                                    variant={Statuses[val.status]}
                                    onClick={(e) =>
                                      this.handleClick(e, val.uniquekey)
                                    }
                                  >
                                    {Labels[val.status]}
                                  </Button>
                                )}
                              {['A', 'D'].includes(val.status) && (
                                <div className='status-toggle'>
                                  <input
                                    type='checkbox'
                                    id='status_1'
                                    checked={val.status === 'A' ? true : false}
                                    className='check'
                                    readOnly
                                  />
                                  <ToolTips
                                    Placement='top'
                                    Text={
                                      val.status === 'A' ? 'Active' : 'Deactive'
                                    }
                                  >
                                    <label
                                      htmlFor='status_1'
                                      className='checktoggle'
                                      onClick={(e) =>
                                        this.statusChangeHandler(
                                          val.status === 'A' ? 'D' : 'A',
                                          val.uniquekey
                                        )
                                      }
                                    >
                                      checkbox
                                    </label>
                                  </ToolTips>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No records available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='container-fluid no-padding'>
                  <div className='row pull-right'>
                    <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                      &nbsp;
                      {totalItems instanceof Array && (
                        <Pagination
                          pageSize={20}
                          items={totalItems}
                          onChangePage={this.onChangePage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modals open={open} setOpen={this.setOpen}>
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>
              {modal_type === 'status_change'
                ? 'Add description'
                : 'Add Payment'}
            </Modal.Title>
          </Modal.Header>
          {modal_type === 'status_change' && (
            <Modal.Body>
              <form>
                <div className='row form-row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <label>Description</label>
                      <textarea
                        className='form-control'
                        name='symptom'
                        value={description}
                        onChange={(e) => this.handleChange(e)}
                        rows={3}
                      />
                      {this.validator.message(
                        'Description',
                        description,
                        'required'
                      )}
                    </div>
                  </div>
                </div>
                <Link
                  to='#'
                  className='btn btn-light-black btn-block'
                  onClick={(e) => this.submitHandler(e)}
                >
                  Save
                </Link>
              </form>
            </Modal.Body>
          )}
          {modal_type === 'payments' && (
            <Modal.Body>
              <form>
                <div className='row form-row'>
                  <div className='col-md-12'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label>Currency</label>: {currency}
                        {/* <select name="currency" value={currency} className="form-control" onChange={e=>this.onChangeHandler(e)}>
                        <option>Select Currency</option>
                        {Currencies.map(cvalue=>{
                          return (
                            <option value={cvalue} key={cvalue}>{cvalue}</option>
                          )
                        })}
                      </select> */}
                        {/* {this.validator.message(
                        "Currency",
                        currency,
                        "required"
                      )} */}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label>Amount</label>: {amount}
                        {/* <input type="number" name="amount" value={amount} className="form-control" onChange={e=>this.onChangeHandler(e)}/>
                      {this.validator.message(
                        "Amount",
                        amount,
                        "required"
                      )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label>Comments</label>
                    <textarea
                      className='form-control'
                      name='comment'
                      value={comment}
                      onChange={(e) => this.onChangeHandler(e)}
                      rows={3}
                    />
                  </div>
                </div>
                <Link
                  to='#'
                  className='btn btn-light-black btn-block'
                  onClick={(e) => this.PaymentSubmitHandler(e)}
                >
                  Save
                </Link>
              </form>
            </Modal.Body>
          )}
        </Modals>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  DoctorListing: state.admin.adminDocListing,
  doctor_charge: state.admin.doctor_charge,
  queryString: state.admin.queryString,
  filter: state.filter,
});

export default connect(mapStateToProps)(AdminDoctorList);
