import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LabListingDetails, LabProfileDetails,WeeklyLabAppointments } from './../store/actions/labPanelActions';
import Pagination from './../store/helpers/Pagination';
import { bucketPath } from './../store/helpers/common';
import LabAppoinment from './modals/LabAppointment';

class LabListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'List',
      currentPage: 1,
      pageOfItems: [],
      listType: 'row',
      totalItems: [],
    };
  }


  componentDidMount() {
    this.props.dispatch(LabListingDetails());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  onClickHandler = (id) => {
    this.props.dispatch(LabProfileDetails(id));
    this.props.dispatch(WeeklyLabAppointments({ uniquekey: id }));
    window.$('#bookAppointmentListing').modal({ show: true,backdrop: "static" });
  };


  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.labs !== this.props.labs) {
      this.setState({ totalItems: this.props.labs });
    }
  }

  render() {
    const { listType,totalItems } = this.state;
    return (
      <div className='page-content background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='doctor-list-section col-md-8'>
              <div className='clearfix'></div>
              <div className='filters-inner-pages'>
                <div className='row'>
                  <div className='total-dr  col-md-2 align-self-center'>
                    <p>{totalItems.length} Labs</p>
                  </div>
                  <ul className='filters-bar-inner col-md-10'>
                    <li
                      className={`nav-item float-right ${
                        listType === 'grid' ? 'select-view' : ''
                      }`}
                    >
                      <Link
                        className='nav-link'
                        to='#'
                        onClick={(e) => this.setState({ listType: 'grid' })}
                      >
                        <i className='fas fa-th'></i>
                      </Link>
                    </li>
                    <li
                      className={`nav-item float-right ${
                        listType === 'row' ? 'select-view' : ''
                      }`}
                    >
                      <Link
                        className='nav-link'
                        to='#'
                        onClick={(e) => this.setState({ listType: 'row' })}
                      >
                        <i className='fas fa-th-list'></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {listType === 'row' && this.ListViewListing()}
              {listType === 'grid' && this.GridListing()}
            </div>
            <div className='col-md-4 doctor-list-right-side-sec'>
              <div className='dr-list-ask-question'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h2>
                      <i className='far fa-envelope'></i>&nbsp; Ask a FREE
                      Question
                    </h2>
                  </div>
                </div>
                <div className='dr-list-ask-question-textbox'>
                  <div className='row'>
                    <div className='col-md-12 align-self-center'>
                      <h4>Get FREE multiple opinions from Doctors</h4>
                      <textarea
                        className='form-control'
                        placeholder='Enter question for you or someone else. E.g. my left eye is red and swollen...'
                        id='exampleFormControlTextarea1'
                        rows='5'
                      ></textarea>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-8 align-self-center'>
                      <p>Posted anonymously</p>
                    </div>
                    <div className='col-md-4 align-self-center'>
                      <Link to='#' className='btn btn-light-black  float-right'>
                        Submit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='dr-list-health-topic-box'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h2>Top Health Tips</h2>
                  </div>
                </div>
                <div className='dr-list-topic-list-box'>
                  <div className="row">
                    <div className="col-lg-7 col-md-12 align-self-center">
                      <h5><Link to="/health-feed">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                    </div>
                  </div>
                </div>
                <div className='dr-list-topic-list-box'>
                  <div className="row">
                    <div className="col-lg-7 col-md-12 align-self-center">
                      <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                    </div>
                  </div>
                </div>
                <div className='dr-list-topic-list-box'>
                  <div className="row">
                    <div className="col-lg-7 col-md-12 align-self-center">
                      <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                    </div>
                  </div>
                </div>
                <div className='dr-list-topic-list-box'>
                  <div className="row">
                    <div className="col-lg-7 col-md-12 align-self-center">
                      <h5><Link to="#">Lorem ipsum, or lipsum as it is sometimes known...</Link></h5>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <img src="./../assets/images/tp-1.png" className="img-fluid" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LabAppoinment />
      </div>
    );
  }
  ListViewListing = () => { 
    let { totalItems } = this.state;
    return (
      <>
        {this.state.pageOfItems &&
          this.state.pageOfItems.map((val, i) => (
            <div className='doctor-list-box' key={`lab_${i}`}>
              <div className='row list-repeat-row'>
                <div className='col-lg-2 col-md-5  doctor-list-img text-center'>
                  <Link to='#'>
                    <img
                      src={`${
                        val.profile_pic
                          ? bucketPath + val.profile_pic
                          : 'assets/img/img-01.jpg'
                      }`}
                      alt='Img'
                    />
                  </Link>
                </div>
                <div className='col-lg-7 col-md-7 doctor-detail-box'>
                  <h2 className='dr-name'>
                    <Link to="#">
                      {val.title}. {val.firstname} {val.lastname}
                    </Link>
                    &nbsp;
                    <Link to='#'>
                      <i className='fas fa-heart'></i>
                    </Link>
                  </h2>
                  {/* <p className='dr-qualification'>
                    {val.degree.map((value, i) => (
                      <span key={i}>{value.toUpperCase()}</span>
                    ))}
                    - {val.specialization_name}
                  </p>  */}
                  <p className='dr-experience'>
                    <i className='fas fa-user-md'></i>
                    {val.position_held ? val.position_held : ''}
                  </p>
                  <p className='dr-location'>
                    <i className='fa fa-map-marker'></i>
                    {val.city},{val.state},{val.country}
                  </p>
                </div>
                <div className='col-lg-3 col-md-12 dr-fees-box  text-center align-self-center'>
                  {/* <ul className='contects-ico active-ico'>
                  <li className='active-ico'>
                      <Link to='#'>
                        <i className='fas fa-comment-alt'></i>
                      </Link>
                    </li>
                    <li className='active-ico'>
                      <Link to='#'>
                        <i className='fas fa-phone'></i>
                      </Link>
                    </li>
                    <li className='active-ico'>
                      <Link to='#'>
                        <i className='fas fa-video'></i>
                      </Link>
                    </li>
                  </ul> */}
                  <Link
                    to='#'
                    className='btn btn-light-black margin-top-10 btn-block'
                    onClick={(e) => this.onClickHandler(val.uniquekey)}
                  >
                    Book Appoinment
                  </Link>
                </div>
              </div>
            </div>
          ))}

        <div className='container-fluid no-padding'>
          <div className='row pull-right'>
            {/* <div className="col-md-5 col-sm-4 col-xs-12 "></div> */}
            <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
              &nbsp;
              {totalItems.length >= 0 ? (
                <Pagination
                  pageSize={10}
                  items={totalItems}
                  onChangePage={this.onChangePage}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  GridListing = () => {
    const { totalItems } = this.state;
    return (
      <div className='grid-view-div'>
        <div className='row list-repeat-row'>
          {this.state.pageOfItems.length > 0
            ? this.state.pageOfItems.map((val, i) => (
                <div className='col-md-6' key={i}>
                  <div className='doctor-list-box'>
                    <div className='row'>
                      <div className='doctor-list-img text-center col-md-3 align-self-center'>
                        <Link to='#'>
                          <img
                            src={`${
                              val.profile_pic
                                ? bucketPath + val.profile_pic
                                : 'assets/img/img-01.jpg'
                            }`}
                            alt='Img'
                          />
                        </Link>
                      </div>
                      <div className='col-md-9 doctor-detail-box'>
                        <h2 className='dr-name'>
                          <Link to="#">
                            {val.title}. {val.firstname} {val.lastname}
                          </Link>
                          &nbsp;
                          <Link to='#' className='float-right margin-top-10'>
                            <i className='fas fa-heart'></i>
                          </Link>
                        </h2>
                        {/* <p className='dr-specification '>
                          {val.specialization_name}
                        </p> */}

                        {/* <ul className='star-ul'>
                          <li>
                            <Link to='#'>
                              <i className='fa fa-star'></i>
                            </Link>
                          </li>
                          <li>
                            <Link to='#'>
                              <i className='fa fa-star'></i>
                            </Link>
                          </li>
                          <li>
                            <Link to='#'>
                              <i className='fa fa-star'></i>
                            </Link>
                          </li>
                          <li>
                            <Link to='#'>
                              <i className='fas fa-star-half-alt'></i>
                            </Link>
                          </li>
                          <li>
                            <Link to='#'>
                              <i className='far fa-star'></i>
                            </Link>
                          </li>
                          <li>
                            <Link className='rating-count' to='#'>
                              (105 reviews)
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    <div className='gird-detail-box'>
                      <div className='row'>
                        <div className='col-md-12'>
                           {/* <p className='dr-qualification'>
                            {val.degree.map((value, i) => {
                              return value.toUpperCase();
                            })}{' '}
                            -{val.specialization_name}
                          </p> */}
                          <p className='dr-experience'>
                            <i className='fas fa-user-md'></i>
                            {val.position_held ? val.position_held : ''}
                          </p>
                          <p className='dr-location'>
                            <i className='fa fa-map-marker'></i> {val.city},
                            {val.state},{val.country}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12 dr-fees-box  align-self-center no-padding'>
                      <Link
                        className='btn btn-light-black margin-top-10 float-right'
                        onClick={(e) => this.onClickHandler(val.uniquekey)}
                      >
                        Book Appoinment
                      </Link>
                      {/* <ul className='contects-ico float-right'>
                      <li className='active-ico'>
                          <Link to='#'>
                            <i className='fas fa-comment-alt'></i>
                          </Link>
                        </li>
                        <li className='active-ico'>
                          <Link to='#'>
                            <i className='fas fa-phone'></i>
                          </Link>
                        </li>
                        <li className='active-ico'>
                          <Link to='#'>
                            <i className='fas fa-video'></i>
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              ))
            : ''}
          <div className='container-fluid no-padding'>
            <div className='row pull-right'>
              <div className='col-md-12 col-sm-12 col-xs-12 pagination '>
                &nbsp;
                {totalItems.length >= 0 && (
                  <Pagination
                    pageSize={10}
                    items={totalItems}
                    onChangePage={this.onChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  labs:state.labPanel.lab_listing
});

export default connect(mapStateToProps)(LabListing);
