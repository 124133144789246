import { apiBaseUrl} from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices';
import toastr from "reactjs-toastr";
import {ShowLoader,ShowAlert} from './staticListingsActions'

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

export function NexmoSendOtp(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/send-otp`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 2000 });
                return {id:1,request_id:data.request_id};
            } else {
                toastr.error(data.ResponseText, { displayDuration: 2000 });
                return {id:0,request_id:""};
            }
        })
    }
}

export function NexmoVerifyOtp(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/verify-otp`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                // toastr.success(data.ResponseText, { displayDuration: 2000 });
                return 1;
            } else {
                // toastr.error(data.ResponseText, { displayDuration: 2000 });
                return 0;
            }
        })
    }
}

export function ChangePasswordApi(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/change-password`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                localStorage.setItem('token',data.token);
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0;
            }
        })
    }
}

export function BookAppointment(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/book-appointment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => { 
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                if (data.type === "D") {
                    localStorage.setItem('appt_with', "doctor");
                } else if (data.type === "CR") {
                    localStorage.setItem('appt_with', "counsellor");
                } else if (data.type === "F") {
                    localStorage.setItem('appt_with', "fitness");
                }
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                return {response:'success',code:data.data};
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return {response:'error'};
            }
        })
    }
}

export function RescheduleAppointment(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/reschedule-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {

            if (data.ResponseCode === "1") {
                dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                return {response:'success',code:data.data};
            } else {
              dispatch(ShowLoader(false));
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return {response:'error'};
            }
        })
    }
}

export function AppoinmentDetail(data){
    let appt_with = localStorage.getItem("appt_with");
    let URL = appt_with === 'fitness' ? 'api/fitness/appointment-detail' : appt_with === 'counsellor' ? 'api/counsellor/appointment-detail' : 'api/doctor/appointment-detail'
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/${URL}`, {
            method: 'POST',
            body: JSON.stringify({appointment_code:data}),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.FETCH_APPOINTMENT_CHECKOUT,payload: data.data})
            } else {
                return {response:'error'};
            }
        })
    }
}

export function CheckoutDetail(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/get_delivery_charge`, {
            method: 'POST',
            // body: JSON.stringify({appointment_code:data}),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.FETCH_CHECKOUT_DETAILS,payload: data.data})
            } else {
                return {response:'error'};
            }
        })
    }
}

export function CheckOutPatient(data){
    let appt_with = localStorage.getItem("appt_with");
    let URL = appt_with === 'fitness' ? 'stripe/checkout-for-Fitness' : appt_with === 'counsellor' ? 'stripe/checkout-for-Counsellor' : 'stripe/checkout'
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/${URL}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                localStorage.removeItem("appt_with");
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:10000}));
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function PatientAppointmentList(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient/appointment-list`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.PATIENT_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function PatientCounsellorAppointmentList(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient/appointment-list-for-counsellor`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.PATIENT_COUNSELLOR_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function PatientFitnessAppointmentList(){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient/appointment-list-for-fitness`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.PATIENT_FITNESS_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function DoctorPatientAppointmentList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/patient-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.DOCTOR_PATIENT_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function WeeklyAppointments(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/weekly-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.WEEKLY_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                // dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function counselorWeeklyAppointments(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/weekly-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.COUNSELLOR_WEEKLY_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                // dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function fitnessWeeklyAppointments(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/weekly-appointments`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.FITNESS_WEEKLY_APPOINTMENT_LIST,payload: data.data})
                return 1;
            } else {
                // dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function RemoveAppointmentData(data) {
    return dispatch => {
        dispatch({
            type: actionTypes.WEEKLY_APPOINTMENT_LIST,
            payload: []
        })
    }
}

export function PrescriptionsList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/prescriptions`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.PRESCRIPTIONS_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function MedicalRecordsList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/medical-records`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.MEDICAL_RECORDS_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function PatientPrescriptionsList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/patient-prescriptions`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.PRESCRIPTIONS_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}

export function PatientMedicalRecordsList(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/Patientmedical-records`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then(data => {
                dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                dispatch({type: actionTypes.MEDICAL_RECORDS_LIST,payload: data.data})
                return 1;
            } else {
                dispatch(ShowAlert({Class:"alert-danger",Message:data.ResponseText,Timeout:10000}));
                return 0
            }
        })
    }
}
