import * as types from "./../types/types";

const initalState = {
    loading: true,
    billingList: [],
    force_login_click:false,
}

const patientReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_BILL_LISTING: 
            return {
                ...state,
                billingList:action.payload
            }
            case types.FORCE_LOGIN_CLICK:
                return {
                    ...state,
                    force_login_click: action.payload
                }
        default:
            return state
    }
}


export default patientReducer;
