import { apiBaseUrl } from './../helpers/common';
import * as actionTypes from './../types/types';
import { handleResponse } from './../helpers/userServices';
import toastr from "reactjs-toastr";
import "reactjs-toastr/lib/toast.css";
import {ShowLoader,ShowAlert} from './staticListingsActions'
import swal from "sweetalert";

export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}

export function adminLogin(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        data.type = "A";
        const request = new Request(`${apiBaseUrl}/api/user/login`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if (responseData.token && responseData.data && responseData.data) {
                localStorage.setItem('admin-token', responseData.token)
                localStorage.setItem('admin-identity', JSON.stringify(responseData.data))
                dispatch(ShowAlert({Class:"alert-success",Message:responseData.ResponseText,Timeout:50000}));
                return 1;
                // history.push('/admin/doctor-listing')
            } else {
                swal({
                    title: responseData.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
            }
        });
    }
}

export function profileInfo() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/profile-info`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_PROFILE_INFO, payload: data.data })
            }
        });
    }
}

export const fetchadmindoctorlisting = (data) => {
    return {
        type: actionTypes.ADMIN_DOCTOR_LISTING,
        payload: data.data
    }
}

export function getAdminDoctorListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/doctor/listing`, {
            method: 'GET',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadmindoctorlisting(data));
        });
    }
}

export function getAdminFitnessExpertListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/fitness/listing`, {
            method: 'GET',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadmindoctorlisting(data));
        });
    }
}

export const fetchadminpatientlisting = (data) => {
    return {
        type: actionTypes.ADMIN_PATIENT_LISTING,
        payload: data
    }
}

export function getAdminPatientListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/patients/listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadminpatientlisting(data));
        });
    }
}

export const fetchadminlablisting = (data) => {
    return {
        type: actionTypes.ADMIN_LAB_LISTING,
        payload: data
    }
}

export function getAdminLabListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/labs/listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadminlablisting(data));
        });
    }
}

export const fetchadminpharmacylisting = (data) => {
    return {
        type: actionTypes.ADMIN_PHARMACY_LISTING,
        payload: data
    }
}

export function getAdminPharmacyListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/pharmacy/listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadminpharmacylisting(data));
        });
    }
}

export const fetchadminhospitallisting = (data) => {
    return {
        type: actionTypes.ADMIN_HOSPITAL_LISTING,
        payload: data
    }
}

export function getAdminHospitalListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/hospital/listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadminhospitallisting(data));
        });
    }
}

export const fetchadminspecialisationlisting = (data) => {
    return {
        type: actionTypes.ADMIN_SPEICALISATION_LISTING,
        payload: data
    }
}

export function getAdminSpecialisationListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/specialization/list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadminspecialisationlisting(data));
        });
    }
}

export function SymptomsListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/symptoms/list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchadminspecialisationlisting(data));
        });
    }
}

export function AddSpecialist(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/${data.id > 0 ? 'edit' : 'add'}/Specialization`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(getAdminSpecialisationListing());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function AddSymptom(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/${data.id > 0 ? 'edit' : 'add'}/Symptoms`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(SymptomsListing());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function deleteSpecialisation(customerID) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/delete/Specialization`, {
            method: 'POST',
            body: JSON.stringify({ "id": customerID }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(getAdminSpecialisationListing());
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                }

            })
            .catch(err => {
                console.log(err);
            });
    }

}
export function deleteSymptoms(customerID) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/user/delete/Symptoms`, {
            method: 'POST',
            body: JSON.stringify({ "id": customerID }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(SymptomsListing());
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                }

            })
            .catch(err => {
                console.log(err);
            });
    }

}


export function AdminProfileInfo(doctorID) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/profile-info`, {
            method: 'POST',
            body: JSON.stringify({ id: doctorID }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_ADMIN_PROFILE_INFO, payload: data.data })
            }
        });
    }
}

export function AdminEditProfile(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/doctor/editProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(AdminProfileInfo());
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
            }
        });
    }
}


export const fetchID = (data) => {
    return {
        type: actionTypes.FETCH_UNQIUE_ID,
        payload: data
    }
}


export const fetchquerylisting = (data) => {
    return {
        type: actionTypes.ADMIN_QUERY_LISTING,
        payload: data
    }
}

export const fetchUserNameListing = (data) => {
    return {
        type: actionTypes.USER_DETAIL_FOR_CHAT,
        payload: data
    }
}

export function getQueryListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/contactUs-listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchquerylisting(data));
        });
    }
}

export function getUserDetailsForChat(data) {
    return dispatch=>{
        const request = new Request(`${apiBaseUrl}/api/user/get-username`,{
            method:'POST',
            body:JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUserNameListing(data));
        })
    }
}



export function VerifyApproval(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/doctor/update-approval`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(getAdminDoctorListing());
                return 1;
            } else {
                swal({
                    title: "Something went wrong. Please try again later.",
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        });
    }
}


export function Articlelisting() {
    return dispatch=>{
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/article-listing`,{
            method:'POST',
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if(responseData.ResponseCode==="1"){
                dispatch({type: actionTypes.ADMIN_ARTICLE_LISTING,payload: responseData.data})
            }else{
                dispatch(ShowAlert({Class:"alert-danger",Message:responseData.ResponseText,Timeout:1500}));
            }
        })
    }
}

export function ArticleStatusChange(data) {
    return dispatch=>{
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/change-article-status`,{
            method:'POST',
            body: JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if(responseData.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:responseData.ResponseText,Timeout:1500}));
                dispatch(Articlelisting())
            }else{
                dispatch(ShowAlert({Class:"alert-danger",Message:responseData.ResponseText,Timeout:1500}));
            }
        })
    }
}

export function getAdminReviewList(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/review-listing-forAdmin`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({type:actionTypes.ADMIN_REVIEWS,payload:responseData.data})
                return 1;
            } else {
                return 0
            }
        });
    }
}

export function DeleteReview(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/review-delete`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({Class:"alert-success",Message:responseData.ResponseText,Timeout:5000}));
                dispatch(getAdminReviewList())
                return 1;
            } else {
                swal({
                    title: responseData.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
                return 0
            }
        });
    }
}

export function AdminPayments(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/payment-by-admin`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(getAdminDoctorListing())
               return 1;
            }else{
                return 0;
            }
        });
    }
}

export function AdminSubscriptionChargeList(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/subscription-list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch({type:actionTypes.ADMIN_SUBSCRIPTION_CHARGE_LIST,payload:responseData.data})
                return 1;
            } else {
                return 0
            }
        });
    }
}

export function DeleteSubscriptionCharge(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/subscription-delete`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if (responseData.ResponseCode === "1") {
                dispatch(ShowAlert({Class:"alert-success",Message:responseData.ResponseText,Timeout:5000}));
                dispatch(AdminSubscriptionChargeList())
                return 1;
            } else {
                swal({
                    title: responseData.ResponseText,
                    icon: "error",
                    dangerMode: true
                })
                return 0
            }
        });
    }
}

export function AdminSubscriptionChargeCreate(data) {
    return dispatch=>{
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/subscription-by-admin`,{
            method:'POST',
            body: JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if(responseData.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:responseData.ResponseText,Timeout:1500}));
                return 1;
            }else{
                dispatch(ShowAlert({Class:"alert-danger",Message:responseData.ResponseText,Timeout:1500}));
                return 0;
            }
        })
    }
}

export function GetDoctorRegistrationPrice(data){
    return dispatch=>{
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/get-area-charge`,{
            method:'POST',
            body: JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if(responseData.ResponseCode==="1"){
                dispatch({type:actionTypes.CHARGES_TO_DOCTOR,payload:responseData.ResponseText})
            }
        })
    }
}

export function getAdminInvoiceListing(data){
    return dispatch=>{
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/invoice-list`,{
            method:'POST',
            body: JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if(responseData.ResponseCode==="1"){
                dispatch({type:actionTypes.ADMIN_INVOICE_LISTING,payload:responseData.data})
            }
        })
    }
}

export function DeleteInvoice(data){
    return dispatch=>{
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/delete-invoice`,{
            method:'POST',
            body: JSON.stringify(data),
            headers:new Headers({'Content-Type':'application/json','X-Auth-Token':`${localStorage.getItem('admin-token')}`}),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            dispatch(ShowLoader(false));
            if(responseData.ResponseCode==="1"){
                dispatch(getAdminInvoiceListing());
                swal({
                    title: responseData.ResponseText,
                    icon: "success",
                    dangerMode: false
                })
            }
        })
    }
}

export function filterResult(query){
    return dispatch => {
        dispatch({type:actionTypes.QUERY, payload:query})
    }
}

export function AddCounsellorTypes(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/councillor-${data.id ? `edit` : 'add'}-Symptoms`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(CounsellorTypeList());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function CounsellorTypeList(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/councillor-Symptoms-list`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}`  }),
        });
        return fetch(request).then(handleResponse).then((responseData) => {
            if (responseData.ResponseCode === "1") {
                dispatch({
                    type: actionTypes.COUNSELLOR_TYPE_LIST,
                    payload: responseData
                });
                return 1
            } else {
                return 0
            }
        });
    }
}

export function DeleteCounsellorType(uniquekey) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/councillor-delete-Symptoms`, {
            method: 'POST',
            body: JSON.stringify({ "id": uniquekey }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(CounsellorTypeList());
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                }

            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function AdminCounsellorListing(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/councillor/listing`, {
            method: 'GET',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch({
                type: actionTypes.ADMIN_COUNSELLOR_LISTING,
                payload: data.data
            });
        });
    }
}

export function CounsellorPayments(data) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/payment-by-counsellor`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch(AdminCounsellorListing())
               return 1;
            }else{
                return 0;
            }
        });
    }
}


export function VerifyCounsellerApproval(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/counsellor/update-approval`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(AdminCounsellorListing());
                return 1;
            } else {
                swal({
                    title: "Something went wrong. Please try again later.",
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        });
    }
}

export function VerifyFitnessExpertApproval(data){
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/fitness/update-approval`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            if(data.ResponseCode==="1"){
                dispatch(ShowAlert({Class:"alert-success",Message:data.ResponseText,Timeout:1500}));
                dispatch(getAdminFitnessExpertListing());
                return 1;
            } else {
                swal({
                    title: "Something went wrong. Please try again later.",
                    icon: "error",
                    dangerMode: true
                })
                return 0;
            }
        });
    }
}


export function AddCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/category-api`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(CategoryListing({type:'list'}));
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}


export function CategoryListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/category-api`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_CATEGORY_LISTING,
                payload: data.data
            });
        });
    }
}

export function DeleteCategory(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/category-api`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(CategoryListing({type:'list'}));
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                } 
            })
            .catch(err => {
                console.log(err);
            });
    }

}


export function AddSubCategory(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-subcategory`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(SubCategoryListing());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function UpdateSubCategory(data,id) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/edit-subcategory/${id}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(SubCategoryListing());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function SubCategoryListing() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/listing-subcategory`, {
            method: 'POST',
            body: JSON.stringify(),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_SUBCATEGORY_LISTING,
                payload: data.data
            });
        });
    }
}


export function DeleteSubCategory(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/delete-subcategory`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(SubCategoryListing());
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                } 
            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function AddProduct(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/add-product`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                localStorage.setItem('product', data.uniquekey);
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(ProductListing());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function ProductListing() {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/product-listing`, {
            method: 'POST',
            body: JSON.stringify(),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_PRODUCT_LISTING,
                payload: data.data
            });
        });
    }
}


export function DeleteProduct(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/delete-product`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    dispatch(ProductListing());
                    toastr.success(data.ResponseText, { displayDuration: 1500 });
                } else {
                    toastr.error(data.ResponseText, { displayDuration: 1500 });
                } 
            })
            .catch(err => {
                console.log(err);
            });
    }

}

export function EditProduct(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/edit-products`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then(data => {
            dispatch(ShowLoader(false));
            if (data.ResponseCode === "1") {
                toastr.success(data.ResponseText, { displayDuration: 1500 });
                dispatch(ProductListing());
                return 1;
            } else {
                toastr.error(data.ResponseText, { displayDuration: 1500 });
                return 0;
            }
        })
    }
}

export function ProductImagesListing(data) {
    return dispatch => {
        dispatch(ShowLoader(true));
        const request = new Request(`${apiBaseUrl}/api/product-images-listing`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(ShowLoader(false));
            dispatch({
                type: actionTypes.FETCH_PRODUCT_IMAGES_LISTING,
                payload: data.data
            });
        });
    }
}

export function DeleteProductImage(data) {
    return dispatch => {
        const request = new Request(`${apiBaseUrl}/api/delete-product-images`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });

        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    let id =  localStorage.getItem('product');
                    dispatch(ProductImagesListing({id:id}));
                    toastr.success(data.data, { displayDuration: 1500 });
                } else {
                    toastr.error(data.data, { displayDuration: 1500 });
                } 
            })
            .catch(err => {
                console.log(err);
            });
    }

}


export function AdminLiveData() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/data_count`, {
            method: 'POST', 
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_TOTAL_COUNT, payload: data.data })
            }
        });
    }
}

export function AdminFitnessClasses() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${apiBaseUrl}/api/FitnessClass_List`, {
            method: 'POST', 
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('admin-token')}` })
        });
        return fetch(request).then(handleResponse).then((data) => {
            if (data.ResponseCode === "1") {
                dispatch({ type: actionTypes.FETCH_ADMIN_FITNESS_CLASSES, payload: data.data })
            }
        });
    }
}
