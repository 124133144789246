import { history } from './history';
import {apiBaseUrl} from './common';
import store from './../store';
import {ShowAlert,ShowLoader} from './../actions/staticListingsActions';

export const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 500 || response.status === 405) {
                return response.status
            }
            if (response.status === 401 || response.status === 400) {
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = '/';
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export const identity = () => {
    let type = localStorage.getItem('type');
    return type;
}

export const ApiRequest = async(path,type,header=true,inputs,loader=true,alert=true) => {
    {loader && store.store.dispatch(ShowLoader(true))}
    let headers   = { 'Content-Type': 'application/json'};
    if(header){
        headers     = {...headers,'X-Auth-Token': `${localStorage.getItem('token')}` };
    }
    let params    = {method: type,headers: new Headers(headers)};

    if(typeof inputs ==="object"){
        params      = {...params,body:JSON.stringify(inputs)}
    }
    const request   = new Request(`${apiBaseUrl}${path}`,params);
    const result    = await fetch(request);
    {loader && setTimeout(()=>{
        store.store.dispatch(ShowLoader(false));
    },1000);}
    const status    = result.status;

    if (status === 500 || status === 405) {
        {alert && store.store.dispatch(ShowAlert({ Class: "alert-danger", Message: "Internal Server Error", Timeout: 5000 }))}
        return status
    }

    if (status === 401 || status === 400) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
        return
    }

    if (status !== 200) {
        {alert && store.store.dispatch(ShowAlert({ Class: "alert-danger", Message: "Unknown Error Occured", Timeout: 5000 }))}
        return status
    }

    const response  = await result.json();
    if (status === 200 && response.ResponseCode==="0") {
        store.store.dispatch(ShowAlert({ Class: "alert-danger", Message: response.ResponseText, Timeout: 5000 }))
    }
    if (status === 200 && response.ResponseCode==="1") {
        {alert && store.store.dispatch(ShowAlert({ Class: "alert-success", Message: response.ResponseText, Timeout: 5000 }))}
    }
    const data      = { ...response, status }
    return data
}
