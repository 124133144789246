import React, { Component } from "react";
import { Link } from "react-router-dom";  
import {BookingClassDetail,FitnessClassCheckOut} from './../../store/actions/fitnessActions'
import { bucketPath } from "./../../store/helpers/common";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import MaskedInput from "react-maskedinput";
import swal from "sweetalert";

const initialState = {
  bookingID: "",
  firstname: "",
  lastname: "",
  email: "", 
  card_name: "",
  card_number: "",
  card_expiry: "",
  cvv: "",
  zip: "",
  terms_and_conditions: false,
  amount:''
};
class FitnessClassCheckout extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({ bookingID: id });
      this.props.dispatch(BookingClassDetail(id));
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickHandler = async(e) => {
    if (!this.validator.allValid()){
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    const {bookingID,firstname,lastname,email,card_name,card_number,card_expiry,cvv} = this.state;
    let date = card_expiry.split("/");
    let data = {
      booking_code: bookingID,
      firstname,
      lastname,
      email,
      cardName: card_name,
      card_number,
      cvv,
      exp_month: date[0],
      exp_year: date[1]
    }
    const response = await this.props.dispatch(FitnessClassCheckOut(data));
    const { checkoutInfo } = this.props; 
    const wrapper = document.createElement('div'); 
    wrapper.innerHTML = `<div><b>`+ checkoutInfo.fitness_class_title + `</b> fitness class of <b>` + checkoutInfo.title + `</b><b>. ` + checkoutInfo.fitness_name + ` </b> is booked successfully. </div>`
    if(response===1){       
      swal({
        title: "Fitness class booked successfully",
        icon: "success",
        content: wrapper
      })
      .then(async (action) => { 
        if(action) {
          this.props.history.push('/patient/appointment');
        }
      });
    }
    
  }

  render() {
    const { checkoutInfo} = this.props; 
    let review_average = checkoutInfo && checkoutInfo.review_average
    return (
      <div className="row">
        <div className="col-md-7 col-lg-8">
          <div className="card">
            <div className="card-body">
              {/* -- Checkout Form -- */}
              <form action="booking-success.html">
                {/* <!-- Personal Information --> */}
                <div className="info-widget">
                  <h4 className="card-title">Personal Information</h4>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>First Name</label>
                        <input
                          className="form-control"
                          name="firstname"
                          value={this.state.firstname}
                          type="text"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('First Name',this.state.firstname,'required')}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>Last Name</label>
                        <input
                          className="form-control"
                          name="lastname"
                          value={this.state.lastname}
                          type="text"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Last Name',this.state.lastname,'required')}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group card-label">
                        <label>Email</label>
                        <input
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          type="email"
                          onChange={(e) => this.onChangeHandler(e)}
                        />
                        {this.validator.message('Email',this.state.email,'required|email')}
                      </div>
                    </div> 
                  </div> 
                </div>
                {/* <!-- /Personal Information --> */}

                <div className="payment-widget">
                  <h4 className="card-title">Payment Method</h4>

                  {/* <!-- Credit Card Payment --> */}
                  <div className="payment-list">
                    <label className="payment-radio credit-card-option">
                      <input type="radio" name="radio" checked={true} />
                      <span className="checkmark"></span>
                      Credit card
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="card_name">Name on Card</label>
                          <input
                            className="form-control"
                            name="card_name"
                            value={this.state.card_name}
                            id="card_name"
                            type="text"
                            onChange={(e) => this.onChangeHandler(e)}
                          />
                          {this.validator.message('Name',this.state.card_name,'required')}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="card_number">Card Number</label>
                            <MaskedInput
                                mask="1111 1111 1111 1111"
                                name="card_number"
                                id="cr_no"
                                size="20"
                                value={this.state.card_number}
                                onChange={(e) => this.onChangeHandler(e)}
                            /> 
                          {this.validator.message('Card Number',this.state.card_number,'required|card_num')}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="expiry_month">Expiry Month</label>
                            <MaskedInput
                                mask="11/1111"
                                id="card_expiry"
                                name="card_expiry"
                                placeholder="MM/YYYY"
                                value={this.state.card_expiry}
                                onChange={(e) => this.onChangeHandler(e)}
                            /> 
                            {this.validator.message('Card Expiry',this.state.card_expiry,'required|card_exp')}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group card-label">
                          <label htmlFor="cvv">CVV</label>
                            <MaskedInput
                                mask="111"
                                className="placeicon"
                                name="cvv"
                                id="cvv"
                                value={this.state.cvv} 
                                onChange={(e) => this.onChangeHandler(e)}
                            /> 
                          {this.validator.message('Card Cvv',this.state.cvv,'required')}
                        </div>
                      </div> 
                    </div>
                  </div>
                  {/* <!-- /Credit Card Payment --> */}

                  {/* <!-- Paypal Payment --> */}
                  <div className="payment-list">
                    <label className="payment-radio paypal-option">
                      <input type="radio" name="radio" />
                      <span className="checkmark"></span>
                      Paypal
                    </label>
                  </div>
                  {/* <!-- /Paypal Payment --> */}

                  {/* <!-- Terms Accept --> */}
                  <div className="terms-accept">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="terms_accept"
                        name="terms_and_conditions"
                        checked={this.state.terms_and_conditions}
                        onClick={(e) =>
                          this.setState((prevState) => ({
                            terms_and_conditions: !prevState.terms_and_conditions,
                          }))
                        }
                      />&nbsp;
                      <label htmlFor="terms_accept">
                        I have read and accept{" "}
                        <Link to="#">Terms &amp; Conditions</Link>
                      </label>
                    </div>
                  </div>
                  {/* <!-- /Terms Accept --> */}

                  {/* <!-- Submit Section --> */}
                  <div className="submit-section mt-4">
                    <Link
                      to="#"
                      className="btn btn-light-black submit-btn"
                      onClick={e=>this.onClickHandler(e)}
                    >
                      Confirm and Pay
                    </Link>
                  </div>
                  {/* <!-- /Submit Section --> */}
                </div>
              </form>
              {/* <!-- /Checkout Form --> */}
            </div>
          </div>
        </div>

        <div className="col-md-5 col-lg-4 theiaStickySidebar">
          {/* <!-- Booking Summary --> */}
          <div className="card booking-card">
            <div className="card-header">
              <h4 className="card-title">Booking Summary</h4>
            </div>
            <div className="card-body">
              {/* <!-- Booking Doctor Info --> */}
              <div className="booking-doc-info">
                <Link to="doctor-profile.html" className="booking-doc-img">
                  <img
                    src={`${
                      checkoutInfo && checkoutInfo.fitness_profile_pic
                        ? bucketPath + checkoutInfo.fitness_profile_pic
                        : "/assets/img/doctors/doctor-thumb-02.jpg"
                    }`}
                    alt="User"
                  />
                </Link>
                <div className="booking-info">
                  <h4>
                    <Link to="doctor-profile.html">{`${checkoutInfo && checkoutInfo.title}.`} {`${
                      checkoutInfo && checkoutInfo.fitness_name
                    }`}</Link>
                  </h4>
                  <div className="rating"> 
                      {[...Array(5).keys()].map((value, i) => { 
                        return (
                          <i
                            key={`star_${i}`}
                            className={`fas fa-star ${
                              value + 1 <= Math.round(review_average)
                                ? "filled"
                                : ""
                            }`}
                          ></i>
                        );
                      })} 
                      <br/>
                      <span className="d-inline-block average-rating"> 
                        {`(${checkoutInfo && checkoutInfo.review_count } reviews)`}
                      </span>
                  </div>
                  <div className="clinic-details">
                    <p className="doc-location">
                      <i className="fas fa-map-marker-alt"></i> {`${checkoutInfo && checkoutInfo.fitness_city},${checkoutInfo && checkoutInfo.fitness_state},${checkoutInfo && checkoutInfo.fitness_country}`}
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Booking Doctor Info --> */}

              <div className="booking-summary">
                <div className="booking-item-wrap">
                  <ul className="booking-date">
                    <li>
                      Fitness Class{" "}
                      <span>{`${
                        checkoutInfo && checkoutInfo.fitness_class_title
                      }`}</span>
                    </li> 
                  </ul>
                  <ul className="booking-date">
                    <li>
                      Date{" "}
                      <span>{`${
                        checkoutInfo && checkoutInfo.appointment_date
                      }`}</span>
                    </li> 
                  </ul>
                  <ul className="booking-fee"> 
                    <li>
                      Class Fee <span>₹ {checkoutInfo && checkoutInfo.fees}</span>
                    </li>
                    
                  </ul>
                  <div className="booking-total">
                    <ul className="booking-total-list">
                      <li>
                        <span>Total</span>
                        <span className="total-cost">₹ {(checkoutInfo.fees ? checkoutInfo.fees : 0)}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /Booking Summary --> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  checkoutInfo: state.fitness.fitness_checkout_details,
  profile_info:state.user.profile_info
});
export default connect(mapStateToProps)(FitnessClassCheckout);
