import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';
import AdminHospitals from './hospital';
import AdminLabs from './Labs'; 
import AdminPharmacy from './Pharmacy'; 

class AdminHospital extends Component {
    state = {tab:'hospital'}
    render() {
        const {tab} = this.state
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body">
                        <nav className="user-tabs mb-4">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="hospital"?"active":""}`} to="#" onClick={e=>this.setState({tab:'hospital'})} >Hospitals</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="lab"?"active":""}`} to="#" onClick={e=>this.setState({tab:'lab'})}>Labs</Link>
                                </li> 
                                <li className="nav-item">
                                    <Link className={`nav-link ${tab==="pharma"?"active":""}`} to="#" onClick={e=>this.setState({tab:'pharma'})}>Pharmacy</Link>
                                </li> 
                            </ul>
                        </nav>
                        {tab==="hospital" && <AdminHospitals />}
                        {tab==="lab" && <AdminLabs />}
                        {tab==="pharma" && <AdminPharmacy />} 
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminHospital