import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Pagination from './../../store/helpers/Pagination';
import { getBillingList, getProductBillingList } from './../../store/actions/patientActions'
import Modals from "./../General/Modals";
import { Modal} from "react-bootstrap";
import { apiBaseUrl } from '../../store/helpers/common'

class PatientBilling extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageOfItems: [],
            currentPage: 1,
            open: false,
            invoice_info: {},
            tab: 'appointment'
        }
    }

    componentDidMount() {
        this.getInvoiceListing();
    }

    getInvoiceListing = () => {
        const { tab } = this.state;
        if(tab === "appointment"){
            this.props.dispatch(getBillingList());
        }
        else {
            this.props.dispatch(getProductBillingList());
        }
    }

    onChangePage = (pageOfItems, page) => {
        this.setState({ pageOfItems: pageOfItems, currentPage: page });
    };

    setOpen = (value) => {
        this.setState({ open: false });
    };

    render() {
        const { billings } = this.props;
        const { pageOfItems, open, invoice_info, tab } = this.state;
        return (
            <>
                <div>
                    <div className="card card-table mb-0">
                        <div className="card-body">
                            <nav className="user-tabs mb-4">
                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                    <li className="nav-item">
                                        <Link className={`nav-link ${tab === "appointment" ? "active" : ""}`} to="#" onClick={e => this.setState({ tab: 'appointment' }, () => this.getInvoiceListing())} >Appointment Invoices</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${tab === "product" ? "active" : ""}`} to="#" onClick={e => this.setState({ tab: 'product' }, () => this.getInvoiceListing())}>Product Invoices</Link>
                                    </li>
                                </ul>
                            </nav>
                            {
                                tab === "appointment" &&
                                <div className="table-responsive">
                                    <table className="table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Invoice No</th>
                                                <th>Doctor</th>
                                                <th>Amount</th>
                                                <th>Paid On</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pageOfItems instanceof Array && pageOfItems.map((value, index) => {
                                                    return (
                                                        <tr key={`billing_${index}`}>
                                                            <td>
                                                                {value.PaymentData?.invoice_number}
                                                            </td>
                                                            <td>
                                                                <h2 className="table-avatar">
                                                                    <a href={"/doctor-view/" + value.DocotrData?.Uniquekey} className="avatar avatar-sm mr-2">
                                                                        <img className="avatar-img rounded-circle" src={"https://storage.googleapis.com/thelifeline-275005.appspot.com/thelifeline/" + value.DocotrData?.Image} alt="User" />
                                                                    </a>
                                                                    <a href={"/doctor-view/" + value.DocotrData?.Uniquekey}>{value.DocotrData?.doctorName} <span>{value.DocotrData?.Specialization ? value.DocotrData?.Specialization : ""}</span></a>
                                                                </h2>
                                                            </td>
                                                            <td>₹{value.PaymentData?.paid_amount}</td>
                                                            <td>{value.PaymentData?.Created.split(" ")[0]}</td>
                                                            <td className="text-right">
                                                                <div className="table-action">
                                                                    {/* <a onClick={e => { this.setState({ open: true, invoice_info: value }) }} className="btn btn-sm bg-info-light">
                                                                    <i className="far fa-eye"></i> View
                                                                </a> */}
                                                                    <a href={apiBaseUrl + "/api/download-invoice?invoice_number=" + value.PaymentData?.invoice_number} className="btn btn-sm bg-primary-light">
                                                                        <i className="fa fa-download"></i> Download
                                                                </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            }
                            {
                                tab === "product" &&
                                <div className="table-responsive">
                                    <table className="table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>Invoice No</th>
                                                {/* <th>Doctor</th> */}
                                                <th>Amount</th>
                                                <th>Paid On</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pageOfItems instanceof Array && pageOfItems.map((value, index) => {
                                                    return (
                                                        <tr key={`invoice_${index}`}>
                                                            <td>
                                                                {value.PaymentData?.invoice_number}
                                                            </td>
                                                            {/* <td>
                                                                <h2 className="table-avatar">
                                                                    <a href={"/doctor-view/" + value.DocotrData?.Uniquekey} className="avatar avatar-sm mr-2">
                                                                        <img className="avatar-img rounded-circle" src={"https://storage.googleapis.com/thelifeline-275005.appspot.com/thelifeline/" + value.DocotrData?.Image} alt="User" />
                                                                    </a>
                                                                    <a href={"/doctor-view/" + value.DocotrData?.Uniquekey}>{value.DocotrData?.doctorName} <span>{value.DocotrData?.Specialization ? value.DocotrData?.Specialization : ""}</span></a>
                                                                </h2>
                                                            </td> */}
                                                            <td>₹{value.PaymentData?.paid_amount}</td>
                                                            <td>{value.PaymentData?.Created.split(" ")[0]}</td>
                                                            <td className="text-right">
                                                                <div className="table-action">
                                                                    {/* <a onClick={e => { this.setState({ open: true, invoice_info: value }) }} className="btn btn-sm bg-info-light">
                                                                    <i className="far fa-eye"></i> View
                                                                </a> */}
                                                                    <a href={apiBaseUrl + "/api/download-invoice?invoice_number=" + value.PaymentData?.invoice_number} className="btn btn-sm bg-primary-light">
                                                                        <i className="fa fa-download"></i> Download
                                                                </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            }
                        </div>
                    </div>
                    {billings instanceof Array && (
                        <Pagination
                            pageSize={10}
                            items={billings}
                            onChangePage={this.onChangePage}
                        />
                    )}
                </div>
                <Modals open={open} setOpen={this.setOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Invoice Details
            			</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="bold">Invoice From</div>
                                <div className="fromAddress">
                                    {invoice_info.DocotrData?.doctorName} <br />
                                    {invoice_info.DocotrData?.DoctorAddress}
                                </div>
                            </div>
                            <div className="col-md-2 offset-md-8 pull-right">
                                <div className="bold">Invoice To</div>
                                <div className="toAddress">
                                    {invoice_info.patientData?.PatientName} <br />
                                    {invoice_info.patientData?.PatientAddress}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="bold">Payment Method</div><br />
                            <div className="paymentType">
                                {invoice_info.PaymentData?.payment_type}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <table>
                                    <tr>
                                        <th>Description</th>
                                        <th>Quantity</th>
                                        <th>VAT</th>
                                        <th>Total</th>
                                    </tr>
                                    <tr>
                                        <td>General Consultation</td>
                                        <td>1</td>
                                        <td>$0</td>
                                        <td>${invoice_info.DocotrData?.GeneralAmount}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {
                                                invoice_info.AppointmentsData?.appointment_type + " Consultation"
                                            }
                                        </td>
                                        <td>1</td>
                                        <td>$0</td>
                                        <td>${invoice_info.DocotrData?.AppointmentAmount}</td>
                                    </tr>
                                    {/* <tr>
										<td></td>
										<td></td>
										<td>Subtotal:</td>
										<td>$350</td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td>Discount</td>
										<td>-10%</td>
									</tr> */}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Total Amount</td>
                                        <td>${invoice_info.PaymentData?.paid_amount}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modals>
            </>
        )
    }
}
const mapStateToProps = state => ({
    billings: state.patient.billingList
});

export default connect(mapStateToProps)(PatientBilling);
