import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PatientCounsellorAppointmentList,counselorWeeklyAppointments } from './../../store/actions/registerationActions';
import Pagination from './../../store/helpers/Pagination';
import swal from "sweetalert";
import CounsellorAppointment from '../modals/CounsellorAppointment';
import { CancelAppointment } from '../../store/actions/userActions';
import { CounselorProfileDetails } from '../../store/actions/globalActions';
import { ShowLoader } from '../../store/actions/staticListingsActions';
import { bucketPath } from '../../store/helpers/common';

const statusDetails = {
  C : {
    text: 'Confirm',
    className: 'bg-warning-light'
  },
  S: {
    text: 'Cancelled',
    className: 'bg-danger-light'
  },
  F: {
    text: 'Completed',
    className: 'bg-success-light'
  }
}
class PatientCounsellorAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pageOfItems: [], currentPage: 1, countDownTime: {}, appointmentCode: '', doctorUniquekey: '' };
    this.intervalID = 0;
  }

  componentDidMount() {
    this.props.dispatch(PatientCounsellorAppointmentList());
  }

  onChangePage = (pageOfItems, page) => {
    this.setState({ pageOfItems: pageOfItems, currentPage: page });
  };

  SetName = (name, type = 'Audio', doctor_uniquekey, appointment_code) => {
    sessionStorage.setItem('_caller_name', name);
    sessionStorage.setItem('_call_type', type);
    sessionStorage.setItem('_doctor_uniquekey', doctor_uniquekey);
    sessionStorage.setItem('_appointment_code', appointment_code);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageOfItems !== this.state.pageOfItems) {
      clearInterval(this.intervalID);
      this.intervalID = 0
      this.countDownTimer(this.state.pageOfItems)
    }
  }

  countDownTimer = (appointments) => {
    this.intervalID = this.intervalID === 0 && setInterval(() => {
      const now = new Date().getTime();
      let timerStatus = [];
      appointments.map(appointment => {
        const givenDate = `${appointment.appointment_date_display}`;
        const countDownDate = new Date(givenDate).getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // console.log("givenDate------------", now , " ====== ", countDownDate, " ========= ", givenDate, " ======== ", new Date('03-Jun-2020 08:00:00').getTime());
        if (distance <= 0 && distance > -900000) {
          timerStatus[appointment.appointmentID] = 'START';
        } else if (distance <= -900000) {
          timerStatus[appointment.appointmentID] = 'EXPIRED';
        } else if (distance > 0 && distance < 3600000) {
          if (!days && hours && minutes && seconds) {
            timerStatus[appointment.appointmentID] = hours + "hours " + minutes + "mins " + seconds + "secs";
          } else if (!days && !hours && minutes && seconds) {
            let text = minutes > 1 ? " mins " : " min "
            timerStatus[appointment.appointmentID] = minutes + text + seconds + "secs";
          } else if (!days && !hours && !minutes && seconds) {
            let text = seconds > 1 ? " secs " : " sec "
            timerStatus[appointment.appointmentID] = seconds + text;
          }
        } else {
          timerStatus[appointment.appointmentID] = givenDate;
        }
        return true
      })
      this.setState({ countDownTime: { ...this.state.countDownTime, ...timerStatus } });
    }, 1000);

    setTimeout(() => {
      this.props.dispatch(ShowLoader(false));
    }, 1000)
  };

  handleCancel = (appointmentCode) => {
    swal({
      title: "Are you sure want to cancel the appointment?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const data = { appointment_code: appointmentCode };
          const res = await this.props.dispatch(CancelAppointment(data));
          if (res === 1) {
            this.props.dispatch(PatientCounsellorAppointmentList());
          }
        }
      });
  }

  GetAppointment = (doctorUniquekey, appointmentCode) => {
    this.props.dispatch(CounselorProfileDetails(doctorUniquekey));
    this.props.dispatch(counselorWeeklyAppointments({ uniquekey: doctorUniquekey }));
    this.setState({ appointmentCode: appointmentCode }, () => {
      window.$('#bookAppointmentListing').modal({ show: true, backdrop: "static" });
    });
  }

  updateAppointmentList = () => {
    this.props.dispatch(PatientCounsellorAppointmentList());
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.intervalID = 0
  }


  render() {
    const { patient_appoinments } = this.props;
    const { pageOfItems } = this.state;
    const patientUniqueKey = JSON.parse(localStorage.data).uniquekey;
    return (
      <div>
        <div className='card card-table mb-0'>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-hover table-center mb-0'>
                <thead>
                  <tr>
                    <th>Doctor</th>
                    <th>Appt Date</th>
                    <th>Booking Date</th>
                    <th>Amount</th>
                    <th>Consult Type</th>
                    <th>Status</th>
                    {/* <th>Duration</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItems.map((value, key) => {
                    let booking = value.creationdate.split(' ');
                    let appointmentdate = value.appointment_datetime.split(' ');
                    return (
                      <tr key={`page_${key}`}>
                        <td>
                            <h2 className="table-avatar">
                              <Link to={`/counsellor-view/${value.councillor_uniquekey}`} className="avatar avatar-sm mr-2">
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={`${value.councillor_profile? bucketPath + value.councillor_profile : "./../assets/doctor/img/patients/patient.jpg"}`}
                                    alt="User"
                                  />
                              </Link>
                              <Link to={`/counsellor-view/${value.councillor_uniquekey}`}>{`${value.councillor_name}`} <span> {value.specialization}</span></Link>
                            </h2>
                        </td>
                        <td>
                          {appointmentdate[0]}
                          <span className='d-block text-info'>
                            {value.starttime}
                          </span>
                        </td>
                        <td>
                          {booking[0]}
                          <span className='d-block text-info'>
                            {booking[1].slice(0,5)}
                          </span>
                        </td>
                        <td>{`₹ ${value.amount}`}</td>
                        <td>{value.appointment_type}</td>
                        <td>
                          <span className={`badge badge-pill ${statusDetails[value.status].className}`}>
                              {statusDetails[value.status].text}
                          </span>
                        </td>
                        {/* <td>{`${value.duration === '0' ? '00:00:00' : value.duration}`}</td> */}
                        <td className='text-right'>
                          {value.status === 'S' && <div className='table-action table-action-2'><h5>
                            {`Appointment cancelled by ${value.updatedBy === patientUniqueKey ? 'you.' : 'counsellor.'}`}
                            </h5></div>
                          }
                          {value.status === 'F' && <div className='appointment-action'>
                              <h5>{`${value.duration === '0' ? '00:00:00' : value.duration}`}</h5>
                            </div>
                          }
                          {value.status === 'C' && <div className='table-action table-action-2'>
                            <h5>
                              {' '}
                              {
                                this.state.countDownTime[value.appointmentID] !== 'START'
                                && this.state.countDownTime[value.appointmentID] !== 'EXPIRED'
                                && this.state.countDownTime[value.appointmentID] === value.appointment_date_display
                                && 'You had an appointment on'
                              }&nbsp;
                              {
                                this.state.countDownTime[value.appointmentID] !== 'START'
                                && this.state.countDownTime[value.appointmentID] !== 'EXPIRED'
                                && this.state.countDownTime[value.appointmentID] !== value.appointment_date_display
                                && 'You\'re appointment will start in'
                              }&nbsp;
                              {/* <br /> */}
                              {this.state.countDownTime[value.appointmentID] !==
                                'START' &&
                                this.state.countDownTime[value.appointmentID]}
                            </h5>
                            {this.state.countDownTime[value.appointmentID] ===
                              'START' && (
                                <Link
                                  to={value.appointment_type === `Chat` ? `/chat-messages` : `/chat-appointment/${value.appointment_code}/${value.councillor_uniquekey}`}
                                  target='_blank'
                                  className='btn btn-sm bg-success-light'
                                  onClick={(e) =>
                                    this.SetName(
                                      value.councillor_name,
                                      value.appointment_type,
                                      value.councillor_uniquekey,
                                      value.appointment_code
                                    )
                                  }
                                >
                                  <i
                                    className={`fas ${
                                      value.appointment_type === 'Chat'
                                        ? 'fa-comment-alt'
                                        : value.appointment_type === 'Video'
                                          ? 'fas fa-video'
                                          : 'fas fa-phone'
                                      }`}
                                  ></i>
                                {value.appointment_type !== 'Chat' ? `Make ${value.appointment_type} Call` : `Start Chat`}
                                </Link>
                              )}
                            {this.state.countDownTime[value.appointmentID] !==
                              'START' &&
                              this.state.countDownTime[value.appointmentID] !==
                              'EXPIRED' && (
                                <Link
                                to={value.appointment_type === `Chat` ? `/chat-messages` : `/chat-appointment/${value.appointment_code}/${value.councillor_uniquekey}`}
                                target='_blank'
                                  className='btn btn-sm bg-warning-light'
                                >
                                  <i
                                    className={`fas ${
                                      value.appointment_type === 'Chat'
                                        ? 'fa-comment-alt'
                                        : value.appointment_type === 'Video'
                                          ? 'fas fa-video'
                                          : 'fas fa-phone'
                                      }`}
                                  ></i>
                                  {value.appointment_type !== 'Chat' ? ` Make ${value.appointment_type} Call` : ` Start Chat`}
                                </Link>
                              )}
                            {
                              this.state.countDownTime[value.appointmentID] !== 'EXPIRED'
                              && (<>
                                <button className="btn btn-sm bg-primary-light" onMouseUp={() => this.GetAppointment(value.councillor_uniquekey, value.appointment_code)}><i className="fas fa-calendar-day"></i> Reschedule</button>
                                <button className="btn btn-sm bg-danger-light" onMouseUp={() => this.handleCancel(value.appointment_code)}><i className="fas fa-calendar-times"></i> Cancel</button>
                                <Link to='/support' className="btn btn-sm bg-info-light"><i className="fas fa-comment-alt"></i> Chat Support</Link></>
                              )
                            }
                          </div>
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {patient_appoinments.length > 0 && (
                <Pagination
                  pageSize={10}
                  items={patient_appoinments}
                  onChangePage={this.onChangePage}
                />
              )}
            </div>
          </div>
        </div>
        <CounsellorAppointment appointmentCode={this.state.appointmentCode} updateAppointmentList={this.updateAppointmentList} />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  patient_appoinments: state.registration.patcoun_appointments,
});

export default connect(mapStateToProps)(PatientCounsellorAppointment);
