import React from 'react'
import { connect } from 'react-redux'
import { Link} from 'react-router-dom';
import { Counselor_Patients } from './../../store/actions/counselorPanelActions'
import Pagination from './../../store/helpers/Pagination'
import {bucketPath} from './../../store/helpers/common'
import moment from 'moment';

class CounsellorPatients extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pageOfItems: [],
			currentPage: 1
		}
	}

	componentDidMount() {
		this.props.dispatch(Counselor_Patients())
	}

	onChangePage = (pageOfItems, page) => {
		this.setState({ pageOfItems: pageOfItems, currentPage: page });
	};

	render() {
		const { patient_listing } = this.props
		const { pageOfItems } = this.state
		return (
			<div className="row row-grid">
				{pageOfItems.map((value, index) => {
					return (
						<div className="col-md-6 col-lg-4 col-xl-3" key={`index${index}`}>
							<div className="card widget-profile pat-widget-profile">
								<div className="card-body">
									<div className="pro-widget-content">
										<div className="profile-info-widget">
											<Link to={`/counsellor/patient-profile/${value.uniquekey}`} className="booking-doc-img">
												<img src={value.profile_pic?`${bucketPath}${value.profile_pic}`:`/assets/doctor/img/patients/patient.jpg`} alt="User" />
											</Link>
											<div className="profile-det-info">
												<h3><Link to={`/counsellor/patient-profile/${value.uniquekey}`}>{`${value.firstname} ${value.lastname}`}</Link></h3>
												<div className="patient-details">
													<h5><b>Patient ID :</b> {value.uniquekey}</h5>
													<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i>{`${value.city}, ${value.country}`}</h5>
												</div>
											</div>
										</div>
									</div>
									<div className="patient-info">
										<ul>
											<li>Age <span>{value.dob?`${moment().diff(value.dob, 'years')} Years`:"NA"} , {value.gender === "M" ? "Male" : value.gender === "F" ? "Female" : "Trans"}</span></li>
											<li>Blood Group <span>{value.blood_group}</span></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					)
				})}
				<div className="container-fluid no-padding">
					<div className="row pull-right">
						<div className="col-md-12 col-sm-12 col-xs-12 pagination ">
							&nbsp;
								{patient_listing && (
								<Pagination
									pageSize={20}
									items={patient_listing}
									onChangePage={this.onChangePage}
								/>
							)
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	patient_listing: state.counselorPanel.patient_listing
});

export default connect(mapStateToProps)(CounsellorPatients); 