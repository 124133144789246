import React, { Component } from 'react';
import Header from './Header'
import Footer from './Footer'
import Alerts from './../../components/General/Alerts'
import NavBar from './NavBar'
class EmptyLayout extends Component {

    componentDidMount(){
        this.storage = localStorage.getItem('admin-token')
        this.path = this.props.match.path
        
        if((!this.storage)){
            this.props.history.push('/admin-login');
        }
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div id="page_container" className="toggled">
                    <NavBar />
                    <div className="main_container">
                        <div className="page_content">
                            <div className="page-wrapper">
                                <div className="content container-fluid">
                                <Alerts />
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    <Footer />
                    </div>
                </div>
            </>
        );
    }
}

export default EmptyLayout;